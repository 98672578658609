import * as React from 'react';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { Transition } from './Transition';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
    gap: theme.spacing(0.5),
  },
  '& .MuiDialog-paper': {
    borderRadius: theme.spacing(1),
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
}

export const DialogContainer = ({ open, onClose, children }: Props) => {
  return (
    <BootstrapDialog
      //@ts-ignore: Need to disable tabIndex for 'Create new matter' modal to work
      TransitionProps={{ tabIndex: '' }}
      sx={{
        zIndex: 1,
      }}
      onClose={onClose}
      open={open}
      TransitionComponent={Transition}
    >
      {children}
    </BootstrapDialog>
  );
};
