import React, { FC } from 'react';
import { Heading } from '~/src/components/Typography';
import cns from 'classnames';

interface Props {
  title: string;
  childContainerClassName?: string;
}

const SideMenuItem: FC<Props> = ({
  title,
  children,
  childContainerClassName,
}) => {
  return (
    <div className="mb-8">
      <Heading htmlElement="h6" variant="subheading">
        {title}
      </Heading>
      <div className={cns('mt-2', childContainerClassName)}>{children}</div>
    </div>
  );
};

export default SideMenuItem;
