import { useMutation } from 'react-query';
import { APIError } from '~/src/utils/error';
import { Contact } from '../types';
import { fetchAndParse, useInvalidateQuery } from '../../utils';
import { keys } from '../keys';
import { getContactsUrl } from '../urls';

type UpdateContact = {
  orgFprint: string;
  contactId: number;
  contact: Partial<Contact>;
};

export const useUpdateContact = () =>
  useMutation<Contact, APIError, UpdateContact>({
    onSuccess: useInvalidateQuery(keys.all),
    mutationFn: ({ orgFprint, contactId, contact }) =>
      fetchAndParse(getContactsUrl(orgFprint, `${contactId}`), {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(contact),
      }),
  });
