import { types, flow, destroy } from 'mobx-state-tree';
import { getOrgFprintFromStoreNode } from '../utils';

const CreatePaginatedListModel = (name, Model, ResourceModel, callbacks) => {
  const DEFAULT_PAGINATE = () => [];
  const DEFEAULT_ADD_ITEM = (identifier, item) => item;

  const PaginatedListModel = types
    .model('PaginatedListResource', {
      isLoading: types.optional(types.boolean, false),
      list: types.optional(
        types.array(types.array(types.reference(ResourceModel))),
        [],
      ),
      search: types.optional(types.string, ''),
      sortBy: types.optional(types.string, 'last_modified'),
      order: types.optional(types.string, ''),
      limit: types.optional(types.integer, 10),
      requestPayload: types.maybeNull(types.model({})),
    })
    .actions((self) => {
      const paginate = flow(function* paginate(query, requestPayload = null) {
        // TODO: Abstract defaults into exportable/importable constants
        const page = query.page || 1;
        const search = query.search || '';
        const sortBy = query.sortBy || 'last_modified';
        const order = query.order || '';
        const limit = query.limit || 10;
        const pageIndex = page - 1;

        const addItem = self.addItem || callbacks.addItem || DEFEAULT_ADD_ITEM;
        const hasQueryChanged =
          self.search !== search ||
          self.sortBy !== sortBy ||
          self.order !== order ||
          self.limit !== limit;

        self.list.push([]);

        self.isLoading = true;

        self.search = search;
        self.sortBy = sortBy;
        self.order = order;
        self.limit = limit;
        self.requestPayload = requestPayload;

        try {
          // if query params change, reset self.list to empty array and render empty table
          if (hasQueryChanged) {
            self.list = [];
          }
          const fetchCall = callbacks.paginate || DEFAULT_PAGINATE;
          const orgFprint = getOrgFprintFromStoreNode(self);
          const res = yield fetchCall(
            { page, search, sortBy, order, limit, orgFprint },
            requestPayload,
          ) || [];
          const next = res.map((contact) =>
            addItem(contact.identifier, contact),
          );
          if (
            (hasQueryChanged || self.requestPayload !== requestPayload) &&
            pageIndex === 0
          ) {
            self.list = [next];
          } else {
            self.list[pageIndex] = next;
          }

          self.isLoading = false;
          return next;
        } catch (error) {
          console.error('Failed to paginate : ', error);
          self.error =
            typeof error === 'string' ? error : 'Something went wrong';
          self.isLoading = false;
          return [];
        }
      });

      const addItemToList = (item) => {
        if (self.list && self.list[0]) {
          self.list[0].unshift(item.identifier);
        } else {
          self.list = [[item.identifier]];
        }
      };

      return {
        paginate,
        addItemToList,
      };
    });

  return types.compose(Model, PaginatedListModel).named(name);
};

const CreatePaginatedDictionaryListModel = (
  name,
  Model,
  ResourceModel,
  callbacks,
) => {
  const DictionaryModel = types
    .model('DictionaryModel', {
      dictionary: types.optional(types.map(ResourceModel), {}),
    })
    .actions((self) => {
      const getItem = (key) => {
        return self.dictionary.get(key);
      };

      const addItem = (key, value) => {
        self.dictionary.set(key, value);
        const setItem = self.dictionary.get(key);

        if (callbacks.addItem) {
          callbacks.addItem(setItem);
        }

        return setItem.identifier;
      };

      const removeItem = (key) => {
        for (let page = 0; page < self.list.length; page += 1) {
          const pageList = self.list[page] || [];
          let itemIndexToRemove = -1;

          for (let itemIndex = 0; itemIndex < pageList.length; itemIndex += 1) {
            const item = pageList[itemIndex];
            if (item && item.identifier === key) {
              itemIndexToRemove = itemIndex;
            }
          }

          if (itemIndexToRemove > -1) {
            pageList.splice(itemIndexToRemove, 1);
          }

          self.list[page] = pageList;
        }

        const item = self.dictionary.get(key);
        self.dictionary.delete(key);

        if (callbacks.removeItem) {
          callbacks.removeItem(item);
        }

        destroy(item);
      };

      return {
        addItem,
        removeItem,
        getItem,
      };
    });

  const ComposedDictionaryModel = types.compose(Model, DictionaryModel);
  return CreatePaginatedListModel(
    name,
    ComposedDictionaryModel,
    ResourceModel,
    callbacks,
  );
};

export {
  CreatePaginatedListModel,
  CreatePaginatedDictionaryListModel as default,
};
