import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

export default StyleSheet.create({
  container: {
    margin: `${theme.unit * 4}px auto`,
    textAlign: 'center',
    color: theme.colors.bluebird,
    ':hover': {
      cursor: 'pointer',
    },
  },
  spinner: {
    marginBottom: `${theme.unit * 2}px`,
  },
  containerSlim: {
    margin: `${theme.unit * 2}px auto`,
  },
});
