import assert from 'assert';
import { useState } from 'react';
import { INVALID_REASON_TOOLTIP_MAP } from '~/src/components/SignaturePackage/constants';

/**
 * This hook is used to indicate whether or not the
 * signature package wizard is in a valid state and
 * can be moved forward. If not, this hook should
 * provide a reason.
 */
const useIsValid = () => {
  const [valid, _setValid] = useState(true);
  const [invalidReason, setInvalidReason] = useState<null | string>(null);

  const setValid = (isValid: boolean, reason?: string) => {
    if (isValid) {
      _setValid(true);
      setInvalidReason(null);
    } else {
      assert(reason, 'Package has been labelled "invalid" without a reason!');
      _setValid(false);
      setInvalidReason(reason);
    }
  };
  // @ts-expect-error
  const reason = invalidReason ? INVALID_REASON_TOOLTIP_MAP[invalidReason] : '';

  return { valid, setValid, invalidReason: reason, setInvalidReason };
};

export default useIsValid;
