import { useQuery } from 'react-query';
import { getProjectPopulateUrl } from '../urls';
import { AsyncResultStatus } from '../types';
import { useCurrentOrgFprint } from '../../user';
import { useState } from 'react';
import { keys } from '../keys';
import { usePollingTaskStatus } from '~/src/hooks/usePollingTaskStatus';

const fetchPojectPopulate = async (orgFprint: string, projectId: number) => {
  const response = await fetch(getProjectPopulateUrl(orgFprint, projectId), {
    method: 'POST',
  });
  const data = await response.json();
  return {
    taskId: data.task_id,
    status: data.status,
  };
};

export const useProjectPopulateState = (
  projectId: number,
  isEnabled: boolean,
) => {
  const [projectPopulateQueryIsSettled, setProjectPopulateQueryIsSettled] =
    useState(false);
  const [projectPopulateQueryError, setProjectPopulateQueryError] =
    useState(false);

  const [taskId, setTaskId] = useState<string | undefined>(undefined);
  const orgFprint = useCurrentOrgFprint();

  useQuery(
    [keys.PROJECT_ASYNC_POPULATE_STATUS, orgFprint, projectId],
    () => fetchPojectPopulate(orgFprint, projectId),
    {
      enabled: isEnabled && !projectPopulateQueryIsSettled,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onSettled: (data) => {
        setTaskId(data?.taskId);
        setProjectPopulateQueryIsSettled(
          !data?.taskId || data?.status === AsyncResultStatus.SUCCESS,
        );
      },
      onError: () => setProjectPopulateQueryError(true),
    },
  );

  const { data: taskStatusQuery } = usePollingTaskStatus(taskId);
  const taskStatusQueryIsSettled =
    taskStatusQuery?.status === AsyncResultStatus.SUCCESS ||
    taskStatusQuery?.status === AsyncResultStatus.FAILURE;

  const isSettled = taskStatusQueryIsSettled || projectPopulateQueryIsSettled;
  const isSuccessful =
    projectPopulateQueryIsSettled ||
    taskStatusQuery?.status === AsyncResultStatus.SUCCESS;
  return {
    data: {
      status: taskStatusQuery?.status,
      taskId,
      isSettled,
      isSuccessful,
      hasError: (isSettled && !isSuccessful) || projectPopulateQueryError,
    },
  };
};
