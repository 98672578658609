import * as React from 'react';
import { ReactComponent as WarningIcon } from '~/src/static/warning-icon.svg';
import { ReactComponent as DismissIcon } from '~/src/static/dismiss-icon.svg';

type LimitWarningProps = {
  onDismiss: () => void;
};

export const LimitWarning = ({ onDismiss }: LimitWarningProps) => {
  return (
    <div className="mt-1 mr-4 mb-0 ml-3 px-6 py-4 pr-10 border border-yellow-300 bg-yellow-50 rounded-lg relative">
      <div>
        <div className="flex">
          <div className="font-semibold flex items-center">
            <div className="mr-2 flex">
              <WarningIcon />
            </div>
            <div>Limit reached</div>
          </div>
          <button
            className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
            onClick={onDismiss}
          >
            <DismissIcon />
          </button>
        </div>
        <div className="text-sm mt-2">
          Cannot add more than 20 documents to selection.
        </div>
      </div>
    </div>
  );
};
