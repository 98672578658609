import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import qs from 'qs';

import DocumentPreviewSlideIn from '~/src/components/Documents/DocumentPreviewSlideIn';
import DocumentSetPreviewSlideIn from '~/src/components/DocumentSets/DocumentSetPreviewSlideIn';
import { history } from '~/src/utils/history';

class ProjectPreviewSlideIn extends React.Component {
  // eslint-disable-next-line react/no-deprecated
  componentWillMount = () => {
    this.project = this.props.store.projects.getProject(this.props.projectId);
    if (!this.project) {
      this.hideSlideIn();
    } else {
      this.documents = this.project.documents;
    }
  };

  componentWillUnmount = () => {
    this.project = null;
  };

  handleOnDraftNewDocument = () => {
    const templateId = this.documents && this.documents[0].templateId;
    if (templateId) {
      history.push(`/templates/${templateId}/new`);
      this.props.hideSlideIn();
    } else {
      const error = 'Drafted document is missing template ID';
      console.error(error, new Error(error));
    }
  };

  handleOnDraftNewDocSet = () => {
    const templateIds = this.project && this.project.getTemplateIds();

    if (templateIds) {
      const queryParams = qs.stringify({ ids: templateIds }, { encode: false });
      history.push(`/templates/docset/new?${queryParams}`);
      this.props.hideSlideIn();
    } else {
      const error = "Drafted document set is has no template id's";
      console.error(error, new Error(error));
    }
  };

  render() {
    const { hideSlideIn } = this.props;

    const title = this.project.name;

    if (this.project.isDocumentSet()) {
      const templateIds = this.project && this.project.getTemplateIds();

      const imageSets = this.project.documents.map((document) => {
        const documentPages = document && document.pages ? document.pages : [];
        const list = documentPages.map(({ backgroundUrl }) => backgroundUrl);
        return { title: document.title, list };
      });

      return (
        <DocumentSetPreviewSlideIn
          title={title}
          imageSets={imageSets}
          onCancel={hideSlideIn}
          disableDraftDocSet={!templateIds || templateIds.length === 0}
          onDraftNewDocSet={this.handleOnDraftNewDocSet}
        />
      );
    }
    const document = this.documents && this.documents[0];
    const pages = document && document.pages ? document.pages : [];
    const images = pages.map(({ backgroundUrl }) => backgroundUrl);
    const template =
      document && this.props.store.templates.getTemplate(document.templateId);

    return (
      <DocumentPreviewSlideIn
        title={title}
        images={images}
        onCancel={hideSlideIn}
        hideAddToSelection={true}
        hideDraftDocument={false}
        disableDraftDocument={!document || !template}
        onDraftNewDocument={this.handleOnDraftNewDocument}
      />
    );
  }
}

ProjectPreviewSlideIn.propTypes = {
  projectId: PropTypes.number.isRequired,
};

export default inject((store) => store)(observer(ProjectPreviewSlideIn));
