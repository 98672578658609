import { FetchInterceptor } from 'fetch-intercept';
import cookieUtil from '~/src/utils/cookies';
import env from '~/src/utils/env';
import analyticsService from '~/src/services/analytics';

const ignoreList = [/authtoken-emulated-user/];

const isIgnorable401 = (url: string) =>
  ignoreList.some((regexp) => regexp.test(url));

export const createInterceptor = (): FetchInterceptor => ({
  request: (url, config = {}) => {
    if (!url.includes(env.apiUrl)) return [url, config];
    const headers = cookieUtil.getHeaderTokens();
    return [
      url,
      {
        ...config,
        headers: { ...(headers || {}), ...(config.headers || {}) },
      },
    ];
  },
  response: (res) => {
    if (!res.url.includes(env.apiUrl)) return res;
    if (res.status === 401 && !isIgnorable401(res.url)) {
      analyticsService.track('Account Logged Out Automatically');
      cookieUtil.logout();
      window.location.assign('/signin/?auto_logged=true');
    }
    if (res.status === 503 && res.statusText === 'Service Unavailable') {
      window.location.reload();
    }
    return res;
  },
});
