import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

export default StyleSheet.create({
  rowActionButton: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.unit * 1}px ${theme.unit * 2}px`,
    paddingRight: theme.unit,
  },
  actionButton: {
    marginRight: theme.unit * 2,
  },
  actions: {
    display: 'flex',
  },
  contactsSlideInContainer: {
    minHeight: '100%',
    display: 'flex',
    flexFlow: 'column',
    flexGrow: 1,
  },
  contactSlideInHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.unit * 3,
    background: theme.surface,
    alignItems: 'center',
    borderBottom: theme.border,
  },
  contactSlideInHeaderTitle: {
    maxWidth: '60%',
    paddingRight: theme.unit,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: theme.fonts.size.large,
    color: theme.colors.charcoal,
  },
  contactSlideInBodyFooter: {
    bottom: '83px',
  },
  contactValidationError: {
    color: 'red',
    fontSize: theme.fonts.size.small,
  },
  contactSlideInBody: {
    padding: theme.unit * 3,
    background: theme.background,
    // display: "flex",
    // flexFlow: "column",
    // flexGrow: 1,
    position: 'absolute',
    top: '83px',
    bottom: 0,
    overflowY: 'scroll',
    left: 0,
    right: 0,
    minHeight: 'auto',
  },
  contactSlideInCard: {
    width: '100%',
  },
  contactsSlideInFooter: {
    padding: theme.unit * 3,
    borderTop: theme.border,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    background: theme.surface,
  },

  /* General */

  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.unit * 2,
    background: theme.surface,
    borderTop: theme.border,
  },

  footerButton: {
    marginLeft: theme.unit * 2,
  },

  /* Contacts Import */
  contactsImportContainer: {
    maxWidth: theme.unit * 110,
    margin: '0 auto',
  },
  contactsImportTitle: {
    fontSize: theme.fonts.size.small,
    fontWeight: 500,
    margin: `${theme.unit * 2}px 0`,
  },

  contactsImportCardTitle: {
    fontSize: theme.fonts.size.small,
    fontWeight: 500,
  },
  contactsImportCardContainer: {
    display: 'flex',
  },
  contactsImportCard: {
    minHeight: theme.unit * 43,
    marginRight: theme.unit * 7,
    ':last-of-type': {
      marginRight: '0',
    },
  },
  contactsImportCardMessage: {
    fontSize: theme.fonts.size.small,
    color: theme.colors.charcoal,
    lineHeight: '1.5em',
    flexGrow: 1,
  },
  contactsImportCardActions: {
    marginTop: theme.unit * 2,
    display: 'flex',
    justifyContent: 'flex-end',
  },
});
