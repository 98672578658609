import { Box, MenuItem, Select } from '@mui/material';
import React from 'react';
import { FormField } from '~/src/components/FormField';

type SeatsProps = {
  value: number;
  onChange: (value: number) => void;
};

export const Seats = ({ value, onChange }: SeatsProps) => {
  return (
    <Box width={'130px'}>
      <FormField labelProps={{ htmlFor: 'seats' }} label={'Number of seats'}>
        <Select
          inputProps={{ id: 'seats' }}
          value={value}
          onChange={(e) => onChange(+e.target.value)}
        >
          {Array.from({ length: 50 }).map((_, i) => (
            <MenuItem key={i} value={i + 1}>
              {i + 1}
            </MenuItem>
          ))}
        </Select>
      </FormField>
    </Box>
  );
};
