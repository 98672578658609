import React from 'react';

import Checkbox from '~/src/components/Checkbox';
import { Text } from '~/src/components/Typography';
import DraggableIcon from '~/src/components/DraggableIcon';
import theme from '~/src/theme';
import DraggableListContainer, {
  DraggableListItem,
} from '~/src/components/Draggable';

const DraggableDocumentsList = ({
  enableDragDrop,
  draggableType,
  // initialDocuments,
  docs,
  setDocs,
  selectedDocumentIds,
  toggleCheckBox,
  onReorderDocument,
}) => {
  // const [documents, setDocuments] = useState(initialDocuments);

  const moveDocument = (dragIndex, hoverIndex) => {
    const updatedDocuments = [...docs];
    const dragCard = updatedDocuments[dragIndex];
    updatedDocuments.splice(dragIndex, 1);
    updatedDocuments.splice(hoverIndex, 0, dragCard);

    // setDocuments(updatedDocuments); // update local state
    setDocs(updatedDocuments); // use parent function to update parent state

    const docIds = updatedDocuments.map((doc) => {
      return doc.id;
    });
    onReorderDocument(docs, docIds);
  };

  const renderDocument = (doc) => {
    const docDescription = doc.subtitle
      ? `${doc.title} (${doc.subtitle})`
      : doc.title;
    return (
      <div className="flex items-center border-t border-gray-200 gap-x-3 px-3 hover:bg-gray-100">
        <DraggableIcon
          className="ml-2"
          color={theme.colors.concrete}
          size={14}
        />
        <Checkbox
          checked={selectedDocumentIds[doc.id]}
          color={theme.colors.bluebird}
          size={3}
          onClick={() => toggleCheckBox(doc.id)}
        />
        <Text
          strong
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
            paddingTop: '0.75rem',
            paddingBottom: '0.75rem',
          }}
        >
          {docDescription}
        </Text>
      </div>
    );
  };

  const renderDraggableListItem = (item, index) => {
    return (
      <DraggableListItem
        enableDragDrop={enableDragDrop}
        key={item.id}
        index={index}
        id={item.id}
        moveItem={moveDocument}
        item={item}
        renderItem={renderDocument}
        draggableType={draggableType}
      />
    );
  };

  return (
    <DraggableListContainer
      renderDraggableListItem={renderDraggableListItem}
      items={docs}
      showNSelectedFragment={true}
      selectedDocumentIds={selectedDocumentIds}
    />
  );
};

export default DraggableDocumentsList;
