import { Box, Drawer } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import {
  Project,
  ProjectStackDocument,
  useTemplatePreview,
} from '~/src/entities/project';
import { Sidebar } from '../Sidebar';
import { useWatch, UseFormReturn } from 'react-hook-form';
import { PopulatePageCard } from './PopulatePageCard/PopulatePageCard';
import { DOCUMENT_CATEGORY_ID } from '~/src/stores/sidebarItemsStore';
import {
  getFieldLabelsForGroup,
  getFieldsForGroup,
  getEmptyFields,
} from './utils';
import { ProgressTrackerWidget } from '~/src/components/ProgressTrackerWidget';
import { useRelatedQuestionnaireSubmissionUnmappedQuestions } from '~/src/entities/questionnairesV2/hooks/useRelatedQuestionnaireSubmissionUnmappedQuestions';
import { UnmappedQuestionsWidget } from '~/src/components/UnmappedQuestionsWidget';
import DocumentPreviewSlideIn from '~/src/components/Documents/DocumentPreviewSlideIn';
import analyticsService from '~/src/services/analytics';
import ProjectUpdater from './ProjectUpdater';
import { ProjectProvider } from '../../ProjectProvider';
import Toolbar from './Toolbar';

type PopulatePageContentProps = {
  methods: UseFormReturn;
  project: Project;
  isClioQuestionnaireEnabled: boolean;
  isProgressTrackerEnabled: boolean;
  onSaving: () => void;
  onSaved: () => void;
  onGenerate: () => void;
  isGenerating: boolean;
};

type DrawerContainerProps = {
  document: ProjectStackDocument;
  open: boolean;
  onClose: () => void;
};

const DrawerContainer = ({ document, open, onClose }: DrawerContainerProps) => {
  const docId =
    document.document_type === DOCUMENT_CATEGORY_ID.MS_WORD
      ? document.template_docid
      : document.global_template_docid;

  const { data: globalTemplate, ...response } = useTemplatePreview(
    docId,
    document.document_type === DOCUMENT_CATEGORY_ID.MS_WORD,
  );

  const getPagesArray = () => {
    let pagesArray = [''];
    if (response.isSuccess && globalTemplate) {
      if ('document' in globalTemplate) {
        pagesArray = globalTemplate.document.pages.map(
          (page) => page.background_url,
        );
      } else {
        pagesArray = globalTemplate.preview_pages.map(
          (page) => page.background_url,
        );
      }
    }
    return pagesArray;
  };
  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        width: '800px',
        '& .MuiDrawer-paper': {
          width: '800px',
          boxSizing: 'border-box',
        },
      }}
    >
      <DocumentPreviewSlideIn
        title={document.title}
        images={getPagesArray()}
        message={
          'The preview pages for this document are generating. Please try again later.'
        }
        onCancel={onClose}
        hideAddToSelection={true}
        hideDraftDocument={true}
        onDraftNewDocument={true}
        onAddToSelection={() => {}}
      />
    </Drawer>
  );
};

export const PopulatePageContent = ({
  methods,
  project,
  isClioQuestionnaireEnabled,
  isProgressTrackerEnabled,
  onGenerate,
  isGenerating,
  onSaving,
  onSaved,
}: PopulatePageContentProps) => {
  const { data: unmappedQuestionsData, isLoading: isUnmappedQuestionsLoading } =
    useRelatedQuestionnaireSubmissionUnmappedQuestions(
      project.stack_data.project_metadata.matter_id,
    );

  const allFieldsFromWatch = useWatch();

  const progressTrackerRef = useRef<HTMLDivElement>(null);

  const [expandedSection, setSectionExpanded] = useState('');
  const [lastExpandedCardIndex, setLastExpandedCardIndex] = useState(-1);
  const [cardsExpanded, setCardsExpanded] = useState(() => {
    return project.stack_data.groups.map((group) => {
      const fields = getFieldsForGroup(project, group);
      const emptyFields = getEmptyFields(fields);
      if (group.entity_type == 'Contact') {
        if (emptyFields.length !== group.fields.length) {
          return true;
        }
        if (group.related_contact_id) {
          return true;
        }
        return false;
      }
      return true;
    });
  });

  const [previewDocument, setPreviewDocument] =
    useState<ProjectStackDocument>();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [currentField, setCurrentField] = useState<string | undefined>(
    undefined,
  );

  const allFields = project.stack_data.groups
    .map((group) => getFieldsForGroup(project, group))
    .flat();
  const allFieldsLabels = project.stack_data.groups
    .map((group) => getFieldLabelsForGroup(project, group))
    .flat();
  const totalFieldsCount = allFields.length;
  const initialTotalFilledFields = allFields.filter(
    (field) =>
      field.value !== undefined && field.value !== null && field.value !== '',
  );

  const totalFilledFields = allFields.filter((field) => {
    return (
      field.fid in allFieldsFromWatch &&
      allFieldsFromWatch[field.fid] !== undefined &&
      allFieldsFromWatch[field.fid] !== ''
    );
  });

  const [isUnmappedQuestionsHidden, setUnmappedQuestionsHidden] =
    useState(true);

  const [isProgressTrackerHidden, setProgressTrackerHidden] = useState(
    initialTotalFilledFields.length === totalFieldsCount,
  );

  const fidToFieldLabelMapRef = useRef(
    new Map(allFieldsLabels.map((field) => [field?.fid, field?.full_label])),
  );
  const fieldsReviewedMapRef = useRef(
    new Map(allFields.map((field) => [field.fid, !!field.value])),
  );

  const fieldFocusedByWidget = useRef<string | null>(null);

  const getCurrentFieldLabel = () => {
    if (currentField) {
      const fidToFieldLabelMap = fidToFieldLabelMapRef.current;
      return fidToFieldLabelMap.get(currentField);
    }
  };

  const getFieldReviewedCount = () => {
    const fieldsReviewedMap = fieldsReviewedMapRef.current;
    return Array.from(fieldsReviewedMap.values()).filter((value) => value)
      .length;
  };

  const fidToGroupIndex: {
    [key: string]: number;
  } = {};
  project.stack_data.groups.forEach((group, groupIndex) => {
    group.fields.forEach((fid) => {
      fidToGroupIndex[fid] = groupIndex;
    });
  });

  const focusCurrentFieldAfterExpanded = (groupIndex: number) => {
    if (currentField) {
      if (fidToGroupIndex[currentField] === groupIndex) {
        methods.setFocus(currentField);
        if (isProgressTrackerEnabled) {
          alignFocusedFieldWithProgressTracker();
          setLastExpandedCardIndex(groupIndex);
        }
      }
    }
  };

  const onNextClick = () => {
    analyticsService.track('Review Widget Clicked', {
      projectId: project.id,
      action: 'next',
      button_label: 'Next',
    });
    if (getFieldReviewedCount() === totalFieldsCount) {
      setCurrentField(undefined);
    } else {
      if (currentField) {
        fieldsReviewedMapRef.current.set(currentField, true);
      }
      const nextField = allFields.find(
        (field) => !fieldsReviewedMapRef.current.get(field.fid),
      );
      if (nextField) {
        fieldFocusedByWidget.current = nextField.fid;
        setCurrentField(nextField.fid);
        methods.setFocus(nextField.fid);
        if (isProgressTrackerEnabled) {
          alignFocusedFieldWithProgressTracker();
        }

        // expand the card the focused field is under
        const groupIndex = fidToGroupIndex[nextField.fid];
        const nextCardsExpanded = [...cardsExpanded];
        if (nextCardsExpanded[groupIndex!] === false) {
          nextCardsExpanded[groupIndex!] = true;
          setCardsExpanded(nextCardsExpanded);
        }
      }
    }
  };

  const onReviewAgainClick = () => {
    analyticsService.track('Review Widget Clicked', {
      projectId: project.id,
      action: 'next',
      button_label: 'Review again',
    });

    fieldsReviewedMapRef.current = new Map(
      allFields.map((field) => {
        if (field.fid in allFieldsFromWatch) {
          return [field.fid, !!allFieldsFromWatch[field.fid]];
        }
        return [field.fid, false];
      }),
    );

    const nextField = allFields.find(
      (field) => !fieldsReviewedMapRef.current.get(field.fid),
    );
    if (nextField) {
      fieldFocusedByWidget.current = nextField.fid;
      setCurrentField(nextField.fid);
      methods.setFocus(nextField.fid);

      if (isProgressTrackerEnabled) {
        alignFocusedFieldWithProgressTracker();
      }

      // expand the card the focused field is under
      const groupIndex = fidToGroupIndex[nextField.fid];
      const nextCardsExpanded = [...cardsExpanded];
      if (nextCardsExpanded[groupIndex!] === false) {
        nextCardsExpanded[groupIndex!] = true;
        setCardsExpanded(nextCardsExpanded);
      }
    }
  };

  const markedAsReviewedOrNot = (fieldId: string, reviewedOrNot: boolean) => {
    fieldsReviewedMapRef.current.set(fieldId, reviewedOrNot);
  };

  const getScrollableAncestor = (element: Element) => {
    if (element === null) return null;
    let style = getComputedStyle(element);
    const overflowRegex = /(auto|scroll)/;
    let parent = element;
    while (parent) {
      style = getComputedStyle(parent);
      if (overflowRegex.test(style.overflow + style.overflowY)) {
        return parent;
      }
      if (parent.parentElement) {
        parent = parent.parentElement;
      } else {
        break;
      }
    }
    return document.documentElement;
  };

  // align the focused field with Progress Tracker when clicking Next button.
  const alignFocusedFieldWithProgressTracker = () => {
    if (document.activeElement) {
      const focusedField = document.activeElement;
      const focusedFieldRect = focusedField.getBoundingClientRect();
      if (progressTrackerRef.current) {
        const scrollableAncestor = getScrollableAncestor(focusedField);
        const progressTrackerRect =
          progressTrackerRef.current.getBoundingClientRect();

        if (scrollableAncestor) {
          const top =
            scrollableAncestor.scrollTop +
            focusedFieldRect.top -
            progressTrackerRect.top -
            progressTrackerRect.height / 2;

          scrollableAncestor.scroll({
            top,
            behavior: 'smooth',
          });
        }
      }
    }
  };

  // make Progress Tracker follow the focused field when clicking through input fields.
  const makeProgressTrackerFollowFocusedField = () => {
    if (document.activeElement) {
      const focusedFieldRect = document.activeElement.getBoundingClientRect();

      // ancestor element shared by the focused field and progress tracker widget
      const sharedAncestorElement = document.getElementById(
        'cardlist-progresstracker-container',
      );
      const sharedAncestorElementRect =
        sharedAncestorElement!.getBoundingClientRect();

      if (progressTrackerRef.current) {
        const progressTrackerRect =
          progressTrackerRef.current.getBoundingClientRect();

        const focusedFieldCenter =
          focusedFieldRect.bottom - focusedFieldRect.height / 2;

        let nextProgressTrackerTop =
          focusedFieldCenter - progressTrackerRect.height / 2;

        const yOverflowDiff =
          nextProgressTrackerTop +
          progressTrackerRect.height -
          sharedAncestorElementRect.bottom +
          40; //the lowest position the widget should touch is 40px above the bottom of the page

        if (yOverflowDiff > 0) {
          nextProgressTrackerTop = nextProgressTrackerTop - yOverflowDiff;
        }

        progressTrackerRef.current.style.top = `${nextProgressTrackerTop}px`;
      }
    }
  };

  const onFieldFocus = (fieldId: string) => {
    setCurrentField(fieldId);

    if (isProgressTrackerEnabled) {
      if (fieldFocusedByWidget.current !== fieldId) {
        makeProgressTrackerFollowFocusedField();
      }
      fieldFocusedByWidget.current = null;
      fieldsReviewedMapRef.current.set(fieldId, true);
    }
  };

  const onFieldBlur = () => {
    if (getFieldReviewedCount() === totalFieldsCount) {
      setCurrentField(undefined);
    }
  };

  const onFieldNameClick = () => {
    if (currentField) {
      fieldFocusedByWidget.current = currentField;
      methods.setFocus(currentField);
      if (isProgressTrackerEnabled) {
        alignFocusedFieldWithProgressTracker();
      }
    }
  };

  const handleProgressTrackerClick = () => {
    analyticsService.track('Populate Toolbar Clicked', {
      projectId: project.id,
      tool: 'review_widget',
      state: isProgressTrackerHidden ? 'open' : 'closed',
      onPageLoad: false,
    });
    if (isProgressTrackerHidden) {
      setUnmappedQuestionsHidden(true);
    }
    setProgressTrackerHidden(!isProgressTrackerHidden);
  };

  const handleUnmappedQuestionsClick = () => {
    if (isUnmappedQuestionsHidden) {
      setProgressTrackerHidden(true);
    }
    setUnmappedQuestionsHidden(!isUnmappedQuestionsHidden);
  };

  const countTouched = (fieldId: string) => {
    fieldsReviewedMapRef.current.set(fieldId, true);
    if (getFieldReviewedCount() === totalFieldsCount) {
      setCurrentField(undefined);
    }
  };

  useEffect(() => {
    analyticsService.track('Populate Toolbar Clicked', {
      projectId: project.id,
      tool: 'review_widget',
      state:
        initialTotalFilledFields.length !== totalFieldsCount
          ? 'open'
          : 'closed',
      onPageLoad: true,
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProjectProvider project={project}>
      <ProjectUpdater
        project={project}
        isProgressTrackerEnabled={isProgressTrackerEnabled}
        onSaved={onSaved}
        onSaving={onSaving}
      />
      <Box display={'flex'} overflow={'auto'} flex={1}>
        <Box
          flex={'0 0 259px'}
          overflow={'auto'}
          sx={{ scrollbarGutter: 'stable' }}
          bgcolor={'white'}
          mr={2}
        >
          <Sidebar
            onClickDocument={(document) => {
              setPreviewDocument(document);
              setDrawerOpen(true);
            }}
            onExpandSection={(section) => {
              const isCurrentlyExpanded = expandedSection === section.gid;
              if (!isCurrentlyExpanded)
                document
                  .getElementById(section.gid)
                  ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
              setSectionExpanded((s) => (s === section.gid ? '' : section.gid));
            }}
            expandedSection={expandedSection}
            matterName={project.stack_data.project_metadata.matter_title}
            project={project}
          />
          {previewDocument && (
            <DrawerContainer
              document={previewDocument}
              open={isDrawerOpen}
              onClose={() => setDrawerOpen(false)}
            />
          )}
        </Box>
        <Box
          id="cardlist-progresstracker-container"
          flex={1}
          overflow={'auto'}
          sx={(theme) => ({
            display: 'flex',
            scrollPadding: theme.spacing(3, 0, 0),
          })}
        >
          <Box
            maxWidth={464}
            width={'100%'}
            sx={(theme) => ({ margin: theme.spacing(2, 'auto', 5) })}
          >
            {project.stack_data.groups.map((group, groupIndex) => (
              <PopulatePageCard
                key={group.gid}
                expanded={cardsExpanded[groupIndex] as boolean}
                onChangeExpanded={(newValue: boolean) => {
                  const nextCardsExpanded = [...cardsExpanded];
                  nextCardsExpanded[groupIndex] = newValue;
                  setCardsExpanded(nextCardsExpanded);

                  if (isProgressTrackerEnabled) {
                    if (!newValue) {
                      // when collapsing a card
                      if (currentField) {
                        const groupIndexOfcurrentField =
                          fidToGroupIndex[currentField];
                        if (groupIndexOfcurrentField === groupIndex) {
                          // if current field is under the card, remove focus from current field
                          setCurrentField(undefined);
                        }
                      }
                    }
                  }
                }}
                group={group}
                project={project}
                callbackAfterExpanded={() => {
                  focusCurrentFieldAfterExpanded(groupIndex);
                }}
                markedAsReviewedOrNot={markedAsReviewedOrNot}
                onBlur={onFieldBlur}
                onFocus={onFieldFocus}
                countTouched={countTouched}
              />
            ))}
          </Box>
          {(isProgressTrackerEnabled || isClioQuestionnaireEnabled) && (
            <Box>
              <Toolbar
                isProgressTrackerEnabled={isProgressTrackerEnabled}
                isProgressTrackerHidden={isProgressTrackerHidden}
                onProgressTrackerClick={handleProgressTrackerClick}
                isClioQuestionnaireEnabled={
                  isClioQuestionnaireEnabled &&
                  unmappedQuestionsData !== undefined &&
                  unmappedQuestionsData.length > 0
                }
                isUnmappedQuestionsHidden={isUnmappedQuestionsHidden}
                onUnmappedQuestionsClick={handleUnmappedQuestionsClick}
              />

              {isProgressTrackerEnabled && !isProgressTrackerHidden && (
                <ProgressTrackerWidget
                  widgetRef={progressTrackerRef}
                  key={lastExpandedCardIndex}
                  fieldName={getCurrentFieldLabel()}
                  totalFieldsCount={totalFieldsCount}
                  fieldsReviewedCount={getFieldReviewedCount()}
                  totalFilledFieldsCount={totalFilledFields.length}
                  onNextClick={onNextClick}
                  onReviewAgainClick={onReviewAgainClick}
                  onFieldNameClick={onFieldNameClick}
                  onGenerate={onGenerate}
                  isGenerating={isGenerating}
                  onClose={handleProgressTrackerClick}
                />
              )}

              {isClioQuestionnaireEnabled && !isUnmappedQuestionsHidden && (
                <UnmappedQuestionsWidget
                  data={unmappedQuestionsData}
                  isLoading={isUnmappedQuestionsLoading}
                  onClose={handleUnmappedQuestionsClick}
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
    </ProjectProvider>
  );
};
