import { useObserver } from 'mobx-react';
import useMst from '~/src/hooks/useMst';
import useResourcePagination from '~/src/hooks/useResourcePagination';

const useMattersPagination = () => {
  const { matter } = useMst((store) => ({
    matter: store.matter,
  }));

  const {
    loading,
    next,
    done,
    empty,
    page,
    search,
    setSearch,
    setSortBy,
    setOrder,
  } = useResourcePagination({
    fetch: matter.fetch,
    limit: 30,
    order: 'desc',
  });

  const listData = useObserver(() => {
    return matter.list.flat();
  });

  return {
    next,
    page,
    search,
    data: listData,
    empty,
    loading,
    done,

    setSearch,
    setSortBy,
    setOrder,
  };
};

export default useMattersPagination;
