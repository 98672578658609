import React from 'react';
import { Box } from '@mui/material';
import ToggleProgressTracker from './ToggleProgressTracker';
import ToggleUnmappedQuestions from './ToggleUnmappedQuestions';

type ToolbarProps = {
  isProgressTrackerEnabled: boolean;
  isProgressTrackerHidden: boolean;
  onProgressTrackerClick: () => void;
  isClioQuestionnaireEnabled: boolean;
  isUnmappedQuestionsHidden: boolean;
  onUnmappedQuestionsClick: () => void;
};

const Toolbar = ({
  isProgressTrackerEnabled,
  isProgressTrackerHidden,
  onProgressTrackerClick,
  isClioQuestionnaireEnabled,
  isUnmappedQuestionsHidden,
  onUnmappedQuestionsClick,
}: ToolbarProps) => {
  if (!isProgressTrackerEnabled && !isClioQuestionnaireEnabled) return null;

  return (
    <Box
      sx={(theme) => {
        let width = '50px';
        if (isProgressTrackerEnabled && isClioQuestionnaireEnabled) {
          width = '90px';
        }
        return {
          width,
          height: '40px',
          position: 'fixed',
          right: '32px',
          top: '80px',
          backgroundColor: theme.palette.background.paper,
          boxShadow: 2,
          borderRadius: '4px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        };
      }}
    >
      {isProgressTrackerEnabled && (
        <ToggleProgressTracker
          isProgressTrackerHidden={isProgressTrackerHidden}
          onProgressTrackerClick={onProgressTrackerClick}
        />
      )}

      {isClioQuestionnaireEnabled && (
        <ToggleUnmappedQuestions
          isUnmappedQuestionsHidden={isUnmappedQuestionsHidden}
          onUnmappedQuestionsClick={onUnmappedQuestionsClick}
        />
      )}
    </Box>
  );
};

export default Toolbar;
