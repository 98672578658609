import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as DeleteIcon } from '~/src/static/delete-icon.svg';

type DeleteIconButtonProps = {
  onClick: () => void;
};

export const DeleteIconButton = ({ onClick }: DeleteIconButtonProps) => {
  return (
    <div
      data-tip
      data-for="delete_tooltip"
      className="w-8 h-8 flex-grow-0 cursor-pointer justify-center rounded items-center hidden group-hover:flex hover:bg-gray-300"
      onClick={onClick}
      data-testid="delete-icon"
    >
      <DeleteIcon />
      <ReactTooltip id="delete_tooltip" place="bottom" effect="solid">
        Delete
      </ReactTooltip>
    </div>
  );
};
