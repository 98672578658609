/* Libraries */
import React, { useState } from 'react';
import { StyleSheet, css } from 'aphrodite';

/* Components */
import { components } from 'react-select';
import { X, Plus } from 'react-feather';
import SelectInput from '~/src/components/Inputs/SelectInput';

/* Hooks */
import useMst from '~/src/hooks/useMst';
import useMattersPagination from '~/src/hooks/useMattersPagination';
import { useCanSyncSingleMatter } from '~/src/hooks/useClio';

/* Configurations */
import { LAYOUT_MODAL_TYPES } from '~/src/components/PageLayout/Modals';
import theme from '~/src/theme';
import { useLayoutContext } from '~/src/contexts/Layout';

import {
  useClioMatters,
  useImportFromClioModal,
} from '~/src/entities/clioMatters';
import analyticsService from '~/src/services/analytics';

import { Chip } from '@mui/material';
import { filterMattersList } from '~/src/components/Inputs/MatterSelect/MatterSelectUtil';

// enforce creation for matter

const styles = StyleSheet.create({
  inputIcon: {
    paddingLeft: '10px',
  },
  icon: {
    marginBottom: '-1px',
    paddingRight: '10px',
  },
  createNewMatter: {
    display: 'block',
    alignItems: 'center',
    padding: '10px',
    color: theme.colors.bluebird,
    borderBottom: theme.border,
  },
  clioMatterOption: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  clioMatterOptionLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'block',
    paddingRight: 10,
  },
});

const ClearIndicator = (props) => {
  const {
    children = <X size={14} color={theme.colors.charcoal} />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;

  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles('clearIndicator', props)}
    >
      <div style={{ padding: '0px 5px' }}>{children}</div>
    </div>
  );
};

const ClearIndicatorStyles = (base) => ({
  ...base,
  cursor: 'pointer',
});
const NotImportedMatter = (clioMatter) => (
  <div className={css(styles.clioMatterOption)}>
    <span className={css(styles.clioMatterOptionLabel)}>
      {clioMatter.display_number}: {clioMatter.description || ''}
    </span>
    <Chip label={'From Clio Manage'} />
  </div>
);

const MatterSelect = (props) => {
  const {
    label,
    required,
    hint,
    onBlur,
    onFocus,
    editable,
    isCreatable,
    value,
    onChange,
  } = props;

  const { createNewMatter } = useMst((store) => ({
    createNewMatter: store.matter.createNewMatter,
  }));

  const { hideModal, showModal } = useLayoutContext();
  const [key, setKey] = useState('select-key');
  const analyticsEventName = 'Single Matter Import From Matter Select';
  const [{ show: showImportFromClioModal }, importFromClioModal] =
    useImportFromClioModal(
      analyticsEventName,
      {
        onMutate: ({ clio_matter_id }) => {
          analyticsService.track(`${analyticsEventName}- Initiated`, {
            clioMatterId: clio_matter_id,
          });
        },
      },
      { allowSearch: false, onMatterSelect: onChange },
    );

  const handleCreateNewMatter = () => {
    const handleCreateMatter = async (form) => {
      const matterName = form.fields.name.value;

      try {
        const newMatterIdentifier = await createNewMatter(matterName);
        const newmatterId = newMatterIdentifier.split(':')[1];
        onChange(`${newmatterId}`);
      } catch (error) {
        console.error('Failed to create new matter', error);
      }

      hideModal();
    };

    showModal(LAYOUT_MODAL_TYPES.formField, {
      title: 'Create new matter',
      primaryActionTitle: 'Create',
      primaryActionInProcessTitle: 'Creating...',
      fields: [
        {
          label: 'Name',
          id: 'name',
          type: 'text',
          validation: ({ value }) => !!value && value.trim().length > 0,
        },
      ],
      onConfirm: handleCreateMatter,
    });
  };

  const MenuList = (props) => {
    return (
      <components.MenuList {...props}>
        <div
          className={css(styles.createNewMatter)}
          onClick={handleCreateNewMatter}
        >
          <i className={css(styles.icon)}>
            <Plus color={theme.colors.bluebird} size={12} />
          </i>
          <span>Create new matter</span>
        </div>
        {props.children}
      </components.MenuList>
    );
  };

  const { data, setSearch, search: debouncedSearch } = useMattersPagination();

  const clioMatters = useClioMatters(
    {
      query: debouncedSearch,
      fields: [
        'description',
        'display_number',
        'open_date',
        'id',
        'responsible_attorney{name}',
      ],
      order: 'updated_at(desc)',
      limit: 20,
    },
    { enabled: useCanSyncSingleMatter() },
  );

  const handleInputChange = (value, { action }) => {
    if (action === 'input-change') {
      setSearch(value);
    }
  };

  const options = data.map((option) => ({
    ...option,
    value: `${option.id}`,
    label: option.fullTitle(),
  }));

  const notImportedMatters = (clioMatters.data || [])
    .filter(({ already_imported }) => !already_imported)
    .map((clioMatter) => ({
      clioMatter,
      value: `clio:${clioMatter.id}`,
    }));

  return (
    <>
      <SelectInput
        key={key}
        hint={hint}
        label={label}
        required={required}
        onBlur={onBlur}
        onFocus={onFocus}
        cssStyle={styles.input}
        placeholder="Search for name"
        customComponents={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
          ClearIndicator,
          MenuList: isCreatable ? MenuList : components.MenuList,
        }}
        customStyles={{
          clearIndicator: ClearIndicatorStyles,
        }}
        onInputChange={handleInputChange}
        value={value}
        onChange={(id, action) => {
          if (id?.startsWith('clio')) {
            setKey(Date.now());
            showImportFromClioModal(
              notImportedMatters.find(({ value }) => value === id)?.clioMatter,
            );
            return;
          }
          onChange(id, action);
        }}
        isClearable={true}
        editable={editable}
        options={[...notImportedMatters, ...options]}
        formatOptionLabel={(option) =>
          option.clioMatter ? (
            <NotImportedMatter {...option.clioMatter} />
          ) : (
            option.label
          )
        }
        filterOption={filterMattersList}
        menuPortalTarget={document.body}
        styles={{
          menu: (provided) => ({ ...provided, zIndex: 9999, fontSize: '14px' }),
        }}
      />

      {importFromClioModal}
    </>
  );
};

MatterSelect.defaultProps = {
  isCreatable: true,
};

export default MatterSelect;
