import { TemplateDefaultValues, PdfTemplateTagInstance } from '~/src/models';
import { GenerateDocuments } from '@clio/hancock';
import {
  CheckBox,
  TextArea,
  TextBox,
  Xfdf,
} from '@clio/hancock/dist/generate/schema';

const MAGIC_DIVISOR = 1.34;
// These are magic values from `useBackend.js`:
const MAGIC_TEXTBOX_HEIGHT_MULTIPLIER = 0.95;
const MAGIC_CHECKBOX_HEIGHT_MULTIPLIER = 0.95;
const MAGIC_COORDINATE_MULTIPLIER = 1.005;
const MAGIC_TEXTAREA_HEIGHT_MULTIPLIER = 1.05;

const magicHancockAnnotationPlacementAdjustment = (
  startingCoordinateValue: number | undefined,
) => {
  // This function takes in a x (left) or y (top) coordinate and
  // uses a magic offset position to generate a more accurate represenation
  // of where the annotation should be
  if (typeof startingCoordinateValue != 'number') {
    return startingCoordinateValue;
  }
  return (
    (startingCoordinateValue / MAGIC_DIVISOR) * MAGIC_COORDINATE_MULTIPLIER
  );
};

const magicAnnotationWidthAdjustment = (width: number | undefined) => {
  // This funciton takes in a width and uses a divisor to give a more accurate
  // representation of what the width should be
  if (typeof width != 'number') {
    return width;
  }
  return width / MAGIC_DIVISOR;
};

const magicAnnotationTextBoxHeightAdjustment = (height: number | undefined) => {
  // This funciton takes in a height and uses a divisor to give a more accurate
  // representation of what the height should be for a texbox

  if (typeof height != 'number') {
    return height;
  }
  return (height / MAGIC_DIVISOR) * MAGIC_TEXTBOX_HEIGHT_MULTIPLIER;
};

const magicAnnotationCheckBoxHeightAdjustment = (
  height: number | undefined,
) => {
  // This funciton takes in a height and uses a divisor to give a more accurate
  // representation of what the height should be for a texbox

  if (typeof height != 'number') {
    return height;
  }
  return (height / MAGIC_DIVISOR) * MAGIC_CHECKBOX_HEIGHT_MULTIPLIER;
};

const magicAnnotationTextAreaHeightAdjustment = (
  height: number | undefined,
) => {
  // This funciton takes in a height and uses a divisor to give a more accurate
  // representation of what the height should be for a multiline/textarea field

  if (typeof height != 'number') {
    return height;
  }
  return (height / MAGIC_DIVISOR) * MAGIC_TEXTAREA_HEIGHT_MULTIPLIER;
};

const initializeTextBoxTypeElement = () => {
  const textBoxElement: TextBox = {
    x: 1,
    y: 1,
    height: 1,
    width: 1,
    pageNumber: 1,
    content: '',
  };

  return textBoxElement;
};

const initializeCheckBoxTypeElement = () => {
  const checkBoxElement: CheckBox = {
    x: 1,
    y: 1,
    height: 1,
    width: 1,
    pageNumber: 1,
    state: 'Off',
  };

  return checkBoxElement;
};

const initializeButtonTypeElement = () => {
  const buttonElement: CheckBox = {
    x: 1,
    y: 1,
    height: 1,
    width: 1,
    pageNumber: 1,
    state: 'Off',
  };

  return buttonElement;
};

const initializeMultiLineTypeElement = () => {
  const textBoxElement: TextArea = {
    x: 1,
    y: 1,
    height: 1,
    width: 1,
    pageNumber: 1,
    content: '',
    lineHeight: 18,
  };

  return textBoxElement;
};

const initializeCustomElement = () => {
  const textBoxElement: Xfdf = {
    xfdf: '',
  };

  return textBoxElement;
};

const mapTemplateDefaultValuesToHancockDocument = (
  templateDefaultValuesResponse: TemplateDefaultValues,
  customStringPrefix: string = 'customField_',
): GenerateDocuments => {
  const templateDefaultValuesPDF =
    templateDefaultValuesResponse.template.originalFile;

  const allTemplateDefaultValuesElements =
    templateDefaultValuesResponse.pdfTemplateTagInstances;

  const templateCheckboxes: CheckBox[] = [];
  const templateTextboxes: TextBox[] = [];
  const templateButtons: CheckBox[] = [];
  const templateMultiLine: TextArea[] = [];
  const templateCustomElement: Xfdf[] = [];

  const addCommonHancockElements = (
    singleFieldElement: any, // TODO: set as baseclass in hancock?
    pdfTemplateTagInstance: PdfTemplateTagInstance,
    index: number,
    magicWidthAdjustment: Function = (width: any) => {
      // by default function to pass through width value
      return width;
    },
    magicHeightAdjustment: Function = (height: any) => {
      // by default function to pass through height value
      return height;
    },
  ) => {
    singleFieldElement.x = magicHancockAnnotationPlacementAdjustment(
      pdfTemplateTagInstance.left,
    );
    singleFieldElement.y = magicHancockAnnotationPlacementAdjustment(
      pdfTemplateTagInstance.top,
    );
    singleFieldElement.height = magicHeightAdjustment(
      pdfTemplateTagInstance.height,
    );
    singleFieldElement.width = magicWidthAdjustment(
      pdfTemplateTagInstance.width,
    );
    singleFieldElement.pageNumber = pdfTemplateTagInstance.pageNumber;

    const pdfElementID: number = pdfTemplateTagInstance?.pdfElementID;

    // This metadata allows us to store the pdfElementID (a unique id for the field)
    // We can then use this field for saving/loading the values object of template default values
    // because the values object uses the pdfElementID as a key
    singleFieldElement.metadata = {
      tabIndex: (
        index +
        1000 * (pdfTemplateTagInstance.pageNumber - 1)
      ).toString(),
      pdfElementID: pdfElementID.toString(),
    };
    if (
      pdfTemplateTagInstance.tagStr ||
      pdfTemplateTagInstance.computedFieldTag
    ) {
      singleFieldElement.metadata.CUSTOM_CSS = `{"background": "rgba(132, 87, 255, 0.1)"}`;
      singleFieldElement.metadata.tagString =
        pdfTemplateTagInstance.tagStr ||
        pdfTemplateTagInstance.computedFieldTag;
    }
    return singleFieldElement;
  };

  const cleanContentValue = (valueOfElementToBeSet: string | undefined) => {
    if (typeof valueOfElementToBeSet === 'undefined') {
      return '';
    }
    return valueOfElementToBeSet;
  };

  const cleanStateValue = (valueOfElementToBeSet: string | undefined) => {
    // Takes in a value to be set for a checkbox, if it is not equal to 'Yes', we force it to be off
    if (valueOfElementToBeSet != 'Yes') {
      return 'Off';
    }
    return valueOfElementToBeSet;
  };

  const valuesLookupObject: { [key: string]: string } =
    templateDefaultValuesResponse.values || {};

  allTemplateDefaultValuesElements.forEach(
    (pdfTemplateTagInstance: PdfTemplateTagInstance, index: number) => {
      let singleTextboxElement = initializeTextBoxTypeElement();
      let singleCheckboxElement = initializeCheckBoxTypeElement();
      let singleButtonElement = initializeButtonTypeElement();
      let singleMultiLineElement = initializeMultiLineTypeElement();

      // valueOfElementToBeSet can be undefined if there is no value for that field
      const valueOfElementToBeSet: string | undefined = valuesLookupObject
        ? valuesLookupObject[pdfTemplateTagInstance?.pdfElementID.toString()]
        : undefined;

      switch (pdfTemplateTagInstance.input_type_from_pdf) {
        case 1:
          // Textbox
          singleTextboxElement.fontSize = 0;
          singleTextboxElement.content = cleanContentValue(
            valueOfElementToBeSet,
          );
          singleTextboxElement = addCommonHancockElements(
            singleTextboxElement,
            pdfTemplateTagInstance,
            index,
            magicAnnotationWidthAdjustment,
            magicAnnotationTextBoxHeightAdjustment,
          );
          templateTextboxes.push(singleTextboxElement);
          break;

        // Checkbox
        case 2:
          singleCheckboxElement.state = cleanStateValue(valueOfElementToBeSet);
          singleCheckboxElement = addCommonHancockElements(
            singleCheckboxElement,
            pdfTemplateTagInstance,
            index,
            magicAnnotationWidthAdjustment,
            magicAnnotationCheckBoxHeightAdjustment,
          );
          templateCheckboxes.push(singleCheckboxElement);
          break;

        // Button
        case 3:
          singleButtonElement.state = cleanStateValue(valueOfElementToBeSet);
          singleButtonElement = addCommonHancockElements(
            singleButtonElement,
            pdfTemplateTagInstance,
            index,
            magicAnnotationWidthAdjustment,
            magicAnnotationCheckBoxHeightAdjustment,
          );
          templateButtons.push(singleButtonElement);
          break;

        // Multi Line/Text Area
        case 4:
          singleMultiLineElement.fontSize = 9;
          singleMultiLineElement.content = cleanContentValue(
            valueOfElementToBeSet,
          );
          singleMultiLineElement.lineHeight =
            pdfTemplateTagInstance?.lineHeight ||
            singleMultiLineElement.lineHeight;
          singleMultiLineElement = addCommonHancockElements(
            singleMultiLineElement,
            pdfTemplateTagInstance,
            index,
            magicAnnotationWidthAdjustment,
            magicAnnotationTextAreaHeightAdjustment,
          );
          templateMultiLine.push(singleMultiLineElement);
          break;

        // Signature Block
        case 5:
          break;
        // Signature Date
        case 6:
          break;

        default:
          console.error('Input Type not found: ', pdfTemplateTagInstance);
          break;
      }
    },
  );

  const keysInValuesLookupObject = Object.keys(valuesLookupObject);

  keysInValuesLookupObject.forEach((key) => {
    const customFieldElement: Xfdf = initializeCustomElement();
    if (
      key.startsWith(customStringPrefix) &&
      typeof valuesLookupObject[key] === 'string'
    ) {
      customFieldElement.xfdf = valuesLookupObject[key] || '';
      templateCustomElement.push(customFieldElement);
    }
  });

  const document: GenerateDocuments = {
    documentPath: templateDefaultValuesPDF,
    checkboxes: templateCheckboxes,
    textboxes: templateTextboxes,
    textareas: templateMultiLine,
    xfdfs: templateCustomElement,
  };

  return document;
};

export default mapTemplateDefaultValuesToHancockDocument;
