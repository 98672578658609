import React, { useCallback, useState } from 'react';
import { CourtFormsModal, CourtFormsModalProps } from './CourtFormsModal';

type UseCourtFormsModalProps = Omit<CourtFormsModalProps, 'open' | 'onClose'>;

export const useCourtFormsModal = (props: UseCourtFormsModalProps) => {
  const [open, setOpen] = useState(false);

  const toggle = useCallback(() => setOpen((o) => !o), []);

  const modal = <CourtFormsModal {...props} open={open} onClose={toggle} />;

  return [toggle, modal] as const;
};
