import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { startCase, values } from 'lodash';
import EntityFieldCard from '~/src/components/EntityFieldCard';

const VariablesEntityFieldCardsList = (props) => {
  const { variables, selectedField, onFieldClick } = props;

  const [cards, setCards] = useState([]);

  useEffect(() => {
    const cardLabelMap = {};

    variables.forEach((variable) => {
      const { tag } = variable;

      let entityId = null;

      // TODO: Refactor, super hacky AJR 05/06/2021
      if (variable.entityLabel) {
        entityId = variable.entityLabel;
        // variable.prompt = variable.fieldLabel;
        // variable.source = variable.source;
        // variable.type = variable.fieldType;
      } else {
        const splitTag = tag.split('.');
        entityId = splitTag[0];
      }

      if (!cardLabelMap[entityId]) {
        cardLabelMap[entityId] = {
          label: startCase(entityId),
          fields: [variable],
        };
      } else {
        cardLabelMap[entityId].fields.push(variable);
      }
    });

    const cardArray = values(cardLabelMap);
    setCards([...cardArray]);
  }, [variables]);

  return (
    <div style={{ marginTop: '16px' }}>
      {cards.map((card) => (
        <EntityFieldCard
          key={card.label}
          label={card.label}
          fields={card.fields}
          onFieldClick={onFieldClick}
          selectedField={selectedField}
        />
      ))}
    </div>
  );
};

VariablesEntityFieldCardsList.propTypes = {
  variables: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string,
      type: PropTypes.string,
      prompt: PropTypes.string,
      tag: PropTypes.string,
    }),
  ).isRequired,
  onFieldClick: PropTypes.func,
};

export default VariablesEntityFieldCardsList;
