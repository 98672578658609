import { types, flow, getRoot } from 'mobx-state-tree';

import templateDefaultValuesService from '~/src/services/templateDefaultValues';
import createPaginatedModel from '~/src/stores/composers/createPaginatedModel';

import { APIError } from '~/src/utils/error';
import { getTemplateDefaultValuesIdentifier } from '~/src/utils/dataTransformers';
import { getOrgFprintFromStoreNode } from './utils';

const TemplateDefaultValuesStore = types.model('TemplateDefaultValuesStore', {
  identifier: types.identifier,
  id: types.number,
  title: types.maybeNull(types.string),
  values: types.maybeNull(types.map(types.frozen({}))),
  organizationId: types.number,
  template: types.map(types.frozen({})),
  pdfTemplateTagInstances: types.array(types.map(types.frozen({}))),
  createdAt: types.string,
  lastModified: types.string,
});

const TemplateDefaultValuesListStore = types
  .model('TemplateDefaultValuesListStore', {
    isLoading: false,
    error: '',
  })
  .actions((self) => {
    const getTemplateDefaultValuesList = flow(function* (query = {}) {
      return yield self.paginate(query);
    });
    const createTemplateDefaultValues = flow(function* (templateId) {
      const newTemplateDefaultValues =
        yield templateDefaultValuesService.createTemplateDefaultValues(
          getOrgFprintFromStoreNode(self),
          templateId,
        );
      self.addItem(
        newTemplateDefaultValues.identifier,
        newTemplateDefaultValues,
      );
      if (self.list && self.list[0]) {
        self.list[0].unshift(newTemplateDefaultValues.identifier);
      } else {
        self.list = [[newTemplateDefaultValues.identifier]];
      }
      return newTemplateDefaultValues;
    });
    const getTemplateDefaultValues = flow(function* (templateDefaultValuesId) {
      self.isLoading = true;

      const templateDefaultValues =
        yield templateDefaultValuesService.getTemplateDefaultValues(
          getOrgFprintFromStoreNode(self),
          templateDefaultValuesId,
        );
      self.isLoading = false;
      return templateDefaultValues;
    });
    const getTemplateDefaultValuesByIds = flow(function* (
      templateDefaultValuesIds,
    ) {
      self.isLoading = true;

      const templateDefaultValues =
        yield templateDefaultValuesService.getTemplateDefaultValuesByIds(
          getOrgFprintFromStoreNode(self),
          templateDefaultValuesIds,
        );
      self.isLoading = false;
      return templateDefaultValues;
    });

    const checkTemplateDefaultValuesExistInTemplateSets = flow(function* (
      templateDefaultValuesId,
    ) {
      self.isLoading = true;

      const relatedTemplateSetsCount =
        yield templateDefaultValuesService.checkTemplateDefaultValuesExistInTemplateSets(
          getOrgFprintFromStoreNode(self),
          templateDefaultValuesId,
        );
      self.isLoading = false;
      return relatedTemplateSetsCount.count;
    });

    const submitTemplateDefaultValues = flow(function* (
      type,
      { id, title, values },
    ) {
      self.isLoading = true;
      switch (type) {
        case 'update':
          try {
            const updatedTemplateDefaultValues =
              yield templateDefaultValuesService.updateTemplateDefaultValues(
                getOrgFprintFromStoreNode(self),
                id,
                title,
                values,
              );
            self.dictionary.set(
              updatedTemplateDefaultValues.identifier,
              updatedTemplateDefaultValues,
            );
            self.isLoading = false;
            return true;
          } catch (error) {
            self.isLoading = false;
            if (error instanceof APIError) {
              self.error = error.message;
            } else if (typeof error === 'string') {
              self.error = error;
            } else {
              self.error = 'Something went wrong!';
            }
            console.error('Failed to update TemplateDefaultValues: ', error);
            return false;
          }
        case 'delete':
          try {
            yield templateDefaultValuesService.deleteTemplateDefaultValues(
              getOrgFprintFromStoreNode(self),
              id,
            );
            self.removeItem(getTemplateDefaultValuesIdentifier(id));
            self.isLoading = false;
            return true;
          } catch (error) {
            self.isLoading = false;
            console.error('Failed to delete TemplateDefaultValues: ', error);
            return false;
          }
        case 'duplicate':
          try {
            const newTemplateDefaultValues =
              yield templateDefaultValuesService.duplicateTemplateDefaultValues(
                getOrgFprintFromStoreNode(self),
                id,
                title,
              );
            self.addItem(
              newTemplateDefaultValues.identifier,
              newTemplateDefaultValues,
            );
            if (self.list && self.list[0]) {
              self.list[0].unshift(newTemplateDefaultValues.identifier);
            } else {
              self.list = [[newTemplateDefaultValues.identifier]];
            }
            self.isLoading = false;
            return { success: true, newId: newTemplateDefaultValues.id };
          } catch (error) {
            self.isLoading = false;
            if (error instanceof APIError) {
              self.error = error.message;
            } else if (typeof error === 'string') {
              self.error = error;
            } else {
              self.error = 'Something went wrong!';
            }
            console.error('Failed to duplicate TemplateDefaultValues: ', error);
            return { success: false, newId: null };
          }
        default:
      }
    });

    const addFormTemplateToSelectionSidebar = (templateDefaultValues) => {
      const template = templateDefaultValues.template.toJSON();
      getRoot(self).sidebarItems.add({
        id: `${template.docId}+${templateDefaultValues.id}`,
        defaultValueId: templateDefaultValues.id,
        title: templateDefaultValues.title,
      });
    };

    const clearError = () => {
      self.error = '';
    };
    return {
      createTemplateDefaultValues,
      getTemplateDefaultValues,
      getTemplateDefaultValuesByIds,
      getTemplateDefaultValuesList,
      submitTemplateDefaultValues,
      addFormTemplateToSelectionSidebar,
      checkTemplateDefaultValuesExistInTemplateSets,
      clearError,
    };
  })
  .views((self) => {
    function selectOptions() {
      return Array.from(self.dictionary.values()).map(
        (templateDefaultValues) => ({
          value: `${templateDefaultValues.template.toJSON().docId} ${
            templateDefaultValues.id
          }`,
          label: templateDefaultValues.title,
        }),
      );
    }

    function getTemplateDefaultValuesFromStore(templateDefaultValuesId) {
      return self.dictionary.get(
        getTemplateDefaultValuesIdentifier(templateDefaultValuesId),
      );
    }

    function getTemplateDefaultValuesListFromStore(templateDefaultValuesIds) {
      return templateDefaultValuesIds
        .map((id) =>
          self.dictionary.get(getTemplateDefaultValuesIdentifier(id)),
        )
        .filter((value) => value != undefined);
    }

    return {
      selectOptions,
      getTemplateDefaultValuesFromStore,
      getTemplateDefaultValuesListFromStore,
    };
  });

const PaginatedTemplateDefaultValuesListStore = createPaginatedModel(
  'PaginatedTemplateDefaultValuesListStore',
  TemplateDefaultValuesListStore,
  TemplateDefaultValuesStore,
  { paginate: templateDefaultValuesService.getTemplateDefaultValuesList },
);

export {
  PaginatedTemplateDefaultValuesListStore as default,
  TemplateDefaultValuesStore,
};
