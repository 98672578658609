import React, { FC } from 'react';

// Components

interface Props {
  children: React.ReactNode;
}

const FederalSection: FC<Props> = ({ children }) => {
  return (
    <div className="my-5">
      <div className="p-2 font-medium">Federal</div>

      <div className="flex flex-col bg-white rounded-lg overflow-hidden border border-slate-200">
        {children}
      </div>
    </div>
  );
};

export default FederalSection;
