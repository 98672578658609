import React, { useCallback, useState } from 'react';
import { UpdateSeatsModal, UpdateSeatsModalProps } from './UpdateSeatsModal';

type UseUpdateSeatsModalProps = Omit<UpdateSeatsModalProps, 'open' | 'onClose'>;

export const useUpdateSeatsModal = (props: UseUpdateSeatsModalProps) => {
  const [open, setOpen] = useState(false);

  const toggle = useCallback(() => setOpen((o) => !o), []);

  const modal = <UpdateSeatsModal {...props} open={open} onClose={toggle} />;

  return [toggle, modal] as const;
};
