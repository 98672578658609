/* Hooks */
import useMst from '~/src/hooks/useMst';

/* Constants */
import { VALIDATION_TYPES } from '~/src/utils/validation';
import { LAYOUT_MODAL_TYPES } from '~/src/components/PageLayout/Modals';
import { LAYOUT_SLIDEIN_TYPES } from '~/src/components/PageLayout/SlideIns';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import { useLayoutContext } from '../contexts/Layout';
import { history } from '../utils/history';
import analyticsService from '~/src/services/analytics';
import useFeatureAccesses from '~/src/hooks/useFeatureAccesses';

const useDocumentsActions = (isDrafting) => {
  const {
    deleteDocument,
    deleteDocumentFile,
    updateDocument,
    updateDocumentMetadata,
    deleteDocumentFromMatters,
    getProject,
    fetchProject,
    duplicateDocument,
    projects,
    sidebarItems,
  } = useMst((store) => ({
    documents: store.documents,
    deleteDocumentFromMatters: store.matter.deleteDocument,
    deleteDocument: store.documents.deleteDocument,
    deleteDocumentFile: store.documents.deleteDocumentFile,
    updateDocument: store.documents.updateDocument,
    updateDocumentMetadata: store.documents.updateDocumentMetadata,
    getProject: store.projects.getProject,
    fetchProject: store.projects.fetchProjectData,
    duplicateDocument: store.projects.duplicateDocument,
    projects: store.projects,
    sidebarItems: store.sidebarItems,
  }));

  const { showSlideIn, showToast, showModal, hideModal, setTitle } =
    useLayoutContext();

  const { isCanary } = useFeatureAccesses();

  const getOrFetchDocument = async (document) => {
    let project = getProject(document.id);

    if (!project) {
      project = await fetchProject(document.id, document.title);
    }

    return project;
  };

  /* Actions */

  /* delete document in matter detail page or document listing page */
  const handleDeleteMatterDocument = (document) => {
    const handleDeleteConfirm = async () => {
      await deleteDocumentFromMatters(document.id);

      const success = await deleteDocument(document.id);

      if (success) {
        showToast(LAYOUT_TOAST_TYPES.success, {
          message: `Document "${document.title}" deleted.`,
        });
      } else {
        showToast(LAYOUT_TOAST_TYPES.error);
      }

      hideModal();
      return document;
    };

    showModal(LAYOUT_MODAL_TYPES.confirm, {
      title: `Delete Document "${document.title}"?`,
      message: `Are you sure you want to permanently delete this document? This action cannot be undone.`,
      onConfirm: handleDeleteConfirm,
      primaryActionTitle: 'Delete',
      processingText: 'Deleting...',
    });
  };

  /* delete document in side bar in drafting flow */
  const handleDeleteProjectDocument = (document) => {
    const handleDeleteConfirm = async () => {
      if (document.templateId) {
        // `PUT /api/v2/org/{orgFprint}/project/{projectId}/document/` has already been fired
        const success = await deleteDocumentFile(document.id);

        if (success) {
          if (isDrafting && !isCanary) {
            // isDrafting check is to make sure we are on drafting page before we do a project refresh

            // refreshProject connects to the backend and gets the latest copy of the project to update the store
            const refreshSuccess = await fetchProject(
              projects.curProjectId,
              true,
            );

            if (!refreshSuccess) {
              console.error('Refresh after Delete Failed');
            }
          } else if (isDrafting && isCanary) {
            // We don't want to immediately fetch the project from the backend, but do want to show the users that the document has been removed
            sidebarItems.remove(document);
            sidebarItems.setFocusOnSidebarItemFromIndex(0);
          }
          showToast(LAYOUT_TOAST_TYPES.success, {
            message: `Document "${document.title}" deleted.`,
          });
        } else {
          showToast(LAYOUT_TOAST_TYPES.error);
        }
      } else {
        // `PUT /api/v2/org/{orgFprint}/project/{projectId}/document/` has NOT yet been fired
        try {
          sidebarItems.remove(document);
          showToast(LAYOUT_TOAST_TYPES.success, {
            message: `Document "${document.title}" removed.`,
          });
        } catch (e) {
          console.error('Error removing document from sidebar:', e, {
            target: document,
          });
        }
      }
      hideModal();
      return document;
    };

    showModal(LAYOUT_MODAL_TYPES.confirm, {
      title: `Delete Document "${document.title}"?`,
      message: `Are you sure you want to permanently delete this document? This action cannot be undone.`,
      onConfirm: handleDeleteConfirm,
      primaryActionTitle: 'Delete',
      processingText: 'Deleting...',
    });
  };

  const handleDownloadDocument = async (document) => {
    const handleDownload = async (showHighlights) => {
      setTitle('Download Document');
      const project = await getOrFetchDocument(document);
      const success = project.exportDoc(showHighlights);
      if (success) {
        showToast(LAYOUT_TOAST_TYPES.success, {
          message: `Document "${document.title}" downloaded.`,
        });
      } else {
        showToast(LAYOUT_TOAST_TYPES.error);
      }

      hideModal();
    };

    if (document.processedDocx) {
      // Document was generated after highlight toggle was implemented
      showModal(LAYOUT_MODAL_TYPES.downloadDocument, {
        documentTitle: document.title,
        onConfirm: handleDownload,
      });
    } else {
      // Document only has raw populated version, no need for prompt
      handleDownload(true);
    }
  };

  const handleEditDocument = async (document) => {
    setTitle('Edit Document');
    const project = await getOrFetchDocument(document);

    if (project) {
      analyticsService.track('Document Edited', {
        projectId: project.id,
        documentId: document.id,
      });
      history.push(`/populate/${project.id}`);
    }
  };

  const handleViewDocument = async (document) => {
    setTitle('View Document');
    const project = await getOrFetchDocument(document);
    if (project) {
      showSlideIn(LAYOUT_SLIDEIN_TYPES.projectPreview, {
        largeSlideIn: true,
        projectId: project.id,
      });
    }
  };

  /* rename document in matter detail page or document listing page */
  const handleRenameMatterDocument = async (document) => {
    const handleRenameConfirm = async (form) => {
      const { value } = form.fields.name;
      const success = await updateDocument(document.id, { title: value });

      if (success) {
        showToast(LAYOUT_TOAST_TYPES.success, {
          message: `Document "${value}" renamed successfully.`,
        });
      } else {
        showToast(LAYOUT_TOAST_TYPES.error);
      }

      hideModal();
    };

    showModal(LAYOUT_MODAL_TYPES.formField, {
      title: 'Rename Document',
      primaryActionTitle: 'Rename',
      fields: [
        {
          label: 'Name',
          id: 'name',
          type: 'rename',
          defaultValue: document.title,
          validation: VALIDATION_TYPES.renameDocument,
          documentNamesObject: {},
        },
      ],
      onConfirm: handleRenameConfirm,
    });
  };

  /* rename document in side bar in drafting flow */
  const handleRenameProjectDocument = async (document) => {
    const handleRenameConfirm = async (form) => {
      const { value } = form.fields.name;
      const success = await updateDocumentMetadata(document.id, {
        title: value,
      });

      if (success) {
        showToast(LAYOUT_TOAST_TYPES.success, {
          message: `Document "${value}" renamed successfully.`,
        });
      } else {
        showToast(LAYOUT_TOAST_TYPES.error);
      }

      hideModal();
    };

    const dictionary_of_all_document_names = {};

    if (isDrafting) {
      // console.log(getProject(projects.curProjectId));
      getProject(projects.curProjectId).documents.forEach(
        (documentAlreadyInProject) => {
          if (documentAlreadyInProject.title != document.title) {
            if (
              documentAlreadyInProject.title in dictionary_of_all_document_names
            ) {
              dictionary_of_all_document_names[
                documentAlreadyInProject.title
              ] += 1;
            } else {
              dictionary_of_all_document_names[
                documentAlreadyInProject.title
              ] = 1;
            }
          }
        },
      );
    }

    showModal(LAYOUT_MODAL_TYPES.formField, {
      title: 'Rename Document',
      primaryActionTitle: 'Rename',
      fields: [
        {
          label: 'Name',
          id: 'name',
          type: 'rename',
          defaultValue: document.title,
          validation: VALIDATION_TYPES.renameDocument,
          documentNamesObject: dictionary_of_all_document_names,
        },
      ],
      onConfirm: handleRenameConfirm,
    });
  };

  const handleDuplicateDocument = async (document) => {
    const handleDuplicateConfirm = async (form) => {
      const success_await = await duplicateDocument(
        projects.curProjectId,
        document.id,
        form.fields?.number_of_documents_to_duplicate.value,
      );

      if (success_await) {
        showToast(LAYOUT_TOAST_TYPES.success, {
          message: `Document duplicated successfully`,
        });
      } else {
        showToast(LAYOUT_TOAST_TYPES.error);
      }

      hideModal();
    };

    showModal(LAYOUT_MODAL_TYPES.formField, {
      title: 'Duplicate Document',
      primaryActionTitle: 'Duplicate',
      analyticsName: 'Document Duplicated',
      fields: [
        {
          id: 'duplicate_modal_name',
          type: 'staticText',
          defaultValue: '',
          label: document.title,
        },
        {
          label: 'Number of Copies',
          id: 'number_of_documents_to_duplicate',
          type: 'duplicate_document',
          defaultValue: '1',
          validation:
            VALIDATION_TYPES.numeric &&
            VALIDATION_TYPES.duplicate_document_limit,
        },
      ],
      onConfirm: handleDuplicateConfirm,
    });
  };

  return {
    deleteMatterDocument: handleDeleteMatterDocument,
    deleteProjectDocument: handleDeleteProjectDocument,
    editDocument: handleEditDocument,
    viewDocument: handleViewDocument,
    renameMatterDocument: handleRenameMatterDocument,
    renameProjectDocument: handleRenameProjectDocument,
    downloadDocument: handleDownloadDocument,
    duplicateDocument: handleDuplicateDocument,
  };
};

export default useDocumentsActions;
