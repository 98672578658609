import React from 'react';
import { Box, Typography } from '@mui/material';
import {
  ProjectStackField,
  ProjectStackDocument,
} from '~/src/entities/project';
import { FieldHeaderCount } from './FieldHeaderCount';
import { FieldHeaderHint } from './FieldHeaderHint';
import { Edited, EditIconButton } from '~/src/components/Inputs/Edit';

export type FieldHeaderWithEditProps = {
  projectField: ProjectStackField;
  projectStackDocuments: ProjectStackDocument[];
  isEdited: boolean;
  isEditing: boolean;
  setIsEditing: (arg1: boolean) => void;
  canClose?: boolean | undefined;
};

export const FieldHeaderWithEdit = ({
  projectField,
  projectStackDocuments,
  isEdited,
  isEditing,
  setIsEditing,
  canClose,
}: FieldHeaderWithEditProps) => {
  return (
    <Box pb={2}>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Box flex={1}>
          <Typography variant="label">{projectField.label}</Typography>
        </Box>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box
            sx={() => ({ visibility: isEdited ? 'visible' : 'hidden' })}
            mr={1}
          >
            {isEdited ? <Edited data-testid="edited-chip" /> : null}
          </Box>
          <Box>
            <EditIconButton
              disabled={canClose === undefined ? false : !canClose}
              data-testid="edit-text-to-merge"
              isEditing={isEditing}
              sx={() => ({
                visibility: projectField.is_standalone_question
                  ? 'hidden'
                  : 'visible',
              })}
              onClick={() => setIsEditing(!isEditing)}
            />
          </Box>
          <Box pl={1}>
            <FieldHeaderCount
              projectField={projectField}
              projectStackDocuments={projectStackDocuments}
            />
          </Box>
        </Box>
      </Box>
      <Box>
        <FieldHeaderHint projectField={projectField} />
      </Box>
    </Box>
  );
};
