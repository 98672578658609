import {
  useCheckStateFormsEnabled,
  useClioQuestionnairesEnabled,
  useCourtFormsEnabled,
  useFeatureAccess,
  useFederalCourtFormsEnabled,
  useIsCanary,
  useIsClioFree,
  useIsMaintenanceBannerEnabled,
  useIsUnifiedPlatform,
  useQuestionnairesEnabled,
  useWordDocumentAutomationEnabled,
} from '../entities/user';

const useFeatureAccesses = () => {
  const isClioFreePlan = useIsClioFree();
  const isUnifiedPlatform = useIsUnifiedPlatform();
  const isCourtFormsEnabled = useCourtFormsEnabled();
  const doesFeatureAccessForThisStateCodeExist = useCheckStateFormsEnabled();
  const isQuestionnairesEnabled = useQuestionnairesEnabled();
  const isClioQuestionnaireEnabled = useClioQuestionnairesEnabled();
  const isWordDocumentAutomationEnabled = useWordDocumentAutomationEnabled();
  const isFederalCourtFormsEnabled = useFederalCourtFormsEnabled();
  const isAddinLongDocumentsEnabled = useFeatureAccess('ADDIN_LONG_DOCUMENTS');
  const isCanary = useIsCanary();
  const isMaintenanceBannerEnabled = useIsMaintenanceBannerEnabled();

  return {
    isClioFreePlan,
    doesFeatureAccessForThisStateCodeExist,
    isQuestionnairesEnabled,
    isClioQuestionnaireEnabled,
    isWordDocumentAutomationEnabled,
    isFederalCourtFormsEnabled,
    isCourtFormsEnabled,
    isUnifiedPlatform,
    isAddinLongDocumentsEnabled,
    isCanary,
    isMaintenanceBannerEnabled,
  };
};

export default useFeatureAccesses;
