import { useObserver } from 'mobx-react';
import useMst from '~/src/hooks/useMst';
import useResourcePagination from '~/src/hooks/useResourcePagination';

const useContactsPagination = () => {
  const { contacts } = useMst((store) => ({
    contacts: store.contacts,
  }));

  const { loading, empty, next, done, page, setSearch, setSortBy, setOrder } =
    useResourcePagination({
      fetch: contacts.fetch,
      limit: 10,
      order: 'desc',
    });

  const listData = useObserver(() => {
    return contacts.list.flat();
  });

  const allData = useObserver(() => {
    return Array.from(contacts.dictionary.keys()).map((key) =>
      contacts.dictionary.get(key),
    );
  });

  return {
    next,
    page,
    data: listData,
    allData,
    empty,
    loading,
    done,
    setSearch,
    setSortBy,
    setOrder,
  };
};

export default useContactsPagination;
