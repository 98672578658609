import React from 'react';
import { css } from 'aphrodite';

import { Link } from 'react-router-dom';
import { Text } from '~/src/components/Typography';
import { ReactComponent as SignaturesEmptyImage } from '~/src/static/SignaturesEmptyImage.svg';
import styles from '~/src/components/Button/styles';

const SignaturesEmpty = () => {
  return (
    <div className="flex flex-col justify-center items-center gap-8 flex-grow-0 text-center mt-20">
      <SignaturesEmptyImage />
      <div className="flex flex-col justify-center items-center gap-3 max-w-lg">
        <Text htmlElement="p" variant="display">
          Sign or send documents out for signatures in minutes
        </Text>

        <Text htmlElement="p" subdued>
          Learn more about
          <a
            className="ml-1"
            href="https://help.lawyaw.com/en/articles/5674035-signing-from-drafting"
          >
            signatures
          </a>
        </Text>
      </div>
      <Link
        className={css(styles.button, styles.buttonPrimary)}
        to="/custom-signature-package/new"
      >
        New signature
      </Link>
    </div>
  );
};

export default SignaturesEmpty;
