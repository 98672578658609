import React from 'react';
import { SummaryFormItem } from './SummaryFormItem';
import { BillingCycleType } from './types';
import { planPrice } from './utils';

type PlanItemProps = {
  name: React.ReactNode;
  price: number;
  cycle: BillingCycleType;
};

export const PlanItem = ({ name, price, cycle }: PlanItemProps) => (
  <SummaryFormItem label={name} price={planPrice(price, cycle)} />
);
