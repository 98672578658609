/* Libs */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useObserver } from 'mobx-react';
import {
  Square,
  CheckSquare,
  ChevronDown,
  ChevronRight,
  Folder,
} from 'react-feather';
import { snakeCase } from 'lodash';

/* Hooks */
import usePageScroll from '~/src/hooks/usePageScroll';
import useTemplateSetsPagination from '~/src/hooks/useTemplateSetsPagination';

/* Containers */
import Table from '~/src/containers/Table';

/* Components */
import LoadMoreDisplayText from '~/src/components/LoadMoreDisplayText';

/* Styles */

const ChooseTemplateSetTableContainer = (props) => {
  const { onChange } = props;

  const pageScroll = usePageScroll();

  const { next, loading, done, data, setSearch, setSortBy, empty, setOrder } =
    useTemplateSetsPagination();

  const [selectedTemplateSet, setSelectedTemplateSet] = useState(null);

  useEffect(() => {
    onChange(selectedTemplateSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplateSet]);

  const loadMore = next;
  const handleOnPageScroll = () => {
    if ((pageScroll === 0 || pageScroll >= 85) && !loading) {
      loadMore();
    }
  };

  const handleSearchChange = (searchValue) => {
    setSearch(searchValue);
  };

  const handleSortByChange = (property, order) => {
    if (!property) {
      return;
    }

    const orderLabel = order > 0 ? 'desc' : 'asc';
    let sortBy = snakeCase(property);

    // TODO: abstract this into a model/transformer lookup
    if (property === 'updatedAt') {
      sortBy = 'last_modified';
    }

    setSortBy(sortBy);
    setOrder(orderLabel);
  };

  const toggleSelectTemplateSetById = (id) => {
    const isNewTemplateSetId = id !== selectedTemplateSet;

    if (isNewTemplateSetId) {
      setSelectedTemplateSet(id);
    }
  };

  const handleCheckboxClicked = (id) => {
    toggleSelectTemplateSetById(id);
  };

  const templateSetsTableData = useObserver(() =>
    data.map((set) => ({
      id: set.id,
      title: set.title,
      selected: selectedTemplateSet === set.id,
      count: set.currentTemplates().length,
      updatedAt: set.updatedAt,
      children: set.currentTemplates().map((template) => {
        return {
          id: template.id,
          title: template.title,
        };
      }),
    })),
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleOnPageScroll, [pageScroll]);

  // const empty = true;
  const isLoading = false;
  // const data = [];
  const visibleItems = templateSetsTableData.length;
  // const empty = templateSetsTableData.length === 0;

  /* Table Column Render Functions */
  const renderNameColumn = (templateTitle) => (
    <p>
      <i className="mr-4">
        <Folder size={16} />
      </i>
      {templateTitle}
    </p>
  );

  const renderChildToggle = (value, { showChildren }) => (
    <div>
      {!showChildren && (
        <i>
          <ChevronRight size={16} />
        </i>
      )}
      {showChildren && (
        <i>
          <ChevronDown size={16} />
        </i>
      )}
    </div>
  );

  const renderSelectedColumn = (selected, { rowData: { id } }) => (
    <div
      className="ml-4"
      onClick={(e) => {
        // We don't want this to propgate or else it will trigger the child rows to expand
        e.stopPropagation();
        e.preventDefault();
        handleCheckboxClicked(id);
      }}
    >
      {selected && <CheckSquare size={16} />}
      {!selected && <Square size={16} />}
    </div>
  );

  const renderChildNameColumn = (templateTitle) => (
    <p className="flex items-center text-sm ml-1">{templateTitle}</p>
  );

  return (
    <>
      <Table
        isLoading={isLoading}
        empty={empty}
        emptyTitle={'No template sets match that filter'}
        searchPlaceholder="Search sets"
        data={templateSetsTableData}
        visibleItems={visibleItems}
        rowClickable={true}
        rowStyle={{ margin: '0 12px' }}
        onSearchChange={handleSearchChange}
        onSortByChange={handleSortByChange}
        columns={[
          {
            dataKey: 'selected',
            render: renderSelectedColumn,
            style: { width: '29px' },
          },
          { render: renderChildToggle },
          {
            label: 'Name',
            dataKey: 'title',
            render: renderNameColumn,
            style: {
              width: '60%',
              textAlign: 'left',
              textOverflow: 'ellipsis',
              overfllow: 'hidden',
            },
          },
          {
            dataKey: 'count',
            label: 'Number of templates',
            style: { justifySelf: 'flex-end' },
          },
        ]}
        childColumns={[
          {
            label: 'Name',
            dataKey: 'title',
            style: {
              width: '60%',
              textAlign: 'left',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            },
            render: renderChildNameColumn,
          },
        ]}
      />
      <LoadMoreDisplayText
        loading={loading}
        done={done}
        onLoadMore={loadMore}
      />
    </>
  );
};

ChooseTemplateSetTableContainer.propTypes = {
  onChange: PropTypes.func,
};

ChooseTemplateSetTableContainer.defaultProps = {
  onChange: () => {},
};

export default ChooseTemplateSetTableContainer;
