import { Box, Typography } from '@mui/material';
import React from 'react';

type SummaryItemProps = {
  name: string;
  price: React.ReactNode;
};

export const SummaryItem = ({ name, price }: SummaryItemProps) => {
  return (
    <Box
      data-testid="subscription-summary-item"
      pb={2}
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <Typography variant={'body1'}>{name}</Typography>
      <Typography variant={'label'}>{price}</Typography>
    </Box>
  );
};
