import { useMutation } from 'react-query';
import env from '~/src/utils/env';
import { APIError } from '~/src/utils/error';

export const getEmulationUrl = (orgFprint: string) =>
  `${env.apiUrl}/api/v2/account/org/${orgFprint}/authtoken-emulated-user/`;

export const useEmulateUser = () =>
  useMutation<unknown, APIError, { email: string; orgFprint: string }>({
    onSuccess: () => {
      window.location.href = window.location.origin;
    },
    mutationFn: async ({ email, orgFprint }) => {
      const response = await fetch(getEmulationUrl(orgFprint), {
        method: 'PUT',
        body: JSON.stringify({ email }),
      });
      if (!response.ok) throw new APIError('Failed to emulate user', response);
    },
  });
