import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

export default StyleSheet.create({
  button: {
    userSelect: 'none',
    border: theme.border,
    borderColor: theme.colors.bluebird,
    color: theme.colors.bluebird,
    // background: theme.surface,
    borderRadius: theme.borderRadius,
    padding: `${theme.unit * 1}px ${theme.unit * 3}px`,
    fontSize: theme.fonts.size.small,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'transform 0.2s ease, background 0.2s ease',
    ':hover': {
      transform: 'translateY(1px)',
      background: '#F2F6FF',
      cursor: 'pointer',
    },
    ':focus': {
      transform: 'translateY(1px)',
      background: '#F2F6FF',
      cursor: 'pointer',
      outline: 'none',
    },
    ':active': {
      transform: 'translateY(1px)',
      background: '#F2F6FF',
      cursor: 'pointer',
      outline: 'none',
    },
  },
  buttonPrimary: {
    color: theme.surface,
    background: theme.colors.bluebird,
    fontSize: theme.fonts.size.small,
    ':hover': {
      transform: 'translateY(1px)',
      background: '#1046DE',
    },
    ':active': {
      transform: 'translateY(1px)',
      background: '#1046DE',
    },
    ':focus': {
      transform: 'translateY(1px)',
      background: '#1046DE',
    },
  },
  buttonDisabled: {
    opacity: 0.6,
    ':hover': {
      cursor: 'not-allowed',
      transform: 'translateY(0)',
    },
    ':active': {
      cursor: 'not-allowed',
      transform: 'translateY(0)',
    },
    ':focus': {
      cursor: 'not-allowed',
      transform: 'translateY(0)',
    },
  },
  buttonSuccess: {
    color: theme.surface,
    borderColor: 'transparent',
    background: theme.colors.tennisBallGreen,
    ':hover': {
      transform: 'translateY(1px)',
      background: '#00AE3D',
    },
    ':active': {
      transform: 'translateY(1px)',
      background: '#00AE3D',
    },
    ':focus': {
      transform: 'translateY(1px)',
      background: '#00AE3D',
    },
  },
  buttonDiscrete: {
    color: theme.colors.charcoal,
    background: 'transparent',
    borderColor: theme.colors.concrete,
    ':hover': {
      background: 'transparent',
    },
    ':active': {
      background: 'transparent',
    },
    ':focus': {
      background: 'transparent',
    },
  },
  buttonAlert: {
    color: theme.surface,
    background: theme.colors.paleTomato,
    borderColor: theme.colors.paleTomato,
    ':hover': {
      transform: 'translateY(1px)',
      background: '#dc6363',
    },
    ':active': {
      transform: 'translateY(1px)',
      background: '#dc6363',
    },
    ':focus': {
      transform: 'translateY(1px)',
      background: '#dc6363',
    },
  },
  buttonSecondaryAlert: {
    color: theme.colors.paleTomato,
    borderColor: theme.colors.paleTomato,
    ':hover': {
      transform: 'translateY(1px)',
      background: '#FDE8E9',
    },
    ':active': {
      transform: 'translateY(1px)',
      background: '#FDE8E9',
    },
    ':focus': {
      transform: 'translateY(1px)',
      background: '#FDE8E9',
    },
  },
  buttonIconButton: {
    border: 'none',
    padding: `${theme.unit}px ${theme.unit * 2}px`,
    ':hover': {
      transform: 'translateY(0px)',
    },
  },
  buttonIcon: {
    display: 'inherit',
    position: 'relative',
    left: -theme.unit * 1,
  },
  buttonIconCenter: {
    left: 0,
  },
});
