import React, { useState } from 'react';
import Button from '~/src/components/Button';
import { css } from 'aphrodite';
import PropTypes from 'prop-types';

import styles from './styles';

const Confirmation = ({
  title,
  message,
  onCancel,

  primaryActionTitle,
  onConfirm,
  secondaryActionTitle,
  onSecondaryConfirm,
  processingText,
}) => {
  const [primaryActionDisabled, setPrimaryActionDisabled] = useState(false);
  const [primaryActionLabel, setPrimaryActionLabel] =
    useState(primaryActionTitle);

  const handleSubmit = () => {
    setPrimaryActionDisabled(true);
    setPrimaryActionLabel(processingText || primaryActionTitle);
    onConfirm();
  };

  return (
    <div className={css(styles.modalContainer)}>
      <h1 className={css(styles.modalTitle)}>{title}</h1>
      <p
        className={css(styles.modalMessage, styles.modalMessageCenterVertical)}
      >
        {message}
      </p>
      <div className={css(styles.modalActions)}>
        <Button cssStyle={styles.buttonAction} onClick={onCancel}>
          Cancel
        </Button>

        {secondaryActionTitle && onSecondaryConfirm && (
          <Button cssStyle={styles.buttonAction} onClick={onSecondaryConfirm}>
            {secondaryActionTitle}
          </Button>
        )}

        <Button
          cssStyle={styles.buttonAction}
          onClick={handleSubmit}
          primary
          disabled={primaryActionDisabled}
        >
          {primaryActionLabel}
        </Button>
      </div>
    </div>
  );
};

Confirmation.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  primaryActionTitle: PropTypes.string,
  secondaryActionTitle: PropTypes.string,
};

Confirmation.defaultProps = {
  title: 'Confirm',
  message: 'Are you sure?',
  primaryActionTitle: 'Confirm',
  secondaryActionTitle: null,
};

export default Confirmation;
