import { useMutation, UseMutationOptions } from 'react-query';
import { BillingCycleType, SubscriptionV2 } from '../types';
import { useCurrentOrgFprint } from '../../user';
import { useCreateStripeCardToken } from './useCreateStripeCardToken';
import {
  composeMutationOpts,
  fetchAndParse,
  useInvalidateQuery,
} from '../../utils';
import { getSubscriptionV2Url } from '../urls';
import { APIError } from '~/src/utils/error';
import { keys } from '../keys';

export type CreateSubscriptionV2Params = {
  card: stripe.StripeCardTokenData;
  add_plans: string[];
  duration: BillingCycleType;
  seat: number;
  coupon?: string;
};

export const useCreateSubscriptionV2 = (
  options: UseMutationOptions<
    SubscriptionV2,
    APIError,
    CreateSubscriptionV2Params
  > = {},
) => {
  const orgFprint = useCurrentOrgFprint();
  const { createCardTokenAsync } = useCreateStripeCardToken();

  return useMutation(
    composeMutationOpts(options, {
      onSuccess: useInvalidateQuery(keys.subscription),
      mutationFn: async ({ card, ...rest }) => {
        const { id } = await createCardTokenAsync(card);
        return fetchAndParse<SubscriptionV2>(getSubscriptionV2Url(orgFprint), {
          method: 'POST',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify({ 'card-token': id, ...rest }),
        });
      },
    }),
  );
};
