import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from '@mui/material';
import React from 'react';
import {
  getBillingCycleFromSubscription,
  getCourtFormPlansFromSubscription,
  getSeatsFromSubscription,
  getNysbaPlanFromSubscription,
  getWordPlanFromSubscription,
  isSubscriptionCanceled,
  SubscriptionV2,
} from '~/src/entities/subscription';
import { CanceledBanner } from './CanceledBanner';
import { PastDueBanner } from './PastDueBanner';
import { ConfigurePlan } from './ConfigurePlan';
import { NextInvoice } from './NextInvoice';
import { RenewSubscriptionButton } from './RenewSubscriptionButton';
import { UpdatePaymentMethod } from './UpdatePaymentMethod';

type SubscriptionCardContentProps = {
  subscription: SubscriptionV2;
  isLoading: boolean;
  isError: boolean;
  refetch: () => void;
};

export const SubscriptionCardContent = ({
  subscription,
  isLoading,
  isError,
  refetch,
}: SubscriptionCardContentProps) => {
  // const isNysbaSignup = useCurrentOrg().org?.signup_source === 'nysba';
  if (isLoading)
    return (
      <Box display={'flex'} justifyContent={'center'}>
        <CircularProgress />
      </Box>
    );

  if (isError)
    return (
      <>
        <Typography textAlign={'center'} variant={'body1'}>
          There was an error fetching your subscription
        </Typography>
        <Box pt={2} display={'flex'} justifyContent={'center'}>
          <Button
            data-testid="refetch-subscription-btn"
            variant={'text'}
            onClick={() => refetch()}
          >
            Retry
          </Button>
        </Box>
      </>
    );

  let ConfigurePlanSection = (
    <ConfigurePlan
      seats={getSeatsFromSubscription(subscription)}
      contentPackage={!!getNysbaPlanFromSubscription(subscription)}
      word={!!getWordPlanFromSubscription(subscription)}
      courtForms={getCourtFormPlansFromSubscription(subscription)}
      billingCycle={getBillingCycleFromSubscription(subscription)}
    />
  );

  if (subscription.status === 'past_due') {
    ConfigurePlanSection = (
      <>
        <Box pb={2}>
          <PastDueBanner endDate={subscription.current_period_end} />
        </Box>
        <RenewSubscriptionButton />
      </>
    );
  } else if (isSubscriptionCanceled(subscription)) {
    ConfigurePlanSection = (
      <>
        <Box pb={2}>
          <CanceledBanner endDate={subscription.current_period_end} />
        </Box>
        <RenewSubscriptionButton />
      </>
    );
  }

  return (
    <>
      {ConfigurePlanSection}
      <Box mx={-3} my={3}>
        <Divider />
      </Box>
      <Box pb={3}>
        <NextInvoice
          billingCycle={getBillingCycleFromSubscription(subscription)}
          seats={getSeatsFromSubscription(subscription)}
          periodEnd={subscription.current_period_end}
        />
      </Box>
      <UpdatePaymentMethod last4={subscription.customer.card.last4} />
    </>
  );
};
