import React from 'react';

import { css } from 'aphrodite';
import styles from '~/src/components/RowActions/styles';

import Button from '~/src/components/Button';
import Triangle from '~/src/components/Triangle';
import Dropdown from '~/src/components/Dropdown';
import dropdownStyles from '~/src/components/Dropdown/styles';

import { type QuestionnaireSubmission } from '@clio/questionnaire-builder';
import analyticsService from '~/src/services/analytics';
import { tryConvertToNumber } from '~/src/entities/questionnaires';

export const RowActions = ({
  actions,
  submission,
}: {
  actions: {
    edit: (submission: QuestionnaireSubmission) => void;
    rename: (submission: QuestionnaireSubmission) => void;
    delete: (submission: QuestionnaireSubmission) => void;
  };
  submission: QuestionnaireSubmission;
}) => {
  const renderTrigger = ({
    toggleDropdown,
  }: {
    toggleDropdown: () => void;
  }) => {
    const handleDropdownClick = () => {
      analyticsService.track('Clicked on Live Questionnaire Actions', {
        questionnaire_id: submission.questionnaire_id,
        submission_id: submission.id,
        matter_id: tryConvertToNumber(submission.target_identifier),
      });
      toggleDropdown();
    };

    return (
      <span analyticsname="Actions">
        <Button onClick={handleDropdownClick} cssStyle={styles.rowActionButton}>
          Actions <Triangle primary orientation={'down'} />
        </Button>
      </span>
    );
  };

  const handleEdit = () => {
    actions.edit(submission);
  };

  const handleRename = () => {
    actions.rename(submission);
  };

  const handleDelete = () => {
    actions.delete(submission);
  };

  return (
    <Dropdown renderTrigger={renderTrigger}>
      <a
        onClick={handleEdit}
        className={css(dropdownStyles.dropdownActionItem)}
        analyticsname="View"
      >
        View
      </a>
      <a
        onClick={handleRename}
        className={css(dropdownStyles.dropdownActionItem)}
        analyticsname="Rename"
      >
        Rename
      </a>
      <a
        onClick={handleDelete}
        className={css(dropdownStyles.dropdownActionItem)}
        analyticsname="Delete"
      >
        Delete
      </a>
    </Dropdown>
  );
};
