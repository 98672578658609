import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { startCase } from 'lodash';
import { useObserver } from 'mobx-react';

/* Hooks */
import useRefMouseDown from '~/src/hooks/useRefMouseDown';

/* Components */
import { PlusCircle, Trash, Copy, Edit, Share2 } from 'react-feather';
import Spinner from '~/src/components/Spinner';
import {
  MultipleChoiceInput,
  MultipleChoiceOptions,
  SelectInput,
  SelectOptionInput,
  Label,
} from '~/src/components/Inputs';
import Button from '~/src/components/Button';
import { LAYOUT_SLIDEIN_TYPES } from '~/src/components/PageLayout/SlideIns';
import { useLayoutContext } from '~/src/contexts/Layout';
import QuestionOptionsMenuOption from './QuestionOptionsMenuOption';
import AddAboveButtonBar from './AddAboveButtonBar';

/* Constants */
import {
  UPLOAD_LIMIT_OPTIONS,
  UPLOAD_TYPES_OPTIONS,
  QUESTION_TYPE_OPTIONS,
  QUESTION_TYPE_MULTIPLE_CHOICE,
  QUESTION_TYPE_MULTI_SELECT,
  QUESTION_TYPE_FILE_UPLOAD,
  QUESTION_TYPE_LOOKUP,
} from './constants';

const QuestionInput = (props) => {
  const {
    id,
    label,
    required,
    placeholder,
    type,
    options,
    uploadLimit,
    uploadType,
  } = props;

  const [value, update] = useState();

  const inputProps = {
    id,
    required,
    onChange: update,
    label,
    placeholder,
    defaultValue: type,
  };

  const InputEl = QUESTION_TYPE_LOOKUP[type];

  if (
    type === QUESTION_TYPE_MULTIPLE_CHOICE ||
    type === QUESTION_TYPE_MULTI_SELECT
  ) {
    inputProps.options = options;
  }

  if (type === QUESTION_TYPE_FILE_UPLOAD) {
    inputProps.uploadLimit = uploadLimit;
    inputProps.uploadType = uploadType;
  }

  return <InputEl {...inputProps} onChange={update} value={value} />;
};

const CreatingQuestionOverlay = () => (
  <div className="flex items-center justify-center absolute top-0 left-0 right-0 bottom-0 bg-opacity-75 bg-white z-10">
    <div className="text-center">
      <Spinner />
      <span className="mt-4 block text-sm">Creating question...</span>
    </div>
  </div>
);

const DragHandle = ({ dragHandleProps, isDragging, show }) => (
  <div
    {...dragHandleProps}
    style={{ cursor: isDragging ? 'grabbing' : 'move' }}
    className={cn(
      'transition-all absolute top-1 left-1/2 w-8 h-4 flex items-center -ml-4 px-2 py-1 rounded hover:shadow cursor-move',
      {
        'opacity-0': !show,
      },
    )}
  >
    <div className="w-full">
      <div className="w-full bg-blueGray-400 h-0.5 mb-0.5" />
      <div className="w-full bg-blueGray-400 h-0.5" />
    </div>
  </div>
);

const Container = ({
  containerRef,
  isDragging,
  hovering,
  editing,
  creating,
  showAddQuestionBar,
  dragHandleProps,
  children,
  onClick: handleClick,
  onMouseUp: handleMouseUp,
  onMouseDown: handleMouseDown,
  onMouseEnter: handleMouseEnter,
  onMouseLeave: handleMouseLeave,
}) => (
  <div
    ref={containerRef}
    className={cn(
      'p-6 m-2 bg-white rounded relative flex justify-between border border-white border-solid transition-all ',
      {
        'border-blueGray-200': (hovering && !editing) || isDragging,
        'shadow-md border-blueGray-200': editing,
      },
    )}
    onClick={handleClick}
    onMouseDown={handleMouseDown}
    onMouseUp={handleMouseUp}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
  >
    <DragHandle
      dragHandleProps={dragHandleProps}
      isDragging={isDragging}
      show={hovering && !showAddQuestionBar}
    />
    {creating && <CreatingQuestionOverlay />}
    {showAddQuestionBar && <AddAboveButtonBar />}
    {children}
  </div>
);

Container.defaultProps = {
  onClick: () => {},
  handleMouseUp: () => {},
  onMouseDown: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
};

const QuestionNumberRow = ({ sortOrder, dragHandleProps, isDragging }) => (
  <div
    {...dragHandleProps}
    style={{ cursor: isDragging ? 'grabbing' : 'move' }}
  >
    <div className="text-sm font-semibold mr-6 pt-2">Q{sortOrder}.</div>
  </div>
);

QuestionNumberRow.defaultProps = {
  dragHandleProps: {},
  isDragging: false,
};

const QuestionInputRow = ({
  isRequired,
  isDragging,
  isMultipleChoice,
  isFileUpload,
  hasTag,
  tagLabel,
  type,
  label,
  options,
  editing,
  uploadLimit,
  uploadTypes,
  onLabelChange: handleLabelChange,
  onOptionschange: handleOptionsChange,
  onUploadLimitChange: handleUploadLimitChange,
  onUploadTypesChange: handleUploadTypesChange,
}) => {
  const canEdit = editing && !isDragging;

  const questionInputProps = {
    type,
    options,
    uploadLimit,
    uploadTypes,
    placeholder: startCase(type.toLowerCase()),
  };

  if (canEdit) {
    questionInputProps.label = 'Preview';
  }

  return (
    <div className="w-6/12 mr-6">
      {canEdit && (
        <Label label="Question Text">
          <input
            className="text-base bg-blueGray-100 rounded p-2 outline-none w-full"
            value={label}
            onChange={handleLabelChange}
          />
        </Label>
      )}
      {!canEdit && (
        <div className="p-2 text-base">
          {label}
          {isRequired ? <span className="text-red-500"> *</span> : ''}
        </div>
      )}
      <div className="mt-2 w-30">
        {!canEdit && <QuestionInput {...questionInputProps} />}
        {canEdit && isMultipleChoice && (
          <MultipleChoiceOptions
            label={'Multiple Choice Options'}
            optionLabel="Option Label"
            optionValueLabel="Option Value"
            value={options}
            onChange={handleOptionsChange}
          />
        )}
        {canEdit && isFileUpload && (
          <MultipleChoiceInput
            label="File Upload Limit"
            options={UPLOAD_LIMIT_OPTIONS}
            onChange={handleUploadLimitChange}
            value={uploadLimit}
          />
        )}
        {canEdit && isFileUpload && (
          <SelectOptionInput
            multiSelect
            label="File Upload Types"
            placeholder="The types of files allowed"
            value={uploadTypes}
            isSearchable={false}
            options={UPLOAD_TYPES_OPTIONS}
            onChange={handleUploadTypesChange}
          />
        )}
      </div>
      <div className="inline-block">
        {/* {type && (
          <div className="mt-6 mr-4 rounded border-2 border-blueGray-200 border-solid text-xs inline-flex">
            <span className="bg-blueGray-200 p-1.5 inline-block truncate">Type</span>
            <span className="p-1.5 inline-block text-xs truncate inline-block">{startCase(type.toLowerCase())}</span>
          </div>
        )} */}

        {hasTag && (
          <div className="mt-6 rounded border-2 border-blueGray-200 border-solid text-xs inline-flex">
            <span className="bg-blueGray-200 p-1.5 inline-block truncate">
              Maps to
            </span>
            <span className="p-1.5 inline-block text-xs truncate inline-block">
              {tagLabel}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

const QuestionEditorRow = ({
  type,
  required,
  editing,
  onTypeChange: handleTypeChange,
  onRequiredChange: handleRequiredChange,
  onConfirm: handleConfirm,
}) => (
  <div className="w-5/12">
    {editing && (
      <>
        <SelectInput
          label="Type"
          options={QUESTION_TYPE_OPTIONS}
          value={type}
          onChange={handleTypeChange}
        />
        <MultipleChoiceInput
          label="Required"
          value={`${required}`}
          options={[
            {
              label: 'True',
              value: 'true',
            },
            {
              label: 'False',
              value: 'false',
            },
          ]}
          onChange={handleRequiredChange}
        />
        <Button className="float-right" primary onClick={handleConfirm}>
          Save
        </Button>
      </>
    )}
  </div>
);

const QuestionOptionsMenu = ({
  id,
  hovering,
  editing,
  onAdd: handleAdd,
  onDuplicate: handleDuplicate,
  onRemove: handleRemove,
  onEdit: handleEdit,
  onMap: handleMap,

  onMouseEnterAddQuestion: handleMouseEnterAddQuestion,
  onMouseLeaveAddQuestion: handleMouseLeaveAddQuestion,
  onMouseEnterDuplicateQuestion: handleMouseEnterDuplicateQuestion,
  onMouseLeaveDuplicateQuestion: handleMouseLeaveDuplicateQuestion,
}) => {
  const questionAddId = `question-options-menu-${id}-add`;
  const questionDuplicateId = `question-options-menu-${id}-duplicate`;
  const questionRemoveId = `question-options-menu-${id}-remove`;
  const questionEditId = `quesion-options-menu-${id}-edit`;
  const questionMapId = `question-options-menu-${id}-map`;

  return (
    <div
      className={cn(
        'bg-white shadow absolute p-2 rounded-full transition-all duration-400 opacity-0 top-0 -right-16 cursor-pointer hover:opacity-100',
        {
          'opacity-100': hovering || editing,
        },
      )}
    >
      <QuestionOptionsMenuOption
        id={questionAddId}
        Icon={PlusCircle}
        label="Add question"
        onMouseDown={handleAdd}
        onMouseEnter={handleMouseEnterAddQuestion}
        onMouseLeave={handleMouseLeaveAddQuestion}
      />
      <QuestionOptionsMenuOption
        className="my-2"
        id={questionMapId}
        label={'Map to Template Field'}
        Icon={Share2}
        onMouseDown={handleMap}
      />
      <QuestionOptionsMenuOption
        className="my-2"
        id={questionDuplicateId}
        Icon={Copy}
        label="Duplicate question"
        onMouseDown={handleDuplicate}
        onMouseEnter={handleMouseEnterDuplicateQuestion}
        onMouseLeave={handleMouseLeaveDuplicateQuestion}
      />
      <QuestionOptionsMenuOption
        className="my-2"
        id={questionEditId}
        Icon={Edit}
        label="Edit question"
        onMouseDown={handleEdit}
      />
      <QuestionOptionsMenuOption
        className="my-2"
        id={questionRemoveId}
        Icon={Trash}
        label="Remove question"
        onMouseDown={handleRemove}
      />
    </div>
  );
};

const Question = (props) => {
  const {
    question,
    isDragging,
    dragHandleProps,
    onMouseEnter: handleMouseEnterProp,
    onMouseLeave: handleMouseLeaveProp,
  } = props;

  const { showSlideIn } = useLayoutContext();

  const {
    creating,
    type,
    sortOrder,
    label,
    tagLabel,
    required,
    options,
    uploadLimit,
    uploadTypes,
    editing,
  } = useObserver(() => ({
    creating: question.creating,
    type: question.type,
    sortOrder: question.sortOrder,
    label: question.label,
    tagLabel: question.getTagLabel(),
    required: question.required,
    options: question.options,
    uploadLimit: question.uploadLimit,
    uploadTypes: question.uploadTypes,
    editing: question.editing,
  }));

  const hasTag = !!tagLabel;

  const containerRef = useRef();
  const [hovering, setHovering] = useState(false);
  const [showAddQuestionBar, setShowAddQuestionBar] = useState(false);

  const isRequired = required;

  const handleQuestionClicked = () => {
    question.setEditing(true);
  };

  const handleOutsideQuestionClicked = () => {
    question.setEditing(false);
  };

  const handleMouseEnter = () => {
    if (handleMouseEnterProp) {
      handleMouseEnterProp();
    }
    setHovering(true);
  };

  const handleMouseLeave = () => {
    if (handleMouseLeaveProp) {
      handleMouseLeaveProp();
    }
    setHovering(false);
  };

  const handleTypeChange = (type) => {
    question.setType(type);
  };

  const handleOptionsChange = (options) => {
    question.setOptions(options);
  };

  const handleRequiredChange = (value) => {
    question.setRequired(value === 'true');
  };

  const handleUploadTypesChange = (uploadTypes) => {
    question.setUploadTypes(uploadTypes);
  };

  const handleUploadLimitChange = (limit) => {
    question.setUploadLimit(limit);
  };

  const handleLabelChange = (event) => {
    const value = event && event.target && event.target.value;
    question.setLabel(value);
  };
  const handleAdd = () => {
    question.createNewQuestion();
  };
  const handleDuplicate = () => {
    question.duplicateQuestion();
  };
  const handleRemove = () => {
    question.remove();
  };
  const handleEdit = () => {
    question.setEditing(true);
  };
  const handleMouseEnterAddQuestion = () => {
    setShowAddQuestionBar(true);
  };
  const handleMouseLeaveAddQuestion = () => {
    setShowAddQuestionBar(false);
  };
  const handleMouseEnterDuplicateQuestion = () => {
    setShowAddQuestionBar(true);
  };
  const handleMouseLeaveDuplicateQuestion = () => {
    setShowAddQuestionBar(false);
  };
  const handleConfirm = () => {
    question.setEditing(false);
    // TODO: trigger questionnaire save showToast()
  };

  const handleMap = () => {
    const handleConfirmMapping = ({ templateIds, templateSetIds, tag }) => {
      question.setTag({ templateIds, templateSetIds, tag });
    };

    showSlideIn(LAYOUT_SLIDEIN_TYPES.questionnaireQuestionMapField, {
      question,
      questionnaire: question.getQuestionnaire(),
      onConfirmMapping: handleConfirmMapping,
    });
  };

  useRefMouseDown(
    containerRef,
    editing,
    handleQuestionClicked,
    handleOutsideQuestionClicked,
  );

  // TODO - multi select preview?
  const isMultipleChoice =
    type === QUESTION_TYPE_MULTIPLE_CHOICE ||
    type === QUESTION_TYPE_MULTI_SELECT;
  const isFileUpload = type === QUESTION_TYPE_FILE_UPLOAD;

  return (
    <Container
      dragHandleProps={dragHandleProps}
      isDragging={isDragging}
      containerRef={containerRef}
      hovering={hovering}
      editing={editing}
      creating={creating}
      showAddQuestionBar={showAddQuestionBar}
      onMouseDown={(e) => e.stopPropagation()}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <QuestionNumberRow
        sortOrder={sortOrder}
        dragHandleProps={dragHandleProps}
        isDragging={isDragging}
      />
      <QuestionInputRow
        isDragging={isDragging}
        isRequired={isRequired}
        isMultipleChoice={isMultipleChoice}
        isFileUpload={isFileUpload}
        hasTag={hasTag}
        tagLabel={tagLabel}
        type={type}
        label={label}
        options={options}
        editing={editing}
        uploadLimit={uploadLimit}
        uploadTypes={uploadTypes}
        onLabelChange={handleLabelChange}
        onOptionschange={handleOptionsChange}
        onUploadLimitChange={handleUploadLimitChange}
        onUploadTypesChange={handleUploadTypesChange}
      />
      <QuestionEditorRow
        type={type}
        isDragging={isDragging}
        editing={editing}
        required={required}
        onConfirm={handleConfirm}
        onTypeChange={handleTypeChange}
        onRequiredChange={handleRequiredChange}
      />
      <QuestionOptionsMenu
        id={question.id}
        isDragging={isDragging}
        hovering={hovering}
        editing={editing}
        onAdd={handleAdd}
        onDuplicate={handleDuplicate}
        onRemove={handleRemove}
        onEdit={handleEdit}
        onMap={handleMap}
        onMouseEnterAddQuestion={handleMouseEnterAddQuestion}
        onMouseLeaveAddQuestion={handleMouseLeaveAddQuestion}
        onMouseEnterDuplicateQuestion={handleMouseEnterDuplicateQuestion}
        onMouseLeaveDuplicateQuestion={handleMouseLeaveDuplicateQuestion}
      />
    </Container>
  );
};

Question.propTypes = {
  type: PropTypes.string,
  sortOrder: PropTypes.number,
  label: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.array,
  uploadLimit: PropTypes.string,
};

export default Question;
