import { useTheme } from '@mui/material';
import React from 'react';
import { CheckCircle } from 'react-feather';

export const Complete = () => {
  const theme = useTheme();
  return (
    <CheckCircle
      size={16}
      stroke={theme.palette.green[400]}
      data-testid="complete-checkmark-icon"
    />
  );
};
