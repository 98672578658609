import React from 'react';

type DueTodayProps = {
  total: number;
  discount: number;
  discount_percent: number;
};

export const DueToday = ({
  total,
  discount,
  discount_percent,
}: DueTodayProps) => {
  return (
    <div>
      <div className="text-base font-medium mb-2 mt-6">Due</div>
      <div className="flex flex-row justify-between text-xs">
        <span>Total</span>
        <span className="font-semibold">${total / 100 + discount / 100}</span>
      </div>

      {discount > 0 && (
        <div className="flex flex-row justify-between text-xs mb-4">
          <span>Discount ({discount_percent}%)</span>
          <span className="font-semibold text-emerald-600">
            -${discount / 100}
          </span>
        </div>
      )}

      <div className="flex flex-row justify-between text-xs">
        <span>Due today</span>
        <span className="font-semibold">${total / 100}</span>
      </div>
    </div>
  );
};
