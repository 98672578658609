/* Libraries */
import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';

/* Components */
import FormTemplateDraftingContainer from '~/src/containers/FormTemplateDraftingContainer';

interface MatchParams {
  formTemplateID: string;
}

interface Props extends RouteComponentProps<MatchParams, {}, any> {} // TODO LocationType

const FormTemplateDrafting = () => (
  <Switch>
    <Route
      exact
      path="/form-template/:formTemplateID"
      render={({ match: { params }, location }: Props) => {
        const { formTemplateID } = params;
        return (
          <FormTemplateDraftingContainer
            templateDefaultValuesID={parseInt(formTemplateID)}
            duplicate={location.state?.duplicate}
          />
        );
      }}
    ></Route>
  </Switch>
);

export default FormTemplateDrafting;
