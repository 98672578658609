/* Libs */
import React, { useState, useEffect } from 'react';
import { css } from 'aphrodite';

/* Styles */
import styles from './styles';

const FadeIn = (props) => {
  const { show, children } = props;

  const [animate, setAnimate] = useState(false);

  const triggerAnimation = () => {
    const currentTimeout = setTimeout(() => {
      setAnimate(true);
    }, 50);

    return () => {
      if (currentTimeout) {
        clearTimeout(currentTimeout);
      }
    };
  };

  useEffect(triggerAnimation, [show]);

  return (
    show && (
      <div className={css(styles.fadeIn, animate && styles.fadeInActive)}>
        {children}
      </div>
    )
  );
};

FadeIn.defaultProps = {
  show: true,
};

export default FadeIn;
