import { useMutation, useQueryClient } from 'react-query';
import { APIError } from '~/src/utils/error';
import {
  getClioQuestionnaireAuthToken,
  questionnaireAuthorizationHeaders,
} from '../utils';
import { getQuestionnaireSubmissionById } from '../urls';
import {
  SubmissionSectionAddOrUpdate,
  QuestionnaireSubmission,
} from '@clio/questionnaire-builder';
import { submissionQueryArgs } from '../queryArgs';

type UpdateClioQuestionnaireSubmission = {
  submissionId: string;
  submissionUpdate: SubmissionSectionAddOrUpdate | { title: string };
};

export const useUpdateQuestionnaireSubmission = (orgFprint: string) => {
  const queryClient = useQueryClient();
  return useMutation<
    QuestionnaireSubmission,
    APIError,
    UpdateClioQuestionnaireSubmission
  >({
    onSuccess: () => {
      queryClient.invalidateQueries(submissionQueryArgs.all);
    },
    mutationFn: async ({ submissionId, submissionUpdate }) => {
      const token = await getClioQuestionnaireAuthToken(orgFprint);
      const response = await fetch(
        getQuestionnaireSubmissionById(submissionId),
        {
          method: 'PUT',
          headers: {
            ...questionnaireAuthorizationHeaders(token),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(submissionUpdate),
        },
      );

      if (!response.ok) {
        throw new APIError('Failed to update submission', response);
      }

      return response.json();
    },
  });
};
