import { Instance } from 'mobx-state-tree';
import { useQuery, UseQueryOptions } from 'react-query';
import { composeQueryOpts } from '../entities/utils';
import { Matter } from '../stores/matterStore';
import { useFetchMatterDetail } from './useFetchMatterDetail';

export const useMatterDetail = (
  matterId: string | number,
  options: UseQueryOptions<Instance<typeof Matter>> = {},
) => {
  const fetchMatter = useFetchMatterDetail();
  return useQuery(
    composeQueryOpts(options, {
      queryKey: ['matter', +matterId],
      queryFn: () => fetchMatter(matterId),
    }),
  );
};
