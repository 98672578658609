import React, { FC, useEffect } from 'react';
import { SignaturePackage, WhoSigns } from '~/src/models';
import {
  DraggableRecipientsList,
  NonDraggableRecipientMe,
} from '~/src/components/SignaturePackage/DraggableRecipients';
import Button from '~/src/components/Button';
import { DRAGGABLE_TYPES } from '~/src/components/SignaturePackage/constants';
import { Heading, Text } from '../Typography';

interface Props {
  whoSigns: WhoSigns;
  currentUserInfo: SignaturePackage.LeanRecipient;
  enableRecipientsDragDrop: boolean;
  setEnableRecipientsDragDrop: React.Dispatch<React.SetStateAction<boolean>>;
  recipients: SignaturePackage.LeanRecipient[];
  setRecipients: React.Dispatch<
    React.SetStateAction<SignaturePackage.LeanRecipient[]>
  >;
  validateRecipientsInput: (
    nextRecipients?: SignaturePackage.LeanRecipient[],
  ) => void;
}

const SignersInput: FC<Props> = ({
  whoSigns,
  currentUserInfo,
  enableRecipientsDragDrop,
  setEnableRecipientsDragDrop,
  recipients,
  setRecipients,
  validateRecipientsInput,
}) => {
  const updateAllRecipients = (
    updatedRecipients: SignaturePackage.Recipient[],
  ) => {
    setRecipients(updatedRecipients);
  };

  const updateName = (name: string, recipient: SignaturePackage.Recipient) => {
    setRecipients((recipients) =>
      recipients.map((r) => (r === recipient ? { ...r, name } : r)),
    );
  };

  const updateEmail = (
    email: string,
    recipient: SignaturePackage.Recipient,
  ) => {
    setRecipients((recipients) =>
      recipients.map((r) => (r === recipient ? { ...r, email } : r)),
    );
  };

  const removeRecipient = (recipient: SignaturePackage.Recipient) => {
    const nextRecipients = recipients.filter((r) => r !== recipient);
    setRecipients(nextRecipients);

    if (nextRecipients.length <= 1) {
      setEnableRecipientsDragDrop(false);
    }
  };

  const addRecipient = () => {
    const nextRecipients = [...recipients];
    nextRecipients.push({
      name: '',
      email: '',
      signOrder: 0,
    });
    setRecipients(nextRecipients);
  };

  useEffect(() => {
    if (whoSigns != WhoSigns.JustMe && recipients.length < 1) {
      addRecipient();
    } else if (whoSigns == WhoSigns.JustMe) {
      recipients.splice(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whoSigns, recipients]);

  return (
    <>
      {whoSigns !== WhoSigns.JustMe && (
        <div className="bg-white rounded-lg	border border-gray-200 shadow-sm mt-6">
          <div className="p-4 flex justify-between">
            <Heading>Signers</Heading>
            <label className="flex items-center gap-2">
              <input
                name="isSetOrder"
                type="checkbox"
                checked={enableRecipientsDragDrop}
                onClick={() => setEnableRecipientsDragDrop((prev) => !prev)}
                disabled={recipients.length <= 1}
              />
              <Text>Set sign order</Text>
            </label>
          </div>
          <div>
            <div>
              <div>
                {whoSigns === WhoSigns.MeAndOthers && (
                  <NonDraggableRecipientMe recipient={currentUserInfo} />
                )}

                <DraggableRecipientsList
                  recipients={recipients}
                  draggableType={DRAGGABLE_TYPES.RECIPIENTS}
                  updateAllRecipients={updateAllRecipients}
                  updateName={updateName}
                  updateEmail={updateEmail}
                  enableDragDrop={enableRecipientsDragDrop}
                  removeRecipient={removeRecipient}
                  onTextFieldChange={validateRecipientsInput}
                />
                <div className="p-4 flex border-t border-gray-200">
                  <Button onClick={addRecipient}> + Add another signer</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SignersInput;
