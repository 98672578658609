/* Libraries */
import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

const styles = StyleSheet.create({
  divider: {
    width: '1px',
    background: theme.colors.smoke,
    position: 'absolute',
    top: `-${theme.unit * 2}px`,
    bottom: `-${theme.unit * 2}px`,
    left: '50%',
  },
  column: {
    width: '100%',
    boxSizing: 'border-box',
    padding: theme.unit * 2,
    position: 'relative',
    overflow: 'hidden',

    ':first-of-type': {
      marginRight: theme.unit * 4,
      textAlign: 'right',
    },
    'last-of-type': {
      marginLeft: theme.unit * 4,
      textAlign: 'right',
    },
  },
  card: {
    display: 'inline-flex',
    textAlign: 'left',
    marginBottom: theme.unit * 2,
    maxWidth: theme.unit * 75,
  },
});

export default styles;
