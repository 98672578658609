import { useMutation, useQueryClient } from 'react-query';
import { APIError } from '~/src/utils/error';
import {
  getClioQuestionnaireAuthToken,
  questionnaireAuthorizationHeaders,
} from '../utils';
import { submissionQueryArgs } from '../queryArgs';
import { getQuestionnaireSubmissionById } from '../urls';

type DeleteClioQuestionnaireSubmission = {
  submissionId: string;
};

export const useDeleteClioQuestionnaireSubmission = (orgFprint: string) => {
  const queryClient = useQueryClient();
  return useMutation<void, APIError, DeleteClioQuestionnaireSubmission>({
    onSuccess: () => {
      queryClient.invalidateQueries(submissionQueryArgs.all);
    },
    mutationFn: async ({ submissionId }) => {
      const token = await getClioQuestionnaireAuthToken(orgFprint);
      const response = await fetch(
        getQuestionnaireSubmissionById(submissionId),
        {
          method: 'DELETE',
          headers: {
            ...questionnaireAuthorizationHeaders(token),
          },
        },
      );

      if (!response.ok) {
        throw new APIError('Failed to delete submission', response);
      }
    },
  });
};
