import Cookies from 'js-cookie';

const CSRF_KEY = 'csrftoken';

const ORG_FPRINT = 'orgFprint';
const USER_EMULATION_IN_PROGRESS_KEY = 'userEmulationInProgress';

const COOKIE_KEYS = [ORG_FPRINT];

function getOrgFprint() {
  return Cookies.get(ORG_FPRINT);
}

function setOrgFprint(orgFprint: string) {
  Cookies.set(ORG_FPRINT, orgFprint);
}

function logout() {
  COOKIE_KEYS.forEach((key) => Cookies.remove(key));
}

function getHeaderTokens(): Record<string, string> {
  const csrfToken = Cookies.get(CSRF_KEY);
  if (!csrfToken) return {};
  return {
    'X-CSRFToken': csrfToken,
  };
}

function adminTokensExist() {
  return Cookies.get(USER_EMULATION_IN_PROGRESS_KEY) !== undefined;
}

export default {
  getOrgFprint,
  setOrgFprint,
  logout,
  getHeaderTokens,
  adminTokensExist,
};
