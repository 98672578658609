import React, { FC, useEffect, useState } from 'react';
import { pick, isEqual } from 'lodash';
import { SignaturePackage, WhoSigns } from '~/src/models';
import EmailValidator from 'email-validator';
import {
  SOME_RECIPIENTS_INVALID,
  CURRENT_STEP,
} from '../components/SignaturePackage/constants';
import ChooseSigners from '../components/ChooseSigners/ChooseSigners';
import useIsValid from '../components/SignaturePackage/hooks/useIsValid';
import { showSaveModal } from '../components/SignaturePackage/SaveModals';
import HeaderFooter from './HeaderFooter';

interface Props {
  user: any;
  signaturePackage: SignaturePackage.SignaturePackage;
  showModal: (type: string, object: any) => void;
  hideModal: () => void;
  onBack: () => void;
  onNext: () => void;
  onExit: () => void;
  onUpdate: (
    signaturePackage: SignaturePackage.LeanSignaturePackage,
  ) => Promise<void>;
}

const ChooseSignersContainer: FC<Props> = ({
  user,
  signaturePackage,
  showModal,
  hideModal,
  onBack,
  onNext,
  onExit,
  onUpdate,
}) => {
  const { valid, setValid, invalidReason } = useIsValid();

  const [whoSigns, setWhoSigns] = useState<WhoSigns>(
    signaturePackage ? signaturePackage.whoSigns : WhoSigns.MeAndOthers,
  );

  const [enableRecipientsDragDrop, setEnableRecipientsDragDrop] = useState(
    signaturePackage && signaturePackage.customOrder
      ? signaturePackage.customOrder
      : false,
  );

  const initialRecipients = signaturePackage
    ? signaturePackage.recipients.map(({ id, email, name, signOrder }) => ({
        id,
        email,
        name,
        signOrder,
      }))
    : [];

  const currentUserInfo: SignaturePackage.LeanRecipient = {
    id:
      initialRecipients.length &&
      initialRecipients[0] &&
      whoSigns !== WhoSigns.Others
        ? initialRecipients[0].id
        : null,
    email: user.profile.email || user.currentOrganization.email,
    name: user.profile.displayName,
    signOrder: 0,
  };

  const [recipients, setRecipients] = useState(() => {
    if (initialRecipients && initialRecipients.length > 0) {
      const recipientsToShow: SignaturePackage.LeanRecipient[] = [];

      initialRecipients.forEach((recipient) => {
        if (recipient.email === currentUserInfo.email) {
          currentUserInfo.id = recipient.id;
          return;
        }

        recipientsToShow.push(recipient);
      });

      return recipientsToShow;
    }

    return [
      {
        name: '',
        email: '',
        signOrder: 0,
      },
    ];
  });

  const createSignaturePackageForRecipients = () => {
    const recipientsToSend =
      whoSigns === WhoSigns.JustMe
        ? [currentUserInfo]
        : [currentUserInfo, ...recipients];

    const signaturePackageData: SignaturePackage.LeanSignaturePackage = {
      id: signaturePackage!.id,
      whoSigns,
      recipients: recipientsToSend,
      customOrder: enableRecipientsDragDrop,
      currentStep: CURRENT_STEP.CHOOSE_SIGNERS,
    };

    return signaturePackageData;
  };

  const saveRecipientsToSignaturePackage = async () => {
    const signaturePackageForRecipients = createSignaturePackageForRecipients();

    try {
      await onUpdate(signaturePackageForRecipients);
    } catch (err: any) {
      setValid(false, SOME_RECIPIENTS_INVALID);
    }
  };

  const validateRecipientsInput = (
    nextRecipients?: SignaturePackage.LeanRecipient[],
  ) => {
    const recipientsToValidate = nextRecipients || recipients;
    const someRecipientsAreInvalid = recipientsToValidate.some(
      (r) => !r.name || !r.email || !EmailValidator.validate(r.email),
    );

    if (someRecipientsAreInvalid || recipients.length < 1) {
      setValid(false, SOME_RECIPIENTS_INVALID);
    } else {
      setValid(true);
    }
  };

  useEffect(() => {
    if (whoSigns === WhoSigns.JustMe) {
      setValid(true);
      return;
    }

    validateRecipientsInput();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whoSigns, setValid, recipients]);

  const noChangesMade = () => {
    const recipientsToSend =
      whoSigns === WhoSigns.JustMe
        ? [currentUserInfo]
        : [currentUserInfo, ...recipients];

    return isEqual(
      Object.assign(
        pick(signaturePackage, [
          'id',
          'whoSigns',
          'recipients',
          'customOrder',
          'currentStep',
        ]),
        { recipients: recipientsToSend },
      ),
      createSignaturePackageForRecipients(),
    );
  };

  const handleExitClick = () => {
    const onConfirm = async () => {
      await saveRecipientsToSignaturePackage();
      onExit();
      hideModal();
    };

    if (noChangesMade()) {
      onExit();
    } else {
      showSaveModal(showModal, onConfirm, () => {
        onExit();
        hideModal();
      });
    }
  };

  const handleNextClick = async () => {
    await saveRecipientsToSignaturePackage();
    onNext();
  };

  return (
    <HeaderFooter
      signaturePackageTitle={signaturePackage.title}
      whoSigns={whoSigns}
      step={'choose-signers'}
      invalidReason={invalidReason}
      valid={valid}
      onBackClick={onBack}
      onNextClick={handleNextClick}
      onExitClick={handleExitClick}
    >
      <div className="w-[36rem] mx-auto">
        <ChooseSigners
          whoSigns={whoSigns}
          setWhoSigns={setWhoSigns}
          currentUserInfo={currentUserInfo}
          recipients={recipients}
          setRecipients={setRecipients}
          enableRecipientsDragDrop={enableRecipientsDragDrop}
          setEnableRecipientsDragDrop={setEnableRecipientsDragDrop}
          validateRecipientsInput={validateRecipientsInput}
        />
      </div>
    </HeaderFooter>
  );
};

export default ChooseSignersContainer;
