import React, { useEffect } from 'react';
import { Info, Close } from '@mui/icons-material';

import { ReactComponent as IconError } from '~/src/static/icon-error.svg';
import { CLIO_ERROR_STATE } from '~/src/utils/constants';
import { IconButton } from '@mui/material';

const ClioSyncErrorBanner = (props: {
  errorState: string;
  onDismiss?: () => void;
}) => {
  let title: string;
  let message: React.ReactFragment;
  let bgColour: string = 'bg-red-50';
  let borderColour: string = 'border-red-300';
  let icon: React.ReactNode = <IconError />;

  const { onDismiss } = props;

  useEffect(() => {
    if (onDismiss) {
      const timeoutId = setTimeout(() => {
        onDismiss();
      }, 2 * 60 * 1000); // 2 minutes in milliseconds

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [onDismiss]);

  switch (props.errorState) {
    case CLIO_ERROR_STATE.SYNC_NOT_ENABLED:
      title = 'Sync not detected';
      message = (
        <>
          Complete the setup steps to connect your Clio Manage and Clio Draft
          accounts found{' '}
          <a href="https://help.clio.com/hc/en-us/articles/24313560334747-Get-Started-With-Clio-Draft">
            here
          </a>
        </>
      );
      break;
    case CLIO_ERROR_STATE.MATTER_SYNC_NOT_ENABLED:
      title = 'Unable to sync matter';
      message =
        'We were unable to sync your latest matter and client details from Clio Manage. Please check your Clio matter permissions and try again.';
      break;
    case CLIO_ERROR_STATE.SYNC_IN_QUEUE_OR_IN_PROGRESS:
      title = 'You are currently running another Clio sync';
      message =
        'This may take a few minutes. Once your sync is finished you can run another sync.';
      bgColour = 'bg-blue-50';
      borderColour = '';
      icon = <Info />;
      break;
    case CLIO_ERROR_STATE.GENERIC_SYNC:
      title = 'Sync error';
      message =
        'We were unable to sync your Clio Manage data to Clio Draft. Please check that all information is accurate and up to date before proceeding. ';
      break;
    default:
      return null;
  }

  return (
    <div
      className={`flex justify-between px-6 py-4 pr-10 border ${borderColour} mb-5 rounded-lg relative ${bgColour}`}
    >
      <div className="flex flex-row">
        <div className="mr-2 flex">{icon}</div>
        <div className="flex flex-col">
          <div className="font-semibold flex items-center">
            <h2>{title}</h2>
          </div>
          <div className="text-sm mt-2">
            <p>{message}</p>
          </div>
        </div>
      </div>
      <IconButton aria-label="delete" onClick={onDismiss}>
        <Close />
      </IconButton>
    </div>
  );
};

export default ClioSyncErrorBanner;
