import { useQuery, UseQueryOptions } from 'react-query';
import { APIError } from '~/src/utils/error';
import { composeQueryOpts } from '../../utils';

import {
  getClioQuestionnaireAuthToken,
  questionnaireAuthorizationHeaders,
} from '../utils';
import { getQuestionnaireSubmissionById } from '../urls';
import { type QuestionnaireSubmission } from '@clio/questionnaire-builder';
import { submissionQueryArgs } from '../queryArgs';

export const useClioQuestionnaireSubmissionById = (
  orgFprint: string,
  submissionId: string,
  options: UseQueryOptions<QuestionnaireSubmission, APIError> = {},
) =>
  useQuery(
    composeQueryOpts(options, {
      queryKey: submissionQueryArgs.detail(submissionId),
      queryFn: async () => {
        const token = await getClioQuestionnaireAuthToken(orgFprint);
        const response = await fetch(
          getQuestionnaireSubmissionById(submissionId),
          {
            headers: questionnaireAuthorizationHeaders(token),
          },
        );

        if (!response.ok)
          throw new APIError(
            'Failed to retrieve selected questionnaire submission',
            response,
          );

        return response.json();
      },
    }),
  );
