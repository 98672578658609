import { useInfiniteQuery } from 'react-query';
import { Contact } from '../types';
import { keys } from '../keys';
import { QueryParams, getMatterContactsUrl } from '../urls';
import { fetchAndParse } from '../../utils';
import { useCurrentOrgFprint } from '../../user';

export const useContacts = (
  matterId: string | number,
  parameters: Partial<QueryParams> = { limit: 10 },
) => {
  const orgFprint = useCurrentOrgFprint();
  return useInfiniteQuery(
    keys.relatedList(matterId, parameters),
    async ({ pageParam }) => {
      const params = { ...parameters, page: pageParam || 1, matter: matterId };
      return {
        contacts: await fetchAndParse<Contact[]>(
          getMatterContactsUrl(orgFprint, matterId, params),
        ),
        params,
      };
    },
    {
      keepPreviousData: true,
      getNextPageParam: ({ contacts, params }) =>
        contacts.length === params.limit ? params.page + 1 : undefined,
    },
  );
};
