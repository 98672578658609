import React from 'react';
import { NavLink } from 'react-router-dom';

import { ReactComponent as ChevronRightIcon } from './chevron-right.svg';

const DrillDownPanel = ({ label, linkUrl }) => (
  <NavLink
    className="hover:bg-gray-50 flex justify-between p-4 border-b last:border-0 border-slate-200"
    to={linkUrl}
  >
    <div className="top-4 left-2 text-black">{label}</div>

    <div className="top-4 right-5">
      <ChevronRightIcon />
    </div>
  </NavLink>
);

export default DrillDownPanel;
