import React from 'react';
import Button from '~/src/components/Button';
import { css } from 'aphrodite';
import PropTypes from 'prop-types';

import styles from './styles';

const Warning = ({
  title,
  message,
  primaryActionTitle,
  onCancel,
  onConfirm,
}) => (
  <div className={css(styles.modalBody)}>
    <h1 className={css(styles.modalTitle)}>{title}</h1>
    <p className={css(styles.modalMessage)}>{message}</p>
    <div className={css(styles.modalActions)}>
      <Button cssStyle={styles.buttonAction} onClick={onCancel}>
        Cancel
      </Button>{' '}
      <Button cssStyle={styles.buttonAction} onClick={onConfirm} alert>
        {primaryActionTitle}
      </Button>
    </div>
  </div>
);

Warning.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
};

Warning.defaultProps = {
  title: 'Warning',
  message: 'Are you sure?',
  primaryActionTitle: 'Confirm',
};

export default Warning;
