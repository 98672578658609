import { Box, Typography } from '@mui/material';
import React from 'react';
import {
  BillingCycleType,
  COURT_FORMS,
  getNYSBASurrogatePrice,
  getCourtFormLabelFromPlanId,
  getCourtFormProductPrice,
  getPlanPrice,
  getSeatsProductPrice,
  getWordDocumentAutomationProductPrice,
} from '~/src/entities/subscription';
import { SummaryItem } from './SummaryItem';

type RecurringChargesProps = {
  contentPackage?: boolean;
  courtForms: string[];
  word: boolean;
  seats: number;
  billingCycle: BillingCycleType;
};

export const RecurringCharges = ({
  seats,
  contentPackage,
  courtForms,
  word,
  billingCycle,
}: RecurringChargesProps) => {
  return (
    <>
      <Box pb={2}>
        <Typography variant={'subheading'}>Recurring charges</Typography>
      </Box>
      <Box data-testid="recurring-charges" pb={2}>
        <SummaryItem
          key={'seats'}
          name={`${seats} seat${seats === 1 ? '' : 's'}`}
          price={getPlanPrice(
            getSeatsProductPrice(billingCycle) * seats,
            billingCycle,
          )}
        />

        {contentPackage && (
          <SummaryItem
            name="NYSBA Surrogate forms"
            price={getPlanPrice(getNYSBASurrogatePrice(), billingCycle)}
          />
        )}

        {COURT_FORMS.filter(({ id }) => courtForms.includes(id)).map(
          ({ id }) => {
            return (
              <SummaryItem
                key={id}
                name={getCourtFormLabelFromPlanId(id)}
                price={getPlanPrice(
                  getCourtFormProductPrice(billingCycle),
                  billingCycle,
                )}
              />
            );
          },
        )}
        {word ? (
          <SummaryItem
            key={'word'}
            name={'Word document automation'}
            price={getPlanPrice(
              getWordDocumentAutomationProductPrice(billingCycle),
              billingCycle,
            )}
          />
        ) : null}
      </Box>
    </>
  );
};
