import React, { FC } from 'react';

interface Props {
  stateName: string;
  children: React.ReactNode;
}
const StateSection: FC<Props> = ({ stateName, children }) => {
  return (
    <div className="my-5">
      <div className="p-2 font-medium">{stateName}</div>

      <div className="flex flex-col bg-white rounded-lg overflow-hidden border border-slate-200">
        {children}
      </div>
    </div>
  );
};

export default StateSection;
