import React from 'react';
import { styled } from '@mui/material';
import Button from '~/src/components/Button';
import MiniSpinner from '~/src/components/Spinner/MiniSpinner';

type PopulatePageFooterProps = {
  onGenerate: () => void;
  onCancel: () => void;
  isGenerating: boolean;
  isSaving: boolean;
};

const Div = styled('div')({
  display: 'flex',
});

const BackButton = styled(Button)({
  marginRight: '16px',
});

const GenerateButton = styled(Button)({
  width: '228px',
  svg: {
    height: '21px',
  },
});

export const PopulatePageFooter = ({
  onGenerate,
  onCancel,
  isGenerating,
  isSaving,
}: PopulatePageFooterProps) => {
  return (
    <Div>
      <BackButton onClick={onCancel}>Cancel</BackButton>
      <GenerateButton
        analyticsname="Document Generated"
        primary
        onClick={onGenerate}
        disabled={isGenerating || isSaving}
      >
        {(isGenerating || isSaving) && <MiniSpinner />}
        {isGenerating
          ? 'Generating document(s)'
          : isSaving
          ? 'Saving changes'
          : 'Generate document(s)'}
      </GenerateButton>
    </Div>
  );
};
