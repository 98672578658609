import React, { useState, memo } from 'react';
import { useObserver } from 'mobx-react';
import cn from 'classnames';

/* Constants */
import { LAYOUT_SLIDEIN_TYPES } from '~/src/components/PageLayout/SlideIns';
import { LAYOUT_MODAL_TYPES } from '~/src/components/PageLayout/Modals';

/* Components */
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  PlusCircle,
  Video,
  Info,
  X,
  Copy,
  Trash,
  Plus,
  Maximize2,
  Minimize2,
} from 'react-feather';

import { useLayoutContext } from '~/src/contexts/Layout';
import Question from './Question';
import QuestionOptionsMenuOption from './QuestionOptionsMenuOption';
import AddAboveButtonBar from './AddAboveButtonBar';

/* Hooks */

const DragHandle = ({ dragHandleProps, isDragging, show }) => (
  <div
    {...dragHandleProps}
    style={{ cursor: isDragging ? 'grabbing' : 'move' }}
    className={cn(
      'transition-all absolute top-1 left-1/2 w-8 h-4 flex items-center -ml-4 px-2 py-1 rounded hover:shadow cursor-move',
      {
        'opacity-0': !show,
      },
    )}
  >
    <div className="w-full">
      <div className="w-full bg-blueGray-400 h-0.5 mb-0.5" />
      <div className="w-full bg-blueGray-400 h-0.5" />
    </div>
  </div>
);

const AddNewQuestion = ({ onClick: handleClick }) => (
  <div
    className="group m-6 flex items-center justify-center transition-all cursor-pointer transition-all opacity-80 hover:opacity-100"
    onClick={handleClick}
  >
    <div className="rounded-full h-5 w-5 mr-3 inline-flex items-center justify-center bg-black text-white transition-all group-hover:bg-blue-600">
      <Plus size={14} color={'white'} />
    </div>
    <span className="text-base inline-block transition-all group-hover:text-blue-600">
      Add new question
    </span>
  </div>
);

const GroupOptionActions = ({
  expanded,
  show,
  id,

  onAddInstructions: handleAddInstructions,
  onAddQuestionSet: handleAddQuestionSet,
  onDuplicate: handleDuplicate,
  onRemove: handleRemove,
  onExpand: handleExpand,
  onCollapse: handleCollapse,

  onAddQuestionSetMouseEnter: handleAddQuestionSetMouseEnter,
  onAddQuestionSetMouseLeave: handleAddQuestionSetMouseLeave,
  onDuplicateMouseEnter: handleDuplicateMouseEnter,
  onDuplicateMouseLeave: handleDuplicateMouseLeave,
}) => {
  const rootId = `question-group-options-menu-${id}`;
  const questionAddId = `${rootId}-add`;
  const questionDuplicateId = `${rootId}-duplicate`;
  const questionRemoveId = `${rootId}-remove`;
  const questionAddInstructionsId = `${rootId}-add-instructions`;

  const questionExpandId = `${rootId}-expand`;
  const questionCollapseId = `${rootId}-collapse`;

  return (
    <div
      className={cn(
        'bg-white shadow absolute p-2 rounded-full transition-all duration-400 opacity-0 top-0 -left-12 cursor-pointer hover:opacity-100  z-40',
        {
          'opacity-100': show,
        },
      )}
    >
      <QuestionOptionsMenuOption
        id={questionAddId}
        Icon={PlusCircle}
        label="Add new question group"
        onMouseDown={handleAddQuestionSet}
        onMouseEnter={handleAddQuestionSetMouseEnter}
        onMouseLeave={handleAddQuestionSetMouseLeave}
      />
      {!expanded && (
        <QuestionOptionsMenuOption
          className="my-2"
          id={questionExpandId}
          Icon={Maximize2}
          label="Expand question group"
          onMouseDown={handleExpand}
        />
      )}

      {expanded && (
        <QuestionOptionsMenuOption
          className="my-2"
          id={questionCollapseId}
          Icon={Minimize2}
          label="Collapse question group"
          onMouseDown={handleCollapse}
        />
      )}

      <QuestionOptionsMenuOption
        className="my-2"
        id={questionAddInstructionsId}
        Icon={Info}
        label="Add instructions"
        onMouseDown={handleAddInstructions}
      />
      <QuestionOptionsMenuOption
        className="my-2"
        id={questionDuplicateId}
        Icon={Copy}
        label="Duplicate question group"
        onMouseDown={handleDuplicate}
        onMouseEnter={handleDuplicateMouseEnter}
        onMouseLeave={handleDuplicateMouseLeave}
      />
      <QuestionOptionsMenuOption
        className="my-2"
        id={questionRemoveId}
        Icon={Trash}
        label="Remove question set"
        onMouseDown={handleRemove}
      />
    </div>
  );
};

const QuestionGroupContainer = (props) => {
  const {
    id,
    showAddQuestionSetBar,
    dragHandleProps,
    hovering,
    isDragging,
    expanded,
    questionHover,
    name,
    description,
    videoUrl,
    questionCount,

    onClick: handleClick,
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,

    onRemoveVideo: handleRemoveVideo,
    onNameChange: handleNameChange,
    onInstructionsClick: handleInstructionsClick,
    onAddQuestionSet: handleAddQuestionSet,
    onDuplicate: handleDuplicate,
    onRemove: handleRemove,
    onExpand: handleExpand,
    onCollapse: handleCollapse,
    onAddQuestionSetMouseEnter: handleAddQuestionSetMouseEnter,
    onAddQuestionSetMouseLeave: handleAddQuestionSetMouseLeave,
    onDuplicateMouseEnter: handleDuplicateMouseEnter,
    onDuplicateMouseLeave: handleDuplicateMouseLeave,

    children,
  } = props;

  return (
    <div
      id={id}
      className={cn(
        'relative my-4 p-2 bg-white rounded shadow-sm hover:shadow-lg tranistion',
      )}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {showAddQuestionSetBar && <AddAboveButtonBar />}
      <DragHandle
        dragHandleProps={dragHandleProps}
        show={!showAddQuestionSetBar && hovering}
        sDragging={isDragging}
      />
      <GroupOptionActions
        expanded={expanded}
        show={hovering && !questionHover}
        id={id}
        onAddInstructions={handleInstructionsClick}
        onAddQuestionSet={handleAddQuestionSet}
        onDuplicate={handleDuplicate}
        onRemove={handleRemove}
        onExpand={handleExpand}
        onCollapse={handleCollapse}
        onAddQuestionSetMouseEnter={handleAddQuestionSetMouseEnter}
        onAddQuestionSetMouseLeave={handleAddQuestionSetMouseLeave}
        onDuplicateMouseEnter={handleDuplicateMouseEnter}
        onDuplicateMouseLeave={handleDuplicateMouseLeave}
      />
      <div className="flex-col justify-items-stretch">
        <div className="text-center m-4">
          <input
            className="p-4 w-full text-lg text-center outline-none rounded cursor-text transition-all hover:bg-blueGray-100 focus:bg-blueGray-100"
            type="text"
            placeholder="Click here to add title"
            value={name}
            onChange={handleNameChange}
          />
          {description && (
            <p
              className="mt-4 text-sm text-left cursor-pointer"
              onMouseDown={handleInstructionsClick}
            >
              {description}
            </p>
          )}
          {videoUrl && videoUrl.length > 0 && (
            <div
              className="mt-4 w-2/12 items-center p-1.5 rounded border-2 bg-blueGray-200 border-blueGray-200 border-solid text-xs flex justify-between truncate cursor-pointer"
              onMouseDown={handleInstructionsClick}
            >
              <Video size={12} color={'#000000'} />
              Video
              <span
                className="inline-block cursor-pointer"
                onMouseDown={handleRemoveVideo}
              >
                <X size={12} color={'#000000'} />
              </span>
            </div>
          )}
          {!expanded && (
            <div className="text-sm my-2 text-blueGray-500 italic">
              {questionCount} question{questionCount > 1 ? 's' : ''}
            </div>
          )}
        </div>
        {children}
      </div>
    </div>
  );
};

const QuestionGroup = memo((props) => {
  const { dragHandleProps, isDragging, group, onExpand } = props;

  const { id } = group;

  const { name, questions, description, videoUrl, expanded } = useObserver(
    () => ({
      name: group.name,
      questions: group.sortedQuestions(),
      description: group.description,
      videoUrl: group.videoUrl,
      expanded: group.expanded,
    }),
  );

  const { showSlideIn, showModal, hideModal } = useLayoutContext();

  const [hovering, setHovering] = useState(false);
  const [showAddQuestionSetBar, setShowAddQuestionSetBar] = useState(false);
  const [questionHover, setQuestionHover] = useState(false);

  const handleNameChange = (event) => {
    const value = event && event.target && event.target.value;
    group.setName(value);
  };

  const handleExpand = () => {
    group.setExpanded(true);
    if (onExpand) {
      onExpand(group);
    }
  };

  const handleCollapse = () => {
    group.setExpanded(false);
  };

  const handleMouseEnter = () => {
    setHovering(true);
  };

  const handleMouseLeave = () => {
    setHovering(false);
  };

  const onDragEnd = ({ source, destination }) => {
    const sourceIndex = (source && source.index) || 0;
    const destinationIndex = (destination && destination.index) || 0;
    group.moveQuestions(sourceIndex, destinationIndex);
  };

  const handleInstructionsUpdate = ({ description, videoUrl }) => {
    group.setInstructions({
      description,
      videoUrl,
    });
  };

  const handleInstructionsClick = () => {
    showSlideIn(LAYOUT_SLIDEIN_TYPES.questionInstructionalContent, {
      description,
      videoUrl,
      onConfirm: handleInstructionsUpdate,
    });
  };

  const handleAddQuestionSet = () => {
    group.createNewQuestionSet();
  };
  const handleDuplicate = () => {
    group.duplicateQuestionSet();
  };
  const handleRemove = () => {
    showModal(LAYOUT_MODAL_TYPES.confirm, {
      title: `Delete Question Group "${name}"?`,
      message: `Are you sure you want to permanently delete this question group? This action cannot be undone.`,
      onConfirm: () => {
        hideModal();
        group.remove();
      },
      primaryActionTitle: 'Delete',
    });
  };

  const handleAddQuestionSetMouseEnter = () => {
    setShowAddQuestionSetBar(true);
  };
  const handleAddQuestionSetMouseLeave = () => {
    setShowAddQuestionSetBar(false);
  };
  const handleDuplicateMouseEnter = () => {
    setShowAddQuestionSetBar(true);
  };
  const handleDuplicateMouseLeave = () => {
    setShowAddQuestionSetBar(false);
  };

  const handleAddQuestion = (event) => {
    event.stopPropagation();
    event.preventDefault();
    group.createNewQuestion();
  };

  const handleRemoveVideo = (event) => {
    event.stopPropagation();
    event.preventDefault();
    group.removeVideoUrl();
  };

  const handleQuestionMouseEnter = () => {
    setQuestionHover(true);
  };

  const handleQuestionMouseLeave = () => {
    setQuestionHover(false);
  };

  const handleClick = () => {
    handleExpand();
  };

  return (
    <QuestionGroupContainer
      id={id}
      showAddQuestionSetBar={showAddQuestionSetBar}
      dragHandleProps={dragHandleProps}
      hovering={hovering}
      isDragging={isDragging}
      expanded={expanded}
      questionHover={questionHover}
      name={name}
      description={description}
      videoUrl={videoUrl}
      questionCount={questions.length}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onRemoveVideo={handleRemoveVideo}
      onNameChange={handleNameChange}
      onInstructionsClick={handleInstructionsClick}
      onAddQuestionSet={handleAddQuestionSet}
      onDuplicate={handleDuplicate}
      onRemove={handleRemove}
      onExpand={handleExpand}
      onCollapse={handleCollapse}
      onAddQuestionSetMouseEnter={handleAddQuestionSetMouseEnter}
      onAddQuestionSetMouseLeave={handleAddQuestionSetMouseLeave}
      onDuplicateMouseEnter={handleDuplicateMouseEnter}
      onDuplicateMouseLeave={handleDuplicateMouseLeave}
    >
      {expanded && (
        <>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {questions.map((question, index) => (
                    <Draggable
                      key={question.id}
                      draggableId={question.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={provided.draggableProps.style}
                          className={cn({
                            'opacity-75': snapshot.isDragging,
                          })}
                        >
                          <Question
                            dragHandleProps={provided.dragHandleProps}
                            key={question.id}
                            question={question}
                            isDragging={snapshot.isDragging}
                            onMouseEnter={handleQuestionMouseEnter}
                            onMouseLeave={handleQuestionMouseLeave}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <AddNewQuestion onClick={handleAddQuestion} />
        </>
      )}
    </QuestionGroupContainer>
  );
});

QuestionGroup.defaultProps = {
  intake: {},
  onExpand: () => {},
};

export default QuestionGroup;
