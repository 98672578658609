/* Libs */
import React from 'react';
import qs from 'qs';

/* Hooks */
import useMst from '~/src/hooks/useMst';

/* Constants */
import { VALIDATION_TYPES } from '~/src/utils/validation';
import { LAYOUT_MODAL_TYPES } from '~/src/components/PageLayout/Modals';
import { LAYOUT_SLIDEIN_TYPES } from '~/src/components/PageLayout/SlideIns';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import { useLayoutContext } from '../contexts/Layout';
import { history } from '../utils/history';
import analyticsService from '~/src/services/analytics';

const useDocumentSetsActions = () => {
  const { projectsStore, documentSetsStore, deleteDocumentSetFromMatters } =
    useMst((store) => ({
      deleteDocumentSetFromMatters: store.matter.deleteDocumentSet,
      projectsStore: store.projects,
      documentSetsStore: store.documentSets,
    }));

  const { showSlideIn, showToast, showModal, hideModal, setTitle } =
    useLayoutContext();

  const getOrFetchDocumentSet = async (docSet: any) => {
    let fetchedDocSet = projectsStore.getProject(docSet.id);

    if (!fetchedDocSet) {
      fetchedDocSet = await projectsStore.fetchProjectData(
        docSet.id,
        docSet.title,
      );
    }

    return fetchedDocSet;
  };

  const handleDeleteDocumentSet = (docSet: any) => {
    const handleDeleteConfirm = async () => {
      deleteDocumentSetFromMatters(docSet.id);
      const success = await documentSetsStore.deleteDocument(docSet.id);

      if (success) {
        showToast(LAYOUT_TOAST_TYPES.success, {
          message: `Document set "${docSet.title}" deleted`,
        });
      } else {
        showToast(LAYOUT_TOAST_TYPES.error, {
          message: `Document set "${docSet.title}" could not be deleted`,
        });
      }

      hideModal();
    };

    showModal(LAYOUT_MODAL_TYPES.delete, {
      title: 'Delete Document Set',
      message: (
        <>
          <strong>Warning: this action is permanent.</strong>
          <br />
          <br />
          The documents contained in this set will also be deleted.
        </>
      ),
      onConfirm: handleDeleteConfirm,
      primaryActionTitle: 'Delete',
    });
  };

  const handleDownloadDocumentSet = async (docSet: any) => {
    const handleDownload = async (showHighlights: any) => {
      setTitle('Download Document Set');
      const fetchedDocSet = await getOrFetchDocumentSet(docSet);
      showToast(LAYOUT_TOAST_TYPES.loading, {
        message: `Document set "${docSet.title}" is downloading`,
      });

      if (!fetchedDocSet) {
        showToast(LAYOUT_TOAST_TYPES.error, {
          message: `Document set "${docSet.title}" could not be downloaded`,
        });
        return;
      }

      const success = await fetchedDocSet.exportDocSet(showHighlights);

      if (success) {
        showToast(LAYOUT_TOAST_TYPES.success, {
          message: `Document set "${docSet.title}" successfully downloaded`,
        });
      } else {
        showToast(LAYOUT_TOAST_TYPES.error, {
          message: `Document set "${docSet.title}" could not be downloaded`,
        });
      }

      hideModal();
    };

    const hasProcessedVersion = docSet.documents.every(
      (doc: any) => doc.processedDocx,
    );

    if (hasProcessedVersion) {
      showModal(LAYOUT_MODAL_TYPES.downloadDocument, {
        documentTitle: docSet.title,
        onConfirm: handleDownload,
      });
    } else {
      handleDownload(true);
    }
  };

  const handleEditDocumentSet = async (docSet: any) => {
    setTitle('Edit Document Set');
    const fetchedDocSet = await getOrFetchDocumentSet(docSet);

    if (fetchedDocSet) {
      analyticsService.track('Document Set Edited', {
        projectId: fetchedDocSet.id,
      });
      history.push(`/populate/${fetchedDocSet.id}`);
    }
  };

  const handleViewDocumentSet = async (docSet: any) => {
    setTitle('View Document Set');
    const fetchedDocSet = await getOrFetchDocumentSet(docSet);

    if (fetchedDocSet) {
      showSlideIn(LAYOUT_SLIDEIN_TYPES.projectPreview, {
        largeSlideIn: true,
        projectId: fetchedDocSet.id,
      });
    }
  };

  const handleRenameDocumentSet = async (docSet: any) => {
    const handleRenameConfirm = async (form: {
      fields: { name: { value: string } };
    }) => {
      const { value } = form.fields.name;
      const success = await projectsStore.updateProject(docSet.id, {
        title: value,
      });

      if (success) {
        showToast(LAYOUT_TOAST_TYPES.success, {
          message: `Document set "${value}" renamed successfully.`,
        });
      } else {
        showToast(LAYOUT_TOAST_TYPES.error, {
          message: `Document set "${docSet.title}" could not be renamed`,
        });
      }

      hideModal();
    };

    showModal(LAYOUT_MODAL_TYPES.formField, {
      title: 'Rename Document Set',
      primaryActionTitle: 'Save',
      fields: [
        {
          label: 'Name',
          id: 'name',
          type: 'text',
          defaultValue: docSet.title,
          validation: VALIDATION_TYPES.present,
        },
      ],
      onConfirm: handleRenameConfirm,
    });
  };

  const handleDraftNewDocumentSet = async (docSet: any) => {
    const project = projectsStore.getProject(docSet.id);
    const templateIds = project && project.getTemplateIds();

    if (templateIds) {
      const queryParams = qs.stringify({ ids: templateIds }, { encode: false });
      history.push(`/templates/docset/new?${queryParams}`);
    } else {
      const error = 'Drafted document is missing template ID';
      console.error(error, new Error(error));
      showToast(LAYOUT_TOAST_TYPES.error, {
        message: 'Something went wrong, please try again later.',
      });
    }
  };

  return {
    deleteDocumentSet: handleDeleteDocumentSet,
    downloadDocumentSet: handleDownloadDocumentSet,
    editDocumentSet: handleEditDocumentSet,
    viewDocumentSet: handleViewDocumentSet,
    renameDocumentSet: handleRenameDocumentSet,
    newDocumentSet: handleDraftNewDocumentSet,
  };
};

export default useDocumentSetsActions;
