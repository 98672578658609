import React, { FC } from 'react';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  indicatorColor: string;
}

const TextWithIndicator: FC<Props> = ({
  indicatorColor,
  style,
  children,
  ...rest
}) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      gap: 5,
      ...(style || {}),
    }}
    {...rest}
  >
    <div
      style={{
        borderRadius: '50%',
        backgroundColor: indicatorColor,
        minWidth: 10,
        minHeight: 10,
      }}
    />
    <div>{children}</div>
  </div>
);

TextWithIndicator.propTypes = {
  // indicatorColor: hexCode.isRequired, // this line causes => Warning: Failed prop type: Invalid prop `indicatorColor` supplied to `TextWithIndicator`. Must be a valid color code.
};

export default TextWithIndicator;
