/* Libraries */
import { camelCase } from 'lodash';

/* Services */
import contactsService from '~/src/services/api/contactsService';

/* Utilities */
import { mapContact } from '~/src/utils/dataTransformers';

function contacts() {
  const fetchDefaultContactFields = async (orgFprint, contactId) => {
    let response;

    if (contactId) {
      response = await contactsService.postContactFieldMap(
        orgFprint,
        [],
        contactId,
      );
      response.fields = Object.keys(response);
    } else {
      response = await contactsService.fetchDefaultContactFields(orgFprint);
    }

    if (response.fields) {
      return response.fields.map(camelCase);
    }

    return [];
  };

  const detachContactFromCard = async (
    orgFprint,
    matterId,
    contactId,
    entity,
  ) => {
    return contactsService.detachContactFromCard(
      orgFprint,
      matterId,
      contactId,
      entity,
    );
  };

  const attachContactToCard = async (
    orgFprint,
    projectId,
    contactId,
    entity,
    attributes,
  ) => {
    return contactsService.attachContactToCard(
      orgFprint,
      projectId,
      contactId,
      entity,
      attributes,
    );
  };

  const createNewContact = async (orgFprint, contact) => {
    const { type, ...contactFields } = contact;
    const nextContact = await contactsService.createContact(
      orgFprint,
      type,
      contactFields,
    );
    return mapContact(nextContact);
  };

  const getContacts = async ({
    page,
    search,
    sortBy,
    order,
    limit,
    orgFprint,
  }) => {
    const contacts = await contactsService.getContacts(orgFprint, {
      page,
      search,
      sortBy,
      order,
      limit,
    });

    return contacts.map((contact) => mapContact(contact));
  };

  const fetchContact = async (orgFprint, contactId) => {
    const res = await contactsService.fetchContact(orgFprint, contactId);
    return mapContact(res);
  };

  const fetchContactInSpecificMatter = async (
    orgFprint,
    contactId,
    matterId,
  ) => {
    const res = await contactsService.fetchContactInSpecificMatter(
      orgFprint,
      contactId,
      matterId,
    );
    return mapContact(res);
  };

  const updateContact = async (orgFprint, contact) => {
    const { id, type, fields } = contact;

    return mapContact(
      contactsService.updateContact(orgFprint, id, type, fields),
    );
  };

  const deleteContact = async (orgFprint, contactId) => {
    return contactsService.deleteContact(orgFprint, contactId);
  };

  return {
    fetchDefaultContactFields,
    detachContactFromCard,
    attachContactToCard,
    deleteContact,
    updateContact,
    createNewContact,
    getContacts,
    fetchContact,
    fetchContactInSpecificMatter,
  };
}

export default contacts();
