import { ArrowBack } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import {
  BillingCycleType,
  getProductID,
  useCreateSubscriptionV2,
  NYSBA_SURROGATE_ID,
  WORD_PRODUCT_ID,
  PaymentFormValues,
} from '~/src/entities/subscription';
import { useCurrentOrg } from '~/src/entities/user';
import { ConfigurePlan } from './ConfigurePlan';
import { SubscribePaymentForm } from './SubscribePaymentForm';
import { Summary } from './Summary';

type SubscribeState = {
  seats: number;
  contentPackage: boolean;
  courtForms: string[];
  billingCycle: BillingCycleType;
  word: boolean;
  coupon: string;
};

type SubscribeProps = {
  onSubscribe: () => Promise<void>;
};

export const Subscribe = ({ onSubscribe }: SubscribeProps) => {
  const [confirming, setConfirming] = useState(false);
  const { isError, mutate, reset } = useCreateSubscriptionV2({
    onSuccess: onSubscribe,
  });

  const isNysbaSignup = useCurrentOrg().org?.signup_source === 'nysba';
  const [formState, setFormState] = useState<SubscribeState>({
    seats: 1,
    contentPackage: isNysbaSignup ? true : false,
    courtForms: [],
    billingCycle: isNysbaSignup ? 'annual' : 'monthly',
    word: false,
    coupon: '',
  });

  const handleSubmit = async ({ exp, ...card }: PaymentFormValues) => {
    let add_plans: string[] = [];

    if (formState.contentPackage)
      add_plans.push(getProductID(formState.billingCycle, NYSBA_SURROGATE_ID));

    formState.courtForms.forEach((formId) => {
      add_plans.push(getProductID(formState.billingCycle, formId));
    });

    if (formState.word)
      add_plans.push(getProductID(formState.billingCycle, WORD_PRODUCT_ID));

    const exp_month = +exp.slice(0, 2);
    const exp_year = +exp.slice(2);

    return new Promise<void>((res) => {
      mutate(
        {
          card: { ...card, exp_month, exp_year },
          add_plans,
          duration: formState.billingCycle,
          seat: formState.seats,
          coupon: formState.coupon,
        },
        { onSettled: () => res() },
      );
    });
  };

  const hasAtLeastOnePlanSelected =
    formState.word ||
    formState.courtForms.length !== 0 ||
    formState.contentPackage;

  return (
    <Box display={'flex'} justifyContent={'center'}>
      <Box maxWidth={'90%'} width={'900px'}>
        <Box pb={2}>
          <Box display={'flex'} height={'30px'} alignItems={'center'}>
            {confirming ? (
              <Box pr={1}>
                <IconButton onClick={() => setConfirming(false)}>
                  <ArrowBack />
                </IconButton>
              </Box>
            ) : null}
            <Typography variant={'subheading'}>Configure your plan</Typography>
          </Box>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={7}>
            <Card>
              <Box p={3}>
                {isError ? (
                  <Box py={2}>
                    <Alert
                      onClose={reset}
                      variant={'filled'}
                      severity={'error'}
                    >
                      An error occurred
                    </Alert>
                  </Box>
                ) : null}
                {confirming ? (
                  <SubscribePaymentForm onSubmit={handleSubmit} />
                ) : (
                  <ConfigurePlan
                    {...formState}
                    onChange={(params) =>
                      setFormState((s) => ({ ...s, ...params }))
                    }
                  />
                )}
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={5}>
            <Card>
              <Summary
                {...formState}
                renderExtra={(isLoading, isError) => {
                  if (confirming) return null;
                  if (isLoading || isError) return null;
                  return (
                    <Box pt={1}>
                      <Button
                        fullWidth
                        data-testid="subscribe-continue-button"
                        size={'large'}
                        disabled={!hasAtLeastOnePlanSelected}
                        variant={'contained'}
                        onClick={() => setConfirming(true)}
                      >
                        Next
                      </Button>
                      {hasAtLeastOnePlanSelected ? null : (
                        <Box pt={2}>
                          <Typography textAlign={'center'} variant={'body1'}>
                            Choose at least 1 plan to continue
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  );
                }}
                onChangeCoupon={(coupon) =>
                  setFormState((s) => ({ ...s, coupon }))
                }
              />
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
