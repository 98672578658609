import React from 'react';
import { css } from 'aphrodite';

import styles from './styles';

const Container = ({ children }) => (
  <aside className={css(styles.container)}>{children}</aside>
);

export default Container;
