import React from 'react';
import { css } from 'aphrodite';
import { startCase } from 'lodash';
import { inject, observer } from 'mobx-react';

/* Higher Order Components */
import WithLayoutProps from '~/src/hoc/WithLayoutProps';

/* Components */
import CreditCardForm from '~/src/components/CreditCardForm';
import Button from '~/src/components/Button';
import { Form, renderFormField } from '~/src/components/Forms';

/* Styles */
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import styles from './styles';

/* Constants */

const ChoosePlanForm = ({
  selectedPlan,
  planPrice,
  totalPrice,
  onChange,
  onCancel,
  onConfirm,
}) => {
  const fields = [
    {
      id: 'plan',
      label: 'Plan',
      type: 'multipleChoice',
      defaultValue: 'pro',
      options: [
        // { label: "Basic", value: "basic" },
        { label: 'Pro', value: 'pro' },
      ],
      validation: () => true,
    },
    {
      id: 'seats',
      label: 'Number of users',
      type: 'incrementer',
      minValue: 1,
      maxValue: 10,
      defaultValue: 1,
      validation: () => true,
    },
  ];

  const renderFields = fields.map((field) => renderFormField(field));

  return (
    <div className={css(styles.modalContainer)}>
      <h1 className={css(styles.modalTitle)}>Choose plan</h1>
      <h2 className={css(styles.modalSubtitle)}>
        {startCase(selectedPlan)} plan ${planPrice} / month per user, billed
        monthly
      </h2>
      <div className={css(styles.modalBody)}>
        <Form triggerOnMount fields={renderFields} onChange={onChange} />
      </div>
      <div className={css(styles.modalCreateSubscriptionDueToday)}>
        Due today: <span>${totalPrice}</span>
      </div>
      <div className={css(styles.modalActions)}>
        <Button cssStyle={styles.buttonAction} onClick={onCancel}>
          Cancel
        </Button>{' '}
        <Button cssStyle={styles.buttonAction} onClick={onConfirm} primary>
          Next
        </Button>
      </div>
    </div>
  );
};

const PaymentMethodForm = ({ onCancel, onChange, onSubmit }) => {
  return (
    <div className={css(styles.modalContainer)}>
      <h1 className={css(styles.modalTitle)}>Payment method</h1>
      <div className={css(styles.modalBody)}>
        <CreditCardForm onChange={onChange} />
      </div>
      <div className={css(styles.modalActions)}>
        <Button cssStyle={styles.buttonAction} onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={onSubmit} cssStyle={styles.buttonAction} primary>
          Submit
        </Button>
      </div>
    </div>
  );
};

class CreateSubscriptionModal extends React.Component {
  state = {
    selectedPlan: 'pro',
    seats: 1,
    showPaymentMethod: false,
    paymentFormValue: {},
  };

  handlePlanChange = (form) => {
    const selectedPlan = form.fields.plan.value;
    const seats = form.fields.seats.value;

    this.setState({ selectedPlan, seats });
  };

  handlePaymentMethodChange = (form) => {
    this.setState({
      paymentFormValue: form,
    });
  };

  handlePaymentMethodSubmit = async () => {
    const formFields = this.state.paymentFormValue.fields;

    const ccExpMonth = formFields.exp.value.split('/')[0];
    const ccExpYear = formFields.exp.value.split('/')[1];
    const addressZip = formFields.address_zip.value;
    const addressCity = formFields.address_city.value;
    const addressState = formFields.address_state.value;
    const cvc = formFields.cvc.value;
    const number = formFields.number.value;

    const card = {
      number,
      cvc,
      exp_month: ccExpMonth,
      exp_year: ccExpYear,
      address_zip: addressZip,
      address_state: addressState,
      address_city: addressCity,
    };

    const plan = this.state.selectedPlan;
    const { seats } = this.state;

    const res =
      await this.props.store.user.currentOrganization.createSubscription({
        plan,
        seats,
        card,
      });

    if (res) {
      this.props.showToast(LAYOUT_TOAST_TYPES.success, {
        message: 'Subscription created!',
      });
      setTimeout(this.props.onCancel, 500);
    } else {
      this.props.showToast(LAYOUT_TOAST_TYPES.error, {
        message: 'Something went wrong, please try again later.',
      });
    }
  };

  handleCancel = () => {
    if (this.state.showPaymentMethod) {
      this.setState({ showPaymentMethod: false });
    } else {
      this.props.onCancel();
    }
  };

  handlePlanConfirm = () => {
    this.setState({ showPaymentMethod: true });
  };

  render() {
    const { selectedPlan, seats, showPaymentMethod } = this.state;

    const planPricing = {
      basic: 59,
      pro: 99,
    };

    const planPrice = planPricing[selectedPlan];
    const totalPrice = planPricing[selectedPlan] * seats;

    return showPaymentMethod ? (
      <PaymentMethodForm
        onCancel={this.handleCancel}
        onChange={this.handlePaymentMethodChange}
        onSubmit={this.handlePaymentMethodSubmit}
      />
    ) : (
      <ChoosePlanForm
        selectedPlan={selectedPlan}
        planPrice={planPrice}
        totalPrice={totalPrice}
        onChange={this.handlePlanChange}
        onCancel={this.handleCancel}
        onConfirm={this.handlePlanConfirm}
      />
    );
  }
}

export default inject((store) => store)(
  WithLayoutProps(observer(CreateSubscriptionModal)),
);
