import React from 'react';
import { type QuestionnaireSubmission } from '@clio/questionnaire-builder';
import { QuestionnaireSubmissionTableCardListItem } from './QuestionnaireSubmissionTableCardListItem';
import { RelatedQuestionnaireSubmission } from '~/src/entities/questionnairesV2/types';

export const QuestionnaireSubmissionTableCardList: React.FC<{
  submissions: QuestionnaireSubmission[];
  onRename: (
    submission: QuestionnaireSubmission,
    relatedQuestionnaireSubmissions:
      | RelatedQuestionnaireSubmission[]
      | undefined,
  ) => void;
  onDelete: (
    submission: QuestionnaireSubmission,
    relatedQuestionnaireSubmissions:
      | RelatedQuestionnaireSubmission[]
      | undefined,
  ) => void;
  onViewDetails: (submission: QuestionnaireSubmission) => void;
}> = ({ submissions, onRename, onDelete, onViewDetails }) => {
  return (
    <>
      {submissions.map((submission) => {
        return (
          <QuestionnaireSubmissionTableCardListItem
            key={submission.id}
            submission={submission}
            onRename={onRename}
            onDelete={onDelete}
            onViewDetails={onViewDetails}
          />
        );
      })}
    </>
  );
};
