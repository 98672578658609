/* Libraries */
import React from 'react';
import { observer, inject } from 'mobx-react';

/* Components */
import SettingsIntegrations from '~/src/components/Settings/SettingsIntegrations';

/* Higher Order Components */
import WithLayoutProps from '~/src/hoc/WithLayoutProps';

/* Constants */
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import { LAYOUT_MODAL_TYPES } from '~/src/components/PageLayout/Modals';

class SettingsIntegrationsContainer extends React.Component {
  showSuccessToast(message) {
    this.props.showToast(LAYOUT_TOAST_TYPES.success, { message });
  }

  showErrorToast(message) {
    this.props.showToast(LAYOUT_TOAST_TYPES.error, { message });
  }

  componentDidMount() {
    this.refreshTimerId = setInterval(async () => {
      const result = await this.props.store.clio.updateClioIntegrationData();

      if (result !== null) {
        if (result === 'enabled') {
          this.showSuccessToast('Clio data successfuly synced!');
        } else if (result === 'error') {
          this.showErrorToast(
            'Something went wrong while syncing your Clio data, please contact us.',
          );
        }
      }
    }, 10000);
  }

  componentWillUnmount() {
    clearInterval(this.refreshTimerId);
  }

  handleConnectRedirect = () => {
    const redirectOrigin =
      window && window.location
        ? window.location.origin
        : 'https://app.lawyaw.com';

    const redirect = `${redirectOrigin}/clients/clio/connect`;
    const authUrl = this.props.store.clio.getAuthUrl(redirect);

    window.location = authUrl;
  };

  handleOnSync = async () => {
    const success = await this.props.store.clio.sync();

    if (success) {
      this.props.showToast(LAYOUT_TOAST_TYPES.success, {
        message: 'Clio data succesfully started!',
      });
    } else {
      this.props.showToast(LAYOUT_TOAST_TYPES.error, {
        message:
          'Something went wrong syncing your Clio data, please try again later.',
      });
    }
  };

  handleOnDisconnect = () => {
    this.props.showModal(LAYOUT_MODAL_TYPES.clioDisconnect, {
      disableClickCatcher: true,
      onConfirm: async () => {
        const success = await this.props.store.clio.deauthenticate();

        if (success) {
          this.props.showToast(LAYOUT_TOAST_TYPES.success, {
            message: 'Clio integration disconnected',
          });
        } else {
          this.props.showToast(LAYOUT_TOAST_TYPES.error, {
            message:
              'We could not disconnect your Clio integration. Please contact support.',
          });
        }

        this.props.hideModal();
      },
      onCancel: () => {
        this.props.hideModal();
      },
    });
  };

  render() {
    const lastSynced = this.props.store.clio.lastSyncedDisplayText();
    const enabled = this.props.store.clio.authenticated;
    const { syncing } = this.props.store.clio;
    const { clioSyncStatus } = this.props.store.clio;

    return (
      <SettingsIntegrations
        syncing={syncing}
        lastSynced={lastSynced}
        enabled={enabled}
        clioSyncStatus={clioSyncStatus}
        onConnect={this.handleConnectRedirect}
        onSync={this.handleOnSync}
        onDisconnect={this.handleOnDisconnect}
      />
    );
  }
}

export default inject((store) => store)(
  WithLayoutProps(observer(SettingsIntegrationsContainer)),
);
