import { useObserver } from 'mobx-react';
import useMst from '~/src/hooks/useMst';
import useResourcePagination, {
  SearchRequestPayload,
} from '~/src/hooks/useResourcePagination';
import SortByOrder from '~/src/hooks/sortByOrder';
import { CourtFormSortByOption } from '~/src/hooks/courtFormSortByOption';

const usePdfCourtFormsPagination = (requestPayload: SearchRequestPayload) => {
  const { pdfCourtForms } = useMst((store) => ({
    pdfCourtForms: store.pdfCourtForms,
  }));

  const { loading, next, page, empty, setSearch, done, setSortBy, setOrder } =
    useResourcePagination(
      {
        fetch: pdfCourtForms.fetch,
        limit: 10,
        order: SortByOrder.asc,
        sortBy: CourtFormSortByOption.title,
      },
      requestPayload,
    );

  const listData = useObserver(() => {
    return pdfCourtForms.list.flat();
  });

  return {
    next,
    page,
    empty,
    data: listData,
    loading,
    done,

    setSearch,
    setSortBy,
    setOrder,
  };
};

export default usePdfCourtFormsPagination;
