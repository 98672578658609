import React from 'react';
import { css } from 'aphrodite';
import { ReactComponent as AttachContactEmptyStateSvg } from '~/src/static/attach-contact-empty-state.svg';

import Button from '~/src/components/Button';

import styles from './styles';

const AttachContactAddContacts = () => (
  <div className={css(styles.addContactContainer)}>
    <div className={css(styles.addContactTitle)}>Attach contacts to cards</div>
    <p className={css(styles.addContactDescription)}>
      Create or import contacts so you can attach people and companies to cards
      to speed up your workflow
    </p>
    <a
      target={'_blank'}
      href="https://www.lawyaw.com/faq/"
      className={css(styles.addContactLearMoreLink)}
      rel="noreferrer"
    >
      Learn more
    </a>
    <div className={css(styles.addContactIllustrationContainer)}>
      <AttachContactEmptyStateSvg
        style={{ margin: '0 auto', display: 'block' }}
      />
    </div>
    <div className={css(styles.addContactButtonContainer)}>
      <Button cssStyle={styles.addContactButton} primary>
        Create contact
      </Button>
      <Button cssStyle={styles.addContactButton}>Import Contacts</Button>
    </div>
  </div>
);

export default AttachContactAddContacts;
