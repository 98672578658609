import React, { FC, useEffect } from 'react';
import { snakeCase } from 'lodash';
import { useObserver } from 'mobx-react';
import { Instance } from 'mobx-state-tree';

/* Components */
import TemplateSetsTable from './TemplateSetsTable';
import TemplateSetsEmptyView from './TemplateSetsEmptyView';
import LoadMoreDisplayText from '~/src/components/LoadMoreDisplayText';

/* Hooks */
import usePageScroll from '~/src/hooks/usePageScroll';
import useTemplateSetsPagination from '~/src/hooks/useTemplateSetsPagination';

/* Store */
import { TemplateSetStore } from '~/src/stores/templateSetsStore';

/* Types */
import { SortByOrder } from '~/src/hooks/sortByOrder';

interface TemplateSetTableRowData {
  id: number;
  title: string;
  defaultValuesId: number;
  subtitle: string;
}

interface Actions {
  view?: (templateSet: Instance<typeof TemplateSetStore>) => void;
  remove?: ({
    template,
    templateSetId,
  }: {
    template: TemplateSetTableRowData;
    templateSetId: number;
  }) => void;
  rename?: (templateSet: Instance<typeof TemplateSetStore>) => void;
  delete?: (templateSet: Instance<typeof TemplateSetStore>) => void;
  generate?: (templateSet: Instance<typeof TemplateSetStore>) => void;
}

interface Props {
  onAddTemplatesToSeletionSidebar: (
    templateSet: Instance<typeof TemplateSetStore>,
  ) => void;
  rowActions?: Actions;
  childRowActions?: Actions;
}

const TemplateSetsTableWrapper: FC<Props> = ({
  onAddTemplatesToSeletionSidebar,
  rowActions,
  childRowActions,
}) => {
  const pageScroll = usePageScroll();

  const { next, loading, done, data, setSearch, setSortBy, empty, setOrder } =
    useTemplateSetsPagination();

  const loadMore = next;

  const handleOnPageScroll = () => {
    if ((pageScroll === 0 || pageScroll >= 85) && !loading) {
      loadMore();
    }
  };

  const handleSearchChange = (searchValue: string) => {
    setSearch(searchValue);
  };

  const handleSortByChange = (property: string, order: number) => {
    if (!property) {
      return;
    }

    const orderLabel = order > 0 ? SortByOrder.desc : SortByOrder.asc;
    let sortBy = snakeCase(property);

    // TODO: abstract this into a model/transformer lookup
    if (property === 'updatedAt') {
      sortBy = 'last_modified';
    }

    setSortBy(sortBy);
    setOrder(orderLabel);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleOnPageScroll, [pageScroll]);

  const templateSetsTableData = useObserver(() => {
    return (data as Instance<typeof TemplateSetStore>[]).map((set) => ({
      id: set.id,
      title: set.title,
      children: set.currentTemplatesWithValues().map((twv) => {
        return {
          id: twv?.template?.id,
          title: twv.templateDefaultValues
            ? twv.templateDefaultValues.title
            : twv?.template?.title,
          defaultValuesId: twv.templateDefaultValues
            ? twv.templateDefaultValues.id
            : null,
          subtitle: twv?.template?.subtitle,
        };
      }),
    }));
  });

  if (empty && data.length === 0) {
    return <TemplateSetsEmptyView />;
  }

  return (
    <>
      <TemplateSetsTable
        data={templateSetsTableData}
        visibleItems={templateSetsTableData.length}
        isLoading={false}
        empty={!loading && done && templateSetsTableData.length === 0}
        onAddTemplatesToSeletionSidebar={onAddTemplatesToSeletionSidebar}
        rowActions={rowActions}
        childRowActions={childRowActions}
        onSearchChange={handleSearchChange}
        onSortByChange={handleSortByChange}
      />
      <LoadMoreDisplayText
        loading={loading}
        done={done}
        onLoadMore={loadMore}
      />
    </>
  );
};

export default TemplateSetsTableWrapper;
