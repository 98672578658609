import React from 'react';
import { css } from 'aphrodite';

import Card, { CardTitle } from '~/src/components/Card';

import styles from './styles';
import { useCancelSubscriptionModal } from '~/src/entities/subscription';
import { useStore } from '~/src/hooks/useMst';

export const CancelCard = ({ endDate }) => {
  const store = useStore();
  const [toggleModal, modal] = useCancelSubscriptionModal({
    endDate,
    onCanceled: () => store.user.currentOrganization.fetchSubscription(),
  });

  return (
    <>
      {modal}
      <Card elevate>
        <CardTitle bold>Cancel subscription</CardTitle>
        <p className={css(styles.settingsRow, styles.cardBody)}>
          Upon canceling, you and your team will lose access to your templates,
          drafted documents, contacts and matter information stored in Clio
          Draft after the current billing period.
        </p>
        <a
          role="button"
          tabIndex={0}
          href={'#'}
          onKeyPress={(e) => {
            e.preventDefault();
            toggleModal();
          }}
          onClick={(e) => {
            e.preventDefault();
            toggleModal();
          }}
          className={css(styles.buttonLink)}
        >
          Cancel subscription
        </a>
      </Card>
    </>
  );
};
