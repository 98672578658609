import React from 'react';
import { Box, Typography } from '@mui/material';
import { ReactComponent as SelectDocumentsSvg } from './select-documents.svg';
import { BannerWrapper } from './BannerWrapper';
import analyticsService from '~/src/services/analytics';
import { tryConvertToNumber } from '~/src/entities/questionnaires';

export const SelectDocumentsBanner = ({
  open,
  questionnaireId,
  onClose,
}: {
  open: boolean;
  questionnaireId: string;
  onClose: () => void;
}) => {
  const handleOnClose = () => {
    analyticsService.track('Clicked on Banner Close', {
      questionnaire_id: tryConvertToNumber(questionnaireId),
      banner_type: 'info',
      page: 'documents_select_page',
      sub_page: null,
    });
    onClose();
  };

  return (
    <BannerWrapper open={open} onClose={handleOnClose}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'row',
          gap: '16px',
        }}
      >
        <SelectDocumentsSvg />
        <Box
          sx={{
            maxWidth: '468px',
            color: 'black',
          }}
        >
          <Typography variant="subheading">
            Choose the documents that need client information
          </Typography>
          <Typography variant="body1" paddingTop={'4px'}>
            To build a questionnaire template, start by selecting the documents
            you need to draft, then Clio Draft will automatically suggest the
            needed questions.
          </Typography>
        </Box>
      </Box>
    </BannerWrapper>
  );
};
