import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';

import Table from '~/src/containers/Table';
import Button from '~/src/components/Button';
import Triangle from '~/src/components/Triangle';
import Dropdown from '~/src/components/Dropdown';

import dropdownStyles from '~/src/components/Dropdown/styles';
import styles from './styles';

const QuestionnaireResponsesActions = () => {
  return <div />;
};

const RowAction = ({ actions, intake }) => {
  const renderTrigger = ({ toggleDropdown }) => (
    <Button onClick={toggleDropdown} cssStyle={styles.rowActionButton}>
      Actions <Triangle primary orientation={'down'} />
    </Button>
  );

  const handleEdit = () => {
    actions.edit(intake);
  };

  const handleView = () => {
    actions.view(intake);
  };

  return (
    <Dropdown renderTrigger={renderTrigger}>
      <a
        onClick={handleView}
        className={css(dropdownStyles.dropdownActionItem)}
      >
        View
      </a>
      <a
        onClick={handleEdit}
        className={css(dropdownStyles.dropdownActionItem)}
      >
        Edit
      </a>
    </Dropdown>
  );
};

const QuestionnaireResponses = ({
  hideHeader,
  empty,
  columnWidths,
  noBorder,
  visibleItems,
  intakes,
  rowActions,
  onSortByChange,
  onSearchChange,
}) => {
  const renderRowAction = (intake) => (
    <RowAction actions={rowActions} intake={intake} />
  );

  return (
    <>
      <Table
        noBorder={noBorder}
        hideHeader={hideHeader}
        emptyTitle={'No questionnaire responses match that filter'}
        empty={empty}
        isLoading={false}
        searchPlaceholder="Search questionnaire responses "
        renderActions={() => <QuestionnaireResponsesActions />}
        onSearchChange={onSearchChange}
        onSortByChange={onSortByChange}
        data={intakes}
        visibleItems={visibleItems}
        columns={[
          {
            label: 'Email/Phone',
            dataKey: 'email',
            style: {
              width: columnWidths[0],
              textAlign: 'left',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            },
          },
          {
            label: 'Submitted At',
            dataKey: 'submittedAt',
            style: { width: columnWidths[1], textAlign: 'left' },
          },
          {
            label: 'Actions',
            hideLabel: true,
            render: renderRowAction,
            style: { flexGrow: 1, display: 'flex', justifyContent: 'flex-end' },
          },
        ]}
      />
    </>
  );
};

QuestionnaireResponses.propTypes = {
  columnWidths: PropTypes.arrayOf(PropTypes.string),
  intakes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      email: PropTypes.string,
      sentAt: PropTypes.string,
      submittedAt: PropTypes.string,
    }),
  ),
  visibleItems: PropTypes.number,
  rowActions: PropTypes.shape({
    edit: PropTypes.func,
    view: PropTypes.func,
  }),
};

QuestionnaireResponses.defaultProps = {
  intakes: [],
  columnWidths: ['60%', '10%'],
  visibleItems: 10,
  rowActions: {
    edit: () => {},
    view: () => {},
  },
};

export default QuestionnaireResponses;
