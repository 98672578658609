import * as React from 'react';
import { CardListItemActionsProps, CardListItemProps } from '.';
import { CardListItem } from './CardListItem';
import { renderMetaWithActions } from './utils';

export type CardListItemWithActionsProps = {
  actions?: CardListItemActionsProps;
  meta?: React.ReactNode;
} & Omit<CardListItemProps, 'renderMeta'>;

export const CardListItemWithActions = ({
  meta,
  actions,
  ...rest
}: CardListItemWithActionsProps) => (
  <CardListItem
    {...rest}
    renderMeta={renderMetaWithActions({ actions, meta })}
  />
);
