import React, { Component } from 'react';
import { ChevronDown, Trash } from 'react-feather';

import {
  getAllSigners,
  signersPropType,
} from '~/src/components/SignerSelection/utils';
import theme from '~/src/theme';
import assert from 'assert';
import SignerMenuItem from '~/src/components/SignerSelection/ChooseSignerMenuItem';
import { SIGNER_INDICATOR_COLORS } from '~/src/components/SignerSelection/constants';
import TextWithIndicator from '~/src/components/SignerSelection/TextWithIndicator';
import { Signers, Signer, Email } from '~/src/models';

interface Props {
  // The signers in this document
  signers: Signers;
  // What to do if this date element is deleted
  onDelete: () => void;
  // What to do when a new signer is selected to be assigned to this element
  onDaterSelected: (signer: Signer) => void;
}

interface State {
  isDropDownOpen: boolean;
  signerEmail: Email | null;
  bottom: '0' | 'none';
}

class ChangeDaterMenu extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    assert('me' in props.signers);
    this.state = {
      isDropDownOpen: false,
      signerEmail: props.signers.me.email,
      bottom: 'none',
    };
  }

  static propTypes = {
    signers: signersPropType,
  };

  // The ref of the actual dropdown containing the list of signers
  dropDownRef = React.createRef<HTMLDivElement>();

  /**
   * When triggered, opens the dropdown if closed (or closes it if open). Then,
   * determines where the dropdown should be placed relative to the container and
   * updates the styling for the dropdown div accordingly
   */
  toggleDropDown = () => {
    this.setState({ isDropDownOpen: !this.state.isDropDownOpen }, () => {
      if (this.dropDownRef.current) {
        const iframeApp = this.dropDownRef.current.closest('.App');
        const { y, height } = this.dropDownRef.current.getBoundingClientRect();
        /**
         * If the menu's bottom border will appear below the app's height, change
         * `bottom` property that we will use to position the dropdown so that it
         * is not cut off. We include 10px as a margin of safety.
         */
        this.setState({
          bottom: y + height + 10 > iframeApp!.clientHeight ? '0' : 'none',
        });
      }
    });
  };

  selectDater = (signer: Signer) => {
    const { signers } = this.props;
    assert('me' in signers);
    // Me is already the signer if this menu is open so nothing to do here
    if (signer.email === signers.me.email) {
      return;
    }
    this.setState({ signerEmail: signer.email });
    this.props.onDaterSelected(signer);
  };

  renderSignerDetailsArray = (signers: Signers) => {
    if (!('others' in signers)) {
      return [];
    }

    return signers.others.map((signer, i) => {
      const first = i === 0;
      const last = i === signers.others.length - 1;
      return (
        <SignerMenuItem
          key={signer.email}
          onSignerSelected={() => this.selectDater(signer)}
          indicatorColor={
            SIGNER_INDICATOR_COLORS[i % SIGNER_INDICATOR_COLORS.length]!
          }
          first={first}
          last={last}
        >
          {signer.name}
        </SignerMenuItem>
      );
    });
  };

  render() {
    const { onDelete, signers } = this.props;
    const { signerEmail } = this.state;
    assert('me' in signers);
    const allSigners = getAllSigners(signers);
    assert(
      signerEmail === null ||
        allSigners.map((e) => e.email).includes(signerEmail),
      'ChangeSignatureMenu.tsx has an unexpected signer!',
    );
    return (
      <div
        style={{
          padding: 13,
          display: 'flex',
          flexDirection: 'column',
          gap: 8,
          width: 220,
          fontSize: theme.fonts.size.small,
        }}
      >
        <div style={{ display: 'flex' }}>
          <h5
            style={{
              margin: 0,
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              fontSize: theme.fonts.size.extraSmall,
              color: '#000',
            }}
          >
            Signature field
          </h5>
          <Trash
            size={theme.fonts.size.regular}
            color={theme.colors.charcoal}
            style={{
              cursor: 'pointer',
            }}
            onClick={() => onDelete()}
          />
        </div>
        <div>Select signer</div>
        <div
          onClick={this.toggleDropDown}
          style={{
            display: 'flex',
            borderRadius: theme.borderRadius,
            border: theme.border,
            font: theme.fonts.size.regular,
            padding: 7,
            alignItems: 'center',
          }}
        >
          <div style={{ flexGrow: 1 }}>
            {signerEmail ? (
              <TextWithIndicator
                indicatorColor="#000"
                style={{
                  padding: 0,
                }}
              >
                Me now
              </TextWithIndicator>
            ) : (
              'Choose signer'
            )}
          </div>
          {this.state.signerEmail === null && <ChevronDown />}
        </div>
        <div
          style={{
            position: 'relative',
            width: '100%',
          }}
        >
          {this.state.isDropDownOpen && (
            <div
              ref={this.dropDownRef}
              style={{
                position: 'absolute',
                bottom: this.state.bottom,
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'white',
                border: this.state.isDropDownOpen ? theme.border : 'none',
                borderRadius: theme.borderRadius,
                width: '100%',
                boxShadow: this.state.isDropDownOpen ? theme.boxShadow : 'none',
              }}
            >
              {this.renderSignerDetailsArray(signers)}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ChangeDaterMenu;
