import React from 'react';
import TemplateList from './TemplateList';

export type Template = {
  value: string;
  label: string;
  documentType?: number;
  subtitle?: string;
};

interface PropsType {
  label: string;
  onChange: (value: string[]) => void;
  value: Template[];
}

export const TemplateListInput = (Props: PropsType) => {
  return (
    <div>
      <TemplateList
        templates={Props.value}
        handleDelete={(template) => {
          const nextTemplateIDs = Props.value
            .filter((t) => t.value != template.value)
            .map((t) => {
              return t.value;
            });
          Props.onChange(nextTemplateIDs);
        }}
        title={Props.label}
      />
    </div>
  );
};
