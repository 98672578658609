export default class ElementKeyTool {
  /**
   * Formats a "one-way" project key tag.
   * @param {string} tag - The tag of the field.
   * @param {number|string} elementId - The element id of the field.
   * @returns {string} The formatted key.
   */
  public static format_one_way_project_key(
    tag: string,
    elementId: number | string,
  ): string {
    return `${tag}\t${elementId}`;
  }

  /**
   * Attempts to resolve the value of a tag.
   * @param elementData - The element data.
   * @param stackSavedData - The stack saved data.
   * @param projectDocumentKey - The project document id key.
   * @returns {{ oneWayField, fieldKey, tagValue }}
   */
  public static tryResolveTagValue(
    elementData: any,
    stackSavedData: Record<string, string>,
    projectDocumentKey: string,
  ) {
    // Start off with the one way project key tag, to see if there is any data for it.

    let { oneWayField, fieldKey, tagValue } =
      ElementKeyTool._tryParseOneWayField(
        elementData,
        stackSavedData,
        projectDocumentKey,
      );

    if (!oneWayField) {
      // This is not a one way field, so check against the tag and alias.

      // Check if the default value is undefined and the element value is defined.
      if (elementData.value !== undefined) {
        // No data found for this key, so use the default value.
        tagValue = elementData.value;
      } else {
        if (elementData.tag in stackSavedData) {
          // The tag was found, so use it.
          fieldKey = elementData.tag;
        } else if (elementData.aliasTag in stackSavedData) {
          // The alias was found, so use it.
          fieldKey = elementData.aliasTag;
        }

        // Resolve the data from this key, or get undefined if not present.
        tagValue = stackSavedData[fieldKey];
      }

      // Check if the default value is a batch select field.
      if (tagValue !== undefined && tagValue !== null) {
        try {
          // batch select fields are saved in a JSON format
          const result = JSON.parse(tagValue);
          if (result && Array.isArray(result)) {
            tagValue = result.filter(Boolean).join(', ');
          }
        } catch {
          // ignore parse error
        }
      }
    }

    return { oneWayField, fieldKey, tagValue };
  }

  /**
   * Formats a tag for an untagged element.
   * @param elementData - The element data.
   * @returns {string} The formatted untagged element key.
   */
  public static formatUntaggedElement(elementData: any): string {
    return `untagged_${elementData.id}`;
  }

  /**
   * Formats a field name for an element.
   * - "lawyaw_prefix" is used to avoid collisions with other form fields.
   * @param elementData - The element data.
   * @param fieldKey - The field key.
   * @returns {string} The formatted field name.
   */
  public static formatFieldName(elementData: any, fieldKey: string): string {
    return `${elementData.id}lawyaw_prefix${elementData.tag || fieldKey}`;
  }

  /**
   * Formats a project document id string.
   * @param delimiter - The delimiter to use. Defaults to "\t".
   * @param projectDocumentId - The project document id.
   * @returns {string} The formatted project document id string.
   */
  public static formatProjectDocumentIdString(
    delimiter: string = '\t',
    projectDocumentId: number | string,
  ): string {
    return `${delimiter}${projectDocumentId}`;
  }

  /**
   * Attempts to parse a one way field using the element tag / aliasTag and stack saved data.
   * @param elementData - The element data.
   * @param stackSavedData - The stack saved data.
   * @param projectDocumentKey - The project document id key.
   * @returns {{ oneWayField, fieldKey, tagValue }}
   */
  private static _tryParseOneWayField(
    elementData: any,
    stackSavedData: Record<string, string>,
    projectDocumentKey: string,
  ): { oneWayField: boolean; fieldKey: string; tagValue: string | undefined } {
    // Track if this is a one way field.
    let oneWayField = false;
    let tagValue: undefined | string;
    let fieldKey: string = '';

    for (let key of ['tag', 'aliasTag']) {
      fieldKey = ElementKeyTool.format_one_way_project_key(
        elementData[key],
        elementData.id,
      );

      // Check if there is data for the one way project doc key tag.
      if (!(fieldKey in stackSavedData)) {
        fieldKey += projectDocumentKey;
      }

      // Check if there is data for the one way project doc key tag.
      if (fieldKey in stackSavedData) {
        // There is data for the one way project doc key tag, so use it.
        tagValue = stackSavedData[fieldKey];
        // Toggle the one way field flag.
        oneWayField = true;
        break;
      }
    }

    // If the one way field flag is not set, then
    // use the element tag as the field key.
    if (!oneWayField) {
      fieldKey = elementData.tag;
    }

    return { oneWayField, fieldKey, tagValue };
  }
}
