import React from 'react';
import PropTypes from 'prop-types';

import Table from '~/src/containers/Table';
import Button from '~/src/components/Button';

import { User, Briefcase } from 'react-feather';

const renderColumnType = ({ type }) => {
  if (type === 'business') {
    return <Briefcase size={18} color={'#626E7A'} />;
  }
  return <User size={18} color={'#626E7A'} />;
};

const AttachContactsSearchContacts = ({
  contacts,
  onSelectContact,
  onCreateContact,
}) => {
  const mappedContacts = contacts.map((contact) => ({
    id: contact.id,
    type: contact.type,
    name: contact.fullName(),
  }));

  const handleRowclick = (data) => {
    onSelectContact(data.id);
  };

  const Actions = () => (
    <Button onClick={onCreateContact}>Create contact</Button>
  );

  return (
    <Table
      emptyTitle={'No contacts match that filter'}
      searchPlaceholder={'Search contacts'}
      renderActions={Actions}
      data={mappedContacts}
      stackActions
      hideColumnHeaders
      rowClickable
      onRowClick={handleRowclick}
      columns={[
        {
          label: 'Type',
          dataKey: 'type',
          render: renderColumnType,
          style: {
            width: '10%',
            justifyContent: 'center',
            textAlign: 'center',
          },
        },
        {
          label: 'Name',
          dataKey: 'name',
          style: {
            width: '90%',
            textAlign: 'left',
          },
        },
      ]}
    />
  );
};

AttachContactsSearchContacts.defaultProps = {
  contacts: [],
  onCreateContact: () => {},
  onSelectContact: () => {},
};

AttachContactsSearchContacts.propTypes = {
  contacts: PropTypes.array,
  onCreateContact: PropTypes.func,
  onSelectContact: PropTypes.func,
};

export default AttachContactsSearchContacts;
