/* Services */
import gatedActionsService from '~/src/services/api/gatedActionsService';
import store from '~/src/stores';
import { history } from '../utils/history';

function gatedActions() {
  const userClickedContactSales = async (utmCampaign) => {
    const orgFprint = store.user.getCurrentOrgFprint();
    const userEmail = store.user.currentOrganization.email;
    const sourcePage = history.location.pathname;
    return gatedActionsService.userClickedContactSales({
      orgFprint,
      userEmail,
      sourcePage,
      utmCampaign,
    });
  };

  return {
    userClickedContactSales,
  };
}

export default gatedActions();
