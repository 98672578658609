import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
  fadeIn: {
    display: 'flex',
    flexFlow: 'column',
    flexGrow: 1,
    position: 'relative',
    transform: 'translate3d(0,0,0,1)',
    transition: 'opacity 350ms ease',
    opacity: 0,
  },
  fadeInActive: {
    opacity: 1,
  },
});
