import React from 'react';
import { Button } from '@mui/material';
import { MessageDialog } from '~/src/components/Modal/mui';

interface Props {
  onClose: () => void;
}

export const MessageDialogError = ({ onClose }: Props) => {
  return (
    <MessageDialog
      title={'An error occurred'}
      onClose={onClose}
      dialogActions={<Button onClick={onClose}>Close</Button>}
    >
      Something went wrong, please try again later.
    </MessageDialog>
  );
};
