import { useState, useEffect } from 'react';

/* Hooks */
import useMst from '~/src/hooks/useMst';

const useFetchQuestionnaireResponse = (id) => {
  const [loading, setLoading] = useState(true);
  const [intakeResponse, setIntakeResponse] = useState();

  const { updateQuestionnaireResponse, fetchQuestionnaireResponse } = useMst(
    (store) => ({
      fetchQuestionnaireResponse:
        store.questionnaireResponses.fetchQuestionnaireResponse,
      updateQuestionnaireResponse:
        store.questionnaireResponses.updateQuestionnaireResponse,
    }),
  );

  useEffect(() => {
    fetchQuestionnaireResponse(id).then((nextIntakeResponse) => {
      setIntakeResponse(nextIntakeResponse);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateIntakeResponse = (propertiesToUpdate) => {
    return updateQuestionnaireResponse(intakeResponse.id, propertiesToUpdate);
  };

  return {
    updateQuestionnaireResponse: handleUpdateIntakeResponse,
    intakeResponse,
    loading,
  };
};

export default useFetchQuestionnaireResponse;
