import React from 'react';
import { css } from 'aphrodite';

import styles from './styles';

const STATUS_TO_COLORS = {
  enabled: styles.enabled,
  disabled: styles.disabled,
  syncing: styles.syncing,
  error: styles.error,
};

const ClioSyncStatus = ({ status }) => (
  <div>
    <i className={css(styles.icon, STATUS_TO_COLORS[status])} />
    <span
      className={css(styles.text, status === 'disabled' && styles.textDisabled)}
    >
      {status === 'enabled' && 'Enabled'}
      {status === 'syncing' && 'Syncing'}
      {status === 'disabled' && 'Disabled'}
      {status === 'error' && 'Sync Error'}
    </span>
  </div>
);

ClioSyncStatus.defaultProps = {
  status: 'disabled',
};

export default ClioSyncStatus;
