import React, { useState, useRef } from 'react';

import useMst from '~/src/hooks/useMst';

import Button from '~/src/components/Button';
import Spinner from '~/src/components/Spinner';
import { css } from 'aphrodite';

import { TextInput } from '~/src/components/Inputs';
import { ReactComponent as CloseIcon } from '~/src/static/close-icon.svg';
import styles from './styles';

export interface TemplateDefaultValuesDraftingModalProps {
  title: string;
  message: string;
  content?: string;
  inputValue?: string;
  enableTextInput: boolean;
  onCancel: () => void;
  onDiscard?: () => void;
  onSave?: (value: string) => void;
  onClose: () => void;
  onDelete?: () => void;
  confirmButtonName: string;
}

const TemplateDefaultValuesModal = ({
  title,
  message,
  inputValue,
  enableTextInput,
  onCancel,
  onDiscard,
  onSave,
  onDelete,
  confirmButtonName,
}: TemplateDefaultValuesDraftingModalProps) => {
  const [value, setValue] = useState(inputValue || '');

  const { error, clearError, isLoading } = useMst((store) => ({
    error: store.templateDefaultValues.error,
    clearError: store.templateDefaultValues.clearError,
    isLoading: store.templateDefaultValues.isLoading,
  }));

  const ref = useRef(null);
  const textInputProps = {
    label: 'Name',
    className: 'flex-auto',
    placeholder: value || 'Name',
    onChange: (input: string) => {
      setValue(input);
    },
    onFocus: () => {
      clearError();
    },
    value,
    noMargin: true,
    error,
  };

  const hasTitle = value ? value.trim().length > 0 : false;
  const hasTitleChanged = value !== inputValue;

  const isDisabled =
    confirmButtonName === 'Save and close'
      ? isLoading || !hasTitle
      : isLoading || !hasTitle || !hasTitleChanged;

  return (
    <div className={css(styles.modalContainer, styles.modalContainerMedium)}>
      <h1 className={css(styles.modalTitleWithCloseIcon)}>
        {title}
        <button className="flex" onClick={onCancel}>
          <CloseIcon />
        </button>
      </h1>
      {message && (
        <p
          className={css(
            styles.modalMessage,
            styles.modalMessageCenterVertical,
          )}
        >
          {message}
        </p>
      )}
      {enableTextInput && <TextInput {...textInputProps} ref={ref} />}
      <div className={css(styles.modalActions)}>
        <Button cssStyle={styles.buttonAction} onClick={onCancel}>
          Cancel
        </Button>{' '}
        {onDiscard && (
          <Button
            cssStyle={styles.buttonAction}
            onClick={onDiscard}
            analyticsname="Form Template - Modal - Discard Changes"
          >
            Discard Changes
          </Button>
        )}
        {onSave && (
          <Button
            analyticsname={`Form Template - Modal - ${confirmButtonName}`}
            cssStyle={styles.buttonAction}
            primary
            onClick={() => onSave(value!)}
            disabled={isDisabled}
          >
            {confirmButtonName}
            {isLoading && <Spinner />}
          </Button>
        )}
        {onDelete && (
          <Button
            cssStyle={styles.buttonAction}
            alert
            onClick={onDelete}
            analyticsname="Form Template - Modal - Delete"
          >
            Delete Template
          </Button>
        )}
      </div>
    </div>
  );
};

export default TemplateDefaultValuesModal;
