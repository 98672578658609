import cookieUtil from '~/src/utils/cookies';
import { queryClient } from '~/src/utils/queryClient';
import {
  getMemberships,
  MemebershipAPIResponse,
  useMemberships,
} from './useMemberships';

export const getOrgFromMembership = ({
  memberships,
}: MemebershipAPIResponse) => {
  const orgFprint = cookieUtil.getOrgFprint();
  const org = memberships.find(({ fprint, is_default }) =>
    orgFprint ? fprint === orgFprint : is_default,
  );

  return org || memberships[0];
};

export const useCurrentOrg = () => {
  const result = useMemberships({
    staleTime: Number.POSITIVE_INFINITY,
  });
  return {
    ...result,
    org: result.data ? getOrgFromMembership(result.data) : undefined,
  };
};

export const useCurrentOrgFprint = () => useCurrentOrg().org?.fprint!;

export const getOrg = (client = queryClient) =>
  getOrgFromMembership(getMemberships(client)!)!;

export const getOrgFprint = (client = queryClient) => getOrg(client).fprint;
