import React from 'react';
import { Box } from '@mui/material';

export const SubmissionWrapper: React.FC<{
  children: React.ReactElement;
}> = ({ children }) => (
  <Box
    sx={(theme) => ({
      display: 'flex',
      padding: '10px',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '10px',
      alignSelf: 'stretch',
      background: theme.palette.grey[100],
    })}
  >
    {children}
  </Box>
);
