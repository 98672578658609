import { Typography, Box } from '@mui/material';
import React from 'react';

type SidebarHeaderProps = {
  matterName: string;
};

export const SidebarHeader = ({ matterName }: SidebarHeaderProps) => {
  return (
    <>
      <Box pb={1}>
        <Typography variant="label">Matter</Typography>
      </Box>
      <Typography variant="body1">{matterName}</Typography>
    </>
  );
};
