import React from 'react';
import PropTypes from 'prop-types';

const DraggableList = ({ renderDraggableListItem, items }) => {
  return <>{items.map((item, i) => renderDraggableListItem(item, i))}</>;
};

DraggableList.propTypes = {
  renderDraggableListItem: PropTypes.func,
  items: PropTypes.array,
};

DraggableList.defaultProps = {
  renderDraggableListItem: () => {},
  items: [],
};

export default DraggableList;
