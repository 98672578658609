import React, { MouseEvent } from 'react';
import { css } from 'aphrodite';

import Button from '~/src/components/Button';
import Dropdown from '~/src/components/Dropdown';
import Triangle from '~/src/components/Triangle';

import dropdownStyles from '~/src/components/Dropdown/styles';

import styles from './styles';

export interface Actions<T> {
  edit?: (template: T) => void;
  duplicate?: (template: T) => void;
  rename?: (template: T) => void;
  delete?: (template: T) => void;
  generate?: (template: T) => void;
  previewFormTemplate?: (template: T) => void;
  createFormTemplate?: (template: T) => void;
}

interface Props<T> {
  actions: Actions<T>;
  template: T;
}

const RowActions = <T,>({ actions, template }: Props<T>) => {
  const renderTrigger = ({
    toggleDropdown,
  }: {
    toggleDropdown: () => void;
  }) => {
    const handleClick = (event: MouseEvent) => {
      event.stopPropagation();
      toggleDropdown();
    };

    return (
      <span>
        <Button onClick={handleClick} cssStyle={styles.rowActionButton}>
          Actions <Triangle primary />
        </Button>
      </span>
    );
  };

  const handleOnEdit = (event: MouseEvent) => {
    event.stopPropagation();
    actions.edit?.(template);
  };

  const handleOnDuplicate = (event: MouseEvent) => {
    event.stopPropagation();
    actions.duplicate?.(template);
  };

  const handleOnRename = (event: MouseEvent) => {
    event.stopPropagation();
    actions.rename?.(template);
  };

  const handleOnDelete = (event: MouseEvent) => {
    event.stopPropagation();
    actions.delete?.(template);
  };

  const handleOnGenerate = (event: MouseEvent) => {
    event.stopPropagation();
    actions.generate?.(template);
  };

  const handleOnPreviewFormTemplate = (event: MouseEvent) => {
    event.stopPropagation();
    actions.previewFormTemplate?.(template);
  };

  const handleOnCreateFormTemplate = (event: MouseEvent) => {
    event.stopPropagation();
    actions.createFormTemplate?.(template);
  };

  return (
    <Dropdown renderTrigger={renderTrigger}>
      {actions.generate && (
        <a
          onClick={handleOnGenerate}
          className={css(dropdownStyles.dropdownActionItem)}
        >
          Draft new document set
        </a>
      )}
      {actions.previewFormTemplate && (
        <a
          analyticsname="Form Template - Table Actions - Preview"
          onClick={handleOnPreviewFormTemplate}
          className={css(dropdownStyles.dropdownActionItem)}
        >
          Preview
        </a>
      )}
      {actions.edit && (
        <a
          analyticsname="Form Template - Table Actions - Edit"
          onClick={handleOnEdit}
          className={css(dropdownStyles.dropdownActionItem)}
        >
          Edit form template
        </a>
      )}
      {actions.duplicate && (
        <a
          analyticsname="Form Template - Table Actions - Duplicate"
          onClick={handleOnDuplicate}
          className={css(dropdownStyles.dropdownActionItem)}
        >
          Duplicate
        </a>
      )}
      {actions.rename && (
        <a
          analyticsname="Form Template - Table Actions - Rename"
          onClick={handleOnRename}
          className={css(dropdownStyles.dropdownActionItem)}
        >
          Rename
        </a>
      )}
      {actions.delete && (
        <a
          analyticsname="Form Template - Table Actions - Delete"
          onClick={handleOnDelete}
          className={css(dropdownStyles.dropdownActionItem)}
        >
          Delete
        </a>
      )}
      {actions.createFormTemplate && (
        <a
          analyticsname="Form Template - Table Actions - Create"
          onClick={handleOnCreateFormTemplate}
          className={css(dropdownStyles.dropdownActionItem)}
        >
          Create form template
        </a>
      )}
    </Dropdown>
  );
};

export default RowActions;
