/* Libraries */
import React, { Fragment } from 'react';

/* Containers */
import UserAvatarDropdownMenu from '~/src/containers/UserAvatarDropdownMenu';

/* Components */
import { ReactComponent as CloseIcon } from '~/src/static/close-icon.svg';
import { EmulationBanner } from './EmulationBanner';

export interface HeaderProps {
  title?: string;
  showAvatar?: boolean;
  tabs?: React.ReactNode;
  showCloseButton?: boolean;
  onClose?: () => void;
}

const Header = ({
  title = '',
  showAvatar = true,
  showCloseButton = false,
  tabs,
  onClose,
}: HeaderProps) => {
  return (
    <Fragment>
      <div className="bg-white  border-gray-200 border-b">
        <header
          className="flex justify-between p-4  items-center"
          style={
            showCloseButton
              ? { borderBottom: '1px solid rgb(218, 221, 230)' }
              : undefined
          }
        >
          {showCloseButton ? (
            <div className="w-1/3 flex items-center">
              <div
                className="hover:bg-gray-200 cursor-pointer rounded-full w-8 h-8 flex justify-center items-center mr-4"
                onClick={onClose}
              >
                <button className="flex">
                  <CloseIcon />
                </button>
              </div>
              <h1 className="font-semibold">{title}</h1>
            </div>
          ) : (
            <h1 className="font-semibold">{title}</h1>
          )}
          <EmulationBanner />
          {showAvatar ? <UserAvatarDropdownMenu /> : null}
        </header>

        {tabs}
      </div>
    </Fragment>
  );
};

export default Header;
