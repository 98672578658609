import React from 'react';
import PropTypes from 'prop-types';

import { getWindowDimensions } from '~/src/utils/window';

import { css } from 'aphrodite';

import _ from 'lodash';
import styles from './styles';
import { ClickAwayListener } from '@mui/material';

const defaultState = {
  open: false,
  position: 'bottom',
};

class Dropdown extends React.Component {
  state = defaultState;

  containerRef = React.createRef();

  hideDropdown = () => {
    this.setState({ open: false });
  };

  showDropdown = () => {
    this.setState({ open: true });
    this.checkBoundingRec();
  };

  checkBoundingRec = () => {
    const ref = this.containerRef;
    if (ref.current) {
      const boundingRec = ref.current.getBoundingClientRect();
      const windowDimensions = getWindowDimensions();
      const pixelsFromBottomViewport = windowDimensions.y - boundingRec.bottom;

      if (pixelsFromBottomViewport <= 150) {
        this.setState({ position: 'top' });
      } else {
        this.setState({ position: defaultState.position });
      }
    }
  };

  toggleDropdown = () => {
    if (this.state.open) {
      this.hideDropdown();
    } else {
      this.showDropdown();
    }
  };

  render() {
    const { renderTrigger, children, width, horizontalPosition, className } =
      this.props;

    const { open, position } = this.state;

    const renderTriggerProps = {
      showDropdown: this.showDropdown,
      hideDropdown: this.hideDropdown,
      toggleDropdown: this.toggleDropdown,
      open,
    };

    const dropdownPositionStyle = styles[_.camelCase(`dropdown ${position}`)];
    const dropdownHorizontalPositionStyle =
      styles[_.camelCase(`dropdown ${horizontalPosition}`)];

    return (
      <ClickAwayListener onClickAway={() => this.hideDropdown()}>
        <div
          style={{ position: 'relative' }}
          className={className}
          ref={this.containerRef}
        >
          {renderTrigger(renderTriggerProps)}
          <div
            style={{ width }}
            className={css(
              styles.dropdown,
              dropdownPositionStyle,
              dropdownHorizontalPositionStyle,
              !open && styles.dropdownClose,
            )}
          >
            {children}
          </div>
        </div>
      </ClickAwayListener>
    );
  }
}

Dropdown.propTypes = {
  renderTrigger: PropTypes.func,
  horizontalPosition: PropTypes.string,
};

Dropdown.defaultProps = {
  renderTrigger: () => {},
  horizontalPosition: 'right',
};

export default Dropdown;
