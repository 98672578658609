import { QueryParams } from './urls';

export const keys = {
  all: ['relatedQuestionnaireSubmissionQuestions'] as const,

  lists: () => [...keys.all, 'list'] as const,

  list: (filters: Partial<QueryParams>) =>
    [...keys.lists(), { filters }] as const,

  listByQuestionnaireSubmission: (questionnaireSubmissionId: number) =>
    [
      ...keys.lists(),
      'questionnaireSubmissionId',
      questionnaireSubmissionId,
    ] as const,

  listByMatter: (matterId: number) =>
    [...keys.lists(), 'matterId', matterId] as const,

  listByMatterUnmappedQuestions: (matterId: number) =>
    [...keys.lists(), matterId, 'unmappedQuestions'] as const,

  details: () => [...keys.all, 'detail'] as const,

  detail: (id: number) => [...keys.details(), id] as const,
};
