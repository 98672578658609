import { Box, Card, Divider, OutlinedInput, Typography } from '@mui/material';
import * as React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { FormField } from '~/src/components/FormField';
import { PracticeArea, Referrer, State } from './fields';
import { SubmitButton } from './SubmitButton';
import { WelcomeFormResponse } from './types';
import { questionKeyToLabel } from './utils';

type WelcomeFormContentProps = {
  isFreeTrial?: boolean;
  onSubmit: (response: WelcomeFormResponse) => void;
};

export const WelcomeFormContent = ({
  isFreeTrial,
  onSubmit,
}: WelcomeFormContentProps) => {
  const methods = useForm<WelcomeFormResponse>({ mode: 'onTouched' });

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
        <Card>
          <Box p={3}>
            <Typography variant={'subheading'}>
              Help us customize your experience with Clio Draft
            </Typography>
          </Box>
          <Divider />
          <Box p={3}>
            <Box pb={2}>
              <Controller<WelcomeFormResponse, 'primaryPracticeArea'>
                defaultValue={''}
                name="primaryPracticeArea"
                rules={{ required: 'This field is required' }}
                render={({ field, fieldState }) => {
                  return (
                    <FormField
                      error={fieldState.error?.message}
                      required
                      label={questionKeyToLabel(field.name)}
                    >
                      <PracticeArea {...field} />
                    </FormField>
                  );
                }}
              />
            </Box>
            {!isFreeTrial ? (
              <Box pb={2}>
                <Controller<WelcomeFormResponse, 'secondaryPracticeArea'>
                  defaultValue={''}
                  name="secondaryPracticeArea"
                  render={({ field, fieldState }) => {
                    return (
                      <FormField
                        error={fieldState.error?.message}
                        label={questionKeyToLabel(field.name)}
                      >
                        <PracticeArea {...field} />
                      </FormField>
                    );
                  }}
                />
              </Box>
            ) : null}
            <Box pb={2}>
              <Controller<WelcomeFormResponse, 'numberOfPeople'>
                name={'numberOfPeople'}
                rules={{
                  validate: (value = '') => {
                    if (isNaN(+value) || +value < 0)
                      return 'Please enter a number';
                  },
                }}
                defaultValue={''}
                render={({ field, fieldState }) => {
                  return (
                    <FormField
                      error={fieldState.error?.message}
                      label={questionKeyToLabel(field.name)}
                    >
                      <OutlinedInput
                        {...field}
                        onChange={(e) => field.onChange(e.target.value.trim())}
                        inputMode={'numeric'}
                      />
                    </FormField>
                  );
                }}
              />
            </Box>
            {!isFreeTrial ? (
              <Box pb={2}>
                <Controller<WelcomeFormResponse, 'referrer'>
                  name={'referrer'}
                  defaultValue={''}
                  render={({ field, fieldState }) => {
                    return (
                      <FormField
                        error={fieldState.error?.message}
                        label={questionKeyToLabel(field.name)}
                      >
                        <Referrer {...field} />
                      </FormField>
                    );
                  }}
                />
              </Box>
            ) : null}
            <Box pb={2}>
              <Controller<WelcomeFormResponse, 'state'>
                name={'state'}
                rules={{ required: 'This field is required' }}
                defaultValue={''}
                render={({ field, fieldState }) => {
                  return (
                    <FormField
                      error={fieldState.error?.message}
                      label={'Which state do you practice in primarily?'}
                      required
                    >
                      <State {...field} />
                    </FormField>
                  );
                }}
              />
            </Box>
            {!isFreeTrial ? (
              <Controller<WelcomeFormResponse, 'phoneNumber'>
                name={'phoneNumber'}
                defaultValue={''}
                render={({ field, fieldState }) => {
                  return (
                    <FormField
                      error={fieldState.error?.message}
                      label={questionKeyToLabel(field.name)}
                    >
                      <OutlinedInput {...field} inputMode={'tel'} />
                    </FormField>
                  );
                }}
              />
            ) : null}
          </Box>
          <Box px={3} pb={3} display={'flex'} justifyContent={'flex-end'}>
            <SubmitButton label={isFreeTrial ? 'Enter my account' : 'Next'} />
          </Box>
        </Card>
      </form>
    </FormProvider>
  );
};
