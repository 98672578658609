import React from 'react';
import { css } from 'aphrodite';

/* Components */
import Card, { CardTitle, CardBreak } from '~/src/components/Card';
import Button from '~/src/components/Button';
import { Label } from '~/src/components/Inputs';

/* Styles */
import styles from './styles';
import { history } from '~/src/utils/history';

const SubscriptionDetails = ({ subscription }) => {
  const handleUpgrade = () => {
    history.replace('/subscribe');
  };

  return (
    <div className={css(styles.settingsCardContainer)}>
      <Card elevate>
        <div className="relative">
          <CardTitle bold>Subscription</CardTitle>
          <div className={css(styles.settingsRow)}>
            <Label className={css(styles.settingsLabel)} label={'Plan'}>
              Free trial
            </Label>
          </div>

          <div className={css(styles.settingsRow)}>
            <Button primary onClick={handleUpgrade}>
              Upgrade
            </Button>
          </div>

          <div className={css(styles.settingsRow)}>
            <CardBreak />
          </div>

          <div className={css(styles.settingsRow)}>
            <Label className={css(styles.settingsLabel)} label={'Next invoice'}>
              You have {subscription.remainingDaysOfFreeTrial()} days remaining
              from your free trial.
            </Label>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default SubscriptionDetails;
