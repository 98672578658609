import { types, flow, getRoot } from 'mobx-state-tree';
import pdfCourtFormsService from '~/src/services/pdfCourtFormsService';

import createPaginatedModel from '~/src/stores/composers/createPaginatedModel';

import { getTemplateIdentifier } from '~/src/utils/dataTransformers';
import { history } from '../utils/history';
import { getOrgFprintFromStoreNode } from './utils';

const PdfCourtFormStore = types.model('PdfCourtFormStore', {
  identifier: types.identifier,
  id: types.string,
  filePath: types.maybeNull(types.string),
  fprint: types.maybeNull(types.string),
  filterCategory: types.maybeNull(types.string),
  locality: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  subtitle: types.maybeNull(types.string),
  documentCategoryId: types.maybeNull(types.number),
  legalDocumentType: types.maybeNull(types.string),
  legalPracticeArea: types.maybeNull(types.string),
  isDeleted: types.boolean,
});

const PdfCourtFormsStore = types
  .model('PdfCourtFormsStore', {
    isLoading: false,
    error: '',
  })
  .actions((self) => {
    const fetch = flow(function* (query, requestPayload) {
      return yield self.paginate(query || {}, requestPayload);
    });

    const addTemplateToSeletionSidebar = (template) => {
      const store = getRoot(self);
      store.sidebarItems.add({
        ...template,
      });
    };

    const createFormTemplate = flow(function* (template) {
      const store = getRoot(self);
      const { id } =
        yield store.templateDefaultValues.createTemplateDefaultValues(
          template.id,
        );
      history.push(`/form-template/${id}`);
    });

    return {
      fetch,
      addTemplateToSeletionSidebar,
      createFormTemplate,
    };
  })
  .views((self) => {
    function getTemplate(templateId) {
      return self.dictionary.get(getTemplateIdentifier(templateId));
    }

    function getTemplatePreview(templateId) {
      return pdfCourtFormsService.getTemplatePreview(
        getOrgFprintFromStoreNode(self),
        templateId,
      );
    }

    function selectOptions() {
      return Array.from(self.dictionary.values()).map((template) => ({
        value: template.id,
        label: template.title,
        subtitle: template.subtitle,
        documentType: template.documentCategoryId,
      }));
    }

    return {
      getTemplate,
      getTemplatePreview,
      selectOptions,
    };
  });

const PaginatedPdfCourtFormsStore = createPaginatedModel(
  'PaginatedPdfCourtFormsStore',
  PdfCourtFormsStore,
  PdfCourtFormStore,
  { paginate: pdfCourtFormsService.getPdfCourtForms },
);

export { PaginatedPdfCourtFormsStore as default, PdfCourtFormStore };
