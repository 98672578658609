import React, { useCallback, useState } from 'react';
import { UpdatePaymentMethodModal } from './UpdatePaymentMethodModal';

type UseUpdatePaymentMethodModalProps = {
  onUpdated?: () => void;
};

export const useUpdatePaymentMethodModal = ({
  onUpdated,
}: UseUpdatePaymentMethodModalProps = {}) => {
  const [open, setOpen] = useState(false);
  const toggle = useCallback(() => setOpen((o) => !o), []);
  const modal = (
    <UpdatePaymentMethodModal
      onUpdated={() => {
        toggle();
        onUpdated?.();
      }}
      open={open}
      onClose={toggle}
    />
  );

  return [toggle, modal] as const;
};
