import { ProjectStackDocument } from '.';
import { base64ToBytes, bytesToBase64 } from './transform';
import {
  Project,
  FieldType,
  StringFieldType,
  ProjectStackSavedData,
} from './types';

export const isMultipleChoiceFieldType = (
  fieldType: FieldType | StringFieldType,
) =>
  fieldType === FieldType.MULTIPLE_CHOICE ||
  fieldType === FieldType.BATCH_SELECT ||
  fieldType === 'BATCH_SELECT' ||
  fieldType === 'MULTIPLE_CHOICE';

export const isPatchSelectType = (fieldType: FieldType | StringFieldType) =>
  fieldType === FieldType.BATCH_SELECT || fieldType === 'BATCH_SELECT';

export const isDateFieldType = (fieldType: FieldType | StringFieldType) =>
  fieldType === FieldType.DATE || fieldType === 'DATE';

// This function transforms all references to a projects fields
// so that special characters won't get in the way of the form
// We need to run the form values through an "untransformer" to get the
// correct field values back (see unsantisizeProjectRecord)
export const transformProjectFields = (project: Project): Project => {
  const transformedDocuments = project.stack_data.documents.map((d) => ({
    ...d,
    document_fields: Object.entries(d.document_fields).reduce<
      ProjectStackDocument['document_fields']
    >((acc, [key, value]) => {
      acc[sanitizeFieldName(key)] = value;
      return acc;
    }, {}),
  }));

  // Create a flat list of document_fields from all documents
  project.document_fields = transformedDocuments.flatMap((d) =>
    Object.entries(d.document_fields).map(([key, value]) => ({ [key]: value })),
  );

  // Continue the rest of the transformations for stack_data and stack_saved_data
  const transformedProject = {
    ...project,
    stack_data: {
      ...project.stack_data,
      documents: transformedDocuments,
      fields: project.stack_data.fields.map((f) => ({
        ...f,
        fid: sanitizeFieldName(f.fid),
      })),
      groups: project.stack_data.groups.map((g) => ({
        ...g,
        fields: g.fields.map(sanitizeFieldName),
      })),
    },
    stack_saved_data: Object.entries(
      project.stack_saved_data,
    ).reduce<ProjectStackSavedData>((acc, [key, value]) => {
      acc[sanitizeFieldName(key)] = value;
      return acc;
    }, {}),
  };

  return transformedProject;
};

export const sanitizeFieldName = (name: string) =>
  bytesToBase64(new TextEncoder().encode(name));

export const unsanitizeProjectFieldName = (name: string) =>
  new TextDecoder().decode(base64ToBytes(name));

export const unsanitizeProjectRecord = (record: Record<string, any>) =>
  Object.entries(record).reduce<Record<string, any>>((acc, [key, value]) => {
    acc[unsanitizeProjectFieldName(key)] = value;
    return acc;
  }, {});
