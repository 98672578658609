import * as React from 'react';
import { ReactComponent as EmptyViewIcon } from './empty-view.svg';
import { ReactComponent as NewEmptyViewIcon } from './new-empty-view.svg';
import { Box, Typography } from '@mui/material';

export const Empty = ({
  useAlphaFeedbackStyle,
}: {
  useAlphaFeedbackStyle: boolean;
}) => {
  if (useAlphaFeedbackStyle) {
    return (
      <Box padding={'24px'}>
        <Box display="flex" justifyContent="center" padding={'16px'}>
          <NewEmptyViewIcon />
        </Box>
        <Box textAlign="center" padding={'8px 60px'}>
          <Typography variant="subheading">
            Selected documents will be shown here
          </Typography>
          <br />
          <Typography variant="label" color="textSecondary" fontWeight={400}>
            Documents can be reordered or removed.
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <div className="p-6 pt-20">
      <div className="flex justify-center">
        <EmptyViewIcon />
      </div>
      <div className="pt-6 text-center font-semibold">
        No documents selected
      </div>
      <div className="pt-3 text-center text-sm">
        Add templates or sets that you want to draft from the left side.
      </div>
    </div>
  );
};
