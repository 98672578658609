import { useObserver } from 'mobx-react';
import useMst from '~/src/hooks/useMst';
import useResourcePagination from '~/src/hooks/useResourcePagination';
import { SortByOrder } from '~/src/hooks/sortByOrder';

const useTemplatesPagination = () => {
  const { templates } = useMst((store) => ({
    templates: store.templates,
  }));

  const { loading, next, page, empty, setSearch, done, setSortBy, setOrder } =
    useResourcePagination({
      fetch: templates.fetch,
      limit: 10,
      order: SortByOrder.desc,
    });

  const listData = useObserver(() => {
    return templates.list.flat();
  });

  return {
    next,
    page,
    empty,
    data: listData,
    loading,
    done,

    setSearch,
    setSortBy,
    setOrder,
  };
};

export default useTemplatesPagination;
