import { throttle } from 'lodash';
import { useEffect, useState } from 'react';

const calculatePercent = (element: HTMLElement | null) => {
  if (!element) return 0;
  const scrollDiff = element.scrollHeight - element.scrollTop;
  return (element.clientHeight / scrollDiff) * 100;
};

const usePageScroll = (elementId = 'lawyaw-main-body') => {
  const element = document.getElementById(elementId);
  const [percent, setPercent] = useState(() => calculatePercent(element));
  useEffect(() => {
    const handler = throttle(() => {
      if (!element) return;
      setPercent(calculatePercent(element));
    }, 50);
    element?.addEventListener('scroll', handler);

    return () => element?.removeEventListener('scroll', handler);
  }, [element]);

  return percent;
};

export default usePageScroll;
