import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  Typography,
  styled,
  Tooltip,
  IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { ArrowRight } from 'react-feather';

type ProgressTrackerWidgetProps = {
  widgetRef: React.RefObject<HTMLDivElement>;
  fieldName?: string;
  totalFieldsCount: number;
  fieldsReviewedCount: number;
  totalFilledFieldsCount: number;
  onNextClick: () => void;
  onReviewAgainClick?: () => void;
  onFieldNameClick: () => void;
  onGenerate: () => void;
  onClose: () => void;
  isGenerating: boolean;
};

const FieldNameTypography = styled(Typography)(`
p {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 176px;
  white-space: nowrap;
  display: inline-block;
  cursor: pointer;
},
svg {
  margin-bottom: 10px;
  cursor: pointer;
}
`);

const ProgressTrackerWidget = ({
  widgetRef,
  fieldName,
  onNextClick,
  onReviewAgainClick,
  totalFieldsCount,
  fieldsReviewedCount,
  totalFilledFieldsCount,
  onFieldNameClick,
  onGenerate,
  onClose,
  isGenerating,
}: ProgressTrackerWidgetProps) => {
  const areAllFieldsReviewed = fieldsReviewedCount === totalFieldsCount;
  const areAllFieldsFilled = totalFilledFieldsCount === totalFieldsCount;
  const noFieldIsSelected = fieldName === undefined;

  const getFieldNameTypography = () => {
    if (noFieldIsSelected) {
      let msg = 'No field is selected.';
      if (areAllFieldsFilled) {
        msg = 'No blank fields remaining.';
      } else if (areAllFieldsReviewed) {
        msg = 'All blank fields are reviewed';
      }
      return (
        <Typography
          variant="button"
          sx={(theme) => ({
            color: theme.palette.grey[400],
          })}
        >
          {msg}
        </Typography>
      );
    }
    return (
      <Tooltip title={fieldName} placement="bottom-start" arrow>
        <FieldNameTypography variant="button" onClick={onFieldNameClick}>
          <p>{fieldName}</p>
          <ArrowRight size={11} />
        </FieldNameTypography>
      </Tooltip>
    );
  };
  return (
    <Card
      ref={widgetRef}
      raised
      sx={{
        width: '224px',
        position: 'fixed',
        left: 'calc(50% + 380px)',
        top: '20%',
        transition: 'top 500ms ease',
      }}
    >
      <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ marginRight: 'auto' }}>
            <Typography variant="smallCaption" sx={{ marginBottom: '16px' }}>
              REVIEW FIELDS
            </Typography>
          </Box>
          <Box>
            <IconButton
              aria-label="close"
              onClick={() => {
                onClose();
              }}
            >
              <Close />
            </IconButton>
          </Box>
        </Box>

        {getFieldNameTypography()}

        <Typography
          variant="smallLabel"
          sx={(theme) => ({
            color: theme.palette.text.subdued,
            marginTop: '16px',
            textAlign: 'end',
          })}
        >
          {totalFieldsCount - fieldsReviewedCount}{' '}
          {totalFieldsCount - fieldsReviewedCount > 1 ? 'fields' : 'field'} to
          review
        </Typography>
        <LinearProgress
          variant="determinate"
          value={(fieldsReviewedCount / totalFieldsCount) * 100}
          sx={(theme) => ({
            margin: '4px 0px 16px',
            backgroundColor: theme.palette.green[50],
            height: '10px',
            borderRadius: '10px',
            '& > .MuiLinearProgress-bar': {
              backgroundColor: theme.palette.green[400],
            },
          })}
        />

        {!areAllFieldsReviewed && (
          <Button
            onClick={onNextClick}
            className="mb-4"
            sx={(theme) => {
              return {
                backgroundColor: theme.palette.grey[100],
                color: theme.palette.grey[900],
                border: 'none',
                '&:hover': {
                  backgroundColor: theme.palette.grey[200],
                  border: 'none',
                },
              };
            }}
          >
            Next
          </Button>
        )}

        {areAllFieldsReviewed && !areAllFieldsFilled && (
          <Button
            onClick={onReviewAgainClick}
            className="mb-4"
            sx={(theme) => ({
              backgroundColor: theme.palette.grey[100],
              color: theme.palette.grey[900],
              border: 'none',
              '&:hover': {
                backgroundColor: theme.palette.grey[200],
                border: 'none',
              },
            })}
          >
            Review again
          </Button>
        )}

        {areAllFieldsReviewed && areAllFieldsFilled && (
          <Button
            onClick={onGenerate}
            disabled={isGenerating}
            className="mb-4"
            sx={(theme) => ({
              backgroundColor: theme.palette.background.page,
              color: theme.palette.grey[900],
              border: 'none',
              '&:hover': {
                border: 'none',
              },
            })}
          >
            Generate document(s)
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default ProgressTrackerWidget;
