import React from 'react';
import { useUIFlagEnabled } from '~/src/entities/uiFlags';
import { SettingsSubscriptionPageV2 } from './SettingsSubscriptionPageV2';
import { SettingsSubscriptionPage } from './SettingsSubscriptionPage';

export default () =>
  useUIFlagEnabled('newSubscribePage') ? (
    <SettingsSubscriptionPageV2 />
  ) : (
    <SettingsSubscriptionPage />
  );
