import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';
import theme from '~/src/theme';

import { Square, CheckSquare, PlusSquare, MinusSquare } from 'react-feather';

import styles from './styles';

const Checkbox = ({
  id,
  checked,
  color,
  size,
  onClick,
  disabled,
  cssStyles = undefined,
}) => {
  const [hover, setHover] = useState(false);

  const handleMouseLeave = () => setHover(false);
  const handleMouseEnter = () => setHover(true);

  const handleClick = () => {
    if (disabled) {
      return;
    }

    if (id && onClick) {
      onClick(id);
    } else if (onClick) {
      onClick();
    }
  };

  const Icon = hover && !disabled ? PlusSquare : Square;
  const CheckedIcon = hover && !disabled ? MinusSquare : CheckSquare;

  return (
    <div
      className={css(styles.checkbox, !disabled && styles.clickable, cssStyles)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {!checked && (
        <Icon
          size={theme.unit * size}
          color={disabled ? theme.colors.concrete : color}
          onClick={handleClick}
        />
      )}
      {checked && (
        <CheckedIcon
          size={theme.unit * size}
          color={theme.colors.bluebird}
          onClick={handleClick}
        />
      )}
    </div>
  );
};

Checkbox.propTypes = {
  id: PropTypes.number,
  size: PropTypes.number,
  checked: PropTypes.bool,
  color: PropTypes.string,
  onClick: PropTypes.func,
};

Checkbox.defaultProps = {
  id: null,
  size: 1.5,
  checked: false,
  color: theme.colors.pattensBlue,
  onClick: () => {},
};

export default Checkbox;
