/* Libraries */
import { addMiddleware } from 'mobx-state-tree';

/* Event Handlers */
import templateSetHandler from './templateSets';
import projectsEventHandler from './projects';
import userEventHandler from './user';

const analyticsMiddleware = (stores, rootStore) => {
  const storeActionHandlers = {
    templateSets: templateSetHandler,
    projects: projectsEventHandler,
    user: userEventHandler,
  };

  Object.keys(storeActionHandlers).forEach((storeKey) => {
    const store = stores[storeKey];

    if (store) {
      addMiddleware(store, (call, next) => {
        if (typeof storeActionHandlers[storeKey] === 'function') {
          storeActionHandlers[storeKey](call, rootStore);
        }

        return next(call);
      });
    }
  });
};

export default analyticsMiddleware;
