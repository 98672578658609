import { useObserver } from 'mobx-react';
import useMst from '~/src/hooks/useMst';
import useResourcePagination from '~/src/hooks/useResourcePagination';

const useDocumentsSetsPagination = () => {
  const { documentSets } = useMst((store) => ({
    documentSets: store.documentSets,
  }));

  const { loading, next, empty, done, page, setSearch, setSortBy, setOrder } =
    useResourcePagination({
      fetch: documentSets.fetch,
      limit: 10,
      order: 'desc',
    });

  const listData = useObserver(() => {
    return documentSets.list.flat();
  });

  return {
    next,
    page,
    empty,
    data: listData,
    loading,
    done,

    setSearch,
    setSortBy,
    setOrder,
  };
};

export default useDocumentsSetsPagination;
