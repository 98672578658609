import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';
import { fadeOutRight, fadeInRight } from 'react-animations';

export const ANIMATION_DURATION = 400;

export default StyleSheet.create({
  container: {
    position: 'fixed',
    right: theme.unit * 3,
    bottom: theme.unit * 3,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 11,
    fontSize: '14px',
  },
  containerHidden: {
    right: '-100%',
  },
  containerAnimateOut: {
    animationName: fadeOutRight,
    animationDuration: `${ANIMATION_DURATION}ms`,
    animationFillMode: 'forwards',
  },
  containerAnimateIn: {
    animationName: fadeInRight,
    animationDuration: `${ANIMATION_DURATION}ms`,
    animationFillMode: 'backwards',
  },
  toast: {
    minWidth: 300,
    color: theme.colors.white,
    fontWeight: 600,
    borderRadius: theme.borderRadius,
    boxShadow: theme.boxShadow,
    padding: theme.unit * 2,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  toastCloseIcon: {
    paddingLeft: theme.unit * 2,
    ':hover': {
      cursor: 'pointer',
    },
  },
  toastLoadingIcon: {
    paddingRight: theme.unit * 2,
  },
  successToast: {
    background: '#13A368',
  },
  errorToast: {
    background: '#DA4E3C',
  },
  loadingToast: {
    background: theme.colors.bluebird,
  },
});
