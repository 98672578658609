export type Step =
  | 'select-documents'
  | 'choose-signers'
  | 'add-signatures'
  | 'send-email';

export enum CURRENT_STEP {
  SELECT_DOCUMENTS = 1,
  CHOOSE_SIGNERS,
  ADD_SIGNATURES,
  SEND_EMAIL,
  SENT_FOR_SIGNATURES,
}

export const DRAGGABLE_TYPES = {
  RECIPIENTS: 'recipients',
  SIGNATURE_DOCUMENTS: 'signatureDocuments',
};

export const TOO_FEW_SIGNERS = 'TOO_FEW_SIGNERS';
export const NO_DOCUMENTS_SELECTED = 'NO_DOCUMENTS_SELECTED';
export const SOME_RECIPIENTS_INVALID = 'SOME_RECIPIENTS_INVALID';
export const EMAIL_SUBJECT_REQUIRED = 'EMAIL_SUBJECT_REQUIRED';
export const IS_LOADING = 'IS_LOADING';
export const NEED_AT_LEAST_ONE_SIGNATURE_FOR_EACH =
  'NEED_AT_LEAST_ONE_SIGNATURE_FOR_EACH';
export const INVALID_REASON_TOOLTIP_MAP = {
  [TOO_FEW_SIGNERS]: 'Too few recipients for package',
  [NO_DOCUMENTS_SELECTED]: 'No documents selected',
  [SOME_RECIPIENTS_INVALID]: 'Some recipients are invalid',
  [NEED_AT_LEAST_ONE_SIGNATURE_FOR_EACH]:
    'Every recipient must have at least one signature',
  [EMAIL_SUBJECT_REQUIRED]: 'The email subject must not be empty',
  [IS_LOADING]: 'Loading...',
};

export const DOCUMENT_UPLOAD_MAX_MEMORY_BYTES = 25 * 1000 * 1000;
export const DOCUMENT_UPLOAD_ALLOWED_FILES_TYPES = ['docx', 'pdf', 'doc'];
