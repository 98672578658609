import assert from 'assert';
import T from 'prop-types';

import {
  Signers,
  Signer,
  SignaturePackage,
  WhoSigns,
  PackageId,
  MeOnly,
  MeAndOthers,
  OthersOnly,
  SignatureBox,
  InputType,
  Assignee,
  Placeholder,
  SignedWithImage,
  DateBox,
  Dated,
  PageIdsByPageNumber,
  Pdf,
  UpdateElementPayload,
  AssignSignatureOrDateRequestBody,
  AssignDateNowRequestBody,
} from '~/src/models';

import env from '~/src/utils/env';

export const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result && result.length >= 4
    ? {
        r: parseInt(result[1]!, 16),
        g: parseInt(result[2]!, 16),
        b: parseInt(result[3]!, 16),
      }
    : null;
};

export const signerPropType = T.exact({
  email: T.string.isRequired,
  name: T.string.isRequired,
  id: T.number.isRequired,
});

export const signersPropType = T.oneOfType([
  T.exact({
    me: signerPropType.isRequired,
    others: T.arrayOf(signerPropType.isRequired).isRequired,
  }),
  T.exact({
    me: signerPropType.isRequired,
  }),
  T.exact({
    others: T.arrayOf(signerPropType.isRequired).isRequired,
  }),
]).isRequired;

export const getAllSigners = (signers: Signers): Signer[] => {
  let all: Signer[] = [];
  if ('others' in signers) {
    all = all.concat(signers.others);
  }
  if ('me' in signers) {
    all.push(signers.me);
  }
  return all;
};

export const makePackageSigners = (
  signaturePackage: SignaturePackage.SignaturePackage,
  // myEmail: string,
): Signers => {
  const firstRecipient: SignaturePackage.Recipient =
    signaturePackage.recipients[0]!;
  const firstSigner: Signer = {
    email: firstRecipient.email,
    name: firstRecipient.name,
    id: firstRecipient.id,
  };

  if (signaturePackage.whoSigns === WhoSigns.JustMe) {
    const result: MeOnly = {
      // me: signaturePackage.recipients.find((s) => s.email === myEmail)!,
      me: firstSigner,
    };
    return result;
  }

  const recipientsExcludeCurrentUser = signaturePackage.recipients.slice(1); // excludes the first element ("Me") from recipients array

  assert(
    !signaturePackage.recipients.some((e) => e.emailSent),
    'Emails were already sent to some signers!',
  );
  if (signaturePackage.whoSigns === WhoSigns.MeAndOthers) {
    const result: MeAndOthers = {
      // me: signaturePackage.recipients.find((s) => s.email === myEmail)!,
      me: firstSigner,

      others: recipientsExcludeCurrentUser // signaturePackage.recipients
        // .filter((e) => e.email !== myEmail)
        .map(({ email, name, id }) => ({ email, name, id })),
    };
    return result;
  }

  assert(
    signaturePackage.whoSigns === WhoSigns.Others,
    'Unknown whoSigns type found!',
  );
  const result: OthersOnly = {
    others: recipientsExcludeCurrentUser // signaturePackage.recipients
      // .filter((e) => e.email !== myEmail)
      .map(({ email, name, id }) => ({ email, name, id })),
  };
  return result;
};

export const makeSignatureBoxes = (
  pages: SignaturePackage.Page[],
): SignatureBox[] => {
  const assignments = pages
    .map(({ elements, pageNumber }) =>
      elements
        // an assignment must have a recipient,
        // shouldn't have an image height or width,
        // and should be of input type 5
        .filter(
          (e) =>
            e.recipient &&
            !e.imageHeight &&
            e.inputType === InputType.SignatureBlock,
        )
        .map(
          ({
            left,
            top,
            height,
            width,
            signaturePageId,
            recipient,
            id,
          }): Assignee => ({
            id,
            type: 'assignee',
            inputType: InputType.SignatureBlock,
            signaturePageId,
            pageNumber,
            left,
            top,
            height,
            width,
            recipient: {
              name: recipient!.name,
              email: recipient!.email,
              id: recipient!.id,
            },
          }),
        ),
    )
    .flat();

  // TODO: uncomment the code below once we are ready to implement placeholder fields:
  const placeholders: Placeholder[] = [];
  // const placeholders = pages
  //   .map(({ elements, pageNumber }) =>
  //     elements
  //       // a placeholder must not have a recipient,
  //       // shouldn't have an image height or width,
  //       // and should be of input type 5
  //       .filter(
  //         (e) =>
  //           !e.recipient &&
  //           !e.imageHeight &&
  //           !e.value &&
  //           e.inputType === InputType.SignatureBlock,
  //       )
  //       .map(
  //         ({ left, top, height, width, signaturePageId, id }): Placeholder => ({
  //           id,
  //           type: 'placeholder',
  //           inputType: InputType.SignatureBlock,
  //           signaturePageId,
  //           pageNumber,
  //           left,
  //           top,
  //           height,
  //           width,
  //         }),
  //       ),
  //   )
  //   .flat();

  const signatures = pages
    .map(({ elements, pageNumber }) =>
      elements
        // Signatures must have an XFDF image, have no value, and be of input type 5
        .filter(
          (e) =>
            e.recipient?.xfdfSignature &&
            !e.value &&
            e.inputType === InputType.SignatureBlock,
        )
        .map(
          ({
            left,
            top,
            imageHeight,
            imageWidth,
            signaturePageId,
            recipient,
            id,
          }): SignedWithImage => ({
            id,
            type: 'signed-with-image',
            inputType: InputType.SignatureBlock,
            signaturePageId,
            pageNumber,
            left,
            top,
            imageHeight: imageHeight!,
            imageWidth: imageWidth!,
            recipient: {
              name: recipient!.name,
              email: recipient!.email,
              id: recipient!.id,
              xfdfSignature: recipient!.xfdfSignature!,
            },
          }),
        ),
    )
    .flat();

  return [...assignments, ...placeholders, ...signatures];
};

export const makeDateBoxes = (pages: SignaturePackage.Page[]): DateBox[] => {
  const assignments = pages
    .map(({ elements, pageNumber }) =>
      elements
        .filter((e) => e.recipient && e.inputType === InputType.SignatureDate)
        .map(
          ({
            left,
            top,
            height,
            width,
            signaturePageId,
            recipient,
            id,
          }): Assignee => ({
            id,
            type: 'assignee',
            inputType: InputType.SignatureDate,
            signaturePageId,
            pageNumber,
            left,
            top,
            height,
            width,
            recipient: {
              name: recipient!.name,
              email: recipient!.email,
              id: recipient!.id,
            },
          }),
        ),
    )
    .flat();

  // TODO: uncomment the code below once we are ready to implement placeholder fields:
  const placeholders: Placeholder[] = [];
  // const placeholders = pages
  //   .map(({ elements, pageNumber }) =>
  //     elements
  //       // a placeholder must not have a recipient,
  //       // shouldn't have an image height or width,
  //       // and should be of input type 5
  //       .filter(
  //         (e) =>
  //           !e.recipient &&
  //           !e.imageHeight &&
  //           e.inputType === InputType.SignatureDate,
  //       )
  //       .map(
  //         ({ left, top, height, width, signaturePageId, id }): Placeholder => ({
  //           id,
  //           type: 'placeholder',
  //           inputType: InputType.SignatureBlock,
  //           signaturePageId,
  //           pageNumber,
  //           left,
  //           top,
  //           height,
  //           width,
  //         }),
  //       ),
  //   )
  //   .flat();

  const dates = pages
    .map(({ elements, pageNumber }) =>
      elements
        .filter(
          (e) => e.value && e.recipient && e.inputType === InputType.Textbox,
        )
        .map(
          ({
            left,
            top,
            height,
            width,
            signaturePageId,
            value,
            recipient,
            id,
          }): Dated => ({
            id,
            type: 'dated',
            inputType: InputType.Textbox,
            value: value!,
            signaturePageId,
            pageNumber,
            left,
            top,
            height,
            width,
            recipient: {
              name: recipient!.name,
              email: recipient!.email,
              id: recipient!.id,
            },
          }),
        ),
    )
    .flat();

  return [...assignments, ...placeholders, ...dates];
};

export const makePackageIds = (pages: SignaturePackage.Page[]): PackageId[] => {
  return pages
    .map(({ elements, pageNumber }) =>
      elements
        .filter((e) => e.value && e.value.includes('Package ID:'))
        .map(
          ({
            left,
            top,
            height,
            width,
            signaturePageId,
            value,
            id,
          }): PackageId => ({
            id,
            type: 'packageId',
            inputType: InputType.TextArea,
            signaturePageId,
            left,
            top,
            height,
            width,
            value: value!,
            pageNumber,
          }),
        ),
    )
    .flat();
};

export const makePageIdsByPageNumber = (pages: SignaturePackage.Page[]) => {
  return pages.reduce((acc, each) => {
    acc[each.pageNumber] = each.id.toString();
    return acc;
  }, {} as PageIdsByPageNumber);
};

export const makePackagePdfs = (
  signaturePackage: SignaturePackage.SignaturePackage,
): Array<Pdf> => {
  return signaturePackage.lawyawDocuments.map((e) => {
    // TODO: Uncomment assertion once it's true
    assert('originalFileUrl' in e && !!e.originalFileUrl, 'No file URL found!');
    const url = e.originalFileUrl.startsWith('http')
      ? new URL(e.originalFileUrl)
      : new URL(env.apiUrl + e.originalFileUrl);
    return {
      id: e.id.toString(),
      title: e.title,
      // TODO: Undo defaulting the bodyData to a sample file,
      // because originalFileUrl should always exist at this point:
      bodyData:
        'originalPDfBlob' in e && 'size' in e && !!e.originalPDfBlob
          ? e.originalPDfBlob
          : url,
      signatures: makeSignatureBoxes(e.pages),
      dates: makeDateBoxes(e.pages),
      packageIds: makePackageIds(e.pages),
      pageIdsByPageNumber: makePageIdsByPageNumber(e.pages),
    };
  });
};

export const makeAssignSignatureOrDateBody = (
  assignee: UpdateElementPayload,
  pageIdsByPageNumber: PageIdsByPageNumber,
): AssignSignatureOrDateRequestBody => {
  assert(
    assignee.inputType === InputType.SignatureDate ||
      assignee.inputType === InputType.SignatureBlock ||
      assignee.inputType === InputType.TextArea,
    'Assignment is of wrong input type!',
  );
  let result: AssignSignatureOrDateRequestBody | null = null;
  if (assignee.type === 'assignee') {
    result = {
      left: assignee.left,
      top: assignee.top,
      height: assignee.height,
      width: assignee.width,
      input_type: assignee.inputType,
      signature_page_id: Number(pageIdsByPageNumber[assignee.pageNumber]!),
      recipient: {
        name: assignee.recipient.name,
        email: assignee.recipient.email,
        id: assignee.recipient.id,
      },
    };
  } else if (assignee.type === 'signed-with-image') {
    result = {
      left: assignee.left,
      top: assignee.top,
      image_height: assignee.imageHeight,
      image_width: assignee.imageWidth,
      width: assignee.imageWidth,
      height: assignee.imageHeight,
      input_type: assignee.inputType,
      signature_page_id: Number(pageIdsByPageNumber[assignee.pageNumber]!),
      recipient: {
        name: assignee.recipient.name,
        email: assignee.recipient.email,
        id: assignee.recipient.id,
      },
    };
  } else if (assignee.type === 'packageId') {
    result = assignee;
  }
  assert(!!result, 'Unhandled case!');
  if (assignee.id) {
    result.id = assignee.id;
  }
  return result;
};

export const makeAssignDateNowBody = (
  dated: Dated,
  pageIdsByPageNumber: PageIdsByPageNumber,
): AssignDateNowRequestBody[] => {
  assert(
    dated.inputType === InputType.Textbox,
    'Date now is of wrong input type!',
  );
  const result: AssignDateNowRequestBody = {
    left: dated.left,
    top: dated.top,
    height: dated.height,
    width: dated.width,
    value: dated.value,
    input_type: dated.inputType,
    signature_page_id: Number(pageIdsByPageNumber[dated.pageNumber]!),
    recipient: {
      name: dated.recipient.name,
      email: dated.recipient.email,
      id: dated.recipient.id,
    },
  };
  if (dated.id) {
    result.id = dated.id;
  }
  return [result];
};

export const extractNewAssignmentId = (
  pack: SignaturePackage.SignaturePackage,
  documentId: string,
  element: Assignee | Dated | SignedWithImage | PackageId,
): number => {
  const document = pack.lawyawDocuments.find(
    (e) => e.id.toString() === documentId,
  )!;
  if ('recipient' in element) {
    const {
      recipient: { email, name, id },
      left,
      top,
    } = element;
    const target = document.pages[element.pageNumber - 1]!.elements.find(
      (e) =>
        e.left === left &&
        e.top === top &&
        e.recipient!.email === email &&
        e.recipient!.name === name &&
        e.recipient!.id === id,
    );
    assert(!!target, 'No element found for assignment!');
    return target.id;
  }
  const { value, left, top, signaturePageId } = element;
  const target = document.pages[element.pageNumber - 1]!.elements.find(
    (e) =>
      e.left === left &&
      e.top === top &&
      e.signaturePageId === signaturePageId &&
      e.value === value,
  );
  assert(!!target, 'No element found for assignment!');
  return target.id;
};

const PAGE_XFDF_REGEX = /page="[0-9]*"/g;
const NOT_A_NUMBER = '<gesture>NaN';

export const sanitizeSignature = (xfdfSignature: string): string | null => {
  if (xfdfSignature.includes(NOT_A_NUMBER) || xfdfSignature.includes('null'))
    return null;

  return xfdfSignature.replace(PAGE_XFDF_REGEX, 'page="0"');
};
