import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';

import { Search } from 'react-feather';

import Spinner from '~/src/components/Spinner';
import TextInput from '~/src/components/Inputs/TextInput';
import styles from './styles';

import TableBody from './TableBody';

const Table = ({
  stackActions,
  hideHeader,
  hideColumnHeaders,
  isLoading,
  sortedData,
  columns,
  childColumns,
  childRowStyle,
  rowStyle,
  setSortBy,
  sortBy,
  isSortByDisabled,
  sortOrder,
  filter,
  setFilter,
  renderActions,
  searchPlaceholder,
  emptyTitle,
  empty,
  noBorder,
  onRowClick,
  onShowMore,
  rowClickable,
  customButton,
}) => {
  let body = (
    <TableBody
      noBorder={noBorder}
      hideColumnHeaders={hideColumnHeaders}
      sortedData={sortedData}
      columns={columns}
      childColumns={childColumns}
      childRowStyle={childRowStyle}
      rowStyle={rowStyle}
      setSortBy={setSortBy}
      sortBy={sortBy}
      isSortByDisabled={isSortByDisabled}
      sortOrder={sortOrder}
      onRowClick={onRowClick}
      onShowMore={onShowMore}
      rowClickable={rowClickable}
    />
  );

  if (empty) {
    body = (
      <div className={css(styles.emptyContainer)}>
        <h1 className={css(styles.emptyTitle)}>{emptyTitle}</h1>
      </div>
    );
  }

  const header = (
    <div
      className={css(
        styles.actionContainer,
        stackActions && styles.actionContainerStacked,
      )}
    >
      <TextInput
        value={filter}
        onChange={setFilter}
        LeftIcon={Search}
        placeholder={searchPlaceholder}
        cssStyle={stackActions ? styles.actionSearchStacked : undefined}
      />

      {customButton}

      <div className={css(styles.actions)}>
        {renderActions && renderActions()}
      </div>
    </div>
  );

  return (
    <div className={css(styles.container)}>
      {!hideHeader && header}
      {isLoading ? <Spinner /> : body}
    </div>
  );
};

Table.propTypes = {
  isLoading: PropTypes.bool,
  sortedData: PropTypes.array,
  searchPlaceholder: PropTypes.string,
  filter: PropTypes.string,
  stackActions: PropTypes.bool,
  hideHeader: PropTypes.bool,
  noBorder: PropTypes.bool,
  hideColumnHeaders: PropTypes.bool,
  rowClickable: PropTypes.bool,
  empty: PropTypes.bool,

  sortOrder: PropTypes.oneOf([0, 1, -1]),
  sortBy: PropTypes.string,
  setSortBy: PropTypes.func,
  onRowClick: PropTypes.func,
  onShowMore: PropTypes.func,
  customButton: PropTypes.element,

  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      dataKey: PropTypes.string,
      hideLabel: PropTypes.bool,
      style: PropTypes.object,
      disableSort: PropTypes.bool,
      render: PropTypes.func,
    }),
  ),
};

Table.defaultProps = {
  noBorder: false,
  empty: false,
  stackActions: false,
  hideHeader: false,
  rowClickable: false,
  searchPlaceholder: 'Search',
  customButton: null,
  onShowMore: () => {},
  onRowClick: () => {},
};

export default Table;
