/* Libraries */
import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';

/* Styles */
import styles, { ANIMATION_DURATION } from './styles';

const TOAST_DELAY = 2500;

class ToasterLayout extends React.Component {
  state = {
    animate: false,
    mounted: false,
  };

  componentDidMount() {
    this.setState({ mounted: true, animate: this.props.show });
  }

  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  show = () => {
    this.setState({ animate: true });
    setTimeout(this.props.onCancel, this.props.toastDelay || TOAST_DELAY);
  };

  hide = () => {
    setTimeout(() => {
      if (this.state.mounted) {
        this.setState({ animate: false });
      }
    }, ANIMATION_DURATION);
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.show && this.props.show) {
      this.show();
    }

    if (prevProps.show && !this.props.show) {
      this.hide();
    }
  }

  render() {
    const { children, show } = this.props;
    const { animate } = this.state;

    return (
      <div
        className={css(
          styles.container,
          !animate && styles.containerHidden,
          !show && styles.containerAnimateOut,
          show && styles.containerAnimateIn,
        )}
      >
        {children}
      </div>
    );
  }
}

ToasterLayout.defaultProps = {
  show: false,
};

ToasterLayout.propTypes = {
  show: PropTypes.bool,
};

export default ToasterLayout;
