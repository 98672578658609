import React from 'react';

import TextInput from '~/src/components/Inputs/TextInput';
import { Text } from '~/src/components/Typography';

const NonDraggableRecipientMe = ({ recipient }) => {
  if (!recipient) {
    return null;
  }

  return (
    <div className="flex border-t border-gray-200">
      <div className="p-4 flex-auto">
        <div className="flex items-center justify-between mb-3">
          <Text strong>{recipient.name || 'Me'}</Text>
        </div>
        <div className="flex gap-3">
          <TextInput
            label="Name"
            className="flex-auto"
            value={recipient.name}
            editable={false}
            noMargin
            disabled={true}
          />
          <TextInput
            label="Email"
            className="flex-auto"
            value={recipient.email}
            editable={false}
            noMargin
            disabled={true}
          />
        </div>
      </div>
    </div>
  );
};

export default NonDraggableRecipientMe;
