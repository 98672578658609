import React, { FC, useState } from 'react';
import { pick, isEqual } from 'lodash';
import { SignaturePackage } from '~/src/models';
import EmailValidator from 'email-validator';
import { LAYOUT_TOAST_TYPES } from '../components/PageLayout/Toasts';
import {
  EMAIL_SUBJECT_REQUIRED,
  INVALID_REASON_TOOLTIP_MAP,
  SOME_RECIPIENTS_INVALID,
  CURRENT_STEP,
} from '../components/SignaturePackage/constants';
import {
  DocumentsToSend,
  EmailBody,
  EmailCarbonCopy,
  PackageSigners,
} from '../components/SendEmail';
import {
  showSaveModal,
  showDiscardChangesModal,
} from '../components/SignaturePackage/SaveModals';
import HeaderFooter from './HeaderFooter';

const isValid = (emailSubject: string, emailCC: { emailId: string }[]) => {
  if (!emailSubject)
    return {
      valid: false,
      invalidReason: INVALID_REASON_TOOLTIP_MAP[EMAIL_SUBJECT_REQUIRED],
    };

  if (emailCC.some(({ emailId }) => !EmailValidator.validate(emailId)))
    return {
      valid: false,
      invalidReason: INVALID_REASON_TOOLTIP_MAP[SOME_RECIPIENTS_INVALID],
    };

  return { valid: true };
};

interface Props {
  user: any;
  signaturePackage: SignaturePackage.SignaturePackage;
  showToast: (...args: any[]) => void;
  showModal: (type: string, object: any) => void;
  hideModal: () => void;
  onBack: () => void;
  onNext: () => void;
  onSend: (emailPackage: SignaturePackage.SignaturePackage) => Promise<void>;
  onUpdate: (
    user: any,
    signaturePackge: any,
  ) => Promise<SignaturePackage.SignaturePackage>;
  onCompletion: () => void;
}

const SendEmailContainer: FC<Props> = ({
  user,
  signaturePackage,
  showToast,
  showModal,
  hideModal,
  onBack,
  onNext,
  onSend,
  onUpdate,
  onCompletion,
}) => {
  const currentUserInfo: SignaturePackage.LeanRecipient = {
    id: 0,
    email: user.profile.email || user.currentOrganization.email,
    name: user.profile.displayName,
    signOrder: 0,
  };

  const getInitialEmailCC = () => {
    if (!signaturePackage || !signaturePackage.emailCc) return [];

    return (signaturePackage.emailCc as string).split(',').map((email) => {
      return { emailId: email };
    });
  };

  const [emailSubject, setEmailSubject] = useState(
    signaturePackage ? signaturePackage!.emailSubject! : '',
  );

  const [emailMessage, setEmailMessage] = useState(
    signaturePackage ? signaturePackage!.emailBody! : '',
  );

  const [emailCC, setEmailCC] = useState<{ emailId: string }[]>(() =>
    getInitialEmailCC(),
  );

  const saveEmailInfo = () => {
    const signaturePackageData = {
      signaturePackageId: signaturePackage.id,
      title: emailSubject,
      emailSubject,
      emailBody: emailMessage,
      emailCC,
      currentStep: CURRENT_STEP.SEND_EMAIL,
    };

    return onUpdate(currentUserInfo, signaturePackageData);
  };

  const sendSignaturePackage = async (
    updatedPackage: SignaturePackage.SignaturePackage,
  ) => {
    try {
      await onSend(updatedPackage);
    } catch {
      showToast(LAYOUT_TOAST_TYPES.error, {
        message: 'Email request unsuccessful, please try again.',
      });
    } finally {
      onCompletion();
    }
  };

  const handleNextClick = async () => {
    const updatedPackage = await saveEmailInfo();
    await sendSignaturePackage(updatedPackage);
  };

  const noChangesMade = () => {
    return isEqual(
      Object.assign(pick(signaturePackage, ['emailSubject', 'emailBody']), {
        emailCC: getInitialEmailCC(),
      }),
      { emailSubject, emailBody: emailMessage, emailCC },
    );
  };

  const handleExitClick = () => {
    if (signaturePackage) {
      // save message
      const onConfirm = async () => {
        await saveEmailInfo();
        onNext();
        hideModal();
      };

      if (noChangesMade()) {
        onNext();
      } else {
        showSaveModal(showModal, onConfirm, () => {
          onNext();
          hideModal();
        });
      }
    } else {
      // no save changes
      showDiscardChangesModal(showModal, () => {
        onNext();
        hideModal();
      });
    }
  };

  const { valid, invalidReason } = isValid(emailSubject, emailCC);

  return (
    <HeaderFooter
      signaturePackageTitle={signaturePackage.title}
      whoSigns={signaturePackage.whoSigns}
      step={'send-email'}
      invalidReason={invalidReason!}
      valid={valid}
      onBackClick={onBack}
      onNextClick={handleNextClick}
      onExitClick={handleExitClick}
    >
      <div className="w-[36rem] mx-auto">
        <DocumentsToSend checkedDocuments={signaturePackage.lawyawDocuments} />
        <PackageSigners
          recipients={signaturePackage.recipients.slice(1)} // remove author
          whoSigns={signaturePackage.whoSigns}
          currentUserInfo={currentUserInfo}
        />
        <EmailCarbonCopy emailCC={emailCC} setEmailCC={setEmailCC} />
        <EmailBody
          emailSubject={emailSubject}
          setEmailSubject={setEmailSubject}
          emailMessage={emailMessage}
          setEmailMessage={setEmailMessage}
        />
      </div>
    </HeaderFooter>
  );
};

export default SendEmailContainer;
