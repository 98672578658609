import React from 'react';

import Button from '~/src/components/Button';
import { useUpdatePaymentMethodModal } from '~/src/entities/subscription';
import { useStore } from '~/src/hooks/useMst';

import styles from './styles';

export const UpdatePaymentMethodButton = () => {
  const store = useStore();
  const [toggleModal, updatePaymentMethodModal] = useUpdatePaymentMethodModal({
    onUpdated: () => store.user.currentOrganization?.fetchSubscription(),
  });
  return (
    <>
      {updatePaymentMethodModal}
      <Button
        cssStyle={styles.settingsSubscriptionLabelButton}
        onClick={() => toggleModal()}
      >
        Update payment method
      </Button>
    </>
  );
};
