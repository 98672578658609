import { useEffect, useState } from 'react';
import { Matter } from '~/src/stores/matterStore';
import { Project } from '~/src/stores/projectsStore';
import { Instance } from 'mobx-state-tree';

import matterService from '~/src/services/matter';
import { useStore } from '~/src/hooks/useMst';

export const useDraftDocumentsUsingQuestionnaire = (
  orgFprint: string,
  matterId: string | undefined,
) => {
  const store = useStore();
  const [matterData, setMatterData] =
    useState<Instance<typeof Matter | null>>(null);
  const [isMatterError, setIsMatterError] = useState(false);

  useEffect(() => {
    const getMatterData = async () => {
      if (!matterId || !orgFprint) return;

      try {
        setIsMatterError(false);
        const { matter } = await matterService.fetchMatter(orgFprint, matterId);

        setMatterData(matter as Instance<typeof Matter>);
      } catch (e) {
        setIsMatterError(true);
      }
    };

    getMatterData();
  }, [orgFprint, matterId]);

  const createDocSetAndUpdateProject = async (
    title: string,
    templateIds: string[],
  ): Promise<Instance<typeof Project>> => {
    setIsMatterError(!matterData);

    if (!matterData) {
      throw new Error('Matter data not found.');
    }

    const project = (await store.projects.createDocSet(
      templateIds,
      title,
      matterData.id,
    )) as Instance<typeof Project>;

    return project;
  };

  return { matterData, isMatterError, createDocSetAndUpdateProject };
};
