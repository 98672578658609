import React from 'react';
import { css } from 'aphrodite';
import { AlertCircle } from 'react-feather';

import Button from '~/src/components/Button';
import Banner from '~/src/components/Banner';

import styles from './styles';
import { useRenewSubscription } from '~/src/entities/subscription';
import { useStore } from '~/src/hooks/useMst';
import { useSnackbar } from 'notistack';

export const RenewSubscriptionButton = ({ message }) => {
  const store = useStore();
  const snackbar = useSnackbar();
  const { mutate, isLoading } = useRenewSubscription({
    onSuccess: () => {
      store.user.currentOrganization.fetchSubscription();
    },
    onError: () => {
      snackbar.enqueueSnackbar(
        'Something went wrong, please contact support.',
        { variant: 'error' },
      );
    },
  });

  return (
    <div className={css(styles.settingsRow)}>
      <Banner small alert Icon={AlertCircle}>
        {message}
      </Banner>
      <Button onClick={() => mutate()} disabled={isLoading} primary>
        {isLoading ? 'Renewing...' : 'Renew Subscription '}
      </Button>
    </div>
  );
};
