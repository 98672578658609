import React from 'react';
import { Chip, List, ListItemButton, ListItemText } from '@mui/material';
import { ArrowForwardIos } from '@mui/icons-material';
import { ClioMatter } from '~/src/services/api/clioService';

type ListMattersProps = {
  matters: ClioMatter[];
  onSelect: (matter: ClioMatter) => void;
};

export const ListMatters = ({ matters, onSelect }: ListMattersProps) => (
  <List>
    {matters.map((matter) => (
      <ListItemButton
        divider
        key={matter.display_number}
        onClick={() => onSelect(matter)}
      >
        <ListItemText>{matter.display_number}</ListItemText>
        {matter.already_imported ? (
          <Chip sx={{ mr: 2 }} label={'Already Imported'} />
        ) : null}
        <ArrowForwardIos />
      </ListItemButton>
    ))}
  </List>
);
