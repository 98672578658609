import { Typography } from '@mui/material';
import React from 'react';

export const MissingFields = ({ number }: { number: number }) => (
  <Typography
    variant={'caption'}
    sx={(theme) => ({
      color: theme.palette.yellow[700],
      fontWeight: 600,
    })}
  >
    {number}
  </Typography>
);
