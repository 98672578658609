import { useMutation } from 'react-query';
import { APIError } from '~/src/utils/error';
import { getQuestionnaireTemplateListUrl } from '../urls';
import { QuestionnaireTemplate } from '../types';

type CreateQuestionnaireTemplate = {
  orgFprint: string;
  questionnaireId: string;
  templateIds: number[];
};

export const useCreateQuestionnaireTemplate = () =>
  useMutation<QuestionnaireTemplate, APIError, CreateQuestionnaireTemplate>({
    mutationFn: async ({ orgFprint, questionnaireId, templateIds }) => {
      const response = await fetch(getQuestionnaireTemplateListUrl(orgFprint), {
        method: 'POST',
        body: JSON.stringify({
          questionnaire_id: questionnaireId,
          template_ids: templateIds,
        }),
      });

      if (!response.ok) {
        throw new APIError('Failed to create questionnaire template', response);
      }

      return response.json();
    },
  });
