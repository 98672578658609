import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';

import { VALIDATION_TYPES } from '~/src/utils/validation';

import Form, { renderFormField } from '~/src/components/Forms';

import Card, { CardTitle } from '~/src/components/Card';
import Button from '~/src/components/Button';

import styles from './styles';

const DefaultLabel = ({ isDefault, onSetAsDefault }) => {
  return isDefault ? (
    <p className={css(styles.settingsOrganizationDefault)}>default</p>
  ) : (
    <a
      role="button"
      className={css(styles.settingsOrganizationSetAsDefault)}
      onClick={onSetAsDefault}
    >
      Set as default
    </a>
  );
};

const SettingsOrganization = ({
  isDefault,
  showSetAsDefault,
  currentOrganizationName,
  onSave,
  onChange,
  onSetAsDefault,
}) => {
  const fields = [
    {
      label: 'Organization name',
      id: 'name',
      type: 'text',
      defaultValue: currentOrganizationName,
      validation: VALIDATION_TYPES.present,
    },
  ];

  const [currentFieldValue, setCurrentFieldValue] = useState(false);

  const handleFormChange = (form) => {
    if (form.fields.name && form.fields.name.value) {
      setCurrentFieldValue(form.fields.name.value);
      onChange(form.fields.name.value);
    }
  };

  const disabled = currentOrganizationName === currentFieldValue;
  const fieldsProp = fields.map((field) => renderFormField(field));

  const setAsDefaultLabel = showSetAsDefault ? (
    <DefaultLabel isDefault={isDefault} onSetAsDefault={onSetAsDefault} />
  ) : null;

  return (
    <div className={css(styles.settingsCardContainer)}>
      <Card>
        <CardTitle>Organization</CardTitle>
        <div
          className={css(
            styles.settingsRow,
            styles.settingsOrganizationNameRow,
          )}
        >
          {setAsDefaultLabel}
          <Form
            fields={fieldsProp}
            onChange={handleFormChange}
            triggerOnMount={false}
          />
        </div>
      </Card>
      <div className={css(styles.settingsAccountButtonContainer)}>
        <Button primary disabled={disabled} onClick={onSave}>
          Save changes
        </Button>
      </div>
    </div>
  );
};

SettingsOrganization.defaultProps = {
  onChange: PropTypes.func,
  onSetAsDefault: PropTypes.func,
};

SettingsOrganization.defaultProps = {
  onChange: () => {},
  onSetAsDefault: () => {},
};

export default SettingsOrganization;
