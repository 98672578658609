import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
  modalHeading: {
    color: 'black !important',
    fontSize: '16px !important',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px !important',
  },
  modalDescription: {
    black: 'black !important',
    fontSize: '14px !important',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px !important',
    maxWidth: '475px !important',
  },
});
