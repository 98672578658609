/* Libraries */
import React from 'react';
import { css } from 'aphrodite';
import { useDropzone } from 'react-dropzone';
import uuid from 'uuid/v4';

/* Components */
import LoadingOverlay from '~/src/components/PageLayout/LoadingOverlay';

/* Styles */
import styles from './styles';

const UploadTextArea = ({
  disabled,
  uploading,
  maxFiles,
  multiple,
  acceptedDocs,
  onChange,
  onError,
}) => {
  const handleDropAccepted = (files) => {
    const nextFiles = files.map((file) => {
      file.id = uuid();
      return file;
    });

    onChange(nextFiles);
  };

  const dropzoneProps = {
    disabled: disabled || uploading,
    accept: acceptedDocs,
    onDropAccepted: handleDropAccepted,
    onDropRejected: onError,
    maxFiles,
    multiple,
  };

  const { getRootProps, getInputProps, isDragActive } =
    useDropzone(dropzoneProps);

  return (
    <div
      className={css(styles.dropArea, uploading && styles.dropAreaDisabled)}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {uploading && (
        <LoadingOverlay title="Uploading your documents" relative={true} />
      )}
      {isDragActive && (
        <div>
          <h1 className={css(styles.title)}>Drop your documents here...</h1>
          {/* <p className={css(styles.description)}>or <a>Browse files</a></p> */}
        </div>
      )}
      {!isDragActive && (
        <div>
          <h1 className={css(styles.title)}>Drag or drop documents here</h1>
          <p className={css(styles.description)}>
            or <a>Browse files</a>
          </p>
        </div>
      )}
    </div>
  );
};

export default UploadTextArea;
