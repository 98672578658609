import React from 'react';
import Button from '@mui/material/Button';
import { DialogContainer, MessageDialog } from '~/src/components/Modal/mui';

interface Props {
  open: boolean;
  title: string;
  message: React.ReactNode;
  onCancel: () => void;
  onConfirm: () => void;
}

const DeleteModal = ({ open, title, message, onCancel, onConfirm }: Props) => {
  return (
    <DialogContainer open={open} onClose={onCancel}>
      <MessageDialog
        title={title}
        onClose={onCancel}
        dialogActions={
          <>
            <Button onClick={onCancel}>Cancel</Button>
            <Button onClick={onConfirm} variant="red" autoFocus>
              Delete
            </Button>
          </>
        }
      >
        {message}
      </MessageDialog>
    </DialogContainer>
  );
};

export default DeleteModal;
