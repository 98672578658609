import { Box, IconButton, Tooltip } from '@mui/material';
import React from 'react';

export type CardListItemIconAction = {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
};

export type CardListItemIconActionsProps = {
  actions: CardListItemIconAction[];
};

export const CardListItemIconActions = ({
  actions = [],
}: CardListItemIconActionsProps) => {
  return (
    <Box display={'flex'} gap={1}>
      {actions.map(({ label, onClick, icon }) => (
        <Tooltip key={label} title={label}>
          <IconButton
            sx={(theme) => ({ color: theme.palette.grey[900] })}
            onClick={(e) => {
              e.stopPropagation();
              onClick();
            }}
          >
            {icon}
          </IconButton>
        </Tooltip>
      ))}
    </Box>
  );
};
