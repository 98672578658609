import { useEffect, useRef } from 'react';
import { isEqual } from 'lodash';
import { Contact } from '~/src/entities/contact';
import { ProjectStackFieldGroup } from '~/src/entities/project';
import { useUpdateContactWithProjectData } from '~/src/entities/project/hooks';
import { useDebouncedValue } from '~/src/hooks/useDebouncedValue';
import { useWatchContactDataFields } from './useWatchContactDataFields';

type ContactUpdaterWithProjectDataProps = {
  contact: Contact;
  group: ProjectStackFieldGroup;
};

const ContactUpdaterWithProjectData = ({
  contact,
  group,
}: ContactUpdaterWithProjectDataProps) => {
  const { mutate } = useUpdateContactWithProjectData(group['org_contact']);

  const contactId = contact?.id;

  const contactData = useDebouncedValue(
    useWatchContactDataFields(group.fields),
    1000,
  );

  // Store the previous contact data
  const prevContactDataRef = useRef(contactData);

  useEffect(() => {
    if (!contactId) return;

    // Clean up contactData by removing empty first_name and last_name
    const cleanedContactData = { ...contactData };
    if (cleanedContactData['first_name'] === '')
      delete cleanedContactData['first_name'];
    if (cleanedContactData['last_name'] === '')
      delete cleanedContactData['last_name'];

    // Check if there is any data to update
    if (Object.keys(cleanedContactData).length === 0) return;

    // Check if the data has actually changed
    if (isEqual(cleanedContactData, prevContactDataRef.current)) return;

    // Update the previous data ref
    prevContactDataRef.current = cleanedContactData;

    // Call mutate with the cleanedContactData
    mutate({
      contactId,
      stack_saved_data: cleanedContactData,
    });
  }, [mutate, contactId, contactData]);

  return null;
};

export default ContactUpdaterWithProjectData;
