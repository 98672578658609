import React from 'react';

import Card from '~/src/components/Card';
import Table from '~/src/containers/Table';
import { ReactComponent as DownloadIcon } from '~/src/static/download-icon.svg';

// const SettingsBilling = ({ invoices }) => {
const SettingsBilling = ({ receipts }) => {
  const renderHeader = () => 'Billing history';

  return (
    <Card noPadding renderHeader={renderHeader}>
      <Table
        emptyTitle={'No billing history'}
        noBorder={true}
        hideHeader={true}
        data={receipts}
        hideColumnHeaders
        columns={[
          {
            dataKey: 'created',
            style: { width: '40%', textAlign: 'left' },
          },
          {
            dataKey: 'amount_captured',
            style: { width: '50%', textAlign: 'left' },
          },
          {
            dataKey: 'reciept_url',
            render: (url) => {
              return (
                <a
                  href={url}
                  className="cursor-pointer bg-gray-100 rounded p-2 flex items-center justify-center"
                >
                  <DownloadIcon />
                </a>
              );
            },
          },
        ]}
      />
    </Card>
  );
};

SettingsBilling.defaultProps = {
  receipts: [],
};

export default SettingsBilling;
