import { http, getPaginationUrlQuery } from '~/src/services/requests';
import { PaginatedParams } from '~/src/globalTypes';

export interface TemplateWithValues {
  templateId: number;
  templateDefaultValuesId: number | null;
}
export interface TemplateSetPayload {
  title?: string;
  templateIds?: number[];
  templatesWithValues?: TemplateWithValues[];
}

interface TemplateWithValuesResponse {
  template_id: number;
  template_default_values_id: number | null;
}

export interface TemplateSetApiResponse {
  id: number;
  title: string;
  created_at: string;
  last_modified: string;
  num_templates: number;
  organization_id: number;
  search_text: string;
  templates: number[];
  templates_with_values: TemplateWithValuesResponse[];
}

function templateSetsService() {
  return {
    fetchById: async (
      orgFprint: string,
      templateSetId: number,
    ): Promise<TemplateSetApiResponse> => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v2/org/${orgFprint}/template-set/${templateSetId}/`,
          method: 'GET',
        },
        parse,
      );
    },
    update: async (
      orgFprint: string,
      id: number,
      payload: TemplateSetPayload,
    ): Promise<TemplateSetApiResponse> => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v2/org/${orgFprint}/template-set/${id}/`,
          method: 'PATCH',
          body: {
            title: payload.title,
            templatesWithValues: payload.templatesWithValues,
          },
        },
        parse,
      );
    },
    delete: async (orgFprint: string, id: number) => {
      const parse = false;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v2/org/${orgFprint}/template-set/${id}/`,
          method: 'DELETE',
        },
        parse,
      );
    },
    create: async (
      orgFprint: string,
      payload: TemplateSetPayload,
    ): Promise<TemplateSetApiResponse> => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v2/org/${orgFprint}/template-sets/`,
          method: 'POST',
          body: {
            title: payload.title,
            templatesWithValues: payload.templatesWithValues,
          },
        },
        parse,
      );
    },
    fetchAll: async (orgFprint: string): Promise<TemplateSetApiResponse[]> => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/template-sets/`,
          method: 'GET',
        },
        parse,
      );
    },
    fetch: async (
      orgFprint: string,
      { page, search, sortBy, order, limit }: PaginatedParams,
    ): Promise<TemplateSetApiResponse[]> => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v2/org/${orgFprint}/template-sets/${getPaginationUrlQuery(
            { page, search, sortBy, order, limit },
          )}`,
          method: 'GET',
        },
        parse,
      );
    },
  };
}

export default templateSetsService();
