import React from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import styles from './styles';
import { css } from 'aphrodite';

interface Props {
  title: string;
  dialogActions: React.ReactNode;
  children: React.ReactNode;
  onClose?: () => void;
}

export const MessageDialog = ({
  title,
  onClose,
  dialogActions,
  children,
}: Props) => {
  return (
    <React.Fragment>
      <DialogTitle sx={{ m: 0, p: 2 }} className={css(styles.modalHeading)}>
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 12,
          top: 12,
          color: (theme) => theme.palette.grey[500],
          borderRadius: 24,
        }}
      >
        <Close height={20} width={20} />
      </IconButton>
      <DialogContent dividers className={css(styles.modalDescription)}>
        {children}
      </DialogContent>
      <DialogActions>{dialogActions}</DialogActions>
    </React.Fragment>
  );
};
