/* Libraries */
import React from 'react';
import { Instance } from 'mobx-state-tree';

/* Components */
import Table from '~/src/containers/Table';
import { PdfCourtFormStore } from '~/src/stores/pdfCourtFormsStore';
import { ReactComponent as InformationIcon } from './information-icon.svg';

const RequestCourtForm = () => (
  <div className="mt-2">
    <InformationIcon className="mr-2 mb-1" />
    <span className="mr-2 hidden lg:inline">
      Can&apos;t find the forms you are looking for?
    </span>
    <a
      href="https://lawyaw.typeform.com/request-a-form"
      target="_blank"
      rel="noreferrer"
    >
      Request a form
    </a>
  </div>
);

interface PdfCourtForm extends Instance<typeof PdfCourtFormStore> {}
interface Props {
  pdfCourtForms: PdfCourtForm[];
  visibleItems: number;
  empty: boolean;
  renderRowAction: (t: any) => any;
  onSearchChange: (value: string) => void;
  onSortByChange: (property: string, order: number) => void;
}

const PdfTable = ({
  pdfCourtForms,
  visibleItems,
  empty,
  renderRowAction,
  onSearchChange,
  onSortByChange,
}: Props) => {
  return (
    <div className="flex">
      <Table
        emptyTitle={'No Court Forms match that filter'}
        empty={empty}
        isLoading={false}
        searchPlaceholder="Search court forms"
        renderActions={() => <RequestCourtForm />}
        data={pdfCourtForms}
        visibleItems={visibleItems}
        onSearchChange={onSearchChange}
        isSortByDisabled={false}
        onSortByChange={onSortByChange}
        columns={[
          {
            label: 'Form number',
            dataKey: 'title',
            style: {
              width: '30%',
              textAlign: 'left',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            },
          },
          {
            label: 'Form title',
            dataKey: 'subtitle',
            style: {
              width: '40%',
              textAlign: 'left',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            },
          },
          {
            label: 'Actions',
            hideLabel: true,
            render: renderRowAction,
            style: { flexGrow: 1, display: 'flex', justifyContent: 'flex-end' },
          },
        ]}
      />
    </div>
  );
};

export default PdfTable;
