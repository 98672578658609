import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

export default StyleSheet.create({
  dropArea: {
    position: 'relative',
    maxWidth: '878px',
    height: '200px',
    background: theme.colors.white,
    border: `dashed 1px ${theme.colors.bluebird}`,
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ':hover': {
      cursor: 'pointer',
    },
  },
  dropAreaActive: {
    background: `rgba(36,89,237, 0.1)`,
  },
  dropAreaDisabled: {
    ':hover': {
      cursor: 'not-allowed',
    },
  },
  title: {
    fontSize: theme.fonts.size.large,
    fontWeight: '400',
  },
  description: {
    textAlign: 'center',
    fontSize: theme.fonts.size.regular,
    marginTop: theme.unit * 2,
  },
});
