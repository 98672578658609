import { useQuery, UseQueryOptions } from 'react-query';
import { getTaskStatusUrl } from './urls';
import { TaskStatusResponse } from './types';
import { APIError } from '~/src/utils/error';
import { composeQueryOpts, fetchAndParse } from '~/src/entities/utils';
import { useCurrentOrgFprint } from '~/src/entities/user';
import { useRef } from 'react';

const keys = {
  taskStatus: (uuid: string | undefined) => ['taskStatus', uuid] as const,
};

export const usePollingTaskStatus = (
  taskId: string | undefined,
  options: UseQueryOptions<TaskStatusResponse | null, APIError> = {},
) => {
  const alreadyResolvedRef = useRef<boolean>(false);
  const orgFprint = useCurrentOrgFprint();
  return useQuery(
    composeQueryOpts(options, {
      enabled: !!taskId && !alreadyResolvedRef.current,
      queryKey: keys.taskStatus(taskId),
      refetchInterval: (data) => {
        if (
          alreadyResolvedRef.current ||
          data?.status === 'SUCCESS' ||
          data?.status === 'FAILURE'
        ) {
          alreadyResolvedRef.current = true;
          return false;
        }

        return 5000;
      },
      queryFn: () => {
        if (taskId) {
          return fetchAndParse<TaskStatusResponse>(
            getTaskStatusUrl(orgFprint, taskId),
          );
        }
        return Promise.resolve(null);
      },
    }),
  );
};
