import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';
import Triangle from '~/src/components/Triangle';

import styles from './styles';

const UserAvatar = ({
  triangleOrientation,
  onClick,
  showTriangle,
  src,
  cssStyle,
}) => (
  <div className={css(styles.container, cssStyle)} onClick={onClick}>
    <img src={src} className={css(styles.avatar)} />
    {showTriangle && <Triangle orientation={triangleOrientation} />}
  </div>
);

UserAvatar.defaultProps = {
  showTriangle: false,
  triangleOrientation: 'down',
  onClick: () => {},
};

UserAvatar.propTypes = {
  showTriangle: PropTypes.bool,
  triangleOrientation: PropTypes.string,
  onClick: PropTypes.func,
};

const getUserAvatarPlaceholder = (initials) => {
  return `https://ui-avatars.com/api/?name=${initials}&background=F2F4F7&color=2459ED`;
};

export { UserAvatar as default, getUserAvatarPlaceholder };
