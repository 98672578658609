/* Libraries */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import theme from "~/src/theme";
import { css } from 'aphrodite';
import TextareaAutosize from 'react-textarea-autosize';

/* Components */
import Label from './Label';

/* Styles */
import styles from './styles';

const TextArea = React.forwardRef((props, ref) => {
  const {
    cssStyle,
    placeholder,
    label,
    labelCssStyle,
    autoComplete,
    value,
    type,
    hint,
    count,
    error,
    editable,
    onChange,
    onFocus,
    onBlur,
    minRows,
  } = props;
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const [focus, setFocus] = useState(false);

  const handleOnFocus = () => {
    onFocus();
    setFocus(true);
  };
  const handleOnBlur = () => {
    onBlur();
    setFocus(false);
  };

  return (
    <Label
      label={label}
      hint={hint}
      count={count}
      error={error}
      focus={focus}
      editable={editable}
      cssStyle={cssStyle}
      labelCssStyle={labelCssStyle}
    >
      {!editable && (
        <p className={css(styles.textInput, styles.textInputLabelStatic)}>
          {value}
        </p>
      )}
      {editable && (
        <TextareaAutosize
          ref={ref}
          value={value}
          minRows={minRows}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          onChange={handleChange}
          autoComplete={autoComplete}
          className={css(styles.textInput)}
          type={type}
          placeholder={placeholder}
        />
      )}
    </Label>
  );
});

TextArea.propTypes = {
  cssStyle: PropTypes.object,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  hint: PropTypes.string,
  count: PropTypes.number,
  editable: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  minRows: PropTypes.number,
};

TextArea.defaultProps = {
  editable: true,
  minRows: 2,
  type: 'text',
  onFocus: () => {},
  onBlur: () => {},
};

export default TextArea;
