import { http, getPaginationUrlQuery } from '~/src/services/requests';
import { keysToSnakeCase } from '~/src/utils/objects';

function contactsService() {
  return {
    fetchDefaultContactFields: async (orgFprint) => {
      const parse = true;

      const url = `/clients/api/v1/org/${orgFprint}/contact/fields/`;

      return http.innerSendAuthenticated(
        {
          relativePath: url,
          method: 'GET',
        },
        parse,
      );
    },
    postContactFieldMap: async (orgFprint, fields, contactId) => {
      const parse = true;
      const rootUrl = `/clients/api/v1/org/${orgFprint}/contact`;
      const url = contactId
        ? `${rootUrl}/${contactId}/map/`
        : `${rootUrl}/map/`;
      return http.innerSendAuthenticated(
        {
          relativePath: url,
          method: 'POST',
          body: {
            fields,
          },
        },
        parse,
      );
    },
    detachContactFromCard: async (orgFprint, projectId, contactId, entity) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/project/${projectId}/contact/${contactId}/?role=${entity}`,
          method: 'DELETE',
        },
        parse,
      );
    },
    attachContactToCard: async (
      orgFprint,
      projectId,
      contactId,
      entity,
      attributes,
    ) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/project/${projectId}/contact/${contactId}/?role=${entity}`,
          method: 'POST',
          body: {
            entity,
            attributes,
          },
        },
        parse,
      );
    },
    getContacts: async (orgFprint, { page, search, sortBy, order, limit }) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/contact/${getPaginationUrlQuery(
            { page, search, sortBy, order, limit },
          )}`,
          method: 'GET',
        },
        parse,
      );
    },
    fetchContact: async (orgFprint, contactId) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/contact/${contactId}/`,
          method: 'GET',
        },
        parse,
      );
    },
    fetchContactInSpecificMatter: async (orgFprint, contactId, matterId) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/contact/${contactId}/?matter=${matterId}`,
          method: 'GET',
        },
        parse,
      );
    },
    createContact: async (orgFprint, type, contactProperties) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/contact/`,
          method: 'POST',
          body: keysToSnakeCase({
            contactType: type,
            contactSource: 'MANUAL',
            ...contactProperties,
          }),
        },
        parse,
      );
    },
    updateContact: async (orgFprint, id, type, fields) => {
      const parse = true;

      const { firstName, lastName, middleName, ...otherProps } = fields;

      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/contact/${id}/`,
          method: 'PATCH',
          body: keysToSnakeCase({
            contactType: type,
            contactSource: 'MANUAL',
            firstName,
            lastName,
            middleName,
            ...otherProps,
          }),
        },
        parse,
      );
    },
    deleteContact: async (orgFprint, id) => {
      const parse = false;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/contact/${id}/`,
          method: 'DELETE',
        },
        parse,
      );
    },
  };
}

export default contactsService();
