import { css } from 'aphrodite';
import React from 'react';
import Spinner from '../Spinner';
import styles from './styles';

type ModalContentRowProps = {
  children: React.ReactNode;
};

export const ModalContentRow = ({ children }: ModalContentRowProps) => {
  return <div className={css(styles.modalRowSmall)}>{children}</div>;
};

type ModalContentProps = {
  title?: React.ReactNode;
  children: React.ReactNode;
  actions?: React.ReactNode;
  messages?: string[];
  size?: 'small' | 'medium' | 'large';
  titleIcon?: React.ReactNode;
};

const classesForSize = (size: ModalContentProps['size']) => {
  const classes = [styles.modalContainer];
  if (size === 'small') classes.push(styles.modalContainerSmall);
  if (size === 'medium') classes.push(styles.modalContainerMedium);
  if (size === 'large') classes.push(styles.modalContainerLarge);
  return classes;
};

export const ModalContent = ({
  title,
  children,
  actions,
  size,
  messages,
  titleIcon,
}: ModalContentProps) => {
  return (
    <div className={css(...classesForSize(size))}>
      {title ? (
        <div className={css(styles.modalHeader)}>
          <h1
            className={css(styles.modalHeaderTitle)}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {titleIcon}
            {title}
          </h1>
        </div>
      ) : null}
      {messages?.map((msg) => (
        <p key={msg} className={css(styles.modalMessage)}>
          {msg}
        </p>
      ))}
      <div className={css(styles.modalBody)}>{children}</div>
      {actions ? (
        <div className={css(styles.modalActions)}>{actions}</div>
      ) : null}
    </div>
  );
};

export const ModalLoadingContent = () => {
  return (
    <ModalContent>
      <Spinner />
    </ModalContent>
  );
};
