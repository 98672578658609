import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Table from '~/src/containers/Table';

const ClioQuestionnaireSubmissionsTable = ({
  hideHeader,
  columnWidths,
  noBorder,
  visibleItems,
  clioQuestionnaireSubmissions,
}) => {
  const renderRowAction = (row) => {
    return (
      <a
        href={`/clients/questionnaires/${row.questionnaire_id}/responses/${row.questionnaire_submission_id}`}
        target="_blank"
        rel="noreferrer"
      >
        View
      </a>
    );
  };

  return (
    <>
      <div>
        <Table
          noBorder={noBorder}
          hideHeader={hideHeader}
          data={clioQuestionnaireSubmissions}
          visibleItems={visibleItems}
          columns={[
            {
              label: 'Questionnaire',
              dataKey: 'title',
              style: {
                width: columnWidths[0],
                textAlign: 'left',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              },
            },
            {
              label: 'Date',
              dataKey: 'created_at',
              render: (val) => {
                return moment(val).format('MM/DD/YYYY');
              },
              style: { width: columnWidths[1], textAlign: 'left' },
            },
            {
              label: 'Actions',
              hideLabel: true,
              render: renderRowAction,
              style: {
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'flex-end',
              },
            },
          ]}
        />
      </div>
    </>
  );
};

ClioQuestionnaireSubmissionsTable.propTypes = {
  columnWidths: PropTypes.arrayOf(PropTypes.string),
  clioQuestionnaireSubmissions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      updatedAt: PropTypes.string,
    }),
  ),
  visibleItems: PropTypes.number,
  rowActions: PropTypes.shape({
    delete: PropTypes.func,
  }),
};

ClioQuestionnaireSubmissionsTable.defaultProps = {
  clioQuestionnaireSubmissions: [],
  columnWidths: ['60%', '10%'],
  visibleItems: 10,
  rowActions: {
    delete: () => {},
  },
};

export default ClioQuestionnaireSubmissionsTable;
