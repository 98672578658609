/* Services */
import templateDefaultValuesApiService, {
  RelatedTemplateSetsCount,
} from '~/src/services/api/templateDefaultValuesApiService';

import { mapTemplateDefaultValues } from '~/src/utils/dataTransformers';
import { PaginatedParams } from '~/src/globalTypes';
import { TemplateDefaultValues } from '~/src/models';

const templateDefaultValuesService = () => {
  const getTemplateDefaultValuesList = async ({
    page,
    search,
    sortBy,
    order,
    limit,
    orgFprint,
  }: PaginatedParams): Promise<TemplateDefaultValues[]> => {
    const templateDefaultValuesList =
      await templateDefaultValuesApiService.getTemplateDefaultValuesList({
        page,
        search,
        sortBy,
        order,
        limit,
        orgFprint,
      });

    return templateDefaultValuesList.map((templateDefaultValues) =>
      mapTemplateDefaultValues(templateDefaultValues),
    );
  };

  const createTemplateDefaultValues = async (
    orgFprint: string,
    templateId: number,
  ): Promise<TemplateDefaultValues> => {
    const newTemplateDefaultValues =
      await templateDefaultValuesApiService.createTemplateDefaultValues(
        orgFprint,
        templateId,
      );

    return mapTemplateDefaultValues(newTemplateDefaultValues);
  };

  const getTemplateDefaultValues = async (
    orgFprint: string,
    templateDefaultValueId: number,
  ): Promise<TemplateDefaultValues> => {
    const templateDefaultValues =
      await templateDefaultValuesApiService.getTemplateDefaultValues(
        orgFprint,
        templateDefaultValueId,
      );
    return mapTemplateDefaultValues(templateDefaultValues);
  };

  const getTemplateDefaultValuesByIds = async (
    orgFprint: string,
    templateDefaultValueIds: number[],
  ): Promise<TemplateDefaultValues[]> => {
    if (!templateDefaultValueIds || templateDefaultValueIds.length === 0) {
      return [];
    }

    const templateDefaultValues =
      await templateDefaultValuesApiService.getTemplateDefaultValuesByIds(
        orgFprint,
        templateDefaultValueIds,
      );
    const results = templateDefaultValues.map((tdv) =>
      mapTemplateDefaultValues(tdv),
    );

    return results;
  };

  const updateTemplateDefaultValues = async (
    orgFprint: string,
    id: number,
    title: string,
    values: any,
  ): Promise<TemplateDefaultValues> => {
    const payload: { title?: string; values?: any } = {};
    if (title) {
      payload.title = title;
    }
    if (values) {
      payload.values = values;
    }
    const newTemplateDefaultValues =
      await templateDefaultValuesApiService.updateTemplateDefaultValues(
        orgFprint,
        id,
        payload,
      );

    return mapTemplateDefaultValues(newTemplateDefaultValues);
  };

  const checkTemplateDefaultValuesExistInTemplateSets = async (
    orgFprint: string,
    templateDefaultValuesId: number,
  ): Promise<RelatedTemplateSetsCount> => {
    const relatedTemplateSetsCount =
      await templateDefaultValuesApiService.checkTemplateDefaultValuesExistInTemplateSets(
        orgFprint,
        templateDefaultValuesId,
      );
    return relatedTemplateSetsCount;
  };

  const deleteTemplateDefaultValues = async (
    orgFprint: string,
    templateDefaultValuesId: number,
  ): Promise<void> => {
    await templateDefaultValuesApiService.deleteTemplateDefaultValues(
      orgFprint,
      templateDefaultValuesId,
    );
  };

  const duplicateTemplateDefaultValues = async (
    orgFprint: string,
    id: number,
    title: string,
  ): Promise<TemplateDefaultValues> => {
    const newTemplateDefaultValues =
      await templateDefaultValuesApiService.duplicateTemplateDefaultValues(
        orgFprint,
        id,
        {
          title,
        },
      );

    return mapTemplateDefaultValues(newTemplateDefaultValues);
  };

  return {
    getTemplateDefaultValuesList,
    getTemplateDefaultValues,
    getTemplateDefaultValuesByIds,
    createTemplateDefaultValues,
    updateTemplateDefaultValues,
    deleteTemplateDefaultValues,
    duplicateTemplateDefaultValues,
    checkTemplateDefaultValuesExistInTemplateSets,
  };
};

export default templateDefaultValuesService();
