import { Link, Typography } from '@mui/material';
import React from 'react';
import { CLIO_ERROR_STATE } from '~/src/utils/constants';

export const ErrorMessage = ({ error }: { error: string }) => {
  if (error === CLIO_ERROR_STATE.COURT_FORMS_NOT_ENABLED) {
    return (
      <>
        <Typography gutterBottom>
          Your account doesn&apos;t have access to court form automation and
          therefore can&apos;t draft or edit court forms in Clio Draft.
        </Typography>
        <Typography>
          To add court form automation to your account, contact Clio Draft
          support
        </Typography>
      </>
    );
  }

  if (error === CLIO_ERROR_STATE.MATTER_SYNC_NOT_ENABLED) {
    return (
      <Typography>
        We were unable to sync your latest matter and client details from Clio
        Manage. Please check your Clio matter permissions and try again.
      </Typography>
    );
  }

  if (error === CLIO_ERROR_STATE.GENERIC_SYNC) {
    return (
      <Typography>
        We were unable to sync your Clio Manage data to Clio Draft. Please check
        that all information is accurate and up to date before proceeding.
      </Typography>
    );
  }

  if (error === CLIO_ERROR_STATE.SYNC_NOT_ENABLED) {
    return (
      <Typography>
        Complete the setup steps to connect your Clio Manage and Clio Draft //
        accounts found{' '}
        <Link
          href={
            'https://help.clio.com/hc/en-us/articles/24313560334747-Get-Started-With-Clio-Draft'
          }
        >
          here
        </Link>
        .
      </Typography>
    );
  }

  return <Typography>An unknown error occurred.</Typography>;
};
