import React from 'react';
import { css } from 'aphrodite';

import styles from './styles';

const Triangle = ({ orientation, primary }) => (
  <div
    className={css(
      styles.arrow,
      // Deafults to "up" orientation
      orientation === 'up' && styles.arrowUp,
      orientation === 'down' && styles.arrowDown,
      orientation === 'left' && styles.arrowLeft,
      orientation === 'right' && styles.arrowRight,
      primary && styles.primary,
    )}
  >
    {'  '}
  </div>
);

Triangle.defaultProps = {
  orientation: 'down',
};

export default Triangle;
