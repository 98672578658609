import React from 'react';
import { Box } from '@mui/material';

export const StyledCardWrapper: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => (
  <Box
    sx={{
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'flex-start',
      alignContent: 'flex-start',
      paddingTop: '24px',
    }}
  >
    {children}
  </Box>
);
