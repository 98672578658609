export const SEAT_PRODUCT_ID = 'SEATS';
export const WORD_PRODUCT_ID = 'WORD_DOCUMENT_AUTOMATION';

export const NYSBA_SURROGATE_ID = 'CONTENT_PACKAGE_NYSBA_SURROGATE-2';
export const CONTENT_PACKAGE = [
  {
    name: 'NYSBA SURROGATE',
    id: NYSBA_SURROGATE_ID,
    price: 1200,
  },
];

export const COURT_FORMS = [
  {
    name: 'USCIS FORMS',
    id: 'FEDERAL_FORMS',
  },
  {
    name: 'BRITISH COLUMBIA FORMS',
    id: 'CA_BC_JC_FORMS',
  },
  {
    name: 'ALABAMA FORMS',
    id: 'AL_JC_FORMS',
  },
  {
    name: 'ALASKA FORMS',
    id: 'AK_JC_FORMS',
  },
  {
    name: 'ARIZONA FORMS',
    id: 'AZ_JC_FORMS',
  },
  {
    name: 'ARKANSAS FORMS',
    id: 'AR_JC_FORMS',
  },
  {
    name: 'CALIFORNIA FORMS',
    id: 'CA_JC_FORMS',
  },
  {
    name: 'COLORADO FORMS',
    id: 'CO_JC_FORMS',
  },
  {
    name: 'CONNECTICUT FORMS',
    id: 'CT_JC_FORMS',
  },
  {
    name: 'DELAWARE FORMS',
    id: 'DE_JC_FORMS',
  },
  {
    name: 'DISTRICT OF COLUMBIA FORMS',
    id: 'DC_JC_FORMS',
  },
  {
    name: 'FLORIDA FORMS',
    id: 'FL_JC_FORMS',
  },
  {
    name: 'GEORGIA FORMS',
    id: 'GA_JC_FORMS',
  },
  {
    name: 'HAWAII FORMS',
    id: 'HI_JC_FORMS',
  },
  {
    name: 'IDAHO FORMS',
    id: 'ID_JC_FORMS',
  },
  {
    name: 'ILLINOIS FORMS',
    id: 'IL_JC_FORMS',
  },
  {
    name: 'INDIANA FORMS',
    id: 'IN_JC_FORMS',
  },
  {
    name: 'IOWA FORMS',
    id: 'IA_JC_FORMS',
  },
  {
    name: 'KANSAS FORMS',
    id: 'KS_JC_FORMS',
  },
  {
    name: 'KENTUCKY FORMS',
    id: 'KY_JC_FORMS',
  },
  {
    name: 'LOUISIANA FORMS',
    id: 'LA_JC_FORMS',
  },
  {
    name: 'MAINE FORMS',
    id: 'ME_JC_FORMS',
  },
  {
    name: 'MARYLAND FORMS',
    id: 'MD_JC_FORMS',
  },
  {
    name: 'MASSACHUSETTS FORMS',
    id: 'MA_JC_FORMS',
  },
  {
    name: 'MICHIGAN FORMS',
    id: 'MI_JC_FORMS',
  },
  {
    name: 'MINNESOTA FORMS',
    id: 'MN_JC_FORMS',
  },
  {
    name: 'MISSISSIPPI FORMS',
    id: 'MS_JC_FORMS',
  },
  {
    name: 'MISSOURI FORMS',
    id: 'MO_JC_FORMS',
  },
  {
    name: 'MONTANA FORMS',
    id: 'MT_JC_FORMS',
  },
  {
    name: 'NEBRASKA FORMS',
    id: 'NE_JC_FORMS',
  },
  {
    name: 'NEVADA FORMS',
    id: 'NV_JC_FORMS',
  },
  {
    name: 'NEW HAMPSHIRE FORMS',
    id: 'NH_JC_FORMS',
  },
  {
    name: 'NEW JERSEY FORMS',
    id: 'NJ_JC_FORMS',
  },
  {
    name: 'NEW MEXICO FORMS',
    id: 'NM_JC_FORMS',
  },
  {
    name: 'NEW YORK FORMS',
    id: 'NY_JC_FORMS',
  },
  {
    name: 'NORTH CAROLINA FORMS',
    id: 'NC_JC_FORMS',
  },
  {
    name: 'NORTH DAKOTA FORMS',
    id: 'ND_JC_FORMS',
  },
  {
    name: 'OHIO FORMS',
    id: 'OH_JC_FORMS',
  },
  {
    name: 'OKLAHOMA FORMS',
    id: 'OK_JC_FORMS',
  },
  {
    name: 'OREGON FORMS',
    id: 'OR_JC_FORMS',
  },
  {
    name: 'PENNSYLVANIA FORMS',
    id: 'PA_JC_FORMS',
  },
  {
    name: 'RHODE ISLAND FORMS',
    id: 'RI_JC_FORMS',
  },
  {
    name: 'SOUTH CAROLINA FORMS',
    id: 'SC_JC_FORMS',
  },
  {
    name: 'SOUTH DAKOTA FORMS',
    id: 'SD_JC_FORMS',
  },
  {
    name: 'TENNESSEE FORMS',
    id: 'TN_JC_FORMS',
  },
  {
    name: 'TEXAS FORMS',
    id: 'TX_JC_FORMS',
  },
  {
    name: 'UTAH FORMS',
    id: 'UT_JC_FORMS',
  },
  {
    name: 'VERMONT FORMS',
    id: 'VT_JC_FORMS',
  },
  {
    name: 'VIRGINIA FORMS',
    id: 'VA_JC_FORMS',
  },
  {
    name: 'WASHINGTON FORMS',
    id: 'WA_JC_FORMS',
  },
  {
    name: 'WEST VIRGINIA FORMS',
    id: 'WV_JC_FORMS',
  },
  {
    name: 'WISCONSIN FORMS',
    id: 'WI_JC_FORMS',
  },
  {
    name: 'WYOMING FORMS',
    id: 'WY_JC_FORMS',
  },
];

export const SEATS_MONTHLY_PRICE = 30;
export const SEATS_YEARLY_PRICE = 300;

export const COURT_FORMS_MONTHLY_PRICE = 40;
export const COURT_FORMS_YEARLY_PRICE = 400;

export const WORD_DOCUMENT_AUTOMATION_MONTHLY_PRICE = 80;
export const WORD_DOCUMENT_AUTOMATION_YEARLY_PRICE = 800;

export const COUPON = {
  CLIO_SSO_FREE_TRIAL: 'mxTpcrjN', // 10% coupon code for Clio SSO
};
