import React from 'react';

import { useDraftDocumentsUsingQuestionnaire } from '~/src/entities/questionnaires';
import { useCurrentOrgFprint } from '~/src/entities/user';
import { Typography } from '@mui/material';
import { ListViewItem, ListViewItemWrapper } from '../shared/ListView';
import { QuestionnaireSubmission } from '@clio/questionnaire-builder';
import env from '~/src/utils/env';

export const RelatedMatterRow: React.FC<{
  submission: QuestionnaireSubmission;
}> = ({ submission }) => {
  const orgFprint = useCurrentOrgFprint();

  const { matterData, isMatterError } = useDraftDocumentsUsingQuestionnaire(
    orgFprint,
    submission.target_identifier,
  );

  if (isMatterError) {
    return (
      <ListViewItemWrapper>
        <Typography variant="body1" sx={{ flex: '1 1 0', fontWeight: 600 }}>
          Error retrieving metadata, please try again later.
        </Typography>
      </ListViewItemWrapper>
    );
  }

  if (!matterData) {
    return (
      <ListViewItemWrapper>
        <Typography variant="body1" sx={{ flex: '1 1 0', fontWeight: 600 }}>
          Loading...
        </Typography>
      </ListViewItemWrapper>
    );
  }

  return (
    <ListViewItem
      title={matterData.title || ''}
      handleOnClick={() =>
        window.open(
          env.basename +
            `/questionnaires/${submission.questionnaire_id}/responses/${submission.id}`,
          '_blank',
        )
      }
      buttonName={'Go to responses'}
    />
  );
};
