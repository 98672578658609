import { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { SortByOrder } from '~/src/hooks/sortByOrder';
import { SortByOption } from '~/src/hooks/sortByOption';

/**
 * Default: pagination limit => 10.
 */
export const DEFAULT_LIMIT: number = 10;
/**
 * Default: pagination page => 1.
 */
export const DEFAULT_PAGE: number = 1;
/**
 * Default: search query value => ''.
 */
export const DEFAULT_SEARCH: string = '';
/**
 * Default: pagination sortBy option => last modified.
 */
export const DEFAULT_SORT_BY: string = SortByOption.lastModified;
/**
 * Default: pagination order by => (last_modified in descending order).
 */
export const DEFAULT_ORDER: SortByOrder = SortByOrder.desc;
/**
 * Default: pagination loading indicator => 10.
 */
export const DEFAULT_LOADING: boolean = true;
/**
 * Default: pagination limit => 10.
 */
export const DEFAULT_DATA: Array<any> = [];
/**
 * Default: pagination limit => 10.
 */
export const DEFAULT_DONE: boolean = false;
/**
 * Default: pagination limit => 10.
 */
export const DEFAULT_EMPTY: boolean = false;

/**
 * Resource Pagination request argument interface.
 */
export interface PaginatedFetchArgs {
  /**
   * Limit the number of results per page to this value.
   */
  limit: number;
  /**
   * Sort by ordering method - 'asc' or 'desc'.
   */
  order?: SortByOrder;
  /**
   * Sort by field.
   */
  sortBy?: string;
  /**
   * Search query value.
   */
  search?: string;
  /**
   * Page number requested.
   */
  page?: number;
}

/**
 * Generic interface that can be used with the backend TypeSense search query.
 */
export interface SearchRequestPayload {
  /**
   * Fields that should be used in the query as facets.
   */
  facets?: object;
  /**
   * Field that should be used in the query to group by.
   */
  group_by?: string;

  /**
   * Field that should be used in the query to group by.
   */
  filter_by?: string;

  /**
   * Field that should be used to define the fields to search against.
   */
  query_by?: string;
}

/**
 * Paginated resource resolver function.
 */
export type PaginatedResourceResolver = (
  args: PaginatedFetchArgs,
  requestPayload?: SearchRequestPayload,
) => Promise<void>;

/**
 * Parameter object used by the `useResourcePagination` function.
 */
export interface UseResourcePaginationParams extends PaginatedFetchArgs {
  /**
   * Required resource fetch function, used to resolve the resources to be paginated.
   * @param {PaginatedFetchArgs} args - Base arguments to be passed to the `useResourcePagination` function.
   * @param {SearchRequestPayload} requestPayload - Optional search request payload.
   */
  fetch: PaginatedResourceResolver;
  /**
   * Is loading flag.
   */
  loading?: boolean;
  /**
   * Is Empty flag.
   */
  empty?: boolean;
  /**
   * Is done loading flag.
   */
  done?: boolean;
  /**
   * Optional additional data array.
   */
  data?: Array<any>;
}

/**
 * Use resource pagination hook.
 * @param {UseResourcePaginationParams} pagerParams - Resource pagination params.
 * @param {SearchRequestPayload} [requestPayload] - Optional search request payload.
 */
const useResourcePagination = (
  pagerParams: UseResourcePaginationParams,
  requestPayload?: SearchRequestPayload,
) => {
  // Extract The fetch function and the resource fetch limit.
  const fetchFunction: PaginatedResourceResolver = pagerParams.fetch;
  const limit = pagerParams.limit || DEFAULT_LIMIT;

  // Initialize the required state management values for the resource pagination hook.
  const [page, setPage] = useState<number>(pagerParams.page || DEFAULT_PAGE);
  const [search, setSearch] = useState<string>(
    pagerParams.search || DEFAULT_SEARCH,
  );
  const [sortBy, setSortBy] = useState<string>(
    pagerParams.sortBy || DEFAULT_SORT_BY,
  );
  const [order, setOrder] = useState<SortByOrder>(
    pagerParams.order || DEFAULT_ORDER,
  );
  const [data, setData] = useState<Array<any>>(
    pagerParams.data || DEFAULT_DATA,
  );
  const [done, setDone] = useState<boolean>(pagerParams.done || DEFAULT_DONE);
  const [loading, setLoading] = useState<boolean>(
    pagerParams.loading || DEFAULT_LOADING,
  );
  const [empty, setEmpty] = useState(pagerParams.empty || DEFAULT_EMPTY);

  /**
   * Fetch method wrapper that also handles the loading state values.
   * @param {PaginatedFetchArgs} params - Pager args used in the pagination request.
   */
  const fetchData = (params: PaginatedFetchArgs) => {
    // Set the loading flag for this component.
    setLoading(true);
    setDone(false);

    // Perform the actual fetch request.
    fetchFunction(params, requestPayload).then((res: any) => {
      // Set the empty state.

      setEmpty(res.length === 0 && search.length === 0 && page === 1);

      // use the returned value.
      if (res.length === 0) {
        setDone(true);
      } else {
        setData([...data, ...res]);
        setDone(res.length < params.limit);
      }

      // Update the loading state, as loading has completed.
      setLoading(false);
    });
  };

  useEffect(() => {
    if (page > 1 && !empty) {
      // Not the first page, and there are results.
      fetchData({
        page,
        search,
        sortBy,
        order,
        limit,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (page === 1) {
      // This is the first page.
      fetchData({
        page,
        search,
        sortBy,
        order,
        limit,
      });
    } else {
      setPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, sortBy, order, limit]);

  /**
   * This function returns the Next set of pages results.
   */
  const next = () => !done && setPage(page + 1);

  /**
   * Updates the search field.
   * @param {string} value - Text value to search against.
   */
  const handleSetSearch = (value: string) => {
    setPage(1);
    setSearch(value);
  };

  /**
   * Updates the sortBy field.
   * @param {string} sortByValue - Text value representing the field name of the field to sort by.
   */
  const handleSetSortBy = (sortByValue: string) => {
    setPage(1);
    setSortBy(sortByValue);
  };

  /**
   * Updates the sort ordering method.
   * @param {string} sortOrderValue - Ordering method, 'asc' or 'desc'.
   */
  const handleSetOrder = (sortOrderValue: SortByOrder) => {
    setPage(1);
    setOrder(sortOrderValue);
  };

  // Return the data object needed to use this hook.
  return {
    page,
    loading,
    done,
    empty,
    search,
    next: debounce(next, 500),
    setSearch: debounce(handleSetSearch, 750),
    setSortBy: debounce(handleSetSortBy, 250),
    setOrder: debounce(handleSetOrder, 250),
  };
};

export default useResourcePagination;
