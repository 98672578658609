import React from 'react';
import { Form, renderFormField } from '~/src/components/Forms';
import { useDraftDocumentsUsingQuestionnaire } from '../../../hooks/useDraftDocumentsUsingQuestionnaire';
import { useCurrentOrgFprint } from '~/src/entities/user';
import { Box } from '@mui/material';

export const SubmissionTitle = ({
  matterId,
  title,
  questionnaireTitle,
  onFormChange,
  disableField = false,
}: {
  matterId: string;
  title: React.ReactElement;
  questionnaireTitle: string;
  onFormChange: (form: any) => void;
  disableField?: boolean;
}) => {
  const orgFprint = useCurrentOrgFprint();
  const { matterData } = useDraftDocumentsUsingQuestionnaire(
    orgFprint,
    matterId,
  );

  // if matterData is not available yet, we use an empty string until it is available
  // this is to prevent the title from being " - Questionnaire Name" which is not ideal
  const defaultTitle = matterData
    ? (matterData?.title ?? '') + ' - ' + questionnaireTitle
    : '';

  const fields = [
    {
      id: 'title',
      type: 'text',
      validation: ({ value }: { value: string }) =>
        !!value && value.trim().length > 0,
      defaultValue: defaultTitle ?? '',
      disabled: disableField,
    },
  ];

  const fieldsProp = fields.map((field) => renderFormField(field));

  return (
    <Box paddingTop={'16px'} paddingBottom={'8px'}>
      {title}
      <Box paddingTop={'4px'}>
        <Form
          fields={fieldsProp}
          onChange={onFormChange}
          className={undefined}
          focusFirstInput={undefined}
          focusTimeout={undefined}
        />
      </Box>
    </Box>
  );
};
