import { List, ListItem, ListItemText } from '@mui/material';
import * as React from 'react';
import {
  CardListItemIconAction,
  CardListItemIconActions,
} from './CardListItemIconActions';

type CardListItemSubListItem = {
  title: React.ReactNode;
  actions?: CardListItemIconAction[];
};

type CardListItemSubListProps = {
  items?: CardListItemSubListItem[];
};

export const CardListItemSubList = ({
  items = [],
}: CardListItemSubListProps) => (
  <List dense>
    {items.map(({ title, actions = [] }, i) => (
      <ListItem key={i} disableGutters divider>
        <ListItemText>{title}</ListItemText>
        <CardListItemIconActions actions={actions} />
      </ListItem>
    ))}
  </List>
);
