import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import useMst from '~/src/hooks/useMst';
import { TOTAL_DOCUMENT_LIMIT } from '~/src/utils/constants';

import {
  TextInput,
  CreditCardNumberInput,
  CreditCardExpInput,
  CreditCardCvcInput,
  TextArea,
  SelectInput,
  MultipleChoiceInput,
  MultipleChoiceOptions,
  PlanChoiceInput,
  MatterSelect,
  StaticText,
  StaticLink,
  CopyLink,
  Incrementer,
  DatePickerInput,
  FileUpload,
} from '~/src/components/Inputs';
import styles from '~/src/components/Inputs/styles';
import useField from './hooks/useField';
import { TemplateListInput } from '../Inputs/TemplateList/TemplateListInput';

const FormField = ({
  getInputRef,
  label,
  labelCssStyle,
  validation,
  type,
  defaultValue,
  fields,
  autoComplete,
  id,
  error,
  onChange,
  editable,
  options,
  hint,
  count,
  onClick,
  href,
  onRemove,
  required,
  uploadLimit,
  uploadTypes,
  sortedSelectedTemplates,
  ...customProps
}) => {
  const [passedRef, setPassedRef] = useState(false);
  const { value, focus, touched, valid, setValue, setFocus } = useField({
    value: defaultValue,
    validation,
  });

  const { totalNumber } = useMst((store) => ({
    totalNumber: store.sidebarItems.items.length, // fortunately, store.sidebarItems.items.length is mutable. So, it will trigger re-render.
  }));

  const [duplicateErrorState, setDuplicateErrorState] = useState(false);

  // we'll use duplicateNameState to show UI errors if user tries to rename a document to the name of another existing document
  const [duplicateNameState, setDuplicateNameState] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    const field = {
      value,
      focus,
      touched,
      valid,
    };

    if (defaultValue) {
      onChange(field);
      setValue(defaultValue, { fields });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // BUG: This can trigger an infinite loop
  useEffect(() => {
    if (defaultValue !== value) {
      setValue(defaultValue, { fields });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  useEffect(() => {
    const field = {
      value,
      focus,
      touched,
      valid,
    };

    onChange(field);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, focus, touched, valid]);

  const handleChange = (value) => {
    setValue(value, { ...customProps, ...{ fields } });
  };

  const handleDuplicateNameState = (value) => {
    const dictionary_of_document_titles = customProps.documentNamesObject;

    if (typeof dictionary_of_document_titles !== 'undefined') {
      if (value in dictionary_of_document_titles) {
        setDuplicateNameState(true);
      } else {
        setDuplicateNameState(false);
      }
    } else {
      setDuplicateNameState(false);
    }
  };

  const handleNumericChange = (value) => {
    // This gets the first continuous set of numerics in
    // an entered form field.
    const regex_num = /[0-9\b]+/;
    // value returns array is match exists
    // null if not
    value = value.match(regex_num);

    if (value === null) {
      value = '';
    } else {
      value = String(value);
    }
    setValue(value, { fields });
    return value;
  };

  const handleDuplicateErrorState = (value) => {
    // Currently Only 20 (twenty) documents are allowed in a document set
    // this will display an error to the user to make that limit clearly known
    const input_value_cleaned =
      isNaN(parseInt(value)) || typeof value === 'undefined'
        ? 0
        : parseInt(value);

    if (totalNumber + input_value_cleaned > TOTAL_DOCUMENT_LIMIT) {
      setDuplicateErrorState(true);
    } else {
      setDuplicateErrorState(false);
    }
  };

  const handleRemove = (...args) => {
    onChange(false);
    if (onRemove) {
      onRemove(...args);
    }
  };

  const handleBlur = () => {
    setFocus(false);
  };

  const handleFocus = () => {
    setFocus(true);
  };

  useEffect(() => {
    if (getInputRef && !passedRef && inputRef.current) {
      if (inputRef.current._ref) {
        inputRef.current = inputRef.current._ref;
      }

      getInputRef(inputRef);
      setPassedRef(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef]);

  switch (type) {
    case 'text':
    case 'password':
    case 'email':
      return (
        <TextInput
          id={id}
          autoComplete={autoComplete}
          ref={inputRef}
          label={label}
          labelCssStyle={labelCssStyle}
          value={value}
          type={type}
          error={error}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          editable={editable}
          hint={hint}
          count={count}
          onRemove={handleRemove}
          required={required}
          {...customProps}
        />
      );
    case 'rename':
      return (
        <TextInput
          id={id}
          autoComplete={autoComplete}
          ref={inputRef}
          label={label}
          labelCssStyle={labelCssStyle}
          value={value}
          type={type}
          error={error}
          onChange={(value) => {
            handleDuplicateNameState(value);
            handleChange(value);
          }}
          onBlur={handleBlur}
          onFocus={handleFocus}
          editable={editable}
          hint={hint}
          count={count}
          onRemove={handleRemove}
          required={required}
          inputClassStyle={
            duplicateNameState
              ? styles.renameErrorInput
              : styles.renameStandardInput
          }
          {...customProps}
        />
      );
    case 'numeric':
      return (
        <TextInput
          id={id}
          autoComplete={autoComplete}
          ref={inputRef}
          label={label}
          labelCssStyle={labelCssStyle}
          value={value}
          type={type}
          error={error}
          onChange={handleNumericChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          editable={editable}
          hint={hint}
          count={count}
          onRemove={handleRemove}
          required={required}
          {...customProps}
        />
      );
    case 'duplicate_document':
      return (
        <TextInput
          id={id}
          autoComplete={autoComplete}
          ref={inputRef}
          label={label}
          labelCssStyle={labelCssStyle}
          value={value}
          type={type}
          error={error}
          onChange={(value) => {
            handleDuplicateErrorState(handleNumericChange(value));
          }}
          onBlur={handleBlur}
          onFocus={handleFocus}
          editable={editable}
          hint={hint}
          count={count}
          onRemove={handleRemove}
          required={required}
          inputClassStyle={
            duplicateErrorState ? styles.duplicateErrorInput : styles.textInput
          }
          {...customProps}
        />
      );
    case 'textarea':
      return (
        <TextArea
          autoComplete={autoComplete}
          ref={inputRef}
          label={label}
          labelCssStyle={labelCssStyle}
          value={value}
          type={type}
          error={error}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          editable={editable}
          hint={hint}
          count={count}
          required={required}
          {...customProps}
        />
      );
    case 'select':
      return (
        <SelectInput
          label={label}
          labelCssStyle={labelCssStyle}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          editable={editable}
          options={options}
          required={required}
          {...customProps}
        />
      );
    case 'multiSelect':
      return (
        <SelectInput
          multiple={true}
          label={label}
          labelCssStyle={labelCssStyle}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          editable={editable}
          options={options}
          required={required}
          {...customProps}
        />
      );
    case 'multipleChoice':
      return (
        <MultipleChoiceInput
          label={label}
          labelCssStyle={labelCssStyle}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          options={options}
          hint={hint}
          count={count}
          required={required}
          {...customProps}
        />
      );
    case 'multipleChoiceOptions':
      return (
        <MultipleChoiceOptions
          label={label}
          labelCssStyle={labelCssStyle}
          value={value}
          onChange={handleChange}
          hint={hint}
          {...customProps}
        />
      );
    case 'fileUpload':
      return (
        <FileUpload
          label={label}
          labelCssStyle={labelCssStyle}
          value={value}
          onChange={handleChange}
          hint={hint}
          required={required}
          fileCountLimit={Number(uploadLimit)}
          acceptedDocumentTypes={uploadTypes}
        />
      );
    case 'date':
      return (
        <DatePickerInput
          ref={inputRef}
          label={label}
          labelCssStyle={labelCssStyle}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          options={options}
          hint={hint}
          count={count}
          required={required}
          {...customProps}
        />
      );
    case 'matterSelect':
      return (
        <MatterSelect
          // ref={inputRef}
          label={label}
          hint={hint}
          labelCssStyle={labelCssStyle}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          editable={editable}
          options={options}
          required={required}
          {...customProps}
        />
      );
    case 'staticText':
      return (
        <StaticText
          onClick={onClick}
          label={label}
          labelCssStyle={labelCssStyle}
          value={value}
          hint={hint}
          count={count}
          required={required}
          {...customProps}
        />
      );
    case 'copyLink':
      return (
        <CopyLink
          onClick={onClick}
          label={label}
          labelCssStyle={labelCssStyle}
          value={value}
          hint={hint}
          count={count}
          href={href}
          required={required}
          {...customProps}
        />
      );
    case 'staticLink':
      return (
        <StaticLink
          onClick={onClick}
          label={label}
          labelCssStyle={labelCssStyle}
          value={value}
          hint={hint}
          count={count}
          href={href}
          required={required}
          {...customProps}
        />
      );
    case 'incrementer':
      return (
        <Incrementer
          label={label}
          labelCssStyle={labelCssStyle}
          hint={hint}
          count={count}
          error={error}
          value={value}
          onBlur={handleFocus}
          onFocus={handleBlur}
          onChange={handleChange}
          maxValue={customProps.maxValue}
          minValue={customProps.minValue}
          required={required}
          {...customProps}
        />
      );
    case 'ccNumber':
      return (
        <CreditCardNumberInput
          id={id}
          ref={inputRef}
          label={label}
          labelCssStyle={labelCssStyle}
          value={value}
          type={type}
          error={error}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          editable={editable}
          hint={hint}
          count={count}
          required={required}
          {...customProps}
        />
      );
    case 'ccExp':
      return (
        <CreditCardExpInput
          id={id}
          ref={inputRef}
          label={label}
          labelCssStyle={labelCssStyle}
          value={value}
          type={type}
          error={error}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          editable={editable}
          hint={hint}
          count={count}
          required={required}
          {...customProps}
        />
      );
    case 'ccCvc':
      return (
        <CreditCardCvcInput
          id={id}
          ref={inputRef}
          label={label}
          labelCssStyle={labelCssStyle}
          value={value}
          type={type}
          error={error}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          editable={editable}
          hint={hint}
          count={count}
          required={required}
          {...customProps}
        />
      );
    case 'planChoice':
      return (
        <PlanChoiceInput
          label={label}
          labelCssStyle={labelCssStyle}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          options={options}
          hint={hint}
          count={count}
          required={required}
          {...customProps}
        />
      );
    case 'templateList':
      return (
        <TemplateListInput
          label={label}
          labelCssStyle={labelCssStyle}
          value={sortedSelectedTemplates}
          onChange={handleChange}
          hint={hint}
          options={options}
          {...customProps}
        />
      );
    default:
      return null;
  }
};

FormField.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  formFields: PropTypes.object,
  fields: PropTypes.array,
  editable: PropTypes.bool,
  onChange: PropTypes.func,
  validation: PropTypes.func,
  getInputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

FormField.defaultProps = {
  label: '',
  type: '',
  options: [],
  fields: {},
  formFields: {},
  editable: true,
  getInputRef: false,
  validation: () => {},
  onChange: () => {},
};

export default React.forwardRef((formFieldProps, ref) => {
  return <FormField {...formFieldProps} forwardedRef={ref} />;
});
