import { useMemo } from 'react';
import { getAllSigners } from '~/src/components/SignerSelection/utils';
import { Pdf, Signers } from '~/src/models';

/**
 * Returns the count of signature and date assignments
 * by assignee for a signature package
 *
 * @param pdfs
 * @param signers
 * @returns
 */
const useSignatureCount = (pdfs: Pdf[], signers: Signers) => {
  const signatureCount = useMemo(
    () =>
      pdfs.reduce<{ [key in string]: number }>((acc, each) => {
        // eslint-disable-next-line no-param-reassign
        each.signatures.forEach((sig) => {
          // Placeholders aren't assigned and therefore don't count towards any recipient's signature count
          if (sig.type === 'placeholder') return;
          if (!acc[sig.recipient.email]) {
            acc[sig.recipient.email] = 1;
          } else {
            acc[sig.recipient.email] += 1;
          }
        });
        const allSigners = getAllSigners(signers);
        allSigners.forEach((signer) => {
          if (!acc[signer.email]) {
            acc[signer.email] = 0;
          }
        });
        return acc;
      }, {}),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pdfs],
  );
  const dateCount = useMemo(
    () =>
      pdfs.reduce<{ [key in string]: number }>((acc, each) => {
        // eslint-disable-next-line no-param-reassign
        each.dates.forEach((date) => {
          // Placeholders aren't assigned and therefore don't count towards any recipient's date count
          if (date.type === 'placeholder') return;
          if (!acc[date.recipient.email]) {
            acc[date.recipient.email] = 1;
          } else {
            acc[date.recipient.email] += 1;
          }
        });
        const allSigners = getAllSigners(signers);
        allSigners.forEach((signer) => {
          if (!acc[signer.email]) {
            acc[signer.email] = 0;
          }
        });
        return acc;
      }, {}),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pdfs],
  );
  return { signatureCount, dateCount };
};

export default useSignatureCount;
