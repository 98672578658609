import React, { useRef, useState } from 'react';
import cns from 'classnames';
import theme from '~/src/theme';
import MiniSpinner from '~/src/components/Spinner/MiniSpinner';
import { ReactComponent as UploadIcon } from '~/src/static/upload-icon.svg';
import DragAndDropFiles from './DragAndDropFiles';
import ErrorMessage from './ErrorMessageBox';
import {
  DOCUMENT_UPLOAD_MAX_MEMORY_BYTES,
  DOCUMENT_UPLOAD_ALLOWED_FILES_TYPES,
} from '../SignaturePackage/constants';

interface UploadDocumentBoxProps {
  errorMessages: string[];
  setErrorMessages: React.Dispatch<React.SetStateAction<string[]>>;
  onFileUploaded: (files: File[]) => void;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoadingBar = () => {
  return (
    <div className="justify-center border-t p-2">
      <div className="space-y-1 text-center" role="status">
        <MiniSpinner />
        <span className="text-sm pt-1 pl-2 text-gray-500">Uploading...</span>
      </div>
    </div>
  );
};

const UploadDocumentBox = ({
  errorMessages,
  setErrorMessages,
  onFileUploaded,
  isLoading,
  setIsLoading,
}: UploadDocumentBoxProps) => {
  const drag = useRef<HTMLDivElement>(null);
  const [dragging, setDragging] = useState(false);

  const validateFilesAndRemoveFails = (files: File[]) => {
    const isFileSizeValid = (file: File) =>
      file.size > DOCUMENT_UPLOAD_MAX_MEMORY_BYTES;

    const isFileExtensionValid = (file: File) => {
      const extension = `${`${file.name}`.split('.').pop()}`;
      return (
        !DOCUMENT_UPLOAD_ALLOWED_FILES_TYPES.includes(extension) ||
        DOCUMENT_UPLOAD_ALLOWED_FILES_TYPES.includes(file.name)
      );
    };

    setErrorMessages([]);
    const filesToRemove: File[] = [];
    const fileArr = Array.from(files);
    fileArr.forEach((file) => {
      if (isFileExtensionValid(file)) {
        const message = `Uploaded file '${
          file.name
        }' is not allowed. Allowed file types: .${DOCUMENT_UPLOAD_ALLOWED_FILES_TYPES.join(
          ', .',
        )}`;
        setErrorMessages((prev) => [...prev, message]);
        filesToRemove.push(file);
      } else if (isFileSizeValid(file)) {
        const message = `Uploaded file '${file.name}' is too large. Max size: ${
          DOCUMENT_UPLOAD_MAX_MEMORY_BYTES / (1000 * 1000)
        } mb`;
        setErrorMessages((prev) => [...prev, message]);
        filesToRemove.push(file);
      }
    });

    const filteredFiles = fileArr.filter(
      (file) => !filesToRemove.includes(file),
    );

    return filteredFiles;
  };

  const addFiles = (files: File[]) => {
    setIsLoading(true);
    const filteredFiles = validateFilesAndRemoveFails(files);

    if (filteredFiles.length === 0) {
      setIsLoading(false);
      return;
    }

    onFileUploaded(files);
  };

  const onFileInput = async (event: React.ChangeEvent) => {
    addFiles((event.target as HTMLFormElement).files);
  };

  const onDragAndDrop = async (files: File[]) => {
    addFiles(files);
  };

  const UploadDocuments = (
    <label htmlFor="file-upload">
      <div
        className={cns(
          dragging ? 'border-blue-500 bg-gray-100' : '',
          'flex justify-center cursor-pointer mt-1 px-6 pt-5 pb-6 border-2 border-gray-300 border-dotted rounded-md hover:border-blue-500 hover:bg-gray-100',
        )}
        ref={drag}
      >
        <div className="space-y-1 text-center">
          <UploadIcon />
          <div className="justify-center text-sm text-gray-600 pt-1">
            <div
              className="font-semibold"
              style={{ color: theme.colors.blue600 }}
            >
              Upload documents
            </div>
            <input
              id="file-upload"
              name="file-upload"
              data-testid="file-upload"
              className="sr-only"
              onChange={(e) => onFileInput(e)}
              onClick={(e) => ((e.target as HTMLFormElement).value = null)}
              type="file"
              multiple
              accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            />
            <div className="text-sm pt-1 text-gray-500">
              or drag here to upload
            </div>
          </div>
        </div>
      </div>
    </label>
  );

  return (
    <>
      {isLoading ? <LoadingBar /> : <></>}
      {errorMessages.map((message, index) => (
        <ErrorMessage key={index} title={'Error'} message={message} />
      ))}
      <div className="p-3 border-t">
        <DragAndDropFiles
          onUpload={onDragAndDrop}
          drag={drag}
          setDragging={setDragging}
        >
          {UploadDocuments}
        </DragAndDropFiles>
      </div>
    </>
  );
};

export default UploadDocumentBox;
