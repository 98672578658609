import React from 'react';
import { Edit3 } from 'react-feather';

import Card from '~/src/components/Card';
import ReactPlayer from 'react-player';
import ContactUsButton from '~/src/components/ContactUsButton';
import theme from '~/src/theme';

const CardHeader = () => (
  <h3 style={{ display: 'flex', alignItems: 'center', margin: '0px' }}>
    <Edit3 color={theme.colors.bluebird} size={22} />
    <span
      style={{
        marginLeft: '16px',
        textTransform: 'uppercase',
        color: theme.colors.bluebird,
        fontSize: '14px',
      }}
    >
      E Signatures
    </span>
  </h3>
);

const CardFooter = () => {
  const utmCampaign = 'e-signatures';
  return (
    <div>
      <ContactUsButton
        utmCampaign={utmCampaign}
        routeToLink={`https://www.lawyaw.com/contact-us/?utm_source=clio_free_tier_upgrade&utm_campaign=${utmCampaign}`}
      />
    </div>
  );
};

const EsignaturesGated = () => {
  return (
    <div style={{ marginTop: 24 }}>
      <Card renderHeader={CardHeader} renderFooter={CardFooter} elevate>
        <h1
          style={{
            marginBottom: '16px',
            fontSize: '18px',
            lineHeight: '1.5em',
          }}
        >
          Sign documents and send them out for signature
        </h1>
        <ReactPlayer
          url="https://lawyaw-3.wistia.com/medias/cprcx7khud"
          width={510}
          height={286.875}
        />
        <p style={{ lineHeight: '1.5em', marginTop: '16px' }}>
          Upgrade your plan to access signatures, template sets, questionnaires
          and more.
        </p>
      </Card>
    </div>
  );
};

export default EsignaturesGated;
