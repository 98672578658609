import React from 'react';
import { AlertCircle } from 'react-feather';

/* Components */
import Banner from '~/src/components/Banner';
import Button from '~/src/components/Button';
import { useSubscription } from '../entities/subscription';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';

const FreeTrialBannerContainer = () => {
  const { data: subscription } = useSubscription();
  const history = useHistory();
  const location = useLocation();

  if (!subscription) return null;
  if (!subscription.is_active_free_trial) return null;
  if (location.pathname.includes('subscribe')) return null;
  if (!subscription.trial_end) return null;

  const remainingDays = moment(subscription.trial_end).diff(moment(), 'days');

  if (remainingDays < 0)
    return (
      <Banner small alert fullWidth Icon={AlertCircle}>
        Your free trial period has expired. Configure your plan to continue.
      </Banner>
    );

  return (
    <Banner small alert fullWidth Icon={AlertCircle}>
      <span className="mr-3">
        {remainingDays} days left. Get 10% discount by upgrading before the end
        of your free trial.
      </span>

      <Button primary onClick={() => history.push('/subscribe')}>
        Upgrade
      </Button>
    </Banner>
  );
};

export default FreeTrialBannerContainer;
