import React, { FC } from 'react';
import {
  CURRENT_STEP,
  Step,
} from '~/src/components/SignaturePackage/constants';
import useMst from '~/src/hooks/useMst';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import WithLayoutProps from '~/src/hoc/WithLayoutProps';
import SelectDocumentsContainer from './SelectDocumentsContainer';
import SignerSelectionWithLayoutContainer from './SignerSelectionWithLayoutContainer';
import useFetchSignaturePackage from '../hooks/useFetchSignaturePackage';
import ChooseSignersContainer from './ChooseSignersContainer';
import SendEmailContainer from './SendEmailContainer';
import { WhoSigns } from '../models';
import { history } from '../utils/history';

interface Props {
  maybeSignaturePackageId?: string;
  step: Step;
  showModal: (type: string, object: any) => void;
  hideModal: () => void;
  showToast: (...args: any[]) => void;
}

/**
 * This container allows the user to create a project and
 * signature package from scratch using only custom documents.
 */
const CustomSignaturePackageContainer: FC<Props> = ({
  maybeSignaturePackageId,
  step,
  showToast,
  showModal,
  hideModal,
}) => {
  const { signatures, user } = useMst((store) => {
    return {
      signatures: store.signatures,
      user: store.user,
    };
  });

  const signaturePackageId = parseInt(maybeSignaturePackageId!);

  const { signaturePackage: selectedPackage } = useFetchSignaturePackage(
    String(signaturePackageId),
  );

  const updateSignaturePackageStatus = async (currentStep: CURRENT_STEP) => {
    const signaturePackageData = {
      signaturePackageId: selectedPackage!.id,
      currentStep,
    };

    await signatures.updateSignaturePackage('', signaturePackageData);
  };

  if (step === 'choose-signers' && selectedPackage)
    return (
      <ChooseSignersContainer
        signaturePackage={selectedPackage}
        showModal={showModal}
        hideModal={hideModal}
        user={user}
        onBack={() =>
          history.replace(
            `/custom-signature-package/${selectedPackage.lawyawProjectId}/${selectedPackage.id}/select-documents`,
          )
        }
        onNext={() =>
          history.replace(
            `/custom-signature-package/${selectedPackage.lawyawProjectId}/${selectedPackage.id}/add-signatures`,
          )
        }
        onExit={() => history.replace('/signatures')}
        onUpdate={(signaturePackageForRecipients) =>
          signatures.updateSignaturePackage('', signaturePackageForRecipients)
        }
      />
    );

  if (step === 'add-signatures' && selectedPackage)
    return (
      <SignerSelectionWithLayoutContainer
        signaturePackage={selectedPackage}
        onNext={async () => {
          if (selectedPackage.whoSigns === WhoSigns.JustMe) {
            await updateSignaturePackageStatus(
              CURRENT_STEP.SENT_FOR_SIGNATURES,
            );
            history.replace('/signatures');
            showToast(LAYOUT_TOAST_TYPES.success, {
              message: 'Signature package completed',
            });
          } else {
            await updateSignaturePackageStatus(CURRENT_STEP.ADD_SIGNATURES);
            history.replace(
              `/custom-signature-package/${selectedPackage.lawyawProjectId}/${selectedPackage.id}/send-email`,
            );
          }
        }}
        onBack={() =>
          history.replace(
            `/custom-signature-package/${selectedPackage.lawyawProjectId}/${selectedPackage.id}/choose-signers`,
          )
        }
        onExit={async () => {
          await updateSignaturePackageStatus(CURRENT_STEP.ADD_SIGNATURES);
          history.replace('/signatures');
        }}
      />
    );

  if (step === 'send-email' && selectedPackage)
    return (
      <SendEmailContainer
        user={user}
        signaturePackage={selectedPackage}
        onUpdate={(currentUser, signaturePackage) =>
          signatures.updateSignaturePackage(currentUser.email, signaturePackage)
        }
        showToast={showToast}
        showModal={showModal}
        hideModal={hideModal}
        onBack={() =>
          history.replace(
            `/custom-signature-package/${selectedPackage.lawyawProjectId}/${selectedPackage.id}/add-signatures`,
          )
        }
        onNext={() => history.replace('/signatures')}
        onSend={(emailPackage) => signatures.sendSignaturePackage(emailPackage)}
        onCompletion={async () => {
          await updateSignaturePackageStatus(CURRENT_STEP.SENT_FOR_SIGNATURES);
          history.replace('/signatures');
          showToast(LAYOUT_TOAST_TYPES.success, {
            message: 'Signature package sent for signature(s)',
          });
        }}
      />
    );

  // default: select-documents step
  return (
    <SelectDocumentsContainer
      signaturePackage={selectedPackage}
      showModal={showModal}
      hideModal={hideModal}
      onNext={(projectId, packageId) =>
        history.replace(
          `/custom-signature-package/${projectId}/${packageId}/choose-signers`,
        )
      }
      onCreate={(filesToUpload) =>
        signatures.createCustomSignaturePackage(filesToUpload)
      }
      onAdd={(signaturePackageId, filesToUpload) =>
        signatures.addDocumentsToPackage(signaturePackageId, filesToUpload)
      }
      onUpdate={(signaturePackageData) =>
        signatures.updateSignaturePackage('', signaturePackageData)
      }
      onReorder={(signaturePackage, documents, sortedServerDocIds) =>
        signatures.reorderDocuments(
          signaturePackage,
          documents,
          sortedServerDocIds,
        )
      }
      onExit={() => history.replace('/signatures')}
    />
  );
};

export default WithLayoutProps(CustomSignaturePackageContainer);
