import React from 'react';
import { Alert, AlertTitle, Box, Button } from '@mui/material';
import { AlertTriangle } from 'react-feather';

export const NoQuestionsMessage: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => {
  return (
    <Alert
      sx={(theme) => ({
        padding: theme.spacing(0.5, 2),
        background: theme.palette.yellow[50],
        color: theme.palette.text.primary,
        '.MuiAlert-icon': {
          color: theme.palette.text.primary,
        },
        border: '1px solid #D1D5DB',
      })}
      icon={<AlertTriangle fontSize="inherit" />}
      severity="warning"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignContent: 'center',
          gap: '4px',
        }}
      >
        <AlertTitle
          sx={{
            width: 'auto',
          }}
        >
          To share a questionnaire, a question must be added first.
        </AlertTitle>
        <Box>
          <Button
            variant="grey"
            sx={{
              width: '130px',
              fontSize: 14,
              fontWeight: 600,
              padding: '6px 16px',
              backgroundColor: '#F3F4F6 !important',
              color: 'black',
              '&:hover': {
                backgroundColor: '#D1D5DB !important',
              },
            }}
            onClick={onClick}
          >
            Edit questionnaire
          </Button>
        </Box>
      </Box>
    </Alert>
  );
};
