import { forEach, snakeCase, isEmpty } from 'lodash';
import qs from 'qs';

import env from '~/src/utils/env';
import { APIError } from '../utils/error';

type GetPaginationUrlQueryParams = Record<string, string | number> & {
  search?: string;
  sortBy?: string;
  order?: string;
  limit?: string | number;
  page?: string | number;
};

export const getPaginationUrlQuery = ({
  search,
  sortBy,
  order,
  limit,
  page,
  ...rest
}: GetPaginationUrlQueryParams) => {
  const searchValue = search && search.length > 0 ? search : undefined;
  const sortByValue = sortBy && sortBy.length > 0 ? sortBy : undefined;

  const params: Record<string, string | number> = {};

  if (searchValue) {
    params.search = searchValue;
  }

  if (sortByValue) {
    params.sort_by = sortByValue;
  }

  if (order) {
    params.order = order;
  }

  if (limit) {
    params.limit = limit;
  }

  if (page) {
    params.page = page;
  }

  forEach(rest, (val, key) => {
    params[snakeCase(key)] = val;
  });

  const query = qs.stringify(params);

  return `?${query}`;
};

type HttpRequestsParams = {
  apiUrl: string;
};

type FormUploadParams = {
  relativePath: string;
  parse?: boolean;
  host?: string;
  data: FormData;
};

type InnerSendParams = {
  relativePath: string;
  host?: string;
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  body?: any;
  headers?: Record<string, string>;
  timeout?: number;
};

const httpRequests = ({ apiUrl }: HttpRequestsParams) => {
  async function formUpload({
    host = apiUrl,
    parse = false,
    relativePath,
    data,
  }: FormUploadParams) {
    const requestUrl = `${host}${relativePath}`;
    let response;

    try {
      response = await fetch(requestUrl, {
        method: 'POST',
        body: data,
      });
    } catch (err) {
      console.error(`Error uploading ${requestUrl}`, err);
      throw err;
    }

    return parse ? response.json() : response;
  }

  async function innerSend({
    host = apiUrl,
    relativePath,
    method,
    body,
    headers = {},
    timeout,
  }: InnerSendParams) {
    const requestUrl = `${host}${relativePath}`;
    let response;

    if (!isEmpty(body)) {
      headers['Content-Type'] = 'application/json';
      body = JSON.stringify(body);
    }

    try {
      const controller = new AbortController();
      const signal = controller.signal;

      if (timeout) {
        setTimeout(() => controller.abort(), timeout);
      }

      response = await fetch(requestUrl, {
        body,
        headers,
        method,
        signal,
      });

      if (response.status >= 300) {
        const json = await response.json();
        throw new APIError(json.message, response);
      }
    } catch (err) {
      console.error(`Error requesting ${requestUrl}`, err);
      throw err;
    }

    return response;
  }

  async function innerSendAuthenticated(
    params: InnerSendParams,
    parse = false,
  ) {
    const response = await innerSend({
      host: apiUrl,
      ...params,
    });

    return parse ? response.json() : response;
  }

  return {
    formUpload,
    innerSend,
    innerSendAuthenticated,
  };
};

export const http = httpRequests({
  apiUrl: env.apiUrl || window.origin,
});
