import { useQuery, UseQueryOptions } from 'react-query';
import { APIError } from '~/src/utils/error';
import { useCurrentOrgFprint } from '../../user';
import { composeQueryOpts, fetchAndParse } from '../../utils';
import { keys } from '../keys';
import { Contact } from '../types';
import { getContactsUrl } from '../urls';

export const useContact = (
  contactId: number,
  options: UseQueryOptions<Contact, APIError> = {},
) => {
  const orgFprint = useCurrentOrgFprint();
  return useQuery(
    composeQueryOpts(options, {
      queryKey: contactId ? keys.detail(contactId) : undefined,
      queryFn: () =>
        fetchAndParse<Contact>(getContactsUrl(orgFprint, contactId)),
    }),
  );
};
