import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useUpdateSeatsModal } from '~/src/entities/subscription';

type SeatsProps = {
  seats: number;
  onChange: (seats: number) => void;
};

export const Seats = ({ seats, onChange }: SeatsProps) => {
  const [toggleSeatChangeModal, seatsModal] = useUpdateSeatsModal({
    defaultSeats: seats,
    onChange: (nextSeats) => {
      toggleSeatChangeModal();
      onChange(nextSeats);
    },
  });

  return (
    <>
      {seatsModal}
      <Box pb={2}>
        <Box pb={1}>
          <Typography variant={'subheading'}>Plan</Typography>
        </Box>
        <Typography variant={'body1'}>
          {seats} seat{seats === 1 ? '' : 's'}
        </Typography>
      </Box>
      <Button
        variant={'contained'}
        onClick={toggleSeatChangeModal}
        size={'large'}
      >
        Update seats
      </Button>
    </>
  );
};
