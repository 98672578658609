import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

export default StyleSheet.create({
  cardRow: {
    marginTop: `${theme.unit * 2}px`,
  },

  /* Clio Success */
  successContainer: {
    textAlign: 'center',
  },

  successIcon: {
    display: 'block',
    marginBottom: theme.unit * 4,
  },

  /* ClioLoading */
  container: {
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    display: 'block',
    marginTop: theme.unit * 2,
    textAlign: 'center',
    fontSize: theme.fonts.size.regular,
    color: theme.colors.charcoal,
  },
  subtitle: {
    maxWidth: theme.unit * 50,
    lineHeight: '1.75em',
    marginTop: theme.unit * 2,
    textAlign: 'center',
    fontSize: theme.fonts.size.small,
    color: theme.colors.chrome,
  },
  actionContainer: {
    margin: '0 auto',
    marginTop: theme.unit * 4,
    display: 'flex',
    justifyContent: 'center',
  },
});
