import React from 'react';
import { Divider, Button, Box } from '@mui/material';
import { useMissingFields } from '../../../useMissingFields';
import { ProjectStackFieldGroup } from '~/src/entities/project';

type SaveAsNewContactProps = {
  onClick: () => void;
  group: ProjectStackFieldGroup;
};

export const SaveAsNewContactButton = ({
  onClick,
  group,
}: SaveAsNewContactProps) => {
  const hasAtLeastOneFieldFilled =
    useMissingFields(group.fields).length < group.fields.length;

  if (!hasAtLeastOneFieldFilled) return null;

  return (
    <>
      <Divider />
      <Box display={'flex'} justifyContent={'flex-end'} px={3} py={2}>
        <Button onClick={onClick} variant={'contained'}>
          Save as new contact
        </Button>
      </Box>
    </>
  );
};
