import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
} from '@mui/material';
import React, { useState } from 'react';

import { CourtFormsList, CourtFormsListProps } from './CourtFormsList';

export type CourtFormsModalProps = {
  excludeList?: string[]; // court forms to exclude
  defaultValues: string[];
  renderExtra?: CourtFormsListProps['renderExtra'];
  onChange: (forms: string[]) => void;
} & Omit<DialogProps, 'onChange'>;

export const CourtFormsModal = ({
  excludeList,
  defaultValues,
  renderExtra,
  onChange,
  ...rest
}: CourtFormsModalProps) => {
  const [values, setValues] = useState(defaultValues);
  return (
    <Dialog
      fullWidth
      maxWidth={'sm'}
      {...rest}
      TransitionProps={{ onEnter: () => setValues(defaultValues) }}
    >
      <DialogTitle>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box>Choose court form packages</Box>
          <IconButton onClick={(e) => rest.onClose?.(e, 'backdropClick')}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers={true}>
        <CourtFormsList
          excludeList={excludeList}
          renderExtra={renderExtra}
          value={values}
          onChange={setValues}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={(e) => rest.onClose?.(e, 'escapeKeyDown')}
        >
          Cancel
        </Button>
        <Button
          data-testid="court-forms-modal-confirm"
          variant="contained"
          onClick={() => onChange(values)}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
