import React from 'react';
import Card from '~/src/components/Card';
import Button from '~/src/components/Button';
import { Heading, Text } from '~/src/components/Typography';

export const ScheduleCall = () => {
  return (
    <div className="md:shrink-0 md:w-7/12 mt-4">
      <Card dynamic>
        <div className="flex flex-col gap-4">
          <Heading variant="subheading">
            Do you have additional questions?
          </Heading>

          <Text>
            Schedule a call with one of our team members to get answers.
          </Text>

          <Button
            discrete
            onClick={() =>
              window.open(
                'https://lawyaw.chilipiper.com/book/free-trial',
                '_blank',
              )
            }
            className="self-start"
          >
            Schedule a call
          </Button>
        </div>
      </Card>
    </div>
  );
};
