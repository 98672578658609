/* Libs */
import React, { useEffect } from 'react';

/* Containers */
import QuestionnaireForm from '~/src/containers/questionnaires/QuestionnaireForm';

/* Containers */
import PageContainer from '~/src/containers/pages/Page';

/* Hooks */
import useFetchQuestionnaireForm from '~/src/hooks/useFetchQuestionnaireForm';

const EditQuestionnaireContainer = ({ id, setTitle }) => {
  const { loading, intake } = useFetchQuestionnaireForm(id, true);

  useEffect(() => {
    const intakeExists = !!intake.id;

    if (!loading && !intakeExists) {
      history.replace('/templates');
    }

    if (!loading && intakeExists && setTitle) {
      setTitle(intake.title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <PageContainer loading={loading} loadingTitle="Loading your questionnaire">
      <QuestionnaireForm intakeId={intake.id} />
    </PageContainer>
  );
};

export default EditQuestionnaireContainer;
