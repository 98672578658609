import React from 'react';
import { css } from 'aphrodite';

import styles from './styles';

type ColumnProps = {
  children: React.ReactNode;
  small?: boolean;
  width?: string;
  maxWidth?: string;
  margin?: string;
  alignItems?: string;
  justifyContent?: string;
  flexFlow?: string;
};

type RowProps = {
  children: React.ReactNode;
  alignItems?: string;
  justifyContent?: string;
  flexFlow?: string;
};

const Column = ({
  children,
  small,
  width,
  maxWidth,
  margin,
  alignItems,
  justifyContent,
  flexFlow,
}: ColumnProps) => (
  <div
    className={css(styles.column, small && styles.columnSmall)}
    style={{ maxWidth, width, margin, alignItems, justifyContent, flexFlow }}
  >
    {children}
  </div>
);

const Row = ({ children, alignItems, justifyContent, flexFlow }: RowProps) => (
  <div
    className={css(styles.row)}
    style={{ alignItems, justifyContent, flexFlow }}
  >
    {children}
  </div>
);

export { Column, Row };
