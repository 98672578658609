import { Box, Typography } from '@mui/material';
import React from 'react';

type SingleSelectActionProps = {
  onClick: () => void;
  label: string;
};

export const SingleSelectAction = ({
  onClick,
  label,
}: SingleSelectActionProps) => {
  return (
    <Box
      px={3}
      py={2}
      onClick={onClick}
      sx={(theme) => ({
        cursor: 'pointer',
        borderTop: `1px solid${theme.palette.border}`,
        background: theme.palette.background.page,
        textAlign: 'center',
        '&:hover': {
          color: `${theme.palette.blue[600]} !important`,
          background: theme.palette.blue[50],
        },
      })}
    >
      <Typography variant="button">{label}</Typography>
    </Box>
  );
};
