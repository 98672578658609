import React, { useEffect, useState } from 'react';
import { autorun } from 'mobx';

import PropTypes from 'prop-types';
import { css } from 'aphrodite';

/* Components */
import { Container, Body, Header, Footer } from '~/src/components/SlideIn';
import { default as slideInStyles } from '~/src/components/SlideIn/styles';
import ChooseTemplatesForm from './ChooseTemplatesForm';

/* Styles */

const QuestionFieldMapSlideIn = ({
  intake,
  questionId,
  onTemplateChange,
  onFieldChange,
  onRemoveQustionTag,
}) => {
  const [variables, setVariables] = useState([]);
  const [templateIds, setTemplateIds] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [questionTag, setQuestionTag] = useState('');
  const [questionLabel, setQuestionLabel] = useState('');

  useEffect(() => {
    // This function runs everytime one of the mobx property changes, i.e. loading, templateIds, templateVariables etc..
    if (questionId) {
      const freshQuestion = intake.getQuestion(questionId);

      setQuestionTag(freshQuestion.tag || '');
      setQuestionLabel(freshQuestion.label);
    }

    if (intake.id) {
      if (intake.templateVariables) {
        setVariables([...intake.templateVariables.toJSON()]);
      }

      setTemplateIds(intake.templateIds);
      setIsSaving(intake.loading);
    }

    return autorun(() => {
      if (questionId) {
        const freshQuestion = intake.getQuestion(questionId);

        setQuestionTag(freshQuestion.tag || '');
        setQuestionLabel(freshQuestion.label);
      }

      if (intake.templateVariables) {
        setVariables([...intake.templateVariables.toJSON()]);
      }

      setTemplateIds([...intake.templateIds]);
      setIsSaving(intake.loading);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intake.templateIds, intake.templateVariables, intake.loading]);

  const body = (
    <ChooseTemplatesForm
      variables={variables}
      templateId={templateIds.length > 0 && templateIds[0]}
      questionLabel={questionLabel}
      questionTag={questionTag}
      onTemplateChange={onTemplateChange}
      onFieldChange={onFieldChange}
      onRemoveQustionTag={onRemoveQustionTag}
    />
  );

  return (
    <Container>
      <Header title={`Map Question`} />
      <Body>
        <div className={css(slideInStyles.innerBody)}>{body}</div>
      </Body>
      <Footer alignRight>
        <div className={css(slideInStyles.footerLink)}>
          {isSaving ? 'Saving...' : 'Saved'}
        </div>
      </Footer>
    </Container>
  );
};

QuestionFieldMapSlideIn.propTypes = {
  templateIds: PropTypes.array,
};

QuestionFieldMapSlideIn.defaultProps = {
  templateIds: [],
};

export default QuestionFieldMapSlideIn;
