import qs from 'qs';
import env from '~/src/utils/env';

export type QueryParams = {
  search: string;
  sort_by: string;
  order: 'desc' | 'asc';
  limit: number;
  matter: string | number;
  search_text: string;
};

export const getContactsUrl = (
  orgFprint: string,
  contactId?: string | number,
) =>
  `${env.apiUrl}/clients/api/v1/org/${orgFprint}/contact/${
    contactId ? `${contactId}/` : ''
  }`;

export const getMatterContactsUrl = (
  orgFprint: string,
  matterId: string | number,
  params?: Partial<QueryParams>,
) => {
  const stringified = params ? `${`?${qs.stringify(params)}`}` : '';
  return `${env.apiUrl}/clients/api/v1/org/${orgFprint}/contact/matter/${matterId}/${stringified}`;
};

export const getListUrl = (
  orgFprint: string,
  params?: Partial<QueryParams>,
) => {
  const stringified = params ? `${`?${qs.stringify(params)}`}` : '';
  return `${getContactsUrl(orgFprint)}${stringified}`;
};

export const getContactFieldKeyMapUrl = (
  orgFprint: string,
  contactId: string | number,
) => `${getContactsUrl(orgFprint, contactId)}map/`;
