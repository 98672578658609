import React from 'react';
import { getValidation } from '~/src/utils/validation';
import Form from './Form';
import FormField from './FormField';

const renderFormField = (field, additionalProps) => {
  const { validation, required, ...customProps } = field;

  const fieldValidation =
    typeof validation === 'function' ? validation : getValidation(validation);

  const props = {
    ...field,
    ...additionalProps,
  };

  const renderField = (fieldProps) => {
    return (
      <FormField
        labelCssStyle={field.labelCssStyle}
        label={field.label}
        id={field.id}
        type={field.type}
        editable={field.editable}
        defaultValue={field.defaultValue}
        options={field.options}
        error={field.error}
        autoComplete={field.autoComplete}
        validation={fieldValidation}
        hint={field.hint}
        count={field.count}
        required={required}
        {...fieldProps}
        {...customProps}
        {...additionalProps}
      />
    );
  };

  return {
    id: field.id,
    type: field.type,
    editable: props.editable,
    render: renderField,
    validation: field.validation,
    defaultValue: field.defaultValue,
  };
};

export { FormField, Form, Form as default, renderFormField };
