import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

export default StyleSheet.create({
  documentSetsTitle: {
    maxWidth: 1080,
    width: '100%',
    margin: '0 auto',
    marginTop: `${theme.unit * 6}px`,
    marginBottom: `${theme.unit * 2}px`,
  },
  rowActionButton: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.unit * 1}px ${theme.unit * 2}px`,
    paddingRight: theme.unit,
  },
});
