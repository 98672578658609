/* Libraries */
import { StyleSheet } from 'aphrodite';

const styles = StyleSheet.create({
  label: {
    fontWeight: 600,
  },
  section: {
    marginBottom: 24,
  },
  clioLink: {
    marginRight: 3,
  },
});

export default styles;
