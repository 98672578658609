import React from 'react';
import { DialogContainer } from '~/src/components/Modal/mui';
import { DeleteSubmissionDialogContents } from './DeleteSubmissionDialogContents';
import { QuestionnaireSubmission } from '@clio/questionnaire-builder';

interface Props {
  open: boolean;
  submission: QuestionnaireSubmission;
  onClose: () => void;
}

export const DeleteSubmissionDialogModal = ({
  open,
  submission,
  onClose,
}: Props) => {
  return (
    <DialogContainer open={open} onClose={onClose}>
      <DeleteSubmissionDialogContents
        submission={submission}
        onClose={onClose}
      />
    </DialogContainer>
  );
};
