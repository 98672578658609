/* Libs */
import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

/* Icons */
import { ReactComponent as ScratchDocumentIcon } from '~/src/static/scratch-document-icon.svg';
import { ReactComponent as MagicIcon } from '~/src/static/magic-icon.svg';

const QUESTIONNAIRE_CREATION_TYPE_SCRATCH =
  'QUESTIONNAIRE_CREATION_TYPE_SCRATCH';
const QUESTIONNAIRE_CREATION_MAP_TO_DOCUMENTS =
  'QUESTIONNAIRE_CREATION_MAP_TO_DOCUMENTS';

const CreateNewQuestionnaireWalkthroughContainer = (props) => {
  const { onChange } = props;

  const [type, setType] = useState(QUESTIONNAIRE_CREATION_MAP_TO_DOCUMENTS);

  useEffect(() => {
    onChange(type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const handleSelectStartFromScratch = () => {
    setType(QUESTIONNAIRE_CREATION_TYPE_SCRATCH);
  };
  const handleSelectMapToDocuments = () => {
    setType(QUESTIONNAIRE_CREATION_MAP_TO_DOCUMENTS);
  };

  const isCreateFromScratchSelected =
    type === QUESTIONNAIRE_CREATION_TYPE_SCRATCH;
  const isCreateMapToDocuments =
    type === QUESTIONNAIRE_CREATION_MAP_TO_DOCUMENTS;

  return (
    <div className="flex flex-grow items-center justify-center">
      <div>
        <h1 className="text-xl text-center font-bold m-8">
          How would you like to create a Questionnaire?
        </h1>
        <div className="flex justify-center">
          <div
            className={cn(
              'p-10 m-5 border-solid border border-white rounded bg-white text-center cursor-pointer transition hover:shadow-lg',
              {
                'shadow-lg': isCreateFromScratchSelected,
                'shadow-sm': !isCreateFromScratchSelected,
                'border-blue-500': isCreateFromScratchSelected,
                'border-gray-300': !isCreateFromScratchSelected,
              },
            )}
            onClick={handleSelectStartFromScratch}
          >
            <ScratchDocumentIcon />
            <p className="text-lg text-center font-bold mt-5">
              Start from scratch
            </p>
          </div>
          <div
            className={cn(
              'p-10 m-5 border-solid border border-white rounded bg-white text-center cursor-pointer transition hover:shadow-lg',
              {
                'shadow-lg': isCreateMapToDocuments,
                'shadow-sm': !isCreateMapToDocuments,
                'border-blue-500': isCreateMapToDocuments,
                'border-gray-300': !isCreateMapToDocuments,
              },
            )}
            onClick={handleSelectMapToDocuments}
          >
            <MagicIcon />
            <p className="text-lg text-center font-bold mt-5">
              Map to documents
            </p>
          </div>
        </div>
      </div>
    </div>
    // <>
    //   <Row alignItems="center" flexFlow="row wrap">
    //     <Column maxWidth="100%" width="100%" margin="0 auto" >
    //       <h1 style={{ textAlign: 'center' }}>How would you like to create a Questionnaire?</h1>
    //     </Column>
    //     <Column maxWidth="40%" margin="0 auto">
    //       <Card elevate={1}>
    //         <h1 style={{ textAlign: 'center', padding: "100px 0" }}>Start from scratch</h1>
    //       </Card>
    //     </Column>
    //     <Column maxWidth="40%" margin="0 auto">
    //       <Card elevate={1}>
    //         <h1 style={{ textAlign: 'center', padding: "100px 0" }}>Create from Template or Template Set</h1>
    //       </Card>
    //     </Column>
    //   </Row >
    // </>
  );
};

CreateNewQuestionnaireWalkthroughContainer.propTypes = {
  onChange: PropTypes.func,
};

CreateNewQuestionnaireWalkthroughContainer.defaultProps = {
  onChange: () => {},
};

export {
  CreateNewQuestionnaireWalkthroughContainer as default,
  QUESTIONNAIRE_CREATION_TYPE_SCRATCH,
  QUESTIONNAIRE_CREATION_MAP_TO_DOCUMENTS,
};
