import { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import useBackend from '~/src/hooks/useBackend';

import { useWebViewerContext } from '~/src/components/Webviewer';
import { getPdfBlob, getFileFromUrl } from '~/src/hooks/useBackend.utils';

const PDFViewer = ({
  currentSignaturePackage,
  currentProject,
  allDocuments,
  currentDocument,
  currentUserInfo,
  requestedDownloadPdf,
  requestedDownloadAllPdf,
  setRequestedDownloadAllPdf,
  allPdfBlobs,
  setAllPdfBlobs,
  createEsignPackage,
  setCurrentDocumentPdfBlob,
  signaturePackageFilesData,
  setSignaturePackageFilesData,
  setReRouteToEsign,
  setViewerIsLoading,
  setRequestedDownloadPdf,
  docIndex,
  setDocIndex,
  setBackendLoaded,
  backendLoaded,
  viewerIsLoading,
  showHighlighting,
}) => {
  const { makeWebViewerVisible, setZIndexOfWebViewer, instance } =
    useWebViewerContext();

  const {
    newInit,
    loadDocumentIntoWebViewer,
    currentPdfBlob,
    pdfBlobUpdateInProgress,
  } = useBackend(
    currentProject.stackSavedData?.toJSON(),
    currentProject,
    instance,
    (...args) => currentProject.setStackSavedData(...args),
  );

  const [zipFiles, setZipFiles] = useState(new JSZip());

  useEffect(() => {
    if (currentPdfBlob) {
      setCurrentDocumentPdfBlob(currentPdfBlob);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPdfBlob]);

  useEffect(() => {
    if (createEsignPackage) {
      // setCurrentPdfBlob(getPdfBlob(instance.docViewer));
      let tempBlob = {};
      // We are generating the PDF blob for the current document to ensure
      // we have the most recent changes
      getPdfBlob(instance, true).then((value) => {
        tempBlob = value;
        if (!pdfBlobUpdateInProgress) {
          allPdfBlobs.push(tempBlob.blobData);
          setAllPdfBlobs(allPdfBlobs);
          signaturePackageFilesData.push({
            name: `${currentDocument.title}.${currentDocument.id}.pdf`,
            blobData: tempBlob.blobData,
            numberOfPages: tempBlob.numberOfPages,
          });
          setSignaturePackageFilesData(signaturePackageFilesData);
          if (signaturePackageFilesData.length == allDocuments.length) {
            setReRouteToEsign(true);
          } else if (docIndex === allDocuments.length) {
            // TODO - Only loop through pdf documents and not the docx, for docx just append the already generated pdfs!
            setDocIndex(1);
          } else {
            const newDocIndex = docIndex + 1;
            if (docIndex < allDocuments.length) {
              setDocIndex(newDocIndex);
            }
          }
          if (allDocuments.length == 1) {
            setReRouteToEsign(true);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createEsignPackage, currentPdfBlob, pdfBlobUpdateInProgress]);

  const downloadAllPDFS = async () => {
    const currentBlob = await getPdfBlob(instance, true);
    allPdfBlobs.push(currentBlob.blobData);
    if (currentDocument.docx) {
      const docxFile = showHighlighting
        ? currentDocument.docx
        : currentDocument.processedDocx;
      zipFiles.file(
        `${currentDocument.title}.docx`,
        getFileFromUrl(docxFile, `${currentDocument.title}.docx`),
      );
    } else {
      zipFiles.file(`${currentDocument.title}.pdf`, currentBlob.blobData);
    }
    setAllPdfBlobs(allPdfBlobs);
    if (docIndex === allDocuments.length) {
      setDocIndex(1);
    } else {
      const newDocIndex = docIndex + 1;

      if (docIndex < allDocuments.length) {
        setDocIndex(newDocIndex);
      }
    }
  };

  useEffect(() => {
    if (requestedDownloadAllPdf) {
      if (currentPdfBlob) {
        downloadAllPDFS();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestedDownloadAllPdf, currentPdfBlob]);

  useEffect(() => {
    const handleDownloadPdf = async () => {
      const blob = await getPdfBlob(instance, true);
      if (currentDocument.docx) {
        const docxFile = showHighlighting
          ? currentDocument.docx
          : currentDocument.processedDocx;
        const response = await fetch(docxFile);
        const data = await response.blob();
        saveAs(data, `${currentDocument.title}.docx`, {
          type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        });
      } else {
        let documentTitleToSave = currentDocument.title;
        // If extension is not in document title, add it
        if (
          documentTitleToSave.substr(documentTitleToSave.length - 4) !== '.pdf'
        ) {
          documentTitleToSave += '.pdf';
        }
        saveAs(blob.blobData, documentTitleToSave);
      }
      setRequestedDownloadPdf(false);
    };

    if (requestedDownloadPdf) {
      handleDownloadPdf();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestedDownloadPdf]);

  const downloadZip = async () => {
    const generatedZip = await zipFiles.generateAsync({ type: 'blob' });
    saveAs(generatedZip, `${currentProject.name}.zip`);
    setZipFiles(new JSZip());
  };

  const webviewerIsReady = !!instance;

  useEffect(() => {
    if (allPdfBlobs.length == allDocuments.length) {
      setAllPdfBlobs([]);
      if (createEsignPackage) {
        setReRouteToEsign(true);
        setDocIndex(1);
      } else if (requestedDownloadAllPdf) {
        downloadZip();
        setDocIndex(1);
        setRequestedDownloadAllPdf(false);
        setZIndexOfWebViewer(1);
        setViewerIsLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPdfBlob, docIndex]);

  useEffect(() => {
    if (!webviewerIsReady) return;
    if (backendLoaded) return;
    makeWebViewerVisible();
    newInit(allDocuments.toJSON());
    setTimeout(() => {
      setBackendLoaded(true);
    }, 2500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backendLoaded, webviewerIsReady]);

  useEffect(() => {
    if (backendLoaded && currentDocument) {
      if (!viewerIsLoading) {
        makeWebViewerVisible();
      }
      if (
        currentDocument.title &&
        (currentDocument.pdf || currentDocument.originalFileUrl)
      ) {
        const currentDocumentTitleArray = currentDocument.title.split('/');
        const filename =
          currentDocumentTitleArray[currentDocumentTitleArray.length - 1];
        let pdfUrl =
          currentDocument.pdf ||
          currentDocument.originalPDfBlob ||
          currentDocument.originalFileUrl;
        if (currentDocument.docx) {
          pdfUrl = showHighlighting
            ? currentDocument.ms_pdf
            : currentDocument.processedMsPdf;
        }
        const loadDocData = {
          fileUrl: pdfUrl,
          docsData: allDocuments.toJSON(),
          currentSignaturePackage,
          isEsign: !!currentSignaturePackage.title,
          currentUserInfo,
          currentDocumentInfo: currentDocument,
          data: {
            filename,
            documentId: `${currentDocument.id}`,
            extension: 'pdf',
          },
          initialPagesData: currentDocument.pages,
          currentProject,
          currentDocumentId: currentDocument.id,
        };

        loadDocumentIntoWebViewer(loadDocData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDocument, showHighlighting]);

  return null;
};

PDFViewer.propTypes = {
  docIndex: PropTypes.number,
  setDocIndex: PropTypes.func,
  setViewerIsLoading: PropTypes.func,
  currentSignaturePackage: PropTypes.object,
  currentProject: PropTypes.object,
  allDocuments: PropTypes.array,
  currentDocument: PropTypes.object,
  currentUserInfo: PropTypes.object,
  requestedDownloadPdf: PropTypes.bool,
  requestedDownloadAllPdf: PropTypes.bool,
  setRequestedDownloadAllPdf: PropTypes.func,
  allPdfBlobs: PropTypes.array,
  setAllPdfBlobs: PropTypes.func,
  createEsignPackage: PropTypes.bool,
  setCurrentDocumentPdfBlob: PropTypes.func,
  signaturePackageFilesData: PropTypes.array,
  setSignaturePackageFilesData: PropTypes.func,
  showHighlighting: PropTypes.bool,
};

PDFViewer.defaultProps = {
  docIndex: 1,
  backendLoaded: false,
  setBackendLoaded: () => {},
  setDocIndex: () => {},
  setViewerIsLoading: () => {},
  currentSignaturePackage: {},
  currentProject: {},
  allDocuments: [],
  currentDocument: {},
  currentDocumentIndex: 0,
  showPreviousDocument: () => {},
  showNextDocument: () => {},
  currentUserInfo: {},
  requestedDownloadPdf: false,
  requestedDownloadAllPdf: false,
  setRequestedDownloadAllPdf: () => {},
  allPdfBlobs: [],
  setAllPdfBlobs: () => {},
  createEsignPackage: false,
  setCurrentDocumentPdfBlob: () => {},
  signaturePackageFilesData: [],
  setSignaturePackageFilesData: () => {},
  showHighlighting: true,
};

export default PDFViewer;
