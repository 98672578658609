/* Libraries */
import React from 'react';
import { useDropzone } from 'react-dropzone';

/* Utilities */

/* Components */

import Card from '~/src/components/Card';
import Button from '~/src/components/Button';
import { Text, Heading } from '~/src/components/Typography';

/* Styles */
import { observer } from 'mobx-react';
import { NameInput } from './NameInput';
import useUser from '~/src/hooks/useUser';
import { useSnackbar } from 'notistack';

const SettingsAccount = observer(
  ({ avatar, onImageUpload, onImageRemove, onImageUploadError }) => {
    const snackbar = useSnackbar();

    const handleImageUpload = (files) => {
      if (files.length > 0) {
        onImageUpload(files);
      }
    };

    const { profile } = useUser();

    const dropzoneProps = {
      disabled: false,
      accept: ['image/png', 'image/jpeg', 'image/jpg'],
      onDropAccepted: handleImageUpload,
      onDropRejected: onImageUploadError,
      minSize: 0,
      maxSize: 10000000,
      maxFiles: 1,
      multiple: false,
    };

    const { getRootProps, getInputProps } = useDropzone(dropzoneProps);

    return (
      <Card>
        <Heading>Profile</Heading>
        <div className="mt-8">
          <Text strong>Photo</Text>
          <div className="mt-2">
            {avatar && (
              <img
                src={avatar}
                className="w-32 h-32 rounded-full border-gray-300 border"
              />
            )}
          </div>
          <div className="mt-4 flex gap-4">
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Button>Upload photo</Button>
            </div>
            {avatar && <Button onClick={onImageRemove}>Remove photo</Button>}
          </div>
        </div>

        <div className="mt-8">
          <NameInput
            initialValues={{
              first_name: profile.firstName,
              last_name: profile.lastName,
              middle_name: profile.middleName,
            }}
            displayName={profile.displayName}
            onChange={async ({ first_name, last_name, middle_name }) => {
              const res = await profile.update({
                firstName: first_name,
                lastName: last_name,
                middleName: middle_name,
              });

              if (res)
                snackbar.enqueueSnackbar('Profile updated', {
                  variant: 'success',
                });
              return res;
            }}
          />
        </div>
      </Card>
    );
  },
);

export default SettingsAccount;
