import { Contact } from '~/src/entities/contact';
import {
  ProjectStackFieldGroup,
  ProjectStackField,
  ProjectStackDocument,
  Project,
} from '~/src/entities/project';

export const getDocumentsForField = (
  projectStackDocuments: ProjectStackDocument[],
  field: ProjectStackField,
) =>
  projectStackDocuments.filter((doc) => {
    if (field.fid in doc.document_fields) {
      return true;
    }
    return false;
  });

export const getFieldsForGroup = (
  project: Project,
  group: ProjectStackFieldGroup,
) =>
  group.fields
    .map((fieldId) => {
      const field = project.stack_data.fields.find(
        ({ fid }) => fid === fieldId,
      );
      if (!field)
        console.error(
          `Missing field ${fieldId} in group ${group.label} in project ${project.id}`,
        );
      return field;
    })
    .filter((f): f is ProjectStackField => !!f)
    .sort((a, b) => {
      // sort_order will be introduced in the Canary and will be used to sort the fields
      const sortOrderA =
        'sort_order' in a && a.sort_order !== undefined ? a.sort_order : 0;
      const sortOrderB =
        'sort_order' in b && b.sort_order !== undefined ? b.sort_order : 0;

      return sortOrderA - sortOrderB;
    });

export const getFieldLabelsForGroup = (
  project: Project,
  group: ProjectStackFieldGroup,
) =>
  group.fields.map((fieldId) => {
    const field = project.stack_data.fields.find(({ fid }) => fid === fieldId);
    if (!field) {
      console.error(
        `Missing field ${fieldId} in group ${group.label} in project ${project.id}`,
      );
    } else {
      return { fid: field.fid, full_label: `${group.label}, ${field.label}` };
    }
  });

export const getEmptyFields = (fields: ProjectStackField[]) => {
  const emptyFields = fields.filter((field) => {
    return !field.value;
  });
  return emptyFields;
};

export const getUpdatedFormFieldsForContact = (
  contact: Contact,
  keyMap: Record<string, string>,
  attributes: string[],
): string[] => {
  // Copy the contact fields that are not an object or a dictionary.
  const resolveFrom: Record<string, any> = { ...contact.data };
  for (const key in contact) {
    if (typeof contact[key as keyof Contact] !== 'object') {
      resolveFrom[key] = contact[key as keyof Contact];
    }
  }
  return attributes.map((attr) => {
    const resolvedAttr = keyMap[attr]!;
    // if the keyMap doesn't contain the attr (resolvedAttr), try and pull it from the
    // contacts data map by just using the attribute
    const resolvedValue = resolveFrom[resolvedAttr || attr];
    return resolvedValue ? `${resolvedValue}` : '';
  });
};
