import { useObserver } from 'mobx-react';
import useMst from '~/src/hooks/useMst';
import useResourcePagination from '~/src/hooks/useResourcePagination';

const useTemplateSetsPagination = () => {
  const { templateSets } = useMst((store) => ({
    templateSets: store.templateSets,
  }));

  const { loading, next, page, setSearch, setSortBy, setOrder } =
    useResourcePagination({
      fetch: templateSets.fetch,
      limit: templateSets.limit,
      order: templateSets.order,
      search: templateSets.search,
      page: templateSets.list.length,
      sortBy: templateSets.sortBy,
      done: templateSets.done,
    });

  const listData = useObserver(() => {
    return templateSets.list.flat();
  });

  const done = useObserver(() => {
    return templateSets.done;
  });

  const empty = useObserver(() => {
    return templateSets.empty;
  });

  return {
    next,
    page,
    empty,
    data: listData,
    loading,
    done,

    setSearch,
    setSortBy,
    setOrder,
  };
};

export default useTemplateSetsPagination;
