import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useCancelSubscriptionModal } from '~/src/entities/subscription';

type CancelSubscriptionProps = {
  endDate: string;
};

export const CancelSubscription = ({ endDate }: CancelSubscriptionProps) => {
  const [toggle, modal] = useCancelSubscriptionModal({ endDate });
  return (
    <>
      {modal}
      <Box pb={5}>
        <Typography variant={'h2'}>Cancel subscription</Typography>
      </Box>
      <Box pb={5}>
        <Typography variant={'body1'}>
          Upon canceling, you and your team will lose access to your templates,
          drafted documents, contacts and matter information stored in Clio
          Draft after the current billing period.
        </Typography>
      </Box>
      <Button
        data-testid="cancel-subscription-btn"
        variant={'contained'}
        color={'inherit'}
        onClick={toggle}
      >
        Cancel subscription
      </Button>
    </>
  );
};
