/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Containers */
import QuestionnaireForm from '~/src/containers/questionnaires/QuestionnaireForm';

const CreateNewQuestionnairePageContainer = (props) => {
  const { templateSetIds, templateIds } = props;

  const createConfig = {
    templateSetIds,
    templateIds,
  };

  return <QuestionnaireForm createConfig={createConfig} />;
};

CreateNewQuestionnairePageContainer.propTypes = {
  templateSetIds: PropTypes.arrayOf(PropTypes.string),
  templateIds: PropTypes.arrayOf(PropTypes.string),
};

CreateNewQuestionnairePageContainer.defaultProps = {
  templateSetIds: [],
  tempalteIds: [],
};

export default CreateNewQuestionnairePageContainer;
