import { useMutation, UseMutationOptions } from 'react-query';
import { BillingCycleType, SubscriptionV2 } from '../types';
import { useCurrentOrgFprint } from '../../user';
import {
  composeMutationOpts,
  fetchAndParse,
  useInvalidateQuery,
} from '../../utils';
import { getSubscriptionV2Url } from '../urls';
import { APIError } from '~/src/utils/error';
import { keys } from '../keys';

export type UpdateSubscriptionParams = {
  add_plans: string[];
  remove_plans: string[];
  duration: BillingCycleType;
  interval_change?: boolean;
  seat: number;
  coupon?: string;
};

export const useUpdateSubscriptionV2 = (
  options: UseMutationOptions<
    SubscriptionV2,
    APIError,
    UpdateSubscriptionParams
  > = {},
) => {
  const orgFprint = useCurrentOrgFprint();

  return useMutation(
    composeMutationOpts(options, {
      onSuccess: useInvalidateQuery(keys.subscription),
      mutationFn: async (body) =>
        fetchAndParse<SubscriptionV2>(getSubscriptionV2Url(orgFprint), {
          method: 'PUT',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify(body),
        }),
    }),
  );
};
