import { QueryClient } from 'react-query';

export const DEFAULT_STALE_TIME = 500; // prevents queries from going stale instantly

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: DEFAULT_STALE_TIME,
    },
  },
});
