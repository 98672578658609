import { filter } from 'lodash';
import { types, flow, getParent } from 'mobx-state-tree';

/* Stores */
import { Project } from '~/src/stores/projectsStore';

/* Services */
import documentsService from '~/src/services/documents';

/* Utils */
import { CreatePaginatedListModel } from '~/src/stores/composers/createPaginatedModel';
import { getOrgFprintFromStoreNode } from './utils';
import analyticsService from '../services/analytics';

const DocumentStore = types
  .model('DocumentStore', {
    error: '',
  })
  .actions((self) => {
    const updateDocument = flow(function* updateDocument(
      documentId,
      properties,
    ) {
      try {
        const rootStore = getParent(self);
        const doc = rootStore.projects.getProject(documentId);
        const newName = properties && properties.title;
        analyticsService.track('Document Renamed', {
          documentId,
          oldName: doc.title,
          newName,
        });

        const next = yield rootStore.projects.updateProject(
          documentId,
          properties,
        );

        return next;
      } catch (error) {
        console.error('Failed to updated document : ', error);
        return false;
      }
    });

    const updateDocumentMetadata = flow(function* updateDocumentMetadata(
      documentId,
      metaData,
    ) {
      try {
        const rootStore = getParent(self);
        const { curProjectId } = rootStore.projects;
        const next = yield documentsService.updateDocumentMetadata(
          getOrgFprintFromStoreNode(self),
          curProjectId,
          documentId,
          metaData,
        );

        const currentProject = rootStore.projects.getProject(curProjectId);
        const documentToRename = currentProject.getDocument(documentId);
        documentToRename.updateProjectDocument(documentId, metaData);

        return next;
      } catch (error) {
        console.error('Failed to updated document metadata : ', error);
        return false;
      }
    });

    const deleteDocument = flow(function* (documentId) {
      analyticsService.track('Document Removed', {
        documentId,
      });
      const rootStore = getParent(self);

      for (let page = 0; page < self.list.length; page += 1) {
        self.list[page] = filter(self.list[page], (doc) => {
          return doc.id !== documentId;
        });
      }

      return yield rootStore.projects.deleteProject(documentId);
    });

    const deleteDocumentFile = flow(function* (documentId) {
      try {
        const rootStore = getParent(self);
        const { curProjectId } = rootStore.projects;
        const next = yield documentsService.deleteDocumentFile(
          getOrgFprintFromStoreNode(self),
          curProjectId,
          documentId,
        );
        return next;
      } catch (error) {
        console.error('Failed to updated document metadata : ', error);
        return false;
      }
    });

    const addDocumentToStore = (document) => {
      if (self.list && self.list[0]) {
        self.list[0].unshift(document.identifier);
      } else {
        self.list = [[document.identifier]];
      }
    };

    const addItem = (identifier, item) => {
      const rootStore = getParent(self);
      rootStore.projects.addProject(item);
      return identifier;
    };

    const fetch = flow(function* (query = {}) {
      return yield self.paginate(query);
    });

    const addDocumentToSelectionSidebar = (document) => {
      const rootStore = getParent(self);
      rootStore.sidebarItems.add({
        ...document,
      });
    };

    return {
      fetch,
      addItem,
      addDocumentToStore,
      deleteDocument,
      updateDocument,
      updateDocumentMetadata,
      deleteDocumentFile,
      addDocumentToSelectionSidebar,
    };
  })
  .views((self) => {
    const tableList = () => {
      return self.list.flat().map((document) => ({
        id: document.id,
        fprint: document.fprint,
        title: document.title,
        updatedAt: document.updatedAt,
        docx: document.docx,
      }));
    };

    return {
      tableList,
    };
  });

const PaginatedDocumentStore = CreatePaginatedListModel(
  'PaginatedDocumentStore',
  DocumentStore,
  Project,
  { paginate: documentsService.fetch },
);

export default PaginatedDocumentStore;
