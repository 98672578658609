import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as clipboard from 'clipboardy';
import { css } from 'aphrodite';

/* Hooks */
import { useQuestionnairePublishLink } from '~/src/hooks/questionnaires';

/* Components */
import { Form, renderFormField } from '~/src/components/Forms';
import Button from '~/src/components/Button';

/* Styles */
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import { useLayoutContext } from '~/src/contexts/Layout';
import styles from './styles';

/* Constants */

const ACTION_LABEL_DEFAULT = 'Copy Link';

const PublishIntakeFormModal = (props) => {
  let copyLinkTimeout = null;

  const { intakeId, onCancel } = props;
  const [actionLabel, setActionLabel] = useState(ACTION_LABEL_DEFAULT);

  const { showToast } = useLayoutContext();

  useEffect(() => {
    return () => {
      if (copyLinkTimeout) {
        clearTimeout(copyLinkTimeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { publishLink, setMatterId } = useQuestionnairePublishLink(intakeId);

  const handleFormChange = (form) => {
    const matterId = form.fields.matter.value;

    if (matterId) {
      setMatterId(matterId);
    }
  };

  const handleCopyLink = () => {
    clipboard.write(publishLink).then(() => {
      setActionLabel('Copied');

      copyLinkTimeout = setTimeout(() => {
        setActionLabel(ACTION_LABEL_DEFAULT);
      }, 3000);

      showToast(LAYOUT_TOAST_TYPES.success, {
        message: 'Link copied to clipboard!',
      });
    });
  };

  const fields = [
    {
      label: 'Link',
      id: 'link',
      hint: 'The public link to your questionnaire',
      type: 'copyLink',
      target: '_blank',
      defaultValue: publishLink,
      onClick: handleCopyLink,
    },
    {
      label: 'Choose a matter',
      id: 'matter',
      type: 'matterSelect',
      hint: 'Otherwise a new matter will be created on submission',
      isCreatable: false,
      validation: () => {
        return true;
      },
    },
  ];

  const fieldsProp = fields.map((field) => {
    const fieldProps = { ...field, editable: true };
    const customProps = {};
    return renderFormField(fieldProps, customProps);
  });

  return (
    <div
      className={css(styles.modalContainer)}
      // qid={intakeId}
      // lid={publishLink}
    >
      <h1 className={css(styles.modalTitle)}>Publish questionnaire</h1>
      <div className={css(styles.modalBody)}>
        <Form triggerOnMount fields={fieldsProp} onChange={handleFormChange} />
      </div>
      <div className={css(styles.modalActions)}>
        <Button cssStyle={styles.buttonAction} onClick={onCancel}>
          Close
        </Button>{' '}
        <Button
          analyticsname="Copy Link to Questionnaire"
          cssStyle={styles.buttonAction}
          onClick={handleCopyLink}
          primary
        >
          {actionLabel}
        </Button>
      </div>
    </div>
  );
};

PublishIntakeFormModal.propTypes = {
  onCancel: PropTypes.func,
};

PublishIntakeFormModal.defaultProps = {
  onCancel: () => {},
  onConfirm: () => {},
};

export default PublishIntakeFormModal;
