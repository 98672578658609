import { useMutation, useQueryClient } from 'react-query';
import { APIError } from '~/src/utils/error';
import { getRelatedQuestionnaireSubmissionIdUrl } from '../urls';
import { questionnaireQueryArgs } from '../queryArgs';
import { RelatedQuestionnaireSubmission } from '~/src/entities/questionnairesV2/types';
import { getRelatedQuestionnaireSubmissionsByQuestionnaireSubmissionIdUrl } from '~/src/entities/questionnairesV2/urls';

type Variables = {
  qs: {
    id: number;
    title?: string;
    project_id?: number;
  };
  title: string;
};

export const useRenameRelatedQuestionnaireSubmission = (orgFprint: string) => {
  const queryClient = useQueryClient();
  return useMutation<RelatedQuestionnaireSubmission, APIError, Variables>({
    onSuccess: () => {
      queryClient.invalidateQueries(questionnaireQueryArgs.all);
    },
    mutationFn: async (payload) => {
      const relatedQuestionnaireSubmissionsResponse = await fetch(
        getRelatedQuestionnaireSubmissionsByQuestionnaireSubmissionIdUrl(
          orgFprint,
          payload.qs.id,
        ),
      );

      const relatedQuestionnaireSubmissions =
        await relatedQuestionnaireSubmissionsResponse.json();

      if (
        relatedQuestionnaireSubmissions &&
        relatedQuestionnaireSubmissions.length > 0 &&
        relatedQuestionnaireSubmissions[0] !== undefined
      ) {
        const patchPayload = {
          requestPayload: {},
          id: relatedQuestionnaireSubmissions[0].id,
          title: payload.title,
        };

        const response = await fetch(
          getRelatedQuestionnaireSubmissionIdUrl(orgFprint, patchPayload.id),
          {
            method: 'PATCH',
            body: JSON.stringify(patchPayload),
          },
        );
        if (!response.ok) {
          throw new APIError(
            'Failed to update(rename) related questionnaire submission in Draft',
            response,
          );
        }
        return response.json();
      }
    },
  });
};
