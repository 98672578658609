import React from 'react';
import { Alert, Box, Collapse, IconButton } from '@mui/material';
import { X } from 'react-feather';

export const BannerWrapper = ({
  open,
  onClose,
  children,
}: {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}) => {
  return (
    <Box marginBottom={'16px'}>
      <Collapse
        in={open}
        sx={{
          minHeight: 'auto !important',
        }}
      >
        <Alert
          variant="filled"
          severity={'info'}
          icon={false}
          action={
            <IconButton aria-label="close" size="small" onClick={onClose}>
              <X size={16} />
            </IconButton>
          }
          sx={(theme) => ({
            backgroundColor: theme.palette.blue[100],
          })}
        >
          {children}
        </Alert>
      </Collapse>
    </Box>
  );
};
