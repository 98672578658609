import React from 'react';
import { useLogoutEmulatedUser } from '~/src/entities/user';
import cookies from '~/src/utils/cookies';

export const EmulationBanner = () => {
  const adminTokensExist = cookies.adminTokensExist();
  const { mutate } = useLogoutEmulatedUser();

  if (!adminTokensExist) return null;

  return (
    <h2>
      You are currently emulating a user.{' '}
      <a href="#" onClick={(e) => (e.preventDefault(), mutate())}>
        Stop Emulating
      </a>
    </h2>
  );
};
