/* Libs */
import React, { useState } from 'react';
import { css } from 'aphrodite';

/* Components */
import { Container, Body, Header, Footer } from '~/src/components/SlideIn';
import Button from '~/src/components/Button';
import { TextInput, TextArea } from '~/src/components/Inputs';

/* Styles */
import { default as slideInStyles } from '~/src/components/SlideIn/styles';

const QuestionInstructionalContentSlideIn = (props) => {
  const [description, setDescription] = useState(props.description);
  const [videoUrl, setVideoUrl] = useState(props.videoUrl);

  const handleDescriptionChange = (value) => setDescription(value);
  const handleVideoUrlChange = (value) => setVideoUrl(value);

  const handleCancel = props.onCancel || props.hideSlideIn;
  const handleConfirm = () => {
    if (props.onConfirm) {
      props.onConfirm({ description, videoUrl });
    }

    props.hideSlideIn();
  };

  return (
    <Container>
      <Header title={`Add Instructions`} />
      <Body>
        <div className={css(slideInStyles.innerBody)}>
          <TextArea
            label="Description"
            value={description}
            onChange={handleDescriptionChange}
          />
          <TextInput
            label="Video Url"
            placeholder="Paste your Youtube or Vimeo link here"
            value={videoUrl}
            onChange={handleVideoUrlChange}
          />
        </div>
      </Body>
      <Footer alignRight>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button
          onClick={handleConfirm}
          cssStyle={slideInStyles.footerButton}
          primary
        >
          Confirm
        </Button>
      </Footer>
    </Container>
  );
};

export default QuestionInstructionalContentSlideIn;
