import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

export default StyleSheet.create({
  text: {
    fontSize: theme.fonts.size.small,
    marginLeft: theme.unit,
  },
  textDisabled: {
    color: theme.colors.chrome,
  },
});
