import { Typography } from '@mui/material';
import * as React from 'react';

type CardListItemMetaTextProps = {
  children: React.ReactNode;
};

export const CardListItemMetaText = ({
  children,
}: CardListItemMetaTextProps) => (
  <Typography
    component={'span'}
    fontSize={12}
    sx={(theme) => ({ color: theme.palette.text.secondary })}
  >
    {children}
  </Typography>
);
