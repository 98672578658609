import React from 'react';
import { Box } from '@mui/material';

export const ListViewItemWrapper: React.FC = ({ children }) => (
  <Box
    sx={(theme) => ({
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(0.5),
      alignSelf: 'stretch',
      background: 'white',
      padding: theme.spacing(1),
      borderRadius: theme.spacing(1),
    })}
  >
    {children}
  </Box>
);
