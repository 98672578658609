import React from 'react';

/* Components */
import {
  ToasterLayout,
  SuccessToast,
  ErrorToast,
  LoadingToast,
} from '~/src/components/Toasts';
import { useLayoutContext } from '~/src/contexts';

export const LAYOUT_TOAST_TYPES = {
  success: 'SUCCESS',
  error: 'ERROR',
  loading: 'LOADING',
} as const;

export type ToastType =
  typeof LAYOUT_TOAST_TYPES[keyof typeof LAYOUT_TOAST_TYPES];

export const TOASTS: Record<ToastType, React.ElementType> = {
  [LAYOUT_TOAST_TYPES.success]: SuccessToast,
  [LAYOUT_TOAST_TYPES.error]: ErrorToast,
  [LAYOUT_TOAST_TYPES.loading]: LoadingToast,
};

const Toasts = () => {
  const {
    toastActive: active,
    hideToast: hide,
    toastProps: props,
    toastType: type,
  } = useLayoutContext();
  const ToastChild = type && TOASTS[type] ? TOASTS[type] : null;

  return (
    <ToasterLayout toastDelay={props.toastDelay} show={active} onCancel={hide}>
      {ToastChild && <ToastChild onCancel={hide} {...props} />}
    </ToasterLayout>
  );
};

export default Toasts;
