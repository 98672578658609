import { getFieldErrorKey } from '~/src/utils/general';
import { startCase } from 'lodash';
import { VALIDATION_TYPES, validations } from '~/src/utils/validation';
import {
  CONTACT_REQUIRED_FIELDS,
  FORM_EMAIL_FIELD_ERROR_MESSAGE,
  FORM_FIELD_REQUIRED_ERROR_MESSAGE,
} from '~/src/utils/constants';
import { ORGANIZATION_TYPE } from '~/src/stores/contactsStore';

const validateContactFields = (
  contact_form_values,
  addToInvalidFormFields,
  removeFromInvalidFormFields,
  contactType = '',
) => {
  Object.keys(contact_form_values.fields).forEach((key) => {
    // Validate required fields
    if (CONTACT_REQUIRED_FIELDS.includes(key)) {
      const field_error_key = getFieldErrorKey(
        key,
        FORM_FIELD_REQUIRED_ERROR_MESSAGE,
      );
      let field_key = startCase(key);

      const isOrgContact =
        contactType === ORGANIZATION_TYPE ||
        (contact_form_values.fields['type'] &&
          contact_form_values.fields['type'].value === ORGANIZATION_TYPE);

      if (isOrgContact && key === 'lastName') {
        field_key = 'Name'; // As of now the Organization name is mapped into the lastName field in the backend.
      }

      const item = {
        field_key: field_key,
        error: FORM_FIELD_REQUIRED_ERROR_MESSAGE,
      };
      if (contact_form_values.fields[key].value.length === 0) {
        addToInvalidFormFields(field_error_key, item);
      } else {
        removeFromInvalidFormFields(field_error_key);
      }
    }

    if (key === 'email') {
      const value = contact_form_values.fields[key].value;
      const valid = validations[VALIDATION_TYPES.email]?.({
        value: value,
        touched: true,
      });
      const field_error_key = getFieldErrorKey(
        key,
        FORM_EMAIL_FIELD_ERROR_MESSAGE,
      );
      const item = {
        field_key: startCase(key),
        error: FORM_EMAIL_FIELD_ERROR_MESSAGE,
      };
      if (!valid && value !== '') {
        addToInvalidFormFields(field_error_key, item);
      } else {
        removeFromInvalidFormFields(field_error_key);
      }
    }
  });
};

export { validateContactFields };
