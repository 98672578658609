import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { history } from '~/src/utils/history';

import {
  QuestionnaireBuilderPage,
  ClioQuestionnairePage,
  ClioQuestionnaireSelectDocumentsPage,
  QuestionnaireSubmissionPage,
  QuestionnaireAutoGeneration,
} from '~/src/pages/ClioQuestionnaire';

const ClioQuestionnaires: React.FC = () => {
  return (
    <Switch>
      <Redirect exact from="/questionnaires" to="/questionnaires/templates" />
      <Route
        path={'/questionnaires/:questionnaireId/select-documents'}
        render={({ match }) => (
          <ClioQuestionnaireSelectDocumentsPage
            questionnaireId={match.params.questionnaireId}
          />
        )}
      />
      <Route
        path={'/questionnaires/:questionnaireId/auto-generate'}
        render={({ match }) => (
          <QuestionnaireAutoGeneration
            questionnaireId={match.params.questionnaireId}
          />
        )}
      />
      <Route
        path={'/questionnaires/:questionnaireId/builder'}
        render={({ match, location }) => {
          const params = new URLSearchParams(location.search);
          const isAutoGenerated =
            params.get('isAutoGenerated')?.toLowerCase() === 'true';
          const hasAutoGenerateErrors =
            params.get('hasAutoGenerateErrors')?.toLowerCase() === 'true';

          return (
            <QuestionnaireBuilderPage
              questionnaireId={match.params.questionnaireId}
              isAutoGenerated={isAutoGenerated}
              hasAutoGenerateErrors={hasAutoGenerateErrors}
            />
          );
        }}
      />
      <Route
        path={'/questionnaires/:questionnaireId/responses/:submissionId'}
        render={({ match }) => (
          <QuestionnaireSubmissionPage
            onExit={() => {
              history.push('/questionnaires/responses');
            }}
            submissionId={match.params.submissionId}
            questionnaireId={match.params.questionnaireId}
          />
        )}
      />
      <ClioQuestionnairePage />
    </Switch>
  );
};

export default ClioQuestionnaires;
