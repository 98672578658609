import React from 'react';
import { Box, Typography } from '@mui/material';
import {
  CardListItemWithActions,
  CardListItemMetaText,
} from '~/src/components/CardList';
import { Copy, Edit, MoreVertical, Trash, Type } from 'react-feather';
import { type Questionnaire } from '@clio/questionnaire-builder';
import { ReactComponent as NavQuestionnairesIcon } from '~/src/static/nav-questionnaires-black.svg';
import moment from 'moment';
import { QuestionnaireMetadataRow } from './QuestionnaireMetadataRow';

export const QuestionnaireTableCardList: React.FC<{
  questionnaires: Array<Questionnaire & { total_questions: number }>;
  handleEdit: (questionnaire: Questionnaire) => void;
  handleRename: (questionnaire: Questionnaire) => void;
  handleDuplicate: (questionnaire: Questionnaire) => void;
  handleShare: (
    questionnaire: Questionnaire & { total_questions: number },
  ) => void;
  handleDelete: (questionnaire: Questionnaire) => void;
}> = ({
  questionnaires,
  handleEdit,
  handleRename,
  handleDuplicate,
  handleShare,
  handleDelete,
}) => {
  return (
    <>
      {questionnaires.map((questionnaire) => {
        const actions = [
          {
            label: 'Edit',
            icon: <Edit size={16} />,
            onClick: () => handleEdit(questionnaire),
          },
          {
            label: 'Rename',
            icon: <Type size={16} />,
            onClick: () => handleRename(questionnaire),
          },
          {
            label: 'Duplicate',
            icon: <Copy size={16} />,
            onClick: () => handleDuplicate(questionnaire),
          },
          {
            label: 'Delete',
            icon: <Trash size={16} />,
            onClick: () => handleDelete(questionnaire),
          },
        ];

        return (
          <Box pb={1} key={questionnaire.id}>
            <CardListItemWithActions
              key={questionnaire.id}
              onClick={() => handleEdit(questionnaire)}
              title={{
                label: (
                  <Box display={'flex'} gap={1} alignItems={'center'}>
                    <NavQuestionnairesIcon />
                    <Typography variant="button">
                      {questionnaire.title}
                    </Typography>
                  </Box>
                ),
                meta: [
                  <QuestionnaireMetadataRow
                    key={questionnaire.id}
                    totalQuestions={questionnaire.total_questions}
                    questionnaireId={String(questionnaire.id)}
                  />,
                ],
              }}
              meta={
                <Box display={'flex'} gap={1} alignItems={'center'}>
                  <CardListItemMetaText>
                    Last updated:{' '}
                    {moment(questionnaire.updated_at).format('MM/DD/YYYY')}
                  </CardListItemMetaText>
                  <MoreVertical size={16} />
                </Box>
              }
              actions={{
                actions,
                primary: {
                  label: 'Share',
                  variant: 'outlined',
                  disabled: false,
                  onClick: () =>
                    handleShare(
                      questionnaire as Questionnaire & {
                        total_questions: number;
                      },
                    ),
                },
              }}
            />
          </Box>
        );
      })}
    </>
  );
};
