import { isObject, camelCase, snakeCase, isArray, isString } from 'lodash';

export const keyValuesToCamelCase = (o) => {
  if (isArray(o)) {
    return o.map((item) => {
      return keyValuesToCamelCase(item);
    });
  }
  if (isObject(o)) {
    const n = {};
    Object.keys(o).forEach((key) => {
      n[camelCase(key)] = keyValuesToCamelCase(o[key]);
    });
    return n;
  }
  if (isString(o)) {
    return camelCase(o);
  }

  return o;
};

export const keysToCamel = (o) => {
  if (isArray(o)) {
    return o.map((item) => {
      return keysToCamel(item);
    });
  }
  if (isObject(o)) {
    const n = {};
    Object.keys(o).forEach((key) => {
      n[camelCase(key)] = keysToCamel(o[key]);
    });
    return n;
  }

  return o;
};

export const keysToSnakeCase = (o) => {
  if (isArray(o)) {
    return o.map((item) => {
      return keysToSnakeCase(item);
    });
  }
  if (isObject(o)) {
    const n = {};
    Object.keys(o).forEach((key) => {
      n[snakeCase(key)] = keysToSnakeCase(o[key]);
    });
    return n;
  }

  return o;
};
