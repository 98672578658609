import { useMutation, UseMutationOptions } from 'react-query';
import { APIError } from '~/src/utils/error';
import { useCurrentOrgFprint } from '../../user';
import { composeMutationOpts, fetcher, useInvalidateQuery } from '../../utils';
import { keys } from '../keys';
import { getCancelSubscriptionReasonUrl, getSubscriptionUrl } from '../urls';

export const CANCELLATION_REASONS = [
  {
    name: 'technicalIssues',
    label: 'Technical issues',
  },
  {
    name: 'trainingMaterial',
    label: 'Not enough training material',
  },
  {
    name: 'noBuyIn',
    label: 'Did not get buy-in from colleagues',
  },
  {
    name: 'shuttingDown',
    label: 'Shutting down or switching practice',
  },
  {
    name: 'missingFeatures',
    label: 'Missing features I need',
  },
  {
    name: 'tooComplicated',
    label: 'Too complicated to learn',
  },
  {
    name: 'switchedProducts',
    label: 'Switching to another product',
  },
  {
    name: 'timeConsuming',
    label: 'Initial setup seems too time consuming',
  },
  {
    name: 'customerService',
    label: "Didn't get enough support from customer service",
  },
] as const;

export type CancellationReason = typeof CANCELLATION_REASONS[number]['name'];

export type CancellationReasons = Record<CancellationReason, boolean> & {
  other?: string;
};

export const useCancelSubscription = (
  options: UseMutationOptions<
    void,
    APIError,
    { reasons: CancellationReasons }
  > = {},
) => {
  const orgFprint = useCurrentOrgFprint();
  return useMutation(
    composeMutationOpts(options, {
      onSuccess: useInvalidateQuery(keys.subscription),
      mutationFn: async ({ reasons }) => {
        fetch(getCancelSubscriptionReasonUrl(orgFprint), {
          method: 'POST',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify(reasons),
        });
        await fetcher(getSubscriptionUrl(orgFprint), { method: 'DELETE' });
      },
    }),
  );
};
