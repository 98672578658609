import { Box, Typography } from '@mui/material';
import React from 'react';

type PlanCardProps = {
  label: string;
  description?: React.ReactNode;
  action?: React.ReactNode;
};

export const PlanCard = ({ label, description, action }: PlanCardProps) => {
  return (
    <Box
      sx={(theme) => ({
        border: `1px solid ${theme.palette.border}`,
        borderRadius: `${theme.shape.borderRadius}px`,
      })}
      p={2}
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <Box>
        <Typography variant={'label'}>{label}</Typography>
        {description ? <Box>{description}</Box> : null}
      </Box>
      {action}
    </Box>
  );
};
