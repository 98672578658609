/* Hooks */
import useMst from '~/src/hooks/useMst';

/* Constants */
import { LAYOUT_SLIDEIN_TYPES } from '~/src/components/PageLayout/SlideIns';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import { useLayoutContext } from '../contexts/Layout';

const useContactsActions = () => {
  const { showToast, showSlideIn } = useLayoutContext();

  const { viewContact, deleteContact } = useMst((store) => ({
    viewContact: store.contacts.viewContact,
    deleteContact: store.contacts.deleteContact,
  }));

  const handleViewContact = ({ id }) => {
    viewContact(id);
    showSlideIn(LAYOUT_SLIDEIN_TYPES.contact, { edit: false, id });
  };

  const handleEditContact = ({ id }) => {
    const handleSuccess = () => {
      showToast(LAYOUT_TOAST_TYPES.success, { message: `Contact updated` });
    };

    showSlideIn(LAYOUT_SLIDEIN_TYPES.contact, {
      edit: true,
      id,
      onSave: handleSuccess,
    });
  };

  const handleCreateContact = () => {
    const handleSuccess = () => {
      showToast(LAYOUT_TOAST_TYPES.success, { message: `Contact created` });
    };

    showSlideIn(LAYOUT_SLIDEIN_TYPES.contactCreate, {
      onCreate: handleSuccess,
    });
  };

  const handleDeleteContact = ({ id }) => {
    deleteContact(id);
    showToast(LAYOUT_TOAST_TYPES.success, { message: `Contact deleted` });
  };

  return {
    onViewContact: handleViewContact,
    onEditContact: handleEditContact,
    onCreateContact: handleCreateContact,
    onDeleteContact: handleDeleteContact,
  };
};

export default useContactsActions;
