import React, { useState } from 'react';

import { ReactComponent as IconError } from '~/src/static/icon-error.svg';
import { ReactComponent as CloseIcon } from '~/src/static/close-icon.svg';

interface ErrorMessageProps {
  title: string;
  message: string;
}

const ErrorMessage = ({ title, message }: ErrorMessageProps) => {
  const [showError, setShowError] = useState(true);
  const closeErrorMessage = () => setShowError(false);

  if (!showError) return null;

  return (
    <div className="p-3 border-t">
      <div
        className="bg-red-50 border border-red-400 p-4 rounded-md relative"
        role="alert"
      >
        <div className="flex">
          <div className="flex-none">
            <IconError />
          </div>
          <div className="flex-1 ml-4">
            <p className="font-bold">{title}</p>
            <p className="text-sm mt-2">{message}</p>
          </div>
          <div
            className="absolute top-0 bottom-0 right-0 px-4 py-3"
            role="button"
            onClick={closeErrorMessage}
          >
            <CloseIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorMessage;
