import React, { FC } from 'react';
import { SignaturePackage, WhoSigns } from '~/src/models';
import { SignersButtons } from './SignersButtons';
import SignersInput from './SignersInput';

interface Props {
  whoSigns: WhoSigns;
  setWhoSigns: React.Dispatch<React.SetStateAction<WhoSigns>>;
  currentUserInfo: SignaturePackage.LeanRecipient;
  recipients: SignaturePackage.LeanRecipient[];
  setRecipients: React.Dispatch<
    React.SetStateAction<SignaturePackage.LeanRecipient[]>
  >;
  enableRecipientsDragDrop: boolean;
  setEnableRecipientsDragDrop: React.Dispatch<React.SetStateAction<boolean>>;
  validateRecipientsInput: (
    nextRecipients?: SignaturePackage.LeanRecipient[],
  ) => void;
}

const ChooseSigners: FC<Props> = ({
  whoSigns,
  setWhoSigns,
  currentUserInfo,
  recipients,
  setRecipients,
  enableRecipientsDragDrop,
  setEnableRecipientsDragDrop,
  validateRecipientsInput,
}) => {
  return (
    <>
      <SignersButtons
        whoSigns={whoSigns}
        onChange={(who) => setWhoSigns(who)}
      />
      <SignersInput
        whoSigns={whoSigns}
        currentUserInfo={currentUserInfo}
        enableRecipientsDragDrop={enableRecipientsDragDrop}
        setEnableRecipientsDragDrop={setEnableRecipientsDragDrop}
        recipients={recipients}
        setRecipients={setRecipients}
        validateRecipientsInput={validateRecipientsInput}
      />
    </>
  );
};

export default ChooseSigners;
