import React from 'react';
import { Clipboard } from 'react-feather';

import Card from '~/src/components/Card';
import ReactPlayer from 'react-player';
import ContactUsButton from '~/src/components/ContactUsButton';
import theme from '~/src/theme';

const CardHeader = () => {
  return (
    <h3 style={{ display: 'flex', alignItems: 'center', margin: '0px' }}>
      <Clipboard color={theme.colors.bluebird} size={22} />
      <span
        style={{
          marginLeft: '16px',
          textTransform: 'uppercase',
          color: theme.colors.bluebird,
          fontSize: '14px',
        }}
      >
        Questionnaires
      </span>
    </h3>
  );
};

const CardFooter = () => {
  const utmCampaign = 'questionnaires';
  return (
    <div>
      <ContactUsButton
        utmCampaign={utmCampaign}
        routeToLink={`https://www.lawyaw.com/contact-us/?utm_source=clio_free_tier_upgrade&utm_campaign=${utmCampaign}`}
      />
    </div>
  );
};

const QuestionnairesGated = () => {
  return (
    <div style={{ marginTop: 24 }}>
      <Card elevate renderHeader={CardHeader} renderFooter={CardFooter}>
        <h1
          style={{
            marginBottom: '16px',
            fontSize: '18px',
            lineHeight: '1.5em',
          }}
        >
          Send out Questionnaire forms that populate client and matter
          information directly into your templates
        </h1>
        <ReactPlayer
          url="https://lawyaw-3.wistia.com/medias/sjmu4h600z"
          width={510}
          height={286.875}
        />
        <p style={{ lineHeight: '1.5em', marginTop: '16px' }}>
          Upgrade your plan to access questionnaires, template sets, signatures
          and more.
        </p>
      </Card>
    </div>
  );
};

export default QuestionnairesGated;
