import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';

import { CheckCircle } from 'react-feather';

import theme from '~/src/theme';
import styles from './styles';

const CheckListItem = ({ valid, label }) => {
  const color = valid ? theme.colors.tennisBall : theme.colors.chrome;

  return (
    <li className={css(styles.checkListItem)}>
      <i className={css(styles.checkListItemIcon)}>
        <CheckCircle color={color} size={theme.fonts.size.small} />
      </i>{' '}
      {label}
    </li>
  );
};

CheckListItem.propTypes = {
  valid: PropTypes.bool,
  label: PropTypes.string,
};

CheckListItem.defaultProps = {
  valid: false,
  label: 'Check List Item',
};

const ValidationCheckList = ({ list }) => {
  const renderListItem = ({ label, valid }, index) => (
    <CheckListItem key={index} label={label} valid={valid} />
  );
  return <ul className={css(styles.checkList)}>{list.map(renderListItem)}</ul>;
};

ValidationCheckList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      valid: PropTypes.bool,
    }),
  ),
};

ValidationCheckList.defaultProps = {
  list: [],
};

export default ValidationCheckList;
