import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AddDocumentContainer from '~/src/containers/AddDocumentContainer';

const AddDocumentRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/add-document/:projectId"
        render={(props) => {
          const { projectId } = props.match.params;
          return <AddDocumentContainer projectId={parseInt(projectId)} />;
        }}
      ></Route>
    </Switch>
  );
};

export default AddDocumentRoutes;
