import { types, flow } from 'mobx-state-tree';

/* Utilities */
import { getQuestionnaireResponseIdentifier } from '~/src/utils/dataTransformers';

/* Services */
import questionnairesService from '~/src/services/questionnaires';
import { getOrgFprintFromStoreNode } from './utils';

const QuestionnaireResponseAnswer = types.model('QuestionnaireResponseAnswer', {
  id: types.string,
  questionId: types.string,
  answerText: types.string,
  questionText: types.string,
  questionHint: types.string,
  questionData: types.frozen(),
  type: types.string,
  tag: types.maybeNull(types.string),
});

const QuestionnaireResponseAnswerGroup = types.model(
  'QuestionnaireResponseAnswerGroup',
  {
    id: types.string,
    sortOrder: types.integer,
    name: types.string,
    answers: types.optional(types.array(QuestionnaireResponseAnswer), []),
  },
);

const QuestionnaireResponse = types.model('QuestionnaireResponse', {
  id: types.string,
  identifier: types.identifier,
  email: types.maybeNull(types.string),
  phoneNumber: types.maybeNull(types.string),
  questionnaireId: types.string,
  matterId: types.string,
  name: types.maybeNull(types.string),
  sentAt: types.maybeNull(types.string),
  startedAt: types.string,
  submittedAt: types.string,
  answers: types.optional(types.array(QuestionnaireResponseAnswer), []),
  answerGroups: types.optional(
    types.array(QuestionnaireResponseAnswerGroup),
    [],
  ),
});

const QuestionnaireResponses = types
  .model('QuestionnaireResponses', {
    dictionary: types.optional(types.map(QuestionnaireResponse), {}),
  })
  .actions((self) => {
    const updateQuestionnaireResponse = flow(
      function* updateQuestionnaireResponse(id, propertiesToUpdate) {
        try {
          return yield questionnairesService.updateQuestionnaireResponse(
            getOrgFprintFromStoreNode(self),
            id,
            propertiesToUpdate,
          );
        } catch (error) {
          console.error('Failed to update questionnaire response ', error);
        }
      },
    );

    const fetchQuestionnaireResponse = flow(
      function* fetchQuestionnaireResponse(email) {
        try {
          const res = yield questionnairesService.fetchQuestionnaireResponse(
            getOrgFprintFromStoreNode(self),
            email,
          );

          self.addQuestionnaireResponse(res);
          return res;
        } catch (error) {
          console.error('Error fetching questionnaire response ', error);
        }
      },
    );

    const addQuestionnaireResponse = (questionnaire) => {
      self.dictionary.set(questionnaire.identifier, questionnaire);
    };

    const getQuestionnaireResponse = (id) => {
      return self.dictionary.get(getQuestionnaireResponseIdentifier(id));
    };

    return {
      updateQuestionnaireResponse,
      fetchQuestionnaireResponse,
      addQuestionnaireResponse,
      getQuestionnaireResponse,
    };
  });

export { QuestionnaireResponses as default, QuestionnaireResponse };
