import React from 'react';
import Button from '~/src/components/Button';
import { css } from 'aphrodite';

import styles from './styles';

const ClioDisconnectConfirmModal = ({ onCancel, onConfirm }) => (
  <div className={css(styles.modalContainer)}>
    <h1 className={css(styles.modalTitle)}>Disconnect Clio</h1>
    <p className={css(styles.modalMessage, styles.modalMessageCenterVertical)}>
      You are about to disconnect your Clio Integration. No data is going to be
      removed.
    </p>
    <p className={css(styles.modalMessage, styles.modalMessageCenterVertical)}>
      {'Are you sure about that?'}
    </p>
    <div className={css(styles.modalActions)}>
      <Button cssStyle={styles.buttonAction} onClick={onCancel}>
        Cancel
      </Button>{' '}
      <Button cssStyle={styles.buttonAction} primary onClick={onConfirm}>
        Confirm
      </Button>
    </div>
  </div>
);

export default ClioDisconnectConfirmModal;
