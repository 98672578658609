import React, { useEffect, useState } from 'react';
import { css } from 'aphrodite';

import styles, { ANIMATION_DURATION, customizeOpacity } from './styles';

const Overlay = ({ show, alpha, children }) => {
  const [showOverlay, setShowOverlay] = useState(show);

  useEffect(() => {
    setTimeout(() => {
      setShowOverlay(show);
    }, ANIMATION_DURATION);
  }, [show]);

  if (!showOverlay) {
    return null;
  }

  return (
    <div
      className={css(
        styles.overlay,
        show && styles.overlayShow,
        !show && styles.overlayHidden,
        alpha && customizeOpacity(alpha),
      )}
    >
      {children}
    </div>
  );
};

export default Overlay;
