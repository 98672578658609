/* Libraries */
import * as React from 'react';
import { useObserver } from 'mobx-react';

/* Components */
import { LoadingOverlay } from '~/src/components/PageLayout';
import { useStore } from '../hooks/useMst';
import { useEffect } from 'react';

const Auth = ({ children }: { children: React.ReactElement }) => {
  const store = useStore();

  useEffect(() => {
    store.initialize();
  }, [store]);

  return useObserver(() =>
    store.initializing ? (
      <LoadingOverlay title="Loading your data..." relative={false} />
    ) : (
      children
    ),
  );
};

export default Auth;
