import React from 'react';
import { useMissingFields } from '../../useMissingFields';
import { MissingFieldsChip } from './MissingFields';
import { PopulatedChip } from './Populated';

export const useCardChip = (fields: string[]) => {
  const numMissing = useMissingFields(fields).length;

  if (!numMissing) return <PopulatedChip />;

  if (numMissing === fields.length) return null;

  return <MissingFieldsChip number={numMissing} />;
};
