import React from 'react';
import { Button, Typography, Box } from '@mui/material';
import {
  CONTENT_PACKAGE,
  getPlanPrice,
  PlanCard,
} from '~/src/entities/subscription';

type ContentPackagePlanCardProps = {
  value: boolean;
  onChange: (value: boolean) => void;
};

export const ContentPackagePlanCard = ({
  value,
  onChange,
}: ContentPackagePlanCardProps) => {
  return (
    <Box>
      <PlanCard
        label={'NYSBA Surrogate forms'}
        description={
          <Typography variant={'body1'}>
            {getPlanPrice(CONTENT_PACKAGE[0]!.price, 'annual')}
          </Typography>
        }
        action={
          <Button
            data-testid={'content-pkg-button'}
            variant={value ? 'outlined' : 'contained'}
            color={value ? 'error' : 'primary'}
            onClick={() => {
              value = !value;
              onChange(value);
            }}
          >
            {value ? 'Remove' : 'Add'}
          </Button>
        }
      />
    </Box>
  );
};
