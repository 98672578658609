import React, { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import PageLayout, { LoadingOverlay } from '~/src/components/PageLayout';
import { useSnackbar } from 'notistack';
import { useIsCanary } from '~/src/entities/user/hooks/useFeatureAccess';
import useFeatureAccesses from '~/src/hooks/useFeatureAccesses';
import {
  useUpdateProject,
  useProjectAsyncUpdateState,
} from '~/src/entities/project/hooks';

import {
  useProject,
  transformProjectFields,
  unsanitizeProjectRecord,
} from '~/src/entities/project';
import { PopulatePageContent } from './PopulatePageContent';
import { PopulatePageFooter } from './PopulatePageContent/PopulatePageFooter';
import { useUIFlagEnabled } from '~/src/entities/uiFlags';
import { delayMilliseconds } from '~/src/utils/general';
import analyticsService from '~/src/services/analytics';
import { useHistory } from 'react-router-dom';

type PopulatePageV2Props = {
  projectId: number;
};

export const PopulatePageV2 = ({ projectId }: PopulatePageV2Props) => {
  const history = useHistory();
  const isCanary = useIsCanary();
  const { data: projectAsyncUpdateState } = useProjectAsyncUpdateState(
    projectId,
    isCanary,
  );
  const canaryDependanciesMet =
    (isCanary && projectAsyncUpdateState?.isSettled) || !isCanary;
  const hasCanaryDependancyError =
    isCanary && projectAsyncUpdateState?.hasError === true;

  const projectQuery = useProject(projectId, {
    cacheTime: 0,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: !isCanary || canaryDependanciesMet,
  });
  const featureAccesses = useFeatureAccesses();
  const isProgressTrackerEnabled = !!(
    useUIFlagEnabled('progressTracker') && featureAccesses['isUnifiedPlatform']
  );
  const [isSaving, setIsSaving] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const { mutateAsync: updateProject } = useUpdateProject();
  const snackbar = useSnackbar();

  const isDoneLoading = !projectQuery.isLoading && canaryDependanciesMet;

  const onBack = () => history.push('/clients');

  const pageProps = {
    showNavigation: false,
    headerProps: {
      showCloseButton: true,
      showAvatar: false,
      onClose: () => {
        analyticsService.track('Back Clicked', {
          page: 'populate_page',
          location: 'header',
        });
        onBack();
      },
    },
  };

  const projectData = useMemo(() => {
    if (!projectQuery.data) return null;
    return transformProjectFields(projectQuery.data);
  }, [projectQuery.data]);

  const methods = useForm({
    values: projectData ? projectData.stack_saved_data : {},
  });

  const onGenerate = async () => {
    try {
      setIsGenerating(true);

      /* 
      Pause momentarily because there is a slight delay for the change on the screen
      to be reflected in the internal state of react-hook-form.
      This is to prevent the last change on the screen from being missing in the
      internal state of react-hook-form when the generate button is clicked
      (i.e. when the payload for API call is retreieved from the internal state of react-hook-form)
      */
      await delayMilliseconds(1000);

      await updateProject({
        projectId,
        stack_saved_data: unsanitizeProjectRecord(methods.getValues()),
      });
      history.push(`/drafting/${projectId}`);
    } catch (error) {
      snackbar.enqueueSnackbar(
        'Something went wrong, please try again later.',
        {
          variant: 'error',
        },
      );
      setIsGenerating(false);
    }
  };

  const noErrors = projectData && !hasCanaryDependancyError;

  if (noErrors && isDoneLoading)
    return (
      <PageLayout
        title={projectData.title}
        disablePadding
        {...pageProps}
        footer={
          <PopulatePageFooter
            isGenerating={isGenerating}
            isSaving={isSaving}
            onCancel={() => {
              analyticsService.track('Back Clicked', {
                page: 'populate_page',
                location: 'footer',
              });
              onBack();
            }}
            onGenerate={onGenerate}
          />
        }
      >
        <FormProvider {...methods}>
          <PopulatePageContent
            methods={methods}
            project={projectData}
            isClioQuestionnaireEnabled={
              !!featureAccesses.isClioQuestionnaireEnabled
            }
            isProgressTrackerEnabled={isProgressTrackerEnabled}
            onSaving={() => setIsSaving(true)}
            onSaved={() => setIsSaving(false)}
            onGenerate={onGenerate}
            isGenerating={isGenerating}
          />
        </FormProvider>
      </PageLayout>
    );

  if (!isDoneLoading) {
    return (
      <PageLayout {...pageProps}>
        <LoadingOverlay title="Loading details" subtitle="Please wait" />
      </PageLayout>
    );
  }

  return <PageLayout {...pageProps}>Error</PageLayout>;
};
