/**
 * Basic 'sortBy' field options.
 */
// eslint-disable-next-line no-shadow
export enum SortByOption {
  /**
   * Sorts by the last modified timestamp of the model.
   */
  lastModified = 'last_modified',
  /**
   * Sorts by the last create at timestamp of the model.
   */
  createdAt = 'created_at',
}

export default SortByOption;
