import { Close } from '@mui/icons-material';
import {
  Box,
  DialogProps,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  IconButton,
  Alert,
} from '@mui/material';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { NameFormContent, NameFormValues } from './NameForm';

export type UpdateNameModalProps = {
  initialValues: Partial<NameFormValues>;
  handleUpdate: (values: NameFormValues) => Promise<boolean>;
} & DialogProps;

export const UpdateNameModal = ({
  initialValues,
  handleUpdate,
  ...props
}: UpdateNameModalProps) => {
  const form = useForm<NameFormValues>({
    defaultValues: {
      first_name: '',
      middle_name: '',
      last_name: '',
    },
  });

  return (
    <FormProvider {...form}>
      <Dialog
        keepMounted
        fullWidth
        maxWidth={'xs'}
        {...props}
        TransitionProps={{
          onEnter: () => {
            form.setValue('first_name', initialValues.first_name || '');
            form.setValue('last_name', initialValues.last_name || '');
            form.setValue('middle_name', initialValues.middle_name || '');
          },
          onExited: () => form.reset(),
        }}
      >
        <DialogTitle>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box pr={2}>Update name</Box>
            <IconButton onClick={(e) => props.onClose?.(e, 'backdropClick')}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <form
            id="update-name-form"
            noValidate
            onSubmit={form.handleSubmit(async (values) => {
              const res = await handleUpdate(values);
              if (!res) {
                form.setError('root', { message: 'An error occurred' });
              } else {
                form.clearErrors('root');
              }
            })}
          >
            {form.formState.errors['root'] ? (
              <Box data-testid="update-name-modal-error" pb={2}>
                <Alert variant="filled" severity="error">
                  {form.formState.errors['root'].message}
                </Alert>
              </Box>
            ) : null}
            <NameFormContent />
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={(e) => props.onClose?.(e, 'escapeKeyDown')}
          >
            Cancel
          </Button>
          <Button
            type={'submit'}
            form={'update-name-form'}
            data-testid="update-name-modal-confirm"
            variant="contained"
            disabled={form.formState.isSubmitting}
          >
            {form.formState.isSubmitting ? 'Saving...' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};
