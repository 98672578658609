import React, { useState } from 'react';
import { css } from 'aphrodite';
import { Error } from '~/src/components/Type';
import CreditCardForm from '~/src/components/CreditCardForm';
import Button from '~/src/components/Button';

import modalStyles from '~/src/components/Modal/styles';

export const PaymentMethodForm = ({
  coupon,
  couponError,
  couponApplied,
  onCancel,
  onChange,
  onSubmit,
  error,
  loading,
}) => {
  const [isValid, setIsValid] = useState(false);

  const couponAppliedSuccessfully = coupon && couponApplied && !couponError;
  const noCouponApplied = !coupon;

  const canSubmit =
    isValid && !loading && (couponAppliedSuccessfully || noCouponApplied);

  const handleSubmit = () => {
    if (canSubmit) {
      onSubmit();
    }
  };

  const handleChange = (form) => {
    setIsValid(form.valid);
    onChange(form);
  };

  return (
    <div className="flex flex-col grow md:min-w-[400px]">
      <h1 className={css(modalStyles.modalTitle)}>Payment method</h1>
      <div className={css(modalStyles.modalBody)}>
        <CreditCardForm onChange={handleChange} />
      </div>
      {error && (
        <div className={css(modalStyles.modalActions)}>
          <Error>{error}</Error>
        </div>
      )}
      <div className={css(modalStyles.modalActions)}>
        <Button cssStyle={modalStyles.buttonAction} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          disabled={!canSubmit}
          onClick={handleSubmit}
          cssStyle={modalStyles.buttonAction}
          primary
        >
          {loading ? 'Loading...' : 'Confirm & pay'}
        </Button>
      </div>
    </div>
  );
};
