/* Libraries */
import React from 'react';
import { inject, observer } from 'mobx-react';
import { document } from '~/src/utils/window';

/* Higher Order Components */
import WithLayoutProps from '~/src/hoc/WithLayoutProps';

/* Components */
import UserAvatarDropdownMenu from '~/src/components/PageLayout/UserAvatarDropdownMenu';

/* Constants */
import { LAYOUT_MODAL_TYPES } from '~/src/components/PageLayout/Modals';
import { history } from '../utils/history';

class UserAvatarDropdownMenuContainer extends React.Component {
  handleEditProfile = () => {
    // This hides the dropdown menu
    document.body.click();
    history.push('/settings/account');
  };

  handleSwitchOrganization = (form) => {
    this.props.store.user.switchOrganization(form.fields.organizations.value);
  };

  handleSwitchOrganizations = () => {
    const currentOrgFprint = this.props.store.user.currentOrganization.fprint;
    const options = this.props.store.user.getOrganizationFormOptionList();

    this.props.showModal(LAYOUT_MODAL_TYPES.formField, {
      title: 'Switch organization',
      primaryActionTitle: 'Switch',
      fields: [
        {
          id: 'organizations',
          type: 'multipleChoice',
          defaultValue: `${currentOrgFprint}`,
          options,
          validation: () => true,
        },
      ],
      onConfirm: this.handleSwitchOrganization,
      // Prevent the form from growing too tall in the event that there are a lot of
      // organizations (which happens mainly with internal testers on demo accounts)
      className: 'max-h-96 overflow-auto',
    });
  };

  render() {
    const { avatar } = this.props.store.user.profile;
    const { initials } = this.props.store.user.profile;
    const organizationName = this.props.store.user.currentOrganization
      ? this.props.store.user.currentOrganization.organizationName
      : '';
    const email =
      this.props.store.user.profile.email ||
      (this.props.store.user.currentOrganization
        ? this.props.store.user.currentOrganization.email
        : '');

    const showSwitchOrganizationsAction =
      this.props.store.user.organizations.length > 1;

    let showEditProfileAction = true;
    const path =
      (history.location &&
        history.location.pathname &&
        history.location.pathname.split('/').join('')) ||
      '';
    if (path === 'subscribe') {
      // hide Edit Profile option in subscribe page
      showEditProfileAction = false;
    }

    return (
      <UserAvatarDropdownMenu
        email={email}
        avatar={avatar}
        initials={initials}
        organization={organizationName}
        showSwitchOrganizationsAction={showSwitchOrganizationsAction}
        showEditProfileAction={showEditProfileAction}
        onEditProfile={this.handleEditProfile}
        onSwitchOrganizations={this.handleSwitchOrganizations}
      />
    );
  }
}

export default inject((store) => store)(
  WithLayoutProps(observer(UserAvatarDropdownMenuContainer)),
);
