import {
  Box,
  FormControl,
  FormControlProps,
  FormHelperText,
  FormLabel,
  Typography,
} from '@mui/material';
import * as React from 'react';

type FormFieldProps = React.PropsWithChildren<{
  label?: string;
  required?: boolean;
  error?: string;
  helperText?: string;
  labelProps?: Partial<React.ComponentProps<typeof FormLabel>>;
}> &
  Omit<FormControlProps, 'error'>;

export const FormField = ({
  label,
  required,
  children,
  error,
  helperText,
  labelProps = {},
  ...rest
}: FormFieldProps) => {
  return (
    <FormControl
      size="small"
      variant={'outlined'}
      fullWidth
      {...rest}
      required={required}
      error={!!error}
    >
      {label ? (
        <Box pb={1}>
          <FormLabel {...labelProps}>
            <Typography variant="label">{label}</Typography>
          </FormLabel>
        </Box>
      ) : null}
      {children}
      <FormHelperText>{error || helperText}</FormHelperText>
    </FormControl>
  );
};
