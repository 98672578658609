import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

export default StyleSheet.create({
  container: {
    margin: '0 auto',
    maxWidth: '1080px',
    width: '100%',
  },
  actions: {},
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  actionContainerStacked: {
    flexFlow: 'column',
    alignItems: 'flex-end',
  },
  actionSearchStacked: {
    width: '100%',
    marginTop: 0,
    marginBottom: theme.unit * 2,
    ':last-of-type': {
      marginBottom: theme.unit * 2,
    },
  },
  tableNoBorder: { border: 'none' },
  table: {
    userSelect: 'none',
    background: theme.colors.white,
    position: 'relative',

    // boxShadow: `1px 1px 3px 0px rgba(222, 222, 223, 0.25), -1px -1px 3px 0px rgba(222,222,223,0.25)`,

    borderBottom: theme.border,
    borderColor: theme.colors.darkIce,
    borderRadius: theme.borderRadius,
  },
  header: {
    display: 'flex',
    borderBottom: theme.border,
    height: theme.unit * 5,
    alignItems: 'center',
    background: '#F9F9FA',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderTop: theme.border,
    borderLeft: theme.border,
    borderRight: theme.border,
    borderColor: theme.colors.darkIce,
  },
  headerNoBorder: {
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
  },
  headerColumnIcon: {
    marginLeft: theme.unit,
  },
  headerColumn: {
    whiteSpace: 'nowrap',
    userSelect: 'none',
    fontSize: theme.fonts.size.small,
    color: theme.colors.charcoal,
    display: 'flex',
    alignItems: 'center',
    ':hover': {
      cursor: 'pointer',
    },
  },
  headerColumnActive: {
    color: theme.colors.eggplant,
  },
  rowRightBorder: {
    borderRight: theme.border,
    borderColor: theme.colors.darkIce,
  },
  rowLeftBorder: {
    borderLeft: theme.border,
    borderColor: theme.colors.darkIce,
  },
  rowBottomBorder: {
    borderBottom: theme.border,
    borderColor: 'rgb(239,239,239)',
    ':last-of-type': {
      borderBottom: 'none',
    },
  },
  rowShowChildren: {
    borderBottom: `solid 1px rgb(239,239,239)`,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  rowClickable: {
    ':hover': {
      cursor: 'pointer',
    },
  },
  rowColumn: {
    color: theme.colors.charcoal,
    padding: '16px 0',
  },
  childRow: {
    borderLeft: '8px solid #dededf',
  },
  emptyContainer: {
    width: '100%',
    textAlign: 'center',
    margin: `${theme.unit * 4}px auto`,
    color: theme.colors.charcoal,
  },
  emptytitle: {
    fontSize: theme.fonts.size.regular,
    color: theme.colors.charcoal,
  },
  emptyDescription: {
    fontSize: theme.fonts.size.small,
    color: theme.colors.charcoal,
    marginTop: theme.unit * 2,
  },
  emptyLink: {
    fontSize: theme.fonts.size.small,
    color: theme.colors.bluebird,
    ':hover': {
      cursor: 'pointer',
    },
  },
});
