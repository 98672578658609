/* Libs */
import React from 'react';
import { css } from 'aphrodite';
import { Link } from 'react-router-dom';

/* Components */
import QuestionnairesTableEmpty from '~/src/components/QuestionnairesTable/QuestionnairesTableEmpty';

import Table from '~/src/containers/Table';
import Button from '~/src/components/Button';
import Triangle from '~/src/components/Triangle';
import Dropdown from '~/src/components/Dropdown';

/* Styles */
import styles from '~/src/components/QuestionnairesTable/styles';
import dropdownStyles from '~/src/components/Dropdown/styles';

const RowAction = ({ actions, intake }) => {
  const renderTrigger = ({ toggleDropdown }) => (
    <Button onClick={toggleDropdown} cssStyle={styles.rowActionButton}>
      Actions <Triangle primary orientation={'down'} />
    </Button>
  );

  const handlePublish = () => {
    actions.publish(intake);
  };

  const handleView = () => {
    actions.view(intake);
  };

  const handleRename = () => {
    actions.rename(intake);
  };

  const handleDelete = () => {
    actions.delete(intake);
  };

  return (
    <Dropdown renderTrigger={renderTrigger}>
      <a
        onClick={handlePublish}
        className={css(dropdownStyles.dropdownActionItem)}
      >
        Link
      </a>
      <a
        onClick={handleView}
        className={css(dropdownStyles.dropdownActionItem)}
      >
        Edit
      </a>
      <a
        onClick={handleRename}
        className={css(dropdownStyles.dropdownActionItem)}
      >
        Rename
      </a>
      <a
        onClick={handleDelete}
        className={css(
          dropdownStyles.dropdownActionItem,
          dropdownStyles.dropdownActionItemRed,
        )}
      >
        Delete
      </a>
    </Dropdown>
  );
};

const IntakesAction = () => {
  return (
    <Link to={`/questionnaire/new`}>
      <Button primary>Create New Questionnaire</Button>
    </Link>
  );
};

const QuestionnairesTable = ({
  intakes,
  isLoading,
  rowActions,
  onSearchChange,
  onSortByChange,
}) => {
  const renderRowAction = (intake) => (
    <RowAction actions={rowActions} intake={intake} />
  );

  return (
    <Table
      visibleItems={intakes.length}
      isLoading={isLoading}
      emptyTitle={'No questionnaires for that filter'}
      searchPlaceholder="Search"
      renderActions={IntakesAction}
      data={intakes}
      onSearchChange={onSearchChange}
      onSortByChange={onSortByChange}
      columns={[
        {
          label: 'Name',
          dataKey: 'title',
          style: {
            width: '30%',
            textAlign: 'left',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          },
        },
        {
          label: 'Description',
          dataKey: 'description',
          style: {
            width: '30%',
            textAlign: 'left',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          },
        },
        {
          label: 'Actions',
          hideLabel: true,
          render: renderRowAction,
          style: { flexGrow: 1, display: 'flex', justifyContent: 'flex-end' },
        },
      ]}
    />
  );
};

const QuestionnairesTableLoading = ({
  empty,
  intakes,
  onView,
  onRename,
  onPublish,
  onSearchChange,
  onSortByChange,
  onDelete,
}) => {
  const actions = {
    view: onView,
    rename: onRename,
    delete: onDelete,
    publish: onPublish,
  };

  if (empty) {
    return <QuestionnairesTableEmpty />;
  }

  return (
    <div>
      <QuestionnairesTable
        intakes={intakes}
        rowActions={actions}
        isLoading={false}
        onSearchChange={onSearchChange}
        onSortByChange={onSortByChange}
      />
    </div>
  );
};

export default QuestionnairesTableLoading;
