import React from 'react';

import { WhoSigns } from '~/src/models';
import { Heading } from '../../Typography';
import { classNames } from '../../SignaturePackage/styles';
import { SIGNERS_BUTTONS } from './Buttons';
import { SignerButton } from './SignButton';

type SignersButtonsProps = {
  whoSigns: WhoSigns;
  onChange: (to: WhoSigns) => void;
};

export const SignersButtons = ({ whoSigns, onChange }: SignersButtonsProps) => {
  return (
    <div className={`${classNames.card} mt-6`}>
      <div className="p-4 pb-0">
        <Heading>Who needs to sign?</Heading>
      </div>
      <div className="p-4 flex gap-4 justify-between pt-6">
        {SIGNERS_BUTTONS.map(({ who, ...rest }) => {
          const selected = whoSigns === who;
          return (
            <SignerButton
              key={who}
              selected={selected}
              onClick={() => onChange(who)}
              {...rest}
            />
          );
        })}
      </div>
    </div>
  );
};
