import React from 'react';
import { MessageDialog } from '~/src/components/Modal/mui';
import {
  useDeleteClioQuestionnaireSubmission,
  useDeleteRelatedQuestionnaireSubmission,
} from '../../hooks';
import { QuestionnaireSubmission } from '@clio/questionnaire-builder';
import { useCurrentOrgFprint } from '~/src/entities/user';
import { useRelatedQuestionnaireSubmissionsByQuestionnaireSubmissionId } from '~/src/entities/questionnairesV2/hooks/useRelatedQuestionnaireSubmissionsByQuestionnaireSubmissionId';
import { useLayoutContext } from '~/src/contexts';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import Spinner from '~/src/components/Spinner';
import { Button } from '@mui/material';

interface Props {
  onClose: () => void;
  submission: QuestionnaireSubmission;
}

export const DeleteSubmissionDialogContents = ({
  onClose,
  submission,
}: Props) => {
  const orgFprint = useCurrentOrgFprint();

  const { showToast } = useLayoutContext();

  const { mutate: deleteSubmission } =
    useDeleteClioQuestionnaireSubmission(orgFprint);

  const { data: relatedQuestionnaireSubmissions, isLoading } =
    useRelatedQuestionnaireSubmissionsByQuestionnaireSubmissionId(
      submission.id,
      {
        onError: () => {
          showToast(LAYOUT_TOAST_TYPES.error, {
            message: 'Something went wrong, please try again later.',
          });
        },
      },
    );

  const { mutate: deleteRelatedSubmission } =
    useDeleteRelatedQuestionnaireSubmission(orgFprint);

  const handleOnDelete = () => {
    deleteSubmission(
      { submissionId: `${submission.id}` },
      {
        onSuccess: () => {
          if (
            relatedQuestionnaireSubmissions &&
            relatedQuestionnaireSubmissions.length > 0 &&
            relatedQuestionnaireSubmissions[0] !== undefined
          ) {
            const payload = {
              id: relatedQuestionnaireSubmissions[0].id,
            };

            deleteRelatedSubmission(payload, {
              onError: () => {
                showToast(LAYOUT_TOAST_TYPES.error, {
                  message: 'Something went wrong, please try again later.',
                });
              },
            });
          }
          showToast(LAYOUT_TOAST_TYPES.success, {
            message: 'Live questionnaire deleted',
          });
          onClose();
        },
        onError: () => {
          showToast(LAYOUT_TOAST_TYPES.error, {
            message: 'Something went wrong, please try again later.',
          });
        },
      },
    );
  };

  if (isLoading) {
    return (
      <MessageDialog
        title={'Loading...'}
        onClose={onClose}
        dialogActions={
          <>
            <Button onClick={onClose} variant="outlined">
              Cancel
            </Button>
          </>
        }
      >
        <Spinner />
      </MessageDialog>
    );
  }

  return (
    <MessageDialog
      title={'Delete Live Questionnaire'}
      onClose={onClose}
      dialogActions={
        <>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleOnDelete} variant="red">
            Delete
          </Button>
        </>
      }
    >
      <strong>Warning: this action is permanent.</strong>
      <br />
      <br />
      Any links to the Live Questionnaire will still be accessible for the
      recipient but they will not be able to submit their response.
    </MessageDialog>
  );
};
