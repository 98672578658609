import React, { FC, useEffect, useState } from 'react';
import { WhoSigns } from '~/src/models';
import { FileUpload } from '~/src/models/fileUpload';
import { SignaturePackage } from '~/src/models/signaturePackage';
import { LoadingOverlay } from '../components/PageLayout';
import {
  NO_DOCUMENTS_SELECTED,
  CURRENT_STEP,
  IS_LOADING,
} from '../components/SignaturePackage/constants';

import SelectDocuments from '../components/SelectDocuments/SelectDocuments';
import HeaderFooter from './HeaderFooter';
import { showDiscardChangesModal } from '../components/SignaturePackage/SaveModals';
import useIsValid from '../components/SignaturePackage/hooks/useIsValid';

interface Props {
  signaturePackage: SignaturePackage.SignaturePackage | undefined;
  onCreate: (
    filesToUpload: (File | null)[],
  ) => Promise<SignaturePackage.SignaturePackage>;
  onAdd: (
    signaturePackageId: number,
    filesToUpload: File[],
  ) => Promise<SignaturePackage.SignaturePackage>;
  onUpdate: (signaturePackage: {
    signaturePackageId: number;
    title: string;
    emailSubject: string;
    currentStep: number;
  }) => Promise<SignaturePackage.SignaturePackage>;
  onReorder: (
    signaturePackage: SignaturePackage.SignaturePackage,
    documents: FileUpload.Document[],
    sortedServerDocIds: (number | null)[],
  ) => Promise<void>;
  showModal: (type: string, object: any) => void;
  hideModal: () => void;
  onNext: (projectId: number, packageId: number) => void;
  onExit: () => void;
}

const SelectDocumentsContainer: FC<Props> = ({
  signaturePackage,
  onCreate,
  onAdd,
  onUpdate,
  onReorder,
  showModal,
  hideModal,
  onNext,
  onExit,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { valid, setValid, invalidReason } = useIsValid();
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [documents, setDocuments] = useState<FileUpload.Document[]>([]);

  const setUserFileToDocument = (
    userFilesArray: File[],
    newServerIds: number[] = [],
  ) => {
    setDocuments((allFiles) => {
      const fileDocument = [...allFiles];
      let index = allFiles.length;

      userFilesArray.forEach((file, count) => {
        fileDocument.push({
          id: ++index,
          file,
          title: file.name,
          selected: true,
          serverId: newServerIds.length > 0 ? newServerIds[count]! : null,
        });
      });

      return fileDocument;
    });
  };

  const getNewFileServerIds = (
    res: SignaturePackage.SignaturePackage,
    fileLength: number,
  ): number[] => {
    return [...res.lawyawDocumentIds]
      .sort()
      .slice(res.lawyawDocumentIds.length - fileLength);
  };

  const uploadFilesDirectly = async (filesToUpload: File[]) => {
    // package has been created, we can upload right away...
    try {
      if (!signaturePackage || !signaturePackage.id)
        throw Error('Cannot upload files directly.');

      setErrorMessages([]);
      const res: SignaturePackage.SignaturePackage = await onAdd(
        signaturePackage.id,
        filesToUpload,
      );

      const newServerIds = getNewFileServerIds(res, filesToUpload.length);
      setUserFileToDocument(filesToUpload, newServerIds);
      setIsLoading(false);
    } catch (err: any) {
      setErrorMessages((prev) => [...prev, err.message]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileUploaded = async (userFiles: File[]) => {
    const userFilesArray = Array.from(userFiles);

    if (signaturePackage) {
      // add files to existing package
      await uploadFilesDirectly(userFilesArray);
      return;
    }

    setUserFileToDocument(userFilesArray);
    setIsLoading(false);
  };

  const createCustomSignaturePackage = () => {
    const filesToUpload = documents
      .filter((doc) => doc.selected && !doc.serverId)
      .map((doc) => doc.file);

    return onCreate(filesToUpload);
  };

  const createSignaturePackageData = (
    signaturePackage: SignaturePackage.SignaturePackage,
  ) => {
    const composeSignaturePackageTitle = (
      documents: SignaturePackage.Document[],
    ) => {
      if (!documents.length || !documents[0]) {
        return 'New signature package request';
      }

      if (documents.length === 1) {
        return `${documents[0].title}`;
      }

      if (documents.length === 2) {
        return `${documents[0].title} & 1 other document`;
      }

      return `${documents[0].title} & ${documents.length - 1} other documents`;
    };

    const getSelectedDocuments = (documents: FileUpload.Document[]) => {
      if (documents.some((doc) => !doc.serverId)) return;

      return documents
        .filter((doc) => doc.selected)
        .map((doc) => ({ id: doc.serverId }));
    };

    const title = signaturePackage.title
      ? signaturePackage.title
      : composeSignaturePackageTitle(signaturePackage.lawyawDocuments);

    return {
      signaturePackageId: signaturePackage.id,
      title,
      emailSubject: title,
      currentStep: CURRENT_STEP.SELECT_DOCUMENTS,
      documents: getSelectedDocuments(documents),
    };
  };

  const createSignaturePackage = async () => {
    try {
      setIsLoading(true);

      const createdPackage = await createCustomSignaturePackage();

      if (
        !createdPackage ||
        !createdPackage.lawyawProjectId ||
        !createdPackage.id
      )
        throw new Error('Package was not created.');

      const signaturePackageData = createSignaturePackageData(createdPackage);
      await onUpdate(signaturePackageData);
      onNext(createdPackage.lawyawProjectId, createdPackage.id);
    } catch (err: any) {
      setErrorMessages((prev) => [...prev, err.message]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNextClick = async () => {
    if (!signaturePackage) {
      createSignaturePackage();
      return;
    }

    const signaturePackageData = createSignaturePackageData(signaturePackage);
    await onUpdate(signaturePackageData);
    onNext(signaturePackage.lawyawProjectId, signaturePackage.id);
  };

  useEffect(() => {
    if (!signaturePackage) return;

    const fileDocument: FileUpload.Document[] = [];
    signaturePackage.lawyawDocuments.forEach((lawyawDocument, index) => {
      fileDocument.push({
        id: ++index,
        file: null,
        title: lawyawDocument.title,
        selected: true,
        serverId: lawyawDocument.id,
      });
    });

    setDocuments(fileDocument);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filesToUpload = documents
      .filter((doc) => doc.selected)
      .map((doc) => doc.file);

    if (filesToUpload.length == 0) {
      setValid(false, NO_DOCUMENTS_SELECTED);
    } else {
      setValid(true);
    }
  }, [documents, setValid]);

  const handleExitClick = () => {
    showDiscardChangesModal(showModal, () => {
      onExit();
      hideModal();
    });
  };

  const handleReorderDocument = async (
    documents: FileUpload.Document[],
    docIds: number[],
  ) => {
    if (!signaturePackage) return;

    // sort documents array by array of docIds.
    setValid(false, IS_LOADING);

    const sortedDocs = [...documents].sort(
      (a, b) => docIds.indexOf(a.id) - docIds.indexOf(b.id),
    );

    setDocuments(sortedDocs);

    const sortedServerDocIds = sortedDocs.map((docs) => docs.serverId);

    await onReorder(signaturePackage, documents, sortedServerDocIds);

    setValid(true);
  };

  return (
    <HeaderFooter
      signaturePackageTitle={signaturePackage?.title || 'New signature package'}
      whoSigns={WhoSigns.MeAndOthers}
      step={'select-documents'}
      invalidReason={invalidReason}
      valid={valid && !isLoading}
      onBackClick={() => {}}
      onNextClick={handleNextClick}
      onExitClick={handleExitClick}
    >
      <div className="w-[36rem] mx-auto">
        {isLoading && !signaturePackage ? (
          <LoadingOverlay
            title={'Creating new signature package...'}
            relative={false}
          />
        ) : (
          <SelectDocuments
            documents={documents}
            setDocuments={setDocuments}
            onFileUploaded={handleFileUploaded}
            errorMessages={errorMessages}
            setErrorMessages={setErrorMessages}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            onReorderDocument={handleReorderDocument}
          />
        )}
      </div>
    </HeaderFooter>
  );
};

export default SelectDocumentsContainer;
