import {
  StyledEngineProvider,
  ScopedCssBaseline,
  ThemeProvider,
} from '@mui/material';
import React from 'react';
import { muiTheme } from './muiTheme';

export const MuiBase = ({ children }: { children: React.ReactElement }) => {
  return (
    <StyledEngineProvider>
      <ScopedCssBaseline />
      <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};
