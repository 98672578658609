import { useEffect } from 'react';

import useMst from '~/src/hooks/useMst';
import { useObserver } from 'mobx-react';

const useProject = (isDrafting) => {
  const {
    getProject,
    projects,
    sidebarItems,
    removeSidebarItem,
    addDocumentToSelectionSidebar,
    setFocusOnSidebarItemFromIndex,
    updateTitle,
  } = useMst((store) => ({
    getProject: store.projects.getProject,
    projects: store.projects,
    sidebarItems: store.sidebarItems,
    removeSidebarItem: store.sidebarItems.remove,
    addDocumentToSelectionSidebar: store.sidebarItems.add,
    setFocusOnSidebarItemFromIndex:
      store.sidebarItems.setFocusOnSidebarItemFromIndex,
    updateTitle: store.sidebarItems.updateTitle,
  }));

  const { currentProjectId, documentsInCurrentProject } = useObserver(() => ({
    currentProjectId: projects.curProjectId,
    documentsInCurrentProject: getProject(projects.curProjectId)?.documents
      ?.length,
  }));

  const currentProject = getProject(currentProjectId);
  const docTitlesString = currentProject?.documents
    ?.map((doc) => doc.title)
    .join(',');
  let documentsInProject = [];

  if (typeof currentProject?.documents != 'undefined') {
    documentsInProject = currentProject.documents;
  }

  const generateProjectDocumentLookup = (documentsInProject) => {
    const projectDocumentsLookup = {};
    // first add all docs from project to sidebar, store id:title in an object for lookup later
    documentsInProject.forEach((document) => {
      projectDocumentsLookup[document?.id] = document?.title;
    });
    return projectDocumentsLookup;
  };

  useEffect(() => {
    // In this case we added or removed documents to the project, and want to add it to the sidebar
    // Update sidebar items with project items here
    // The isDrafting check is to prevent updating the sidebar during document selection
    if (typeof currentProject?.documents != 'undefined' && isDrafting) {
      const projectDocumentsLookup =
        generateProjectDocumentLookup(documentsInProject);

      documentsInProject.forEach((document) => {
        addDocumentToSelectionSidebar(document);
      });
      // then delete docs that are not present in project
      sidebarItems.items.forEach((item) => {
        if (!(item.id in projectDocumentsLookup)) {
          // if item isn't in the lookup object, it's not in the project store,
          // and we want to remove it from the sidebar store
          removeSidebarItem(item);
          // after a deletion of a sidebar item, we want to set focus on the first element of the sidebar
          // this is so other actions that rely on sidebar focus (such as downloading) can work correctly
          setFocusOnSidebarItemFromIndex(0);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentsInCurrentProject]);

  useEffect(() => {
    const projectDocumentsLookup =
      generateProjectDocumentLookup(documentsInProject);

    sidebarItems.items.forEach((item) => {
      // The isDrafting check is to prevent updating the sidebar during document selection
      if (item.id in projectDocumentsLookup && isDrafting) {
        if (
          item.title != projectDocumentsLookup[item.id] &&
          typeof projectDocumentsLookup[item.id] != 'undefined'
        ) {
          // In this case, there is a mismatch between the sidebar title,
          // and the project title
          // for a document, so we will update the sidebar to have the correct title
          updateTitle(item.id, projectDocumentsLookup[item.id]);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docTitlesString]);
};

export default useProject;
