import { http } from '~/src/services/requests';
import { keysToSnakeCase } from '~/src/utils/objects';

function projectsService() {
  return {
    createProject: async (orgFprint, templateIds, name, matterId, version) => {
      const parse = true;

      const body =
        version === 2
          ? {
              name,
              templates: templateIds.map((number) => {
                if (number.indexOf(' ') > 0) {
                  const [templateId, defaultValueId] = number.split(' ');
                  return {
                    id: parseInt(templateId),
                    default_values_id: parseInt(defaultValueId),
                  };
                }
                return {
                  id: parseInt(number),
                  default_values_id: null,
                };
              }),
              version,
            }
          : {
              name,
              templateIds: templateIds.map((number) => parseInt(number)),
              version,
            };

      if (matterId) {
        body.matterId = matterId;
      }

      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v2/org/${orgFprint}/project/`,
          method: 'POST',
          body: keysToSnakeCase(body),
        },
        parse,
      );
    },
    populateProject: async (orgFprint, projectId) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/panels/api/v2/org/${orgFprint}/project/${projectId}/populate-ms-words/`,
          method: 'POST',
          timeout: 120000,
        },
        parse,
      );
    },
    orderDocuments: async (orgFprint, projectId, docids) => {
      const parse = false;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/project/${projectId}/sort-order/`,
          method: 'PUT',
          body: { docids },
        },
        parse,
      );
    },
    updateProject: async (
      orgFprint,
      projectId,
      variables,
      projectData,
      currentStackData,
    ) => {
      const parse = true;

      let body = {};

      if (variables) {
        body.stack_saved_data = variables;
      }

      if (currentStackData) {
        body.stack_saved_data = currentStackData.toJSON();
      }

      if (projectData) {
        body = { ...body, ...keysToSnakeCase(projectData) };
      }

      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v2/org/${orgFprint}/project/${projectId}/`,
          method: 'POST',
          body,
        },
        parse,
      );
    },
    fetchProject: async (orgFprint, projectId, getPDFElements) => {
      const parse = true;
      let relativePath = `/clients/api/v2/org/${orgFprint}/project/${projectId}/`;
      if (getPDFElements) {
        relativePath = `/clients/api/v2/org/${orgFprint}/project/${projectId}/?getPDFElements=true`;
      }
      return http.innerSendAuthenticated(
        {
          relativePath,
          method: 'GET',
        },
        parse,
      );
    },
    duplicateDocument: async (
      orgFprint,
      projectId,
      numberOfDuplicates,
      documentID,
    ) => {
      const parse = true;
      const body = {};
      body.documentID = documentID;
      body.numberOfDuplicates = numberOfDuplicates;

      const relativePath = `/clients/api/v2/org/${orgFprint}/project/${projectId}/duplicate/document/`;

      const resp = http.innerSendAuthenticated(
        {
          relativePath,
          method: 'POST',
          body,
        },
        parse,
      );
      return resp;
    },

    updateProjectDocuments: async (orgFprint, projectId, documents) => {
      const parse = true;
      const body = {};
      body.templates = documents;

      const relativePath = `/clients/api/v2/org/${orgFprint}/project/${projectId}/document/`;

      const response = http.innerSendAuthenticated(
        {
          relativePath,
          method: 'PUT',
          body,
        },
        parse,
      );

      return response;
    },

    deleteProject: async (orgFprint, projectId) => {
      const parse = false;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v2/org/${orgFprint}/project/${projectId}/`,
          method: 'DELETE',
        },
        parse,
      );
    },
    getQuestionnaireFormProjectData: async (projectFprint) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/intakeform/getdata/${projectFprint}/`,
          method: 'GET',
        },
        parse,
      );
    },
    downloadDocSet: async (fprint, removeHighlights) => {
      const parse = false;
      return http.innerSendAuthenticated(
        {
          relativePath: `/panels/api/v1/export-doc-set/`,
          method: 'POST',
          body: { fprint, remove_highlights: removeHighlights },
        },
        parse,
      );
    },
  };
}

export default projectsService();
