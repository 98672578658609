import React from 'react';
import { inject } from 'mobx-react';
import WithLayoutProps from '~/src/hoc/WithLayoutProps';
import { ClioLoading, ClioError } from '~/src/components/Clio';
import Button from '~/src/components/Button';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import { LAYOUT_MODAL_TYPES } from '~/src/components/PageLayout/Modals';
import Page from '~/src/components/PageLayout';
import { withRouter } from 'react-router-dom';

class ClioConnectContainer extends React.Component {
  componentDidMount = async () => {
    const handleConfirm = async () => {
      this.props.hideModal();
      this.handleImport();
    };

    const handleCancel = () => {
      this.props.hideModal();
      this.props.showToast(LAYOUT_TOAST_TYPES.success, {
        message: 'Clio authenticated successfully!',
      });
      this.handleBackToIntegrations();
    };

    const res = await this.props.store.clio.authenticate(this.props.code);

    if (res) {
      this.props.showToast(LAYOUT_TOAST_TYPES.success, {
        message: 'Clio authenticated',
      });
      this.props.showModal(LAYOUT_MODAL_TYPES.clioImportConfirm, {
        disableClickCatcher: true,
        onConfirm: handleConfirm,
        onCancel: handleCancel,
        mattersCount: res.mattersCount,
        contactsCount: res.contactsCount,
      });
    } else {
      this.props.showToast(LAYOUT_TOAST_TYPES.error, {
        message: 'Clio authentication failed, please try again later.',
      });
      this.handleBackToIntegrations();
    }
  };

  handleClioAuthRetry = () => {
    const redirectOrigin =
      window && window.location
        ? window.location.origin
        : 'https://app.lawyaw.com';

    const redirect = `${redirectOrigin}/clients/clio/connect`;

    const authUrl = this.props.store.clio.getAuthUrl(redirect);

    window.location = authUrl;
  };

  handleImport = async () => {
    const success = await this.props.store.clio.sync();

    if (success) {
      this.props.showToast(LAYOUT_TOAST_TYPES.success, {
        message: 'Clio import started',
      });
    } else {
      this.props.showToast(LAYOUT_TOAST_TYPES.error, {
        message: 'Clio import failed, please try again later',
      });
      this.props.store.clio.clear();
    }

    this.handleBackToIntegrations();
  };

  handleBackToIntegrations = () => {
    this.props.history.push('/settings/integrations');
  };

  render() {
    const importing = this.props.store.clio.syncing;
    const { importFailed } = this.props.store.clio;
    const { authenticating } = this.props.store.clio;
    const { authenticationFailed } = this.props.store.clio;

    return (
      <Page showNavigation={false} title={'Clio Integration'}>
        {authenticating && (
          <ClioLoading
            title="Connecting to Clio"
            subtitle="This may take up to a few minutes"
          />
        )}
        {importing && (
          <ClioLoading
            title="Starting to import your Clio Data"
            subtitle="This may take up to a few seconds"
          />
        )}

        {!authenticating && authenticationFailed && (
          <ClioError
            title="Failed to connect to Clio"
            subtitle="We were not able to access your Clio account. Click the button below and grant access to Clio Draft to import your data."
            renderAction={() => (
              <Button primary onClick={this.handleClioAuthRetry}>
                Connect to Clio
              </Button>
            )}
          />
        )}
        {!importing && importFailed && !authenticationFailed && (
          <ClioError
            renderAction={() => (
              <Button primary onClick={this.handleImport}>
                Retry Import
              </Button>
            )}
          />
        )}
      </Page>
    );
  }
}

export const ClioConnect = inject((store) => store)(
  withRouter(WithLayoutProps(ClioConnectContainer)),
);
