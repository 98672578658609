import React, { useState } from 'react';

import ReactTooltip from 'react-tooltip';

const QuestionOptionsMenuOption = ({
  id,
  label,
  className = '',
  onMouseDown: handleMouseDown,
  onMouseEnter,
  onMouseLeave,

  Icon,
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
    if (onMouseEnter) {
      onMouseEnter();
    }
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
    if (onMouseLeave) {
      onMouseLeave();
    }
  };

  return (
    <div className={className} data-tip data-for={id}>
      <div
        onMouseDown={handleMouseDown}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Icon size={16} color={isHovering ? '#3B82F6' : '#8C9096'} />
      </div>
      <ReactTooltip id={id} place="right" effect="solid">
        {label}
      </ReactTooltip>
    </div>
  );
};

export default QuestionOptionsMenuOption;
