/* Hooks */
import useMst from '~/src/hooks/useMst';

/* Constants */
import { LAYOUT_SLIDEIN_TYPES } from '~/src/components/PageLayout/SlideIns';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import { LAYOUT_MODAL_TYPES } from '~/src/components/PageLayout/Modals';
import { VALIDATION_TYPES } from '~/src/utils/validation';
import { useLayoutContext } from '../contexts/Layout';

const useTemplateSetsActions = () => {
  const {
    removeTemplateSet,
    getTemplateSetById,
    generateTemplateSet,
    addTemplatesToSeletionSidebar,
  } = useMst((store) => {
    return {
      fetchAll: store.templateSets.fetchAll,
      generateTemplateSet: store.templateSets.generate,
      getTemplateSetById: store.templateSets.getById,
      isLoading: store.templateSets.isLoading,
      createNewTemplateSet: store.templateSets.create,
      removeTemplateSet: store.templateSets.remove,
      addTemplatesToSeletionSidebar:
        store.templateSets.addTemplatesToSeletionSidebar,
      templateSets: store.templateSets,
    };
  });

  const { showSlideIn, showToast, showModal, hideModal } = useLayoutContext();

  const handleRename = ({ id, title }) => {
    const handleRenameConfirm = async (form) => {
      const { value } = form.fields.name;
      const templateSet = getTemplateSetById(id);
      const success = await templateSet.update({ id, title: value });

      if (success) {
        showToast(LAYOUT_TOAST_TYPES.success, {
          message: `Template Set "${value}" renamed successfully.`,
        });
      } else {
        showToast(LAYOUT_TOAST_TYPES.error);
      }

      hideModal();
    };

    showModal(LAYOUT_MODAL_TYPES.formField, {
      title: 'Rename template set',
      primaryActionTitle: 'Save',
      fields: [
        {
          label: 'Name',
          id: 'name',
          type: 'text',
          defaultValue: title,
          validation: VALIDATION_TYPES.present,
        },
      ],
      onConfirm: handleRenameConfirm,
    });
  };

  const handleDelete = (templateSet) => {
    const handleConfirm = () => {
      hideModal();
      removeTemplateSet(templateSet);
    };

    showModal(LAYOUT_MODAL_TYPES.confirm, {
      title: `Delete template set "${templateSet.title}"?`,
      message: `Are you sure you want to delete this template set? This action cannot be undone.`,
      primaryActionTitle: 'Delete',
      onConfirm: handleConfirm,
    });
  };

  const handleGenerate = ({ id }) => {
    generateTemplateSet(id);
  };

  const handleViewTemplate = (template) => {
    showSlideIn(LAYOUT_SLIDEIN_TYPES.documentPreview, {
      largeSlideIn: true,
      templateObj: template,
      templateId: parseInt(template.id),
    });
  };

  const handleRemoveTemplateFromSet = ({ template, templateSetId }) => {
    const templateSet = getTemplateSetById(templateSetId);

    const handleRemoveSingleTemplate = (template, templateSet) => {
      hideModal();

      return templateSet.removeTemplate(template.id, template.defaultValuesId);
    };

    showModal(LAYOUT_MODAL_TYPES.confirm, {
      title: `Remove template from set?`,
      message: `You are removing ${template.title} from ${templateSet.title}.`,
      primaryActionTitle: 'Remove',
      onConfirm: async () => {
        const success = await handleRemoveSingleTemplate(template, templateSet);
        if (success) {
          showToast(LAYOUT_TOAST_TYPES.success, {
            message: `Template "${template.title}" removed successfully.`,
          });
        } else {
          showToast(LAYOUT_TOAST_TYPES.error);
        }
      },
    });
  };

  const handleAddTemplatesToSeletionSidebar = (templateSet) => {
    addTemplatesToSeletionSidebar(templateSet);
  };

  return {
    rename: handleRename,
    delete: handleDelete,
    generate: handleGenerate,
    removeTemplateFromSet: handleRemoveTemplateFromSet,
    viewTemplate: handleViewTemplate,
    addTemplatesToSeletionSidebar: handleAddTemplatesToSeletionSidebar,
  };
};

export default useTemplateSetsActions;
