import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

export default StyleSheet.create({
  on: {
    display: 'inline-block',
    width: theme.unit,
    height: theme.unit,
    borderRadius: theme.unit,
    content: ' ',
    background: theme.colors.tennisBall,
  },
  off: {
    background: theme.colors.darkIce,
  },
});
