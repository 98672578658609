/* Libraries */
import React from 'react';
import { css } from 'aphrodite';

/* Components */
import Button from '~/src/components/Button';
import Dropdown from '~/src/components/Dropdown';
import Triangle from '~/src/components/Triangle';
import dropdownStyles from '~/src/components/Dropdown/styles';
import SaveIndicator from '~/src/components/SaveIndicator';

/* Styles */
import styles from './styles';

export interface TemplateDefaultValuesDraftingFooterProps {
  onClose: () => void;
  onSave: () => void;
  onDuplicate: () => void;
  onRename: () => void;
  onDelete: () => void;
  hasUnsavedChanges: boolean;
}

const TemplateDefaultValuesDraftingFooter = ({
  onClose,
  onSave,
  onDuplicate,
  onRename,
  onDelete,
  hasUnsavedChanges,
}: TemplateDefaultValuesDraftingFooterProps) => {
  const renderTrigger = ({
    toggleDropdown,
  }: {
    toggleDropdown: () => void;
  }) => {
    return (
      <span>
        <Button onClick={toggleDropdown}>
          More Actions <Triangle primary orientation={'down'} />
        </Button>
      </span>
    );
  };

  return (
    <footer className={css(styles.documentPreviewFooter)}>
      <Button onClick={onClose}>Close</Button>
      <div className={css(styles.rightSideButtons)}>
        {<SaveIndicator hasUnsavedChanges={hasUnsavedChanges} />}
        <Button
          primary
          onClick={onSave}
          analyticsname="Form Template - Footer - Save"
        >
          Save Template
        </Button>
        <Dropdown
          renderTrigger={renderTrigger}
          className={css(styles.saveFormTemplateButton)}
        >
          <a
            analyticsname="Form Template - Footer - Duplicate"
            onClick={onDuplicate}
            className={css(dropdownStyles.dropdownActionItem)}
          >
            Duplicate
          </a>
          <a
            analyticsname="Form Template - Footer - Rename"
            onClick={onRename}
            className={css(dropdownStyles.dropdownActionItem)}
          >
            Rename
          </a>
          <a
            analyticsname="Form Template - Footer - Delete"
            onClick={onDelete}
            className={css(dropdownStyles.dropdownActionItem)}
          >
            Delete
          </a>
        </Dropdown>
      </div>
    </footer>
  );
};

export default TemplateDefaultValuesDraftingFooter;
