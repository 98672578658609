/* Libraries */
import React from 'react';
import { useObserver } from 'mobx-react';
import ReactTooltip from 'react-tooltip';
import { css } from 'aphrodite';

/* Components */
import { ChevronDown, ChevronUp, Eye, X } from 'react-feather';
import { ReactComponent as IconFolderSet } from '~/src/static/iconFolderSet.svg';
// import DraggableIcon from "~/src/components/DraggableIcon";
import Table from '~/src/containers/Table';
import Button from '~/src/components/Button';
import { Text } from '~/src/components/Typography';
import RowActions from '~/src/components/RowActions/';
import useMst from '~/src/hooks/useMst';

/* Styles */

/* Icons */
import { ReactComponent as ItemSelectedIcon } from '~/src/static/item-selected.svg';
import styles from './styles';
import { TOTAL_DOCUMENT_LIMIT } from '~/src/utils/constants';

const ChildRowActions = ({
  template,
  templateSetId,
  disabledRemove,
  actions,
}) => {
  const handleView = () => {
    actions.view(template);
  };

  const handleRemove = () => {
    actions.remove({ template, templateSetId });
  };

  return (
    <div className={css(styles.childRowActions)}>
      <Button
        Icon={Eye}
        cssStyle={styles.childRowActionButton}
        onClick={handleView}
      />
      <i className={css(styles.childRowActionsSeperator)} />
      <Button
        Icon={X}
        secondaryAlert
        cssStyle={styles.childRowActionButton}
        disabled={disabledRemove}
        onClick={handleRemove}
      />
    </div>
  );
};

const renderNameColumn = (templateTitle, { showChildren }) => (
  <div className="flex items-center gap-3">
    <div className="rounded-full w-6 h-6 flex justify-center items-center hover:bg-gray-100">
      {!showChildren && <ChevronDown size={16} />}
      {showChildren && <ChevronUp size={16} />}
    </div>
    <div>
      <IconFolderSet />
    </div>
    <Text strong>{templateTitle}</Text>
  </div>
);

const renderChildNameColumn = (templateTitle) => (
  <div className="pl-9">
    {/*
      TODO: Drag and Drop support
      <DraggableIcon className={css(styles.rowIcon)} color={theme.colors.concrete} size={14} />
    */}
    <Text>{templateTitle}</Text>
  </div>
);

const TemplateSetsTable = ({
  isLoading,
  data,
  empty,
  onAddTemplatesToSeletionSidebar,
  rowActions,
  childRowActions,
  visibleItems,
  onSearchChange,
  onSortByChange,
}) => {
  const { sidebarItemTotalNumber, containedInSidebar } = useMst((store) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useObserver(() => ({
      sidebarItemTotalNumber: store.sidebarItems.items.length,
      containedInSidebar: store.sidebarItems.containedInSidebar,
    })),
  );

  const renderAddButtonOrAddedIcon = (templateSet) => {
    if (templateSet.children.length === 0) {
      return null;
    }

    if (containedInSidebar(templateSet.children)) {
      return (
        <div className="flex">
          <div className="mr-2 flex items-center">
            <ItemSelectedIcon />
          </div>
          <div className="leading-none">Added</div>
        </div>
      );
    }

    const disabled =
      sidebarItemTotalNumber >= TOTAL_DOCUMENT_LIMIT ||
      sidebarItemTotalNumber + templateSet.children.length >
        TOTAL_DOCUMENT_LIMIT;

    return (
      <Button
        data-tip
        data-for={`add-button-${templateSet.id}`}
        onClick={(event) => {
          event.stopPropagation();
          onAddTemplatesToSeletionSidebar(templateSet);
        }}
        disabled={disabled}
      >
        <span>Add</span>

        <ReactTooltip
          id={`add-button-${templateSet.id}`}
          place="bottom"
          effect="solid"
          getContent={() => {
            return disabled
              ? 'Cannot add more than 20 documents to selection.'
              : null;
          }}
        ></ReactTooltip>
      </Button>
    );
  };

  const renderRowAction = (template, { toggleChildren, showChildren }) => {
    return (
      <div className="flex">
        <div className="mr-6 flex items-center">
          {renderAddButtonOrAddedIcon(template)}
        </div>

        {rowActions && (
          <RowActions
            actions={rowActions}
            template={template}
            toggleChildren={toggleChildren}
            showChildren={showChildren}
          />
        )}
      </div>
    );
  };

  const renderChildRowAction = (template, { rowData }) => {
    return (
      <ChildRowActions
        templateSetId={rowData.parentRow.id}
        template={template}
        disabledRemove={rowData.parentRow.children.length === 1}
        actions={childRowActions}
      />
    );
  };

  return (
    <div analyticsname="Template Set Actions">
      <Table
        isLoading={isLoading}
        empty={empty}
        emptyTitle={'No template sets match that filter'}
        searchPlaceholder="Search sets"
        data={data}
        visibleItems={visibleItems}
        rowClickable={true}
        onSearchChange={onSearchChange}
        onSortByChange={onSortByChange}
        columns={[
          {
            label: 'Set name',
            dataKey: 'title',
            render: renderNameColumn,
            style: {
              width: '60%',
              textAlign: 'left',
              textOverflow: 'ellipsis',
              overfllow: 'hidden',
            },
          },
          {
            label: 'Actions',
            hideLabel: true,
            render: renderRowAction,
            style: { flexGrow: 1, display: 'flex', justifyContent: 'flex-end' },
          },
        ]}
        childColumns={[
          {
            label: 'Name',
            dataKey: 'title',
            style: {
              width: '20%',
              textAlign: 'left',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            },
            render: renderChildNameColumn,
          },
          {
            label: 'Subtitle',
            dataKey: 'subtitle',
            style: {
              width: '60%',
              textAlign: 'left',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            },
            render: renderChildNameColumn,
          },
          {
            label: 'Actions',
            hideLabel: true,
            render: renderChildRowAction,
            style: { flexGrow: 1, display: 'flex', justifyContent: 'flex-end' },
          },
        ]}
      />
    </div>
  );
};

export default TemplateSetsTable;
