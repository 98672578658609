import {
  TextField,
  InputAdornment,
  CircularProgress,
  styled,
  circularProgressClasses,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import React from 'react';

const StyledCircularProgress = styled(CircularProgress)(`
  & .${circularProgressClasses.svg} {
    display:block !important
  }
`);

type SearchInputProps = {
  value: string;
  searching: boolean;
  onChange: (value: string) => void;
};

export const SearchInput = ({
  value,
  onChange,
  searching,
}: SearchInputProps) => {
  return (
    <TextField
      InputProps={{
        startAdornment: (
          <InputAdornment position={'start'}>
            <Search />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position={'end'}>
            <StyledCircularProgress
              size={22}
              sx={{
                visibility: searching ? 'visible' : 'hidden',
              }}
            />
          </InputAdornment>
        ),
      }}
      analyticsname="Single Matter Sync - Matter Search Input"
      size={'small'}
      fullWidth
      placeholder={'Search'}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};
