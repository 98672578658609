/* Libraries */
import React, { useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { inject } from 'mobx-react';

/* Higher Order Components */
import WithLayoutProps from '~/src/hoc/WithLayoutProps';

/* Components */
import Button from '~/src/components/Button';
import { Form, renderFormField } from '~/src/components/Forms';
import { Label } from '~/src/components/Inputs';

/* Styles */
import theme from '~/src/theme';

/* Constants */
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import styles from './styles';

const customStyles = StyleSheet.create({
  phoneFormRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  phoneButton: {
    minWidth: '120px',
    marginLeft: theme.unit * 2,
  },
});

const PhoneVerificationForm = (props) => {
  const { onCancel, onSendVerificationText, onConfirmVerificationText } = props;

  const [phone, setPhone] = useState('');
  const [phoneValid, setPhoneValid] = useState(false);
  const [phoneVerificationSent, setPhoneVerificationSent] = useState(false);
  const [phoneError, setPhoneError] = useState(null);

  const [verficationCode, setVerificationCode] = useState('');
  const [verificationCodeError, setVerificationCodeError] = useState(null);

  const handlePhoneFormChange = (form) => {
    const phone = form.fields.phone.value;

    setPhone(phone);
    setPhoneValid(phone.length > 1);
  };

  const handleSendVerficationText = async () => {
    const res = await onSendVerificationText(phone);

    if (res) {
      setPhoneVerificationSent(true);
    } else {
      setPhoneError('Not a valid phone number');
    }
  };

  const handleConfirmVerificationText = async () => {
    const res = await onConfirmVerificationText(phone, verficationCode);

    if (res) {
      onCancel();
    } else {
      setVerificationCodeError('Not valid');
    }
  };

  const handleVerificationCodeChange = (form) => {
    setVerificationCode(form.fields.phoneVerification.value);
  };

  const phoneNumberFields = [
    {
      id: 'phone',
      hint: '',
      type: 'text',
      inline: true,
      error: phoneError,
      // disabled: phoneVerificationSent,
      validation: () => true,
    },
  ];

  const verificationFields = [
    {
      id: 'phoneVerification',
      hint: 'Enter the six-digit code received on your mobile phone',
      type: 'text',
      error: verificationCodeError,
      // disabled: true,
      validation: () => true,
    },
  ];

  const renderPhoneNumberFields = phoneNumberFields.map((field) =>
    renderFormField(field),
  );
  const renderVerificationFields = verificationFields.map((field) =>
    renderFormField(field),
  );

  return (
    <div className={css(styles.modalContainer)}>
      <h1 className={css(styles.modalTitle)}>Enable two-step verification</h1>
      <div className={css(styles.modalBody)}>
        <Label
          hint={
            "Enter the phone number of the mobile device you'd like your authentication sent to each time you log in"
          }
        />
        <div className={css(customStyles.phoneFormRow)}>
          <Form
            fields={renderPhoneNumberFields}
            onChange={handlePhoneFormChange}
            className="flex-grow"
          />
          <div className={css(customStyles.phoneButton)}>
            <Button disabled={!phoneValid} onClick={handleSendVerficationText}>
              {phoneVerificationSent ? 'Resend Code' : 'Send Code'}
            </Button>
          </div>
        </div>
      </div>
      <div className={css(styles.modalBody)}>
        <Form
          fields={renderVerificationFields}
          onChange={handleVerificationCodeChange}
        />
      </div>
      <div className={css(styles.modalActions)}>
        <Button cssStyle={styles.buttonAction} onClick={onCancel}>
          Cancel
        </Button>{' '}
        <Button
          disabled={!phoneVerificationSent}
          cssStyle={styles.buttonAction}
          onClick={handleConfirmVerificationText}
          primary
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

const AuthTypeForm = (props) => {
  const { onCancel, onConfirm, ssoMethod } = props;
  const [formValue, setFormValue] = useState({});

  const handleFormChange = (form) => {
    setFormValue(form);
  };

  const handleConfirm = () => {
    onConfirm(formValue);
  };

  const fields = [
    {
      id: 'authTypes',
      label: 'Select a two-step verification method',
      type: 'multipleChoice',
      defaultValue: ssoMethod === 1 ? 'email' : 'phone',
      options: [
        { label: 'Email', value: 'email' },
        { label: 'SMS Text Message', value: 'phone' },
      ],
      validation: () => true,
    },
  ];

  let isEmail = false;

  if (formValue && formValue.fields && formValue.fields.authTypes.value) {
    isEmail = formValue.fields.authTypes.value === 'email';
  }

  const renderFields = fields.map((field) => renderFormField(field));

  return (
    <div className={css(styles.modalContainer)}>
      <h1 className={css(styles.modalTitle)}>Enable two-step verification</h1>
      <div className={css(styles.modalBody)}>
        <Form
          triggerOnMount
          fields={renderFields}
          onChange={handleFormChange}
        />
      </div>
      <div className={css(styles.modalActions)}>
        <Button cssStyle={styles.buttonAction} onClick={onCancel}>
          Cancel
        </Button>{' '}
        <Button cssStyle={styles.buttonAction} onClick={handleConfirm} primary>
          {isEmail ? 'Confirm' : 'Next'}
        </Button>
      </div>
    </div>
  );
};

class TwoFactorAuthModal extends React.Component {
  state = {
    showPhoneVerfication: false,
  };

  sendVerificationText = async (phone) => {
    const res = await this.props.store.user.sendTwoFactorAuthSmsCode(phone);

    if (res) {
      this.props.showToast(LAYOUT_TOAST_TYPES.success, {
        message: 'Code Sent!',
      });
    }

    return res;
  };

  confirmVerificationText = async (phone, verificationCode) => {
    const res = await this.props.store.user.verifyTwoFactorAuthSmsCode(
      phone,
      verificationCode,
    );
    if (res) {
      this.props.showToast(LAYOUT_TOAST_TYPES.success, {
        message: 'Two-factor auth setup complete!',
      });
    }
    return res;
  };

  handleAuthTypeConfirm = async (form) => {
    const authType = form.fields.authTypes.value;

    this.props.store.user.enableTwoFactorAuth(authType);

    if (authType === 'phone') {
      this.setState({ showPhoneVerfication: true });
    } else {
      this.props.showToast(LAYOUT_TOAST_TYPES.success, {
        message: 'Two-factor auth setup complete!',
      });
      this.onCancel();
    }
  };

  onCancel = () => {
    this.setState({ showPhoneVerfication: false });
    this.props.onCancel();
  };

  render() {
    const { onCancel } = this;
    const { showPhoneVerfication } = this.state;
    const { ssoMethod } = this.props.store.user.profile;

    return showPhoneVerfication ? (
      <PhoneVerificationForm
        onCancel={onCancel}
        onSendVerificationText={this.sendVerificationText}
        onConfirmVerificationText={this.confirmVerificationText}
      />
    ) : (
      <AuthTypeForm
        ssoMethod={ssoMethod}
        onConfirm={this.handleAuthTypeConfirm}
        onCancel={onCancel}
      />
    );
  }
}

export default inject((store) => store)(WithLayoutProps(TwoFactorAuthModal));
