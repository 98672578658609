/* Libraries */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';

/* Components */
import SettingsBilling from '~/src/components/Settings/SettingsBilling';

/* Hooks */
import useUser from '~/src/hooks/useUser';

const SettingsBillingContainer = observer(() => {
  const { currentOrganization } = useUser();
  useEffect(() => {
    currentOrganization.fetchReceipts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let receipts = [];
  if (currentOrganization.receipts) {
    receipts = currentOrganization.receipts.map(
      ({ created, amount_captured, reciept_url }) => ({
        created: moment(created * 1000).format('MM/DD/YYYY'),
        amount_captured: `$${amount_captured / 100}`,
        reciept_url,
      }),
    );
  }

  return <SettingsBilling receipts={receipts} />;
});

export default SettingsBillingContainer;
