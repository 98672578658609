import * as React from 'react';
import { css } from 'aphrodite';

import styles from './styles';

type CardProps = {
  renderHeader?: () => React.ReactNode;
  renderFooter?: () => React.ReactNode;
  renderActions?: () => React.ReactNode;
  className?: string;
  cssStyle?: React.CSSProperties;
  noPadding?: boolean;
  large?: boolean;
  small?: boolean;
  dynamic?: boolean;
  children?: React.ReactNode;
  elevate?: number;
};

const Card = ({
  className,
  cssStyle,
  large,
  small,
  dynamic,
  children,
  renderHeader,
  renderFooter,
  renderActions,
  elevate,
  noPadding,
}: CardProps) => (
  <div
    className={`${css(
      styles.card,
      small && styles.cardSmall,
      large && styles.cardLarge,
      !!elevate && styles.cardElevate,
      elevate === 1 && styles.cardElevateOne,
      dynamic && styles.cardDynamic,
      cssStyle,
    )} ${className}`}
  >
    {renderHeader && (
      <div className={css(styles.cardHeader)}>{renderHeader()}</div>
    )}
    <div className={css(styles.cardBody, noPadding && styles.cardNoPadding)}>
      {children}
    </div>
    {renderActions && (
      <div className={css(styles.cardActions)}>{renderActions()}</div>
    )}
    {renderFooter && (
      <div className={css(styles.cardFooter)}>{renderFooter()}</div>
    )}
  </div>
);

export default Card;
