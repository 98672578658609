import React from 'react';
import { Plus } from 'react-feather';

const AddAboveButtonBar = () => (
  <>
    <div className="rounded-full bg-blue-500 absolute w-6 h-6 flex items-center justify-center -mt-3 z-50 left-1/2 top-0 -ml-3">
      <Plus size={14} color={'#FFFFFF'} />
    </div>
    <div className="bg-blue-500 h-0.5 absolute left-0 right-0 -top-px rounded">
      {' '}
    </div>
  </>
);

export default AddAboveButtonBar;
