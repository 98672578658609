import React from 'react';
import { css } from 'aphrodite';

import theme from '~/src/theme';

import styles from './styles';

const Banner = ({
  Icon,
  children,
  alert,
  small,
  fullWidth,
  onIconClick,
  iconColor,
  neutral,
  center,
  paleEggplant,
}) => (
  <div
    className={css(
      styles.banner,
      small && styles.bannerSmall,
      alert && styles.bannerAlert,
      Icon && styles.bannerIcon,
      fullWidth && styles.bannerFullWidth,
      neutral && styles.bannerNeutral,
      center && styles.bannerHorizontalCenter,
      paleEggplant && styles.bannerPaleEggplant,
    )}
  >
    {Icon && (
      <i
        onClick={onIconClick}
        className={css(styles.icon, onIconClick && styles.clickableIcon)}
      >
        <Icon size={16} color={iconColor || theme.colors.charcoal} />
      </i>
    )}
    {children}
  </div>
);

export default Banner;
