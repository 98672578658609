import { Questionnaire } from '@clio/questionnaire-builder';
import { useMutation, useQueryClient } from 'react-query';
import { APIError } from '~/src/utils/error';
import {
  getClioQuestionnaireAuthToken,
  questionnaireAuthorizationHeaders,
} from '../utils';
import { questionnaireQueryArgs } from '../queryArgs';
import { getLogoAttachUrl } from '../urls';

type UploadLogo = {
  questionnaireId: string;
  logo: File;
};

export const useUploadLogo = (orgFprint: string) => {
  const queryClient = useQueryClient();

  return useMutation<Questionnaire, APIError, UploadLogo>(
    async ({ questionnaireId, logo }) => {
      const token = await getClioQuestionnaireAuthToken(orgFprint);

      const formData = new FormData();
      formData.append('logo', logo);

      const response = await fetch(getLogoAttachUrl(questionnaireId), {
        method: 'PUT',
        headers: {
          ...questionnaireAuthorizationHeaders(token),
        },
        body: formData,
      });

      if (!response.ok) {
        throw new APIError('Failed to upload logo', response);
      }

      return response.json();
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(
          questionnaireQueryArgs.detail(String(data.id)),
        );
      },
    },
  );
};
