import { Core } from '@pdftron/webviewer';
import assert from 'assert';
import { ChangeEventHandler } from 'react';
import { Project } from '~/src/models';

type GetFieldByElementId = (
  id: string,
) => Core.Annotations.Forms.Field | undefined;
type GetElementByField = (
  field: Core.Annotations.Forms.Field,
) => Project.DocumentElement | undefined;

export const getIsSumOrSubtractField = (
  computedFieldType: Project.DocumentElement['computedFieldType'],
) =>
  computedFieldType === Project.ComputedFieldType.Subtract ||
  computedFieldType === Project.ComputedFieldType.Sum;

const WRAPPED_BY_PARENTHESIS = /^\((.*)\)$/;

const isParenthesized = (val: string) => WRAPPED_BY_PARENTHESIS.test(val);

const parseNumber = (val: string = '0') => {
  // replace leading $ and commas
  const multiplyBy = isParenthesized(val) ? -1 : 1;
  const parsed = val
    .replace(WRAPPED_BY_PARENTHESIS, '$1')
    .replace(/^(-)?\$/, '$1')
    .replace(/,/g, '');

  return +parsed * multiplyBy;
};

type SumFieldManager = {
  addComputedElement: (element: Project.DocumentElement) => void;
  destroy: () => void;
};

export const createMathFieldManager = (
  documentViewer: Core.DocumentViewer,
  getFieldByElementId: GetFieldByElementId,
  getElementByField: GetElementByField,
): SumFieldManager => {
  // map of reference element id to the sum/subtract element ids it is a part of
  const referenceElementIdMap: Record<string, string[]> = {};

  // map of a sum/subtract field id to it's reference element ids
  const computedFieldElementIdMap: Record<string, string[]> = {};

  const addComputedElement = (element: Project.DocumentElement) => {
    assert(getIsSumOrSubtractField(element.computedFieldType));
    const referenceElementIds = element.computedFieldTag?.split(',') || [];
    if (!referenceElementIds.length) {
      console.error(
        `MathManager - No constituent elements found for math field ${JSON.stringify(
          element,
        )}`,
      );
      return;
    }
    computedFieldElementIdMap[element.id] = referenceElementIds;
    referenceElementIds.forEach((id) => {
      const sumOrSubtractFields = referenceElementIdMap[id] || [];
      sumOrSubtractFields.push(`${element.id}`);
      referenceElementIdMap[id] = sumOrSubtractFields;
    });
  };

  const _getNumericFieldValue = (elementId: string) => {
    const rawFieldValue = getFieldByElementId(elementId)?.getValue();
    const fieldValue = parseNumber(
      rawFieldValue ? `${rawFieldValue}` : undefined,
    );

    return isNaN(fieldValue) ? 0 : fieldValue;
  };

  const _sum = (referenceElementIds: string[]) => {
    return referenceElementIds
      .map(_getNumericFieldValue)
      .reduce((acc, val) => val + acc, 0);
  };

  const _subtract = (minuendId: string, subtrahendId: string) => {
    const minuend = _getNumericFieldValue(minuendId);
    const subtrahend = _getNumericFieldValue(subtrahendId);
    return minuend - subtrahend;
  };

  const _calculate = (computedFieldElementId: string) => {
    const referenceElementIds =
      computedFieldElementIdMap[computedFieldElementId]!;

    const computedField = getFieldByElementId(computedFieldElementId);
    if (!computedField) {
      console.error(
        `MathManager - Expected math field for ${computedFieldElementId}`,
      );
      return;
    }

    const fieldType = getElementByField(computedField);

    switch (fieldType?.computedFieldType) {
      case Project.ComputedFieldType.Sum: {
        const value = _sum(referenceElementIds);
        computedField.setValue(value.toFixed(2));
        break;
      }
      case Project.ComputedFieldType.Subtract: {
        if (referenceElementIds.length != 2) {
          console.error(
            `MathManager - More/less than two element ids found for subtract element id ${computedFieldElementId} - ${referenceElementIds.join(
              ',',
            )}`,
          );
        }
        const value = _subtract(
          referenceElementIds[0]!,
          referenceElementIds[1]!,
        );
        computedField.setValue(value.toFixed(2));
        break;
      }
      default:
        console.error(
          `MathManager - cannot calculate value for unknown computedFieldType ${fieldType?.computedFieldType}`,
        );
        break;
    }
  };

  const _keyUplistener: ChangeEventHandler<HTMLInputElement> = (event) => {
    const fieldId = event.target.parentElement?.id;

    if (!fieldId) return;
    const field = documentViewer
      .getAnnotationManager()
      .getFieldManager()
      .getField(fieldId) as Core.Annotations.Forms.Field;
    const elementData = getElementByField(field);
    if (!elementData) return;

    if (!(elementData?.id in referenceElementIdMap)) return;
    field.setValue(event.target.value);
  };

  const _fieldChangedListener = (field: Core.Annotations.Forms.Field) => {
    const elementData = getElementByField(field);

    if (!elementData) {
      const page = (field as any)?.AH?.$d;
      const fileName = (field as any)?.AH?.ba?.ba?.Eb?.filename;
      console.error(
        `MathManager - No element data found for file: ${fileName} page: ${page} field: ${field.name}`,
      );
      return;
    }
    referenceElementIdMap[elementData.id]?.forEach(_calculate);
  };

  documentViewer.addEventListener('keyUp', _keyUplistener);
  documentViewer
    .getAnnotationManager()
    .addEventListener('fieldChanged', _fieldChangedListener);

  const destroy = () => {
    documentViewer.removeEventListener('keyUp', _keyUplistener);
    documentViewer
      .getAnnotationManager()
      .removeEventListener('fieldChanged', _fieldChangedListener);
  };

  return { addComputedElement, destroy };
};
