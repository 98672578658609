import { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { Project, unsanitizeProjectRecord } from '~/src/entities/project';
import { useUpdateProject } from '~/src/entities/project/hooks';
import { useDebouncedValue } from '~/src/hooks/useDebouncedValue';

type ProjectUpdaterProps = {
  project: Project;
  isProgressTrackerEnabled: boolean;
  onSaving: () => void;
  onSaved: () => void;
};

interface Dictionary {
  [key: string]: any;
}

const areAllValuesEmptyStrings = (dict: Dictionary) => {
  return Object.values(dict).every((value) => value === '');
};

/*
This is a very hacky solution but unfortunately there is no good way to fix the following bug:
Prevent the populate page from sending a POST to the backend when the page first loads. 

I've explored a couple options:
1. Using the formState.isDirty prop to determine when a user first types in a field
    Due to the way we are setting default values to our fields, this boolean is set to true mid-render
    There is no callback that says we're done loading default values into all the fields.
2. Using some sort of "page is done loading and fields are populated"
    AFAIK there is no such flag we can use
    We COULD use a timer combined with a window load event handler to set a flag ourselves but it's still hacky.  

The solution that I've implemented is based on the fact that our useEffect hook will always fire once before reaching a steady state.
The first time useEffect is called, the mutation will not be called. 

*/
enum ProjectUpdaterStates {
  NO_DATA,
  PRELOADING_INPUTS,
  USER_INPUT_EDITABLE,
}

const areDictionariesEqual = (
  dict1: Dictionary,
  dict2: Dictionary,
): boolean => {
  // Check if both dictionaries have the same number of keys
  if (Object.keys(dict1).length !== Object.keys(dict2).length) {
    return false;
  }

  // Check each key in dict1 to see if it exists in dict2 and if the values are the same
  for (let key in dict1) {
    if (Object.prototype.hasOwnProperty.call(dict1, key)) {
      if (
        !Object.prototype.hasOwnProperty.call(dict2, key) ||
        dict1[key] !== dict2[key]
      ) {
        return false;
      }
    }
  }

  return true;
};

const ProjectUpdater = ({
  project,
  isProgressTrackerEnabled,
  onSaving,
  onSaved,
}: ProjectUpdaterProps) => {
  const { mutate } = useUpdateProject({
    onMutate: async () => {
      onSaving();
    },
    onSettled: () => {
      onSaved();
    },
  });

  const watchedValues = useWatch();
  const debouncedWatchedValues = useDebouncedValue(watchedValues, 1500);
  const [componentState, setComponentStates] = useState(
    ProjectUpdaterStates.NO_DATA,
  );

  useEffect(() => {
    /*
    For the first time that useEffect is fired, don't call the mutation. 
    Refer to the comment on ProjectUpdaterStates for more details. 
    */
    if (componentState === ProjectUpdaterStates.NO_DATA) {
      setComponentStates(ProjectUpdaterStates.PRELOADING_INPUTS);
    } else if (componentState === ProjectUpdaterStates.PRELOADING_INPUTS) {
      setComponentStates(ProjectUpdaterStates.USER_INPUT_EDITABLE);
    }

    // Only mutate when the dictionaries are not the same
    if (
      !areDictionariesEqual(project.stack_saved_data, debouncedWatchedValues) &&
      Object.keys(debouncedWatchedValues).length > 0 &&
      // Prevent empty initial payload from being sent.
      !(
        areAllValuesEmptyStrings(debouncedWatchedValues) &&
        Object.keys(project.stack_saved_data).length == 0
      ) &&
      componentState === ProjectUpdaterStates.USER_INPUT_EDITABLE
    ) {
      mutate({
        projectId: project.id,
        stack_saved_data: unsanitizeProjectRecord(debouncedWatchedValues),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedWatchedValues,
    isProgressTrackerEnabled,
    mutate,
    project.id,
    project.stack_saved_data,
  ]);

  return null;
};

export default ProjectUpdater;
