import { useState, CSSProperties, useCallback } from 'react';
import { Position } from './useWebViewerContext';

export const useWebViewerContextAPI = () => {
  const [webViewerContainerStyles, setWebViewerContainerStyles] =
    useState<CSSProperties>({
      position: 'absolute',
      display: 'flex',
      width: 'auto',
      height: 'auto',
      visibility: 'hidden',
      zIndex: -1,
    });

  const makeWebViewerVisible = useCallback(() => {
    setWebViewerContainerStyles((styles) => ({
      ...styles,
      zIndex: 1,
      visibility: 'visible',
    }));
  }, []);

  const setWebViewerPosition = useCallback((pos: Position = {}) => {
    setWebViewerContainerStyles((styles) => ({
      ...styles,
      ...pos,
    }));
  }, []);

  const hideWebViewer = useCallback(() => {
    setWebViewerContainerStyles((styles) => ({
      ...styles,
      zIndex: -1,
      visibility: 'hidden',
    }));
  }, []);

  const setZIndexOfWebViewer = useCallback((zIndex: number) => {
    setWebViewerContainerStyles((styles) => ({ ...styles, zIndex }));
  }, []);

  return {
    webViewerContainerStyles,
    makeWebViewerVisible,
    setWebViewerPosition,
    hideWebViewer,
    setZIndexOfWebViewer,
  };
};
