import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

export default StyleSheet.create({
  rowActionButton: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.unit * 1}px ${theme.unit * 2}px`,
    paddingRight: theme.unit,
  },
  newSignatureButton: {
    marginLeft: 'auto !important',
  },
  emailMessageField: {
    height: '100% !important',
  },
  signaturePackageDetailsComponent: {
    display: 'flex',
    justifyContent: 'flex-end',
    '> p': {
      lineHeight: '20px',
      fontSize: '12px',
    },
  },
  signaturePackageDetailsCard: {
    margin: '0px !important',
    width: '300px !important',
  },
});
