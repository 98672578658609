import React, { FC } from 'react';
import SignerSelectionContainer from '~/src/containers/SignerSelectionContainer';
import { SignaturePackage } from '~/src/models';
import useIsValid from '../components/SignaturePackage/hooks/useIsValid';
import HeaderFooter from './HeaderFooter';

interface Props {
  signaturePackage: SignaturePackage.SignaturePackage;
  onNext: () => void;
  onBack: () => void;
  onExit: () => void;
}

const SignerSelectionWithLayoutContainer: FC<Props> = ({
  signaturePackage,
  onNext,
  onBack,
  onExit,
}) => {
  const { valid, setValid, invalidReason } = useIsValid();

  return (
    <HeaderFooter
      signaturePackageTitle={signaturePackage.title}
      whoSigns={signaturePackage.whoSigns}
      step={'add-signatures'}
      invalidReason={invalidReason}
      valid={valid}
      onBackClick={onBack}
      onNextClick={onNext}
      onExitClick={onExit}
    >
      <SignerSelectionContainer
        signaturePackage={signaturePackage}
        setValid={setValid}
      />
    </HeaderFooter>
  );
};

export default SignerSelectionWithLayoutContainer;
