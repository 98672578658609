import { http, getPaginationUrlQuery } from '~/src/services/requests';

import { PaginatedParams } from '~/src/globalTypes';
import { TemplateDefaultValuesApiResponse, UpdatePayload } from '~/src/models';

export interface RelatedTemplateSetsCount {
  count: number;
}

const templateDefaultValuesApiService = () => {
  return {
    getTemplateDefaultValuesList: async ({
      page,
      search,
      sortBy,
      order,
      limit,
      orgFprint,
    }: PaginatedParams): Promise<TemplateDefaultValuesApiResponse[]> => {
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/form-templates/${getPaginationUrlQuery(
            {
              page,
              search,
              sortBy,
              order,
              limit,
            },
          )}`,
          method: 'GET',
        },
        true,
      );
    },

    getTemplateDefaultValuesByIds: async (
      orgFprint: string,
      formTemplateIds: number[],
    ): Promise<TemplateDefaultValuesApiResponse[]> => {
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/form-templates/?ids=${formTemplateIds.join(
            ',',
          )}`,
          method: 'GET',
        },
        true,
      );
    },

    getTemplateDefaultValues: async (
      orgFprint: string,
      id: number,
    ): Promise<TemplateDefaultValuesApiResponse> => {
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/form-template/${id}/`,
          method: 'GET',
        },
        true,
      );
    },

    createTemplateDefaultValues: async (
      orgFprint: string,
      templateId: number,
    ): Promise<TemplateDefaultValuesApiResponse> => {
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/form-templates/`,
          method: 'POST',
          body: {
            template_id: templateId,
          },
        },
        true,
      );
    },

    updateTemplateDefaultValues: async (
      orgFprint: string,
      id: number,
      updatePayload: UpdatePayload,
    ): Promise<TemplateDefaultValuesApiResponse> => {
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/form-template/${id}/`,
          method: 'PUT',
          body: updatePayload,
        },
        true,
      );
    },

    checkTemplateDefaultValuesExistInTemplateSets: async (
      orgFprint: string,
      id: number,
    ): Promise<RelatedTemplateSetsCount> => {
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/template-sets/form-template/${id}/`,
          method: 'GET',
        },
        true,
      );
    },

    deleteTemplateDefaultValues: async (
      orgFprint: string,
      id: number,
    ): Promise<null> => {
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/form-template/${id}/`,
          method: 'DELETE',
        },
        false,
      );
    },

    duplicateTemplateDefaultValues: async (
      orgFprint: string,
      id: number,
      updatePayload: UpdatePayload,
    ): Promise<TemplateDefaultValuesApiResponse> => {
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/form-template/${id}/duplicate/`,
          method: 'POST',
          body: updatePayload,
        },
        true,
      );
    },
  };
};

export default templateDefaultValuesApiService();
