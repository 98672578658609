import { useMutation } from 'react-query';
import env from '~/src/utils/env';

export const LOGOUT_EMULATED_USER_URL = `${env.apiUrl}/api/v2/account/logout-emulated-user/`;

const fetchLogout = () => fetch(LOGOUT_EMULATED_USER_URL, { method: 'PUT' });
const onSuccess = () => {
  window.location.assign('/clients');
};

export const logoutEmulateduser = async () => {
  await fetchLogout();
  onSuccess();
};

export const useLogoutEmulatedUser = () =>
  useMutation(fetchLogout, { onSuccess });
