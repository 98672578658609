import React, { Component } from 'react';
import TextWithIndicator from '~/src/components/SignerSelection/TextWithIndicator';
import theme from '~/src/theme';
import colors from '~/src/theme/colors';
import T from 'prop-types';

interface Props {
  indicatorColor: string;
  first?: boolean;
  last?: boolean;
  onSignerSelected: () => void;
}

interface State {
  hover: boolean;
}

/**
 * The menu that allows the lawyer to select a signer for
 * a field on a document.
 *
 * NOTE: This is rendered from within WebViewer inside an iframe,
 * and is therefore limited in the following ways:
 *   1. This component (and children) cannot use hooks
 *      (Not sure why but it causes a runtime error)
 *   2. This component (and children) cannot rely on our app's
 *      stylesheets because it's rendered in an iframe.
 */
class SignerMenuItem extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hover: false,
    };
  }

  static propTypes = {
    // indicatorColor: hexCode.isRequired, // this line causes => Warning: Failed prop type: Invalid prop `indicatorColor` supplied to `SignerMenuItem`. Must be a valid color code.
    indicatorColor: T.string,

    first: T.bool,
    last: T.bool,
    onSignerSelected: T.func.isRequired,
  };

  toggleHover = () => {
    this.setState({ hover: !this.state.hover });
  };

  render() {
    const { children, onSignerSelected, indicatorColor, first, last } =
      this.props;
    const { hover } = this.state;
    return (
      <TextWithIndicator
        indicatorColor={indicatorColor}
        style={{
          padding: 10,
          cursor: 'pointer',
          borderBottom: !last ? theme.border : 'none',
          backgroundColor: hover ? colors.smoke : 'white',
          borderTopLeftRadius: first ? theme.borderRadius : 'none',
          borderTopRightRadius: first ? theme.borderRadius : 'none',
          borderBottomLeftRadius: last ? theme.borderRadius : 'none',
          borderBottomRightRadius: last ? theme.borderRadius : 'none',
        }}
        onMouseEnter={this.toggleHover}
        onMouseLeave={this.toggleHover}
        onClick={onSignerSelected}
      >
        {children}
      </TextWithIndicator>
    );
  }
}

export default SignerMenuItem;
