import { useEffect, useState } from 'react';
import { useStore } from '~/src/hooks/useMst';
import { delightedNpsSnippet } from './delightedCodeSnippet';
import { getMemberships } from '~/src/entities/user/hooks/useMemberships';
import { getInitialSurveyDelaySecondsAfterCreation } from './helpers';
import env from '~/src/utils/env';

const DelightedNpsIntegration = () => {
  const showSurveyCurrentEnvironment = env.isDevelopmentMode ? false : true;
  const store = useStore();
  const { user } = store;
  const membership = getMemberships();
  const createdAt = membership?.member_info.created_at;

  const [isDelightedLoaded, setIsDelightedLoaded] = useState(false);

  // function to check if the delighted script is loaded in the DOM properly
  const getDelightedLoaded = () => {
    if ((window as any)?.delightedNps5?.survey) {
      setIsDelightedLoaded(true);
      return true;
    }
    return false;
  };

  // when this component renders, create a MutationObserver to listen to changes in DOM.
  // by doing this, we can identify when the delighted script is fully loaded.
  useEffect(() => {
    delightedNpsSnippet(window, document, 'y1dFeDqKgBdfznoE', 'delightedNps5');

    if (getDelightedLoaded()) {
      return;
    }

    const observer = new MutationObserver(() => {
      if (getDelightedLoaded()) {
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const showSurvey =
    showSurveyCurrentEnvironment && !!createdAt && !!user && isDelightedLoaded;

  useEffect(() => {
    if (showSurvey) {
      const metadata = {
        org_id: membership.memberships[0]?.org_id,
        user_id: membership.member_info.authenticated_user_id,
      };

      (window as any).delightedNps5.survey({
        email: user.currentOrganization?.email,
        name: user.profile?.displayName,
        createdAt: createdAt,
        initialDelay: getInitialSurveyDelaySecondsAfterCreation(createdAt),
        properties: metadata,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSurvey]);

  return null;
};

export default DelightedNpsIntegration;
