import {
  TextInput,
  TextArea,
  FileUpload,
  Incrementer,
  DatePickerInput,
  StaticText,
  MultipleChoiceInput,
} from '~/src/components/Inputs';

export const QUESTION_TYPE_SHORT_TEXT = 'SHORT_TEXT';
export const QUESTION_TYPE_LONG_TEXT = 'LONG_TEXT';
export const QUESTION_TYPE_DATE = 'DATE';
export const QUESTION_TYPE_NUMBER = 'NUMBER';
export const QUESTION_TYPE_STATIC_TEXT = 'STATIC_TEXT';
export const QUESTION_TYPE_MULTIPLE_CHOICE = 'MULTIPLE_CHOICE';
export const QUESTION_TYPE_MULTI_SELECT = 'MULTI_SELECT';
export const QUESTION_TYPE_FILE_UPLOAD = 'FILE_UPLOAD';

export const QUESTION_TYPE_LOOKUP = {
  [QUESTION_TYPE_SHORT_TEXT]: TextInput,
  [QUESTION_TYPE_LONG_TEXT]: TextArea,
  [QUESTION_TYPE_DATE]: DatePickerInput,
  [QUESTION_TYPE_NUMBER]: Incrementer,
  [QUESTION_TYPE_STATIC_TEXT]: StaticText,
  [QUESTION_TYPE_MULTIPLE_CHOICE]: MultipleChoiceInput,
  [QUESTION_TYPE_MULTI_SELECT]: MultipleChoiceInput,
  [QUESTION_TYPE_FILE_UPLOAD]: FileUpload,
};

export const QUESTION_TYPE_OPTIONS = [
  { label: 'Short Text', value: QUESTION_TYPE_SHORT_TEXT },
  { label: 'Long Text', value: QUESTION_TYPE_LONG_TEXT },
  { label: 'Date', value: QUESTION_TYPE_DATE },
  { label: 'Count', value: QUESTION_TYPE_NUMBER },
  { label: 'Multiple Choice', value: QUESTION_TYPE_MULTIPLE_CHOICE },
  { label: 'Multi-Select', value: QUESTION_TYPE_MULTI_SELECT },
  { label: 'File Upload', value: QUESTION_TYPE_FILE_UPLOAD },
];

const FILE_TYPES_MICROSOFT = [
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.ppt',
  '.pptx',
  '.xps',
];
const FILE_TYPES_ADOBE = [
  '.pdf',
  '.dxf',
  '.ai',
  '.psd',
  '.eps',
  '.ps',
  '.svg',
  '.ttf',
];
const FILE_TYPES_ARCHIVE = ['.zip', '.rar', '.tar', '.gzip'];
const FILE_TYPES_AUDIO = ['.mp3', '.mpeg', '.wav', '.ogg'];
const FILE_TYPES_IMAGE = ['.jpeg', '.jpg', '.png', '.gif', '.bmp', '.tif'];
const FILE_TYPES_VIDEO = [
  '.webm',
  '.mpeg4',
  '.3gpp',
  '.mov',
  '.mpegps',
  '.wmv',
  '.flv',
];
const FILE_TYPES_DOCUMENTS = [
  ...FILE_TYPES_MICROSOFT,
  ...FILE_TYPES_ADOBE,
  ...FILE_TYPES_ARCHIVE,
  '.txt',
];

export const UPLOAD_TYPES_OPTIONS = [
  { label: 'Document Files', value: FILE_TYPES_DOCUMENTS },
  { label: 'Image Files', value: FILE_TYPES_IMAGE },
  { label: 'Video Files', value: FILE_TYPES_VIDEO },
  { label: 'Audio Files', value: FILE_TYPES_AUDIO },
];

export const UPLOAD_LIMIT_OPTIONS = [
  { label: 'Single', value: '1' },
  { label: 'Multiple', value: 'Infinity' },
];
