import React from 'react';
import { PopulateCardFields } from '../PopulateCardFields';
import { getFieldsForGroup } from '../utils';
import { PopulatePageCardBody } from './PopulatePageCardBody';
import { PopulatePageCardProps } from './types';

export const PopulatePageOtherCard = ({
  group,
  project,
  ...props
}: PopulatePageCardProps) => {
  const { expanded, onChangeExpanded, callbackAfterExpanded } = props;

  return (
    <PopulatePageCardBody
      group={group}
      callbackAfterExpanded={callbackAfterExpanded}
      expanded={expanded}
      onChangeExpanded={() => {
        onChangeExpanded(!expanded);
      }}
    >
      <PopulateCardFields
        fields={getFieldsForGroup(project, group)}
        projectStackDocuments={project.stack_data.documents}
        {...props}
      />
    </PopulatePageCardBody>
  );
};
