import React from 'react';
import { css } from 'aphrodite';
import uuid from 'uuid/v4';

import theme from '~/src/theme';

import { useDropzone } from 'react-dropzone';

import { Plus } from 'react-feather';
import styles from './styles';

const AddFile = (props) => {
  const {
    disabled,
    uploading,
    maxFiles,
    multiple,
    acceptedDocs,
    onChange,
    onError,
  } = props;

  const handleDropAccepted = (files) => {
    const nextFiles = files.map((file) => {
      file.id = uuid();
      return file;
    });

    onChange(nextFiles);
  };

  const dropzoneProps = {
    disabled: disabled || uploading,
    accept: acceptedDocs,
    onDropAccepted: handleDropAccepted,
    onDropRejected: onError,
    maxFiles,
    multiple,
  };

  const { getRootProps, getInputProps, isDragActive } =
    useDropzone(dropzoneProps);

  return (
    <div
      className={css(styles.addFile, isDragActive && styles.addFileDragging)}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <Plus size={18} color={theme.colors.bluebird} />
    </div>
  );
};

export default AddFile;
