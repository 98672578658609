import React from 'react';
import { camelCase } from 'lodash';
import { css } from 'aphrodite';
import styles from './styles';

import Label from './Label';

const MultipleChoiceInput = (props) => {
  const { label, value, hint, onChange, onBlur, onFocus, options, required } =
    props;

  const id = camelCase(label);

  const fields = [];
  const handleChange = (event) => {
    const { value } = event.target;
    onChange(value);
  };

  options.forEach((field) => {
    const { label } = field;
    const checked = field.value === value;
    fields.push(
      <label
        key={id + field.value}
        className={css(
          styles.populateMultipleChoiceInputLabel,
          checked && styles.populateMultipleChoiceInputLabelChecked,
        )}
      >
        <input
          className={css(styles.populateMultipleChoiceInput)}
          type="radio"
          name={id + field.value}
          value={field.value}
          // onChange={handleChange}
          onClick={handleChange}
          checked={checked}
        />
        {label}
      </label>,
    );
  });

  return (
    <Label label={label} required={required} hint={hint}>
      <>
        <fieldset
          className={css(styles.multipleChoiceFieldset)}
          id={id}
          onFocus={onFocus}
          onBlur={onBlur}
        >
          {fields}
        </fieldset>
      </>
    </Label>
  );
};

export default MultipleChoiceInput;
