import { useQuery, UseQueryOptions } from 'react-query';
import { APIError } from '~/src/utils/error';
import { useCurrentOrgFprint } from '~/src/entities/user/hooks';
import { RelatedQuestionnaireSubmission } from '../types';
import { keys } from '../keys';
import { composeQueryOpts, fetchAndParse } from '../../utils';
import { getRelatedQuestionnaireSubmissionsByQuestionnaireSubmissionIdUrl } from '../urls';

export const useRelatedQuestionnaireSubmissionsByQuestionnaireSubmissionId = (
  questionnaireSubmissionId: number,
  options: UseQueryOptions<
    RelatedQuestionnaireSubmission[] | undefined,
    APIError
  > = {},
) => {
  const orgFprint = useCurrentOrgFprint();
  return useQuery(
    composeQueryOpts(options, {
      queryKey: questionnaireSubmissionId
        ? keys.listByQuestionnaireSubmission(questionnaireSubmissionId)
        : keys.lists(),
      queryFn: () =>
        fetchAndParse<RelatedQuestionnaireSubmission[] | undefined>(
          getRelatedQuestionnaireSubmissionsByQuestionnaireSubmissionIdUrl(
            orgFprint,
            questionnaireSubmissionId,
          ),
        ),
    }),
  );
};
