import {
  QueryClient,
  UseMutationOptions,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import { APIErrorFailedToParse, APIErrorNotOk } from '../utils/error';

export const useInvalidateQuery = (
  ...args: Parameters<QueryClient['invalidateQueries']>
) => {
  const client = useQueryClient();
  return () => client.invalidateQueries(...args);
};

export const useInvalidateQueryReturn = () => {
  const client = useQueryClient();
  return (...args: Parameters<QueryClient['invalidateQueries']>) =>
    client.invalidateQueries(...args);
};

export const composeMutationOpts = <
  T extends UseMutationOptions<any, any, any, any>,
>(
  ...args: T[]
): T => ({
  ...Object.assign({}, ...args),
  onSuccess: (...inner) => args.map(({ onSuccess }) => onSuccess?.(...inner)),
  onError: (...inner) => args.map(({ onError }) => onError?.(...inner)),
  onMutate: (...inner) => args.map(({ onMutate }) => onMutate?.(...inner)),
  onSettled: (...inner) => args.map(({ onSettled }) => onSettled?.(...inner)),
});

export const composeQueryOpts = <T extends UseQueryOptions<any, any, any, any>>(
  ...args: T[]
): T => ({
  ...Object.assign({}, ...args),
  onSuccess: (...inner) => args.map(({ onSuccess }) => onSuccess?.(...inner)),
  onError: (...inner) => args.map(({ onError }) => onError?.(...inner)),
  onSettled: (...inner) => args.map(({ onSettled }) => onSettled?.(...inner)),
});

export const fetcher = async (
  ...args: Parameters<typeof fetch>
): Promise<Response> => {
  const response = await fetch(...args);
  if (!response.ok) throw new APIErrorNotOk(response);
  return response;
};

export const fetchAndParse = async <T>(
  ...args: Parameters<typeof fetcher>
): Promise<T> => {
  const response = await fetcher(...args);

  try {
    return await response.json();
  } catch {
    throw new APIErrorFailedToParse(response);
  }
};
