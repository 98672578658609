import { useWatch } from 'react-hook-form';
import {
  isMultipleChoiceFieldType,
  isPatchSelectType,
} from '~/src/entities/project/utils';
import { useFieldDescriptors } from '../ProjectProvider';

export const useMissingFields = (fields: string[]) => {
  const descriptors = useFieldDescriptors(fields);
  const values = useWatch({ name: fields });

  return fields.filter((field, i) => {
    const value = values[i];
    const descriptor = descriptors[i];
    const hasEnteredValue = !!value;
    if (!descriptor) {
      console.error(`Failed to find field descriptor for field ${field}`);
      return !hasEnteredValue;
    }

    if (!isMultipleChoiceFieldType(descriptor?.variable_type))
      return !hasEnteredValue;

    if (!descriptor.multiple_choice_variable?.options) return !hasEnteredValue;

    const hasEmptyStringAsOption =
      descriptor.multiple_choice_variable?.options.some(
        (o) => o.value === '' || o.value === null,
      );

    if (hasEmptyStringAsOption) return value === undefined;

    if (isPatchSelectType(descriptor?.variable_type)) {
      if (typeof value === 'string') {
        //e.g. `[\"lettuce\",\"\",\"tomato\"]`
        const valueWithoutSquareBracket = value.substring(1, value.length - 1); //e.g. `\"lettuce\",\"\",\"tomato\"`
        const arrayIsNotEmpty = valueWithoutSquareBracket
          .split(',')
          .some((e: string) => e.length > 2);
        return !arrayIsNotEmpty;
      }
    }

    return !hasEnteredValue;
  });
};
