import { LAYOUT_MODAL_TYPES } from '../PageLayout/Modals';

export const showSaveModal = (
  showModal: (type: string, object: any) => void,
  onConfirm: () => void,
  onSecondaryConfirm: () => void,
) => {
  showModal(LAYOUT_MODAL_TYPES.confirm, {
    title: 'Do you want to save changes?',
    message: "Unsaved changes will be lost if you don't save them.",
    primaryActionTitle: 'Save and close',
    onConfirm,
    secondaryActionTitle: 'Discard and close',
    onSecondaryConfirm,
  });
};

export const showDiscardChangesModal = (
  showModal: (type: string, object: any) => void,
  onConfirm: () => void,
) => {
  showModal(LAYOUT_MODAL_TYPES.confirm, {
    title: 'Are you sure you want to leave?',
    message: 'Your changes will not be saved.',
    primaryActionTitle: 'Discard and close',
    onConfirm,
  });
};
