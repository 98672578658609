/* Libraries */
import React from 'react';

import Button from '~/src/components/Button';

import moment from 'moment';
import { Small } from '../Text';
import Card from '../Card';
import styles from './styles';

type SyncSingleMatterProps = {
  handleSync: () => void;
  lastUpdated?: string;
  linkToManage: string;
};

const SyncSingleMatter = ({
  handleSync,
  lastUpdated,
  linkToManage,
}: SyncSingleMatterProps) => (
  <Card
    elevate={4}
    dynamic
    renderHeader={() => 'Get updates from Clio Manage'}
    cssStyle={styles.card}
  >
    {lastUpdated ? (
      <div style={{ marginBottom: 24 }}>
        <Small>
          Last updated {moment(lastUpdated).format('MM/DD/YYYY [at] h:mm a')}
        </Small>
      </div>
    ) : null}

    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Button
        analyticsname="Single Matter Sync - Update now"
        primary
        style={{ marginRight: '18px' }}
        onClick={handleSync}
      >
        Update now
      </Button>
      <a
        style={{ textDecoration: 'underline' }}
        href={linkToManage}
        target="_blank"
        rel="noreferrer"
        analyticsname="Single Matter Sync - View in Clio Manage"
      >
        View in Clio Manage
      </a>
    </div>
  </Card>
);
export default SyncSingleMatter;
