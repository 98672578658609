import React from 'react';
import { css } from 'aphrodite';
import styles from './styles';

const Header = ({ title, subtitle }) => {
  return (
    <header className={css(styles.header)}>
      <h1 className={css(styles.title)}>
        {typeof title === 'function' ? title() : title}
      </h1>
      {subtitle && <h2 className={css(styles.subtitle)}>{subtitle}</h2>}
    </header>
  );
};

export default Header;
