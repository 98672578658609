import React, { FC, useState } from 'react';
import { Instance } from 'mobx-state-tree';

/* Types */
import { Actions } from '~/src/components/RowActions/RowActions';
import { PdfCourtFormStore } from '~/src/stores/pdfCourtFormsStore';
import { stateCodeToStateName } from '~/src/utils/stateCodeToStateName';
/* Components */
import CourtFormsLandingWrapper from './CourtFormsLandingWrapper';
import StateRegionTableWrapper from './StateRegionTableWrapper';
import PdfTableWrapper from './PdfTableWrapper';

interface PdfCourtForm extends Instance<typeof PdfCourtFormStore> {}

interface Props {
  actionType: string;
  disableAddButton: boolean;
  rowActions?: Actions<PdfCourtForm>;
  handleAddTemplateToSeletionSidebar: (t: PdfCourtForm) => void;
  handlePreviewTemplate: (t: PdfCourtForm) => void;
  containedInSidebar: (t: PdfCourtForm) => boolean;
}

const CourtFormsWrapper: FC<Props> = ({
  actionType,
  disableAddButton,
  rowActions,
  handleAddTemplateToSeletionSidebar,
  handlePreviewTemplate,
  containedInSidebar,
}) => {
  const [stateCode, setStateCode] = useState<
    keyof typeof stateCodeToStateName | ''
  >('');
  const [locality, setLocality] = useState('');
  const [tableType, setTableType] = useState('');

  const handleStateFormsSelect = (
    stateCode: keyof typeof stateCodeToStateName,
  ) => {
    setTableType('state');
    setStateCode(stateCode);
    setLocality('None');
  };

  const handleStateReginalFormsSelect = (
    stateCode: keyof typeof stateCodeToStateName,
  ) => {
    setTableType('region');
    setStateCode(stateCode);
  };

  const handleLocalitySelect = (locality: string) => {
    setTableType('state');
    setLocality(locality);
  };

  const showCourtFormLanding = !tableType;
  const showStateRegionTable = tableType === 'region';
  const showPDFTable = tableType === 'state';

  // TODO update breadcrumData based on state and locality selection
  const breadcrumbData: Record<string, any>[] = [
    {
      label: 'Court forms',
      onClick: () => {
        setTableType('');
        setStateCode('');
        setLocality('');
      },
    },
  ];

  if (stateCode) {
    if (stateCode === 'FEDERAL') {
      breadcrumbData.push({
        label: 'USCIS forms',
        onClick: () => {
          setTableType('state');
          setStateCode('FEDERAL');
          setLocality('');
        },
      });
    } else {
      const stateName = stateCodeToStateName[stateCode];
      if (tableType == 'region') {
        breadcrumbData.push({
          label: `${stateName} regional forms`,
        });
      } else if (locality && locality !== 'None') {
        breadcrumbData.push({
          label: `${stateName} regional forms`,
          onClick: () => {
            setTableType('region');
            setStateCode(stateCode);
            setLocality('');
          },
        });
        breadcrumbData.push({
          label: `${locality}`,
        });
      } else {
        breadcrumbData.push({
          label: `${stateName} forms`,
        });
      }
    }
  }

  return (
    <div>
      {showCourtFormLanding && (
        <CourtFormsLandingWrapper
          handleStateFormsSelect={handleStateFormsSelect}
          handleStateReginalFormsSelect={handleStateReginalFormsSelect}
        />
      )}
      {showStateRegionTable && (
        <StateRegionTableWrapper
          actionType={actionType}
          stateCode={stateCode}
          breadcrumbData={breadcrumbData}
          handleClick={handleLocalitySelect}
        />
      )}
      {showPDFTable && (
        <PdfTableWrapper
          stateCode={stateCode}
          locality={locality}
          breadcrumbData={breadcrumbData}
          disableAddButton={disableAddButton}
          rowActions={rowActions}
          handleAddTemplateToSeletionSidebar={
            handleAddTemplateToSeletionSidebar
          }
          handlePreviewTemplate={handlePreviewTemplate}
          containedInSidebar={containedInSidebar}
        />
      )}
    </div>
  );
};

export default CourtFormsWrapper;
