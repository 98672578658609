import React from 'react';

/* Components */
import { TemplateSetsTableWrapper } from '~/src/components/TemplateSets';

/* Hooks */
import useTemplateSetsActions from '~/src/hooks/useTemplateSetsActions';

const TemplateSetsContainer = () => {
  const {
    rename: handleRename,
    delete: handleDelete,
    generate: handleGenerate,
    removeTemplateFromSet: handleRemoveTemplateFromSet,
    viewTemplate: handleViewTemplate,
    addTemplatesToSeletionSidebar: handleAddTemplatesToSeletionSidebar,
  } = useTemplateSetsActions();

  const rowActions = {
    rename: ({ id, title }) => handleRename({ id, title }),
    delete: handleDelete,
    generate: handleGenerate,
  };

  const childRowActions = {
    view: handleViewTemplate,
    remove: ({ template, templateSetId }) =>
      handleRemoveTemplateFromSet({ template, templateSetId }),
  };

  return (
    <TemplateSetsTableWrapper
      onAddTemplatesToSeletionSidebar={(templateSet) =>
        handleAddTemplatesToSeletionSidebar(templateSet)
      }
      rowActions={rowActions}
      childRowActions={childRowActions}
    />
  );
};

export default TemplateSetsContainer;
