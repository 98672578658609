import { WelcomeFormResponse } from './types';

export const questionKeyToLabel = (key: keyof WelcomeFormResponse): string => {
  switch (key) {
    case 'primaryPracticeArea':
      return 'Primary practice area';
    case 'secondaryPracticeArea':
      return 'Secondary practice area';
    case 'state':
      return 'State';
    case 'referrer':
      return 'How did you hear about us?';
    case 'numberOfPeople':
      return 'Number of people in your organization';
    case 'phoneNumber':
      return 'Phone number';
  }
};
