import {
  Box,
  CircularProgress,
  Fade,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import {
  Contact,
  fetchContactFieldKeyMap,
  useContact,
} from '~/src/entities/contact';
import { PopulatePageCardBody } from '../PopulatePageCardBody';
import { PopulatePageCardProps } from '../types';
import {
  sanitizeFieldName,
  unsanitizeProjectFieldName,
  useRemoveContactFromProject,
  useUpdateProjectWithContact,
} from '~/src/entities/project';
import { ContactBar } from './ContactBar';
import { Refresh } from '@mui/icons-material';
import { useCurrentOrgFprint } from '~/src/entities/user';
import { getFieldsForGroup, getUpdatedFormFieldsForContact } from '../../utils';
import { useFormContext } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useCreateContactModal } from '~/src/entities/contact/components/CreateContactModal';
import ContactUpdaterWithProjectData from './ContactUpdaterWithProjectData';
import { SaveAsNewContactButton } from './SaveAsNewContactButton';
import { snakeCase } from 'lodash';
import { PopulateCardFields } from '../../PopulateCardFields';
import { useWatchContactDataFields } from './useWatchContactDataFields';

const updateFieldsForContact = async (
  orgFprint: string,
  supportsDataStack: boolean,
  form: any,
  markedAsReviewedOrNot: any,
  project: any,
  group: any,
  contactObj: Contact,
) => {
  const contactAttributes = getFieldsForGroup(project, group).map(({ label }) =>
    snakeCase(label),
  );
  let keyMap = await fetchContactFieldKeyMap(
    orgFprint,
    contactObj.id,
    contactAttributes,
  );

  const keysToUse = getUpdatedFormFieldsForContact(
    contactObj,
    keyMap,
    contactAttributes,
  );

  keysToUse.forEach((value, i) => {
    let fieldName;
    if (supportsDataStack) {
      fieldName = sanitizeFieldName(`${group.gid}.${contactAttributes[i]!}`);
    } else {
      fieldName = group.fields[i];
    }
    if (form.getValues(fieldName) !== value && !!value) {
      form.setValue(fieldName, value);
      markedAsReviewedOrNot(fieldName, true);
    }
  });
};

export const PopulatePageRoleCard = ({
  group,
  project,
  ...props
}: PopulatePageCardProps) => {
  const form = useFormContext();
  const snackbar = useSnackbar();
  const [isUpdatingContact, setIsUpdatingContact] = useState(false);
  const orgFprint = useCurrentOrgFprint();
  const [contact, setContact] = useState<Contact | null>(null);
  const {
    expanded,
    onChangeExpanded,
    callbackAfterExpanded,
    markedAsReviewedOrNot,
  } = props;
  const supportsDataStack = !!project.stack_data.data_stack;
  const [isCardLoading, setIsCardLoading] = useState(
    !!group.related_contact_id && !supportsDataStack,
  );
  const hasFetchedContact = useRef(false);

  const contactData = useWatchContactDataFields(group.fields);

  const shouldFetchContact =
    !!group.related_contact_id &&
    !hasFetchedContact.current &&
    !supportsDataStack;

  useEffect(() => {
    if (
      !hasFetchedContact.current &&
      supportsDataStack &&
      group.related_contact_id &&
      group.org_contact
    ) {
      hasFetchedContact.current = true;
      setContact(group.org_contact);
      updateFieldsForContact(
        orgFprint,
        supportsDataStack,
        form,
        markedAsReviewedOrNot,
        project,
        group,
        group.org_contact,
      )
        .catch((error) => {
          console.error(
            'Failed to update fields for contact from data stack',
            error,
          );
        })
        .finally(() => {
          setIsCardLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  const { isLoading, refetch, isError } = useContact(
    group.related_contact_id!,
    {
      enabled: shouldFetchContact,
      onSuccess: async (contactObj) => {
        hasFetchedContact.current = true;
        setContact(contactObj);
        await updateFieldsForContact(
          orgFprint,
          supportsDataStack,
          form,
          markedAsReviewedOrNot,
          project,
          group,
          contactObj,
        );
        setIsCardLoading(false);
      },
      onError: async () => {
        setIsCardLoading(false);
      },
      refetchOnReconnect: false,
      refetchOnMount: shouldFetchContact,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );

  // If supportsDataStack is true, set the contact from the project.stack_data.data_stack
  if (
    !hasFetchedContact.current &&
    supportsDataStack &&
    group.related_contact_id &&
    group.org_contact
  ) {
    hasFetchedContact.current = true;
    setContact(group.org_contact);
    updateFieldsForContact(
      orgFprint,
      supportsDataStack,
      form,
      markedAsReviewedOrNot,
      project,
      group,
      group.org_contact,
    )
      .catch((error) => {
        console.error(
          'Failed to update fields for contact from data stack',
          error,
        );
      })
      .finally(() => {
        setIsCardLoading(false);
      });
  }

  const { isLoading: isRemoving, mutateAsync: removeContact } =
    useRemoveContactFromProject();

  const { mutateAsync: addContact } = useUpdateProjectWithContact();

  const onAddContact = async (contact: Contact) => {
    setIsUpdatingContact(true);

    try {
      const { org_contact } = await addContact({
        projectId: project.id,
        contactId: contact.id,
        role: group.label,
      });

      setContact(org_contact);
      await updateFieldsForContact(
        orgFprint,
        supportsDataStack,
        form,
        markedAsReviewedOrNot,
        project,
        group,
        org_contact,
      );
      onChangeExpanded(true);
    } catch (err) {
      snackbar.enqueueSnackbar('Failed to attach contact', {
        variant: 'error',
      });
    } finally {
      setIsUpdatingContact(false);
    }
  };

  const [toggleCreateContactModal, createContactModal] = useCreateContactModal({
    maxWidth: 'xs',
    fullWidth: true,
    onContactCreated: (contact) => {
      toggleCreateContactModal();
      return onAddContact(contact);
    },
  });

  const detachRoleProperties = (rolePrefix: string) => {
    const currentValues = form.getValues();
    Object.keys(currentValues).forEach((key) => {
      if (unsanitizeProjectFieldName(key).startsWith(rolePrefix)) {
        form.unregister(key);
      }
    });
  };

  const onRemoveContact = async () => {
    try {
      detachRoleProperties(group.gid);
      await removeContact({
        contactId: contact!.id,
        projectId: project.id,
        role: group.label,
      });
      group.fields.forEach((fieldName) => {
        form.setValue(fieldName, '');
        markedAsReviewedOrNot(fieldName, false);
      });
      setContact(null);
    } catch (err) {
      snackbar.enqueueSnackbar('Failed to remove contact', {
        variant: 'error',
      });
    }
  };

  const onClickSaveAsNewContact = () => {
    const fullName = contactData['full_name'];
    const firstNameFromFullName = fullName?.split(' ').slice(0, -1).join(' ');
    const lastNameFromFullName = fullName?.split(' ').pop();

    toggleCreateContactModal({
      existingFields: contactData,
      name: contactData['name'],
      firstName: contactData['first_name'] || firstNameFromFullName,
      lastName: contactData['last_name'] || lastNameFromFullName,
    });
  };

  return (
    <>
      {createContactModal}
      <PopulatePageCardBody
        group={group}
        callbackAfterExpanded={callbackAfterExpanded}
        expanded={expanded}
        onChangeExpanded={() => {
          onChangeExpanded(!expanded);
        }}
        extra={
          <Box px={3} py={2} visibility={isCardLoading ? 'hidden' : 'visible'}>
            {!supportsDataStack && contact ? (
              <ContactUpdaterWithProjectData contact={contact} group={group} />
            ) : null}
            <ContactBar
              contact={contact}
              error={
                isError ? (
                  <>
                    <Typography variant={'smallLabel'}>
                      Failed to retrieve contact {group.related_contact_id}
                    </Typography>
                    <Tooltip title={'Retry'}>
                      <IconButton onClick={() => refetch()}>
                        <Refresh fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : null
              }
              isLoading={isLoading || isRemoving || isUpdatingContact}
              contactSelectParams={{
                limit: 20,
              }}
              matterId={project.stack_data.project_metadata.matter_id}
              onAddContact={() => toggleCreateContactModal()}
              onChangeContact={(contact) =>
                contact ? onAddContact(contact) : onRemoveContact()
              }
            />
          </Box>
        }
        footer={
          contact || isCardLoading ? null : (
            <SaveAsNewContactButton
              group={group}
              onClick={onClickSaveAsNewContact}
            />
          )
        }
      >
        <div style={{ position: 'relative' }}>
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate3d(-50%,-50%,0)',
              zIndex: 3,
            }}
          >
            <CircularProgress />
          </div>
          <div style={{ position: 'relative', zIndex: 4 }}>
            <Fade appear={false} in={!isCardLoading}>
              <div style={{ background: 'white' }}>
                <PopulateCardFields
                  fields={getFieldsForGroup(project, group)}
                  projectStackDocuments={project.stack_data.documents}
                  {...props}
                />
              </div>
            </Fade>
          </div>
        </div>
      </PopulatePageCardBody>
    </>
  );
};
