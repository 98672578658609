import { FORM_FIELD_ERROR_CODE_MAPPING } from '~/src/utils/constants';

const hasKey = (obj, key) => {
  return typeof obj[key] !== 'undefined';
};
export { hasKey };

const getFieldErrorKey = (field_key, error) => {
  return field_key + '_' + FORM_FIELD_ERROR_CODE_MAPPING[error];
};
export { getFieldErrorKey };

const delayMilliseconds = (delay) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
};
export { delayMilliseconds };
