import React from 'react';
import { IconButton, OutlinedInput } from '@mui/material';
import { Edit } from 'react-feather';
import { FormField } from '../FormField';
import { useUpdateNameModal } from './UpdateName';
import { NameFormValues } from './UpdateName/NameForm';

type NameInputProps = {
  initialValues: Partial<NameFormValues>;
  displayName: string;
  onChange: (values: NameFormValues) => Promise<boolean>;
};

export const NameInput = ({
  initialValues,
  displayName,
  onChange,
}: NameInputProps) => {
  const [toggle, modal] = useUpdateNameModal({
    initialValues,
    handleUpdate: async (values) => {
      const success = await onChange(values);
      if (success) toggle();
      return success;
    },
  });

  return (
    <>
      {modal}
      <FormField labelProps={{ htmlFor: 'name' }} label={'Name'}>
        <OutlinedInput
          readOnly
          id={'name'}
          onClick={toggle}
          sx={{ '.MuiOutlinedInput-input': { cursor: 'pointer' } }}
          value={displayName}
          endAdornment={
            <IconButton onClick={toggle}>
              <Edit size={16} />
            </IconButton>
          }
        />
      </FormField>
    </>
  );
};
