import { useQuery, UseQueryOptions } from 'react-query';
import { APIError } from '~/src/utils/error';
import { composeQueryOpts } from '../../utils';
import { getQuestionnaireById } from '../urls';

import { type Questionnaire } from '@clio/questionnaire-builder';
import {
  getClioQuestionnaireAuthToken,
  questionnaireAuthorizationHeaders,
} from '../utils';
import { questionnaireQueryArgs } from '../queryArgs';

export const useGetClioQuestionnaireById = (
  orgFprint: string,
  questionnaireId: string,
  options: UseQueryOptions<Questionnaire, APIError> = {},
) =>
  useQuery(
    composeQueryOpts(options, {
      queryKey: questionnaireQueryArgs.detail(questionnaireId),
      queryFn: async () => {
        const token = await getClioQuestionnaireAuthToken(orgFprint);
        const response = await fetch(getQuestionnaireById(questionnaireId), {
          headers: questionnaireAuthorizationHeaders(token),
        });

        if (!response.ok)
          throw new APIError(
            'Failed to retrieve selected questionnaire',
            response,
          );

        return response.json();
      },
    }),
  );
