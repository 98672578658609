import { useMutation } from 'react-query';
import { APIError } from '~/src/utils/error';
import { useInvalidateQuery } from '../../utils';
import { questionnaireQueryArgs } from '../queryArgs';
import { getQuestionnaireBaseUrl } from '../urls';
import { type Questionnaire } from '@clio/questionnaire-builder';
import {
  getClioQuestionnaireAuthToken,
  questionnaireAuthorizationHeaders,
} from '../utils';

type CreateClioQuestionnaire = {
  clioQuestionnaire: Omit<
    Questionnaire,
    | 'id'
    | 'created_at'
    | 'updated_at'
    | 'sections_attributes'
    | 'questionnaire_submission_preview_link'
  >;
};

export const useCreateClioQuestionnaire = (orgFprint: string) =>
  useMutation<Questionnaire, APIError, CreateClioQuestionnaire>({
    onSuccess: useInvalidateQuery(questionnaireQueryArgs.all),
    mutationFn: async ({ clioQuestionnaire }) => {
      const token = await getClioQuestionnaireAuthToken(orgFprint);
      const response = await fetch(getQuestionnaireBaseUrl(), {
        method: 'POST',
        headers: {
          ...questionnaireAuthorizationHeaders(token),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(clioQuestionnaire),
      });

      if (!response.ok) {
        throw new APIError('Failed to create questionnaire', response);
      }

      return response.json();
    },
  });
