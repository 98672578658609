/* Libraries */
import { StyleSheet } from 'aphrodite';

import theme from '~/src/theme';

export default StyleSheet.create({
  errorText: {
    fontSize: theme.fonts.size.small,
    lineHeight: '1.25em',
    color: theme.colors.paleTomato,
    display: 'block',
  },
  label: {
    display: 'block',
    fontSize: theme.fonts.size.small,
    color: theme.colors.chrome,
    marginBottom: theme.unit * 3,
    ':last-child': {
      marginBottom: 0,
    },
  },

  labelNoMargin: {
    marginBottom: 0,
  },
  labelTitle: {
    display: 'block',
    fontWeight: 'bold',
    fontSize: theme.fonts.size.small,
    color: theme.colors.charcoal,
    marginBottom: theme.unit * 1,
  },
});
