import { useMutation, useQueryClient } from 'react-query';
import { APIError } from '~/src/utils/error';
import { getRelatedQuestionnaireSubmissionUrl } from '../urls';
import { questionnaireQueryArgs } from '../queryArgs';
import { RelatedQuestionnaireSubmission } from '~/src/entities/questionnairesV2/types';

type Variables = {
  questionnaire_submission_id: number;
  questionnaire_id: number;
  questionnaire_version_id: number;
  data: {};
};

export const usePersistRelatedQuestionnaireSubmission = (orgFprint: string) => {
  const queryClient = useQueryClient();
  return useMutation<RelatedQuestionnaireSubmission, APIError, Variables>({
    onSuccess: () => {
      queryClient.invalidateQueries(questionnaireQueryArgs.all);
    },
    mutationFn: async (payload) => {
      const response = await fetch(
        getRelatedQuestionnaireSubmissionUrl(orgFprint),
        {
          method: 'POST',
          body: JSON.stringify(payload),
        },
      );

      if (!response.ok) {
        throw new APIError(
          'Failed to persist related questionnaire submission in lawyaw',
          response,
        );
      }

      return response.json();
    },
  });
};
