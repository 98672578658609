import React from 'react';
import { Box } from '@mui/material';

export const StyledCard: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => (
  <Box
    sx={{
      display: 'flex',
      width: '485px',
      padding: '24px',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '24px',
      borderRadius: '8px',
      border: '1px solid #E5E7EB',
      background: 'white',
      boxShadow:
        '0px 0px 1px 0px rgba(0, 0, 0, 0.25), 0px 2px 1px 0px rgba(0, 0, 0, 0.05)',
    }}
  >
    {children}
  </Box>
);
