import React from 'react';
import { css } from 'aphrodite';

import styles from './styles';

type SmallProps = {
  className?: string;
  children: React.ReactNode;
};

const Small = ({ className = '', children }: SmallProps) => {
  return <p className={`${css(styles.small)} ${className}`}>{children}</p>;
};

export default Small;
