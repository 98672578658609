import * as React from 'react';
import { css } from 'aphrodite';

import styles from './styles';

type CardTitleProps = {
  children: React.ReactNode;
  bold?: boolean;
};

const CardTitle = ({ children, bold }: CardTitleProps) => {
  return (
    <div className={css(styles.cardTitle, bold && styles.cardTitleBold)}>
      {children}
    </div>
  );
};

export default CardTitle;
