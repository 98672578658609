import { Instance } from 'mobx-state-tree';
import useMst from '~/src/hooks/useMst';
import { LAYOUT_SLIDEIN_TYPES } from '~/src/components/PageLayout/SlideIns';
import { PdfCourtFormStore } from '~/src/stores/pdfCourtFormsStore';
import { useLayoutContext } from '../contexts/Layout';

interface PdfCourtForm extends Instance<typeof PdfCourtFormStore> {}

const usePdfCourtFormsActions = () => {
  const { showSlideIn } = useLayoutContext();

  const { addTemplateToSeletionSidebar, createFormTemplate } = useMst(
    (store) => ({
      addTemplateToSeletionSidebar:
        store.pdfCourtForms.addTemplateToSeletionSidebar,
      createFormTemplate: store.pdfCourtForms.createFormTemplate,
    }),
  );

  const handleAddTemplateToSeletionSidebar = (template: PdfCourtForm) => {
    addTemplateToSeletionSidebar(template);
  };

  const handlePreviewTemplate = (template: PdfCourtForm) => {
    showSlideIn(LAYOUT_SLIDEIN_TYPES.documentPreview, {
      largeSlideIn: true,
      templateObj: template,
      templateId: template.id,
    });
  };

  return {
    addTemplateToSeletionSidebar: handleAddTemplateToSeletionSidebar,
    previewTemplate: handlePreviewTemplate,
    createFormTemplate,
  };
};

export default usePdfCourtFormsActions;
