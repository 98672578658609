/* Libs */
import { types, flow, getParent } from 'mobx-state-tree';
import { filter } from 'lodash';

/* Stores */
import { Project } from '~/src/stores/projectsStore';

/* Services */
import documentSetsServices from '~/src/services/documentSets';

/* Utils */
import { CreatePaginatedListModel } from '~/src/stores/composers/createPaginatedModel';

const DocumentSetsStore = types
  .model('DocumentSets', {
    error: '',
  })
  .actions((self) => {
    const deleteDocument = flow(function* (documentId) {
      const rootStore = getParent(self);

      for (let page = 0; page < self.list.length; page += 1) {
        self.list[page] = filter(self.list[page], (doc) => {
          return doc.id !== documentId;
        });
      }

      const success = yield rootStore.projects.deleteProject(documentId);

      return success;
    });

    const updateDocument = flow(function* updateDocument(
      documentId,
      properties,
    ) {
      try {
        const rootStore = getParent(self);
        const next = yield rootStore.projects.updateProject(
          documentId,
          properties,
        );
        return next;
      } catch (error) {
        console.error('Failed to updated document : ', error);
        return false;
      }
    });

    const addDocumentSetToStore = (documentSet) => {
      if (self.list && self.list[0]) {
        self.list[0].unshift(documentSet.identifier);
      } else {
        self.list = [[documentSet.identifier]];
      }
    };

    const addItem = (identifier, item) => {
      const rootStore = getParent(self);
      rootStore.projects.addProject(item);
      return identifier;
    };

    const fetch = flow(function* (query = {}) {
      return yield self.paginate(query);
    });

    return {
      fetch,
      addItem,
      updateDocument,
      deleteDocument,
      addDocumentSetToStore,
    };
  })
  .views((self) => {
    const getById = (id) => {
      return self.dictionary.get(`document:${id}`);
    };

    const get = (documentSet) => {
      return self.dictionary.get(documentSet.identifier);
    };

    return {
      get,
      getById,
    };
  });

const PaginatedDocumentSetsStore = CreatePaginatedListModel(
  'PaginatedDocumentSetsStore',
  DocumentSetsStore,
  Project,
  { paginate: documentSetsServices.fetch },
);

export default PaginatedDocumentSetsStore;
