/* Libraries */
import React from 'react';
import { css } from 'aphrodite';
import uuid from 'uuid/v4';

/* Styles */
import styles from './styles';

const EmptyView = ({ title, paragraphs, children }) => {
  return (
    <div className={css(styles.container)}>
      <h1 className={css(styles.title)}>{title}</h1>
      {paragraphs.map((content) => (
        <p key={uuid()} className={css(styles.description)}>
          {content}
        </p>
      ))}
      <div className={css(styles.children)}>{children}</div>
    </div>
  );
};

export default EmptyView;
