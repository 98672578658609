import React from 'react';
import { ClioSync } from './ClioSync';
import { PopulatePageV2 } from './PopulatePageV2';

type PopulatePageProps = {
  projectId: number;
  initialProject?: boolean;
};

export const PopulatePage = ({
  projectId,
  initialProject,
}: PopulatePageProps) => {
  return (
    <ClioSync shouldClear={!initialProject} projectId={projectId}>
      <PopulatePageV2 projectId={projectId} />
    </ClioSync>
  );
};
