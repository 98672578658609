import { useState } from 'react';
import { hasKey } from '~/src/utils/general';

export const DEFAULT_FIELD = {
  value: '',
  valid: true,
  touched: false,
  focus: false,
};

export const FIELD_KEYS = Object.keys(DEFAULT_FIELD);

export default (defaultFields = {}) => {
  const [formFocused, setFormFocused] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [formTouched, setFormTouched] = useState(false);
  const [formFields, setFormFields] = useState(defaultFields);

  let nextFormFields = formFields;

  const handleNewFieldProperties = (fields) => {
    const fieldValues = Object.values(fields);

    const fieldsValid =
      fieldValues
        .map((field) => {
          return field.valid;
        })
        .indexOf(false) === -1;

    const fieldTouched =
      fieldValues
        .map((field) => {
          return field.touched;
        })
        .indexOf(true) !== -1;

    const fieldFocused =
      fieldValues
        .map((field) => {
          return field.focus;
        })
        .indexOf(true) !== -1;

    setFormFocused(fieldFocused);
    setFormValid(fieldsValid);
    if (!formTouched && fieldTouched) {
      setFormTouched(true);
    }
  };

  const setField = async (id, properties) => {
    const nextFields = {};

    if (!formFields[id]) {
      nextFields[id] = { id, ...DEFAULT_FIELD };
    } else {
      nextFields[id] = { id, ...formFields[id] };
    }

    FIELD_KEYS.forEach((key) => {
      if (hasKey(properties, key)) {
        nextFields[id][key] = properties[key];
      }
    });

    nextFormFields = {
      ...nextFormFields,
      ...nextFields,
    };

    handleNewFieldProperties(nextFormFields);
    setFormFields(nextFormFields);
  };

  const removeField = async (id) => {
    delete formFields[id];

    handleNewFieldProperties(formFields);
    setFormFields(formFields);
  };

  return {
    formFocused,
    formValid,
    formTouched,
    removeField,
    setField,
    formFields,
  };
};
