import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Box,
  Alert,
} from '@mui/material';

import { Close } from '@mui/icons-material';

import { FormProvider, useForm } from 'react-hook-form';
import {
  getExpiryDateFromSubmittedFormValues,
  PaymentForm,
  PaymentFormValues,
} from '~/src/entities/subscription';
import { useUpdatePaymentMethod } from '~/src/entities/subscription/hooks';

export type UpdatePaymentMethodProps = {
  onUpdated: () => void;
} & Omit<DialogProps, 'onChange'>;

export const UpdatePaymentMethodModal = ({
  onUpdated,
  ...rest
}: UpdatePaymentMethodProps) => {
  const form = useForm<PaymentFormValues>({ mode: 'onTouched' });
  const { mutate, isError, isLoading } = useUpdatePaymentMethod({
    onSuccess: onUpdated,
  });

  const hasError = !isLoading && isError;

  return (
    <Dialog
      TransitionProps={{ onExited: () => form.reset() }}
      fullWidth
      maxWidth={'xs'}
      {...rest}
    >
      <DialogTitle>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box>Update payment method</Box>
          <IconButton onClick={(e) => rest.onClose?.(e, 'backdropClick')}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers={true}>
        <FormProvider {...form}>
          <form
            id={'update-payment-method'}
            noValidate
            onSubmit={form.handleSubmit(async ({ exp, ...card }) => {
              const { month, year } = getExpiryDateFromSubmittedFormValues(exp);

              return new Promise<void>((res) => {
                mutate(
                  { card: { ...card, exp_month: month, exp_year: year } },
                  { onSettled: () => res() },
                );
              });
            })}
          >
            {hasError ? (
              <Box pb={2}>
                <Alert variant={'filled'} severity={'error'}>
                  An error occurred while updating your payment method
                </Alert>
              </Box>
            ) : null}
            <PaymentForm />
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={(e) => rest.onClose?.(e, 'escapeKeyDown')}
        >
          Cancel
        </Button>
        <Button
          type={'submit'}
          form={'update-payment-method'}
          data-testid="update-payment-method-modal-confirm"
          variant="contained"
          disabled={isLoading}
        >
          {isLoading ? 'Submitting...' : 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
