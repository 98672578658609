import React, { useState, useEffect } from 'react';
import { camelCase } from 'lodash';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';

/* Components */
import { Form, renderFormField } from '~/src/components/Forms';
import { QUESTION_TYPE_LOOKUP } from '~/src/components/QuestionnaireForm/constants';
import EmptyQuestionnaireFormPreview from './EmptyQuestionnaireFormPreview';

/* Utilities */

import styles from './styles';

const QuestionnaireFormPreview = ({
  name,
  description,
  questions,
  onChange,
}) => {
  const empty =
    name.length < 1 && description.length < 1 && questions.length < 1;
  const [formFields, setFormFields] = useState([]);

  useEffect(() => {
    const nextFormFields = questions.map((question) => {
      const {
        label,
        type,
        hint,
        defaultValue,
        required,
        options,
        uploadLimit,
        uploadTypes,
      } = question;

      const field = {
        label,
        hint,
        defaultValue,
        type: QUESTION_TYPE_LOOKUP[type],
        id: question.id || camelCase(label),
        required,
        options,
        validation: () => true,
        uploadLimit,
        uploadTypes: uploadTypes && uploadTypes.flat(),
      };

      return renderFormField(field);
    });

    setFormFields(nextFormFields);
  }, [questions]);

  return (
    <>
      {empty && <EmptyQuestionnaireFormPreview />}
      {!empty && (
        <>
          {name && <h1 className={css(styles.intakePreviewTitle)}>{name}</h1>}
          {description && (
            <p className={css(styles.intakePreviewDescription)}>
              {description}
            </p>
          )}
          <Form fields={formFields} className="mb-4" onChange={onChange} />
        </>
      )}
    </>
  );
};

QuestionnaireFormPreview.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      index: PropTypes.number,
      label: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
};

QuestionnaireFormPreview.defaultProps = {
  name: '',
  description: '',
  questions: [],
};

export default QuestionnaireFormPreview;
