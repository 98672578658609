import { useMutation, useQueryClient } from 'react-query';
import { APIError } from '~/src/utils/error';
import { useCurrentOrgFprint } from '../../user';
import { keys } from '../keys';
import { Matter } from '../types';
import { getMatterUrl } from '../urls';

type Variables = {
  data: Record<string, string>;
};

export const usePatchMatter = (matterId: number) => {
  const orgFprint = useCurrentOrgFprint();
  const queryClient = useQueryClient();
  return useMutation<Matter, APIError, Variables>({
    onSuccess: () => {
      queryClient.invalidateQueries(keys.detail(matterId));
    },
    mutationFn: async (payload) => {
      const response = await fetch(getMatterUrl(orgFprint, matterId), {
        method: 'PATCH',
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new APIError('Failed to patch matter in lawyaw', response);
      }

      return response.json();
    },
  });
};
