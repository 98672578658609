import React from 'react';
import { NavLink } from 'react-router-dom';

const CourtFormsLandingEmptyView = () => {
  return (
    <div className="mx-6">
      <div className="text-center mt-12 text-lg font-medium">
        Fillable court forms
      </div>

      <div className="text-center mt-2">
        Speed up your court form drafting with cloud-based fillable court forms.
      </div>

      <div className="text-center mt-3">
        <NavLink to={'/settings/subscriptions'}>Upgrade now</NavLink>
      </div>
    </div>
  );
};

export default CourtFormsLandingEmptyView;
