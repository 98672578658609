import * as React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { moveListItem } from './utils';

type OrderableListProps<T> = {
  items: T[];
  onChangeOrder: (items: T[]) => void;
  id: (item: T) => string;
  className?: string;
  render: (item: T, index: number) => React.ReactNode;
  name?: string;
};

export const OrderableList = <T,>({
  items,
  onChangeOrder,
  className,
  id,
  render,
  name = 'droppable',
}: OrderableListProps<T>) => (
  <DragDropContext
    onDragEnd={({ source, destination }) => {
      if (!destination) return;
      if (source.index === destination.index) return;
      onChangeOrder(
        moveListItem(items, items[source.index]!, destination.index),
      );
    }}
  >
    <Droppable droppableId={name}>
      {(provided) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className={className}
        >
          {items.map((item, index) => (
            <Draggable key={id(item)} draggableId={id(item)} index={index}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  {render(item, index)}
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  </DragDropContext>
);
