import * as React from 'react';
import { Typography, Box, Stack, Checkbox } from '@mui/material';
import { COURT_FORMS } from '~/src/entities/subscription';

export type CourtFormsListProps = {
  excludeList?: string[]; // court forms to exclude
  value: string[];
  onChange: (nextForms: string[]) => void;
  renderExtra?: (planId: string, isChecked: boolean) => void;
};

export const CourtFormsList = ({
  excludeList,
  value = [],
  onChange,
  renderExtra,
}: CourtFormsListProps) => {
  let courtForms = COURT_FORMS;
  if (excludeList) {
    courtForms = COURT_FORMS.filter((cForm) =>
      excludeList.find((e) => e !== cForm.id),
    );
  }

  return (
    <Stack spacing={1}>
      {courtForms.map(({ name, id }) => {
        const isChecked = value.includes(id);

        return (
          <Box
            key={id}
            sx={(theme) => ({
              '&:hover': {
                cursor: 'pointer',
                background: theme.palette.background.default,
              },
              border: `1px solid ${theme.palette.border}`,
              borderRadius: `${theme.shape.borderRadius}px`,
            })}
            p={2}
            display={'flex'}
            alignItems={'center'}
            onClick={() =>
              onChange(
                isChecked ? value.filter((i) => i !== id) : [...value, id],
              )
            }
          >
            <Box pr={2}>
              <Checkbox checked={isChecked} />
            </Box>
            <Box flex={1} display={'flex'} justifyContent={'space-between'}>
              <Typography variant={'body1'}>{name}</Typography>
              {renderExtra?.(id, isChecked)}
            </Box>
          </Box>
        );
      })}
    </Stack>
  );
};
