import { useEffect } from 'react';
import { useWebViewerContext } from '~/src/components/Webviewer';
import { Position } from '~/src/components/Webviewer/useWebViewerContext';

/**
 * Makes the PDF viewer visible on mount with the given inset, and
 * hides it on unmount. Useful in any context where we need to show
 * the PDF viewer.
 *
 * @param inset – the position of the PDF viewer
 */
const useMakePDFViewerVisible = ({ top, left, right, bottom }: Position) => {
  const { makeWebViewerVisible, setWebViewerPosition, hideWebViewer } =
    useWebViewerContext();

  useEffect(() => {
    setWebViewerPosition({
      top,
      left,
      right,
      bottom,
    });
    makeWebViewerVisible();
    return () => {
      hideWebViewer();
    };
  }, [
    top,
    left,
    right,
    bottom,
    setWebViewerPosition,
    makeWebViewerVisible,
    hideWebViewer,
  ]);
};

export default useMakePDFViewerVisible;
