import React from 'react';
import { css } from 'aphrodite';
import theme from '~/src/theme';
import styles from './styles';

type CSSInputType = Parameters<typeof css>[0];

export type LabelProps = {
  label?: React.ReactNode;
  children: React.ReactElement;
  labelCssStyle?: CSSInputType;
  Icon?: React.ElementType;
  hint?: string;
  count?: string;
  error?: string;
  focus?: boolean;
  editable?: boolean;
  cssStyle?: CSSInputType;
  inline?: boolean;
  className?: string;
  style?: React.CSSProperties;
  noMargin?: boolean;
  required?: boolean;
  unstyled?: string;
  onClick?: () => void;
};

const Label = ({
  label,
  labelCssStyle,
  hint,
  count,
  error,
  focus,
  editable,
  children,
  cssStyle,
  inline,
  className,
  style,
  noMargin,
  required,
  unstyled,
  onClick,
  Icon,
}: LabelProps) => (
  <span
    className={`${css(
      !noMargin && styles.label,
      noMargin && styles.labelNoMargin,
      inline && styles.labelInline,
    )} ${className}`}
    style={style}
    onClick={onClick}
  >
    {label && (
      <span className={css(styles.labelTitle, labelCssStyle)}>
        {Icon && (
          <i className={css(styles.labelIcon)}>
            <Icon color={theme.colors.charcoal} size={theme.fonts.size.small} />
          </i>
        )}
        {label}
        {required && <i className={css(styles.labelRequiredIcon)}>*</i>}
      </span>
    )}
    {hint && <span className={css(styles.labelHint)}>{hint}</span>}
    <div
      className={
        unstyled
          ? ''
          : css(
              styles.textInputLabel,
              focus && styles.textInputLabelFocus,
              !!error && styles.textInputLabelError,
              !!error && focus && styles.textInputLabelFocusError,
              !editable && styles.textInputLabelStatic,
              cssStyle && cssStyle,
            )
      }
    >
      {children}
    </div>
    {count && <span className={css(styles.labelCount)}>x {count}</span>}
    {error && <span className={css(styles.labelError)}>{error}</span>}
  </span>
);

export default Label;
