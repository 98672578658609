const filterMattersList = (option, query) => {
  if (typeof option.label === 'string') {
    return option.label.toLowerCase().indexOf(query.toLowerCase()) >= 0;
  }
  return (
    option.data.clioMatter.display_number
      .toLowerCase()
      .indexOf(query.toLowerCase()) >= 0 ||
    option.data.clioMatter.description
      .toLowerCase()
      .indexOf(query.toLowerCase()) >= 0
  );
};

export { filterMattersList };
