import { types } from 'mobx-state-tree';
import { saveAs } from 'file-saver';

const ProjectDocumentPage = types.model({
  id: types.integer,
  page: types.integer,
  backgroundUrl: types.string,
  isProcessed: types.optional(types.boolean, false),
  elements: types.maybeNull(
    types.array(
      types.maybeNull(
        types.model({
          id: types.maybeNull(types.integer),
          // TODO use pageID not signature Page Id
          signaturePageId: types.maybeNull(types.integer),
          top: types.maybeNull(types.number),
          left: types.maybeNull(types.number),
          width: types.maybeNull(types.number),
          imageWidth: types.maybeNull(types.number),
          imageHeight: types.maybeNull(types.number),
          lineHeight: types.maybeNull(types.number),
          tag: types.maybeNull(types.string),
          aliasTag: types.maybeNull(types.string),
          value: types.maybeNull(types.union(types.boolean, types.string)),
          height: types.maybeNull(types.number),
          inputType: types.maybeNull(types.integer),
        }),
      ),
    ),
    [],
  ),
});

const ProjectDocument = types
  .model({
    id: types.integer,
    identifier: types.identifier,
    docx: types.maybeNull(types.string),
    processedDocx: types.maybeNull(types.string),
    processedMsPdf: types.maybeNull(types.string),
    pdf: types.maybeNull(types.string),
    documentType: types.maybeNull(types.number),
    ms_pdf: types.maybeNull(types.string),
    templateId: types.maybeNull(types.integer),
    globalTemplateId: types.maybeNull(types.integer),
    defaultValueId: types.maybeNull(types.integer),
    title: types.string,
    subtitle: types.string,
    pages: types.optional(types.array(ProjectDocumentPage), []),
    allElements: types.maybeNull(
      types.array(
        types.maybeNull(
          types.model({
            id: types.maybeNull(types.integer),
            top: types.maybeNull(types.number),
            left: types.maybeNull(types.number),
            width: types.maybeNull(types.number),
            height: types.maybeNull(types.number),
            lineHeight: types.maybeNull(types.number),
            tag: types.maybeNull(types.string),
            aliasTag: types.maybeNull(types.string),
            pageNumber: types.maybeNull(types.number),
            inputType: types.maybeNull(types.number),
            computedFieldType: types.maybeNull(types.string),
            computedFieldTag: types.maybeNull(types.string),
            computedTagDestination: types.maybeNull(types.string),
          }),
        ),
      ),
      [],
    ),
    processedPages: types.optional(types.array(ProjectDocumentPage), []),
  })
  .volatile(() => ({
    originalPDfBlob: null,
  }))
  .actions((self) => {
    const downloadDocument = (showHighlights) => {
      const docx = showHighlights ? self.docx : self.processedDocx;
      saveAs(docx, self.title, {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });
    };

    const updatePdfBlob = (pdfBlob) => {
      self.originalPDfBlob = pdfBlob;
    };

    const updateProjectDocument = (projectDocumentID, propertiesToUpdate) => {
      Object.keys(propertiesToUpdate).forEach((property) => {
        const newPropertyValue = propertiesToUpdate[property];
        if (property in self) {
          self[property] = newPropertyValue;
        } else {
          console.error(
            `No property found for ${property} in ProjectDocument ${projectDocumentID}`,
          );
        }
      });
    };

    return {
      downloadDocument,
      updatePdfBlob,
      updateProjectDocument,
    };
  });

export default ProjectDocument;
