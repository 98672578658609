import React, { useEffect } from 'react';
import { snakeCase } from 'lodash';

import LoadMoreDisplayText from '~/src/components/LoadMoreDisplayText';

import usePageScroll from '~/src/hooks/usePageScroll';
import { Signatures as SignaturesTable, SignaturesEmpty } from './Signatures';
import useSignaturesPagination from '~/src/hooks/useSignaturesPagination';
import Page from '~/src/components/PageLayout';
import { useStore } from '~/src/hooks/useMst';
import { VALIDATION_TYPES } from '~/src/utils/validation';
import { history } from '~/src/utils/history';
import { useLayoutContext } from '~/src/contexts';
import { LAYOUT_MODAL_TYPES } from '~/src/components/PageLayout/Modals';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';

export const SignaturesPage = () => {
  const { showToast, showModal, hideModal } = useLayoutContext();
  const { signatures, user } = useStore();

  const pageScroll = usePageScroll();

  const { next, loading, done, data, empty, setSearch, setSortBy, setOrder } =
    useSignaturesPagination();

  const loadMore = next;

  const handleOnPageScroll = () => {
    if ((pageScroll === 0 || pageScroll >= 85) && !loading) {
      loadMore();
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleOnPageScroll, [pageScroll]);

  const handleSortByChange = (property, order) => {
    if (!property) {
      return;
    }

    const sortBy = snakeCase(property);
    setSortBy(sortBy);

    const orderLabel = order > 0 ? 'desc' : 'asc';
    setOrder(orderLabel);
  };

  const handleSearchChange = (value) => {
    setSearch(value);
  };

  const handleCancelOrRemoveSignaturePackage = (currentSignaturePackage) => {
    const handleCancelConfirm = () => {
      signatures.cancelOrRemoveSignaturePackage(currentSignaturePackage.id);
      hideModal();
    };
    showModal(LAYOUT_MODAL_TYPES.confirm, {
      title: `Cancel Signature Request?`,
      message: `Canceling signature package will retract any pending signatures. You will need to create a new package to get signatures.`,
      onConfirm: handleCancelConfirm,
      primaryActionTitle: 'Continue with cancellation',
    });
  };

  const handleDeleteSignaturePackage = (currentSignaturePackage) => {
    const handleDeleteConfirm = () => {
      signatures.removeSignaturePackage(currentSignaturePackage.id);
      hideModal();
    };
    showModal(LAYOUT_MODAL_TYPES.confirm, {
      title: `Delete`,
      message: `This will delete ${currentSignaturePackage.title}. This is a permanent action and cannot be undone.`,
      onConfirm: handleDeleteConfirm,
      primaryActionTitle: 'Delete',
    });
  };

  const handleViewSignaturePackage = (currentSignaturePackage) => {
    history.push(`/signatures/details/${currentSignaturePackage.id}`);
  };

  const handleRename = (currentSignaturePackage) => {
    const handleRenameConfirm = async (form) => {
      const { value } = form.fields.name;

      const title = value;

      const signaturePackageData = {
        signaturePackageId: currentSignaturePackage.id,
        title,
        emailSubject: title,
      };

      const success = await signatures.updateSignaturePackage(
        user.profile.email || user.currentOrganization.email,
        signaturePackageData,
      );

      if (success) {
        showToast(LAYOUT_TOAST_TYPES.success, {
          message: `Signature package "${value}" renamed successfully.`,
        });
      } else {
        showToast(LAYOUT_TOAST_TYPES.error);
      }

      hideModal();
    };

    showModal(LAYOUT_MODAL_TYPES.formField, {
      title: 'Rename signature package',
      primaryActionTitle: 'Save',
      fields: [
        {
          label: 'Name',
          id: 'name',
          type: 'text',
          defaultValue: currentSignaturePackage.title,
          validation: VALIDATION_TYPES.present,
        },
      ],
      onConfirm: handleRenameConfirm,
    });
  };

  const handleResumeSignaturePackage = (currentSignaturePackage) => {
    const getCurrentStepFromNumber = (number) => {
      switch (number) {
        case 4:
          return 'send-email';
        case 3:
          return 'add-signatures';
        case 2:
          return 'choose-signers';
        case 1:
        default:
          return 'select-documents';
      }
    };

    history.push(
      `/signature-wizard/${currentSignaturePackage.lawyawProjectId}/${
        currentSignaturePackage.id
      }/${getCurrentStepFromNumber(currentSignaturePackage.currentStep)}`,
    );
  };

  const handleNewSignaturePackage = () => {
    history.push(`/custom-signature-package/new`);
  };

  const rowActions = {
    view: handleViewSignaturePackage,
    rename: handleRename,
    resume: handleResumeSignaturePackage,
    cancel: handleCancelOrRemoveSignaturePackage,
    delete: handleDeleteSignaturePackage,
    new: handleNewSignaturePackage,
  };

  if (!loading && empty) {
    return (
      <Page title="Signatures">
        <SignaturesEmpty />
      </Page>
    );
  }

  return (
    <Page title="Signatures">
      <SignaturesTable
        empty={!loading && done && data.length === 0}
        isLoading={false}
        signaturePackages={data}
        visibleItems={data.length}
        rowActions={rowActions}
        onSearchChange={handleSearchChange}
        onSortByChange={handleSortByChange}
      />
      <LoadMoreDisplayText
        loading={loading}
        done={done}
        onLoadMore={loadMore}
      />
    </Page>
  );
};
