import React from 'react';
import { PopulatePageRoleCard } from './PopulatePageRoleCard';
import { PopulatePageOtherCard } from './PopulatePageOtherCard';
import { PopulatePageCardProps } from './types';

export const PopulatePageCard = (props: PopulatePageCardProps) =>
  props.group.entity_type === 'Contact' ? (
    <PopulatePageRoleCard {...props} />
  ) : (
    <PopulatePageOtherCard {...props} />
  );
