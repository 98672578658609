import React from 'react';

import { css } from 'aphrodite';
import { ReactComponent as CircleSpinner } from './circle-spinner.svg';

import styles from './styles';

const Spinner = () => (
  <div className={css(styles.container)}>
    <CircleSpinner />
  </div>
);

export default Spinner;
