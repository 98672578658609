import { useCallback, useState } from 'react';

type Status = 'idle' | 'loading' | 'success' | 'error';

export const useCreateStripeCardToken = () => {
  const [status, setStatus] = useState<Status>('idle');
  const [error, setError] = useState<stripe.StripeError | undefined>();
  const [data, setData] = useState<
    stripe.StripeCardTokenResponse | undefined
  >();
  const createCardTokenAsync = useCallback(
    async (card: stripe.StripeCardTokenData) => {
      setStatus('loading');
      try {
        const res = await new Promise<stripe.StripeCardTokenResponse>(
          (res, rej) => {
            window.Stripe.createToken(card, (status, response) =>
              status !== 200 ? rej(response.error) : res(response),
            );
          },
        );
        setData(res);
        setStatus('success');
        return res;
      } catch (err) {
        setStatus('error');
        setError(err as stripe.StripeError);
        throw err;
      }
    },

    [],
  );

  const createCardToken = useCallback(
    async (card: stripe.StripeCardTokenData) => {
      try {
        await createCardTokenAsync(card);
      } catch {
        // ignore
      }
    },
    [createCardTokenAsync],
  );

  const reset = useCallback(() => setStatus('idle'), []);

  return {
    data,
    error: status === 'error' ? error : undefined,
    createCardToken,
    createCardTokenAsync,
    reset,
    status,
    isSuccess: status === 'success',
    isError: status === 'error',
    isLoading: status === 'loading',
  };
};
