import React from 'react';
import Page from '~/src/components/PageLayout';
import { useStore } from '~/src/hooks/useMst';
import { Subscribe } from './Subscribe';

export const SubscribePage = () => {
  const store = useStore();
  return (
    <Page showNavigation={false} title={'Subscribe'}>
      <Subscribe onSubscribe={store.initialize} />
    </Page>
  );
};
