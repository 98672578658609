export const US_AND_CANADA_STATES = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'Washington, D.C.',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
  // Canadian provinces
  'Ontario',
  'Quebec',
  'Nova Scotia',
  'New Brunswick',
  'Manitoba',
  'British Columbia',
  'Prince Edward Island',
  'Saskatchewan',
  'Alberta',
  'Newfoundland and Labrador',
  // Not US or Canada
  'Other',
];

export const REFERRER_OPTIONS = [
  'Colleague',
  'Google',
  'Facebook Ad',
  'Facebook Post',
  'Email',
  'Mail',
  'Clio',
  'Podcast',
  'Other',
];

export const PRACTICE_AREAS = [
  'Administrative',
  'Bankruptcy',
  'Builders Liens',
  'Business',
  'Civil Litigation',
  'Commercial',
  'Conveyance (Purchase)',
  'Conveyance (Sale)',
  'Corporate',
  'Criminal',
  'Employment',
  'Estates',
  'Family',
  'Immigration',
  'Insurance',
  'Personal Injury',
  'Tax',
  'Wills',
];
