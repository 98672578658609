import React, { useEffect, useState } from 'react';
import Card from '~/src/components/Card';
import { css } from 'aphrodite';

import { Form, renderFormField } from '~/src/components/Forms';
import { SLIDE_IN_ANIMATION_DURATION } from '~/src/components/PageLayout/styles';
import styles from './styles';

const ContactSlideInDetail = ({
  fieldValues,
  renderHeader,
  contact,
  edit,
  onFormChange,
}) => {
  const [fieldsProp, setFieldsProp] = useState([]);
  // To copy initial value of contact form to reset if edit is cancelled
  let initialFieldProps;

  const setFields = async () => {
    const fields = await contact.getFormFields(!edit, fieldValues);

    fields.filter((field) => {
      if (!edit && !field.defaultValue) {
        return false;
      }
      return true;
    });

    const nextFieldsProp = fields.map((field) => {
      if (!edit) {
        field.value = field.defaultValue;
      }
      return renderFormField({ ...field, editable: edit });
    });
    // set form value to initial value
    if (edit) {
      setFieldsProp(initialFieldProps);
    }
    setFieldsProp(nextFieldsProp);
  };

  // Called everytime edit changes
  useEffect(() => {
    setFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  // Called once, on first render
  useEffect(() => {
    setFields();
    // copy initial value for reset
    // eslint-disable-next-line react-hooks/exhaustive-deps
    initialFieldProps = fieldsProp;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card
      dynamic
      renderHeader={renderHeader}
      className={css(styles.contactSlideInCard)}
    >
      <Form
        fields={fieldsProp}
        onChange={onFormChange}
        focusFirstInput={'text'}
        focusTimeout={SLIDE_IN_ANIMATION_DURATION}
      />
    </Card>
  );
};

export default ContactSlideInDetail;
