import { useMutation } from 'react-query';
import env from '~/src/utils/env';
import cookieUtil from '~/src/utils/cookies';

export const LOGOUT_URL = `${env.apiUrl}/api/v2/account/logout/`;

const fetchLogout = () => fetch(LOGOUT_URL, { method: 'DELETE' });
const onSettled = () => {
  cookieUtil.logout();
  window.location.assign('/signin');
};

export const doLogout = async () => {
  try {
    await fetchLogout();
  } catch {
    // pass
  } finally {
    onSettled();
  }
};

export const useLogout = () =>
  useMutation({ mutationFn: fetchLogout, onSettled });
