import React from 'react';
import {
  BillingCycle,
  BillingCycleType,
  CourtFormsPlanCard,
  ContentPackagePlanCard,
  WordDocumentAutomationCard,
} from '~/src/entities/subscription';
import { Box, Stack } from '@mui/material';
import { Seats } from './Seats';
import { useConfirmPlanChangeModal } from './ConfirmPlan';
import { useSnackbar } from 'notistack';

type ConfigurePlanProps = {
  billingCycle: BillingCycleType;
  seats: number;
  contentPackage: boolean;
  word: boolean;
  courtForms: string[];
};

export const ConfigurePlan = ({
  seats,
  contentPackage,
  word,
  courtForms,
  billingCycle,
}: ConfigurePlanProps) => {
  const snackbar = useSnackbar();
  const [toggleConfirmModal, confirmPlanChangeModal] =
    useConfirmPlanChangeModal(
      { seats, contentPackage, word, courtForms, billingCycle },
      () => {
        toggleConfirmModal();

        snackbar.enqueueSnackbar('Subscription updated', {
          variant: 'success',
        });
      },
    );

  return (
    <Box>
      {confirmPlanChangeModal}
      <Box pb={5}>
        <Seats
          seats={seats}
          onChange={(nextSeats) => toggleConfirmModal({ seats: nextSeats })}
        />
      </Box>
      <Box pb={5}>
        <Stack spacing={1}>
          {contentPackage && (
            <ContentPackagePlanCard
              value={contentPackage}
              onChange={(nextContentPackage) =>
                toggleConfirmModal({ contentPackage: nextContentPackage })
              }
            />
          )}
          <CourtFormsPlanCard
            billingCycle={billingCycle}
            value={courtForms}
            onChange={(newCourtForms) =>
              toggleConfirmModal({ courtForms: newCourtForms })
            }
          />
          {!contentPackage && (
            <WordDocumentAutomationCard
              value={word}
              billingCycle={billingCycle}
              onChange={(nextWord) => toggleConfirmModal({ word: nextWord })}
            />
          )}
        </Stack>
      </Box>
      <BillingCycle
        value={billingCycle}
        readOnly={contentPackage}
        onChange={(nextBillingCycle) =>
          toggleConfirmModal({ billingCycle: nextBillingCycle })
        }
      />
    </Box>
  );
};
