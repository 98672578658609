/* Libs */
import React, { useState, useEffect } from 'react';
import { autorun } from 'mobx';

/* Components */
import QuestionFieldMapSlideIn from '~/src/components/QuestionFieldMapSlideIn';

const QuestionFieldMapSlideInContainer = ({ intake, questionId }) => {
  const [question, setQuestion] = useState({});

  const handleRemoveQuestionTag = () => {
    question.update({
      ...question,
      tag: '',
    });

    intake.removeTemplate();
  };

  const handleTemplateChanged = (templateId) => {
    question.update({
      ...question,
      tag: '',
    });

    intake.attachTemplate(templateId);
  };

  const handleSelectField = (field) => {
    const question = intake.getQuestion(questionId);

    question.update({
      ...question,
      tag: field.tag,
    });

    intake.update({ ...intake });
  };

  useEffect(() => {
    const question = intake.getQuestion(questionId);
    setQuestion(question);

    return autorun(() => {
      const question = intake.getQuestion(questionId);
      setQuestion(question);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <QuestionFieldMapSlideIn
      intake={intake}
      questionId={questionId}
      onRemoveQustionTag={handleRemoveQuestionTag}
      onTemplateChange={handleTemplateChanged}
      onFieldChange={handleSelectField}
    />
  );
};

export default QuestionFieldMapSlideInContainer;
