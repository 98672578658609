import React from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';
import { Edit3 } from 'react-feather';

import Button from '~/src/components/Button';
import Checkbox from '~/src/components/Checkbox';

import theme from '~/src/theme';

import { NAVIGATION_WIDTH } from '~/src/components/PageLayout/styles';

const styles = StyleSheet.create({
  documentPreviewFooter: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'space-between',
    bottom: 0,
    left: NAVIGATION_WIDTH,
    right: 0,
    borderTop: theme.border,
    background: theme.surface,
    padding: theme.unit * 2,
  },
  documentPreviewHighlightToggle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  documentPreviewHighlightToggleText: {
    display: 'inline-flex',
    fontSize: theme.fonts.size.small,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: theme.unit,
    marginRight: theme.unit * 2,
  },
  rightSideButtons: {
    display: 'flex',
    alignItems: 'center',
  },
  eSignButton: {
    marginRight: '10px',
  },
});

export const ViewDocumentFooter = (props) => {
  const { onEdit, onDownload, showHighlights, onToggleShowHighlights, onSign } =
    props;

  return (
    <footer className={css(styles.documentPreviewFooter)}>
      <span analyticsname="Document Edited">
        <Button Icon={Edit3} onClick={onEdit}>
          Edit
        </Button>
      </span>

      <div
        className={css(styles.documentPreviewHighlightToggle)}
        analyticsname="Highlight Changes"
      >
        <Checkbox
          checked={showHighlights}
          state={showHighlights}
          size={2}
          onClick={onToggleShowHighlights}
          disabled={false}
        />
        <span className={css(styles.documentPreviewHighlightToggleText)}>
          Highlight Changes
        </span>
        <div className={css(styles.rightSideButtons)}>
          <Button
            className={css(styles.eSignButton)}
            Icon={Edit3}
            onClick={onSign}
          >
            eSign
          </Button>
          <span analyticsname="Document Downloaded">
            <Button primary onClick={onDownload}>
              Download
            </Button>
          </span>
        </div>
      </div>
    </footer>
  );
};

ViewDocumentFooter.propTypes = {
  onDownload: PropTypes.func,
  onEdit: PropTypes.func,
  onSign: PropTypes.func,
  showHighlights: PropTypes.bool,
  onToggleShowHighlights: PropTypes.func,
};

ViewDocumentFooter.defaultProps = {
  onDownload: () => {},
  onEdit: () => {},
  onSign: () => {},
  showHighlights: true,
  onToggleShowHighlights: () => {},
};

export default ViewDocumentFooter;
