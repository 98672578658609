import React, { FC } from 'react';

import { ReactComponent as ChevronRightIcon } from './chevron-right.svg';

interface Props {
  label: string;
  handleClick: () => void;
}

const DrillDownPanelButton: FC<Props> = ({ label, handleClick }) => (
  <div
    className="hover:bg-gray-50 flex justify-between p-4 border-b last:border-0 border-slate-200"
    onClick={handleClick}
  >
    <div className="top-4 left-2 text-black">{label}</div>

    <div className="top-4 right-5">
      <ChevronRightIcon />
    </div>
  </div>
);

export default DrillDownPanelButton;
