import { Box, Button, CircularProgress, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import {
  BillingCycleType,
  useUpcomingInvoiceV2,
} from '~/src/entities/subscription';

type NextInvoiceProps = {
  seats: number;
  billingCycle: BillingCycleType;
  periodEnd: string;
};

export const NextInvoice = ({
  seats,
  billingCycle,
  periodEnd,
}: NextInvoiceProps) => {
  const { isLoading, isError, data } = useUpcomingInvoiceV2({
    seat: seats,
    duration: billingCycle,
  });

  return (
    <>
      <Box display={'flex'} alignItems={'center'}>
        <Box flex={'0 0 auto'} pr={2}>
          <Typography variant={'subheading'}>Next Invoice</Typography>
        </Box>
        {isLoading ? <CircularProgress size={15} /> : null}
      </Box>
      {isError ? (
        <Box display={'flex'} alignItems={'center'}>
          <Box pr={2}>
            <Typography variant={'body1'}>
              Failed to retrieve upcoming invoice
            </Typography>
          </Box>
          <Button size={'small'} variant={'text'}>
            Retry
          </Button>
        </Box>
      ) : null}
      {data ? (
        <Box pt={1}>
          <Typography variant={'body1'}>
            ${(data.total / 100).toFixed(2)} on{' '}
            {moment(periodEnd).format('MMMM D, YYYY')}
          </Typography>
        </Box>
      ) : null}
    </>
  );
};
