import React from 'react';
import Button from '~/src/components/Button';
import { ReactComponent as BackArrowIcon } from '~/src/static/back-arrow-icon.svg';

type BackArrowProps = {
  onClick: () => void;
};

export const BackArrow = ({ onClick }: BackArrowProps) => {
  return (
    <div
      style={{
        position: 'relative',
        top: '-7px',
        backgroundColor: 'white',
        border: 'solid 1px #e5e7eb',
        borderRadius: '4px',
        margin: '0 10px 10px 0',
      }}
    >
      <Button Icon={BackArrowIcon} onClick={onClick} />
    </div>
  );
};
