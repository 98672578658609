import React from 'react';
import { useLayoutContext } from '../contexts';

const WithLayoutProps = <P,>(WrappedComponent: React.ComponentType<P>) => {
  const Component = (props: P) => {
    return <WrappedComponent {...useLayoutContext()} {...props} />;
  };
  return Component;
};

export default WithLayoutProps;
