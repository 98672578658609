/* Libraries */
import { types, flow } from 'mobx-state-tree';

/* Stores */
import analyticsMiddleware from '~/src/analytics';
import UserStore from './userStore';
import ProjectsStore from './projectsStore';
import DocumentStore from './documentStore';
import DocumentSetsStore from './documentSetsStore';
import PaginatedTemplateStore from './templateStore';

import TemplateSetsStore from './templateSetsStore';
import SidebarItemsStore from './sidebarItemsStore';
import ContactsStore from './contactsStore';
import ClioStore from './clioStore';
import PaginatedMatterStore from './matterStore';
import SignatureStore from './signatureStore';

import PdfCourtFormsStore from './pdfCourtFormsStore';
import PdfLocalitiesStore from './pdfLocalitiesStore';

import QuestionnaireStore from './questionnairesStore';
import QuestionnaireResponseStore from './questionnaireResponsesStore';

import TagsStore from './tagsStore';

import PaginatedTemplateDefaultValuesStore from './templateDefaultValuesStore';

const RootStore = types
  .model({
    initializing: types.boolean,
    tags: TagsStore,
    questionnaireResponses: QuestionnaireResponseStore,
    questionnaires: QuestionnaireStore,
    user: UserStore,
    contacts: ContactsStore,
    templates: PaginatedTemplateStore,
    templateSets: TemplateSetsStore,
    sidebarItems: SidebarItemsStore,
    documents: DocumentStore,
    pdfCourtForms: PdfCourtFormsStore,
    pdfLocalities: PdfLocalitiesStore,
    projects: ProjectsStore,
    clio: ClioStore,
    matter: PaginatedMatterStore,
    signatures: SignatureStore,
    documentSets: DocumentSetsStore,
    templateDefaultValues: PaginatedTemplateDefaultValuesStore,
  })
  .actions((self) => ({
    setInitializing(value) {
      self.initializing = value || true;
    },

    initialize: flow(function* initialize() {
      self.initializing = true;
      yield self.user.initialize();

      self.clio.initialize();
      self.initializing = false;
    }),
  }));

export const stores = {
  questionnaires: QuestionnaireStore.create(),
  user: UserStore.create(),
  templates: PaginatedTemplateStore.create(),
  templateDefaultValues: PaginatedTemplateDefaultValuesStore.create(),
  templateSets: TemplateSetsStore.create(),
  sidebarItems: SidebarItemsStore.create(),
  projects: ProjectsStore.create(),
  contacts: ContactsStore.create(),
  documents: DocumentStore.create(),
  clio: ClioStore.create(),
  matter: PaginatedMatterStore.create(),
  signatures: SignatureStore.create(),
  pdfCourtForms: PdfCourtFormsStore.create(),
  pdfLocalities: PdfLocalitiesStore.create(),
  documentSets: DocumentSetsStore.create(),
  questionnaireResponses: QuestionnaireResponseStore.create(),
  tags: TagsStore.create(),
};

const store = RootStore.create({
  initializing: true,
  pageScroll: 0,
  ...stores,
});

stores.root = store;

analyticsMiddleware(stores, store);

// DEBUG
window.store = store;

export default store;
