import { useState, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { usePollingTaskStatus } from '~/src/hooks/usePollingTaskStatus';
import { AsyncResultStatus } from '~/src/entities/project/types';
import { getProjectUpdateStatusUrl } from '~/src/entities/project/urls';
import { useCurrentOrgFprint } from '~/src/entities/user';
import { keys } from '../keys';

const fetchProjectStatus = async (orgFprint: string, projectId: number) => {
  const response = await fetch(getProjectUpdateStatusUrl(orgFprint, projectId));
  const data = await response.json();
  return {
    taskId: data.task_id,
    isSuccessful: data.status === AsyncResultStatus.SUCCESS,
    status: data.status,
    isSettled:
      data.status === AsyncResultStatus.SUCCESS ||
      data.status === AsyncResultStatus.FAILURE,
  };
};

export const useProjectAsyncUpdateState = (
  projectId: number,
  isEnabled: boolean,
) => {
  const [isProjectUpdateSettled, setIsProjectUpdateSettled] = useState(false);
  const [projectUpdateError, setProjectUpdateError] = useState(false);
  const [isTaskStatusSettled, setIsTaskStatusSettled] = useState(false);
  const [taskId, setTaskId] = useState<string | undefined>(undefined);
  const isSuccessful = useRef<boolean>(false);
  const orgFprint = useCurrentOrgFprint();

  const projectStatusQuery = useQuery(
    [keys.PROJECT_ASYNC_UPDATE_STATUS, orgFprint, projectId],
    () => fetchProjectStatus(orgFprint, projectId),
    {
      enabled: isEnabled && !isProjectUpdateSettled,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setTaskId(data?.taskId);
        setIsProjectUpdateSettled(data?.isSettled);
      },
      onError: () => {
        setProjectUpdateError(true);
        setIsProjectUpdateSettled(true);
      },
    },
  );

  const taskStatusQuery = usePollingTaskStatus(taskId);

  useEffect(() => {
    const { data } = taskStatusQuery;
    if (data) {
      const { status } = data;
      if (status === AsyncResultStatus.SUCCESS) {
        isSuccessful.current = true;
      }
      if (
        status === AsyncResultStatus.SUCCESS ||
        status === AsyncResultStatus.FAILURE
      ) {
        setIsTaskStatusSettled(true);
        setTaskId(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskStatusQuery.data, projectStatusQuery.data]);
  if (projectStatusQuery.isSuccess) {
    if (projectStatusQuery.data?.isSuccessful) {
      isSuccessful.current = true;
    }
  }
  const isSettled = isTaskStatusSettled || isProjectUpdateSettled;
  return {
    data: {
      isSuccessful: isSuccessful.current,
      status: taskStatusQuery.data?.status || projectStatusQuery.data?.status,
      isSettled,
      hasError:
        (isSettled && !isSuccessful.current) ||
        projectUpdateError ||
        taskStatusQuery.isError,
    },
  };
};
