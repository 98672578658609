import React from 'react';
import { Text } from '~/src/components/Typography';
import { ReactComponent as FormTemplatesEmptyState } from '~/src/static/formTemplatesEmptyState.svg';

const TemplateDefaultValuesListEmptyView = () => {
  return (
    <div className="flex flex-col justify-center items-center gap-8 flex-grow-0 text-center mt-20">
      <FormTemplatesEmptyState />
      <div className="flex flex-col justify-center items-center gap-3 max-w-lg">
        <Text htmlElement="p" variant="display">
          Save time by pre-filling your commonly used forms
        </Text>

        <Text htmlElement="p" subdued>
          Pre-fill forms with custom edits and checkboxes so you don’t have to
          start from scratch every time you are drafting a new form. Click on
          “Create form template” under the “Actions” dropdown of any court form
          to get started.
        </Text>

        <Text htmlElement="p" subdued>
          Learn more about
          <a
            className="ml-1"
            href="https://help.lawyaw.com/en/articles/6483780-get-started-with-form-templates"
          >
            form templates
          </a>
        </Text>
      </div>
    </div>
  );
};

export default TemplateDefaultValuesListEmptyView;
