import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

export default StyleSheet.create({
  arrow: {
    position: 'relative',
    background: theme.colors.charcoal,
    width: 5 * 2,
    padding: `0 ${theme.unit}px`,
    ':after': {
      position: 'absolute',
      bottom: '100%',
      left: '50%',
      border: 'solid transparent',
      content: "' '",
      height: 0,
      width: 0,
      pointerEvents: 'none',
      borderBottomColor: theme.colors.charcoal,
      borderWidth: 5,
      marginLeft: -5,
    },
  },

  primary: {
    background: theme.colors.bluebird,
    ':after': {
      borderBottomColor: theme.colors.bluebird,
    },
  },

  arrowUp: { top: 3 },
  arrowLeft: { transform: 'rotate(-90deg)' },
  arrowRight: { transform: 'rotate(90deg)' },
  arrowDown: {
    bottom: 2,
    transform: 'rotate(180deg)',
  },
});
