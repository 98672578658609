import { useState, useEffect } from 'react';

import useMst from '~/src/hooks/useMst';

import { TemplateDefaultValues } from '~/src/models';

const useFetchTemplateDefaultValues = (templateDefaultValuesID: number) => {
  const { getTemplateDefaultValues } = useMst((store) => ({
    getTemplateDefaultValues:
      store.templateDefaultValues.getTemplateDefaultValues,
  }));

  const [templateData, setTemplateData] =
    useState<null | TemplateDefaultValues>(null);

  useEffect(() => {
    getTemplateDefaultValues(templateDefaultValuesID, true).then(
      (nextTemplateDefaultValue: TemplateDefaultValues) => {
        setTemplateData(nextTemplateDefaultValue);
      },
    );
  }, [templateDefaultValuesID, getTemplateDefaultValues]);

  return templateData;
};

export default useFetchTemplateDefaultValues;
