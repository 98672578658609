import React, { useCallback, useEffect, useRef, useState } from 'react';
import cns from 'classnames';
import assert from 'assert';
import clamp from '~/src/utils/clamp';
import theme from '~/src/theme';
import { ReactComponent as SignSignatureIcon } from '~/src/static/sign-signature-icon.svg';
import { ReactComponent as SignDateIcon } from '~/src/static/sign-date-icon.svg';
import useWebViewerIframe from '~/src/components/SignerSelection/hooks/useWebViewerIframe';
import {
  SIGNATURE_ANNOTATION_HEIGHT_PX,
  SIGNATURE_ANNOTATION_WIDTH_PX,
} from '~/src/components/SignerSelection/constants';

import { createPortal } from 'react-dom';
import { Coordinates } from '~/src/models';

const changeIframeCursor = (
  iframe: HTMLIFrameElement,
  newCursor: 'crosshair' | 'default' /* null means delete */,
) => {
  // FIXME: haven't figured out why iframe sometimes does not exist here. Happens after dropping an annotation in
  // one doc and then going to another doc and trying to drop an annotation.
  if (!iframe.contentWindow) {
    return;
  }
  Array.from(
    iframe.contentWindow!.document.getElementsByClassName('pageSection'),
  ).forEach((element) => {
    // eslint-disable-next-line no-param-reassign
    (element as HTMLElement).style.cursor = newCursor;
  });
};

interface MinMax {
  min: number;
  max: number;
}

const root = document.getElementById('root')!;

type OnDrop = (type: 'signature' | 'date', droppedAt: Coordinates) => void;

const useGhost = (onDrop?: OnDrop) => {
  const iframe = useWebViewerIframe();
  const [type, _setType] = useState<'date' | 'signature' | null>(null);
  const typeRef = React.useRef<'date' | 'signature' | null>(null);
  const setType = (data: 'date' | 'signature' | null) => {
    typeRef.current = data;
    _setType(data);
  };
  const [active, _setActive] = useState(false);
  const activeRef = React.useRef(active);
  const setActive = (data: boolean) => {
    activeRef.current = data;
    _setActive(data);
  };
  const [coords, setCoords] = useState<{ top: number; left: number }>({
    top: 0,
    left: 0,
  });
  const [x, _setX] = useState<MinMax>({ min: 0, max: 0 });
  const xRef = React.useRef(x);
  const setX = (data: MinMax) => {
    xRef.current = data;
    _setX(data);
  };
  const [y, _setY] = useState({ min: 0, max: 0 });
  const yRef = React.useRef(y);
  const setY = (data: MinMax) => {
    yRef.current = data;
    _setY(data);
  };
  const outerLeft = useRef(0);
  const outerTop = useRef(0);

  const stopGhostAnnotation = useCallback(() => {
    setType(null);
    setActive(false);
    changeIframeCursor(iframe!, 'default');
  }, [iframe]);

  useEffect(() => {
    if (!iframe) return;
    if (!onDrop) return;
    const ghostMouseMoveListener = (e: MouseEvent) => {
      if (!activeRef.current) {
        return;
      }
      const top = clamp(
        outerTop.current + e.pageY,
        yRef.current.min,
        yRef.current.max,
      );
      const left = clamp(
        outerLeft.current + e.pageX,
        xRef.current.min,
        xRef.current.max,
      );
      setCoords({ top, left });
    };
    iframe.contentWindow!.document.addEventListener(
      'mousemove',
      ghostMouseMoveListener,
    );
    const ghostClickListener = (e: MouseEvent) => {
      if (!activeRef.current) {
        return;
      }
      const scrollContainer =
        iframe.contentWindow!.document.getElementsByClassName('document')[0];
      const rect = scrollContainer!.getBoundingClientRect();
      const top = Math.abs(rect.top);
      onDrop(typeRef.current!, { x: e.pageX, y: top + e.pageY });
      stopGhostAnnotation();
    };
    iframe.contentWindow!.document.addEventListener(
      'click',
      ghostClickListener,
    );

    // eslint-disable-next-line consistent-return
    return () => {
      iframe.contentWindow!.document.removeEventListener(
        'mousemove',
        ghostMouseMoveListener,
      );
      iframe.contentWindow!.document.removeEventListener(
        'click',
        ghostClickListener,
      );
    };
  }, [onDrop, stopGhostAnnotation, iframe]);

  const startGhostSignature = () => {
    assert(
      !!iframe,
      'Webviewer IFrame not found for the ghost annotation to use!',
    );
    const documentElements =
      iframe.contentWindow!.document.getElementsByClassName('document');
    assert(
      documentElements.length === 1,
      `Expected one 'document' element for the ghost annotation but found ${documentElements.length}`,
    );
    const document = documentElements[0]!;
    // WARNING: getBoundingClientRect causes reflows
    const outer = iframe.getBoundingClientRect();
    const inner = document.getBoundingClientRect();
    outerLeft.current = outer.x;
    outerTop.current = outer.y;
    const minX = outer.x + inner.x;
    const maxX = minX + +inner.width;
    const minY = outer.y + inner.y;
    const maxY = minY + inner.height;
    setX({ min: minX, max: maxX });
    setY({ min: minY, max: maxY });
    setCoords({ top: minY, left: minX });
    setType('signature');
    setActive(true);
    changeIframeCursor(iframe, 'crosshair');
  };

  const startGhostDate = () => {
    assert(
      !!iframe,
      'Webviewer IFrame not found for the ghost annotation to use!',
    );
    const documentElements =
      iframe.contentWindow!.document.getElementsByClassName('document');
    assert(
      documentElements.length === 1,
      `Expected one 'document' element for the ghost annotation but found ${documentElements.length}`,
    );
    const document = documentElements[0]!;
    // WARNING: getBoundingClientRect causes reflows
    const outer = iframe.getBoundingClientRect();
    const inner = document.getBoundingClientRect();
    outerLeft.current = outer.x;
    outerTop.current = outer.y;
    const minX = outer.x + inner.x;
    const maxX = minX + +inner.width;
    const minY = outer.y + inner.y;
    const maxY = minY + inner.height;
    setX({ min: minX, max: maxX });
    setY({ min: minY, max: maxY });
    setCoords({ top: minY, left: minX });
    setType('date');
    setActive(true);
    changeIframeCursor(iframe, 'crosshair');
  };

  const ghostAnnotation = createPortal(
    <div
      id="ghost-annotation"
      className={cns(
        `flex justify-center items-center gap-1 border fixed bg-black px-4 py-1 rounded-sm pointer-events-none`,
        {
          hidden: !active,
        },
      )}
      style={{
        top: coords.top,
        left: coords.left,
        color: 'rgba(17, 24, 39)',
        backgroundColor: 'rgba(196, 196, 200, 0.15)',
        borderColor: 'rgba(196, 196, 200, 1)',
        width: SIGNATURE_ANNOTATION_WIDTH_PX,
        height: SIGNATURE_ANNOTATION_HEIGHT_PX,
        zIndex: 10,
      }}
    >
      {type === 'date' ? (
        <SignDateIcon color={theme.colors.blue600} fontSize={16} />
      ) : (
        <SignSignatureIcon color={theme.colors.blue600} fontSize={16} />
      )}
    </div>,
    root,
  );

  return {
    startGhostSignature,
    startGhostDate,
    ghostAnnotation,
  };
};

export default useGhost;
