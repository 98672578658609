/* Libraries */
import { css } from 'aphrodite';
import React from 'react';

/* Styles */
import styles from './styles';

const Body = ({ children }) => {
  return <div className={css(styles.body)}>{children}</div>;
};

export default Body;
