export default {
  white: '#FFFFFF',
  charcoal: '#202A33',
  chrome: '#7B8396',
  smoke: '#DADDE6',
  ice: '#F2F4F7',
  pattensBlue: '#DDF2FA',
  darkIce: '#DEDEDF',
  concrete: '#B3B3B3',
  bluebird: '#2459ED',
  darkBlue: '#00008B',
  babyBlue: '#CDEDF7',
  eggplant: '#7052C4',
  paleEggplant: '#F4F0FF',
  tennisBall: '#1ABC60',
  paleTomato: '#DC6363',
  beeswax: '#FFF1C8',
  tennisBallGreen: '#00C243',
  blue600: '#2563EB',
};
