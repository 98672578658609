import React from 'react';
import { MenuItem, Select, SelectProps } from '@mui/material';

type SelectWithOptionsProps = SelectProps & { options: string[] };

export const SelectWithOptions = React.forwardRef(
  ({ options, ...rest }: SelectWithOptionsProps, ref) => (
    <Select inputRef={ref} {...rest}>
      {options.map((o) => (
        <MenuItem key={o} value={o}>
          {o}
        </MenuItem>
      ))}
    </Select>
  ),
);
