// eslint-disable-next-line no-shadow
export enum CourtFormSortByOption {
  lastModified = 'last_modified',
  legalDocumentType = 'legal_document_type',
  legalPracticeArea = 'legal_practice_area',
  title = 'title',
  subtitle = 'subtitle',
  filterCategory = 'filter_category',
  locality = 'locality',
  createdAt = 'created_at',
}
export default CourtFormSortByOption;
