import React, { useState } from 'react';
import { Form, renderFormField } from '~/src/components/Forms';

import { ANIMATION_DURATION } from '~/src/components/Modal/styles';
import { VALIDATION_TYPES } from '~/src/utils/validation';

const CreditCardForm = ({ onChange }) => {
  const [fieldsValid, setFieldsValid] = useState({});

  const handleChange = (form) => {
    onChange(form);
    const nextFieldsValid = { ...fieldsValid };

    Object.keys(form.fields).forEach((fieldKey) => {
      const field = form.fields[fieldKey];
      nextFieldsValid[fieldKey] = !!field.value === false ? true : field.valid;
    });

    setFieldsValid(nextFieldsValid);
  };

  const fields = [
    {
      id: 'number',
      label: 'Credit Card Number',
      type: 'ccNumber',
      validation: VALIDATION_TYPES.ccNumber,
      error: fieldsValid.number ? null : 'Not a valid credit card number',
      required: true,
    },
    {
      id: 'exp',
      label: 'Expiration Date (MM/YYYY)',
      type: 'ccExp',
      validation: VALIDATION_TYPES.ccExp,
      error: fieldsValid.exp ? null : 'Not a valid expiration date',
      required: true,
    },
    {
      id: 'cvc',
      label: 'CVC',
      type: 'ccCvc',
      validation: VALIDATION_TYPES.ccCvc,
      error: fieldsValid.cvc ? null : 'Not a valid cvc number',
      required: true,
    },
    {
      id: 'address_zip',
      label: 'Billing Zip Code',
      autoComplete: 'billing postal-code',
      type: 'text',
      validation: VALIDATION_TYPES.present,
      error: fieldsValid.address_zip ? null : 'Not a valid zip code',
      required: true,
    },
    {
      id: 'address_city',
      autoComplete: 'billing address-level2',
      label: 'City',
      type: 'text',
      validation: VALIDATION_TYPES.present,
      error: fieldsValid.address_state ? null : 'Not a valid city',
      required: true,
    },
    {
      id: 'address_state',
      autoComplete: 'billing address-level1',
      label: 'State',
      type: 'text',
      validation: VALIDATION_TYPES.present,
      error: fieldsValid.address_city ? null : 'Not a valid state',
      required: true,
    },
  ];

  const renderFields = fields.map((field) => renderFormField(field));

  return (
    <Form
      triggerOnMount={false}
      fields={renderFields}
      onChange={handleChange}
      focusFirstInput={'ccNumber'}
      focusTimeout={ANIMATION_DURATION}
    />
  );
};

export default CreditCardForm;
