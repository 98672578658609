export const ccFormat = (value) => {
  const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
  const matches = v.match(/\d{4,16}/g);
  const match = (matches && matches[0]) || '';
  const parts = [];

  for (let i = 0, len = match.length; i < len; i += 4) {
    parts.push(match.substring(i, i + 4));
  }

  if (parts.length) {
    return parts.join(' ');
  }
  return value;
};

export const ccExpFormat = (value) => {
  const cleaned = value.replace(/[^0-9]/, '').replace('/', '');

  if (cleaned.length >= 3) {
    return `${cleaned.slice(0, 2)}/${cleaned.slice(2)}`;
  }
  return cleaned;
};

export const checkExp = (value) => {
  const cleanedValue = value.split('/').join('');
  return cleanedValue.length <= 6;
};

export const checkIsNumber = (value) => {
  const cleanedValue = value.split(' ').join('');
  const isNumber = /^\d+$/.test(cleanedValue);
  return isNumber;
};

export const doesTextMatchSearchQuery = (src, query) => {
  const sanitizedQuery = query.toLowerCase().replace(' ', '');
  const sanitizedSrc = src.toLowerCase().replace(' ', '');

  return sanitizedSrc.indexOf(sanitizedQuery) > -1;
};
