import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

/* Components */
import SettingsSecurity from '~/src/components/Settings/SettingsSecurity';

/* Modals */
import { LAYOUT_MODAL_TYPES } from '~/src/components/PageLayout/Modals';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import { doLogout } from '~/src/entities/user';

class SettingsSecurityContainer extends React.Component {
  handleChangePassword = () => {
    const handleConfirm = async (form) => {
      const newPassword = form.fields.newPassword.value;
      const password = form.fields.currentPassword.value;

      const success = await this.props.store.user.changePassword(
        password,
        newPassword,
      );

      if (success) {
        this.props.showToast(LAYOUT_TOAST_TYPES.success, {
          message: 'Password updated!',
        });
        this.props.hideModal();
        setTimeout(doLogout, 2500);
      }

      return success;
    };

    this.props.showModal(LAYOUT_MODAL_TYPES.passwordChange, {
      onConfirm: handleConfirm,
    });
  };

  handleToggleTwoStepVerification = async () => {
    const twoFactorEnabled = this.props.store.user.profile.ssoEnabled;

    if (twoFactorEnabled) {
      const res = await this.props.store.user.disableTwoFactorAuth();
      if (res) {
        this.props.showToast(LAYOUT_TOAST_TYPES.success, {
          message: 'Two factor auth disabled',
        });
      } else {
        this.props.showToast(LAYOUT_TOAST_TYPES.error);
      }
    } else {
      this.props.showModal(LAYOUT_MODAL_TYPES.twoFactorAuth);
    }
  };

  render() {
    const email =
      this.props.store.user.profile.email ||
      this.props.store.user.currentOrganization.email;
    const twoFactorEnabled = this.props.store.user.profile.ssoEnabled;

    return (
      <SettingsSecurity
        email={email}
        onToggleTwoStepVerification={this.handleToggleTwoStepVerification}
        twoFactorEnabled={twoFactorEnabled}
        onChangePassword={this.handleChangePassword}
      />
    );
  }
}

SettingsSecurityContainer.propTypes = {
  showModal: PropTypes.func,
};

SettingsSecurityContainer.defaultProps = {
  showModal: () => {},
};

export default inject((store) => store)(observer(SettingsSecurityContainer));
