/* Libraries */
import React, { FC, useEffect } from 'react';
import { snakeCase } from 'lodash';

/* Hooks */
import usePageScroll from '~/src/hooks/usePageScroll';
import useTemplateDefaultValuesPagination from '~/src/hooks/useTemplateDefaultValuesPagination';

/* Components */
import TemplateDefaultValuesList from '~/src/components/TemplateDefaultValuesList/TemplateDefaultValuesList';
import TemplateDefaultValuesListEmptyView from '~/src/components/TemplateDefaultValuesList/TemplateDefaultValuesListEmptyView';
import LoadMoreDisplayText from '~/src/components/LoadMoreDisplayText';

/* Types */
import { TemplateDefaultValues } from '~/src/models';
import { SortByOrder } from '~/src/hooks/sortByOrder';
import { Actions } from '../RowActions/RowActions';

interface Props {
  addFormTemplateToSelectionSidebar: (t: TemplateDefaultValues) => void;
  containedInSidebar: (t: TemplateDefaultValues) => boolean;
  rowActions?: Actions<TemplateDefaultValues>;
  sidebarItemTotalNumber: number;
  sidebarItemTotalNumberLimit: number;
}

const TemplateDefaultValuesListContainer: FC<Props> = ({
  addFormTemplateToSelectionSidebar,
  containedInSidebar,
  rowActions,
  sidebarItemTotalNumber,
  sidebarItemTotalNumberLimit,
}) => {
  const { next, loading, empty, done, data, setSearch, setSortBy, setOrder } =
    useTemplateDefaultValuesPagination();

  const tableData = data.map(
    (templateDefaultValues: TemplateDefaultValues) => ({
      id: templateDefaultValues.id,
      title: templateDefaultValues.title || 'untitled',
      lastModified: templateDefaultValues.lastModified,
      template: templateDefaultValues.template,
    }),
  );

  const pageScroll = usePageScroll();
  const loadMore = next;

  const handleOnPageScroll = () => {
    if ((pageScroll === 0 || pageScroll >= 85) && !loading) {
      loadMore();
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleOnPageScroll, [pageScroll]);

  const isAddButtonDisabled = () =>
    sidebarItemTotalNumber >= sidebarItemTotalNumberLimit;

  const getAddButtonTooltip = () =>
    sidebarItemTotalNumber >= sidebarItemTotalNumberLimit
      ? 'Cannot add more than 20 documents to selection.'
      : null;

  const handleSearchChange = (searchValue: string) => {
    setSearch(searchValue);

    // If there is a search query value, use the title to sort by...
    // for empty queries, we will use the last modified.
    if (searchValue) {
      setSortBy('title');
      setOrder(SortByOrder.asc);
    } else {
      setSortBy('last_modified');
      setOrder(SortByOrder.desc);
    }
  };

  const handleSortByChange = (property: string, order: number) => {
    if (!property) {
      return;
    }

    const orderLabel = order > 0 ? SortByOrder.desc : SortByOrder.asc;
    let sortBy = snakeCase(property);

    if (property === 'updatedAt') {
      sortBy = 'last_modified';
    }

    setSortBy(sortBy);
    setOrder(orderLabel);
  };

  if (empty) {
    return (
      <>
        <TemplateDefaultValuesListEmptyView />
      </>
    );
  }

  return (
    <>
      <TemplateDefaultValuesList
        templateDefaultValues={tableData}
        visibleItems={tableData.length}
        empty={!loading && done && tableData.length === 0}
        rowActions={rowActions}
        containedInSidebar={containedInSidebar}
        isAddButtonDisabled={isAddButtonDisabled()}
        getAddButtonTooltip={getAddButtonTooltip()}
        addFormTemplateToSelectionSidebar={addFormTemplateToSelectionSidebar}
        onSearchChange={handleSearchChange}
        onSortByChange={handleSortByChange}
      />
      <LoadMoreDisplayText
        loading={loading}
        done={done}
        onLoadMore={loadMore}
      />
    </>
  );
};

export default TemplateDefaultValuesListContainer;
