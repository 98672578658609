import React from 'react';
import { Box } from '@mui/material';

export const ListViewContainer: React.FC<{ children: React.ReactFragment }> = ({
  children,
}) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(1),
        background: theme.palette.grey[100],
        overflowY: 'auto',
        maxHeight: '200px',
        gap: theme.spacing(1),
      })}
    >
      {children}
    </Box>
  );
};
