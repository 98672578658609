/* Libs */
import PropTypes from 'prop-types';
import React from 'react';
import { observer } from 'mobx-react';

/* Hooks */
import useMst from '~/src/hooks/useMst';

/* Components */
import { LoadingOverlay } from '~/src/components/PageLayout';

/* Animations */
import { FadeIn } from '~/src/components/Animations';

const PageContainer = ({ children, loading, loadingTitle }) => {
  const { initializing } = useMst((store) => ({
    initializing: store.initializing,
  }));

  if (loading) {
    return <LoadingOverlay title={loadingTitle} />;
  }

  return !initializing && <FadeIn>{children}</FadeIn>;
};

const propTypes = {
  loading: PropTypes.bool,
  loadingTitle: PropTypes.string,
};

const defaultProps = {
  loading: true,
  loadingTitle: 'Loading',
};

PageContainer.propTypes = propTypes;
PageContainer.defaultProps = defaultProps;

export default observer(PageContainer);
