import { useMutation } from 'react-query';
import env from '~/src/utils/env';
import { APIError } from '~/src/utils/error';

export const ACCOUNT_URL = `${env.apiUrl}/clients/api/v1/account/`;

type UpdateAccountParams = {
  phone: string;
  state: string;
  first_name: string;
  last_name: string;
  middle_name: string;
};

export const useUpdateAccount = () =>
  useMutation<unknown, APIError, Partial<UpdateAccountParams>>({
    mutationFn: (body) =>
      fetch(ACCOUNT_URL, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: { 'content-type': 'application/json' },
      }),
  });
