/* Libraries */
import React from 'react';
import Button from '@mui/material/Button';
import { DialogContainer, MessageDialog } from '~/src/components/Modal/mui';

type ContactDeleteWarningModalProps = {
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
};

const ContactDeleteWarningModal = ({
  open,
  onCancel,
  onConfirm,
}: ContactDeleteWarningModalProps) => (
  <DialogContainer open={open} onClose={onCancel}>
    <MessageDialog
      title="Delete Contact"
      onClose={onCancel}
      dialogActions={
        <>
          <Button onClick={onCancel}>Cancel</Button>
          <Button onClick={onConfirm} variant="red" autoFocus>
            Delete
          </Button>
        </>
      }
    >
      <strong>Warning: this action is permanent.</strong>
      <br />
      <br />
      If this contact was synced from Clio Manage, you will not be able to
      resync it.
    </MessageDialog>
  </DialogContainer>
);

export default ContactDeleteWarningModal;
