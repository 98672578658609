/* Libraries */
import { types, flow } from 'mobx-state-tree';
import qs from 'qs';
import moment from 'moment';

/* Services */
import clioService from '~/src/services/clio';
import env from '~/src/utils/env';

import { CLIO_ERROR_STATE } from '../utils/constants';
import { getOrgFprintFromStoreNode } from './utils';
import analyticsService from '../services/analytics';

const Clio = types
  .model('Clio', {
    error: types.maybeNull(types.string),
    code: types.maybe(types.string),
    lastSynced: types.maybeNull(types.number),
    syncing: types.optional(types.boolean, false),
    clioSyncStatus: types.optional(types.string, 'disabled'),
    authenticated: types.optional(types.boolean, false),
    authenticating: types.optional(types.boolean, false),
    authenticationFailed: types.optional(types.boolean, false),
    pending: types.optional(types.boolean, false),
    importing: types.optional(types.boolean, false),
    importSuccess: types.optional(types.boolean, false),
    importFailed: types.optional(types.boolean, false),
    clearingData: types.optional(types.boolean, false),
    courtFormClioParentId: types.maybeNull(types.string),
    courtFormClioMatterId: types.maybeNull(types.string),
    syncedMatterId: types.maybeNull(types.number),
    errorState: types.union(
      types.enumeration('ErrorState', Object.values(CLIO_ERROR_STATE)),
      types.undefined,
    ),
  })
  .actions((self) => {
    const initialize = flow(function* initialize() {
      // TODO: we need to initialize proper state here. do an http call to see
      // if anything was ever synced, imported or if a sync is in progress
      self.syncing = false;
      self.pending = false;
      self.importing = false;
      self.clioSyncStatus = 'disabled';
      yield self.updateClioIntegrationData();
    });

    const clear = () => {
      self.syncing = false;
      self.authenticated = false;
      self.authenticating = false;
      self.authenticationFailed = false;
      self.pending = false;
      self.importing = false;
      self.importSuccess = false;
      self.importFailed = false;
      self.clearingData = false;
      self.clioSyncStatus = 'disabled';
    };

    const setAuthenticated = (value) => {
      self.authenticated = value;
    };

    const setLastSynced = (value) => {
      if (typeof value === 'string') {
        const date = new Date(value);
        self.lastSynced = date.getTime();
      } else if (typeof value === 'number') {
        self.lastSynced = value;
      }
    };

    const sync = flow(function* sync() {
      self.syncing = true;

      try {
        yield clioService.sync(getOrgFprintFromStoreNode(self));

        self.lastSynced = Date.now();
        analyticsService.track('Clio Sync');
        return true;
      } catch (error) {
        if (error.statusCode === 417) {
          self.authenticated = false;
          self.authenticationFailed = true;
        }

        console.error('Something went wront syncing clio', error);
        self.syncing = false;
        return false;
      }
    });

    const updateClioIntegrationData = flow(
      function* updateClioIntegrationData() {
        const res = yield clioService.getClioIntegrationData(
          getOrgFprintFromStoreNode(self),
        );
        self.syncing = false;
        if (res) {
          const newClioSyncStatus = res.clio_sync_status;
          self.authenticated = res.clio_token_present;
          if (res.last_clio_sync !== null) {
            self.setLastSynced(res.last_clio_sync);
          }

          if (
            newClioSyncStatus !== null &&
            self.clioSyncStatus !== newClioSyncStatus
          ) {
            self.clioSyncStatus = newClioSyncStatus;
            return newClioSyncStatus;
          }
          return null;
        }
      },
    );

    const deauthenticate = flow(function* updateClioIntegrationData() {
      const res = yield clioService.deauthenticate(
        getOrgFprintFromStoreNode(self),
      );
      yield self.updateClioIntegrationData();
      return !!res;
    });

    const authenticate = flow(function* authenticate(code) {
      try {
        self.pending = true;
        self.authenticating = false;

        const resp = yield clioService.authenticate(
          getOrgFprintFromStoreNode(self),
          code,
        );
        // If we don't get an error the reques was a success!
        self.code = code;
        self.authenticated = true;
        self.authenticationFailed = false;
        self.lastSynced = Date.now();
        analyticsService.track('Clio Authenticate');
        return {
          mattersCount: resp.matters_count,
          contactsCount: resp.contacts_count,
        };
      } catch (error) {
        self.authenticating = false;
        self.authenticated = false;
        self.authenticationFailed = true;
        if (error && typeof error === 'string') {
          self.error = error;
        }
        console.error('Something went wrong while authenticating clio', error);
        return null;
      }
    });

    const bulkImport = flow(function* bulkImport() {
      self.importing = true;

      try {
        yield clioService.bulkImport(getOrgFprintFromStoreNode(self));
        self.importSuccess = true;
        self.pending = false;
        self.importFailed = false;
        self.importing = false;
        return true;
      } catch (error) {
        if (error && typeof error === 'string') {
          self.error = error;
        }
        console.error(
          'Something went wrong while bulk importing clio data',
          error,
        );
        self.importSuccess = false;
        self.importFailed = true;
        self.importing = false;
        return false;
      }
    });

    const setCourtFormClioMatterId = (courtFormClioMatterId) => {
      self.courtFormClioMatterId = courtFormClioMatterId;
    };
    const setCourtFormClioParentId = (courtFormClioParentId) => {
      self.courtFormClioParentId = courtFormClioParentId;
    };

    const setCourtFormParams = (params) => {
      self.courtFormClioParentId = params.courtFormClioParentId || null;
      self.courtFormClioMatterId = params.courtFormClioMatterId || null;
      self.syncedMatterId = params.syncedMatterId || null;
    };

    const clearCourtFormParams = () => {
      self.courtFormClioParentId = null;
      self.courtFormClioMatterId = null;
      self.syncedMatterId = null;
    };

    const setErrorState = (error) => {
      self.errorState = error;
    };

    const clearErrorState = () => {
      delete self.errorState;
    };

    return {
      setAuthenticated,
      setLastSynced,
      clear,
      initialize,
      sync,
      bulkImport,
      authenticate,
      deauthenticate,
      updateClioIntegrationData,
      setCourtFormClioMatterId,
      setCourtFormClioParentId,
      setCourtFormParams,
      clearCourtFormParams,
      setErrorState,
      clearErrorState,
    };
  })
  .views((self) => {
    const lastSyncedDisplayText = () => {
      if (self.lastSynced) {
        return moment(self.lastSynced).fromNow();
      }

      return false;
    };

    const getAuthUrl = (redirectUrl) => {
      const queryParams = {
        response_type: 'code',
        client_id: env.clioClientId,
        redirect_uri: redirectUrl,
      };

      return `${env.clioBaseUrl}/oauth/authorize?${qs.stringify(queryParams)}`;
    };

    return {
      lastSyncedDisplayText,
      getAuthUrl,
    };
  });

export default Clio;
