import React from 'react';
import { css } from 'aphrodite';
import Button from '~/src/components/Button';
import modalStyles from '~/src/components/Modal/styles';
import {
  COURT_FORM_PLANS,
  DOCUMENT_AUTOMATION_PLANS,
} from '~/src/utils/constants';
import { DueToday } from '../DueToday';
import { CouponInput } from './CouponInput';
import { SeatsItem } from './SeatsItem';
import { PlanItem } from './PlanItem';

export const SummaryForm = ({
  showNextButton,
  totalPrice,
  couponSubmitting,
  onCouponChange,
  onCouponSubmit,
  onConfirm,
  courtFormPlans,
  documentAutomationPlans,
  seats,
  cycle,
  couponError,
  coupon,
}) => {
  return (
    <div>
      <h1 className={css(modalStyles.modalTitle)}>Summary</h1>

      <div className="text-base font-medium mb-2">Recurring charges</div>

      <SeatsItem seats={seats} cycle={cycle} />

      {courtFormPlans.map((planId) => {
        const plan = COURT_FORM_PLANS[cycle].find((item) => item.id === planId);
        if (!plan) return null;

        return (
          <PlanItem
            name={plan.name}
            price={plan.price}
            cycle={cycle}
            key={planId}
          />
        );
      })}

      {documentAutomationPlans.map((planId) => {
        const plan = DOCUMENT_AUTOMATION_PLANS[cycle].find(
          (item) => item.id === planId,
        );
        if (!plan) return null;

        return (
          <PlanItem
            name={plan.name}
            price={plan.price}
            cycle={cycle}
            key={planId}
          />
        );
      })}

      <CouponInput
        coupon={coupon}
        error={couponError}
        submitting={couponSubmitting}
        onChange={onCouponChange}
        onSubmit={() => onCouponSubmit(coupon)}
      />

      {totalPrice ? <DueToday {...totalPrice} /> : null}

      {showNextButton && (
        <div className={css(modalStyles.modalActions)}>
          <Button
            cssStyle={modalStyles.buttonAction}
            disabled={
              documentAutomationPlans.length === 0 &&
              courtFormPlans.length === 0
            }
            style={{ width: '100%' }}
            onClick={onConfirm}
            primary
          >
            Next
          </Button>
        </div>
      )}
    </div>
  );
};
