import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

export default StyleSheet.create({
  text: {
    fontSize: theme.fonts.size.small,
    marginLeft: theme.unit,
  },
  textDisabled: {
    color: theme.colors.chrome,
  },
  icon: {
    display: 'inline-block',
    width: theme.unit,
    height: theme.unit,
    borderRadius: theme.unit,
    content: ' ',
  },
  enabled: {
    background: theme.colors.tennisBall,
  },
  disabled: {
    background: theme.colors.darkIce,
  },
  error: {
    background: theme.colors.paleTomato,
  },
  syncing: {
    background: theme.colors.beeswax,
  },
});
