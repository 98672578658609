import React from 'react';
import { Box } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import moment from 'moment';
import { FieldRenderProps } from '../PopulateCardField';
import {
  ProjectStackField,
  ProjectStackDocument,
} from '~/src/entities/project';
import FieldHeader from '../FieldHeader';
import { isDateValid } from '~/src/utils/validation';

export type DatePickerFieldProps = {
  field: FieldRenderProps;
  projectField: ProjectStackField;
  projectStackDocuments: ProjectStackDocument[];
};

export const DatePickerField = React.memo(
  ({ field, projectField, projectStackDocuments }: DatePickerFieldProps) => {
    const { value, onChange, countTouched, ref, ...rest } = field;

    const format = projectField.date_format || 'MM/DD/YYYY';

    const parseDateStringToMoment = (dateString: string) => {
      if (isDateValid(dateString)) {
        const moment1 = moment.utc(dateString, format);
        if (moment1.isValid()) {
          const dateStringInSpecifiedFormat = moment1.format(format);
          if (dateStringInSpecifiedFormat !== dateString) {
            onChange(dateStringInSpecifiedFormat);
          }
          return moment1;
        }
      } else {
        // Date.parse(dateString) === NaN
        // e.g.'DD/MM/YY', 'DD/MM/YYYY', 'DD.MM.YY', 'DD.MM.YYYY', 'Do [day of] MMMM, YYYY'
        const moment1 = moment.utc(dateString, format);
        if (moment1.isValid()) {
          return moment1;
        }
      }

      // could not parse according to the desired format try an alternative parse
      if (moment(dateString, format).isValid()) {
        return moment.utc(new Date(dateString));
      }

      return null;
    };

    const handleChange = (momentObj: moment.Moment | null) => {
      if (momentObj && momentObj.isValid()) {
        onChange(momentObj.format(format));
      } else {
        onChange('');
      }
    };

    return (
      <Box>
        <Box>
          <FieldHeader
            projectField={projectField}
            projectStackDocuments={projectStackDocuments}
          />
        </Box>
        <Box>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              slotProps={{
                textField: {
                  fullWidth: true,
                  size: 'small',
                  placeholder: format,
                },
                field: { shouldRespectLeadingZeros: true },
              }}
              inputRef={ref}
              format={format}
              value={parseDateStringToMoment(value)}
              onChange={(evt) => {
                handleChange(evt);
                if (countTouched) {
                  countTouched(field.name);
                }
              }}
              {...rest}
            />
          </LocalizationProvider>
        </Box>
      </Box>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.field.value === nextProps.field.value &&
      prevProps.projectField === nextProps.projectField &&
      prevProps.projectStackDocuments === nextProps.projectStackDocuments
    );
  },
);
