import { Box, Typography } from '@mui/material';
import React from 'react';
import { SummaryItem } from '~/src/entities/subscription';

type TotalProps = {
  subtotal: number;
  discount?: number;
  discount_percent?: number;
  total: number;
};

export const Total = ({
  subtotal,
  discount,
  discount_percent,
  total,
}: TotalProps) => {
  return (
    <>
      <Box pb={2}>
        <Typography variant={'subheading'}>Due</Typography>
      </Box>
      <SummaryItem name={'Total'} price={`$${(subtotal / 100).toFixed(2)}`} />
      {discount ? (
        <SummaryItem
          name={`Discount (${discount_percent}%)`}
          price={`-$${(discount / 100).toFixed(2)}`}
        />
      ) : null}
      <SummaryItem name={'Due Today'} price={`$${(total / 100).toFixed(2)}`} />
    </>
  );
};
