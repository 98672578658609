import { Box } from '@mui/material';
import React from 'react';
import moment from 'moment';
import { Edit, Trash, Type } from 'react-feather';
import { CardListItemWithActions, CardListItemMetaText } from '../CardList';

export const CardListContactsTable = ({
  contacts,
  handleEdit,
  handleView,
  handleDelete,
}) => {
  return contacts.map((contact) => {
    const actions = [
      {
        label: 'Edit',
        icon: <Edit size={16} />,
        onClick: () => handleEdit(contact),
      },
      {
        label: 'View',
        icon: <Type size={16} />,
        onClick: () => handleView(contact),
      },
      {
        label: 'Delete',
        icon: <Trash size={16} />,
        onClick: () => handleDelete(contact),
      },
    ];

    const lastModifiedDate = contact.lastModified
      ? moment(contact.lastModified).format('MM/DD/YYYY')
      : '';

    const lastModifiedBy = contact.lastModifiedBy
      ? contact.lastModifiedBy.displayName
      : '';

    const lastUpdated = [lastModifiedDate, lastModifiedBy]
      .filter((exists) => !!exists)
      .join(', ');

    return (
      <Box pb={1} key={contact.id}>
        <CardListItemWithActions
          key={contact.id}
          title={{
            label: `${contact.firstName} ${contact.lastName}`,
            meta: [contact.email],
          }}
          meta={
            <CardListItemMetaText>
              Last updated: {lastUpdated}
            </CardListItemMetaText>
          }
          actions={{
            actions,
            primary: undefined,
          }}
        />
      </Box>
    );
  });
};
