import React, { useEffect, useState } from 'react';
import { TextField, Box } from '@mui/material';
import {
  ProjectStackField,
  ProjectStackDocument,
} from '~/src/entities/project';
import { FieldRenderProps } from './PopulateCardField';
import { useDebounce } from 'react-use';

import FieldHeader from './FieldHeader';
import { isEqual } from 'lodash';

export type LongTextFieldProps = {
  field: FieldRenderProps;
  projectField: ProjectStackField;
  projectStackDocuments: ProjectStackDocument[];
};

export const LongTextField = React.memo(
  ({ field, projectField, projectStackDocuments }: LongTextFieldProps) => {
    const { onChange, countTouched, ref, value, ...rest } = field;
    const [userValue, setUserValue] = useState('');
    useDebounce(() => onChange(userValue), 300, [userValue]);

    useEffect(() => {
      if (value == undefined) return;

      setUserValue(value);
    }, [value]);

    return (
      <Box>
        <Box>
          <FieldHeader
            projectField={projectField}
            projectStackDocuments={projectStackDocuments}
          />
        </Box>
        <Box>
          <TextField
            multiline
            size="small"
            rows={4}
            fullWidth
            {...rest}
            value={userValue}
            id={field.name}
            inputRef={ref}
            onChange={(e) => {
              setUserValue(e.target.value);
              if (countTouched) {
                countTouched(field.name);
              }
            }}
          />
        </Box>
      </Box>
    );
  },
  (prevProps, nextProps) =>
    isEqual(prevProps.field.value, nextProps.field.value) &&
    isEqual(prevProps.projectField, nextProps.projectField) &&
    isEqual(prevProps.projectStackDocuments, nextProps.projectStackDocuments),
);
