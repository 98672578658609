import { http, getPaginationUrlQuery } from '~/src/services/requests';

import qs from 'qs';

function questionnairesApiService() {
  return {
    fetchTags: async (orgFprint, { templateIds, templateSetIds }) => {
      const parse = true;
      const query = qs.stringify(
        { template_ids: templateIds, template_set_ids: templateSetIds },
        { arrayFormat: 'brackets' },
      );

      return http.innerSendAuthenticated(
        {
          relativePath: `/intake_form/org/${orgFprint}/tag/?${query}`,
          method: 'GET',
        },
        parse,
      );
    },
    createNewQuestionnaire: async (orgFprint, payload) => {
      const parse = true;
      const res = await http.innerSendAuthenticated(
        {
          relativePath: `/intake_form/org/${orgFprint}/intake_form/`,
          method: 'POST',
          body: payload,
        },
        parse,
      );
      return res;
    },
    fetch: async (orgFprint, { page, search, sortBy, order, limit }) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/intake_form/org/${orgFprint}/intake_form/${getPaginationUrlQuery(
            { page, search, sortBy, order, limit },
          )}`,
          method: 'GET',
        },
        parse,
      );
    },
    fetchQuestionnaires: async (orgFprint) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/intake_form/org/${orgFprint}/intake_form/`,
          method: 'GET',
        },
        parse,
      );
    },
    fetchQuestionnaire: async (orgFprint, { id }) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/intake_form/org/${orgFprint}/intake_form/${id}/`,
          method: 'GET',
        },
        parse,
      );
    },
    updateQuestionnaire: async (orgFprint, payload) => {
      const parse = true;
      const { id } = payload;
      const res = await http.innerSendAuthenticated(
        {
          relativePath: `/intake_form/org/${orgFprint}/intake_form/${id}/`,
          method: 'PATCH',
          body: payload,
        },
        parse,
      );

      return res;
    },
    deleteQuestionnaire: async (orgFprint, { id }) => {
      const parse = false;
      return http.innerSendAuthenticated(
        {
          relativePath: `/intake_form/org/${orgFprint}/intake_form/${id}/`,
          method: 'DELETE',
        },
        parse,
      );
    },
    publishQuestionnaire: async (orgFprint, { intakeId, matterId }) => {
      const parse = true;
      const query = matterId ? `?matter_id=${matterId}` : '';
      return http.innerSendAuthenticated(
        {
          relativePath: `/intake_form/org/${orgFprint}/intake_form/${intakeId}/publish/${query}`,
          method: 'POST',
        },
        parse,
      );
    },
    fetchMatterQuestionnaire: async (orgFprint, { matterId }) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/intake_form/org/${orgFprint}/matter/${matterId}/recipient/`,
          method: 'GET',
        },
        parse,
      );
    },
    fetchQuestionnaireResponse: async (orgFprint, { id: recipientId }) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/intake_form/org/${orgFprint}/recipient/${recipientId}/`,
          method: 'GET',
        },
        parse,
      );
    },
    updateQuestionnaireResponse: async (
      orgFprint,
      { id: recipientId, answers = [] },
    ) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/intake_form/org/${orgFprint}/recipient/${recipientId}/`,
          method: 'PUT',
          body: { answers },
        },
        parse,
      );
    },
  };
}

export default questionnairesApiService();
