import React from 'react';
import { css } from 'aphrodite';

import styles from './styles';

type FooterProps = {
  children: React.ReactNode;
};

export default ({ children }: FooterProps) => (
  <footer className={css(styles.footer)}>{children}</footer>
);
