import React, { FC } from 'react';
import { SIGNER_INDICATOR_COLORS } from '~/src/components/SignerSelection/constants';
import SignerDetails from '~/src/components/SignerSelection/SideMenu/SignerDetails';
import { Signers } from '~/src/models';

interface Props {
  signers: Signers;
  signatureCountByAssigneeEmail: { [email: string]: number };
  dateCountByAssigneeEmail: { [email: string]: number };
}

const SignerDetailsArray: FC<Props> = ({
  signers,
  signatureCountByAssigneeEmail,
  dateCountByAssigneeEmail,
}) => {
  const signerDetailsArray = [];
  if ('me' in signers) {
    signerDetailsArray.push(
      <SignerDetails
        key={signers.me.email}
        signer={signers.me}
        label="Me now"
        signatureCount={signatureCountByAssigneeEmail[signers.me.email] ?? 0}
        dateCount={dateCountByAssigneeEmail[signers.me.email] ?? 0}
        indicatorColor="#000"
      />,
    );
  }

  if ('others' in signers) {
    signers.others.forEach((signer, i) => {
      signerDetailsArray.push(
        <SignerDetails
          key={signer.email}
          signer={signer}
          label={signer.name}
          signatureCount={signatureCountByAssigneeEmail[signer.email] ?? 0}
          dateCount={dateCountByAssigneeEmail[signer.email] ?? 0}
          indicatorColor={
            SIGNER_INDICATOR_COLORS[i % SIGNER_INDICATOR_COLORS.length]!
          }
        />,
      );
    });
  }

  return <>{signerDetailsArray}</>;
};

export default SignerDetailsArray;
