import React, { FC, ElementType } from 'react';
import T from 'prop-types';
import cn from 'classnames';

export type Variant = 'heading' | 'subheading';
export type Transform =
  | 'uppercase'
  | 'lowercase'
  | 'capitalize'
  | 'normal-case';
interface Props {
  htmlElement?: ElementType;
  variant?: Variant;
  textTransform?: Transform;
  truncate?: boolean;
}

const Heading: FC<Props> = ({
  children,
  htmlElement: Element = 'h2',
  variant = 'heading',
  textTransform,
  truncate = false,
}) => {
  return (
    <Element
      className={cn(
        'text-gray-900',
        {
          'font-semibold text-heading': variant === 'heading',
          'font-medium text-subheading': variant === 'subheading',
          truncate,
        },
        textTransform ?? '',
      )}
    >
      {children}
    </Element>
  );
};

Heading.propTypes = {
  htmlElement: T.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] as const),
  variant: T.oneOf(['heading', 'subheading'] as const),
};

export default Heading;
