import { useQuery, UseQueryOptions } from 'react-query';
import { APIError } from '~/src/utils/error';
import { useCurrentOrgFprint } from '~/src/entities/user/hooks';
import { RelatedQuestionnaireSubmissionQuestion } from '../types';
import { keys } from '../keys';
import { composeQueryOpts, fetchAndParse } from '../../utils';
import { getRelatedQuestionnaireSubmissionUnmappedQuestionsUrl } from '../urls';

export const useRelatedQuestionnaireSubmissionUnmappedQuestions = (
  matterId: number,
  options: UseQueryOptions<
    RelatedQuestionnaireSubmissionQuestion[],
    APIError
  > = {},
) => {
  const orgFprint = useCurrentOrgFprint();
  return useQuery(
    composeQueryOpts(options, {
      queryKey: matterId
        ? keys.listByMatterUnmappedQuestions(matterId)
        : keys.lists(),
      queryFn: () =>
        fetchAndParse<RelatedQuestionnaireSubmissionQuestion[]>(
          getRelatedQuestionnaireSubmissionUnmappedQuestionsUrl(
            orgFprint,
            matterId,
          ),
        ),
    }),
  );
};
