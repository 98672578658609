import React, { FC } from 'react';
import { ReactComponent as CloseIcon } from '~/src/static/close-icon.svg';
import { ReactComponent as IconArrowRightSmall } from '~/src/static/icon-arrow-right-small.svg';
import { ReactComponent as IconDocs } from '~/src/static/icon-docs.svg';
import { ReactComponent as IconSigner } from '~/src/static/icon-signer.svg';
import { ReactComponent as IconPrepare } from '~/src/static/icon-prepare.svg';
import { ReactComponent as IconSend } from '~/src/static/icon-send.svg';
import { Heading } from '~/src/components/Typography';
import { Step } from '~/src/components/SignaturePackage/constants';
import cns from 'classnames';
import { WhoSigns } from '~/src/models';

export interface HeaderProps {
  signaturePackageTitle?: string;
  step: Step;
  whoSigns: WhoSigns;
  onExitClick: () => void;
}

/**
 * Renders the header at the top of the page that shows the user's progress
 * through the wizard.
 */
const Header: FC<HeaderProps> = ({
  step,
  signaturePackageTitle = '',
  whoSigns,
  onExitClick,
}) => {
  const stepperIconContainer =
    'bg-gray-100 rounded-full w-8 h-8 flex justify-center items-center shrink-0 grow-0';
  const stepperIconContainerActive =
    'bg-gray-900 rounded-full w-8 h-8 flex justify-center items-center shrink-0 grow-0';
  const stepperTextStyle =
    'hidden lg:block text-body text-gray-600 font-medium';

  return (
    <div className="flex bg-white border-b border-gray-200 p-4 items-center">
      <div className="w-1/3 flex items-center">
        <div
          className="hover:bg-gray-200 cursor-pointer rounded-full w-8 h-8 flex justify-center items-center mr-4"
          onClick={onExitClick}
        >
          <button className="flex">
            <CloseIcon />
          </button>
        </div>

        <Heading>{signaturePackageTitle}</Heading>
      </div>

      <div className="w-1/3 flex text-gray-600 justify-center gap-2 items-center">
        <div
          className={
            step === 'select-documents'
              ? stepperIconContainerActive
              : stepperIconContainer
          }
        >
          <IconDocs fill={step === 'select-documents' ? 'white' : '#6B7280'} />
        </div>
        <div
          className={cns(stepperTextStyle, {
            'text-gray-900': step === 'select-documents',
          })}
        >
          Documents
        </div>
        <div className="flex justify-center items-center">
          <IconArrowRightSmall />
        </div>

        <div
          className={
            step === 'choose-signers'
              ? stepperIconContainerActive
              : stepperIconContainer
          }
        >
          <IconSigner fill={step === 'choose-signers' ? 'white' : '#6B7280'} />
        </div>
        <div
          className={cns(stepperTextStyle, {
            'text-gray-900': step === 'choose-signers',
          })}
        >
          Signers
        </div>
        <div className="flex justify-center items-center">
          <IconArrowRightSmall />
        </div>

        <div
          className={
            step === 'add-signatures'
              ? stepperIconContainerActive
              : stepperIconContainer
          }
        >
          <IconPrepare fill={step === 'add-signatures' ? 'white' : '#6B7280'} />
        </div>
        <div
          className={cns(stepperTextStyle, {
            'text-gray-900': step === 'add-signatures',
          })}
        >
          Prepare
        </div>

        {whoSigns !== WhoSigns.JustMe && (
          <>
            <div className="flex justify-center items-center">
              <IconArrowRightSmall />
            </div>
            <div
              className={
                step === 'send-email'
                  ? stepperIconContainerActive
                  : stepperIconContainer
              }
            >
              <IconSend fill={step === 'send-email' ? 'white' : '#6B7280'} />
            </div>
            <div
              className={cns(stepperTextStyle, {
                'text-gray-900': step === 'send-email',
              })}
            >
              Send
            </div>
          </>
        )}
      </div>

      <div className="w-1/3"></div>
    </div>
  );
};

export default Header;
