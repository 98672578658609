import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
  draftingEditContainer: {
    width: '100%',
    height: '100%',
  },
  draftingLoadingOverlay: {
    height: '100%',
    width: '100% !important',
    marginLeft: 'auto !important',
    background: 'rgba(245,250,255,1)',
    marginRight: 'inherit !important',
    position: 'absolute !important',
  },
});
