import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

/* Components */
import { startCase } from 'lodash';
import Button from '~/src/components/Button';

import {
  TextInput,
  MultipleChoiceInput,
  StaticLink,
  MultipleChoiceOptions,
  SelectOptionInput,
} from '~/src/components/Inputs';

/* Styles */
import { css } from 'aphrodite';
import styles from './styles';

/* Constants */
let CURRENT_TIMEOUT = null;

// const ANIMATION_DURATION = 400;

export const QUESTION_TYPE_SHORT_TEXT = 'SHORT_TEXT';
export const QUESTION_TYPE_LONG_TEXT = 'LONG_TEXT';
export const QUESTION_TYPE_DATE = 'DATE';
export const QUESTION_TYPE_NUMBER = 'NUMBER';
export const QUESTION_TYPE_STATIC_TEXT = 'STATIC_TEXT';
export const QUESTION_TYPE_MULTIPLE_CHOICE = 'MULTIPLE_CHOICE';
export const QUESTION_TYPE_MULTI_SELECT = 'MULTI_SELECT';
export const QUESTION_TYPE_FILE_UPLOAD = 'FILE_UPLOAD';

const QUESTION_TYPE_LOOKUP = {
  [QUESTION_TYPE_SHORT_TEXT]: 'text',
  [QUESTION_TYPE_LONG_TEXT]: 'textarea',
  [QUESTION_TYPE_DATE]: 'date',
  [QUESTION_TYPE_NUMBER]: 'incrementer',
  [QUESTION_TYPE_STATIC_TEXT]: 'staticText',
  [QUESTION_TYPE_MULTIPLE_CHOICE]: 'multipleChoice',
  [QUESTION_TYPE_MULTI_SELECT]: 'multiSelect',
  [QUESTION_TYPE_FILE_UPLOAD]: 'fileUpload',
};

const FILE_TYPES_MICROSOFT = [
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.ppt',
  '.pptx',
  '.xps',
];
const FILE_TYPES_ADOBE = [
  '.pdf',
  '.dxf',
  '.ai',
  '.psd',
  '.eps',
  '.ps',
  '.svg',
  '.ttf',
];
const FILE_TYPES_ARCHIVE = ['.zip', '.rar', '.tar', '.gzip'];
const FILE_TYPES_AUDIO = ['.mp3', '.mpeg', '.wav', '.ogg'];
const FILE_TYPES_IMAGE = ['.jpeg', '.jpg', '.png', '.gif', '.bmp', '.tif'];
const FILE_TYPES_VIDEO = [
  '.webm',
  '.mpeg4',
  '.3gpp',
  '.mov',
  '.mpegps',
  '.wmv',
  '.flv',
];
const FILE_TYPES_DOCUMENTS = [
  ...FILE_TYPES_MICROSOFT,
  ...FILE_TYPES_ADOBE,
  ...FILE_TYPES_ARCHIVE,
  '.txt',
];

const UPLOAD_TYPES_OPTIONS = [
  { label: 'Document Files', value: FILE_TYPES_DOCUMENTS },
  { label: 'Image Files', value: FILE_TYPES_IMAGE },
  { label: 'Video Files', value: FILE_TYPES_VIDEO },
  { label: 'Audio Files', value: FILE_TYPES_AUDIO },
];

const DEFAULTS = {
  label: '',
  type: QUESTION_TYPE_SHORT_TEXT,
  required: 'false',
  options: [{ label: '', value: '' }],
  tag: 'Choose Field',
  uploadLimit: '1',
  uploadTypes: [UPLOAD_TYPES_OPTIONS[0].value],
};

const QuestionForm = (props) => {
  const {
    onMapQuestion,
    onSubmit,
    onCancel,
    values,
    focused,
    focusInputDelay,
    clearFormDelay,
  } = props;

  const inputRef = useRef();

  // States
  const [formValid, setFormValid] = useState(!!values.id);
  const [label, setLabel] = useState(values.label || DEFAULTS.label);
  const [labelError, setLabelError] = useState('');
  const [type, setType] = useState(values.type || DEFAULTS.type);
  const [required, setRequired] = useState(
    values.required || DEFAULTS.required,
  );
  const [options, setOptions] = useState(values.options || DEFAULTS.options);
  const [tag, setTag] = useState(
    values.tag ? startCase(values.tag.split('.').join(' ')) : DEFAULTS.tag,
  );
  const [uploadLimit, setUploadLimit] = useState(
    values.uploadLimit || DEFAULTS.uploadLimit,
  );
  const [uploadTypes, setUploadTypes] = useState(
    values.uploadTypes || DEFAULTS.uploadTypes,
  );

  useEffect(() => {
    if (values.label) {
      setLabel(values.label);
    }

    if (values.type) {
      setType(values.type);
    }

    if (values.required) {
      setRequired(`${values.required}`);
    }

    if (values.options) {
      setOptions(values.options);
    }

    if (values.tag) {
      setTag(startCase(values.tag.split('.').join(' ')));
    }

    if (values.uploadLimit) {
      setUploadLimit(`${values.uploadLimit}`);
    }

    if (values.uploadTypes) {
      setUploadTypes(values.uploadTypes);
    }
  }, [values]);

  useEffect(() => {
    if (label && label.length > 1) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [label, type, required, options, tag, uploadLimit, uploadTypes]);

  useEffect(() => {
    if (focused && inputRef.current) {
      CURRENT_TIMEOUT = setTimeout(() => {
        if (inputRef.current && inputRef.current.focus) {
          inputRef.current.focus();
        }
      }, focusInputDelay + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focused, inputRef]);

  // Cleanup
  useEffect(() => {
    return () => {
      if (CURRENT_TIMEOUT) {
        clearTimeout(CURRENT_TIMEOUT);
      }
    };
  }, []);

  const handleLabelChange = setLabel;
  const handleTypeChange = setType;
  const handleRequiredChange = setRequired;
  const handleUploadLimitChange = setUploadLimit;
  const handleOptionsChange = setOptions;
  const handleUploadTypesChange = (selectedOptions) => {
    const selectOptionValues = selectedOptions.map((option) => option.value);
    setUploadTypes(selectOptionValues);
  };

  const resetForm = () => {
    setFormValid(!!values.id);
    setLabel(values.label || DEFAULTS.label);
    setLabelError('');
    setType(values.type || DEFAULTS.type);
    setRequired(values.required || DEFAULTS.required);
    setOptions(values.options || DEFAULTS.options);
    setTag(
      values.tag ? startCase(values.tag.split('.').join(' ')) : DEFAULTS.tag,
    );
    setUploadLimit(values.uploadLimit || DEFAULTS.uploadLimit);
    setUploadTypes(values.uploadTypes || DEFAULTS.uploadTypes);
  };

  const handleCreateQuestionSubmit = () => {
    setLabelError('');

    if (!formValid) {
      setLabelError('Label is required');
    } else {
      const question = {
        label,
        type,
        required: required === 'true',
        options,
        tag,
      };

      if (type === QUESTION_TYPE_FILE_UPLOAD) {
        question.uploadLimit = uploadLimit;
        question.uploadTypes = uploadTypes;
      }

      if (values.id) {
        question.id = values.id;
      } else {
        CURRENT_TIMEOUT = setTimeout(resetForm, clearFormDelay);
      }

      onSubmit(question);
    }
  };

  const handleCreateQuestionCancel = () => {
    onCancel();
  };

  const isMultipleChoice = type === QUESTION_TYPE_MULTIPLE_CHOICE;
  const isFileUpload = type === QUESTION_TYPE_FILE_UPLOAD;

  const canMapToField = !isFileUpload && values.id;

  return (
    <div>
      <TextInput
        required
        error={labelError}
        ref={inputRef}
        label="Label"
        placeholder="The questions name"
        value={label}
        onChange={handleLabelChange}
      />
      <MultipleChoiceInput
        label="Type"
        value={type}
        options={[
          { label: 'Short Text', value: QUESTION_TYPE_SHORT_TEXT },
          { label: 'Long Text', value: QUESTION_TYPE_LONG_TEXT },
          { label: 'Date', value: QUESTION_TYPE_DATE },
          { label: 'Count', value: QUESTION_TYPE_NUMBER },
          { label: 'Multiple Choice', value: QUESTION_TYPE_MULTIPLE_CHOICE },
          { label: 'Multi-Select', value: QUESTION_TYPE_MULTI_SELECT },
          { label: 'File Upload', value: QUESTION_TYPE_FILE_UPLOAD },
        ]}
        onChange={handleTypeChange}
      />
      {isMultipleChoice && (
        <MultipleChoiceOptions
          label={'Multiple Choice Options'}
          optionLabel="Option label"
          optionValueLabel="Option value"
          value={options}
          onChange={handleOptionsChange}
        />
      )}
      {isFileUpload && (
        <MultipleChoiceInput
          label="File upload limit"
          options={[
            { label: 'Single', value: '1' },
            { label: 'Multiple', value: 'Infinity' },
          ]}
          onChange={handleUploadLimitChange}
          value={uploadLimit}
        />
      )}
      {isFileUpload && (
        <SelectOptionInput
          multiSelect
          label="File upload types"
          placeholder="The types of files allowed"
          value={uploadTypes}
          isSearchable={false}
          options={UPLOAD_TYPES_OPTIONS}
          onChange={handleUploadTypesChange}
        />
      )}
      <MultipleChoiceInput
        label="Validation"
        value={required}
        options={[
          {
            label: 'Required',
            value: 'true',
          },
          {
            label: 'Optional',
            value: 'false',
          },
        ]}
        onChange={handleRequiredChange}
      />
      {canMapToField && (
        <StaticLink
          label="Maps to field"
          onClick={() => {
            onMapQuestion(values);
          }}
          value={tag}
        />
      )}

      {/* <StaticText onChange={} /> */}
      {/* <MultipleChoiceOptions /> */}
      <div className={css(styles.submitQuestionActions)}>
        <Button
          onClick={handleCreateQuestionCancel}
          cssStyle={styles.submitQuestionButton}
        >
          Cancel
        </Button>
        <Button
          primary
          disabled={!formValid}
          onClick={handleCreateQuestionSubmit}
          cssStyle={styles.submitQuestionButton}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

QuestionForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  values: PropTypes.object,
  focused: PropTypes.bool,
  focusInputDelay: PropTypes.number,
  clearFormDelay: PropTypes.number,
};

QuestionForm.defaultProps = {
  onSubmit: () => {},
  onCancel: () => {},
  values: {},
  focused: false,
  focusInputDelay: 0,
  clearFormDelay: 0,
};

export { QuestionForm as default, QUESTION_TYPE_LOOKUP };
