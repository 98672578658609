import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

export default StyleSheet.create({
  container: {
    margin: '0 auto',
    marginTop: `${theme.unit * 4}px`,
    width: `${theme.unit * 50}px`,
  },
  title: {
    fontSize: theme.fonts.size.large,
    fontWeight: '500',
    textAlign: 'center',
  },
  description: {
    marginTop: `${theme.unit * 2}px`,
    lineHeight: '1.5em',
    fontSize: theme.fonts.size.regular,
    textAlign: 'center',
  },
  children: {
    marginTop: `${theme.unit * 6}px`,
  },
});
