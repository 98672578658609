import React from 'react';
import { NavLink } from 'react-router-dom';

const Breadcrumb = ({ data: objArray }) => {
  const items = objArray.map((obj, idx) => {
    let linkOrText;
    if (obj.url) {
      linkOrText = <NavLink to={`${obj.url}`}>{obj.label}</NavLink>;
    } else if (obj.onClick) {
      linkOrText = <button onClick={obj.onClick}>{obj.label}</button>;
    } else {
      linkOrText = <div>{obj.label}</div>;
    }

    return (
      <li className="inline-flex items-center" key={obj.label}>
        {linkOrText}

        {idx !== objArray.length - 1 && (
          <svg
            className="h-5 w-auto text-gray-400"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
        )}
      </li>
    );
  });

  return (
    <div className="my-3">
      <ul className="flex text-gray-500 text-sm lg:text-base">{items}</ul>
    </div>
  );
};

export default Breadcrumb;
