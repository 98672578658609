import { InputType } from './inputType';

export type Email = string;

export type Signer = {
  email: Email;
  name: string;
  id: number;
};

export interface MeAndOthers {
  me: Signer;
  others: Signer[];
}

export interface MeOnly {
  me: Signer;
}

export interface OthersOnly {
  others: Signer[];
}

export type Signers = MeAndOthers | MeOnly | OthersOnly;

export interface Coordinates {
  x: number;
  y: number;
}

export interface Assignee {
  // There will be no ID until the assignee is persisted in the DB
  id?: number;
  type: 'assignee';
  inputType: InputType.SignatureBlock | InputType.SignatureDate;
  signaturePageId: number;
  pageNumber: number;
  left: number;
  top: number;
  height: number;
  width: number;
  recipient: {
    name: string;
    email: string;
    id: number;
  };
}

export interface Placeholder {
  id: number;
  type: 'placeholder';
  inputType: InputType.SignatureBlock | InputType.SignatureDate;
  signaturePageId: number;
  pageNumber: number;
  left: number;
  top: number;
  height: number;
  width: number;
}

export interface SignedWithImage {
  // TODO: Still true?       There will be no ID until the assignee is persisted in the DB
  id?: number;
  type: 'signed-with-image';
  inputType: InputType.SignatureBlock;
  signaturePageId: number;
  pageNumber: number;
  left: number;
  top: number;
  imageHeight: number;
  imageWidth: number;
  recipient: {
    name: string;
    email: string;
    id: number;
    xfdfSignature: string;
  };
}

export type SignatureBox = Assignee | SignedWithImage | Placeholder;

export interface Dated {
  // There will be no ID until the assignee is persisted in the DB
  id?: number;
  type: 'dated';
  inputType: InputType.Textbox;
  value: string;
  signaturePageId: number;
  pageNumber: number;
  left: number;
  top: number;
  height: number;
  width: number;
  recipient: {
    name: string;
    email: string;
    id: number;
  };
}

export type DateBox = Assignee | Dated | Placeholder;

export interface PackageId {
  id: number;
  type: 'packageId';
  inputType: InputType.TextArea;
  left: number;
  top: number;
  signaturePageId: number;
  value: string;
  height: number;
  width: number;
  pageNumber: number;
}

export interface PageIdsByPageNumber {
  [pageNumber: string]: string;
}

export interface Pdf {
  id: string;
  title: string;
  bodyData: URL | Blob;
  signatures: SignatureBox[];
  dates: DateBox[];
  packageIds: PackageId[];
  pageIdsByPageNumber: PageIdsByPageNumber;
}

export enum WhoSigns {
  JustMe = 1,
  MeAndOthers = 2,
  Others = 3,
}

interface AssignRequestBody {
  id?: number;
  left: number;
  top: number;
  height: number;
  width: number;
  input_type: InputType.SignatureDate | InputType.SignatureBlock;
  signature_page_id: number;
  recipient: {
    name: string;
    email: string;
    id: number;
  };
}

interface SignedWithImageRequestBody {
  id?: number;
  left: number;
  top: number;
  // evidently, both image_height and image_width are needed for the request?
  image_height: number;
  image_width: number;
  width: number;
  height: number;
  input_type: InputType.SignatureDate | InputType.SignatureBlock;
  signature_page_id: number;
  recipient: {
    name: string;
    email: string;
    id: number;
  };
}

export type AssignSignatureOrDateRequestBody =
  | AssignRequestBody
  | SignedWithImageRequestBody
  | PackageId;

export interface AssignDateNowRequestBody {
  id?: number;
  value: string;
  left: number;
  top: number;
  height: number;
  width: number;
  input_type: InputType.Textbox;
  signature_page_id: number;
  recipient: {
    name: string;
    email: string;
    id: number;
  };
}

export type UpdateElementPayload = Assignee | SignedWithImage | PackageId;
