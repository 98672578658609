import React from 'react';
import { Chip, ChipProps, CircularProgress, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { User, X } from 'react-feather';

const StyledChip = styled(Chip)(({ theme }) => ({
  '& .MuiChip-icon svg': {
    stroke: theme.palette.blue[600],
  },
  '& .MuiChip-deleteIcon': {
    stroke: theme.palette.blue[600],
    marginTop: 2,
  },
  '& .MuiChip-label': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  '&.MuiChip-root': {
    color: theme.palette.blue[600],
    background: theme.palette.blue[50],
    padding: theme.spacing(1),
  },
}));

type UserChipProps = {
  name?: string;
  loading?: boolean;
} & ChipProps;

export const ContactChip = ({ name, loading, ...rest }: UserChipProps) => {
  return (
    <StyledChip
      icon={<User size={14} />}
      deleteIcon={<X size={16} />}
      label={
        loading ? (
          <CircularProgress size={15} />
        ) : (
          <Typography sx={{ color: 'inherit' }} variant={'smallLabel'}>
            {name}
          </Typography>
        )
      }
      {...rest}
    />
  );
};
