import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Box,
  Alert,
} from '@mui/material';

import { Close } from '@mui/icons-material';
import { CancelSubscriptionPrompt } from './CancelSubscriptionPrompt';
import { CancelSubscriptionReason } from './CancelSubscriptionReason';
import {
  CancellationReason,
  CancellationReasons,
  CANCELLATION_REASONS,
  useCancelSubscription,
} from '~/src/entities/subscription';

export type UpdateSeatsModalProps = {
  onCanceled: () => void;
  endDate: string;
} & Omit<DialogProps, 'onChange'>;

const INITIAL_CANCELLATION_STATE = {
  other: '',
  ...CANCELLATION_REASONS.reduce((acc, { name }) => {
    acc[name] = false;
    return acc;
  }, {} as Record<CancellationReason, boolean>),
};

export const CancelSubscriptionModal = ({
  onCanceled,
  open,
  endDate,
  ...rest
}: UpdateSeatsModalProps) => {
  const { mutate, isError, isLoading, reset } = useCancelSubscription({
    onSuccess: onCanceled,
  });
  const [confirming, setConfirming] = useState(false);
  const [checked, setChecked] = useState(false);
  const [reasons, setReasons] = useState<CancellationReasons>(
    INITIAL_CANCELLATION_STATE,
  );

  const hasChosenReason = Object.values(reasons).some(Boolean);
  const isButtonEnabled = confirming ? hasChosenReason : checked;

  const isErrorCancelling = isError && !isLoading;

  return (
    <Dialog
      fullWidth
      maxWidth={'xs'}
      open={open}
      {...rest}
      TransitionProps={{
        onEnter: () => {
          reset();
          setConfirming(false);
          setChecked(false);
          setReasons(INITIAL_CANCELLATION_STATE);
        },
      }}
    >
      <DialogTitle>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box>Are you sure you want to cancel?</Box>
          <IconButton onClick={(e) => rest.onClose?.(e, 'backdropClick')}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers={true}>
        {isErrorCancelling ? (
          <Box pb={2}>
            <Alert variant="filled" severity="error">
              An error occurred while cancelling your subscription
            </Alert>
          </Box>
        ) : null}
        {confirming ? (
          <CancelSubscriptionReason value={reasons} onChange={setReasons} />
        ) : (
          <CancelSubscriptionPrompt
            endDate={endDate}
            checked={checked}
            onChange={setChecked}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={(e) => rest.onClose?.(e, 'escapeKeyDown')}
        >
          Go back
        </Button>
        <Button
          data-testid="cancel-subscription-modal-confirm"
          variant="contained"
          onClick={() =>
            confirming ? mutate({ reasons }) : setConfirming(true)
          }
          disabled={!isButtonEnabled || isLoading}
        >
          {isLoading ? 'Cancelling...' : 'Continue with cancellation'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
