import React, { useMemo } from 'react';
import { Project, ProjectStackField } from '~/src/entities/project';

const ProjectContext = React.createContext<Project | undefined>(undefined);

type ProjectProviderProps = React.PropsWithChildren<{
  project: Project;
}>;

export const useProjectContext = () =>
  React.useContext(ProjectContext) as Project;

export const useFieldDescriptors = (fields: string[]) => {
  const project = useProjectContext();
  return useMemo(
    () =>
      fields.map(
        (fieldId) =>
          project.stack_data.fields.find(
            ({ fid }) => fid === fieldId,
          ) as ProjectStackField,
      ),
    [fields, project.stack_data.fields],
  );
};

export const ProjectProvider = ({
  project,
  children,
}: ProjectProviderProps) => (
  <ProjectContext.Provider value={project}>{children}</ProjectContext.Provider>
);
