import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

const styles = StyleSheet.create({
  addFile: {
    position: 'relative',
    width: '80px',
    height: '110px',
    background: theme.colors.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.borderRadius,
    border: theme.border,
    borderColor: theme.colors.bluebird,
    borderStyle: 'dashed',
    ':hover': {
      cursor: 'pointer',
    },
  },
  addFileDragging: {
    opacity: 0.7,
  },
});

export default styles;
