/* Libs */
import React, { useEffect, useState } from 'react';

/* Hooks */

/* Containers */
import PageContainer from '~/src/containers/pages/Page';
import CreateNewQuestionnaireWalkthrough, {
  QUESTIONNAIRE_CREATION_MAP_TO_DOCUMENTS,
} from '~/src/containers/questionnaires/CreateNewQuestionnaireWalkthrough';

/* Constants */
import { LAYOUT_FOOTER_TYPES, useLayoutContext } from '~/src/contexts/Layout';
import { history } from '~/src/utils/history';

const CreateNewQuestionnaireOptionsPageContainer = () => {
  const { showFooter, hideFooter } = useLayoutContext();

  const [createOptionType, setCreateOptionType] = useState();

  const handleNextActionTypeChange = (type) => {
    setCreateOptionType(type);
  };

  const handleNext = () => {
    // Redirect the user to choose a template / template set
    if (createOptionType === QUESTIONNAIRE_CREATION_MAP_TO_DOCUMENTS) {
      history.push('/questionnaire/new/map');
      return;
    }

    history.push('/questionnaire/new/create');
  };

  const handleCancel = () => {
    history.goBack();
  };

  useEffect(() => {
    showFooter(LAYOUT_FOOTER_TYPES.action, {
      actionLabel: 'Next',
      cancelLabel: 'Cancel',
      // actionDisabled: creatingNewQuestionnaire,
      // cancelDisabled: creatingNewQuestionnaire,
      onAction: handleNext,
      onCancel: handleCancel,
    });

    return hideFooter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOptionType]);

  return (
    <PageContainer loading={false}>
      <CreateNewQuestionnaireWalkthrough
        onChange={handleNextActionTypeChange}
      />
    </PageContainer>
  );
};

export default CreateNewQuestionnaireOptionsPageContainer;
