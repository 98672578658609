import React from 'react';
import { Button, Typography } from '@mui/material';
import { PlanCard } from './PlanCard';
import {
  BillingCycleType,
  COURT_FORMS_MONTHLY_PRICE,
  COURT_FORMS_YEARLY_PRICE,
  getCourtFormProductPrice,
  getPlanPrice,
} from '~/src/entities/subscription';
import { useCourtFormsModal } from './CourtFormsModal';

type CourtFormsPlanCardProps = {
  excludeList?: string[]; // court forms to exclude
  value: string[];
  onChange: (value: string[]) => void;
  billingCycle: BillingCycleType;
};

export const CourtFormsPlanCard = ({
  excludeList,
  value,
  onChange,
  billingCycle,
}: CourtFormsPlanCardProps) => {
  const [toggle, courtFormsModal] = useCourtFormsModal({
    excludeList: excludeList,
    defaultValues: value,
    onChange: (...args) => {
      toggle();
      onChange(...args);
    },
    renderExtra: () => (
      <Typography variant={'body1'}>
        {getPlanPrice(getCourtFormProductPrice(billingCycle), billingCycle)}
      </Typography>
    ),
  });

  let planPriceDesc;
  if (billingCycle === 'annual') {
    planPriceDesc = getPlanPrice(COURT_FORMS_YEARLY_PRICE, 'annual');
  } else {
    planPriceDesc = getPlanPrice(COURT_FORMS_MONTHLY_PRICE, 'monthly');
  }

  return (
    <>
      {courtFormsModal}
      <PlanCard
        label={'Fillable court forms'}
        description={<Typography variant={'body1'}>{planPriceDesc}</Typography>}
        action={
          <Button
            data-testid="court-forms-plan-button"
            variant={'contained'}
            onClick={toggle}
          >
            {value.length ? 'Edit' : 'Add'}
          </Button>
        }
      />
    </>
  );
};
