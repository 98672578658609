import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Box,
  Typography,
} from '@mui/material';

import {
  BillingCycleType,
  getPlanDifference,
  getProductID,
  RecurringCharges,
  useUpcomingInvoiceV2,
  useUpdateSubscriptionV2,
  NYSBA_SURROGATE_ID,
  WORD_PRODUCT_ID,
} from '~/src/entities/subscription';
import { PlanPrice } from './PlanPrice';
import { useEstimatePlansChangeV2 } from '~/src/entities/subscription/hooks/useEstimatePlanChangeV2';

type Plan = {
  seats: number;
  contentPackage: boolean;
  word: boolean;
  courtForms: string[];
  billingCycle: BillingCycleType;
};

export type ConfirmPlanChangeModalProps = {
  existing: Plan;
  next?: Partial<Plan>;
  onConfirm: () => void;
} & Omit<DialogProps, 'onChange'>;

export const ConfirmPlanChangeModal = ({
  existing,
  next,
  onConfirm,
  open,
  ...rest
}: ConfirmPlanChangeModalProps) => {
  const update = useUpdateSubscriptionV2({
    onSuccess: onConfirm,
  });
  const currentSubscription = useUpcomingInvoiceV2(
    { seat: existing.seats, duration: existing.billingCycle },
    { enabled: open },
  );
  const nextPlan = { ...existing, ...next };

  const plans = nextPlan.courtForms.map((id) =>
    getProductID(nextPlan.billingCycle, id),
  );
  if (nextPlan.contentPackage)
    plans.push(getProductID(nextPlan.billingCycle, NYSBA_SURROGATE_ID));
  if (nextPlan.word)
    plans.push(getProductID(nextPlan.billingCycle, WORD_PRODUCT_ID));

  const upcoming = useEstimatePlansChangeV2(
    { plans, seat: nextPlan.seats, duration: nextPlan.billingCycle },
    { enabled: open },
  );

  return (
    <Dialog fullWidth maxWidth={'xs'} open={open} {...rest}>
      <DialogTitle>
        <Typography variant={'subheading'}>Confirm new plan</Typography>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Box pb={4}>
          <RecurringCharges {...nextPlan} />
        </Box>
        <Box pb={4}>
          <Box pb={2}>
            <Typography variant={'subheading'}>Total</Typography>
          </Box>

          <PlanPrice
            {...currentSubscription}
            label={'Current plan'}
            price={currentSubscription.data?.total}
            billingCycle={existing.billingCycle}
          />
          <PlanPrice
            {...upcoming}
            label={'New plan'}
            price={upcoming.data?.total}
            billingCycle={nextPlan.billingCycle}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={(e) => rest.onClose?.(e, 'escapeKeyDown')}
        >
          Cancel
        </Button>
        <Button
          disabled={update.isLoading || !upcoming.data}
          onClick={() => update.mutate(getPlanDifference(existing, nextPlan))}
          data-testid="confirm-plan-change-confirm"
          variant="contained"
        >
          {update.isLoading ? 'Saving...' : 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
