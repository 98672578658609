import { useObserver } from 'mobx-react';
import useMst from '~/src/hooks/useMst';
import useResourcePagination from '~/src/hooks/useResourcePagination';

const useSignaturesPagination = () => {
  const { signatures } = useMst((store) => ({
    signatures: store.signatures,
  }));

  const {
    loading,
    next,
    done,
    empty,
    page,
    search,
    setSearch,
    setSortBy,
    setOrder,
  } = useResourcePagination({
    sortBy: 'id',
    fetch: signatures.fetch,
    limit: 10,
    order: 'desc',
  });

  const listData = useObserver(() => {
    return signatures.list.flat();
  });

  return {
    next,
    page,
    search,
    data: listData,
    empty,
    loading,
    done,
    setSearch,
    setSortBy,
    setOrder,
  };
};

export default useSignaturesPagination;
