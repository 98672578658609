/* Libraries */
import React from 'react';
import { css } from 'aphrodite';
import { Plus, Minus } from 'react-feather';

/* Components */
import theme from '~/src/theme';
import Label from './Label';

/* Styles */
import styles from './styles';

const Incrementer = (props) => {
  const {
    label,
    hint,
    count,
    required,
    error,
    onBlur,
    onFocus,
    onChange,
    maxValue,
    minValue,
  } = props;

  const value = props.value || 0;
  // TODO: check for invalid default values
  // if (value && value < minValue) {
  //   onChange(minValue);
  //   currentValue = minValue;
  // }

  // if (value && value > maxValue) {
  //   onChange(maxValue);
  //   currentValue = maxValue;
  // }

  const onIncrement = () => {
    const nextValue = value + 1;
    if (typeof maxValue === 'undefined') {
      onChange(nextValue);
    } else if (nextValue <= maxValue) {
      onChange(nextValue);
    }
  };

  const onDecrement = () => {
    const nextValue = value - 1;

    if (typeof minValue === 'undefined') {
      onChange(nextValue);
    } else if (nextValue >= minValue) {
      onChange(nextValue);
    }
  };

  const iconColor = theme.colors.chrome;

  return (
    <Label
      label={label}
      hint={hint}
      count={count}
      error={error}
      onFocus={onFocus}
      onBlur={onBlur}
      required={required}
      cssStyle={styles.incrementerContainer}
    >
      <div
        onClick={onDecrement}
        className={css(
          styles.incrementerButton,
          styles.incrementerSubtractButton,
        )}
      >
        <Minus color={iconColor} size={12} />
      </div>
      <div className={css(styles.incrementerValue)}>{value}</div>
      <div
        onClick={onIncrement}
        className={css(styles.incrementerButton, styles.incrementerAddButton)}
      >
        <Plus color={iconColor} size={12} />
      </div>
    </Label>
  );
};

Incrementer.defaultProps = {
  minValue: 0,
  maxValue: 100,
};

export default Incrementer;
