import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

export default StyleSheet.create({
  title: {
    display: 'block',
    marginBottom: theme.unit,
    fontSize: theme.fonts.size.regular,
    color: theme.colors.charcoal,
    fontWeight: 500,
    ':hover': {
      cursor: 'pointer',
    },
  },
  description: {
    display: 'block',
    fontSize: theme.fonts.size.small,
    color: theme.colors.chrome,
    fontWeight: 500,
    lineHeight: '1.5em',
  },
  intakeDetailsContainer: {
    display: 'inline-block',
    margin: theme.unit * 2,
    position: 'relative',
    paddingRight: 75,
    ':hover': {
      cursor: 'pointer',
    },
  },
  intakeDetailsContainerIcon: {
    position: 'absolute',
    opacity: 0,
    right: 0,
    top: '50%',
    marginTop: -17,
    transition: 'opacity 150ms linear',
  },
  intakeDetailsContainerIconHover: {
    opacity: 1,
    borderColor: theme.colors.bluebird,
  },
  // intakeFormPreviewButton: {
  //   background: buttonPreviewColor,
  //   borderColor: buttonPreviewColor,
  //   ":hover": {
  //     background: buttonPreviewColor,
  //     borderColor: buttonPreviewColor,
  //   },
  //   ":focus": {
  //     background: buttonPreviewColor,
  //     borderColor: buttonPreviewColor,
  //   },
  //   ":active": {
  //     background: buttonPreviewColor,
  //     borderColor: buttonPreviewColor,
  //   }
  // },
  intakePreviewTitleIcon: {
    marginRight: theme.unit * 2,
  },
  intakePreviewTitleLayout: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    color: theme.colors.charcoal,
    fontWeight: 'bold',
  },
  intakePreviewTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  intakePreviewFooterLayout: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 16,
    minHeight: 34,
  },
  intakePreviewLink: {
    color: theme.colors.eggplant,
    display: 'flex',
    alignItems: 'center',
  },
  intakePreviewTitle: {
    fontSize: theme.fonts.size.regular,
    color: theme.colors.charcoal,
    fontWeight: 500,
    marginBottom: theme.unit * 2,
  },
  intakePreviewDescription: {
    fontSize: theme.fonts.size.small,
    color: theme.colors.chrome,
    fontWeight: 500,
    marginBottom: theme.unit * 4,
  },

  createQusetionTitle: {
    fontSize: theme.fonts.size.regular,
    color: theme.colors.charcoal,
    fontWeight: 500,
    marginBottom: theme.unit * 4,
  },

  sectionLabel: {
    fontSize: theme.fonts.size.regular,
    color: theme.colors.charcoal,
    fontWeight: 500,
    marginBottom: theme.unit * 2,
  },

  intakeFormTitleCard: {
    marginBottom: theme.unit * 4,
  },
  questionsContainer: {
    marginTop: theme.unit * 2,
    marginBottom: -(theme.unit * 2),
  },
  questionsLabel: {
    marginTop: theme.unit * 2,
    marginBottom: theme.unit * 2,
  },
  submitQuestionButton: {
    marginTop: theme.unit * 4,
    marginRight: theme.unit * 2,
  },
  submitQuestionActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  addQuestionButton: {
    marginTop: 0,
    marginBottom: theme.unit * 3,
  },
  addQuestionFormCard: {
    marginBottom: theme.unit * 4,
  },
  questionsList: {
    marginBottom: theme.unit * 4,
  },
  questionsListItem: {
    listStyleType: 'none',
    marginBottom: theme.unit,
    ':hover': {
      cursor: 'grab',
    },
  },
  questionCard: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    padding: theme.unit * 1.5,
  },
  questionCardActions: {
    marginLeft: 'auto',
    display: 'flex',
  },
  questionCardDraggableIcon: {
    marginRight: theme.unit,
  },
  questionCardMapText: {
    fontSize: 11,
    color: theme.colors.chrome,
    marginTop: theme.unit,
    fontWeight: 300,
    display: 'block',
  },
  questionCardMapTextIcon: {
    marginRight: theme.unit,
  },

  intakeFormActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  intakeFormPreviewButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.unit * 2,
  },
  intakeFormActionsButton: {
    marginRight: theme.unit * 2,
  },
  intakeFormActionsAutoSaveText: {
    display: 'block',
    color: theme.colors.bluebird,
  },
  iconHover: {
    height: '5px',
    width: '45px',
    background: theme.colors.smoke,
    display: 'block',
    margin: '5px auto',
    borderRadius: '50%',
    opacity: '0.75',
  },
  emptyTitle: {
    display: 'block',
    marginTop: theme.unit * 2,
    textAlign: 'center',
    fontSize: theme.fonts.size.regular,
    color: theme.colors.charcoal,
  },
  emptySubtitle: {
    marginTop: theme.unit,
    textAlign: 'center',
    fontSize: theme.fonts.size.small,
    color: theme.colors.chrome,
  },
  emptyIntakeFormPreview: {
    textAlign: 'center',
    margin: '100px auto',
  },
});
