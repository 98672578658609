import React, { useCallback, useEffect } from 'react';
import analyticsService from '../services/analytics';
import { useStore } from '../hooks/useMst';
import { canOrgUseFeatureAccess, useCurrentOrg } from '../entities/user';

type AnalyticsProviderProps = {
  children: React.ReactNode;
};

type AnalyticsContextType = {
  track: (event: string, data?: Record<string, unknown>) => void;
};

const AnalyticsContext = React.createContext<AnalyticsContextType | undefined>(
  undefined,
);

export const useAnalytics = () =>
  React.useContext(AnalyticsContext) as AnalyticsContextType;

export const AnalyticsProvider = ({ children }: AnalyticsProviderProps) => {
  const store = useStore();
  const { org } = useCurrentOrg();

  useEffect(() => {
    const { user } = store;

    const email = user.currentOrganization?.email;
    const admin = user.currentOrganization?.admin;
    const organizationName = user.currentOrganization?.organizationName;
    const orgFprint = user.currentOrganization?.fprint;
    const subscription = user.currentOrganization?.subscription;
    const orgId = user.currentOrganization?.orgId;

    const profile = user.profile;

    const featureAccessData = {
      clioFreePlan: canOrgUseFeatureAccess('CLIO_FREE', org),
      questionnairesEnabled: canOrgUseFeatureAccess('INTAKE_FORMS', org),
    };

    const planData = {
      planAmount: subscription?.plan.amount,
      planId: subscription?.plan.id,
      planInterval: subscription?.plan.interval,
      planName: subscription?.plan.name,
      planQuantity: subscription?.quantity,
      planStatus: subscription?.status,
    };

    const identifyProperties = {
      admin,
      email,
      displayName: `${profile?.firstName} ${profile?.lastName}`,
      orgFprint,
      orgId,
      isPaid: subscription && subscription.status === 'active',
      firstName: profile?.firstName,
      lastName: profile?.lastName,
      middleName: profile?.middleName,
      orgName: organizationName,
      otpEnabled: profile?.ssoEnabled,
      otpMethod: profile?.ssoMethod,
      phone: profile?.phone,
      needsToSubscribe: !subscription,
      pro2: true,
      traits: {
        pro2: true,
        admin,
        firstName: profile?.firstName,
        lastName: profile?.lastName,
        middleName: profile?.middleName,
        orgName: organizationName,
        orgFprint,
        orgId,
        otpEnabled: profile?.ssoEnabled,
        otpMethod: profile?.ssoMethod,
        phone: profile?.phone,
        ...planData,
        ...featureAccessData,
      },

      ...planData,
      ...featureAccessData,
    };

    analyticsService.initialize(identifyProperties, () => ({
      userId: `${user.profile?.authenticatedUserId}`,
      organizationId: `${user.currentOrganization?.orgId}`,
      organizationName: user.currentOrganization?.organizationName,
    }));
  }, [store, org]);

  const track = useCallback<AnalyticsContextType['track']>((...args) => {
    analyticsService.track(...args);
  }, []);

  return (
    <AnalyticsContext.Provider value={{ track }}>
      {children}
    </AnalyticsContext.Provider>
  );
};
