import React from 'react';
import { SelectProps } from '@mui/material';
import { SelectWithOptions } from './SelectWithOptions';
import { PRACTICE_AREAS } from '~/src/entities/welcomeForm';

export const PracticeArea = React.forwardRef((props: SelectProps, ref) => (
  <SelectWithOptions
    inputProps={{ 'data-testid': 'foo' }}
    ref={ref}
    {...props}
    options={PRACTICE_AREAS}
  />
));
