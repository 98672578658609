import { http, getPaginationUrlQuery } from '~/src/services/requests';

function documentSetsService() {
  return {
    updateDocument: async (orgFprint, projectId, properties) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v2/org/${orgFprint}/document-set/${projectId}'`,
          method: 'PUT',
          body: properties,
        },
        parse,
      );
    },
    fetch: async (orgFprint, { page, search, sortBy, order, limit }) => {
      const parse = true;

      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v2/org/${orgFprint}/document-set/${getPaginationUrlQuery(
            { page, search, sortBy, order, limit },
          )}`,
          method: 'GET',
        },
        parse,
      );
    },
    getDocument: async (orgFprint, documentId) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/document-set/${documentId}/`,
          method: 'GET',
        },
        parse,
      );
    },
  };
}

export default documentSetsService();
