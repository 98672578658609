import React from 'react';
import { Chip, Typography } from '@mui/material';
import { PlanCard } from './PlanCard';

export const EsignaturesPlanCard = () => {
  return (
    <PlanCard
      label={'e-signatures'}
      action={
        <Chip
          label={
            <Typography
              variant={'smallLabel'}
              sx={(theme) => ({ color: theme.palette.green[700] })}
            >
              Included for free
            </Typography>
          }
          sx={(theme) => ({ background: theme.palette.green[50] })}
        />
      }
    />
  );
};
