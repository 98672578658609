import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

export default StyleSheet.create({
  cardBody: {
    fontSize: theme.fonts.size.small,
    lineHeight: '1.25em',
  },
  buttonLink: {
    display: 'inline-block',
    padding: `${theme.unit}px 0`,
    fontSize: theme.fonts.size.small,
    ':active': { outline: 'none' },
    ':focus': { outline: 'none' },
  },
  settingsRow: {
    marginBottom: theme.unit * 4,
    ':last-of-type': {
      marginBottom: theme.unit,
    },
  },
  settingsLabel: {
    fontSize: '14px',
    color: '#777E93',
    marginBottom: theme.unit * 1.5,
  },
  settingsCardContainer: {
    margin: '0 auto',
    maxWidth: theme.unit * 70,
  },
  settingsOuterButtonContainer: {
    marginTop: theme.unit * 4,
  },
  settingsSubscriptionLabelButton: {
    marginTop: theme.unit * 2,
  },
});
