import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

export default StyleSheet.create({
  container: {
    minHeight: '100%',
    display: 'flex',
    flexFlow: 'column',
    flexGrow: 1,
  },
  body: {
    display: 'flex',
    flexFlow: 'column',
    flexGrow: 1,
    background: theme.background,
    position: 'absolute',
    left: '0px',
    right: '0px',
    top: '69px',
    bottom: '66px',
    overflowY: 'scroll',
  },
  innerBody: {
    padding: theme.unit * 3,
  },
  header: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    padding: theme.unit * 3,
    background: theme.surface,
    borderBottom: theme.border,
  },
  title: {
    fontSize: theme.fonts.size.regular,
    marginBottom: theme.unit / 2,
  },
  subtitle: {
    fontSize: theme.fonts.size.large,
    fontWeight: '200',
  },
  footer: {
    minHeight: 33,
    position: 'absolute',
    bottom: '0',
    left: '0',
    right: '0',
    padding: theme.unit * 2,
    borderTop: theme.border,
    background: theme.surface,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  footerButton: {
    marginLeft: theme.unit * 2,
  },
  footerLink: {
    color: theme.colors.bluebird,
    marginLeft: theme.unit * 2,
  },
});
