import { Divider, Typography } from '@mui/material';
import React, { PropsWithChildren } from 'react';

type SingleSelectGroupProps = PropsWithChildren<{
  group: string;
  withDivider?: boolean;
}>;

export const SingleSelectGroup = ({
  group,
  children,
  withDivider = true,
}: SingleSelectGroupProps) => {
  return (
    <>
      <Typography
        variant="captionAllCaps"
        sx={(theme) => ({
          display: 'block',
          padding: theme.spacing(1, 2),
          color: theme.palette.text.hint,
        })}
      >
        {group}
      </Typography>
      {children}

      {withDivider ? (
        <Divider
          variant={'middle'}
          sx={(theme) => ({
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
          })}
        />
      ) : null}
    </>
  );
};
