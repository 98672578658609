import React from 'react';

import { ClioMatter } from '~/src/services/api/clioService';
import ErrorContent from './ModalContent/ErrorContent';
import LoadingContent from './ModalContent/LoadingContent';
import MatterDetailContent from './ModalContent/MatterDetailContent';
import MatterSearchContent from './ModalContent/MatterSearchContent';

type ImportFromClioModalContentProps = {
  selectedClioMatter: ClioMatter | undefined;
  setSelectedClioMatter: (value?: ClioMatter) => void;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  allowSearch?: boolean;
  onClose: () => void;
  onImport: () => void;
};

const ImportFromClioModalContent = ({
  selectedClioMatter,
  setSelectedClioMatter,
  isLoading,
  isSuccess,
  isError,
  allowSearch,
  onClose,
  onImport,
}: ImportFromClioModalContentProps) => {
  if (isError) {
    return <ErrorContent onClose={onClose} />;
  }

  if (isLoading || isSuccess) {
    return <LoadingContent />;
  }

  if (selectedClioMatter) {
    return (
      <MatterDetailContent
        showBreadcrumbs={allowSearch}
        onBack={() => setSelectedClioMatter(undefined)}
        selectedClioMatter={selectedClioMatter}
        onClose={onClose}
        onImport={onImport}
      />
    );
  }

  return (
    <MatterSearchContent
      setSelectedClioMatter={setSelectedClioMatter}
      onClose={onClose}
    />
  );
};

export default ImportFromClioModalContent;
