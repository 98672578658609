import * as React from 'react';
import {
  CardListItemActions,
  CardListItemActionsProps,
} from './CardListItemActions';

type RenderMetaWithActions = {
  actions?: CardListItemActionsProps;
  meta?: React.ReactNode;
};

export const renderMetaWithActions = ({
  actions,
  meta,
}: RenderMetaWithActions) => {
  const renderActions = actions ? <CardListItemActions {...actions} /> : meta;

  return (shouldShowActions: boolean) =>
    shouldShowActions ? renderActions : meta;
};
