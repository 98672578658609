import React, { useEffect } from 'react';

import usePageScroll from '~/src/hooks/usePageScroll';
import usePdfLocalitiesPagination from '~/src/hooks/usePdfLocalitiesPagination';

import StateRegionTable from '~/src/components/CourtForms/StateRegionTable';
import LoadMoreDisplayText from '~/src/components/LoadMoreDisplayText';
import Breadcrumb from '~/src/components/Breadcrumb';

import { stateCodeToStateName } from '~/src/containers/CourtFormsLandingContainer';

const StateRegionTableContainer = ({
  filterCategory: stateCode,
  routeUrl = '/library/court-forms',
}) => {
  const requestPayload = {
    facets: {
      filter_category: stateCode,
    },
    group_by: 'filter_category,locality',
    query_by: 'locality',
  };

  const { next, loading, done, data, setSearch } =
    usePdfLocalitiesPagination(requestPayload);

  const pageScroll = usePageScroll();

  const loadMore = next;

  const handleOnPageScroll = () => {
    if ((pageScroll === 0 || pageScroll >= 85) && !loading) {
      loadMore();
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleOnPageScroll, [pageScroll]);

  const tableData = [];
  data.forEach((pdfTemplate) => {
    if (pdfTemplate.locality !== 'None') {
      // locality "None" is state-level, not region-level
      tableData.push({
        filterCategory: pdfTemplate.filterCategory,
        locality: pdfTemplate.locality,
      });
    }
  });

  const handleSearchChange = (searchValue) => {
    setSearch(searchValue);
  };

  const breadcrumbData = [
    {
      label: 'Court forms',
      url: routeUrl,
    },
    {
      label: `${stateCodeToStateName[stateCode]} regional forms`,
    },
  ];

  return (
    <>
      <Breadcrumb data={breadcrumbData} />
      <StateRegionTable
        actionType="link"
        pdfCourtForms={tableData}
        visibleItems={tableData.length}
        empty={!loading && done && tableData.length === 0}
        onSearchChange={handleSearchChange}
        routeUrl={routeUrl}
      />
      <LoadMoreDisplayText
        loading={loading}
        done={done}
        onLoadMore={loadMore}
      />
    </>
  );
};

export default StateRegionTableContainer;
