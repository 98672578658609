import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import analytics from '../utils/analytics';

type RouterAnalyticsProps = {
  children: React.ReactNode;
};

export const RouterAnalytics = ({ children }: RouterAnalyticsProps) => {
  const location = useLocation();

  useEffect(() => {
    const shouldTrackPage =
      // If this is not the root path
      location.pathname !== '/' &&
      // And the pathname does not include 'clients'
      location.pathname.indexOf('clients') === -1;

    if (location.key && shouldTrackPage) {
      analytics.page({
        path: location.pathname,
        search: location.search,
        hash: location.hash,
      });
    }
  }, [location]);

  return <>{children}</>;
};
