import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

export default StyleSheet.create({
  rowActionButton: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.unit * 1}px ${theme.unit * 2}px`,
    paddingRight: theme.unit,
  },
  /* IntakesTableEmpty */
  intakesTableEmptyCardContainer: {
    margin: '0 auto',
    width: `${theme.unit * 80}px`,
    marginTop: theme.unit * 20,
    textAlign: 'center',
    // display: "flex",
    // flexFlow: "column",
    // alignItems: "center",
    // flexGrow: 1,
    // justifyContent: "center",
  },
  intakesTableEmptyContainer: {
    margin: theme.unit * 6,
  },
  // intakesTableEmptyContainerTitle: {
  //   fontSize: theme.fonts.size.regular,
  //   fontWeight: "bold",
  //   textAlign: "center"
  // },
  // intakesTableEmptyContainerDescription: {
  //   marginTop: `${theme.unit * 2}px`,
  //   lineHeight: "1.5em",
  //   fontSize: theme.fonts.size.small,
  //   textAlign: "center"
  // },
  emptyAction: {
    marginTop: theme.unit * 5,
    display: 'flex',
    justifyContent: 'center',
  },
  iconHover: {
    height: '5px',
    width: '45px',
    background: theme.colors.smoke,
    display: 'block',
    margin: '5px auto',
    borderRadius: '50%',
    opacity: '0.75',
  },
  emptyTitle: {
    display: 'block',
    marginTop: theme.unit * 4,
    textAlign: 'center',
    fontSize: theme.fonts.size.regular,
    color: theme.colors.charcoal,
  },
  emptySubtitle: {
    marginTop: theme.unit,
    textAlign: 'center',
    fontSize: theme.fonts.size.small,
    color: theme.colors.chrome,
  },
  emptyIntakeFormPreview: {
    textAlign: 'center',
    margin: '100px auto',
  },
});
