import { useObserver } from 'mobx-react';
import useMst from '~/src/hooks/useMst';
import useResourcePagination from '~/src/hooks/useResourcePagination';

const useIntakesPagination = () => {
  const { intakes } = useMst((store) => ({
    intakes: store.questionnaires,
  }));

  const { loading, next, empty, page, setSearch, done, setSortBy, setOrder } =
    useResourcePagination({
      fetch: intakes.fetch,
      limit: 10,
      order: 'desc',
    });

  const listData = useObserver(() => {
    return intakes.list.flat();
  });

  const isEmpty = useObserver(() => {
    return (
      (empty && intakes.list.flat().length === 0) ||
      intakes.list.flat().length === 0
    );
  });

  return {
    next,
    page,
    data: listData,
    loading,
    done,
    empty: isEmpty,

    setSearch,
    setSortBy,
    setOrder,
  };
};

export default useIntakesPagination;
