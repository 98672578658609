/*
NPS Survey Cadence for first survey is defined by the frontend:
  Customers who sign up after Delighted NPS is launched: 
      30 days after account creation 
  Customers who signed up before Delighted NPS was launched: 
      NEXT 6-month increment based on their account creation date
        If they are within 2 weeks of their last increment, show them the survey as well. Please see PR for more details.

The cadence for subsequent surveys is defined by the Delighted platform settings
*/

const DAYS_PER_MONTH = 30; // technically, some months have 31 days and we have leap years. but i think this approximation is good enough for our purposes
const FEATURE_RELEASE_DATE = '2024-08-02';
const NEW_USER_FIRST_SURVEY_DELAY_DAYS_AFTER_CREATE = 30;
const EXISTING_USER_FIRST_SURVEY_INCREMENT_MONTHS = 6;
const EXISTING_USER_FIRST_SURVEY_LOOKBACK_WINDOW_DAYS = 14;

const daysToSeconds = (days: number) => {
  return 60 * 60 * 24 * days;
};

export const getInitialSurveyDelaySecondsAfterCreation = (
  createdAt: string,
  existingUserIncrementInMonths = EXISTING_USER_FIRST_SURVEY_INCREMENT_MONTHS,
) => {
  const userCreationDate = new Date(createdAt);
  const npsDelightedIntroducedDate = new Date(FEATURE_RELEASE_DATE);
  const isUserCreatedAfterFeature =
    userCreationDate >= npsDelightedIntroducedDate;

  if (isUserCreatedAfterFeature) {
    return daysToSeconds(NEW_USER_FIRST_SURVEY_DELAY_DAYS_AFTER_CREATE);
  }

  // user existed before NPS feature
  const currentTimeInSeconds = Date.now() / 1000;
  const createdTimeInSeconds = userCreationDate.getTime() / 1000;
  const incrementInSeconds = daysToSeconds(
    existingUserIncrementInMonths * DAYS_PER_MONTH,
  );

  const secondsElapsedSinceLastIncrement =
    (currentTimeInSeconds - createdTimeInSeconds) % incrementInSeconds;
  const remainingSecondsForNextIncrement =
    incrementInSeconds - secondsElapsedSinceLastIncrement;

  // they are within the 2 week window since their last increment. show the survey
  if (
    secondsElapsedSinceLastIncrement <
    daysToSeconds(EXISTING_USER_FIRST_SURVEY_LOOKBACK_WINDOW_DAYS)
  ) {
    return (
      currentTimeInSeconds -
      daysToSeconds(EXISTING_USER_FIRST_SURVEY_LOOKBACK_WINDOW_DAYS) -
      createdTimeInSeconds
    );
  }

  // they are outside the 2 week window since the last increment. don't show the survey until the next increment
  return (
    currentTimeInSeconds +
    remainingSecondsForNextIncrement -
    createdTimeInSeconds
  );
};
