import React from 'react';
import { css } from 'aphrodite';

import styles from './styles';

const Footer = ({ children }) => (
  <footer className={css(styles.footer)}>{children}</footer>
);

export default Footer;
