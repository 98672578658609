import { useEffect } from 'react';
import { useWebViewerContext } from '~/src/components/Webviewer';

/**
 * Makes the PDF viewer visible on the signer
 * selection page on mount.
 */
const useMakeSignerSelectionVisible = () => {
  const { makeWebViewerVisible, setWebViewerPosition, hideWebViewer } =
    useWebViewerContext();

  useEffect(() => {
    setWebViewerPosition({
      left: 'min(288px, 50%)',
      right: 'min(288px, 50%)',
      top: 80,
      bottom: 80,
    });
    makeWebViewerVisible();
    return () => {
      hideWebViewer();
    };
    // We only want this hook to run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useMakeSignerSelectionVisible;
