import { Box, Chip, IconButton, Link } from '@mui/material';
import React from 'react';
import { CardListItemSubList } from './CardListItemSubList';
import { Plus, Eye, Trash } from 'react-feather';
import { MoreVert } from '@mui/icons-material';
import { renderMetaWithActions } from './utils';
import { CardListItemMetaText } from './CardListItemMetaText';

const link = (
  <Link onClick={(e) => e.stopPropagation()}>templateName.format</Link>
);

export const actions = [
  {
    label: 'Add',
    icon: <Plus size={16} />,
    onClick: () => {},
  },
  {
    label: 'Preview',
    icon: <Eye size={16} />,
    onClick: () => {},
  },
  {
    label: 'Delete',
    icon: <Trash size={16} />,
    onClick: () => {},
  },
];

export const subList = (
  <CardListItemSubList
    items={[
      { title: link, actions },
      { title: link, actions },
      { title: link, actions },
      { title: link, actions },
    ]}
  />
);

export const cardListItemActions = {
  primary: { label: 'Download', onClick: () => {} },
  actions,
};

export const cardListItemMeta = (
  <Box display={'flex'} gap={1} alignItems={'center'}>
    <Chip
      sx={{
        background: (theme) => theme.palette.info.light,
        color: (theme) => theme.palette.info.main,
      }}
      label={'Draft'}
    />
    <CardListItemMetaText>
      Last updated: 99/99/9999, userEmail
    </CardListItemMetaText>
    <div>
      <IconButton sx={{ fontSize: 16 }}>
        <MoreVert fontSize={'inherit'} />
      </IconButton>
    </div>
  </Box>
);

export const renderMeta = renderMetaWithActions({
  actions: cardListItemActions,
  meta: cardListItemMeta,
});

export const title = {
  label: '#-formName',
  meta: ['Jurisdiction', 'Topic', 'Revision 99/99/9999'],
};

export const titleWithLinks = {
  label: 'lastName, firstName',
  meta: [
    'contactEmail',
    <Link
      key={'link'}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      # documents
    </Link>,
  ],
};
