import { useMutation, useQueryClient } from 'react-query';
import { APIError } from '~/src/utils/error';
import { getRelatedQuestionnaireSubmissionIdUrl } from '../urls';
import { questionnaireQueryArgs } from '../queryArgs';
import { RelatedQuestionnaireSubmission } from '~/src/entities/questionnairesV2/types';

type Variables = {
  id: number;
  title?: string;
  project_id?: number;
};

export const usePatchRelatedQuestionnaireSubmission = (orgFprint: string) => {
  const queryClient = useQueryClient();
  return useMutation<RelatedQuestionnaireSubmission, APIError, Variables>({
    onSuccess: () => {
      queryClient.invalidateQueries(questionnaireQueryArgs.all);
    },
    mutationFn: async (payload) => {
      const response = await fetch(
        getRelatedQuestionnaireSubmissionIdUrl(orgFprint, payload.id),
        {
          method: 'PATCH',
          body: JSON.stringify(payload),
        },
      );

      if (!response.ok) {
        throw new APIError(
          'Failed to update(patch) related questionnaire submission in lawyaw',
          response,
        );
      }

      return response.json();
    },
  });
};
