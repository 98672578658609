import { useQuery, UseQueryOptions } from 'react-query';
import { APIError } from '~/src/utils/error';
import { useCurrentOrgFprint } from '~/src/entities/user/hooks';
import { RelatedQuestionnaireSubmission } from '../types';
import { keys } from '../keys';
import { composeQueryOpts, fetchAndParse } from '../../utils';
import { getRelatedQuestionnaireSubmissionsByMatterIdUrl } from '../urls';

export const useRelatedQuestionnaireSubmissionsByMatterId = (
  matterId: number,
  options: UseQueryOptions<RelatedQuestionnaireSubmission[], APIError> = {},
) => {
  const orgFprint = useCurrentOrgFprint();
  return useQuery(
    composeQueryOpts(options, {
      queryKey: matterId ? keys.listByMatter(matterId) : keys.lists(),
      queryFn: () =>
        fetchAndParse<RelatedQuestionnaireSubmission[]>(
          getRelatedQuestionnaireSubmissionsByMatterIdUrl(orgFprint, matterId),
        ),
    }),
  );
};
