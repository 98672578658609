/* Constants */
import React from 'react';
import useMst from '~/src/hooks/useMst';

/* Constants */
import { LAYOUT_MODAL_TYPES } from '~/src/components/PageLayout/Modals';
import { VALIDATION_TYPES } from '~/src/utils/validation';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import { useLayoutContext } from '../../contexts/Layout';
import { history } from '../../utils/history';

import useFeatureAccesses from '~/src/hooks/useFeatureAccesses';
import { Matter } from '~/src/entities/matter';

const useMattersActions = () => {
  const { matterStore } = useMst((store) => ({
    matterStore: store.matter,
  }));

  const { showToast, showModal, hideModal } = useLayoutContext();
  const { isClioQuestionnaireEnabled } = useFeatureAccesses();

  const handleViewMatter = (matter: Matter) => {
    matterStore.viewMatter(matter.id);
    history.push({
      pathname: `/matter/${matter.id}`,
      state: { title: matterStore.getMatter(matter.id)?.fullTitle() },
    });
  };

  const handleRenameMatter = async (matter: Matter) => {
    const handleRenameConfirm = async (form: {
      fields: { name: { value: string } };
    }) => {
      const { value } = form.fields.name;
      await matterStore.updateMatter(matter.id, { title: value });
      showToast(LAYOUT_TOAST_TYPES.success, {
        message: `Matter ${value} successfully renamed.`,
      });
      hideModal();
    };

    showModal(LAYOUT_MODAL_TYPES.formField, {
      title: 'Rename Matter',
      primaryActionTitle: 'Save',
      fields: [
        {
          label: 'Name',
          id: 'name',
          type: 'text',
          // @ts-ignore: It exists but isn't on the type.
          defaultValue: matter.name,
          validation: VALIDATION_TYPES.present,
        },
      ],
      onConfirm: handleRenameConfirm,
    });
  };

  const handleDeleteMatter = (matter: Matter) => {
    const handleDeleteConfirm = async () => {
      matterStore.deleteMatter(matter.id);
      hideModal();
    };

    const message = isClioQuestionnaireEnabled ? (
      <>
        <strong>Warning: this action is permanent.</strong>
        <br />
        <strong>
          The following content related to this matter will also be deleted:
        </strong>
        <ul className="list-disc" style={{ margin: '20px' }}>
          <li>Live questionnaires</li>
        </ul>
        The documents contained in this set will also be deleted.
      </>
    ) : (
      <>
        <strong>Warning: this action is permanent.</strong>
        <br />
        <br />
        Document sets created for this matter will not be affected.
      </>
    );

    showModal(LAYOUT_MODAL_TYPES.delete, {
      title: 'Delete Matter',
      message: message,
      onConfirm: handleDeleteConfirm,
      primaryActionTitle: 'Delete',
    });
  };

  return {
    onViewMatter: handleViewMatter,
    onRenameMatter: handleRenameMatter,
    onDeleteMatter: handleDeleteMatter,
  };
};

export default useMattersActions;
