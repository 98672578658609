import {
  DialogProps,
  DialogTitle,
  DialogContent,
  Dialog,
  List,
  ListItemText,
  Switch,
  ListItemButton,
} from '@mui/material';
import * as React from 'react';
import {
  useUIFlags,
  useToggleUIFlag,
  UIFlag,
  UI_FLAG_LABELS,
} from './uiFlags.context';

export const UIFlagsModal = (props: DialogProps) => {
  const flags = useUIFlags();
  const toggleFlag = useToggleUIFlag();

  return (
    <Dialog fullWidth maxWidth={'xs'} {...props}>
      <DialogTitle>Manage UI Flags</DialogTitle>
      <DialogContent>
        <List>
          {Object.entries(flags).map(([flag, enabled]) => (
            <ListItemButton
              onClick={() => toggleFlag(flag as UIFlag)}
              key={flag}
            >
              <ListItemText>{UI_FLAG_LABELS[flag as UIFlag]}</ListItemText>
              <Switch checked={enabled} />
            </ListItemButton>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};
