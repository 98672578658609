import React from 'react';
import { Box } from '@mui/material';
import { Controller } from 'react-hook-form';
import {
  ProjectStackField,
  ProjectStackDocument,
} from '~/src/entities/project';
import { PopulateCardField } from './PopulateCardField';
import { isEqual } from 'lodash';

export type PopulateCardFieldsProps = {
  fields: ProjectStackField[];
  projectStackDocuments: ProjectStackDocument[];
  onBlur: (fieldId: string) => void;
  onFocus: (fieldId: string) => void;
  countTouched: (fieldId: string) => void;
};

export const PopulateCardFields = React.memo(
  ({
    fields,
    projectStackDocuments,
    onBlur,
    onFocus,
    countTouched,
  }: PopulateCardFieldsProps) => (
    <>
      {fields.map((projectField) => (
        <Box mb={2} key={projectField.fid}>
          <Controller
            name={projectField.fid}
            render={({ field }) => {
              return (
                <PopulateCardField
                  field={{
                    ...field,
                    onBlur: () => {
                      field.onBlur();
                      onBlur(projectField.fid);
                    },
                    onFocus: () => onFocus(projectField.fid),
                    countTouched: () => {
                      countTouched(projectField.fid);
                    },
                  }}
                  projectField={projectField}
                  projectStackDocuments={projectStackDocuments}
                />
              );
            }}
          />
        </Box>
      ))}
    </>
  ),
  (prevProps, nextProps) =>
    isEqual(prevProps.fields, nextProps.fields) &&
    isEqual(prevProps.projectStackDocuments, nextProps.projectStackDocuments),
);
