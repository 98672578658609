import {
  Box,
  Card,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Edited } from '~/src/components/Inputs/Edit';
import {
  ProjectStackField,
  ProjectStackDocument,
} from '~/src/entities/project';
import { FieldRenderProps } from '..';
import { FieldHeaderWithEdit } from '../FieldHeader/FieldHeaderWithEdit';

export type SingleSelectFieldProps = {
  field: FieldRenderProps;
  projectField: ProjectStackField;
  projectStackDocuments: ProjectStackDocument[];
};

export const SingleSelectField = React.memo(
  ({ field, projectField, projectStackDocuments }: SingleSelectFieldProps) => {
    const { value, onChange, ref, onBlur, onFocus, countTouched } = field;
    const options = projectField.multiple_choice_variable!.options;

    const selectedOption = options.find((o) =>
      o.formatted ? o.option === value : o.value === value,
    );

    const isEdited = !!field.value && !selectedOption;

    const [isEditing, setIsEditing] = useState(isEdited);

    return (
      <Card
        sx={(theme) => ({
          border: `1px solid ${
            isEditing ? theme.palette.border : 'transparent'
          }`,
          marginBottom: theme.spacing(3),
        })}
        elevation={isEditing ? 3 : 0}
      >
        <Box p={isEditing ? 2 : 0}>
          <FieldHeaderWithEdit
            projectField={projectField}
            projectStackDocuments={projectStackDocuments}
            isEdited={isEdited}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />

          {projectField.single_select_display_type === 'Dropdown' ? (
            <Select
              inputRef={ref}
              inputProps={{ id: field.name, 'data-testid': 'select-input' }}
              id={field.name}
              data-testid="select"
              labelId={field.name}
              value={field.value ? field.value : ''}
              sx={{ display: 'block' }}
              size={'small'}
              onBlur={onBlur}
              onFocus={onFocus}
              onChange={(e) => {
                onChange(e.target.value);
                if (countTouched) {
                  countTouched(field.name);
                }
              }}
            >
              {options.map(({ value, option, formatted }, _) => {
                return (
                  <MenuItem key={value} value={formatted ? option : value}>
                    {option}
                  </MenuItem>
                );
              })}
            </Select>
          ) : (
            <RadioGroup
              name={field.name}
              value={field.value}
              onChange={(_, v) => {
                onChange(v);
                if (countTouched) {
                  countTouched(field.name);
                }
              }}
              id={field.name}
            >
              {options.map((fieldOption, i) => {
                let optionValue = fieldOption.formatted
                  ? fieldOption.option
                  : fieldOption.value;

                optionValue = optionValue === null ? '' : optionValue;
                return (
                  <Box pb={1} key={fieldOption.value}>
                    <FormControlLabel
                      style={{ display: 'flex' }}
                      slotProps={{ typography: { variant: 'body1' } }}
                      checked={field.value === optionValue}
                      control={
                        <Radio
                          onBlur={onBlur}
                          onFocus={onFocus}
                          inputRef={i === 0 ? ref : undefined}
                          sx={{ paddingTop: 0, paddingBottom: 0 }}
                        />
                      }
                      label={fieldOption.option}
                      value={optionValue}
                    />
                  </Box>
                );
              })}
            </RadioGroup>
          )}

          {isEditing ? (
            <Box pt={5}>
              <Box pb={1}>
                <Typography variant={'label'}>Text to Merge</Typography>
              </Box>
              <TextField
                id={field.name}
                inputProps={{ 'data-testid': 'text-to-merge-field' }}
                size={'small'}
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                  if (countTouched) {
                    countTouched(field.name);
                  }
                }}
                onBlur={onBlur}
                onFocus={onFocus}
                fullWidth
                multiline
              />
              <Box
                py={1}
                visibility={isEdited ? 'visible' : 'hidden'}
                display={'flex'}
                justifyContent={'flex-end'}
                alignItems={'center'}
              >
                {isEdited ? <Edited /> : null}
              </Box>
            </Box>
          ) : null}
        </Box>
      </Card>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.field.value === nextProps.field.value &&
      prevProps.projectField === nextProps.projectField &&
      prevProps.projectStackDocuments === nextProps.projectStackDocuments
    );
  },
);
