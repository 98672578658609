import { useQuery, UseQueryOptions } from 'react-query';
import { APIError } from '~/src/utils/error';
import { ContactFieldKeyMapResponse } from '../types';
import { useCurrentOrgFprint } from '../../user';
import { composeQueryOpts, fetchAndParse } from '../../utils';
import { keys } from '../keys';
import { getContactFieldKeyMapUrl } from '../urls';

export const fetchContactFieldKeyMap = (
  orgFprint: string,
  contactId: number,
  fields: string[],
) =>
  fetchAndParse<ContactFieldKeyMapResponse>(
    getContactFieldKeyMapUrl(orgFprint, contactId),
    {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({ fields }),
    },
  );

export const useContactFieldMap = (
  contactId: number,
  fields: string[],
  options: UseQueryOptions<ContactFieldKeyMapResponse, APIError> = {},
) => {
  const orgFprint = useCurrentOrgFprint();
  return useQuery(
    composeQueryOpts(options, {
      queryKey: keys.fieldMap(contactId, fields),
      queryFn: () => fetchContactFieldKeyMap(orgFprint, contactId, fields),
    }),
  );
};
