import React from 'react';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import WithLayoutProps from '~/src/hoc/WithLayoutProps';
import Card from '~/src/components/Card';
import { LAYOUT_MODAL_TYPES } from '~/src/components/PageLayout/Modals';
import styles from '../styles';
import { CreateNewSubscription } from '../CreateNewSubscription';
import { NoSubscriptionNeeded } from '../NoSubscriptionNeeded';
import { CancelCard } from '../CancelCard';
import { SubscriptionDetails } from './SubscriptionDetails';
import moment from 'moment';

class SettingsSubscriptionContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nextInvoice: 'Calculating...',
    };
  }

  async componentDidMount() {
    const { subscription, store } = this.props;
    const cycle =
      subscription && subscription.plan.interval === 'month'
        ? 'monthly'
        : 'annual';
    const nextInvoice =
      await store.user.currentOrganization.fetchUpcomingInvoice(
        [],
        [],
        [],
        cycle,
        subscription.quantity,
        '',
      );

    if (nextInvoice) {
      const { total } = nextInvoice;
      this.setState({ nextInvoice: total / 100 });
    }
  }

  handleUpdateSeats = () => {
    this.props.showModal(LAYOUT_MODAL_TYPES.subscriptionUpdate);
  };

  render() {
    const { onCreateNewSubscription, subscription, isClioFree } = this.props;

    const { nextInvoice } = this.state;

    const noSubscription = isClioFree ? (
      <CreateNewSubscription
        onCreateNewSubscription={onCreateNewSubscription}
      />
    ) : (
      <NoSubscriptionNeeded />
    );

    return (
      <div className={css(styles.settingsCardContainer)}>
        <Card elevate>
          {subscription ? (
            <SubscriptionDetails
              subscription={subscription}
              onUpdateSeats={this.handleUpdateSeats}
              onUpdatePaymentMethod={this.handleUpdatePaymentMethod}
              onRenewSubscription={this.handleRenewSubscription}
              nextInvoice={nextInvoice}
            />
          ) : (
            noSubscription
          )}
        </Card>
        <div className={css(styles.settingsOuterButtonContainer)}>
          {subscription && !subscription.canceledAt ? (
            <CancelCard
              endDate={moment(subscription.currentPeriodEnd).format(
                'MMMM DD, YYYY',
              )}
              onCancelSubscription={this.handleCancelSubscription}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export const SettingsSubscription = inject((store) => store)(
  WithLayoutProps(observer(SettingsSubscriptionContainer)),
);
