/* Libs */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';

import { X, Search, ChevronDown, ChevronUp } from 'react-feather';
import Label from '~/src/components/Inputs/Label';
import TextInput from '~/src/components/Inputs/TextInput';
import theme from '~/src/theme';
import { isTouchCapable, scrollIntoView } from './utils';

/* Components */
import DummyInput from './DummyInput';
import ScrollCaptor from './ScrollCaptor';
import ScrollBlock from './ScrollBlock';

import styles from '../styles';
import localStyles from './styles';

let currentInstanceId = 1;

// ==============================
// TODO: Touch Handlers
// ==============================

// startListeningToTouch() {
//   if (document && document.addEventListener) {
//     document.addEventListener('touchstart', this.onTouchStart, false);
//     document.addEventListener('touchmove', this.onTouchMove, false);
//     document.addEventListener('touchend', this.onTouchEnd, false);
//   }
// }
// stopListeningToTouch() {
//   if (document && document.removeEventListener) {
//     document.removeEventListener('touchstart', this.onTouchStart);
//     document.removeEventListener('touchmove', this.onTouchMove);
//     document.removeEventListener('touchend', this.onTouchEnd);
//   }
// }
// onTouchStart = ({ touches }: TouchEvent) => {
//   const touch = touches && touches.item(0);
//   if (!touch) {
//     return;
//   }

//   this.initialTouchX = touch.clientX;
//   this.initialTouchY = touch.clientY;
//   this.userIsDragging = false;
// };
// onTouchMove = ({ touches }: TouchEvent) => {
//   const touch = touches && touches.item(0);
//   if (!touch) {
//     return;
//   }

//   const deltaX = Math.abs(touch.clientX - this.initialTouchX);
//   const deltaY = Math.abs(touch.clientY - this.initialTouchY);
//   const moveThreshold = 5;

//   this.userIsDragging = deltaX > moveThreshold || deltaY > moveThreshold;
// };
// onTouchEnd = (event: TouchEvent) => {
//   if (this.userIsDragging) return;

//   // close the menu if the user taps outside
//   // we're checking on event.target here instead of event.currentTarget, because we want to assert information
//   // on events on child elements, not the document (which we've attached this handler to).
//   if (
//     this.controlRef &&
//     !this.controlRef.contains(event.target) &&
//     this.menuListRef &&
//     !this.menuListRef.contains(event.target)
//   ) {
//     this.blurInput();
//   }

//   // reset move vars
//   this.initialTouchX = 0;
//   this.initialTouchY = 0;
// };
// onControlTouchEnd = (event: SyntheticTouchEvent<HTMLElement>) => {
//   if (this.userIsDragging) return;
//   this.onControlMouseDown(event);
// };
// onClearIndicatorTouchEnd = (event: SyntheticTouchEvent<HTMLElement>) => {
//   if (this.userIsDragging) return;

//   this.onClearIndicatorMouseDown(event);
// };
// onDropdownIndicatorTouchEnd = (event: SyntheticTouchEvent<HTMLElement>) => {
//   if (this.userIsDragging) return;

//   this.onDropdownIndicatorMouseDown(event);
// };

// ==============================
// TODO: Composition Handlers
// ==============================

// startListeningComposition() {
//   if (document && document.addEventListener) {
//     document.addEventListener(
//       'compositionstart',
//       this.onCompositionStart,
//       false
//     );
//     document.addEventListener('compositionend', this.onCompositionEnd, false);
//   }
// }
// stopListeningComposition() {
//   if (document && document.removeEventListener) {
//     document.removeEventListener('compositionstart', this.onCompositionStart);
//     document.removeEventListener('compositionend', this.onCompositionEnd);
//   }
// }
// onCompositionStart = () => {
//   this.isComposing = true;
// };
// onCompositionEnd = () => {
//   this.isComposing = false;
// };

const SelectOptionInputControl = ({
  selectedOptions,
  isFocused,
  menuIsOpen,
  isClearable,
  multiSelect,

  onClearSelection,
  onCloseMenu,

  innerProps,
  innerRef,
  children,
}) => {
  const ClearIcon = !multiSelect && isClearable ? X : ChevronUp;
  const Icon = menuIsOpen ? ChevronDown : ClearIcon;

  const labelIsFocused = isFocused || menuIsOpen;

  const handleIconMouseDown = (event) => {
    if (menuIsOpen) {
      onCloseMenu();
    } else if (isClearable) {
      onClearSelection();
    }

    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <div
      {...innerProps}
      ref={innerRef}
      className={css(
        styles.textInput,
        styles.textInputLabel,
        localStyles.selectOptionInputControl,
        labelIsFocused && styles.textInputLabelFocus,
        !selectedOptions ||
          (selectedOptions.length === 0 && styles.textInputPlaceholder),
      )}
    >
      {children}
      <i className={css(styles.RightIcon)} onMouseDown={handleIconMouseDown}>
        <Icon color={theme.colors.charcoal} size={theme.fonts.size.small} />
      </i>
    </div>
  );
};

const SelectOptionInputValue = (props) => {
  const { label, value, onMouseDown } = props;

  const [mouseOver, setMouseOver] = useState(false);

  const handleMouseEnter = (event) => {
    event.stopPropagation();
    setMouseOver(true);
  };

  const handleMouseLeave = (event) => {
    event.stopPropagation();
    setMouseOver(false);
  };

  const handleMouseDown = (event) => {
    event.stopPropagation();
    onMouseDown({
      label,
      value,
    });
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseDown={handleMouseDown}
      style={{
        transition: 'all 150ms ease',
        background: mouseOver
          ? theme.colors.paleTomato
          : theme.colors.pattensBlue,
        color: mouseOver ? theme.colors.white : theme.colors.bluebird,
        fontSize: 12,
        borderRadius: theme.unit * 2,
        marginRight: theme.unit / 2,
        margin: theme.unit / 2,
        display: 'inline-flex',
        alignItems: 'stretch',
      }}
    >
      <i
        style={{
          paddingRight: theme.unit,
          paddingLeft: theme.unit,
          display: 'inline-flex',
          alignItems: 'center',
        }}
      >
        <X
          color={mouseOver ? theme.colors.white : theme.colors.bluebird}
          size={12}
        />
      </i>
      <span
        style={{
          padding: `${theme.unit}px`,
          paddingRight: theme.unit * 2,
          paddingLeft: 0,
        }}
      >
        {label}
      </span>
    </div>
  );
};

const SelectOptionInputControlValue = ({
  multiSelect,
  selectedOptions,
  placeholder,
  onRemoveOption,
}) => {
  if (!multiSelect || !selectedOptions || selectedOptions.length === 0) {
    return (
      (selectedOptions && selectedOptions[0] && selectedOptions[0].label) ||
      placeholder
    );
  }

  return (
    <div>
      {selectedOptions.map((option) => (
        <SelectOptionInputValue
          key={option.value}
          label={option.label}
          value={option.value}
          onMouseDown={onRemoveOption}
        />
      ))}
    </div>
  );
};

const SelectOptionInputContainer = ({ innerProps, innerRef, children }) => {
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={css(localStyles.selectOptionInputContainer)}
    >
      {children}
    </div>
  );
};

const SelectOptionInputOptionsMenu = ({
  captureMenuScroll,
  menuShouldBlockScroll,

  onMenuScrollToBottom,
  onMenuScrollToTop,

  options,
  noOptionLabel,
  optionsEmpty,
  menuIsOpen,

  renderOption,
  renderHeader,
  renderFooter,

  outerProps,
  outerRef,

  innerProps,
  innerRef,
}) => {
  if (!menuIsOpen) {
    return null;
  }

  if (optionsEmpty) {
    return (
      <div
        className={css(localStyles.selectOptionMenu)}
        {...outerProps}
        ref={outerRef}
      >
        {renderHeader && renderHeader()}
        <p className={css(localStyles.noSelectOptionLabel)}>{noOptionLabel}</p>
      </div>
    );
  }

  return (
    <div
      className={css(localStyles.selectOptionMenu)}
      {...outerProps}
      ref={outerRef}
    >
      {renderHeader && renderHeader()}
      <ScrollCaptor
        isEnabled={captureMenuScroll}
        onTopArrive={onMenuScrollToTop}
        onBottomArrive={onMenuScrollToBottom}
      >
        <ScrollBlock isEnabled={menuShouldBlockScroll}>
          <ul
            className={css(localStyles.selectOptionMenuList)}
            {...innerProps}
            ref={innerRef}
          >
            {options.map(renderOption)}
          </ul>
        </ScrollBlock>
      </ScrollCaptor>
      {renderFooter && renderFooter()}
    </div>
  );
};

const SelectOptionContainer = (props) => {
  const {
    index,
    className,
    innerRef,
    option,
    onSelect,
    onHover,
    SelectOption,
  } = props;

  const handleOnMouseDown = () => {
    onSelect(option);
  };

  const handleHover = (event) => {
    onHover(event, option);
  };

  return (
    <div
      ref={innerRef}
      tabIndex={index === 0 ? 1 : -1}
      onMouseDown={handleOnMouseDown}
      onMouseOver={handleHover}
    >
      <SelectOption index={index} className={className} option={option} />
    </div>
  );
};

const SelectOptionItem = React.forwardRef((props, ref) => {
  const { tabIndex, className, option, onClick, onKeyPress } = props;

  return (
    <li
      tabIndex={tabIndex}
      ref={ref}
      className={className}
      onClick={onClick}
      onKeyPress={onKeyPress}
    >
      {option.label}
    </li>
  );
});

const Select = (props) => {
  const {
    labelProperties,
    noOptionsMessage,
    searchPlaceholder,
    placeholder,
    isClearable,
    isDisabled,
    isSearchable,
    maxHeight,
    multiSelect,

    captureMenuScroll,
    menuShouldBlockScroll,

    value,
    options,

    onChange,
    onSearchInputChange,
    onBlur,
    onFocus,

    onMenuScrollToBottom,
    onMenuScrollToTop,

    renderMenuFooter,
    renderMenuHeader,
  } = props;

  // Node References
  const selectOptionInputContainerRef = useRef();
  const inputRef = useRef();
  const controlRef = useRef();
  const menuRef = useRef();
  const menuContainerRef = useRef();
  const focusedOptionRef = useRef();
  const searchRef = useRef();

  let blockInputBlur = false;

  const selectOptionFromValue = () => {
    const getOptionFromValue = (valueToCompare) => {
      for (let i = 0; i < options.length; i += 1) {
        const option = options[i];

        if (String(valueToCompare) === String(option.value)) {
          return option;
        }
      }

      return false;
    };

    if (multiSelect) {
      const selectedOptions = [];

      for (let i = 0; i < value.length; i += 1) {
        const option = getOptionFromValue(value[i]);
        if (option) {
          selectedOptions.push(option);
        }
      }

      return selectedOptions;
    }

    if (value) {
      const valueToCompare = (value && value.value) || value;
      return [getOptionFromValue(valueToCompare)];
    }

    return [];
  };

  const [blockOptionHover, setBlockOptionHover] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(selectOptionFromValue);
  const [focusedOption, setFocusedOption] = useState(null);

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [instanceId] = useState(() => (currentInstanceId += 1));

  const [searchText, setSearchText] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);

  // TODO:
  const [userIsDragging] = useState(false);
  const instancePrefix = `select-option-input-${instanceId}`;

  // search input changed
  useEffect(() => {
    if (onSearchInputChange) {
      onSearchInputChange(searchText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    const formattedSearchText = searchText.toLowerCase().replace(' ', '');
    let nextFilteredOptions = options.filter((option) => {
      const formattedSearchLabel = option.label.toLowerCase().replace(' ', '');

      return formattedSearchLabel.indexOf(formattedSearchText) > -1;
    });

    // If we are selecting multiple options, remove the selected options from the options list.
    if (multiSelect) {
      const selectedOptionValues = selectedOptions.map(
        (option) => option.value,
      );

      nextFilteredOptions = nextFilteredOptions.filter((option) => {
        return selectedOptionValues.indexOf(option.value) < 0;
      });
    }

    setFilteredOptions(nextFilteredOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, options, selectedOptions]);

  // Ensure the focused option is scrolled into view.
  useEffect(() => {
    if (
      focusedOption &&
      menuRef &&
      menuRef.current &&
      focusedOptionRef &&
      focusedOptionRef.current
    ) {
      scrollIntoView(menuRef.current, focusedOptionRef.current);
    }
  }, [focusedOption]);

  // When the menu opens, focus the search input
  useEffect(() => {
    if (menuIsOpen) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      blockInputBlur = true;

      if (!isSearchable && inputRef && inputRef.current) {
        inputRef.current.focus();
      }

      if (isSearchable && searchRef && searchRef.current) {
        searchRef.current.focus();
      }
    }
  }, [menuIsOpen]);

  const isOptionSelected = (option) => {
    for (let i = 0; i < selectedOptions.length; i += 1) {
      const currOption = selectedOptions[i];
      const currOptionValue = currOption.value ? currOption.value : currOption;

      if (option.value === currOptionValue) {
        return true;
      }
    }

    return false;
  };

  const openMenu = (action) => {
    if (menuIsOpen) {
      return;
    }

    setMenuIsOpen(true);

    if (action === 'first') {
      setFocusedOption(options[0]);
    } else if (action === 'last') {
      setFocusedOption(options[options.length - 1]);
    }
  };

  const closeMenu = () => {
    setFocusedOption(null);
    setMenuIsOpen(false);
    setIsFocused(false);
  };

  const removeSelectedOption = (option) => {
    const nextOptions = selectedOptions.filter((o) => {
      return o.value !== option.value;
    });

    setSelectedOptions(nextOptions);
    onChange(nextOptions);
  };

  const selectOption = (option) => {
    if (!option) {
      setSelectedOptions([]);
      onChange([]);
    } else if (multiSelect) {
      const nextOptions = [...selectedOptions, option];
      setSelectedOptions(nextOptions);
      onChange(nextOptions);
    } else {
      setSelectedOptions([option]);
      onChange(option);
    }

    closeMenu();
  };

  const clearSelection = () => {
    selectOption(null);
    openMenu();
  };

  const optionHover = (option) => {
    if (!blockOptionHover) {
      setFocusedOption(option);
    }
  };

  const getFocusedOptionIndex = () => {
    let index = 0;

    if (!focusedOption) {
      return index;
    }

    for (let i = 0; i < filteredOptions.length; i += 1) {
      const option = filteredOptions[i];
      const isFocused = !!(
        focusedOption &&
        focusedOption.value &&
        option.value === focusedOption.value
      );

      if (isFocused) {
        index = i;

        return index;
      }
    }

    return index;
  };

  const focusOption = (action) => {
    const focusedOptionIndex = getFocusedOptionIndex();
    let nextIndex = focusedOptionIndex;

    if (action === 'next' || action === 'down') {
      nextIndex = focusedOptionIndex + 1;

      if (!filteredOptions[nextIndex]) {
        nextIndex = 0;
      }
    } else if (action === 'previous' || action === 'up') {
      nextIndex = focusedOptionIndex - 1;

      if (nextIndex < 0) {
        nextIndex = filteredOptions.length - 1;
      }
    }

    setFocusedOption(filteredOptions[nextIndex]);
  };

  const focusInput = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleSerchInputChange = (value) => {
    setSearchText(value);
  };

  const handleKeyDown = (event) => {
    if (isDisabled) {
      return;
    }

    if (event.target.tagName === 'INPUT') {
      if (
        event.key === 'ArrowLeft' ||
        event.key === 'ArrowRight' ||
        event.key === 'Delete' ||
        event.key === 'Backspace'
      ) {
        return;
      }
    }

    setBlockOptionHover(true);

    switch (event.key) {
      case 'ArrowLeft':
        // if (!isMulti || inputValue) { return; }
        focusOption('previous');
        break;
      case 'ArrowRight':
        // if (!isMulti || inputValue) { return; }
        focusOption('next');
        break;
      case 'Delete':
      case 'Backspace':
        // if (inputValue) { return; }
        if (selectedOptions.length > 0) {
          clearSelection();
        }
        break;
      case 'Tab':
        // isComposing
        // if (this.isComposing) { return; }

        if (
          event.shiftKey ||
          !menuIsOpen ||
          !focusedOption ||
          // don't capture the event if the menu opens on focus and the focused
          // option is already selected; it breaks the flow of navigation
          isOptionSelected(focusedOption)
        ) {
          return;
        }

        selectOption(focusedOption);
        break;
      case 'Enter':
        if (event.keyCode === 229) {
          // ignore the keydown event from an Input Method Editor(IME)
          // ref. https://www.w3.org/TR/uievents/#determine-keydown-keyup-keyCode
          break;
        }
        if (menuIsOpen) {
          if (!focusedOption) {
            return;
          }
          // if (this.isComposing) { return; }
          selectOption(focusedOption);
          break;
        }
        return;
      case 'Escape':
        if (menuIsOpen) {
          closeMenu();
        } else {
          clearSelection();
        }
        break;
      case 'ArrowUp':
        if (menuIsOpen) {
          focusOption('up');
        } else {
          openMenu('last');
        }
        break;
      case 'ArrowDown':
        if (menuIsOpen) {
          focusOption('down');
        } else {
          openMenu('first');
        }
        break;
      case 'Home':
        if (!menuIsOpen) {
          return;
        }
        focusOption('first');
        break;
      case 'End':
        if (!menuIsOpen) {
          return;
        }
        focusOption('last');
        break;
      default:
        return;
    }

    event.preventDefault();
  };

  const handleMouseDownMenu = (event) => {
    if (event.button !== 0) {
      return;
    }

    event.stopPropagation();
    event.preventDefault();
  };

  const handleMouseMoveMenu = () => {
    setBlockOptionHover(false);
  };

  const handleInputFocus = (event) => {
    setIsFocused(true);

    if (onFocus) {
      onFocus(event);
    }
    // accesability
    // announceAriaLiveContext
    openMenu('first');
  };

  const handleInputBlur = (event) => {
    if (blockInputBlur) {
      blockInputBlur = false;
      return;
    }

    if (
      menuRef &&
      menuRef.current &&
      menuRef.current.contains(document.activeElement)
    ) {
      inputRef.focus();
      return;
    }

    onBlur(event);
    closeMenu();
  };

  const handleControlMouseDown = (event) => {
    if (!isFocused || !menuIsOpen) {
      focusInput();
      openMenu('first');
    }

    event.preventDefault();
  };

  const handleControlTouchEnd = (event) => {
    if (userIsDragging) {
      return;
    }
    handleControlMouseDown(event);
  };

  const renderOption = (option, index) => {
    const selected = isOptionSelected(option);

    const focused =
      focusedOption && focusedOption.value
        ? option.value === focusedOption.value
        : option === focusedOption;

    const handleSelect = () => {
      selectOption(option);
    };

    const handleHover = () => {
      optionHover(option);
    };

    const className = css(
      styles.selectOption,
      focused && styles.selectOptionFocused,
      selected && styles.selectOptionSelected,
    );

    return (
      <SelectOptionContainer
        index={index}
        key={option.value}
        className={className}
        option={option}
        innerRef={(focused && focusedOptionRef) || null}
        selected={selected}
        focused={focused}
        onSelect={handleSelect}
        onHover={handleHover}
        SelectOption={SelectOptionItem}
      />
    );
  };

  const renderSearchOptionInput = () => {
    return (
      <div className={css(localStyles.selectOptionSearchInputContainer)}>
        <TextInput
          ref={searchRef}
          placeholder={searchPlaceholder}
          value={searchText}
          onBlur={handleInputBlur}
          onChange={handleSerchInputChange}
          RightIcon={Search}
          noMargin
          editable
        />
      </div>
    );
  };

  const optionsEmpty = filteredOptions.length === 0;
  const noOptionLabel =
    typeof noOptionsMessage === 'function'
      ? noOptionsMessage()
      : noOptionsMessage;

  const commonStateProps = {
    menuIsOpen,
    isFocused,
    isDisabled,
  };

  const defaultRenderMenuHeader = isSearchable ? renderSearchOptionInput : null;
  const renderMenuHeaderFn = renderMenuHeader || defaultRenderMenuHeader;

  return (
    <SelectOptionInputContainer
      innerRef={selectOptionInputContainerRef}
      innerProps={{
        onKeyDown: handleKeyDown,
      }}
    >
      <SelectOptionInputControl
        {...commonStateProps}
        multiSelect={multiSelect}
        selectedOptions={selectedOptions}
        labelProperties={labelProperties}
        onCloseMenu={closeMenu}
        onClearSelection={clearSelection}
        isClearable={isClearable}
        innerRef={controlRef}
        innerProps={{
          onMouseDown: handleControlMouseDown,
          onTouchEnd: handleControlTouchEnd,
        }}
      >
        <SelectOptionInputControlValue
          multiSelect={multiSelect}
          selectedOptions={selectedOptions}
          placeholder={placeholder}
          onRemoveOption={removeSelectedOption}
        />
        {/* DummyInput to maintain focus/blur functionality */}
        <DummyInput
          id={`${instancePrefix}-input`}
          innerRef={inputRef}
          onChange={() => {}}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          readOnly
          disabled={isDisabled}
          tabIndex={'0'}
          value=""
          aria-autocomplete="list"
        />
      </SelectOptionInputControl>
      {menuIsOpen && (
        <SelectOptionInputOptionsMenu
          options={filteredOptions}
          noOptionLabel={noOptionLabel}
          optionsEmpty={optionsEmpty}
          onMenuScrollToBottom={onMenuScrollToBottom}
          onMenuScrollToTop={onMenuScrollToTop}
          captureMenuScroll={captureMenuScroll}
          menuShouldBlockScroll={menuShouldBlockScroll}
          renderHeader={renderMenuHeaderFn}
          renderFooter={renderMenuFooter}
          renderOption={renderOption}
          outerRef={menuContainerRef}
          innerRef={menuRef}
          innerProps={{
            onMouseDown: handleMouseDownMenu,
            onMouseMove: handleMouseMoveMenu,
            style: { marginTop: theme.unit, maxHeight, overflow: 'scroll' },
          }}
          {...commonStateProps}
        />
      )}
    </SelectOptionInputContainer>
  );
};

Select.defaultProps = {
  labelProperties: {},
  noOptionsMessage: 'No matches found',
  searchPlaceholder: 'Search options',
  placeholder: 'Make a selection',
  isClearable: false,
  isDisabled: false,
  isSearchable: true,
  maxHeight: 200,
  blurInputOnSelect: isTouchCapable(),
  captureMenuScroll: !isTouchCapable(),
  menuShouldBlockScroll: true,
  multiSelect: false,

  value: '',
  options: [],

  onMenuScrollToBottom: () => {},
  onMenuScrollToTop: () => {},
  onChange: () => {},
  onInputChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  onScrollEnd: () => {},
};

const SelectOptionInput = ({
  // event handlers
  onChange,
  onInputChange,
  onFocus,
  onBlur,
  onMenuScrollToBottom,
  onMenuScrollToTop,

  // Input Options
  value,
  options,
  placeholder,
  editable,
  isClearable,
  isSearchable,
  noOptionsMessage,
  multiSelect,

  // Label Options
  hint,
  count,
  error,
  label,

  // Style Options
  cssStyle,
  className,
  noMargin,
  maxHeight,

  // custom components
  renderMenuFooter,
}) => {
  const [focus, setFocus] = useState(false);

  const handleChange = (options) => {
    onChange(options);
  };

  const handleOnFocus = () => {
    onFocus();
    setFocus(true);
  };

  const handleOnBlur = (event) => {
    onBlur(event);
    setFocus(false);
  };

  // const selectedValue = options.filter(option => option.value === value)[0];

  const labelProperties = {
    label,
    hint,
    count,
    error,
    focus,
    editable,
    className,
    cssStyle,
    noMargin,
  };

  return (
    <div className={css(styles.selectInputContainer)}>
      {!editable && (
        <Label {...labelProperties}>
          <p className={css(styles.textInput, styles.textInputLabelStatic)}>
            {value}
          </p>
        </Label>
      )}

      {editable && (
        <Label
          {...labelProperties}
          unstyled={true}
          style={{
            flexGrow: 1,
            maxWidth: '100%',
          }}
        >
          <Select
            labelProperties={labelProperties}
            noOptionsMessage={noOptionsMessage}
            placeholder={placeholder}
            isClearable={isClearable}
            isDisabled={!editable}
            isSearchable={isSearchable}
            maxHeight={maxHeight}
            multiSelect={multiSelect}
            value={value}
            options={options}
            onSearchInputChange={onInputChange}
            onChange={handleChange}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
            onMenuScrollToBottom={onMenuScrollToBottom}
            onMenuScrollToTop={onMenuScrollToTop}
            renderMenuFooter={renderMenuFooter}
          />
        </Label>
      )}
    </div>
  );
};

SelectOptionInput.propTypes = {
  noOptionsMessage: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.any,
  multiSelect: PropTypes.bool,

  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onInputChange: PropTypes.func,
  onMenuScrollToTop: PropTypes.func,
  onMenuScrollToBottom: PropTypes.func,

  isSearchable: PropTypes.bool,
  editable: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    }),
  ),

  renderMenuFooter: PropTypes.func,
  renderMenuHeader: PropTypes.func,
};

SelectOptionInput.defaultProps = {
  noOptionsMessage: () => 'No matches found.',
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  onInputChange: () => {},

  onMenuScrollToTop: () => {},
  onMenuScrollToBottom: () => {},

  renderMenuFooter: () => null,
  renderMenuHeader: () => null,

  multiSelect: false,
  isSearchable: true,
  isClearable: true,
  editable: true,
  options: [],
};

export default SelectOptionInput;
