import React from 'react';
import { startCase } from 'lodash';
import { css } from 'aphrodite';

/* Hooks */
// import useMst from '~/src/hooks/useMst';
import useTemplatesPagination from '~/src/hooks/useTemplatesPagination';

/* Components */
import Card from '~/src/components/Card';
import { SelectInput, StaticText } from '~/src/components/Inputs';
import VariablesEntityFieldCardsList from '~/src/components/VariablesEntityFieldCardsList';

import styles from './styles';

const ChooseTemplatesForm = ({
  templateId,
  questionTag,
  questionLabel,
  variables,
  onFieldChange,
  onTemplateChange,
  onRemoveQustionTag,
}) => {
  const { data, setSearch } = useTemplatesPagination();

  const selectOptions = data.map((template) => ({
    value: `${template.id}`,
    label: template.title,
  }));

  const handleTemplateChange = (templateId) => {
    onTemplateChange(templateId);
  };

  const handleFieldClick = (field) => {
    onFieldChange(field);
  };

  const handleInputChange = (value, { action }) => {
    if (action === 'input-change') {
      setSearch(value);
    }
  };

  const fieldValue = questionTag && startCase(questionTag.split('.').join(' '));

  return (
    <>
      <Card dynamic cssStyle={styles.slideInCard}>
        <StaticText label="Question" value={questionLabel} />
        <SelectInput
          options={selectOptions}
          value={templateId || null}
          label={'Template'}
          onChange={handleTemplateChange}
          onInputChange={handleInputChange}
        />
        <StaticText
          label="Field"
          value={fieldValue}
          placeholder="Select a field to map to"
        />
        {fieldValue && (
          <a
            className={css(styles.clearFieldLink)}
            onClick={onRemoveQustionTag}
          >
            Clear Mapping
          </a>
        )}
      </Card>
      <VariablesEntityFieldCardsList
        variables={variables}
        selectedField={{ tag: questionTag }}
        onFieldClick={handleFieldClick}
      />
    </>
  );
};

export default ChooseTemplatesForm;
