import React, { useState, useEffect } from 'react';
import { css } from 'aphrodite';
import cns from 'classnames';

import styles, { ANIMATION_DURATION } from './styles';

export type OverlayProps = {
  show: boolean;
  onCancel: () => void;
  onAfterClose?: () => void;
  children: React.ReactNode;
  disableClickCatcher?: boolean;
};

const Overlay = ({
  disableClickCatcher,
  children,
  onAfterClose,
  onCancel,
  show,
}: OverlayProps) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (animate) {
      return () => {
        if (!show) onAfterClose?.();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animate, show]);

  useEffect(() => {
    if (show) {
      setAnimate(true);
    } else {
      const timeout = setTimeout(() => {
        setAnimate(false);
      }, ANIMATION_DURATION);

      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    }
  }, [show]);

  return (
    <div
      className={cns(
        'fixed flex inset-0 z-[11] items-center justify-center',
        {
          'top-full left-full truncate': !animate,
        },
        css(!show && styles.slideInOverlayContainerHiddenDelay),
      )}
    >
      <div
        className={css(
          styles.overlayClickCatcher,
          show && styles.overlayClickCatcherShow,
        )}
        onClick={!disableClickCatcher ? onCancel : undefined}
      />
      <div
        className={cns(
          'flex min-w-full h-screen sm:h-auto sm:min-w-[400px] sm:min-h-[150px] mx-0 my-auto bg-white rounded-md p-6',
          css(
            !show && styles.overlayCardAnimationOut,
            show && styles.overlayCardAnimationIn,
          ),
        )}
      >
        {!animate && !show ? null : children}
      </div>
    </div>
  );
};

export default Overlay;
