import React from 'react';
import { css } from 'aphrodite';

import OnOffIcon from '~/src/components/OnOffIcon';

import styles from './styles';

const EnabledDisabled = ({ enabled }) => (
  <div>
    <OnOffIcon on={enabled} />
    <span className={css(styles.text, !enabled && styles.textDisabled)}>
      {enabled && 'Enabled'}
      {!enabled && 'Disabled'}
    </span>
  </div>
);

EnabledDisabled.defaultProps = {
  enabled: true,
};

export default EnabledDisabled;
