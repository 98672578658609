import { snakeCase } from 'lodash';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useFieldDescriptors } from '~/src/pages/Populate/ProjectProvider';

/**
 * @description useWatchContactDataFields accepts an array of field names and returns
 * a formatted object using the snake_case'd labels of the fields as the
 * keys and the values of those fields as the values. This is the contact's "data map"
 * @param fields string[] - an array of field names like those from group.fields
 * @returns Record<string,string> - the data that gets attached to a contact
 */
export const useWatchContactDataFields = (fields: string[]) => {
  const descriptors = useFieldDescriptors(fields);
  const values = useWatch({ name: fields });

  return useMemo(() => {
    const labels = descriptors.map(({ label }) => snakeCase(label));
    return values.reduce<Record<string, string>>((acc, value, index) => {
      acc[labels[index]!] = value;
      return acc;
    }, {});
  }, [values, descriptors]);
};
