import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

export default StyleSheet.create({
  rowIcon: {
    marginRight: `${theme.unit * 2}px`,
  },
  childRow: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.fonts.size.small,
  },
  childRowActions: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexFlow: 'row',
    display: 'flex',
  },
  childRowActionButton: {
    marginRight: theme.unit,
    ':last-of-type': {
      marginRight: 0,
    },
  },
  childRowActionsSeperator: {
    width: '1px',
    background: theme.colors.smoke,
    height: `${theme.unit * 2}px`,
    marginRight: theme.unit,
    display: 'inline-block',
  },
  rowActionButton: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.unit * 1}px ${theme.unit * 2}px`,
    paddingRight: theme.unit,
  },
});
