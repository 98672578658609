/* Libraries */
import React, { FC } from 'react';
import { useObserver } from 'mobx-react';

/* Hooks */
import useMst from '~/src/hooks/useMst';
import usePdfCourtFormsActions from '~/src/hooks/usePdfCourtFormsActions';

/* Components */
import PdfTableWrapper, {
  PdfCourtForm,
} from '~/src/components/CourtForms/PdfTableWrapper';
import { stateCodeToStateName } from '~/src/utils/stateCodeToStateName';
import { TOTAL_DOCUMENT_LIMIT } from '../utils/constants';

/* Types */

interface Props {
  filterCategory: keyof typeof stateCodeToStateName;
  locality?: string;
  routeUrl?: string;
  onPreviewTemplate?: (template: PdfCourtForm) => void;
}

const PdfTableContainer: FC<Props> = ({
  filterCategory,
  locality,
  routeUrl = '/library/court-forms',
  onPreviewTemplate,
}) => {
  const { sidebarItemTotalNumber, containedInSidebar } = useMst((store) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useObserver(() => ({
      sidebarItemTotalNumber: store.sidebarItems.items.length,
      containedInSidebar: store.sidebarItems.containedInSidebar,
    })),
  );

  const {
    addTemplateToSeletionSidebar: handleAddTemplateToSeletionSidebar,
    previewTemplate: handlePreviewTemplate,
    createFormTemplate: handleCreateFromTemplate,
  } = usePdfCourtFormsActions();

  const pdfRowActions = {
    previewFormTemplate: handlePreviewTemplate,
    createFormTemplate: handleCreateFromTemplate,
  };

  const breadcrumbData: Record<string, string>[] = [
    {
      label: 'Court forms',
      url: routeUrl,
    },
  ];

  if (filterCategory === 'FEDERAL') {
    breadcrumbData.push({
      label: 'USCIS forms',
      url: `${routeUrl}/federal_USCIS`,
    });
  } else {
    // filterCategory is stateCode
    const stateName = stateCodeToStateName[filterCategory];
    if (locality && locality !== 'None') {
      // from region list
      breadcrumbData.push({
        label: `${stateName} regional forms`,
        url: `${routeUrl}/${filterCategory}/locality-listing`,
      });
      breadcrumbData.push({
        label: locality,
      });
    } else {
      breadcrumbData.push({
        label: `${stateName} forms`,
      });
    }
  }

  return (
    <PdfTableWrapper
      stateCode={filterCategory}
      locality={locality}
      disableAddButton={sidebarItemTotalNumber >= TOTAL_DOCUMENT_LIMIT}
      breadcrumbData={breadcrumbData}
      rowActions={onPreviewTemplate ? undefined : pdfRowActions}
      containedInSidebar={containedInSidebar}
      handleAddTemplateToSeletionSidebar={handleAddTemplateToSeletionSidebar}
      handlePreviewTemplate={(template: PdfCourtForm) =>
        onPreviewTemplate?.(template) ?? handlePreviewTemplate(template)
      }
    />
  );
};

export default PdfTableContainer;
