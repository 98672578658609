import { Box, CircularProgress, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import {
  BillingCycleType,
  getPlanPrice,
  SummaryItem,
} from '~/src/entities/subscription';
import { Refresh } from '@mui/icons-material';

type NewPlanPriceProps = {
  isLoading: boolean;
  price: number | undefined;
  billingCycle: BillingCycleType;
  refetch: () => void;
  errorMessage?: string;
  label: string;
};

export const PlanPrice = ({
  isLoading,
  price,
  billingCycle,
  refetch,
  errorMessage = 'Error',
  label,
}: NewPlanPriceProps) => {
  if (price)
    return (
      <SummaryItem
        name={label}
        price={getPlanPrice(price / 100, billingCycle)}
      />
    );

  if (isLoading)
    return (
      <SummaryItem name={'New plan'} price={<CircularProgress size={15} />} />
    );

  return (
    <SummaryItem
      name={'New plan'}
      price={
        <Box display={'flex'} alignItems={'center'}>
          <Box pr={1}>{errorMessage}</Box>
          <Tooltip title={'Retry'}>
            <IconButton onClick={refetch}>
              <Refresh />
            </IconButton>
          </Tooltip>
        </Box>
      }
    />
  );
};
