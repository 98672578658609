import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

export default StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    ':hover': {
      cursor: 'pointer',
    },
  },
  avatar: {
    width: 36,
    height: 36,
    borderRadius: 36,
    border: theme.border,
    background: theme.surface,
  },
});
