import { APIError } from '~/src/utils/error';
import { getDuplicateQuestionnaireUrl } from '~/src/entities/questionnaires/urls';
import {
  getClioQuestionnaireAuthToken,
  questionnaireAuthorizationHeaders,
} from '../utils';
import { type Questionnaire } from '@clio/questionnaire-builder';
import { useMutation } from 'react-query';
import { useInvalidateQuery } from '../../utils';
import { questionnaireQueryArgs } from '../queryArgs';

type DuplicateClioQuestionnaire = {
  questionnaireId: string;
  title: string;
  description?: string;
};

export const useDuplicateQuestionnaire = (orgFprint: string) =>
  useMutation<Questionnaire, APIError, DuplicateClioQuestionnaire>({
    onSuccess: useInvalidateQuery(questionnaireQueryArgs.all),
    mutationFn: async ({ questionnaireId, title, description = '' }) => {
      const token = await getClioQuestionnaireAuthToken(orgFprint);
      const response = await fetch(
        getDuplicateQuestionnaireUrl(questionnaireId),
        {
          method: 'POST',
          headers: {
            ...questionnaireAuthorizationHeaders(token),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ title, description }),
        },
      );
      if (!response.ok)
        throw new APIError('Failed to duplicate questionnaire', response);

      return response.json();
    },
  });
