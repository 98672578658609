/* Libraries */
import React from 'react';

/* Components */
import QuestionnaireResponses from '~/src/components/QuestionnaireResponses';

/* Utilities */
import { mapQuestionnaireResponseToTable } from '~/src/utils/dataTransformers';
import { history } from '~/src/utils/history';

const QuestionnaireResponsesContainer = (props) => {
  const loading = false;

  const handleEditIntakeResponse = (intake) => {
    history.push(`/questionnaire/response/${intake.id}/edit`);
  };

  const handleViewIntakeResponse = (intake) => {
    history.push(`/questionnaire/response/${intake.id}/view`);
  };

  const { columnWidths, hideHeader, title, noBorder } = props;

  const rowActions = {
    edit: handleEditIntakeResponse,
    view: handleViewIntakeResponse,
  };

  // If documents list is passed in manually, simply render that data rather then using dynamic pagination
  const tableData = props.intakes.map(mapQuestionnaireResponseToTable);

  return (
    <QuestionnaireResponses
      intakes={tableData}
      visibleItems={tableData.length}
      loading={tableData.length === 0 && loading}
      rowActions={rowActions}
      hideHeader={hideHeader}
      title={title}
      noBorder={noBorder}
      columnWidths={columnWidths}
    />
  );
};

export default QuestionnaireResponsesContainer;
