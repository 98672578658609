import React from 'react';
import { DialogContent, DialogActions, Button } from '@mui/material';

import { ImportHeader } from '../ImportHeader';
import { ReactComponent as IconError } from '~/src/static/icon-error.svg';

type ErrorContentProps = {
  onClose: () => void;
};

const ErrorContent = ({ onClose }: ErrorContentProps) => {
  return (
    <>
      <ImportHeader
        onClose={onClose}
        title={
          <>
            <IconError style={{ marginRight: '16px' }} />
            Failed to Import
          </>
        }
      />
      <DialogContent dividers={true}>
        <div style={{ marginBottom: 24 }}>
          We were unable to import from Clio Manage. Please check your Clio
          matter permissions and try again.
        </div>
        <div>If the problem persists, reach out to customer support.</div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </>
  );
};

export default ErrorContent;
