import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import Table from '~/src/components/Table';

const defaultState = {
  sortBy: '',
  sortOrder: 0,
  filter: '',
  rowCount: 10,
};

const TableContainer = (props) => {
  const {
    data,
    columns,
    childColumns,
    childRowStyle,
    rowStyle,
    renderActions,
    searchPlaceholder,
    emptyTitle,
    empty,
    stackActions,
    hideHeader,
    noBorder,
    hideColumnHeaders,
    visibleItems,
    isLoading,
    rowClickable,
    onRowClick,

    onSearchChange,
    onSortByChange,
    isSortByDisabled,
    customButton,
  } = props;

  const [sortedData, setSortedData] = useState([...data]);
  const [sortBy, setSortBy] = useState(defaultState.sortBy);
  const [sortOrder, setSortOrder] = useState(defaultState.sortOrder);
  const [filter, setFilter] = useState(defaultState.filter);
  const [rowCount, setRowsCount] = useState(defaultState.rowCount);

  useEffect(() => setSortedData(data.slice(0, rowCount)), [data, rowCount]);

  useEffect(() => {
    if (onSearchChange) {
      onSearchChange(filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    if (onSortByChange) {
      onSortByChange(sortBy, sortOrder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, sortOrder]);

  // TODO: Handle sorting for dates
  useEffect(() => {
    if (onSearchChange || onSortByChange) {
      setSortedData(data);
    } else {
      let result = [...data];

      if (filter.length === 0) {
        result = [...data];
      } else {
        result = result.filter((data) => {
          const search = Object.values(data)
            .map((value) => {
              return typeof value === 'string' ? value : '';
            })
            .join(' ')
            .toLowerCase();

          return search.indexOf(filter.toLowerCase()) !== -1;
        });
      }

      result = result.sort((a, b) => {
        if (sortOrder === 0) {
          return 0;
        }

        const aValue =
          typeof a[sortBy] === 'string' ? a[sortBy].toLowerCase() : a[sortBy];
        const bValue =
          typeof b[sortBy] === 'string' ? b[sortBy].toLowerCase() : b[sortBy];

        const isDate = moment(aValue).isValid() && moment(bValue).isValid();

        if (isDate) {
          const aDate = moment(aValue);
          const bDate = moment(bValue);
          if (aDate.isSame(bDate)) {
            return 0;
          }

          return aDate.isBefore(bDate) ? sortOrder : -sortOrder;
        }

        if (aValue < bValue) {
          return sortOrder;
        }

        if (aValue > bValue) {
          return -sortOrder;
        }

        return 0;
      });

      setSortedData(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, sortOrder, filter, data]);

  const handleSetSortBy = (value) => {
    let nextValue = value;

    if (sortBy !== value) {
      setSortOrder(1);
      setSortBy(value);
      return;
    }

    if (sortOrder === 0) {
      setSortOrder(1);
    } else if (sortOrder === 1) {
      setSortOrder(-1);
    } else {
      setSortOrder(0);
      nextValue = '';
    }

    setSortBy(nextValue);
  };

  const handleShowMore = () => {
    setRowsCount(rowCount + defaultState.rowCount);
  };

  const slicedData = sortedData.slice(0, visibleItems);
  let tableEmpty = empty;

  if (!onSearchChange && !onSortByChange) {
    tableEmpty = slicedData.length === 0;
  }

  return (
    <Table
      noBorder={noBorder}
      stackActions={stackActions}
      hideHeader={hideHeader}
      hideColumnHeaders={hideColumnHeaders}
      emptyTitle={emptyTitle}
      empty={tableEmpty}
      isLoading={isLoading}
      data={data}
      sortedData={slicedData}
      columns={columns}
      childColumns={childColumns}
      childRowStyle={childRowStyle}
      rowStyle={rowStyle}
      sortOrder={sortOrder}
      isSortByDisabled={isSortByDisabled}
      sortBy={sortBy}
      filter={filter}
      setFilter={setFilter}
      onShowMore={handleShowMore}
      setSortBy={handleSetSortBy}
      setSortOrder={setSortOrder}
      renderActions={renderActions}
      searchPlaceholder={searchPlaceholder}
      onRowClick={onRowClick}
      rowClickable={rowClickable}
      customButton={customButton}
    />
  );
};

TableContainer.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  emptyTitle: PropTypes.string,
  empty: PropTypes.bool,
  stackActions: PropTypes.bool,
  hideHeader: PropTypes.bool,
  noBorder: PropTypes.bool,
  hideColumnHeaders: PropTypes.bool,
  rowClickable: PropTypes.bool,
  visibleItems: PropTypes.number,
  isSortByDisabled: PropTypes.bool,

  searchPlaceholder: PropTypes.string,

  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      dataKey: PropTypes.string,
      hideLabel: PropTypes.bool,
      style: PropTypes.object,
      render: PropTypes.func,
    }),
  ),

  renderActions: PropTypes.func,
  onRowClick: PropTypes.func,
  customButton: PropTypes.element,

  onSearchChange: PropTypes.func,
  onSortByChange: PropTypes.func,
};

TableContainer.defaultProps = {
  stackActions: false,
  empty: false,
  visibleItems: 10,
  noBorder: false,
  rowClickable: false,
  searchPlaceholder: 'Search',
  emptyTitle: 'No data',
  childRowStyle: {},
  rowStyle: {},
  onRowClick: () => {},
  customButton: null,
};

export default TableContainer;
