/* Libraries */
import React, { useState } from 'react';

/* Components */
import { ViewDocument } from './ViewDocument';
import { ViewDocumentSet } from './ViewDocumentSet';
import Overlay from '~/src/components/Overlay';
import PageLayout, { LoadingOverlay } from '~/src/components/PageLayout';

/* Hooks */
import { useStore } from '~/src/hooks/useMst';

/* Constants */
import { history } from '../../utils/history';
import ViewDocumentFooter from './ViewDocumentFooter';

const DownloadingOverlay = ({ show }) => (
  <Overlay alpha={0.9} show={show}>
    <LoadingOverlay
      title="Downloading documents"
      subtitle="This may take a few seconds"
    />
  </Overlay>
);

const EsignLoadingOverlay = ({ show }) => (
  <Overlay alpha={0.9} show={show}>
    <LoadingOverlay title="Loading eSign" />
  </Overlay>
);

export const ViewDocumentPage = (props) => {
  const { projectId, documentId } = props;
  const { projects, signatures } = useStore();

  const [showHighlights, setShowHighlights] = useState(true);
  const [esignIsLoading, setEsignIsLoading] = useState(false);
  const [project] = useState(() => {
    const project = projects.getProject(projectId);

    if (!project) {
      history.replace(`/populate/${projectId}`);
      return null;
    }

    return project;
  });

  if (!project) {
    return null;
  }

  const document = project.getDocument(documentId);
  const handleDownload = () => {
    if (project && project.isDocumentSet()) {
      project.exportDocSet(showHighlights);
    } else {
      document.downloadDocument(showHighlights);
    }
  };

  const handleEdit = () => {
    const { projectId } = props;
    history.replace(`/populate/${projectId}`);
  };

  const handleSign = async () => {
    setEsignIsLoading(true);
    const createdSignaturePackage = await signatures.createSignaturePackage(
      [],
      projectId,
    );
    if (createdSignaturePackage) {
      setEsignIsLoading(false);
      history.push(`/signatures/prox/${createdSignaturePackage.id}/new`);
    }
  };

  const handleToggleShowHighlights = () => {
    setShowHighlights(!showHighlights);
  };

  const footer = (
    <ViewDocumentFooter
      onDownload={handleDownload}
      onEdit={handleEdit}
      onSign={handleSign}
      showHighlights={showHighlights}
      onToggleShowHighlights={handleToggleShowHighlights}
    />
  );

  if (project.isDocumentSet()) {
    return (
      <PageLayout footer={footer}>
        <ViewDocumentSet
          documentSet={project}
          onDownload={handleDownload}
          onEdit={handleEdit}
          showHighlights={showHighlights}
          onToggleShowHighlights={handleToggleShowHighlights}
        />
        <DownloadingOverlay show={project.isDownloading} />
        <EsignLoadingOverlay show={esignIsLoading} />
      </PageLayout>
    );
  }
  return (
    <PageLayout footer={footer}>
      <ViewDocument
        document={document}
        onDownload={handleDownload}
        onEdit={handleEdit}
        showHighlights={showHighlights}
        onToggleShowHighlights={handleToggleShowHighlights}
      />
      <EsignLoadingOverlay show={esignIsLoading} />
    </PageLayout>
  );
};
