import { useMutation, UseMutationOptions } from 'react-query';
import { APIError } from '~/src/utils/error';
import { Customer } from '../types';
import { useCurrentOrgFprint } from '../../user';
import {
  composeMutationOpts,
  fetchAndParse,
  useInvalidateQuery,
} from '../../utils';
import { keys } from '../keys';
import { getCustomerUrl } from '../urls';
import { useCreateStripeCardToken } from './useCreateStripeCardToken';

type Params = {
  card: stripe.StripeCardTokenData;
};

export const useUpdatePaymentMethod = (
  options: UseMutationOptions<Customer, APIError, Params> = {},
) => {
  const orgFprint = useCurrentOrgFprint();
  const { createCardTokenAsync } = useCreateStripeCardToken();
  return useMutation(
    composeMutationOpts(options, {
      onSuccess: useInvalidateQuery(keys.subscription),
      mutationFn: async ({ card }: Params) => {
        const { id } = await createCardTokenAsync(card);
        return fetchAndParse(getCustomerUrl(orgFprint), {
          method: 'PATCH',
          body: JSON.stringify({ 'card-token': id }),
        });
      },
    }),
  );
};
