import { useQuery, UseQueryOptions, UseMutationOptions } from 'react-query';
import { APIError } from '~/src/utils/error';
import clioService, {
  ClioMatter,
  MatterQueryParameters,
} from '~/src/services/api/clioService';
import {
  AsyncResultResponse,
  AsyncResultStatus,
} from '~/src/entities/project/types';
import { composeQueryOpts, useInvalidateQuery } from '../utils';
import { keys } from './keys';
import analyticsService from '~/src/services/analytics';
import { useCurrentOrgFprint } from '../user';
import { useLayoutContext } from '~/src/contexts/Layout';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import { useSyncSingleMatterTask } from '~/src/hooks/useSyncSingleMatterTask';
import { usePollingTaskStatus } from '~/src/hooks/usePollingTaskStatus';
import { useStore } from '~/src/hooks/useMst';
import { Instance } from 'mobx-state-tree';
import { MatterStore } from '~/src/stores/matterStore';
import matterService from '~/src/services/matter';
import { useEffect, useState } from 'react';

type UseSyncSingleMatterVars = {
  clio_matter_id: string;
};

export const useInvalidateClioMatters = () => useInvalidateQuery(keys.all);

export const useClioMatters = (
  params: MatterQueryParameters,
  options: UseQueryOptions<
    ClioMatter[],
    APIError,
    ClioMatter[],
    ReturnType<typeof keys.list>
  > = {},
) => {
  const orgFprint = useCurrentOrgFprint();
  return useQuery(
    composeQueryOpts(options, {
      keepPreviousData: true,
      queryKey: keys.list(params),
      queryFn: async ({ queryKey }) => {
        const { state } = queryKey[1];
        const start = Date.now();
        const result = await clioService.searchMatter(orgFprint, state);
        analyticsService.track('Clio Matter Search Time', {
          time: Date.now() - start,
        });
        return result;
      },
    }),
  );
};

export const useSyncSingleMatter = (
  analyticsEventName: String,
  successToastMessage: String,
  clioMatterId: number | string | undefined,
  hide: () => void,
  onSynced?: () => void,
  onMatterSelected?: (matterId: string) => void,
  options: UseMutationOptions<
    AsyncResultResponse,
    APIError,
    UseSyncSingleMatterVars
  > = {},
) => {
  const [taskId, setTaskId] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const orgFprint = useCurrentOrgFprint();
  const store = useStore();
  const matterStore: Instance<typeof MatterStore> = store.matter;
  const { showToast } = useLayoutContext();

  const {
    mutateAsync: sync,
    isError: initTaskError,
    error,
    reset,
  } = useSyncSingleMatterTask(options);

  const { data, isError: pollError } = usePollingTaskStatus(taskId);

  useEffect(() => {
    const fetchMatter = async (matter_id: string) => {
      const { matter } = await matterService.fetchMatter(orgFprint, matter_id);
      if (matterStore.getMatter(matter.id)) {
        matterStore.updateMatter(matter.id, { ...matter });
      } else {
        matterStore.setMatter(matter);
      }
      if (onMatterSelected) {
        onMatterSelected(`${matter.id}`);
      }
    };
    const clearPollingAndModal = () => {
      setIsLoading(false);
      setTaskId('');
      hide();
    };
    if (data) {
      const { status, result } = data;
      if (status === AsyncResultStatus.SUCCESS) {
        if (onSynced) {
          onSynced();
        } else if (result?.matter_id) {
          fetchMatter(result?.matter_id).then(() => {
            clearPollingAndModal();
          });
        } else {
          clearPollingAndModal();
          showToast(LAYOUT_TOAST_TYPES.error, {
            message: 'Something went wrong. Please try again later.',
          });
        }
        analyticsService.track(`${analyticsEventName} - Success`, {
          clioMatterId,
        });
        showToast(LAYOUT_TOAST_TYPES.success, {
          message: successToastMessage,
        });
      } else if (status === AsyncResultStatus.FAILURE) {
        analyticsService.track(`${analyticsEventName} - Failure`, {
          clioMatterId,
        });
        showToast(LAYOUT_TOAST_TYPES.error, {
          message: 'Something went wrong. Please try again later.',
        });
        clearPollingAndModal();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (initTaskError) {
      setIsLoading(false);
      setTaskId('');
    }
  }, [initTaskError]);

  return {
    isLoading: isLoading,
    isError: initTaskError || pollError,
    isSuccess: !!data && data.status === AsyncResultStatus.SUCCESS,
    error,
    sync,
    setTaskId,
    setIsLoading,
    reset,
    data,
  };
};
