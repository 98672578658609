import React from 'react';
import { Link } from 'react-router-dom';
import analyticsService from '~/src/services/analytics';

type AddDocumentButtonProps = {
  projectId: string;
};

export const AddDocumentButton = ({ projectId }: AddDocumentButtonProps) => {
  return (
    <div className="ml-2 mt-2" style={{ fontWeight: 500 }}>
      <Link
        onClick={() => {
          analyticsService.track('Add Documents - Clicked');
        }}
        to={`/add-document/${projectId}`} // Add link to addDocument route
      >
        + Add documents
      </Link>
    </div>
  );
};
