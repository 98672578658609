import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { X, Download } from 'react-feather';
import { saveAs } from 'file-saver';

import UploadTextArea from '~/src/components/UploadTextArea';
import AddFile from '~/src/components/AddFile';
import Label from '~/src/components/Inputs/Label';

import { css, StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

const acceptedDocs = ['.doc', '.docx', '.pdf', '.jpg', '.png'];

const styles = StyleSheet.create({
  fileListContainer: {
    marginTop: theme.unit * 2,
    marginBottom: theme.unit * 2,
    ':hover': {
      cursor: 'default',
    },
  },
  fileListItem: {
    maxWidth: '80px',
    margin: `0 ${theme.unit}px`,
    display: 'inline-block',
    verticalAlign: 'top',
    marginBottom: theme.unit * 2,
  },
  fileListAddFile: {
    position: 'relative',
    width: '80px',
    height: '110px',
    background: theme.colors.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.borderRadius,
    border: theme.border,
    borderColor: theme.colors.bluebird,
    borderStyle: 'dashed',
  },
  fileListPreview: {
    position: 'relative',
    width: '80px',
    height: '110px',
    background: 'rgba(24, 143, 249, .06)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.borderRadius,
    border: theme.border,
    borderColor: theme.colors.bluebird,
  },
  fileListPreviewImage: {
    maxWidth: '90%',
    maxHeight: '90%',
  },
  fileListPreviewRemoveIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: -10,
    right: -10,
    background: theme.colors.paleTomato,
    width: 20,
    height: 20,
    borderRadius: 10,
    zIndex: 2,
    ':hover': {
      cursor: 'pointer',
    },
  },
  fileListDownloadOverlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    background: theme.colors.bluebird,
    borderRadius: theme.borderRadius,
    border: theme.border,
    borderColor: theme.colors.bluebird,
    opacity: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    transition: 'opacity 350ms ease',
    ':hover': {
      opacity: 0.9,
      cursor: 'pointer',
    },
  },
  fileListDownloadOverlayShow: {
    display: 'fixed',
  },
});

const FileList = ({
  files,
  onRemoveFile,
  maxFiles,
  onAddFile,
  acceptedDocumentTypes,
}) => {
  if (files.length === 0) {
    return null;
  }

  const showAddFile = maxFiles > files.length;

  const getFileNamExtentions = (name) => {
    const splitName = name.split('.');
    const ext = splitName[splitName.length - 1];
    return ext;
  };

  const handleFileMouseDown = (file) => {
    if (file.path) {
      saveAs(file.path, file.name);
    }
  };

  const renderFile = (file) => {
    const canDownloadFile = !!file.path;

    return (
      <li key={file.id} className={css(styles.fileListItem)}>
        <div
          className={css(styles.fileListPreview)}
          data-tip
          data-for={`file-upload-file-${file.id}`}
        >
          <i
            className={css(styles.fileListPreviewRemoveIcon)}
            onClick={onRemoveFile.bind(this, file)}
          >
            <X color={theme.colors.white} size={10} />
          </i>
          {canDownloadFile && (
            <div
              className={css(styles.fileListDownloadOverlay)}
              onMouseDown={handleFileMouseDown.bind(this, file)}
            >
              <Download color={theme.colors.white} size={20} />
            </div>
          )}
          <div>.{getFileNamExtentions(file.name)}</div>
        </div>
        <div style={{ marginTop: theme.unit * 2 }}>
          <div
            style={{
              color: theme.colors.bluebird,
              wordBreak: 'break-word',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {file.name}
          </div>
          <ReactTooltip
            id={`file-upload-file-${file.id}`}
            place="bottom"
            effect="solid"
          >
            <div
              style={{
                color: theme.colors.white,
              }}
            >
              {file.name}
            </div>
          </ReactTooltip>
        </div>
      </li>
    );
  };

  const handleAddFileChange = (files) => {
    onAddFile(files[0]);
  };

  return (
    <div className={css(styles.fileListContainer)}>
      <ul>
        {files.map(renderFile)}
        {showAddFile && (
          <li className={css(styles.fileListItem)} key={'add-file'}>
            <AddFile
              onChange={handleAddFileChange}
              maxFiles={1}
              multiple={false}
              acceptedDocs={acceptedDocumentTypes}
            />
          </li>
        )}
      </ul>
    </div>
  );
};

const FileUpload = (props) => {
  const {
    label,
    hint,
    count,
    error,
    editable,
    required,
    labelCssStyle,
    noMargin,
    value,
    onChange,
    fileCountLimit,
    acceptedDocumentTypes,
  } = props;

  const [files, setFiles] = useState(value || []);
  const [localError, setLocalError] = useState();

  const handleSetFiles = (filesProp) => {
    let nextFiles = filesProp;

    if (nextFiles.length > fileCountLimit) {
      nextFiles = filesProp.slice(0, fileCountLimit);
    }

    onChange(nextFiles);
    setFiles(nextFiles);
    setLocalError(null);
  };

  const handleRemoveFile = (file) => {
    const filteredFiles = files.filter((currentFile) => {
      return file.id !== currentFile.id;
    });

    handleSetFiles(filteredFiles);
  };

  const handleAddFile = (file) => {
    const nextFiles = [...files, file];
    handleSetFiles(nextFiles);
  };

  const handleError = (filesList) => {
    if (filesList[0] && filesList[0].errors && filesList[0].errors[0]) {
      const { message } = filesList[0].errors[0];
      setLocalError(message);
    }
  };

  return (
    <Label
      label={label}
      hint={hint}
      count={count}
      error={localError || error}
      focus={focus}
      editable={editable}
      cssStyle={labelCssStyle}
      noMargin={noMargin}
      required={required}
    >
      {files.length === 0 && (
        <UploadTextArea
          onChange={handleSetFiles}
          onError={handleError}
          maxFiles={fileCountLimit}
          multiple={fileCountLimit > 1}
          acceptedDocs={acceptedDocumentTypes}
        />
      )}
      <FileList
        files={files}
        onAddFile={handleAddFile}
        onRemoveFile={handleRemoveFile}
        maxFiles={fileCountLimit}
        acceptedDocumentTypes={acceptedDocumentTypes}
      />
    </Label>
  );
};

FileUpload.defaultProps = {
  fileCountLimit: 3,
  acceptedDocumentTypes: acceptedDocs,
};

export default FileUpload;
