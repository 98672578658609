import React, { useState } from 'react';
import { useSyncSingleMatterTask } from '~/src/hooks/useSyncSingleMatterTask';
import ImportFromClioModal from './ImportFromClioModal';
import { ClioMatter } from '~/src/services/api/clioService';
import { useSyncSingleMatter } from '~/src/entities/clioMatters/hooks';

export const useImportFromClioModal = (
  analyticsEventName: String,
  options: Parameters<typeof useSyncSingleMatterTask>[0] = {},
  extraProps: { allowSearch?: boolean; onMatterSelect?: (id: string) => void },
) => {
  const [open, setOpen] = useState(false);
  const [selectedMatter, setSelectedMatter] = useState<ClioMatter>();

  const successToastMessage = 'Matter Imported successfully';

  const {
    isLoading,
    isError,
    isSuccess,
    sync,
    setTaskId,
    setIsLoading,
    reset,
  } = useSyncSingleMatter(
    analyticsEventName,
    successToastMessage,
    selectedMatter?.id,
    () => setOpen(false),
    undefined,
    extraProps?.onMatterSelect,
    options,
  );

  const performSync = async (clio_matter_id: string) => {
    setIsLoading(true);
    const { task_id } = await sync({ clio_matter_id });
    setTaskId(task_id);
  };

  const show = (matter?: ClioMatter) => {
    setSelectedMatter(matter);
    setOpen(true);
  };

  const onAfterClose = () => {
    setSelectedMatter(undefined);
    reset();
  };

  const modal = (
    <ImportFromClioModal
      {...extraProps}
      onSelectMatter={setSelectedMatter}
      selectedMatter={selectedMatter}
      open={open}
      sync={() => performSync(`${selectedMatter!.id}`)}
      isLoading={isLoading}
      isError={isError}
      isSuccess={isSuccess}
      onAfterClose={onAfterClose}
      onClose={() => setOpen(false)}
    />
  );

  return [{ show }, modal] as const;
};
