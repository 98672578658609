declare global {
  interface Window {
    analytics: any;
  }
}

function verifyAnalyticsProperties(
  eventName: string,
  organizationId: string | undefined,
  userId: string | undefined,
) {
  if (!window.analytics) {
    console.error('segment client library not found', { eventName });

    return false;
  }
  if (!userId) {
    console.error('userId not available', { eventName });
    return false;
  }
  if (!organizationId) {
    console.error('organizationId not available', { eventName });
    return false;
  }
  return true;
}

function camelToSnake(string: string) {
  return string
    .replace(/[\w]([A-Z])/g, function (m) {
      return `${m[0]}_${m[1]}`;
    })
    .toLowerCase();
}

function convertKeysToSnakeCase<T extends Record<string, any>>(o: T) {
  return Object.keys(o).reduce((acc, k) => {
    acc[camelToSnake(k)] = o[k];
    return acc;
  }, {} as Record<string, any>);
}

type UserData = {
  userId?: string;
  organizationId?: string;
  organizationName?: string;
};

type AnalyticsService = {
  initialize: (props: Record<string, any>, getUserData: () => UserData) => void;
  track: (eventName: string, data?: Record<string, any>) => void;
};

let _getUserData: () => UserData;

const analyticsService: AnalyticsService = {
  initialize: (initializeProperties, getUserData) => {
    _getUserData = getUserData;
    const { userId, organizationId, organizationName } = getUserData();

    if (!verifyAnalyticsProperties('initialize', organizationId, userId)) {
      return;
    }

    window.analytics.identify(userId, {
      user_id: userId,
      ...convertKeysToSnakeCase(initializeProperties),
    });

    window.analytics.group(organizationId, {
      organization_id: organizationId,
      name: organizationName,
    });
  },
  track: (eventName: string, data = {}) => {
    if (!_getUserData) {
      if (eventName === 'HTTP Request') return;
      console.error('Analytics service not initialized');
      return;
    }

    const { userId, organizationId } = _getUserData();

    if (!verifyAnalyticsProperties(eventName, organizationId, userId)) {
      return;
    }

    window.analytics.track(eventName, {
      schema_version: 1,
      user_id: userId,
      organization_id: organizationId,
      plan_type: 'prox',
      ...convertKeysToSnakeCase(data),
    });
  },
};

export default analyticsService;
