import React from 'react';
import { Heading, Text } from '~/src/components/Typography';
import { SignaturePackage, WhoSigns } from '~/src/models';
import { classNames } from '~/src/components/SignaturePackage/styles';

interface Props {
  recipients: SignaturePackage.Recipient[];
  whoSigns: WhoSigns;
  currentUserInfo: SignaturePackage.LeanRecipient;
}

const PackageSigners: React.FC<Props> = ({
  recipients,
  whoSigns,
  currentUserInfo,
}) => {
  const recipientArray = recipients.map((recipient) => {
    return (
      <div className="p-3 border rounded" key={recipient.email}>
        <Text htmlElement="p" variant="body" strong>
          {recipient.name}
        </Text>
        <Text variant="body" subdued>
          {recipient.email}
        </Text>
      </div>
    );
  });

  if (whoSigns !== WhoSigns.Others) {
    recipientArray.unshift(
      <div className="p-3 border rounded" key={currentUserInfo.email}>
        <Text htmlElement="p" variant="body" strong>
          {currentUserInfo.name}
        </Text>
        <Text variant="body" subdued>
          {currentUserInfo.email}
        </Text>
      </div>,
    );
  }

  return (
    <div className={`${classNames.card} mb-6`}>
      <div className="p-4 flex gap-3 items-center">
        <Heading>Signers</Heading>
        <div className="bg-gray-100 rounded-full w-6 h-6 flex justify-center items-center">
          <Text variant="caption" strong subdued>
            {recipientArray.length}
          </Text>
        </div>
      </div>

      <div className="p-4 pt-0 flex flex-col gap-2">{recipientArray}</div>
    </div>
  );
};

export default PackageSigners;
