import { WebViewerInstance } from '@pdftron/webviewer';
import React, { FC, useState } from 'react';
import WebViewerContext from '~/src/components/Webviewer/useWebViewerContext';
import { useWebViewerContextAPI } from './useWebViewerContextAPI';
import Webviewer, { WebviewerProps } from './Webviewer';

type WebViewerContextProviderProps = {
  webviewer?: Pick<WebviewerProps, 'cssPath' | 'jsPath' | 'licenseKey'>;
};

const WebViewerContextProvider: FC<WebViewerContextProviderProps> = ({
  children,
  webviewer = {},
}) => {
  const { webViewerContainerStyles, ...api } = useWebViewerContextAPI();
  const [instance, setInstance] = useState<WebViewerInstance>();

  return (
    <WebViewerContext.Provider value={{ ...api, instance }}>
      <Webviewer
        {...webviewer}
        styles={webViewerContainerStyles}
        setInstance={setInstance}
      />
      {children}
    </WebViewerContext.Provider>
  );
};

export default WebViewerContextProvider;
