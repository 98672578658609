import React, { FC } from 'react';
import { DRAGGABLE_TYPES } from '~/src/components/SignaturePackage/constants';
import { Heading } from '~/src/components/Typography';
import { FileUpload } from '~/src/models';
import { DraggableDocumentsList } from '../SignaturePackage/DraggableRecipients';
import UploadDocumentBox from './UploadDocumentBox';
import { classNames } from '../SignaturePackage/styles';

interface Props {
  documents: FileUpload.Document[];
  setDocuments: React.Dispatch<React.SetStateAction<FileUpload.Document[]>>;
  errorMessages: string[];
  setErrorMessages: React.Dispatch<React.SetStateAction<string[]>>;
  onFileUploaded: (files: File[]) => void;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onReorderDocument: (
    documents: FileUpload.Document[],
    docIds: number[],
  ) => Promise<void>;
}

const SelectDocuments: FC<Props> = ({
  documents,
  setDocuments,
  errorMessages,
  setErrorMessages,
  onFileUploaded,
  isLoading,
  setIsLoading,
  onReorderDocument,
}) => {
  const handleToggleCheckBox = (id: number) => {
    setDocuments((allDocuments) =>
      allDocuments.map((doc) =>
        doc.id === id ? { ...doc, selected: !doc.selected } : doc,
      ),
    );
  };

  const getSelectedFiles = () => {
    return documents.reduce((acc: { [key: number]: boolean }, doc) => {
      if (doc.selected) {
        acc[doc.id] = true;
      }

      return acc;
    }, {});
  };

  return (
    <div className={`${classNames.card} mt-6`}>
      <div className="p-4 pb-0">
        <Heading>Documents</Heading>
      </div>
      <DraggableDocumentsList
        draggableType={DRAGGABLE_TYPES.SIGNATURE_DOCUMENTS}
        enableDragDrop={true}
        docs={documents}
        setDocs={setDocuments}
        selectedDocumentIds={getSelectedFiles()}
        toggleCheckBox={handleToggleCheckBox}
        onReorderDocument={onReorderDocument}
      />
      <UploadDocumentBox
        errorMessages={errorMessages}
        setErrorMessages={setErrorMessages}
        onFileUploaded={onFileUploaded}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </div>
  );
};

export default SelectDocuments;
