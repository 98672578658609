import React from 'react';
import {
  DialogContent,
  DialogActions,
  Button,
  Box,
  Breadcrumbs,
  DialogTitle,
  IconButton,
  Link,
  Typography,
  Divider,
} from '@mui/material';

import ManageMatterDetail from '~/src/entities/clioMatters/components/ManageMatter/ManageMatterDetail';
import { ClioMatter } from '~/src/services/api/clioService';
import { ImportHeader } from '../ImportHeader';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

type MatterDetailContentProps = {
  selectedClioMatter: ClioMatter;
  onClose: () => void;
  onImport: () => void;
  onBack: () => void;
  showBreadcrumbs?: boolean;
};

const MatterDetailContent = ({
  selectedClioMatter,
  onClose,
  onImport,
  onBack,
  showBreadcrumbs = true,
}: MatterDetailContentProps) => {
  return (
    <>
      <ImportHeader onClose={onClose} title="Import matter from Clio Manage" />
      {showBreadcrumbs ? (
        <>
          <Divider />
          <DialogTitle>
            <Box display={'flex'} alignItems={'center'}>
              <Box marginRight={2}>
                <IconButton onClick={onBack}>
                  <ArrowBackIcon />
                </IconButton>
              </Box>

              <Breadcrumbs>
                <Link underline="hover" color="inherit" onClick={onBack}>
                  All Matters
                </Link>
                <Typography color="text.primary">
                  {selectedClioMatter.display_number}
                </Typography>
              </Breadcrumbs>
            </Box>
          </DialogTitle>
        </>
      ) : null}
      <DialogContent dividers={true}>
        <ManageMatterDetail
          showTitle={!showBreadcrumbs}
          clioMatter={selectedClioMatter}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onImport} variant="contained" autoFocus>
          Import
        </Button>
      </DialogActions>
    </>
  );
};

export default MatterDetailContent;
