import React from 'react';
import { Dialog } from '@mui/material';

import ImportFromClioModalContent from './ImportFromClioModalContent';
import { ClioMatter } from '~/src/services/api/clioService';

type ImportFromClioModalProps = {
  open: boolean;
  sync: () => void;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  allowSearch?: boolean;
  onClose: () => void;
  onAfterClose?: () => void;
  selectedMatter?: ClioMatter;
  onSelectMatter: (matter?: ClioMatter) => void;
};

const ImportFromClioModal = ({
  open,
  sync,
  isLoading,
  isError,
  isSuccess,
  onClose,
  allowSearch = true,
  onAfterClose,
  selectedMatter,
  onSelectMatter,
}: ImportFromClioModalProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll={'paper'}
      TransitionProps={{
        onExited: onAfterClose,
      }}
      PaperProps={{
        sx: {
          width: '100%',
          maxHeight: '560px!important',
          minWidth: '560px!important',
        },
      }}
    >
      <ImportFromClioModalContent
        allowSearch={allowSearch}
        isSuccess={isSuccess}
        selectedClioMatter={selectedMatter}
        setSelectedClioMatter={onSelectMatter}
        isLoading={isLoading}
        isError={isError}
        onClose={onClose}
        onImport={sync}
      />
    </Dialog>
  );
};

export default ImportFromClioModal;
