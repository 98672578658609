import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';

import { childrenPropType } from '~/src/utils/propTypes';

/* Styles */
import styles from './styles';

const Label = ({ style, children, noMargin }) => (
  <span
    className={`${css(
      styles.label,
      styles.labelTitle,
      noMargin && styles.labelNoMargin,
    )}`}
    style={style}
  >
    {children}
  </span>
);

Label.propTypes = {
  style: PropTypes.object,
  noMargin: PropTypes.bool,
  children: childrenPropType,
};

Label.defaultProps = {
  style: {},
  noMargin: false,
  children: null,
};

export default Label;
