import React from 'react';
import { Box, Button, Typography } from '@mui/material';

export const SubmissionRow: React.FC<{
  matterTitle: string;
  onDelete: () => void;
  onView: () => void;
}> = ({ matterTitle, onView, onDelete }) => (
  <Box
    sx={{
      display: 'flex',
      padding: '8px',
      alignItems: 'center',
      gap: '4px',
      alignSelf: 'stretch',
      borderRadius: '8px',
      background: 'white',
    }}
  >
    <Box sx={{ flex: '1 1 0' }}>
      <Typography variant="h2">{matterTitle}</Typography>
    </Box>
    <Button
      variant="grey"
      sx={{
        fontSize: 14,
        fontWeight: 600,
        padding: '6px 16px',
        backgroundColor: '#F3F4F6 !important',
        color: 'black',
        '&:hover': {
          backgroundColor: '#D1D5DB !important',
        },
      }}
      onClick={() => onView()}
    >
      View
    </Button>
    <Button
      variant="red"
      sx={{
        fontSize: 14,
        fontWeight: 600,
        padding: '6px 16px',
        backgroundColor: '#DC2626 !important',
        color: 'white',
        '&:hover': {
          backgroundColor: '#B91C1C !important',
        },
      }}
      onClick={onDelete}
    >
      Delete
    </Button>
  </Box>
);
