import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';

import Card from '~/src/components/Card';
import Button from '~/src/components/Button';
import EnabledDisabledStatus from '~/src/components/EnabledDisabledStatus';
import { Heading, Text } from '~/src/components/Typography';

import styles from './styles';
import { useLogout } from '~/src/entities/user';

const SettingsSecurity = ({
  email,
  twoFactorEnabled,
  onToggleTwoStepVerification,
  onChangePassword,
}) => {
  const { mutate: onLogout } = useLogout();

  return (
    <Card>
      <Heading>Security</Heading>
      <div className="mt-6 mb-4">
        <Text htmlElement="p" strong>
          Email
        </Text>
        <Text htmlElement="p">{email}</Text>
      </div>
      <div className={css(styles.settingsFlexRow)}>
        <Button
          cssStyle={styles.settingsSecurityButton}
          onClick={onChangePassword}
        >
          Change password
        </Button>
        <Button cssStyle={styles.settingsSecurityButton} onClick={onLogout}>
          Log out of all your devices and browsers
        </Button>
      </div>
      <div className="mt-6 mb-4">
        <Heading htmlElement="h3" variant="subheading">
          Two-step verification
        </Heading>
      </div>
      <div className={css(styles.settingsRow)}>
        <div className={css(styles.settingsSecurityTwoFactor)}>
          <EnabledDisabledStatus enabled={twoFactorEnabled} />
        </div>
        {!twoFactorEnabled && (
          <Button onClick={onToggleTwoStepVerification}>Enable</Button>
        )}
        {twoFactorEnabled && (
          <Button onClick={onToggleTwoStepVerification}>Disable</Button>
        )}
      </div>
    </Card>
  );
};

SettingsSecurity.propTypes = {
  email: PropTypes.string,
  twoFactorEnabled: PropTypes.bool,
  onChangePassword: PropTypes.func,
};

SettingsSecurity.defaultProps = {
  email: '',
  twoFactorEnabled: false,
  onChangePassword: () => {},
};

export default SettingsSecurity;
