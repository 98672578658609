import React from 'react';

import { ReactComponent as MiniCircleSpinner } from './mini-circle-spinner.svg';

interface MiniSpinnerProps {
  className?: string;
}

const MiniSpinner = ({ className }: MiniSpinnerProps) => (
  <MiniCircleSpinner className={className} />
);

export default MiniSpinner;
