import { UIFlags, UIFlag } from './uiFlags.context';

const keyForMembership = (membershipId: number) => {
  return `${membershipId}:UI_FLAGS`;
};

export const getDefaultFlagsForMembership = (
  membershipId?: number,
): typeof UIFlags => {
  if (!membershipId) return UIFlags;

  const savedFlags = localStorage.getItem(keyForMembership(membershipId));
  try {
    if (!savedFlags) return UIFlags;
    const parsed: Record<string, boolean> = JSON.parse(savedFlags);
    return Object.keys(parsed)
      .filter((key) => key in UIFlags)
      .reduce(
        (acc, key) => {
          const flagName = key as UIFlag;
          acc[flagName] = parsed[key] ?? acc[flagName];
          return acc;
        },
        { ...UIFlags },
      );
  } catch {
    return UIFlags;
  }
};

export const setFlagsForMembership = (
  membershipId?: number,
  flags?: Record<string, boolean>,
) => {
  if (!membershipId) return;
  localStorage.setItem(keyForMembership(membershipId), JSON.stringify(flags));
};

export const clearFlagsForMembership = (membershipId?: number) =>
  setFlagsForMembership(membershipId);
