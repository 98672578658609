import { Box, Button, CircularProgress, OutlinedInput } from '@mui/material';
import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { FormField } from '~/src/components/FormField';

type CouponCodeFormValues = {
  coupon: string;
};

type CouponCodeFormProps = {
  defaultValues?: { coupon?: string };
  onSubmit: (values: CouponCodeFormValues) => Promise<boolean>;
};

export const CouponCodeForm = ({
  onSubmit,
  defaultValues,
}: CouponCodeFormProps) => {
  const methods = useForm<CouponCodeFormValues>({
    defaultValues,
    mode: 'onSubmit',
  });

  return (
    <FormProvider {...methods}>
      <form
        noValidate
        onSubmit={methods.handleSubmit(async ({ coupon }) => {
          if (!coupon)
            return methods.setError('coupon', {
              message: 'Please enter a coupon code',
              type: '',
            });

          try {
            if (!(await onSubmit({ coupon }))) {
              methods.setError('coupon', {
                message: `${coupon} is not a valid coupon code`,
                type: '',
              });
            }
          } catch (err) {
            methods.setError('coupon', {
              message: 'An unexpected error occurred',
              type: '',
            });
          }
        })}
      >
        <Controller<CouponCodeFormValues>
          name={'coupon'}
          defaultValue={''}
          render={({ field, fieldState, formState }) => {
            return (
              <Box display={'flex'} alignItems={'flex-start'}>
                <Box flex={1} pr={2}>
                  <FormField error={fieldState.error?.message}>
                    <OutlinedInput
                      disabled={formState.isSubmitting}
                      {...field}
                      placeholder={'Apply coupon'}
                    />
                  </FormField>
                </Box>
                <Box flex={'0 0 auto'} display={'flex'}>
                  <Button
                    data-testid="submit-coupon-button"
                    disabled={formState.isSubmitting}
                    type={'submit'}
                    variant={'text'}
                  >
                    {formState.isSubmitting ? (
                      <CircularProgress size={20} />
                    ) : (
                      'Apply'
                    )}
                  </Button>
                </Box>
              </Box>
            );
          }}
        />
      </form>
    </FormProvider>
  );
};
