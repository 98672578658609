/* Libs */
import { types, destroy, getParent } from 'mobx-state-tree';
import { values } from 'mobx';
import { sortBy, find } from 'lodash';
import uuid from 'uuid/v4';

/* Stores */
import { QUESTION_TYPE_SHORT_TEXT } from '~/src/components/QuestionnaireForm/constants';
import QuestionnaireQuestionStore from './questionnaireQuestionStore';

/* Constants */

const sortQuestionGroups = (questonGroupList) =>
  sortBy(questonGroupList, ['sortOrder']);

const createQuestion = (config = {}) => {
  const sortOrder = config.sortOrder || 1;
  return {
    id: config.id || uuid(),
    editing: false,
    type: config.type || QUESTION_TYPE_SHORT_TEXT,
    sortOrder,
    label: config.label || '',
    options: config.options || [],
    tagId: config.tagId || null,
    tag: config.tag || null,
    hint: config.hint || '',
    required: config.required || false,
    uploadLimit: config.uploadLimit || '',
    uploadTypes: config.uploadTypes || [],
  };
};

const QuestionnaireQuestionGroup = types
  .model('QuestionnaireQuestionGroup', {
    id: types.maybeNull(types.string),
    identifier: types.identifier,
    name: types.string,
    sortOrder: types.integer,
    questions: types.optional(types.array(QuestionnaireQuestionStore), []),
    description: types.string,
    videoUrl: types.string,
    expanded: types.optional(types.boolean, false),
  })
  .actions((self) => {
    const setExpanded = (value) => {
      self.expanded = value;
    };

    const removeVideoUrl = () => {
      self.videoUrl = '';
    };

    const moveQuestions = (currentIndex, dropIndex) => {
      const currentSortOrder = currentIndex + 1;
      const dropSortOrder = dropIndex + 1;

      const currentQuestion = find(self.questions, [
        'sortOrder',
        currentSortOrder,
      ]);
      const dropQuestion = find(self.questions, ['sortOrder', dropSortOrder]);

      const nextSortedQuestions = [...self.sortedQuestions()].filter(
        (q) => q.id !== currentQuestion.id,
      );

      const insertBefore = dropIndex > currentIndex;
      const nextDropQuestionIndex = insertBefore
        ? dropIndex - 1
        : dropIndex + 1;

      const dropSpliceIndex =
        dropIndex === nextSortedQuestions.length ? dropIndex - 1 : dropIndex;

      nextSortedQuestions.splice(dropSpliceIndex, 1, currentQuestion);
      nextSortedQuestions.splice(nextDropQuestionIndex, 0, dropQuestion);

      nextSortedQuestions.forEach((question, index) => {
        question.setSortOrder(index + 1, { update: false });
      });

      self.update();
    };

    const setName = (name) => {
      self.name = name;
      self.update();
    };

    const setInstructions = ({ description, videoUrl }) => {
      self.videoUrl = videoUrl;
      self.description = description;

      self.update();
    };

    const createNewQuestionSet = () => {
      self.getQuestionnaireStore().createNewQuestionGroup({
        sortOrder: self.sortOrder,
      });
    };

    const duplicateQuestionSet = () => {
      self.getQuestionnaireStore().createNewQuestionGroup({
        name: `${self.name} copy`,
        sortOrder: self.sortOrder,
        questions: self.questions,
        description: self.description,
        videoUrl: self.videoUrl,
      });
    };

    const setSortOrder = (order) => {
      self.sortOrder = order;
    };

    const update = (_, options = {}) => {
      self.getQuestionnaireStore().update({}, options);
    };

    const remove = () => {
      self.getQuestionnaireStore().removeQuestionGroup(self);
    };

    const getQuestionnaireStore = () => {
      return getParent(self, 3);
    };

    const createNewQuestion = (payload = {}) => {
      if (typeof payload.sortOrder === 'undefined') {
        payload.sortOrder = self.questions.length + 1;
      }

      const newQuestion = createQuestion(payload);
      const insertionPoint = newQuestion.sortOrder;
      const sortedQuestions = self.sortedQuestions(); // .map(qg => toJS(qg));

      newQuestion.creating = true;
      newQuestion.editing = true;

      sortedQuestions.splice(insertionPoint - 1, 0, newQuestion);
      sortedQuestions.forEach((question, index) => {
        const nextSortOrder = index + 1;
        if (question.setSortOrder) {
          question.setSortOrder(nextSortOrder);
        } else {
          question.sortOrder = nextSortOrder;
        }
      });

      self.questions.replace(sortedQuestions);
      self.update(
        {},
        {
          hardRefresh: true,
          callback: () => {
            self.setExpanded(true);
            const question = self.sortedQuestions()[newQuestion.sortOrder - 1];
            question.setEditing(true);
            if (question.label.length === 0) {
              question.setLabel(`New Question ${question.sortOrder}`);
            }
          },
        },
      );
    };

    const removeQuestion = (question) => {
      self.questions.remove(question);
      self.update();
    };

    return {
      removeQuestion,
      createNewQuestion,
      getQuestionnaireStore,
      createNewQuestionSet,
      duplicateQuestionSet,
      remove,

      setExpanded,
      removeVideoUrl,
      moveQuestions,
      setSortOrder,
      setInstructions,
      setName,
      update,
    };
  })
  .views((self) => {
    const sortedQuestions = () => sortBy(self.questions, ['sortOrder']);

    return {
      sortedQuestions,
    };
  });

const QuestionnaireQuestionGroups = types
  .model({
    questionGroups: types.map(QuestionnaireQuestionGroup),
  })
  .views((self) => ({
    get sortedQuestionGroups() {
      return sortQuestionGroups(values(self.questionGroups));
    },
  }))
  .actions((self) => {
    const remove = (questionGroup) => {
      destroy(questionGroup);
    };

    const clearQuestionGroups = () => {
      self.questionGroups.clear();
    };

    const setQuestionGroups = (nextQuestionGroups, options = {}) => {
      const hardRefresh = options.hardRefresh || false;

      if (hardRefresh) {
        const sortedQuestionGroups = sortQuestionGroups(nextQuestionGroups);
        sortedQuestionGroups.forEach((questionGroup, index) => {
          questionGroup.sortOrder = index + 1;
        });
        const nextQuestionGroupMap = {};
        sortedQuestionGroups.forEach(
          (questionGroup) =>
            (nextQuestionGroupMap[questionGroup.identifier] = questionGroup),
        );
        self.questionGroups.replace(nextQuestionGroupMap);
      } else {
        nextQuestionGroups.forEach((questionGroup) => {
          self.questionGroups.put(questionGroup);
        });
      }
      return self;
    };

    return {
      remove,
      setQuestionGroups,
      clearQuestionGroups,
    };
  });

export { QuestionnaireQuestionGroups, QuestionnaireQuestionGroup };
