import React from 'react';
import { useClioQuestionnaireSubmissions } from '../../hooks';
import { useCurrentOrgFprint } from '~/src/entities/user';
import { Box, CircularProgress, DialogTitle, Typography } from '@mui/material';
import env from '~/src/utils/env';
import { ListViewContainer, ListViewItem } from '../shared';
import { ExistingSubmissionMessage } from '../ShareQuestionnaireTab/SharedSubmission';

export const PreviouslyCreatedLink: React.FC<{
  questionnaireId: string;
  matterId: string;
}> = ({ questionnaireId, matterId }) => {
  const orgFprint = useCurrentOrgFprint();

  const {
    data: questionnaireSubmissionData,
    isError,
    isLoading,
  } = useClioQuestionnaireSubmissions(orgFprint, {
    questionnaire_id: questionnaireId,
    target_identifier: matterId,
  });

  const submission =
    questionnaireSubmissionData?.pages[0]?.submissions[0] || null;

  if (isLoading) {
    return (
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: theme.spacing(1),
        })}
      >
        <CircularProgress size={20} />
      </Box>
    );
  }

  if (isError) {
    return (
      <Typography
        sx={(theme) => ({
          color: theme.palette.text.primary,
          paddingTop: theme.spacing(1),
        })}
      >
        An error occured, please try again later...
      </Typography>
    );
  }

  return submission ? (
    <>
      <Box
        sx={{
          border: '1px solid #e5e7eb',
          marginTop: '8px',
          borderRadius: '0.5em',
        }}
      >
        <ExistingSubmissionMessage />
      </Box>
      <DialogTitle className="modalHeading modalSubHeading">Shared</DialogTitle>
      <ListViewContainer>
        <ListViewItem
          title={submission?.questionnaire_version?.title}
          handleOnClick={() =>
            window.open(
              env.basename +
                `/questionnaires/${submission.questionnaire_id}/responses/${submission.id}`,
              '_blank',
            )
          }
          buttonName={'View'}
        />
      </ListViewContainer>
    </>
  ) : (
    <></>
  );
};
