import React from 'react';
import { MoreVertical } from 'react-feather';
import theme from '~/src/theme';
import { css, StyleSheet } from 'aphrodite';

const styles = StyleSheet.create({
  icon: {
    marginLeft: '-5px',
    ':hover': {
      cursor: 'move',
    },
  },
});

const DraggableIcon = ({ color, className, size, ...otherProps }) => (
  <i
    className={`${css(styles.icon)} ${className}`}
    {...otherProps}
    style={{
      whiteSpace: 'nowrap',
      display: 'flex',
    }}
  >
    <MoreVertical size={size || 16} color={color || theme.colors.chrome} />
    <MoreVertical
      size={size || 16}
      color={color || theme.colors.chrome}
      style={{ marginLeft: '-10px' }}
    />
    <MoreVertical
      size={size || 16}
      color={color || theme.colors.chrome}
      style={{ marginLeft: '-10px' }}
    />
  </i>
);

export default DraggableIcon;
