// /* Services */
import templateSetsService from '~/src/services/api/templateSetsService';

/* Data Transformers */
import { mapTemplateSet } from '~/src/utils/dataTransformers';

function templateSets() {
  const update = async (orgFprint, id, payload) => {
    const res = await templateSetsService.update(orgFprint, id, {
      title: payload.title,
      templatesWithValues: payload.templatesWithValues,
    });

    if (res) {
      return mapTemplateSet(res);
    }

    return res;
  };

  const deleteSet = async (orgFprint, id) => {
    const res = await templateSetsService.delete(orgFprint, id);
    return res;
  };

  const fetchAll = async (orgFprint) => {
    const res = await templateSetsService.fetchAll(orgFprint);

    return res.map(mapTemplateSet);
  };

  const create = async (orgFprint, payload) => {
    const res = await templateSetsService.create(orgFprint, {
      title: payload.title,
      templatesWithValues: payload.templatesWithValues,
    });

    return mapTemplateSet(res);
  };

  const fetch = async ({ page, search, sortBy, order, limit, orgFprint }) => {
    const templateSets = await templateSetsService.fetch(orgFprint, {
      page,
      search,
      sortBy,
      order,
      limit,
    });
    return templateSets.map(mapTemplateSet);
  };

  const fetchById = async (orgFprint, id) => {
    const templateSetResponse = await templateSetsService.fetchById(
      orgFprint,
      id,
    );
    return mapTemplateSet(templateSetResponse);
  };

  return {
    fetchById,
    fetchAll,
    fetch,
    create,
    update,
    // delete is a reserved word
    delete: deleteSet,
  };
}

export default templateSets();
