import { useMutation, useQueryClient } from 'react-query';
import { APIError } from '~/src/utils/error';
import { getQuestionnaireById } from '../urls';
import {
  getClioQuestionnaireAuthToken,
  questionnaireAuthorizationHeaders,
} from '../utils';
import { questionnaireQueryArgs } from '../queryArgs';

type DeleteClioQuestionnaire = {
  questionnaireId: string;
};

export const useDeleteClioQuestionnaire = (orgFprint: string) => {
  const queryClient = useQueryClient();
  return useMutation<void, APIError, DeleteClioQuestionnaire>({
    onSuccess: () => {
      queryClient.invalidateQueries(questionnaireQueryArgs.all);
    },
    mutationFn: async ({ questionnaireId }) => {
      const token = await getClioQuestionnaireAuthToken(orgFprint);
      const response = await fetch(getQuestionnaireById(questionnaireId), {
        method: 'DELETE',
        headers: {
          ...questionnaireAuthorizationHeaders(token),
        },
      });

      if (!response.ok) {
        throw new APIError('Failed to delete questionnaire', response);
      }
    },
  });
};
