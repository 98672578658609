import React, { FC, useEffect } from 'react';
import { Instance } from 'mobx-state-tree';
import usePageScroll from '~/src/hooks/usePageScroll';
import usePdfLocalitiesPagination from '~/src/hooks/usePdfLocalitiesPagination';

import StateRegionTable from '~/src/components/CourtForms/StateRegionTable';
import LoadMoreDisplayText from '~/src/components/LoadMoreDisplayText';
import Breadcrumb from '~/src/components/Breadcrumb';

import { PdfLocalityStore } from '~/src/stores/pdfLocalitiesStore';

interface Props {
  actionType: string;
  stateCode: string;
  breadcrumbData: Record<string, any>[];
  handleClick: Function;
}

export interface PdfTemplate extends Instance<typeof PdfLocalityStore> {}

const StateRegionTableWrapper: FC<Props> = ({
  actionType,
  stateCode,
  breadcrumbData,
  handleClick,
}) => {
  const requestPayload = {
    facets: {
      filter_category: stateCode,
    },
    group_by: 'filter_category,locality',
    query_by: 'locality',
  };

  const { next, loading, done, data, setSearch } =
    usePdfLocalitiesPagination(requestPayload);

  const pageScroll = usePageScroll();

  const loadMore = next;

  const handleOnPageScroll = () => {
    if ((pageScroll === 0 || pageScroll >= 85) && !loading) {
      loadMore();
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleOnPageScroll, [pageScroll]);

  const tableData: Partial<PdfTemplate>[] = data
    .filter((pdfTemplate: PdfTemplate) => pdfTemplate.locality !== 'None')
    .map((pdfTemplate: PdfTemplate) => {
      return {
        filterCategory: pdfTemplate.filterCategory,
        locality: pdfTemplate.locality,
      };
    });

  return (
    <>
      <Breadcrumb data={breadcrumbData} />
      <StateRegionTable
        actionType={actionType}
        pdfCourtForms={tableData}
        visibleItems={tableData.length}
        empty={!loading && done && tableData.length === 0}
        onSearchChange={setSearch}
        handleClick={handleClick}
      />
      <LoadMoreDisplayText
        loading={loading}
        done={done}
        onLoadMore={loadMore}
      />
    </>
  );
};

export default StateRegionTableWrapper;
