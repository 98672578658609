import React from 'react';

import { css } from 'aphrodite';
import styles from '~/src/components/RowActions/styles';

import Button from '~/src/components/Button';
import Triangle from '~/src/components/Triangle';
import Dropdown from '~/src/components/Dropdown';
import dropdownStyles from '~/src/components/Dropdown/styles';

import { type Questionnaire } from '@clio/questionnaire-builder';
import analyticsService from '~/src/services/analytics';

export const RowActions = ({
  actions,
  questionnaire,
}: {
  actions: {
    edit: (questionnaire: Questionnaire) => void;
    share: (questionnaire: Questionnaire & { total_questions: number }) => void;
    rename: (questionnaire: Questionnaire) => void;
    duplicate: (questionnaire: Questionnaire) => void;
    delete: (questionnaire: Questionnaire) => void;
  };
  questionnaire: Questionnaire & { total_questions: number };
}) => {
  const renderTrigger = ({
    toggleDropdown,
  }: {
    toggleDropdown: () => void;
  }) => {
    const handleDropdownClick = () => {
      analyticsService.track('Clicked on Questionnaire Actions', {
        questionnaire_id: questionnaire.id,
      });
      toggleDropdown();
    };

    return (
      <span analyticsname="Actions">
        <Button onClick={handleDropdownClick} cssStyle={styles.rowActionButton}>
          Actions <Triangle primary orientation={'down'} />
        </Button>
      </span>
    );
  };

  const handleEdit = () => {
    actions.edit(questionnaire);
  };

  const handleShare = () => {
    actions.share(questionnaire);
  };

  const handleRename = () => {
    actions.rename(questionnaire);
  };

  const handleDuplicate = () => {
    actions.duplicate(questionnaire);
  };

  const handleDelete = () => {
    actions.delete(questionnaire);
  };

  return (
    <Dropdown renderTrigger={renderTrigger}>
      <a
        onClick={handleEdit}
        className={css(dropdownStyles.dropdownActionItem)}
        analyticsname="Edit"
      >
        Edit
      </a>
      <a
        onClick={handleShare}
        className={css(dropdownStyles.dropdownActionItem)}
        analyticsname="Share"
      >
        Share
      </a>
      <a
        onClick={handleRename}
        className={css(dropdownStyles.dropdownActionItem)}
        analyticsname="Rename"
      >
        Rename
      </a>
      <a
        onClick={handleDuplicate}
        className={css(dropdownStyles.dropdownActionItem)}
        analyticsname="Duplicate"
      >
        Duplicate
      </a>
      <a
        onClick={handleDelete}
        className={css(dropdownStyles.dropdownActionItem)}
        analyticsname="Delete"
      >
        Delete
      </a>
    </Dropdown>
  );
};
