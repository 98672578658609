import { Box, Card, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import {
  isSubscriptionCanceled,
  SubscriptionV2,
  useSubscription,
} from '~/src/entities/subscription';
import { CancelSubscription } from './CancelSubscription';
import { SubscriptionCardContent } from './SubscriptionCardContent';
export const SettingsSubscriptionV2 = () => {
  const { data: subscription, ...rest } = useSubscription();

  return (
    <Box maxWidth={560} width={'100%'} margin={'0 auto'}>
      <Box pb={2}>
        <Card>
          <Box p={3}>
            <Box pb={5}>
              <Typography variant={'h2'}>Subscription</Typography>
            </Box>
            <SubscriptionCardContent
              subscription={subscription! as SubscriptionV2}
              {...rest}
            />
          </Box>
        </Card>
      </Box>
      {subscription && !isSubscriptionCanceled(subscription) ? (
        <Card>
          <Box p={3}>
            <CancelSubscription
              endDate={moment(subscription.current_period_end).format(
                'MMMM DD, YYYY',
              )}
            />
          </Box>
        </Card>
      ) : null}
    </Box>
  );
};
