import { Typography, Box, Divider } from '@mui/material';
import * as React from 'react';
import { CardListItemMetaText } from './CardListItemMetaText';

export type CardListItemTitleProps = {
  label: React.ReactNode;
  meta?: React.ReactNode[];
};

export const CardListItemTitle = ({
  label,
  meta = [],
}: CardListItemTitleProps) => {
  return (
    <Box>
      <Typography
        fontWeight={600}
        sx={(theme) => ({ color: theme.palette.text.primary })}
      >
        {label}
      </Typography>
      <Box display={'flex'} alignItems={'center'} gap={1} mt={0.5}>
        {meta.map((node, i) => (
          <React.Fragment key={i}>
            {i === 0 ? null : (
              <Divider orientation="vertical" sx={{ height: '10px' }} />
            )}
            <CardListItemMetaText>{node}</CardListItemMetaText>
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};
