import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

export default StyleSheet.create({
  banner: {
    userSelect: 'none',
    borderRadius: theme.borderRadius,
    boxSizing: 'border-box',
    maxWidth: '1080px',
    background: theme.colors.babyBlue,
    padding: theme.unit * 4,
    margin: `${theme.unit * 2}px auto`,
    display: 'flex',
    alignItems: 'center',
  },
  bannerSmall: {
    fontSize: theme.fonts.size.small,
    lineHeight: '1.25em',
    padding: theme.unit * 2,
  },
  bannerAlert: {
    background: theme.colors.beeswax,
  },
  bannerFullWidth: {
    borderRadius: 0,
    margin: 0,
    maxWidth: '100%',
    width: '100%',
  },
  icon: {
    marginRight: theme.unit,
    marginBottom: '-2px',
  },
  clickableIcon: {
    cursor: 'pointer',
  },
  bannerNeutral: {
    backgroundColor: 'white',
    borderRadius: '6px',
    border: '1px solid #dededf',
  },
  bannerPaleEggplant: {
    background: theme.colors.paleEggplant,
  },
  bannerHorizontalCenter: {
    justifyContent: 'center',
  },
});
