import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

export default StyleSheet.create({
  rowActionButton: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.unit * 1}px ${theme.unit * 2}px`,
    paddingRight: theme.unit,
  },
  actionButton: {
    marginRight: theme.unit * 2,
  },
  templatesPopulate: {
    marginBottom: theme.unit * 4,
  },
  populateSidebarContainer: {
    padding: theme.unit * 2,
  },
  populateSidebarItem: {
    display: 'flex',
    borderRadius: theme.borderRadius,
    fontSize: theme.fonts.size.small,
    padding: `${theme.unit}px ${theme.unit * 3}px`,
    marginBottom: theme.unit,
    ':hover': {
      cursor: 'pointer',
    },
  },
  populateSidebarItemSelected: {
    color: theme.colors.eggplant,
    background: theme.colors.paleEggplant,
  },
  populateSidebarItemIcon: {
    marginRight: theme.unit,
  },
  templatesDraftContainer: {
    maxWidth: theme.unit * 81,
    margin: '0 auto',
  },
  templatesDraftContainerCard: {
    boxSizing: 'border-box',
    margin: '0 auto',
    background: theme.surface,
    border: theme.border,
    borderRadius: theme.borderRadius,
    display: 'flex',
    flexFlow: 'column',
    width: theme.unit * 81,
  },
  templatesDraftAction: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.unit * 2,
  },
  templatesDraftActionPrimary: {
    marginLeft: theme.unit * 2,
  },
  selectDocsHelpText: {
    textAlign: 'center',
    fontSize: theme.fonts.size.extraSmall,
    color: theme.colors.charcoal,
    lineHeight: '1.5em',
    fontWeight: 500,
  },
});
