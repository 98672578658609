import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { snakeCase } from 'lodash';

import { MattersTable } from './MattersTable';
import Banner from '~/src/components/Banner';
import LoadMoreDisplayText from '~/src/components/LoadMoreDisplayText';

import usePageScroll from '~/src/hooks/usePageScroll';
import useMattersPagination from '~/src/hooks/useMattersPagination';
import PageLayout from '~/src/components/PageLayout';
import { useObserver } from 'mobx-react';

export const MattersPage = () => {
  const pageScroll = usePageScroll();

  const { next, loading, done, data, empty, setSearch, setSortBy, setOrder } =
    useMattersPagination();

  const loadMore = next;

  const handleOnPageScroll = () => {
    if ((pageScroll === 0 || pageScroll >= 85) && !loading) {
      loadMore();
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleOnPageScroll, [pageScroll]);

  const handleSortByChange = (property, order) => {
    if (!property) {
      return;
    }

    const orderLabel = order > 0 ? 'desc' : 'asc';
    let sortBy = snakeCase(property);

    // TODO: abstract this into a model/transformer lookup
    if (property === 'updatedAt') {
      sortBy = 'last_modified';
    }

    if (property === 'number') {
      sortBy = 'matter_number';
    }

    if (property === 'numberOfDocuments') {
      sortBy = 'document_count';
    }

    setSortBy(sortBy);
    setOrder(orderLabel);
  };

  const handleSearchChange = (value) => {
    setSearch(value);
  };

  const tableData = useObserver(() =>
    data.map((matter) => {
      return {
        id: matter.id,
        name: matter.title,
        number: matter.matterNumber,
        title: matter.title,
        lastModified: matter.lastModified,
        numberOfDocuments: `${matter.documentCount}`,
        clioMatterId: matter.externalMatterId,
      };
    }),
  );

  return (
    <PageLayout title="Matters">
      <Banner>
        <div>
          To create a new matter, choose documents from the{' '}
          <Link to="/library">library</Link>, and add the new matter in the
          following screen.
        </div>
      </Banner>
      {!empty && (
        <>
          <MattersTable
            visibleItems={tableData.length}
            matters={tableData}
            empty={!loading && done && tableData.length === 0}
            isLoading={false}
            onSearchChange={handleSearchChange}
            onSortByChange={handleSortByChange}
          />
          <LoadMoreDisplayText
            loading={loading}
            done={done}
            onLoadMore={loadMore}
          />
        </>
      )}
    </PageLayout>
  );
};
