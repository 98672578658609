import { useMutation, UseMutationOptions } from 'react-query';
import clio from '~/src/services/api/clioService';
import { AsyncResultResponse } from '~/src/entities/project/types';

import { APIError } from '../utils/error';
import { composeMutationOpts } from '../entities/utils';
import { useCurrentOrgFprint } from '../entities/user';
import { useInvalidateClioMatters } from '../entities/clioMatters';

type UseSyncSingleMatterVars = {
  clio_matter_id: string;
};

export const useSyncSingleMatterTask = (
  options: UseMutationOptions<
    AsyncResultResponse,
    APIError,
    UseSyncSingleMatterVars
  > = {},
) => {
  const orgFprint = useCurrentOrgFprint();
  return useMutation(
    composeMutationOpts(options, {
      onSuccess: useInvalidateClioMatters(),
      mutationFn: async ({ clio_matter_id }) => {
        const syncTask = await clio.matterSyncV2(orgFprint, clio_matter_id);
        return syncTask;
      },
    }),
  );
};
