import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';

/* Components */
import Spinner from '~/src/components/Spinner';

import styles from './styles';

const LoadMoreDisplayText = (props) => {
  const { loading, done, onLoadMore, slim } = props;

  if (done) {
    return null;
  }

  return (
    <div
      className={css(styles.container, slim && styles.containerSlim)}
      onClick={(!loading && onLoadMore) || undefined}
    >
      {loading && (
        <div className={css(styles.spinner)}>
          <Spinner />
        </div>
      )}
      {!loading && 'Load more'}
    </div>
  );
};

LoadMoreDisplayText.propTypes = {
  slim: PropTypes.bool,
  loading: PropTypes.bool,
  done: PropTypes.bool,
  onLoadMore: PropTypes.func,
};

LoadMoreDisplayText.defaultProps = {
  slim: false,
  loading: false,
  done: false,
  onLoadMore: () => {},
};

export default LoadMoreDisplayText;
