import React, { useEffect, useState } from 'react';
import { TextField, Box } from '@mui/material';
import {
  ProjectStackField,
  ProjectStackDocument,
} from '~/src/entities/project';
import { FieldRenderProps } from './PopulateCardField';
import { useDebounce } from 'react-use';

import FieldHeader from './FieldHeader';
import { isEqual } from 'lodash';

export type ShortTextFieldProps = {
  field: FieldRenderProps;
  projectField: ProjectStackField;
  projectStackDocuments: ProjectStackDocument[];
};

export const ShortTextField = React.memo(
  ({ field, projectField, projectStackDocuments }: ShortTextFieldProps) => {
    const { onChange, countTouched, ref, value, ...rest } = field;
    const [userValue, setUserValue] = useState('');
    useDebounce(
      () => {
        if (userValue !== undefined && userValue !== value) onChange(userValue);
      },
      1000,
      [userValue],
    );

    useEffect(() => {
      if (value == undefined) return;

      setUserValue(value);
    }, [value]);

    return (
      <Box>
        <Box>
          <FieldHeader
            projectField={projectField}
            projectStackDocuments={projectStackDocuments}
          />
        </Box>
        <Box>
          <TextField
            size={'small'}
            fullWidth
            {...rest}
            value={userValue}
            id={field.name}
            inputRef={ref}
            inputProps={{
              'data-1p-ignore': true,
            }}
            onChange={(e) => {
              setUserValue(e.target.value);
              if (countTouched) {
                countTouched(field.name);
              }
            }}
          />
        </Box>
      </Box>
    );
  },
  (prevProps, nextProps) =>
    isEqual(prevProps.field.value, nextProps.field.value) &&
    isEqual(prevProps.projectField, nextProps.projectField) &&
    isEqual(prevProps.projectStackDocuments, nextProps.projectStackDocuments),
);
