import React from 'react';
import {
  Contact,
  ContactChip,
  ContactSelect,
  ContactSelectProps,
  getContactName,
} from '~/src/entities/contact';

type ContactBarProps = {
  isLoading: boolean;
  error?: React.ReactNode;
  contact: Contact | null;
  matterId: string | number;
  contactSelectParams: ContactSelectProps['parameters'];
  onAddContact: () => void;
  onChangeContact: (contact: Contact | null) => void;
};

export const ContactBar = ({
  isLoading,
  error,
  contact,
  matterId,
  onChangeContact,
  onAddContact,
  contactSelectParams,
}: ContactBarProps) => {
  if (isLoading) return <ContactChip loading />;

  if (error) return <>{error}</>;

  if (contact)
    return (
      <ContactChip
        name={getContactName(contact)}
        onDelete={() => onChangeContact(null)}
      />
    );

  return (
    <ContactSelect
      parameters={contactSelectParams}
      onAddContact={onAddContact}
      value={contact}
      onChange={onChangeContact}
      matterId={matterId}
    />
  );
};
