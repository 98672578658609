import React from 'react';
import { PropTypes } from 'prop-types';
import { css } from 'aphrodite';

import { ArrowLeftCircle } from 'react-feather';

import Button from '~/src/components/Button';

import theme from '~/src/theme';

import { Footer } from '~/src/components/SlideIn';
import { default as slideInStyles } from '~/src/components/SlideIn/styles';
import styles from './styles';
import ContactSlideInDetail from '../ContactSlideInDetail';

const AttachContactContactDetails = ({
  renderHeader,
  contact,
  onBack,
  onAttach,
}) => {
  const handleAttach = () => {
    onAttach(contact);
  };

  return (
    <div>
      <div className={css(styles.contactDetailBackContainer)} onClick={onBack}>
        <i className={css(styles.contactDetailBackIcon)}>
          <ArrowLeftCircle size={19} color={theme.colors.charcoal} />
        </i>{' '}
        Back to search
      </div>

      <ContactSlideInDetail
        renderHeader={renderHeader}
        contact={contact}
        edit={false}
      />

      <Footer>
        <Button cssStyle={slideInStyles.footerButton} onClick={onBack}>
          Cancel
        </Button>
        <Button
          cssStyle={slideInStyles.footerButton}
          primary
          onClick={handleAttach}
        >
          Attach
        </Button>
      </Footer>
    </div>
  );
};

AttachContactContactDetails.defaultProps = {
  onAttach: () => {},
  onBack: () => {},
};

AttachContactContactDetails.propTypes = {
  renderHeader: PropTypes.func,
  onAttach: PropTypes.func,
  onBack: PropTypes.func,
};

export default AttachContactContactDetails;
