import { css } from 'aphrodite';
import React, { useState } from 'react';
import { useUserPreferences } from '~/src/entities/user';
import Overlay from '../Modal/Overlay';
import styles from './styles';
import SyncSingleMatterModalContent from './SyncSingleMatterModalContent';
import { useSyncSingleMatterTask } from '~/src/hooks/useSyncSingleMatterTask';
import { useSyncSingleMatter } from '~/src/entities/clioMatters/hooks';

export const useSyncSingleMatterModal = (
  analyticsEventName: String,
  onSynced?: () => void,
  options: Parameters<typeof useSyncSingleMatterTask>[0] = {},
) => {
  const [clioMatterId, setClioMatterId] = useState<string>('');

  const hide = () => setClioMatterId('');

  const successToastMessage = 'Matter synced successfully';

  const {
    isLoading,
    isError,
    isSuccess,
    error,
    sync,
    setTaskId,
    setIsLoading,
    reset,
  } = useSyncSingleMatter(
    analyticsEventName,
    successToastMessage,
    clioMatterId,
    hide,
    onSynced,
    undefined,
    options,
  );

  const performSync = async (clio_matter_id: string) => {
    setIsLoading(true);
    const { task_id } = await sync({ clio_matter_id });
    setTaskId(task_id);
  };

  const { data: userPreferences } = useUserPreferences();
  const requiresConfirm =
    !userPreferences || userPreferences.warn_sync_overwrite;

  const shouldShow = !!clioMatterId;
  const isConfirmed = isLoading || isError || isSuccess;

  const modal = (
    <Overlay
      onCancel={hide}
      show={shouldShow}
      disableClickCatcher={isLoading}
      onAfterClose={reset}
    >
      <div className={css(styles.modalContent)}>
        <SyncSingleMatterModalContent
          onCancel={hide}
          onConfirm={() => performSync(clioMatterId)}
          requiresConfirm={requiresConfirm && !isConfirmed}
          isError={isError}
          error={error}
        />
      </div>
    </Overlay>
  );

  return [
    {
      sync: (clio_matter_id: string) => {
        setClioMatterId(clio_matter_id);
        if (!requiresConfirm) performSync(clio_matter_id);
      },
    },
    modal,
  ] as const;
};
