import React from 'react';
import PropTypes from 'prop-types';

import EntityField from './EntityField';
import { ReactComponent as ClioIconSrc } from './clio-logo-icon.svg';

function getCardIcon(type) {
  switch (type) {
    case 'clio':
      return ClioIconSrc;
    case 'contact':
      return null;
    case 'other':
      return null;
    default:
      return null;
  }
}

const EntityFieldCard = (props) => {
  const { label, fields, type, selectedField, onFieldClick } = props;

  const hasFields = fields.length > 0;

  const icon = getCardIcon(type);

  return (
    <div className={'rounded border border-solid border-gray-300 mb-4'}>
      <div
        className={`border border-solid border-l-0 border-r-0 border-t-0 border-gray-300 p-4 flex justify-between flex-row items-center`}
      >
        <div className={'flex'}>
          {icon && <img className="mr-2" src={icon} alt={`${'person'}-icon`} />}
          <div className={'text-sm font-bold'}>{label}</div>
        </div>
        {/* <div className={cn('card__header__column')}>
          {editable && editButton}
          {editable && deleteButton}
        </div> */}
      </div>
      {/* <div className={css(styles.cardBtn)}>
        {editable && addFieldButton}
      </div> */}
      {hasFields && (
        <div>
          {fields.map((field, index) => {
            return (
              <EntityField
                key={`entity-field-card-${index}`}
                tag={field.tag}
                type={field.type}
                prompt={field.prompt}
                source={field.source}
                onSelect={onFieldClick}
                selected={selectedField.tag === field.tag}
              />
            );
          })}
          {/* {fields.map((field) => {
              const attributeSource = field.attributeSource;
              let isExternalSourceField = false;

              Object.keys(INTEGRATION_CARD_TYPES).forEach((key) => {
                if (
                  attributeSource
                  && INTEGRATION_CARD_TYPES[key]
                  && INTEGRATION_CARD_TYPES[key].attributeSource
                  && INTEGRATION_CARD_TYPES[key].attributeSource.toLowerCase() === attributeSource.toLowerCase()) {
                  isExternalSourceField = true;
                }
              });

              return (
                <Field
                  key={field.fieldName}
                  {...field}
                  card={card}
                  disableEdit={isExternalSourceField || !editable}
                  deleting={deletingCard || field.deleting || false}
                  updating={updatingCard || field.updating || false}
                  disabled={disabled}
                />
              );
            })} */}
        </div>
      )}
    </div>
  );
};

EntityFieldCard.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string,
      type: PropTypes.string,
      prompt: PropTypes.string,
      tag: PropTypes.string,
    }),
  ),
  onFieldClick: PropTypes.func,
};

EntityFieldCard.defaultProps = {
  label: 'Card',
  type: 'Other',
  fields: [],
  onFieldClick: () => {},
};

export default EntityFieldCard;
