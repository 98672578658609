import React from 'react';
import { inject, observer } from 'mobx-react';

import store from '~/src/stores';
import { DOCUMENT_CATEGORY_ID } from '~/src/stores/sidebarItemsStore';
import DocumentPreviewSlideIn from '~/src/components/Documents/DocumentPreviewSlideIn';
import { history } from '~/src/utils/history';

class DocumentPreviewSlideInContainer extends React.Component {
  constructor(props) {
    super(props);
    this.setState({
      title: '',
      images: [],
    });
  }

  componentDidMount() {
    if (
      this.props.templateObj.documentCategoryId ===
      DOCUMENT_CATEGORY_ID.TAGGED_PDF
    ) {
      this.props.store.pdfCourtForms
        .getTemplatePreview(this.props.templateId)
        .then((response) => {
          const images = response.document.pages.map((p) => p.background_url);
          this.setState({
            images,
          });
        });
    }
  }

  handleOnDraftNewDocument = () => {
    const { templateId } = this.props;
    history.push(`/templates/${templateId}/new`);
    this.props.hideSlideIn();
  };

  handleAddToSelection = () => {
    store.templates.addTemplateToSeletionSidebar(this.props.templateObj);
    this.props.hideSlideIn();
  };

  render() {
    const {
      hideSlideIn,
      templateId,
      templateObj,
      hideAddToSelection = false,
    } = this.props;

    if (templateObj.documentCategoryId === DOCUMENT_CATEGORY_ID.TAGGED_PDF) {
      const template = this.props.store.pdfCourtForms.getTemplate(templateId);
      const { title } = template;

      return (
        <DocumentPreviewSlideIn
          title={title}
          images={this.state && this.state.images}
          onCancel={hideSlideIn}
          hideAddToSelection={false}
          hideDraftDocument={true}
          onDraftNewDocument={this.handleOnDraftNewDocument}
          onAddToSelection={this.handleAddToSelection}
        />
      );
    } // documentCategoryId === DOCUMENT_CATEGORY_ID.MS_WORD
    const template = this.props.store.templates.getTemplate(templateId);

    const { title } = template;
    const pages = template.previewPages || [];
    const images = pages.map((page) => page.backgroundUrl);
    const message =
      template.previewPages.length === 0
        ? 'The preview pages for this document are generating. Please try again later.'
        : null;

    return (
      <DocumentPreviewSlideIn
        title={title}
        images={images}
        message={message}
        onCancel={hideSlideIn}
        hideAddToSelection={hideAddToSelection}
        hideDraftDocument={true}
        onDraftNewDocument={this.handleOnDraftNewDocument}
        onAddToSelection={this.handleAddToSelection}
      />
    );
  }
}

export default inject((store) => store)(
  observer(DocumentPreviewSlideInContainer),
);
