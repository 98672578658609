import React from 'react';
import { Button, Typography } from '@mui/material';
import { PlanCard } from './PlanCard';
import {
  WORD_DOCUMENT_AUTOMATION_YEARLY_PRICE,
  WORD_DOCUMENT_AUTOMATION_MONTHLY_PRICE,
  getPlanPrice,
  BillingCycleType,
} from '~/src/entities/subscription';

type WordDocumentAutomationCardProps = {
  value: boolean;
  billingCycle: BillingCycleType;
  onChange: (value: boolean) => void;
};

export const WordDocumentAutomationCard = ({
  value,
  billingCycle,
  onChange,
}: WordDocumentAutomationCardProps) => {
  let planPriceDesc;
  if (billingCycle === 'annual') {
    planPriceDesc = getPlanPrice(
      WORD_DOCUMENT_AUTOMATION_YEARLY_PRICE,
      'annual',
    );
  } else {
    planPriceDesc = getPlanPrice(
      WORD_DOCUMENT_AUTOMATION_MONTHLY_PRICE,
      'monthly',
    );
  }

  return (
    <PlanCard
      label={'Word document automation'}
      description={<Typography variant={'body1'}>{planPriceDesc}</Typography>}
      action={
        <Button
          data-testid={'word-doc-button'}
          variant={value ? 'outlined' : 'contained'}
          color={value ? 'error' : 'primary'}
          onClick={() => onChange(!value)}
        >
          {value ? 'Remove' : 'Add'}
        </Button>
      }
    />
  );
};
