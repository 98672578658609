import React from 'react';
import { css } from 'aphrodite';
import { isEqual, cloneDeep } from 'lodash';
import WithLayoutProps from '~/src/hoc/WithLayoutProps';
import Card from '~/src/components/Card';
import modalStyles from '~/src/components/Modal/styles';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';

import {
  COURT_FORM_PLANS,
  DOCUMENT_AUTOMATION_PLANS,
} from '~/src/utils/constants';
import { PaymentMethodForm } from './PaymentMethodForm';
import { ChoosePlanForm } from './ChoosePlanForm';
import { SummaryForm } from './SummaryForm';
import { BackArrow } from './BackArrow';
import { ScheduleCall } from './ScheduleCall';

class CreateSubscriptionModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cycle: 'monthly',
      seats: 0,
      coupon: this.props.defaultCoupon,
      couponDiscount: null,
      paymentPending: false,
      showPaymentMethod: false,
      paymentFormValue: {},
      createSubscriptionError: false,
      courtFormPlans: Array(COURT_FORM_PLANS.monthly.length).fill(false),
      documentAutomationPlans: false,
      totalPrice: null,
    };
  }

  handlePlanChange = async (form) => {
    const selectedPlan = form.fields.plan.value;
    const newState = cloneDeep(this.state);

    if (selectedPlan) {
      const { index, selected } = selectedPlan;
      if (index) {
        // Add to document automation plans
        newState.documentAutomationPlans = selected[0];
      } else {
        // Add to court form plans
        newState.courtFormPlans = selected;
      }
    }
    const seats = form.fields.seats.value;
    const cycle = form.fields.cycle.value;
    const { coupon } = this.state;
    const { courtFormPlans, documentAutomationPlans } = newState;

    const courtFormPlanIds = COURT_FORM_PLANS[cycle]
      .filter((plan, id) => courtFormPlans[id])
      .map((plan) => plan.id);
    const documentAutomationPlanIds = documentAutomationPlans
      ? [DOCUMENT_AUTOMATION_PLANS[cycle][0].id]
      : [];

    newState.cycle = cycle;
    newState.seats = seats;

    if (!isEqual(newState, this.state)) {
      const res = await this.props.fetchUpcomingInvoice(
        [...(courtFormPlanIds ?? []), ...(documentAutomationPlanIds ?? [])],
        [],
        [],
        cycle,
        seats,
        coupon,
      );
      if (res) {
        const { subtotal, total, discount, discount_percent } = res;
        newState.totalPrice = { subtotal, total, discount, discount_percent };
      }

      this.setState(newState);
    }
  };

  handleCouponApply = async (coupon) => {
    this.setState({
      couponSubmitting: true,
      coupon,
    });

    const { courtFormPlans, documentAutomationPlans, cycle, seats } =
      this.state;

    const courtFormPlanIds = COURT_FORM_PLANS[cycle]
      .filter((plan, id) => courtFormPlans[id])
      .map((plan) => plan.id);
    const documentAutomationPlanIds = documentAutomationPlans
      ? [DOCUMENT_AUTOMATION_PLANS[cycle][0].id]
      : [];

    const res = await this.props.fetchUpcomingInvoice(
      [...(courtFormPlanIds ?? []), ...(documentAutomationPlanIds ?? [])],
      [],
      [],
      cycle,
      seats,
      coupon,
    );

    const newState = {};

    if (res) {
      const { subtotal, total, discount, discount_percent } = res;
      newState.totalPrice = { subtotal, total, discount, discount_percent };
      this.setState({
        ...newState,
        couponSubmitting: false,
        couponError: '',
        couponApplied: true,
      });
    } else {
      this.setState({
        couponError: 'This code is invalid',
        couponSubmitting: false,
      });
    }
  };

  handlePaymentMethodSubmit = async () => {
    const {
      couponError,
      courtFormPlans,
      documentAutomationPlans,
      cycle,
      seats,
    } = this.state;

    const formFields = this.state.paymentFormValue.fields;

    const ccExpMonth = formFields.exp.value.split('/')[0];
    const ccExpYear = formFields.exp.value.split('/')[1];
    const addressZip = formFields.address_zip.value;
    const addressCity = formFields.address_city.value;
    const addressState = formFields.address_state.value;
    const cvc = formFields.cvc.value;
    const number = formFields.number.value;

    const card = {
      number,
      cvc,
      exp_month: ccExpMonth,
      exp_year: ccExpYear,
      address_zip: addressZip,
      address_state: addressState,
      address_city: addressCity,
    };

    const coupon = !couponError ? this.state.coupon : '';

    this.setState({
      createSubscriptionError: false,
      paymentPending: true,
    });

    const courtFormPlanIds = COURT_FORM_PLANS[cycle]
      .filter((plan, id) => courtFormPlans[id])
      .map((plan) => plan.id);
    const documentAutomationPlanIds = documentAutomationPlans
      ? [DOCUMENT_AUTOMATION_PLANS[cycle][0].id]
      : [];

    const res = await this.props.createSubscription({
      card,
      add_plans: [
        ...(courtFormPlanIds ?? []),
        ...(documentAutomationPlanIds ?? []),
      ],
      remove_plans: [],
      one_time_charges: [],
      duration: cycle,
      seats,
      coupon,
    });

    if (!res) {
      this.setState({
        createSubscriptionError:
          'Something went wrong, please try again later.',
      });
      this.props.showToast(LAYOUT_TOAST_TYPES.error, {
        message: 'Something went wrong, please try again later.',
      });
    }
  };

  render() {
    const {
      seats,
      cycle,
      showPaymentMethod,
      createSubscriptionError,
      paymentPending,
      coupon,
      couponError,
      couponApplied,
      couponSubmitting,
      courtFormPlans,
      documentAutomationPlans,
      totalPrice,
    } = this.state;

    const courtFormPlanIds = COURT_FORM_PLANS[cycle]
      .filter((plan, id) => courtFormPlans[id])
      .map((plan) => plan.id);
    const documentAutomationPlanIds = documentAutomationPlans
      ? [DOCUMENT_AUTOMATION_PLANS[cycle][0].id]
      : [];

    return (
      <div className="max-w-md mx-auto md:max-w-4xl">
        <div style={{ display: 'flex' }}>
          {showPaymentMethod ? (
            <BackArrow
              onClick={() => this.setState({ showPaymentMethod: false })}
            />
          ) : null}

          <div className={css(modalStyles.modalTitle)}>Configure your plan</div>
        </div>
        <div className="md:flex">
          <div className="md:shrink-0 md:w-7/12">
            <Card dynamic>
              {showPaymentMethod ? (
                <PaymentMethodForm
                  loading={paymentPending}
                  onCancel={() => this.setState({ showPaymentMethod: false })}
                  onChange={(form) => this.setState({ paymentFormValue: form })}
                  onSubmit={this.handlePaymentMethodSubmit}
                  coupon={coupon}
                  couponError={couponError}
                  couponApplied={couponApplied}
                  error={createSubscriptionError}
                />
              ) : (
                <ChoosePlanForm
                  defaultSeats={this.props.defaultSeats}
                  cycle={cycle}
                  onChange={this.handlePlanChange}
                  courtFormPlans={[...courtFormPlans]}
                  documentAutomationPlans={documentAutomationPlans}
                />
              )}
            </Card>
          </div>
          <div className="md:ml-4 md:my-0 my-4 md:w-5/12">
            <Card dynamic>
              <SummaryForm
                totalPrice={totalPrice}
                coupon={coupon}
                couponError={couponError}
                couponSubmitting={couponSubmitting}
                onCouponChange={(value) =>
                  this.setState({ coupon: value, couponError: '' })
                }
                onCouponSubmit={this.handleCouponApply}
                onConfirm={() => this.setState({ showPaymentMethod: true })}
                seats={seats}
                courtFormPlans={courtFormPlanIds}
                documentAutomationPlans={documentAutomationPlanIds}
                cycle={cycle}
                showNextButton={!showPaymentMethod}
              />
            </Card>
          </div>
        </div>
        {this.props.isFreeTrialActive ? <ScheduleCall /> : null}
      </div>
    );
  }
}

export default WithLayoutProps(CreateSubscriptionModal);
