import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

export default StyleSheet.create({
  /* Add Contact Styles */
  addContactContainer: {
    marginTop: theme.unit * 5,
  },

  addContactTitle: {
    textAlign: 'center',
    fontWeight: 'bold',
    color: theme.colors.charcoal,
    fontSize: theme.fonts.size.regular,
    marginBottom: theme.unit * 2,
  },

  addContactDescription: {
    textAlign: 'center',
    fontSize: theme.fonts.size.large,
    color: theme.colors.charcoal,
    lineHeight: '1.25em',
    fontWeight: '400',
  },

  addContactLearMoreLink: {
    display: 'block',
    textAlign: 'center',
    marginTop: theme.unit * 2,
    color: theme.colors.bluebird,
  },

  addContactIllustrationContainer: {
    marginTop: theme.unit * 10,
    marginBottom: theme.unit * 10,
  },

  addContactButtonContainer: {
    marginTop: theme.unit * 4,
    textAlign: 'center',
  },

  addContactButton: {
    display: 'block',
    margin: '0 auto',
    marginBottom: theme.unit * 2,
    minWidth: '150px',
  },

  /* Contact Detail  */
  contactDetailHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  contactDetailHeaderIcon: {
    marginRight: theme.unit * 2,
  },
  contactDetailBackIcon: {
    marginRight: theme.unit * 2,
  },
  contactDetailBackContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.unit * 2,
    fontSize: theme.fonts.size.small,
    color: theme.colors.charcoal,
    ':hover': {
      cursor: 'pointer',
    },
  },
});
