import React from 'react';
import { Clipboard } from 'react-feather';

/* Styles */
import theme from '~/src/theme';
import { css } from 'aphrodite';

import styles from './styles';

const EmptyIntakeFormPreview = () => (
  <div className={css(styles.emptyIntakeFormPreview)}>
    <Clipboard color={theme.colors.chrome} size={40} />
    <div className={css(styles.iconHover)} />
    <h1 className={css(styles.emptyTitle)}>Empty intake form</h1>
    <h2 className={css(styles.emptySubtitle)}>
      Add questions to preview your intake form
    </h2>
  </div>
);

export default EmptyIntakeFormPreview;
