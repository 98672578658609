import React from 'react';
import { MobXProviderContext } from 'mobx-react';
import Store from '~/src/stores';
import { Instance } from 'mobx-state-tree';

export type StoreInstance = Instance<typeof Store>;

export const useStore = () =>
  (React.useContext(MobXProviderContext) as { store: StoreInstance }).store;

const useMst = <FuncType extends (arg: StoreInstance) => any>(
  mapper: FuncType,
): ReturnType<FuncType> => mapper(useStore());

export { useMst as default };
