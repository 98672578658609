import React from 'react';

import Button from '~/src/components/Button';

const QuestionnaireResponsePageFooter = (props) => {
  const { edit, onEdit, onSave } = props;

  return (
    <div className="flex justify-between">
      {!edit && (
        <Button primary onClick={onEdit}>
          Edit
        </Button>
      )}
      {edit && (
        <Button primary onClick={onSave}>
          Save
        </Button>
      )}
    </div>
  );
};

export default QuestionnaireResponsePageFooter;
