import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

export default StyleSheet.create({
  checkList: {
    margin: `${theme.unit * 2}px 0`,
  },

  /* Check List Icon */
  checkListItem: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.fonts.size.small,
    color: theme.colors.charcoal,
    marginBottom: theme.unit * 2,
  },
  checkListItemIcon: {
    marginRight: theme.unit,
  },
});
