// The backend can estimate charges on the upcoming invoice URL if the
// parameter free_trial is provided - all plans must be included

import { useQuery, UseQueryOptions } from 'react-query';
import { APIError } from '~/src/utils/error';
import { UpcomingInvoiceV2 } from '..';
import { useCurrentOrgFprint } from '../../user';
import { composeQueryOpts, fetchAndParse } from '../../utils';
import { keys } from '../keys';
import { BillingCycleType } from '../types';
import { getUpcomingInvoiceV2URLWithParams } from '../urls';

type UseEstimatePlansChangeParams = {
  plans: string[];
  duration: BillingCycleType;
  seat: number;
};

export const useEstimatePlansChangeV2 = (
  { plans, duration, seat }: UseEstimatePlansChangeParams,
  options: Partial<UseQueryOptions<UpcomingInvoiceV2, APIError>> = {},
) => {
  const orgFprint = useCurrentOrgFprint();
  const paramsToSend = {
    free_trial: true,
    add_plans: plans,
    duration,
    seat,
  };
  return useQuery(
    composeQueryOpts(options, {
      queryKey: keys.upcoming(paramsToSend),
      queryFn: () =>
        fetchAndParse(
          getUpcomingInvoiceV2URLWithParams(orgFprint, paramsToSend),
        ),
    }),
  );
};
