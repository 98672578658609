/* Services */
import signaturesService from '~/src/services/api/signaturesService';

import { keysToSnakeCase } from '~/src/utils/objects';
import { mapSignaturePackage } from '../utils/dataTransformers';

function signatures() {
  const updateSignaturePackage = async (
    orgFprint,
    signaturePackageData,
    currentSignaturePackage,
  ) => {
    const updateSignaturePackageData = keysToSnakeCase(signaturePackageData);
    updateSignaturePackageData.lawyaw_project_id =
      currentSignaturePackage.lawyawProjectId;
    updateSignaturePackageData.fprint = currentSignaturePackage.fprint;

    // return_documents_data === true  : coming from ProX/Unified Platform
    // return_documents_data === false : coming from Basic Platform
    updateSignaturePackageData.return_documents_data = true;

    return mapSignaturePackage(
      await signaturesService.updateSignaturePackage(
        orgFprint,
        currentSignaturePackage.lawyawProjectId,
        currentSignaturePackage.fprint,
        updateSignaturePackageData,
      ),
    );
  };

  const updateDocumentForPackage = async (
    orgFprint,
    projectId,
    signaturePackageFprint,
    currentDocument,
    pdfBlob,
  ) => {
    const documentsData = new FormData();
    documentsData.append(
      'original_file',
      pdfBlob.blobData,
      `${currentDocument.title}.pdf`,
    );
    documentsData.append('document_id', currentDocument.id);
    return mapSignaturePackage(
      await signaturesService.updateDocumentForPackage(
        orgFprint,
        projectId,
        signaturePackageFprint,
        documentsData,
      ),
    );
  };

  const addDocumentsToPackage = async (
    orgFprint,
    signaturePackageId,
    pdfBlobs,
    currentSignaturePackage,
  ) => {
    const documentsData = new FormData();
    pdfBlobs.forEach((file) => {
      documentsData.append('new_file_blobs', file, file.name);
    });
    const res = await signaturesService.addDocumentsToPackage(
      orgFprint,
      currentSignaturePackage.lawyawProjectId,
      currentSignaturePackage.fprint,
      documentsData,
    );
    if ('message' in res) throw res;
    return mapSignaturePackage(res);
  };

  const reorderDocuments = async (orgFprint, signaturePackage, docIds) => {
    return signaturesService.reorderDocuments(
      orgFprint,
      signaturePackage,
      docIds,
    );
  };

  const uploadUserSignatureImage = async (
    orgFprint,
    projectId,
    signaturePackageFprint,
    recipientFprint,
    signatureImageBlob,
  ) => {
    return signaturesService.uploadUserSignatureImage(
      orgFprint,
      projectId,
      signaturePackageFprint,
      recipientFprint,
      signatureImageBlob,
    );
  };

  const uploadUserSignatureImageXfdf = async (
    orgFprint,
    projectId,
    signaturePackageFprint,
    recipientFprint,
    signatureImageBlob,
  ) => {
    return signaturesService.uploadUserSignatureImageXfdf(
      orgFprint,
      projectId,
      signaturePackageFprint,
      recipientFprint,
      signatureImageBlob,
    );
  };

  const assignSignatureField = async (
    orgFprint,
    projectId,
    signaturePackageFprint,
    signatureElementData,
  ) => {
    return mapSignaturePackage(
      await signaturesService.assignSignatureField(
        orgFprint,
        projectId,
        signaturePackageFprint,
        signatureElementData,
      ),
    );
  };
  const deleteAssignedSignatureField = async (
    orgFprint,
    projectId,
    signaturePackageFprint,
    elementId,
  ) => {
    return mapSignaturePackage(
      await signaturesService.deleteAssignedSignatureField(
        orgFprint,
        projectId,
        signaturePackageFprint,
        elementId,
      ),
    );
  };
  const printCombined = async (orgFprint, documentsHTML) => {
    return signaturesService.printCombined(orgFprint, documentsHTML);
  };
  const sendSignaturePackage = async (orgFprint, signaturePackageData) => {
    const updatedSignaturePackageData = JSON.parse(
      JSON.stringify(signaturePackageData),
    );
    updatedSignaturePackageData.emailCC = signaturePackageData.emailCC
      ? signaturePackageData.emailCC.split(',').map((email) => {
          return { emailId: email };
        })
      : [];
    const signaturePackage = await signaturesService.sendSignaturePackage(
      orgFprint,
      updatedSignaturePackageData,
    );
    return mapSignaturePackage(signaturePackage);
  };

  const createSignaturePackageFromBlob = async (orgFprint, pdfBlobs) => {
    const data = new FormData();
    pdfBlobs.forEach((pdfBlobData) => {
      data.append('original_file', pdfBlobData.blobData, pdfBlobData.name);
      data.append(pdfBlobData.name, pdfBlobData.numberOfPages);
      data.append('pdf_generated_by_lawyaw', true);
    });
    const signaturePackage = await signaturesService.createSignaturePackage(
      orgFprint,
      data,
    );
    return mapSignaturePackage(signaturePackage);
  };

  const createSignaturePackage = async (orgFprint, files, projectId) => {
    const data = new FormData();
    files.forEach((element) => {
      data.append(
        `${element.name}_file_binary`,
        element.blobData,
        element.name,
      );
      data.append(`${element.name}_pages`, element.numberOfPages);
    });
    data.append('pdf_generated_by_lawyaw', true);
    if (projectId) {
      data.append('source_lawyaw_project_id', projectId);
    }
    const signaturePackage = await signaturesService.createSignaturePackage(
      orgFprint,
      data,
    );
    if (signaturePackage.task_id) {
      return signaturePackage;
    } else {
      return mapSignaturePackage(signaturePackage);
    }
  };

  const createCustomSignaturePackage = async (orgFprint, files) => {
    const documentsData = new FormData();
    files.forEach((file) => {
      documentsData.append('new_file_blobs', file, file.name);
    });
    const res = await signaturesService.createCustomSignaturePackage(
      orgFprint,
      documentsData,
    );
    if ('message' in res) throw res;
    return mapSignaturePackage(res);
  };

  const createSignaturePackageFromExistingProject = async (
    orgFprint,
    projectId,
    data,
  ) => {
    const signaturePackage =
      await signaturesService.createSignaturePackageFromExistingProject(
        orgFprint,
        projectId,
        data,
      );
    return mapSignaturePackage(signaturePackage);
  };

  const getSignaturePackages = async ({ orgFprint, ...query }) => {
    const signaturePackages = await signaturesService.getSignaturePackages(
      orgFprint,
      query,
    );
    return signaturePackages.map((signaturePackage) =>
      mapSignaturePackage(signaturePackage),
    );
  };

  const getSignaturePackage = async (
    orgFprint,
    projectId,
    signaturePackageFprint,
  ) => {
    const signaturePackage = await signaturesService.getSignaturePackage(
      orgFprint,
      projectId,
      signaturePackageFprint,
    );
    return mapSignaturePackage(signaturePackage);
  };

  const cancelOrRemoveSignaturePackage = async (
    orgFprint,
    signaturePackageData,
  ) => {
    // eslint-disable-next-line no-return-await
    return await signaturesService.cancelOrRemoveSignaturePackage(
      orgFprint,
      signaturePackageData,
    );
  };

  return {
    updateSignaturePackage,
    createSignaturePackage,
    createCustomSignaturePackage,
    createSignaturePackageFromExistingProject,
    getSignaturePackage,
    getSignaturePackages,
    reorderDocuments,
    assignSignatureField,
    uploadUserSignatureImage,
    uploadUserSignatureImageXfdf,
    deleteAssignedSignatureField,
    sendSignaturePackage,
    updateDocumentForPackage,
    addDocumentsToPackage,
    printCombined,
    cancelOrRemoveSignaturePackage,
    createSignaturePackageFromBlob,
  };
}

export default signatures();
