import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { PaymentForm, PaymentFormValues } from '~/src/entities/subscription';

const SubmitButton = () => {
  const { isSubmitting } = useFormState();

  return (
    <Button
      data-testid="submit"
      disabled={isSubmitting}
      variant={'contained'}
      type={'submit'}
    >
      {isSubmitting ? 'Submitting...' : 'Confirm & Pay'}
    </Button>
  );
};

type SubscribePaymentFormProps = {
  onSubmit: (values: PaymentFormValues) => Promise<void>;
};

export const SubscribePaymentForm = ({
  onSubmit,
}: SubscribePaymentFormProps) => {
  const form = useForm<PaymentFormValues>({ mode: 'onTouched' });
  return (
    <FormProvider {...form}>
      <form
        data-testid="payment-form"
        noValidate
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <Box pb={3}>
          <Typography variant={'subheading'}>Payment method</Typography>
        </Box>
        <PaymentForm />
        <Box display={'flex'} justifyContent={'flex-end'}>
          <SubmitButton />
        </Box>
      </form>
    </FormProvider>
  );
};
