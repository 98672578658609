import { Tooltip, IconButton, IconButtonProps } from '@mui/material';
import React from 'react';
import { RotateCcw } from 'react-feather';

export const RevertButton = (props: IconButtonProps) => (
  <Tooltip title={'Reset Changes'}>
    <IconButton
      {...props}
      sx={(theme) => ({
        marginLeft: 1,
        color: theme.palette.grey[900],
        width: 24,
        height: 24,
      })}
    >
      <RotateCcw />
    </IconButton>
  </Tooltip>
);
