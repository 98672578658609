import React, { useState, ReactNode, useEffect } from 'react';

import useMst from '~/src/hooks/useMst';

import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';

import useDocumentsActions from '~/src/hooks/useDocumentsActions';
import useProject from '~/src/hooks/useProject';
import { useLayoutContext } from '~/src/contexts/Layout';
import { DeleteIconButton } from './DeleteIconButton';
import { RenameIconButton } from './RenameIconButton';
import { DuplicateIconButton } from './DuplicateIconButton';
import { LimitWarning } from './LimitWarning';
import { Empty } from './Empty';
import { OrderableList } from '../OrderableList';
import { SelectionSidebarItem } from './SelectionSidebarItem';

import { Instance } from 'mobx-state-tree';
import SidebarItemsStore from '~/src/stores/sidebarItemsStore';
import { TOTAL_DOCUMENT_LIMIT } from '~/src/utils/constants';
import { useObserver } from 'mobx-react';
import { useUIFlagEnabled } from '~/src/entities/uiFlags';

export type SidebarItem = {
  id: number | string;
  title: string;
  subtitle: string | null;
  sortOrder: number;
  defaultValueId: number | null;
  globalTemplateId: number | null;
  templateId: number | null;
};

type SidebarStore = Instance<typeof SidebarItemsStore>;

export type SelectionSidebarProps = {
  isDrafting?: boolean;
  renderFooter?: (items: ReturnType<SidebarStore['sortedItems']>) => ReactNode;
  renderHeader?: (items: ReturnType<SidebarStore['sortedItems']>) => ReactNode;
};

const SelectionSidebar = ({
  isDrafting,
  renderFooter,
  renderHeader,
}: SelectionSidebarProps) => {
  const {
    projects: { curProjectId: projectId, orderDocuments, fetchProject },
    currentIndex,
    setCurrentIndex,
    reOrder,
    removeSidebarItem,
    setIdentifier,
    items,
  } = useMst((store) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useObserver(() => ({
      projects: store.projects,
      currentIndex: store.sidebarItems.currentIndex,
      setCurrentIndex: store.sidebarItems.setIndex,
      reOrder: store.sidebarItems.reOrder,
      removeSidebarItem: store.sidebarItems.remove,
      setIdentifier: store.sidebarItems.setIdentifier,
      items: store.sidebarItems.sortedItems(),
    })),
  );

  const totalNumber = items.length;

  const { showToast } = useLayoutContext();

  const {
    renameProjectDocument: handleRenameDocument,
    deleteProjectDocument: deleteDocument,
    duplicateDocument: handleDuplicateDocument,
  } = useDocumentsActions(isDrafting);

  // useProject hook is responsible for re-rendering sidebar based on updates to the project
  useProject(isDrafting);

  const [limitReachedWarningDismissed, setLimitReachedWarningDismissed] =
    useState(false);

  const isNewDocumentSelectionPanel = useUIFlagEnabled(
    'newDocumentSelectionPanel',
  );

  useEffect(() => {
    setCurrentIndex(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isDrafting && totalNumber === 0) return null;

  const shouldShowLimitWarning =
    totalNumber >= TOTAL_DOCUMENT_LIMIT && !limitReachedWarningDismissed;

  const handleChangeOrder = async (reorderedItems: SidebarItem[]) => {
    const currentSelectedDoc = items[currentIndex - 1];
    reOrder(reorderedItems);
    if (!projectId) return;
    await orderDocuments(
      projectId,
      reorderedItems.map(({ id }) => id),
    );
    await fetchProject(projectId, true);
    if (!currentSelectedDoc) return;
    const selectedDocIndex =
      reorderedItems.findIndex(({ id }) => id === currentSelectedDoc.id) + 1;
    setCurrentIndex(selectedDocIndex);
  };

  return (
    <div
      id="selection_sidebar"
      className="bg-grey-light bg-white border-l relative h-full flex flex-col justify-between"
    >
      <div className="overflow-auto">
        {renderHeader?.(items)}

        {totalNumber === 0 ? (
          <Empty useAlphaFeedbackStyle={isNewDocumentSelectionPanel} />
        ) : (
          <>
            {shouldShowLimitWarning ? (
              <LimitWarning
                onDismiss={() => setLimitReachedWarningDismissed(true)}
              />
            ) : null}
            <OrderableList
              onChangeOrder={handleChangeOrder}
              items={items}
              id={({ id }) => `${id}`}
              className={'p-3 pt-0 pb-20'}
              render={(item, index) => (
                <SelectionSidebarItem
                  label={`${item.title} ${item.subtitle || ''}`}
                  selected={
                    items.findIndex(
                      ({ sortOrder }) => sortOrder + 1 === currentIndex,
                    ) === index
                  }
                  onClick={() => {
                    setIdentifier(item.id);
                    setCurrentIndex(index + 1);
                  }}
                  actions={
                    <>
                      {item.templateId && isDrafting && (
                        <RenameIconButton
                          onClick={() => handleRenameDocument(item)}
                        />
                      )}
                      {item.templateId && isDrafting && (
                        <DuplicateIconButton
                          onClick={() => {
                            if (totalNumber >= TOTAL_DOCUMENT_LIMIT) {
                              // in this case, we don't want users to reach the duplication modal, so we will show an error modal
                              showToast(LAYOUT_TOAST_TYPES.error, {
                                toastDelay: 7000,
                                message: `Can't duplicate, exceeds document count limit of 20`,
                              });
                              return null;
                            }

                            handleDuplicateDocument(item);
                          }}
                        />
                      )}
                      {(!isDrafting || totalNumber > 1) && (
                        <DeleteIconButton
                          onClick={() => {
                            if (isDrafting) {
                              deleteDocument(item);
                            } else removeSidebarItem(item);

                            setLimitReachedWarningDismissed(false);
                          }}
                        />
                      )}
                    </>
                  }
                />
              )}
            />
          </>
        )}
      </div>

      {renderFooter?.(items)}
    </div>
  );
};

export default SelectionSidebar;
