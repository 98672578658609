import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';

import styles from './styles';

const OnOffIcon = ({ on }) => (
  <i className={css(styles.on, !on && styles.off)} />
);

OnOffIcon.defaultProps = {
  on: true,
};

OnOffIcon.propTypes = {
  on: PropTypes.bool,
};

export default OnOffIcon;
