/* Services */
import matterService from '~/src/services/api/matterService';

/* Utilities */
import { mapMatterDetails, mapMatter } from '~/src/utils/dataTransformers';

function matter() {
  const createNewMatter = async (orgFprint, name) => {
    const res = await matterService.createNewMatter(orgFprint, name);
    return mapMatter(res);
  };

  const deleteMatter = async (orgFprint, matterId) => {
    return matterService.deleteMatter(orgFprint, matterId);
  };

  const updateMatter = async (orgFprint, matterId, propertiesToUpdate) => {
    const res = await matterService.updateMatter(
      orgFprint,
      matterId,
      propertiesToUpdate,
    );
    return mapMatter(res);
  };

  const fetchMatter = async (orgFprint, matterId) => {
    const res = await matterService.fetchMatter(orgFprint, matterId);

    const resRelatedContacts = res.related_contacts || [];
    const resProjects = res.attached_projects || [];

    const matter = mapMatter(res);

    const { details, attachedContacts, attachedProjects } = mapMatterDetails({
      data: res.data,
      v2_data: res.v2_data,
      related_contacts: resRelatedContacts,
      attached_projects: resProjects,
    });

    matter.details = details;

    return {
      matter,
      attachedProjects,
      attachedContacts,
    };
  };

  const getMatterDetail = async (orgFprint, matterId) => {
    const { details, attachedProjects, attachedContacts } =
      await matterService.getMatterDetail(orgFprint, matterId);

    return {
      details: mapMatterDetails(details),
      attachedProjects,
      attachedContacts,
    };
  };

  const getMatters = async ({ orgFprint, ...query }) => {
    const res = await matterService.getMatters(orgFprint, query);
    return res.map((matter) => mapMatter(matter));
  };

  const getMatterIdTitleMap = async ({ orgFprint }) => {
    const res = await matterService.getMatterIdTitleMap(orgFprint);
    return res;
  };

  return {
    fetchMatter,
    getMatterDetail,
    createNewMatter,
    updateMatter,
    deleteMatter,
    getMatters,
    getMatterIdTitleMap,
  };
}

export default matter();
