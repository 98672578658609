import { http, getPaginationUrlQuery } from '~/src/services/requests';

import { PaginatedParams } from '~/src/globalTypes';
import { RequestPayloadParams } from '~/src/services/pdfCourtFormsService';

function pdfCourtFormsApiService() {
  return {
    fetch: async (
      orgFprint: string,
      { page, search, sortBy, order, limit }: PaginatedParams,
      requestPayload: RequestPayloadParams,
    ) => {
      const parse = true;

      const requestPayloadWithOrgFprint = {
        ...requestPayload,
        org_fprint: orgFprint,
      };

      return http.innerSendAuthenticated(
        {
          relativePath: `/pdf/global_search/${getPaginationUrlQuery({
            page,
            search,
            sortBy,
            order,
            limit,
          })}`,
          method: 'POST',
          body: requestPayloadWithOrgFprint,
        },
        parse,
      );
    },

    getTemplatePreview: async (fprint: string, templateId: number) => {
      return http.innerSendAuthenticated(
        {
          relativePath: `/pdf/api/v1/org/global_template/preview/${templateId}/`,
          method: 'GET',
        },
        true,
      );
    },
  };
}

export default pdfCourtFormsApiService();
