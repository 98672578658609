import { http } from '~/src/services/requests';
import { keysToSnakeCase } from '~/src/utils/objects';

function gatedActionsService() {
  return {
    userClickedContactSales: async (data) => {
      const parse = true;
      const body = keysToSnakeCase(data);
      return http.innerSendAuthenticated(
        {
          relativePath: `/logging/clio/contacted_sales/`,
          method: 'POST',
          body,
        },
        parse,
      );
    },
  };
}

export default gatedActionsService();
