import { useEffect, useState } from 'react';

/* hooks */
import useMst from '~/src/hooks/useMst';

/* stores */
import Questionnaires from '~/src/stores/questionnairesStore';

const useQuestionnaire = (id) => {
  const { fetchOrCreate } = useMst((store) => ({
    fetchOrCreate: async (intakeId) => {
      if (!intakeId) {
        return Questionnaires.create();
      }

      const questionnaire = await store.questionnaires.fetchQuestionnaire(
        intakeId,
      );
      return questionnaire;
    },
  }));

  const [intake, setIntake] = useState();
  const [loading, setLoading] = useState(true);
  const [saving] = useState(false);

  useEffect(() => {
    const fetchOrCreateIntake = async () => {
      setLoading(true);
      const nextIntake = await fetchOrCreate(id);
      setIntake(nextIntake);
      setLoading(false);
    };

    fetchOrCreateIntake();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const updateQuestionnaire = async (valuesToUpdate) => {
    if (intake) {
      // setIsSaving(true);
      intake.update(valuesToUpdate);
      // setIntake(nextIntake);
      // setIsSaving(false);
    }
  };

  return {
    saving,
    loading,
    intake,
    updateQuestionnaire,

    // title: intake && intake.title || "",
    // description: intake && intake.description || "",
    // displayTitle: intake && intake.displayTitle || "",
    // displayDescription: intake && intake.displayDescription || "",

    // questionGroups: intake && intake.questionGroups || [],
  };
};

export default useQuestionnaire;
