import { APIError } from '~/src/utils/error';
import { keys } from '../keys';

import { composeQueryOpts, fetchAndParse } from '../../utils';
import { FeatureAccesses } from '../featureAccesses';
import { UseQueryOptions, useQuery } from 'react-query';
import { queryClient } from '~/src/utils/queryClient';

export const getMembershipUrl = () => `/clients/api/v1/membership/`;

export const enum OTPMethod {
  TEXT = 1,
  EMAIL = 2,
}

export type Member = {
  auth_user_phone_number: string | null;
  authenticated_user_id: number;
  created_at: string;
  dob?: string;
  first_name: string;
  middle_name: string;
  fprint: string;
  id: number;
  last_modified: string;
  last_name: string;
  otp_enabled: boolean;
  otp_method: OTPMethod;
  profile_pic_url?: string;
  signature_pic_url?: string;
  state_bar_number?: string;
  xfdf_signature?: string;
};

export type Organization = {
  organization_name: string;
  signup_source?: 'nysba';
  fprint: string;
  subscription_id: string;
  plan_type?: string;
  org_id: number;
  is_non_profit: boolean;
  enable_dev_tools: boolean;
  clio_integration_enabled: boolean;
  is_clio_free: boolean;
  last_clio_sync?: string;
  last_org_clio_sync?: string;
  feature_accesses: FeatureAccesses;
  feature_flags: {
    pro_experience: boolean;
  };
  num_free_licenses: number;
};

export type Membership = {
  id: number;
  admin: boolean;
  can_share: boolean;
  member_since: string;
  admin_since: string;
  is_default: boolean;
  display_name: string;
  email: string;
};

export type MemebershipAPIResponse = {
  member_info: Member;
  memberships: Array<Membership & Organization>;
};

const queryKey = keys.memberships();
const queryFn = () => fetchAndParse<MemebershipAPIResponse>(getMembershipUrl());

export const useMemberships = (
  options: UseQueryOptions<MemebershipAPIResponse, APIError> = {},
) => useQuery(composeQueryOpts(options, { queryKey, queryFn }));

export const fetchMemberships = (client = queryClient) => {
  client.removeQueries(queryKey);
  return client.fetchQuery<MemebershipAPIResponse, APIError>({
    queryKey,
    queryFn,
    staleTime: Number.POSITIVE_INFINITY,
    cacheTime: Number.POSITIVE_INFINITY,
  });
};

export const getMemberships = (client = queryClient) =>
  client.getQueryData<MemebershipAPIResponse>(queryKey);

export const invalidateMemberships = (client = queryClient) =>
  client.invalidateQueries(queryKey);
