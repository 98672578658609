/* Libraries */
import React, { FC, useEffect, useRef, useState } from 'react';
import useFetchSignaturePackage from '~/src/hooks/useFetchSignaturePackage';
import { css } from 'aphrodite';
import styles from '~/src/pages/Signatures/Signatures/styles';
import { Heading, Text } from '~/src/components/Typography';
import Button from '~/src/components/Button';
import Card from '~/src/components/Card';
import useMakePDFViewerVisible from '~/src/hooks/useMakePDFViewerVisible';
import { Read } from '@clio/hancock';
import { useWebViewerContext } from '~/src/components/Webviewer';
import {
  getPackageIdAndPosition,
  makeReadOnlyDocumentsForHancock,
} from './hancock';
import renderAuditTrail from './renderAuditTrail';
import { NAVIGATION_WIDTH } from '~/src/components/PageLayout/styles';
import { WhoSigns } from '~/src/models';
import PageLayout from '~/src/components/PageLayout';

interface Props {
  signaturePackageId: string;
}

enum LoadState {
  Loading = 'loading',
  Loaded = 'loaded',
  Failed = 'failed',
}

/**
 * Renders a signature package that may be fully or partially signed.
 */
export const SignatureDetailPage: FC<Props> = ({ signaturePackageId }) => {
  const hancock = useRef<Read | null>(null);
  const { signaturePackage } = useFetchSignaturePackage(signaturePackageId);

  const { instance, hideWebViewer } = useWebViewerContext();

  useMakePDFViewerVisible({
    top: 80,
    bottom: 80,
    left: NAVIGATION_WIDTH + 15,
    right: 330,
  });

  const [loadState, setLoadState] = useState<LoadState>(LoadState.Loading);
  useEffect(() => {
    // webviewer not available yet:
    if (!instance) return;
    // No package to render yet or package is invalid:
    if (!signaturePackage) return;
    // Already loaded hancock/the package;
    if (hancock.current) return;
    hancock.current = new Read(instance);
    const packageData = makeReadOnlyDocumentsForHancock(signaturePackage);
    hancock.current
      .loadDocuments(packageData)
      .then(() => {
        // Add the package ID to each page
        const packageId = getPackageIdAndPosition(signaturePackage);
        hancock.current!.renderPackageId(packageId);
        hancock.current!.zoomControlsEnabled = true;
        // Render the audit trail (if there is one)
        if (!signaturePackage.auditTrail) return;
        hancock.current!.renderAdditionalPage((Annotations) =>
          renderAuditTrail(
            signaturePackage.title,
            signaturePackage.fprint,
            signaturePackage.auditTrail!,
            Annotations,
          ),
        );
      })
      .then(() => {
        setLoadState(LoadState.Loaded);
      })
      .catch((err) => {
        console.error(err);
        setLoadState(LoadState.Failed);
        hideWebViewer();
      });
  }, [instance, hideWebViewer, signaturePackage]);

  return (
    <PageLayout
      title={'Signatures'}
      footer={
        <footer>
          <Button
            primary
            onClick={() => {
              if (!hancock.current || !instance || !signaturePackage) return;

              const determineFileName = (title: string) => {
                const possibleFileExtension = title.split('.').pop();
                if (!possibleFileExtension) return `${title}.pdf`;

                if (possibleFileExtension.indexOf(' ') >= 0)
                  // user is using '.' for abbreviation
                  return `${title}.pdf`;

                if (possibleFileExtension !== 'pdf')
                  return title.replace(possibleFileExtension, 'pdf');

                return title;
              };

              hancock.current.download(
                determineFileName(signaturePackage.title),
                instance.Core.SaveOptions.LINEARIZED,
              );
            }}
          >
            Download
          </Button>
        </footer>
      }
    >
      <div className={css(styles.signaturePackageDetailsComponent)}>
        {loadState === LoadState.Failed && (
          <div className="flex-grow">
            There was a problem loading this signature package! Our team has
            been notified.
          </div>
        )}
        {/* @ts-expect-error (some issue with the Card component which is JSX) */}
        <Card elevate cssStyle={styles.signaturePackageDetailsCard}>
          <div>
            <Heading>Summary</Heading>
          </div>

          <div className="mt-6">
            <Heading variant="subheading">Status</Heading>
            <Text>{signaturePackage?.packageStatus || 'Unknown'}</Text>
          </div>

          <div className="mt-6">
            <Heading>Recipients</Heading>
            {signaturePackage?.recipients.map((recipient, index) => {
              return (
                <div key={index}>
                  {!(
                    signaturePackage.whoSigns == WhoSigns.Others &&
                    recipient.signOrder == 0
                  ) && (
                    <Text>
                      {' '}
                      <Text>{recipient.email} </Text>
                      <Text subdued>({recipient.status})</Text>
                    </Text>
                  )}
                </div>
              );
            })}
          </div>

          {signaturePackage?.emailSubject && (
            <div className="mt-6">
              <Heading variant="subheading">Email subject</Heading>
              <Text>{signaturePackage.emailSubject}</Text>
            </div>
          )}
          {signaturePackage?.emailBody && (
            <div className="mt-6">
              <Heading variant="subheading">Email message</Heading>
              <Text>{signaturePackage.emailBody}</Text>
            </div>
          )}

          <div className="mt-6">
            <Heading variant="subheading">Package ID</Heading>
            <Text variant="caption" subdued>
              {signaturePackage?.fprint}
            </Text>
          </div>
        </Card>
      </div>
    </PageLayout>
  );
};
