import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useCurrentOrgFprint } from '~/src/entities/user';
import { useStore } from '~/src/hooks/useMst';
import { usePatchProject } from '~/src/entities/project/hooks';
import clio from '~/src/services/clio';
import { clioManageMatterDocumentsLink } from '~/src/utils/clio';
import { CLIO_ERROR_STATE } from '~/src/utils/constants';
import { ClioSyncDialog } from './ClioSyncDialog';
import qs from 'qs';

type ClioSyncProps = React.PropsWithChildren<{
  shouldClear: boolean;
  projectId: number;
}>;

export const ClioSync = ({
  children,
  shouldClear,
  projectId,
}: ClioSyncProps) => {
  const { clio: clioStore } = useStore();
  const orgFprint = useCurrentOrgFprint();
  const history = useHistory();
  const { clio_matter_id, clio_parent_id } = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  }) as { clio_matter_id?: string; clio_parent_id?: string };

  const hasParams = !!clio_matter_id && !!clio_parent_id;

  const [isSyncing, setIsSyncing] = useState(hasParams);
  const [error, setError] = useState('');

  const patchProject = usePatchProject(projectId);

  const sync = useCallback(async () => {
    if (!clio_matter_id && !clio_parent_id) {
      if (shouldClear) clioStore.clearCourtFormParams();
      return;
    }

    setIsSyncing(true);
    try {
      const { matter_id } = await clio.matterSync(orgFprint, clio_matter_id);
      clioStore.setCourtFormParams({
        syncedMatterId: matter_id,
        courtFormClioMatterId: clio_matter_id,
        courtFormClioParentId: clio_parent_id,
      });
    } catch (err: any) {
      if (err.statusCode === 403) {
        setError(CLIO_ERROR_STATE.COURT_FORMS_NOT_ENABLED);
      } else if (err.statusCode === 417) {
        setError(CLIO_ERROR_STATE.SYNC_NOT_ENABLED);
      } else if (err.statusCode === 404) {
        setError(CLIO_ERROR_STATE.MATTER_SYNC_NOT_ENABLED);
      } else {
        setError(CLIO_ERROR_STATE.GENERIC_SYNC);
      }
    } finally {
      setIsSyncing(false);
      setTimeout(() => {
        patchProject.mutate({
          clio_parent_id: clio_parent_id,
        });
      }, 5000); // update clio_parent_id only after populate page is loaded and fully populated
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clioStore, clio_matter_id, clio_parent_id, orgFprint, shouldClear]);

  useEffect(() => {
    sync();
  }, [sync]);

  return (
    <>
      <ClioSyncDialog
        open={isSyncing || !!error}
        onClose={() => setError('')}
        onReturnToClio={() => {
          window.location.href = clioManageMatterDocumentsLink(clio_matter_id!);
        }}
        error={error}
      />
      {isSyncing ? null : children}
    </>
  );
};
