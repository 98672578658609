import * as React from 'react';
import { Edit, X } from 'react-feather';
import { IconButton, IconButtonProps, Tooltip } from '@mui/material';

type EditIconButtonProps = {
  onClick: () => void;
  isEditing: boolean;
} & IconButtonProps;

export const EditIconButton = ({
  isEditing,
  onClick,
  sx,
  ...rest
}: EditIconButtonProps) => {
  return (
    <Tooltip title={isEditing ? 'Save & Close' : 'Edit Text to Merge'}>
      <IconButton
        {...rest}
        size={'small'}
        onClick={onClick}
        sx={(theme) => ({
          ...(typeof sx === 'function' ? sx(theme) : {}),
          color: theme.palette.grey[900],
          width: 24,
          height: 24,
        })}
      >
        {isEditing ? <X /> : <Edit />}
      </IconButton>
    </Tooltip>
  );
};
