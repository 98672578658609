/* eslint no-useless-escape: 0 */
import { TOTAL_DOCUMENT_LIMIT } from '~/src/utils/constants';

const defaultValidation = () => {
  return [true];
};

export const VALIDATION_TYPES = {
  default: 'DEFAULT',
  present: 'PRESENT',
  newPassword: 'NEW_PASSWORD',
  email: 'EMAIL',
  ccNumber: 'CC_NUMBER',
  ccExp: 'CC_EXP',
  ccCvc: 'CC_CVC',
  zipCode: 'ZIP_CODE',
  numeric: 'NUMERIC',
  duplicate_document_limit: 'DUPLICATE',
  number_of_people_in_organization: 'NUMBER_OF_PEOPLE_IN_ORGANIZATION',
  renameDocument: 'RENAME',
};

const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const MONTH_REGEX = /(0[1-9]|1[0-2])/;

export const validateCardExpiry = (expiryDate) => {
  if (!expiryDate) {
    return false;
  }
  const [expiryMonth, expiryYear] = expiryDate.split('/');
  if (!MONTH_REGEX.test(expiryMonth)) {
    return false;
  }
  if (parseInt(expiryYear, 10) < new Date().getFullYear()) {
    return false;
  }
  if (
    parseInt(expiryYear, 10) === new Date().getFullYear() &&
    parseInt(expiryMonth, 10) < new Date().getMonth() + 1
  ) {
    return false;
  }
  return true;
};

export const validations = {
  [VALIDATION_TYPES.ccNumber]: ({ value, touched }) => {
    const valid = touched && value.length >= 17;
    return valid;
  },
  [VALIDATION_TYPES.ccExp]: ({ value, touched }) => {
    const valid = touched && validateCardExpiry(value);
    return valid;
  },
  [VALIDATION_TYPES.ccCvc]: ({ value, touched }) => {
    const valid = touched && value.length >= 3;
    return valid;
  },
  [VALIDATION_TYPES.zipCode]: ({ value, touched }) => {
    const valid = touched && /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
    return valid;
  },
  [VALIDATION_TYPES.email]: ({ value, touched }) => {
    const valid = touched && EMAIL_REGEX.test(value) === true;
    return valid;
  },

  [VALIDATION_TYPES.renameDocument]: ({
    value,
    touched,
    ...validationProps
  }) => {
    const cleanValue = value && String(value).trim();
    // documentNamesObject should be an object with the docName as key, and number of times the name
    // appears in the project: {<docName> : 1, <docName2>: 1,...}
    // We'll use that to make sure the value being entered does not match any
    // existing document names when renaming a doc
    let uniqueName = true;
    if (typeof validationProps.documentNamesObject != 'undefined') {
      uniqueName = !(cleanValue in validationProps.documentNamesObject);
    }

    const valid =
      (touched && !!cleanValue && cleanValue.length && uniqueName) || false;

    return valid;
  },

  [VALIDATION_TYPES.present]: ({ value, touched }) => {
    const cleanValue = value && String(value).trim();
    const valid = (touched && !!cleanValue && cleanValue.length) || false;
    return valid;
  },
  [VALIDATION_TYPES.newPassword]: ({ value, touched }) => {
    if (!value) {
      return false;
    }

    const hasSixCharacters = value.length >= 6 || false;
    const hasOneLowerCase = value.search(/[a-z]/) !== -1;
    const hasOneUpperCase = value.search(/[A-Z]/) !== -1;
    const hasOneDigit = value.search(/\d/) !== -1;

    return (
      hasSixCharacters &&
      hasOneLowerCase &&
      hasOneUpperCase &&
      hasOneDigit &&
      touched
    );
  },
  [VALIDATION_TYPES.numeric]: ({ value }) => {
    // This regex should only allow numerics
    const valid = /^[0-9\b]+$/.test(value);
    return valid;
  },

  [VALIDATION_TYPES.duplicate_document_limit]: ({ value }) => {
    const limit =
      TOTAL_DOCUMENT_LIMIT -
      document.querySelectorAll('.draggable_side_document').length;
    let new_val = parseInt(value);
    new_val =
      isNaN(new_val) || new_val === null || typeof new_val === 'undefined'
        ? 0
        : new_val;

    const valid = new_val <= limit && new_val >= 1;
    return valid;
  },

  [VALIDATION_TYPES.number_of_people_in_organization]: ({ value }) => {
    if (value === undefined || value === '') {
      // because 'Number of people in your organization' is an optional field, not a required field.
      return true;
    }
    if (isNaN(value)) {
      return false;
    }
    const limit = 2147483647; // The backend uses a Postgres integer column whose maximum value is 2147483647
    const valid = value <= limit && value >= 1;
    return valid;
  },

  [VALIDATION_TYPES.default]: () => true,
};

export const getValidation = (type) => {
  return validations[type] ? validations[type] : defaultValidation;
};

export const isDateValid = (dateString) => {
  const timestamp = Date.parse(dateString);

  return !isNaN(timestamp);
};
