import React, { MouseEventHandler } from 'react';
import Button from '~/src/components/Button';
import { css } from 'aphrodite';
import PropTypes from 'prop-types';
import { ReactComponent as IconError } from '~/src/static/icon-error.svg';
import styles from './styles';

export type ErrorModalProps = {
  messages: string[];
  onCancel: MouseEventHandler<HTMLButtonElement>;
  onSubmit?: MouseEventHandler<HTMLButtonElement>;
  submitButtonName?: string;
  title: string;
};

const Error = (props: ErrorModalProps) => (
  <div className={css(styles.modalBody)}>
    <div className={css(styles.modalTitleWithIcon)}>
      <IconError style={{ marginRight: '8px' }} />
      <h1>{props.title}</h1>
    </div>
    {props.messages.map((msg: string) => (
      <p key={msg} className={css(styles.modalMessage)}>
        {msg}
      </p>
    ))}
    <div className={css(styles.modalActions)}>
      {props.submitButtonName && props.onSubmit && (
        <Button primary cssStyle={styles.buttonAction} onClick={props.onSubmit}>
          {props.submitButtonName}
        </Button>
      )}
      <Button
        primary={!props.submitButtonName}
        cssStyle={styles.buttonAction}
        onClick={props.onCancel}
      >
        Close
      </Button>
    </div>
  </div>
);

Error.propTypes = {
  title: PropTypes.string,
  messages: PropTypes.array,
};

Error.defaultProps = {
  messages: ['Error', 'An unknown error has occured'],
  onCancel: () => {},
  title: 'Error',
};

export default Error;
