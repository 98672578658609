/* Libraries */
import React from 'react';
import { css } from 'aphrodite';

/* Components */
import Spinner from '~/src/components/Spinner';

/* Styles */
import styles from './styles';

type LoadingOverlayProps = {
  className?: string;
  title?: string;
  subtitle?: string;
  relative?: boolean;
};

const LoadingOverlay = ({
  className,
  title,
  subtitle,
  relative,
}: LoadingOverlayProps) => (
  <div
    className={`h-3/6 ${className} ${css(
      styles.loadingOverlayContainer,
      relative && styles.loadingOverlayContainerRelative,
    )}`}
  >
    <div>
      {/* TODO: replace with large loader */}
      <Spinner />
      <h1 className={css(styles.loadingOverlayTitle)}>{title}</h1>
      <h2 className={css(styles.loadingOverlaySubtitle)}>{subtitle}</h2>
    </div>
  </div>
);

const defaultProps: LoadingOverlayProps = {
  className: '',
  title: 'Loading',
  subtitle: 'Please wait',
};

LoadingOverlay.defaultProps = defaultProps;

export default LoadingOverlay;
