import React from 'react';
import cns from 'classnames';

const SELECTED_COLOR = '#2563EB';
const UNSELECTED_COLOR = '#4B5563';

export interface SignerButtonProps {
  onClick: () => void;
  selected?: boolean;
  label: string;
  Icon: any;
}

export const SignerButton = ({
  onClick,
  selected,
  label,
  Icon,
}: SignerButtonProps) => {
  return (
    <div
      className={cns(
        'cursor-pointer hover:bg-gray-100 p-4 flex flex-col flex-1 rounded-md border border-gray-200 text-gray-600 justify-center items-center',
        {
          'bg-blue-50 border-blue-500 border-2': selected,
        },
      )}
      onClick={onClick}
    >
      <div className="w-16 h-16 flex justify-center items-center">
        <Icon fill={selected ? SELECTED_COLOR : UNSELECTED_COLOR} />
      </div>
      <div className={selected ? 'text-blue-600' : 'text-gray-600'}>
        {label}
      </div>
    </div>
  );
};
