import React, { useCallback, useState } from 'react';
import { CancelSubscriptionModal } from './CancelSubscriptionModal';

type UseCancelSubscriptionModalProps = {
  endDate: string;
  onCanceled?: () => void;
};

export const useCancelSubscriptionModal = ({
  endDate,
  onCanceled,
}: UseCancelSubscriptionModalProps) => {
  const [open, setOpen] = useState(false);
  const toggle = useCallback(() => setOpen((o) => !o), []);
  const modal = (
    <CancelSubscriptionModal
      endDate={endDate}
      onCanceled={() => {
        toggle();
        onCanceled?.();
      }}
      open={open}
      onClose={toggle}
    />
  );

  return [toggle, modal] as const;
};
