import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useCurrentOrg } from '~/src/entities/user';
import { Seats } from './Seats';
import {
  BillingCycleType,
  BillingCycle,
  CourtFormsPlanCard,
  ContentPackagePlanCard,
  WordDocumentAutomationCard,
  EsignaturesPlanCard,
} from '~/src/entities/subscription';

type ConfigureParams = {
  seats: number;
  contentPackage: boolean;
  courtForms: string[];
  billingCycle: BillingCycleType;
  word: boolean;
};

type ConfigurePlansProps = ConfigureParams & {
  onChange: (params: ConfigureParams) => void;
};

export const ConfigurePlan = ({
  seats,
  contentPackage,
  courtForms,
  billingCycle,
  word,
  onChange,
}: ConfigurePlansProps) => {
  const isNysbaSignup = useCurrentOrg().org?.signup_source === 'nysba';

  const handleChange = (next: Partial<ConfigureParams>) => {
    onChange({
      seats,
      contentPackage,
      courtForms,
      billingCycle,
      word,
      ...next,
    });
  };

  return (
    <>
      <Box pb={3}>
        <Seats
          value={seats}
          onChange={(value) => handleChange({ seats: value })}
        />
      </Box>
      <Box pb={5}>
        <Box pb={2}>
          <Typography variant="label">Plan</Typography>
        </Box>
        <Stack spacing={1}>
          {isNysbaSignup && (
            <ContentPackagePlanCard
              value={contentPackage}
              onChange={(value) => {
                handleChange({ contentPackage: value });
              }}
            />
          )}
          <CourtFormsPlanCard
            excludeList={isNysbaSignup ? ['NY_JC_FORMS'] : undefined}
            value={courtForms}
            billingCycle={billingCycle}
            onChange={(value) => handleChange({ courtForms: value })}
          />
          {!isNysbaSignup && (
            <WordDocumentAutomationCard
              value={word}
              billingCycle={billingCycle}
              onChange={(value) => handleChange({ word: value })}
            />
          )}
          <EsignaturesPlanCard />
        </Stack>
      </Box>
      <BillingCycle
        value={billingCycle}
        readOnly={isNysbaSignup}
        onChange={(value) => handleChange({ billingCycle: value })}
      />
    </>
  );
};
