/* Libs */
import { types, destroy } from 'mobx-state-tree';
import { sortBy } from 'lodash';
import { TOTAL_DOCUMENT_LIMIT } from '../utils/constants';

export const DOCUMENT_CATEGORY_ID = {
  TAGGED_PDF: 1, // Documents from batchtasks, aka COURT_FORMS
  PDF: 2, // Any other user uploaded documents including attachments.
  TEXT: 3, // Documents drafted in Lawyaw text editor.
  MS_WORD: 4, // Documents stored and generated in MS-word format.
};

const SidebarItemStore = types
  .model('SidebarItemStore', {
    id: types.union(types.string, types.integer),
    title: types.string,
    subtitle: types.maybeNull(types.string),
    sortOrder: types.integer,
    defaultValueId: types.maybeNull(types.integer),
    globalTemplateId: types.maybeNull(types.integer),
    templateId: types.maybeNull(types.integer),
  })
  .actions((self) => {
    const setSortOrder = (sortOrder) => {
      self.sortOrder = sortOrder;
    };
    return {
      setSortOrder,
    };
  });

const SidebarItemsStore = types
  .model('SidebarItemsStore', {
    items: types.optional(types.array(SidebarItemStore), []),
    currentIndex: types.optional(types.number, 1),
    currentIdentifier: types.optional(types.number, 0),
  })
  .actions((self) => {
    const add = (item) => {
      if (self.items.length >= TOTAL_DOCUMENT_LIMIT) {
        return; // add no more
      }

      const found = self.items.find((i) => i.id === item.id);
      if (found === undefined) {
        self.items.push({
          ...item,
          sortOrder: self.items.length, // make sortOrder 0-base instead of 1-base
        });
      }
    };

    const initialize = () => {
      self.items = [];
    };

    const setIdentifier = (id) => {
      if (typeof id === 'string') {
        self.currentIdentifier = parseInt(id, 10);
      } else {
        self.currentIdentifier = id;
      }
    };

    const setFocusOnSidebarItemFromIndex = (index) => {
      // Take in an index for a document we want to focus on for the sidebar
      if (typeof self.items[index] !== 'undefined') {
        const documentIdToFocusOn = self.items[index].id;
        self.setIdentifier(documentIdToFocusOn);
        self.setIndex(index + 1);
      } else {
        console.error('Unable to set sidebar focus from index: ', index);
      }
    };

    const setIndex = (index) => {
      self.currentIndex = index;
    };

    const updateTitle = (id, title) => {
      self.items.find((item) => item.id === id).title = title;
    };

    const removeById = (itemId) => {
      const item = self.items.find((item) => item.id === itemId);
      if (item) remove(item);
    };

    const remove = (item) => {
      destroy(item);

      self.sortedItems().forEach((item, index) => {
        item.sortOrder = index; // make sortOrder 0-base instead of 1-base
      });
    };

    const reOrder = (nextSortedItems) => {
      nextSortedItems.forEach((item, index) => {
        item.setSortOrder(index);
      });
    };

    return {
      add,
      removeById,
      remove,
      reOrder,
      initialize,
      setIdentifier,
      setIndex,
      updateTitle,
      setFocusOnSidebarItemFromIndex,
    };
  })
  .views((self) => {
    const sortedItems = () => sortBy(self.items, ['sortOrder']);

    const containedInSidebar = (item) => {
      if (Array.isArray(item)) {
        // template array under a templateSet
        for (let k = 0; k < item.length; k++) {
          const template = item[k];
          const found = self.items.find((i) => i.id === template.id);
          if (found === undefined) {
            // one template does not exist in the sidebar
            return false;
          }
        }
        return true; // all of the templates exist in the sidebar
      } // single template
      const singleItemContainedInSidebar = () => {
        return self.items.find((i) => {
          if (item?.template) {
            const template = item?.template?.toJSON();
            return (
              `${template.templateDocId}+${item.id}` === i.id ||
              (parseInt(template?.templateDocId) === parseInt(i.templateId) &&
                item.title === i.title)
            );
          }
          return (
            parseInt(i.id) === parseInt(item.id) ||
            parseInt(item.id) === i.globalTemplateId ||
            parseInt(item.id) === parseInt(i.templateId)
          );
        });
      };
      const found = singleItemContainedInSidebar();
      return found !== undefined;
    };

    const hasTemplateDefaultValues = () => {
      return self.items.some((item) => item.defaultValueId);
    };

    return {
      sortedItems,
      containedInSidebar,
      hasTemplateDefaultValues,
    };
  });

export default SidebarItemsStore;
