import React from 'react';
import {
  SEATS_MONTHLY_PRICE,
  SEATS_YEARLY_PRICE,
} from '~/src/entities/subscription';

import { SummaryFormItem } from './SummaryFormItem';
import { BillingCycleType } from './types';
import { planPrice } from './utils';

type SeatsItemProps = {
  seats: number;
  cycle: BillingCycleType;
};

export const SeatsItem = ({ seats, cycle }: SeatsItemProps) => (
  <SummaryFormItem
    label={
      <>
        {seats} seat{seats === 1 ? '' : 's'}
      </>
    }
    price={planPrice(
      cycle === 'monthly'
        ? SEATS_MONTHLY_PRICE * seats
        : SEATS_YEARLY_PRICE * seats,
      cycle,
    )}
  />
);
