/* Services */
import pdfCourtFormsApiService from '~/src/services/api/pdfCourtFormsApiService';

import { mapPdfCourtForm, mapLocality } from '../utils/dataTransformers';

type Facets = {
  filter_category?: string;
  locality: string;
};

export interface Document {
  created_at: string;
  document_type: number;
  filter_category: string;
  fprint: string;
  id: string;
  is_deleted: false;
  last_modified: string;
  legal_document_type: string;
  legal_practice_area: string;
  locality: string;
  pdf_annotated_doc_id: string;
  sorted_created_at: number;
  sorted_filter_category: number;
  sorted_last_modified: number;
  sorted_legal_document_type: number;
  sorted_legal_practice_area: number;
  sorted_locality: number;
  sorted_subtitle: number;
  sorted_title: number;
  subtitle: string;
  title: string;
  title_and_subtitle: string;
}

export interface PaginatedParams {
  page: number;
  search: string;
  sortBy: string;
  order: string;
  limit: number;
  orgFprint: string;
}

export interface RequestPayloadParams {
  group_by?: string;
  facets: Facets;
}

interface Hit {
  document: Document;
  highlights: string[];
  text_match: number;
}

interface GroupedHits {
  group_key: string[];
  hits: Hit[];
}

function pdfCourtFormsService() {
  const getPdfCourtForms = async (
    { page, search, sortBy, order, limit, orgFprint }: PaginatedParams,
    requestPayload: RequestPayloadParams,
  ) => {
    const pdfCourtForms = await pdfCourtFormsApiService.fetch(
      orgFprint,
      { page, search, sortBy, order, limit, orgFprint },
      requestPayload,
    );
    return pdfCourtForms.hits.map((hit: Hit) => mapPdfCourtForm(hit));
  };

  const getLocalities = async (
    { page, search, sortBy, order, limit, orgFprint }: PaginatedParams,
    requestPayload: RequestPayloadParams,
  ) => {
    const groupByResult = await pdfCourtFormsApiService.fetch(
      orgFprint,
      { page, search, sortBy, order, limit, orgFprint },
      requestPayload,
    );
    // e.g. requestPayload === { "group_by": "filter_category,locality", "facets": {"filter_category": "PA"}}

    return groupByResult.grouped_hits.map((grouped_hit: GroupedHits) =>
      mapLocality(grouped_hit),
    );
  };

  const getTemplatePreview = async (orgFprint: string, templateId: number) => {
    return pdfCourtFormsApiService.getTemplatePreview(orgFprint, templateId);
  };

  return {
    getPdfCourtForms,
    getLocalities,
    getTemplatePreview,
  };
}

export default pdfCourtFormsService();
