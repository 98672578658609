import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

export default StyleSheet.create({
  selectOptionInputContainer: {
    display: 'flex',
    flexGrow: 1,
    position: 'relative',
    background: theme.colors.white,
  },

  selectOptionSearchInputContainer: {
    padding: `${theme.unit}px`,
    paddingBottom: 0,
  },

  noSelectOptionLabel: {
    fontSize: theme.fonts.size.small,
    color: theme.colors.chrome,
    textAlign: 'center',
    padding: `${theme.unit * 2}px 0`,
  },

  selectOptionInputControl: {
    display: 'flex !important',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  selectOptionMenu: {
    top: '100%',
    backgroundColor: 'hsl(0,0%, 100 %)',
    borderRadius: '4px',
    boxShadow: '0 6px 8px 0 rgba(35, 40, 54, 0.10)',
    marginBottom: '8px',
    marginTop: '1px',
    position: 'absolute',
    width: '100%',
    zIndex: '1',
    boxSizing: 'border-box',
    background: '#FFFFFF',
    border: 'solid 1px #DADDE6',
  },

  selectOptionMenuList: {
    // maxHeight: "300px",
    overflowY: 'auto',
    paddingBottom: '4px',
    paddingTop: '4px',
    position: 'relative',
    webkitOverflowScrolling: 'touch',
    boxSizing: 'border-box',
  },
});
