import { camelCase } from 'lodash';

export const sortFormFields = (fields, copy) => {
  const fieldOrder = [
    'type',
    'companyName',
    'fullName',
    'firstName',
    'middleName',
    'lastName',
    'email',
    'emailAddress',
    'phone',
    'phoneNumber',
    'street',
    'address',
    'streetAddress',
    'city',
    'state',
    'country',
    'zipcode',
    'zipCode',
    'fax',
  ];

  let nextFields = fields;

  if (copy) {
    nextFields = fields.slice(0);
  }

  nextFields.sort(function (a, b) {
    const aKey = camelCase(a.label || a.id);
    const bKey = camelCase(b.label || b.id);

    const aIndex = fieldOrder.indexOf(aKey);
    const bIndex = fieldOrder.indexOf(bKey);

    if (aIndex > -1 && bIndex > -1) {
      return aIndex - bIndex;
    }
    if (aIndex < 0) {
      return 1;
    }

    return -1;
  });

  return nextFields;
};

export const setListItem = ({ key, list, item }) => {
  let found = false;
  let nextList = [...list];

  nextList = nextList.map((currentItem) => {
    if (currentItem[key] === item[key]) {
      found = true;
      return item;
    }

    return currentItem;
  });

  if (!found) {
    nextList.push(item);
  }

  return nextList;
};

export const removeListItem = ({ key, list, item }) => {
  const nextList = [...list];

  for (let i = 0; i < nextList.length; i++) {
    const currentItem = nextList[i];

    if (item[key] === currentItem[key]) {
      nextList.splice(i, 1);
    }
  }

  return nextList;
};
