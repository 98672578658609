/* Hooks */
import useMst from '~/src/hooks/useMst';

/* Constants */
import { LAYOUT_MODAL_TYPES } from '~/src/components/PageLayout/Modals';
import { useLayoutContext } from '~/src/contexts/Layout';
import { history } from '~/src/utils/history';

const useIntakesActions = () => {
  const { removeQuestionnaire, updateQuestionnaire } = useMst((store) => ({
    fetchAllQuestionnaires: store.questionnaires.fetchAll,
    intakes: store.questionnaires,
    updateQuestionnaire: store.questionnaires.updateQuestionnaire,
    removeQuestionnaire: store.questionnaires.removeQuestionnaire,
  }));

  const { showModal, hideModal } = useLayoutContext();

  const handleViewIntake = (intake) => {
    history.push(`/questionnaire/edit/${intake.id}`);
  };

  const handleRenameIntake = (intake) => {
    const handleRenameConfirm = ({ fields }) => {
      const title = fields.title && fields.title.value;
      const description = fields.description && fields.description.value;
      const nextValues = {};

      if (title) {
        nextValues.title = title;
      }

      if (description) {
        nextValues.description = description;
      }

      updateQuestionnaire({
        id: intake.id,
        ...intake,
        nextValues,
      });

      hideModal();
    };

    showModal(LAYOUT_MODAL_TYPES.formField, {
      title: 'Rename Questionnaire',
      primaryActionTitle: 'Save',
      fields: [
        {
          label: 'Title',
          id: 'title',
          type: 'text',
          placeholder: 'What would you like to call this questionnaire?',
          required: true,
          defaultValue: intake.title,
          validation: ({ value }) => {
            return !!(value && value.length > 1);
          },
        },
        {
          label: 'Description',
          id: 'description',
          type: 'textarea',
          defaultValue: intake.description,
          placeholder: 'Describe the purpose of this questionnaire',
          validation: () => true,
        },
      ],
      onConfirm: handleRenameConfirm,
    });
  };

  const handleDeleteIntake = (intake) => {
    removeQuestionnaire(intake.id);
  };

  const handlePublishIntake = (intake) => {
    showModal(LAYOUT_MODAL_TYPES.publishQuestionnaireForm, {
      intakeId: intake.id,
    });
  };

  return {
    viewIntake: handleViewIntake,
    renameIntake: handleRenameIntake,
    deleteQuestionnaire: handleDeleteIntake,
    publishQuestionnaire: handlePublishIntake,
  };
};

export default useIntakesActions;
