import React from 'react';
import { PatternFormat } from 'react-number-format';

export const CCExpInput = React.forwardRef(({ onChange, ...rest }, ref) => {
  return (
    <PatternFormat
      {...rest}
      format={'##/####'}
      getInputRef={ref}
      onValueChange={(values) => onChange(values.value)}
    />
  );
});
