import React from 'react';
import { OutlinedInput, Stack } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FormField } from '../../FormField';

export type NameFormValues = {
  first_name: string;
  middle_name: string;
  last_name: string;
};

export const NameFormContent = () => (
  <Stack direction={'column'} spacing={2}>
    <Controller
      name={'first_name'}
      rules={{ required: 'First name is required' }}
      render={({ field, fieldState }) => (
        <FormField
          labelProps={{ htmlFor: field.name }}
          required
          error={fieldState.error?.message}
          label={'First name'}
        >
          <OutlinedInput
            data-testid="first-name-input"
            {...field}
            id={field.name}
          />
        </FormField>
      )}
    />

    <Controller
      name={'middle_name'}
      render={({ field, fieldState }) => (
        <FormField
          labelProps={{ htmlFor: field.name }}
          error={fieldState.error?.message}
          label={'Middle name'}
        >
          <OutlinedInput {...field} id={field.name} />
        </FormField>
      )}
    />

    <Controller
      name={'last_name'}
      rules={{ required: 'Last name is required' }}
      render={({ field, fieldState }) => (
        <FormField
          labelProps={{ htmlFor: field.name }}
          required
          error={fieldState.error?.message}
          label={'Last name'}
        >
          <OutlinedInput {...field} id={field.name} />
        </FormField>
      )}
    />
  </Stack>
);
