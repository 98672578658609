import React, { useCallback, useState } from 'react';
import { UpdateNameModal, UpdateNameModalProps } from './UpdateNameModal';

export const useUpdateNameModal = (
  props: Omit<UpdateNameModalProps, 'open'>,
) => {
  const [open, setOpen] = useState(false);
  const toggle = useCallback(() => setOpen((o) => !o), []);
  const modal = <UpdateNameModal {...props} open={open} onClose={toggle} />;

  return [toggle, modal] as const;
};
