import React, { useEffect, useRef } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { useIsSubscriptionExpired } from '../hooks/useIsSubscriptionExpired';
import { useNeedsToCreateSubscription } from '../hooks/useNeedsToCreateSubscription';

type RequiresSubscriptionProps = {
  children: React.ReactNode;
};

const RENEW_PAGE = '/settings/subscriptions';

export const RequiresSubscription = ({
  children,
}: RequiresSubscriptionProps) => {
  const history = useHistory();
  const location = useLocation();
  const needsToCreateSubscription = useNeedsToCreateSubscription();
  const isSubscriptionExpired = useIsSubscriptionExpired();

  const block = useRef<() => void>();

  const path = location.pathname;

  useEffect(() => {
    block.current?.();
    if (isSubscriptionExpired && path === RENEW_PAGE)
      block.current = history.block();
  }, [isSubscriptionExpired, history, path]);

  if (needsToCreateSubscription) return <Redirect to="/subscribe" />;

  if (isSubscriptionExpired && location.pathname !== RENEW_PAGE)
    return <Redirect to={RENEW_PAGE} />;

  return <>{children}</>;
};
