import { Generate, GenerateDocument, GenerateDocuments } from '@clio/hancock';
import { WebViewerInstance } from '@pdftron/webviewer';
import { setTabIndexOfPDFElementsAndFieldResetHandler } from '~/src/components/Webviewer/utils';

import {
  CheckBox,
  TextArea,
  TextBox,
  Xfdf,
} from '@clio/hancock/dist/generate/schema';
/**
 * hancock wrapper class - this is used to manage events such as field changes
 * and wraps other hancock functionality such as loading documents

*/
class HancockWrapper {
  constructor(private instance: WebViewerInstance) {
    this.hancock = new Generate(this.instance!);
  }

  hancock: Generate;

  initializeZoomEventOnDocumentLoad(zoomToValue: number) {
    this.instance.Core.documentViewer.addEventListener('documentLoaded', () => {
      this.instance.Core.documentViewer.zoomTo(zoomToValue);
    });
  }

  // Disabling this here because it's a callback parameter we don't care about here
  // eslint-disable-next-line no-unused-vars
  _getDocumentExtension = (_document: GenerateDocument): string => {
    return 'pdf';
  };

  loadDocuments = async (documents: GenerateDocuments) => {
    await this.hancock.loadDocuments(documents, this._getDocumentExtension);
  };

  _formatCheckboxFields(checkboxFields: CheckBox[] | undefined): {} {
    if (typeof checkboxFields === 'undefined') return {};
    // TODO replace with values type
    const checkboxValues: { [key: string]: string } = {};
    checkboxFields.forEach((checkbox: CheckBox) => {
      const pdfElementID = checkbox.metadata?.pdfElementID;
      const valueOfElement: 'Yes' | 'Off' = checkbox.state;
      if (
        valueOfElement === 'Yes' &&
        pdfElementID != null &&
        typeof pdfElementID != 'undefined'
      ) {
        // only save the value of a checkbox if the user has checked it,
        // otherwise we can default to it being off
        checkboxValues[pdfElementID] = valueOfElement;
      }
    });
    return checkboxValues;
  }

  _formatTextboxFields(textboxFields: TextBox[] | undefined): {} {
    if (typeof textboxFields === 'undefined') return {};
    // TODO replace with values type
    const textBoxValues: { [key: string]: string } = {};
    textboxFields.forEach((textbox: TextBox) => {
      const pdfElementID = textbox.metadata?.pdfElementID;
      const valueOfElement: string = textbox.content;
      if (
        valueOfElement != '' &&
        pdfElementID != null &&
        typeof pdfElementID != 'undefined'
      ) {
        textBoxValues[pdfElementID] = valueOfElement;
      }
    });
    return textBoxValues;
  }

  _formatTextAreaFields(multilineFields: TextArea[] | undefined): {} {
    if (typeof multilineFields === 'undefined') return {};
    // TODO replace with values type
    const multilineValues: { [key: string]: string } = {};
    multilineFields.forEach((multiline: TextArea) => {
      const pdfElementID = multiline.metadata?.pdfElementID;
      const valueOfElement: string = multiline.content;
      if (
        valueOfElement != '' &&
        pdfElementID != null &&
        typeof pdfElementID != 'undefined'
      ) {
        multilineValues[pdfElementID] = valueOfElement;
      }
    });
    return multilineValues;
  }

  _formatCustomFields(
    customFields: Xfdf[],
    customStringPrefix: string = 'customField_',
  ): {} {
    const customFieldValues: { [key: string]: string } = {};
    customFields.forEach((customFieldValue: Xfdf, index: number) => {
      const valueOfElement: string = customFieldValue.xfdf;
      if (!customFieldValue?.annotationId) {
        customFieldValue.annotationId = index.toString();
        console.error(
          'Could not get annotationId for custom field, using index instead',
        );
      }
      if (valueOfElement != '') {
        customFieldValues[
          `${customStringPrefix}
          ${customFieldValue?.annotationId.toString()}`
        ] = valueOfElement;
      }
    });
    return customFieldValues;
  }

  getFieldsFromDocument = async (customStringPrefix: string): Promise<{}> => {
    let checkboxFields = {};
    let textboxFields = {};
    let multilineFields = {};
    let customFields = {};

    const allFields: any = await this.hancock.getFields();

    checkboxFields = this._formatCheckboxFields(allFields?.checkboxes);
    textboxFields = this._formatTextboxFields(allFields?.textboxes);
    multilineFields = this._formatTextAreaFields(allFields?.textareas);
    customFields = this._formatCustomFields(
      allFields?.xfdfs,
      customStringPrefix,
    );

    return {
      ...checkboxFields,
      ...textboxFields,
      ...multilineFields,
      ...customFields,
    };
  };

  /**
   * Sets the tab indices for each field
   */
  setTabIndexOfPDFElementsAndFieldResetHandler = () => {
    const { destroy } = setTabIndexOfPDFElementsAndFieldResetHandler(
      this.instance,
      () => {},
      () => {
        return '';
      },
      (annotation) => {
        const metadata = this.hancock.getAnnotationMetadata(annotation.Id);
        return Number(metadata!.tabIndex);
      },
      () => {},
      () => false, // disable reset button
    );
    return destroy;
  };
}

export default HancockWrapper;
