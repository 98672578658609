import React from 'react';
import { Alert, AlertTitle } from '@mui/material';
import { AlertTriangle } from 'react-feather';

export const ExistingSubmissionMessage: React.FC = () => {
  return (
    <Alert
      sx={(theme) => ({
        padding: theme.spacing(0.5, 2),
        background: theme.palette.yellow[50],
        color: theme.palette.text.primary,
        '.MuiAlert-icon': {
          color: theme.palette.text.primary,
        },
      })}
      icon={<AlertTriangle fontSize="inherit" />}
      severity="warning"
    >
      <AlertTitle>Link already exists for this matter</AlertTitle>
      If you generate a new link, the previous link expires. Recipients will not
      lose their answers in progress and can continue to access the live
      questionnaire using the new link.
    </Alert>
  );
};
