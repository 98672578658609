import React from 'react';

import AttachContactSlideIn from '~/src/components/Contacts/AttachContactSlideIn';
import { inject, observer } from 'mobx-react';

class AttachContactSlideInContainer extends React.Component {
  state = {
    selectedContactId: null,
    newContactForm: {},
  };

  handleSelectContact = (id) => {
    this.setState({ selectedContactId: id });
  };

  handleCancelContact = () => {
    this.setState({ selectedContactId: null });
  };

  handleNewContactChange = (formValues) => {
    this.setState({ newContactForm: formValues });
  };

  handleCreateNewContact = () => {
    // TODO: Create new contact and add it to the card.
    // We also need to set default values here
    // console.log("TODO: create new contact ", this.state.newContactForm);
  };

  render() {
    const { id, store } = this.props;
    let contact;

    const card = store.cards.getCardById(id);
    const contacts = []; // store.contacts.list;

    if (this.state.selectedContactId) {
      contact = store.contacts.getContact(this.state.selectedContactId);
    }

    return (
      <AttachContactSlideIn
        contact={contact}
        contacts={contacts}
        title={card.name}
        onNewContactChange={this.handleNewContactChange}
        onCreateNewContact={this.handleCreateNewContact}
        onSelectContact={this.handleSelectContact}
        onCancelContact={this.handleCancelContact}
      />
    );
  }
}

export default inject((store) => store)(
  observer(AttachContactSlideInContainer),
);
