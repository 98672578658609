/* Utilities */
import { delegateMSTEvent } from '~/src/utils/analytics';
import analyticsService from '~/src/services/analytics';

/* Events */
const events = {
  downloadDocument: (args) => {
    const [document] = args;
    analyticsService.track('Document Downloaded', {
      title: document.title,
      documentId: document.id,
    });
  },

  createDocSet: (args) => {
    const [templateIds, name, matterId] = args;

    analyticsService.track(
      templateIds.length == 1 ? 'Document Created' : 'Document Set Created',
      {
        title: name,
        projectName: name,
        matterId,
        templateIds: JSON.stringify(templateIds),
      },
    );
  },

  updateProject: (args) => {
    const [projectId, projectData] = args;

    analyticsService.track('Document Set Metadata Updated', {
      title: projectData.title,
      projectId,
    });
  },

  deleteProject: (args) => {
    const [projectId] = args;

    analyticsService.track('Document Set Removed', {
      projectId,
    });
  },
};

const flowReturnEvents = {
  exportDocSet: (args) => {
    const [project] = args;
    let cardCount = 0;
    let fieldCount = 0;
    let pageCount = 0;
    let documentCount = 0;

    if (project.cards) {
      project.cards.forEach((card) => {
        cardCount += 1;
        fieldCount += card.fields.length;
      });
    }

    let templateIds = [];
    if (project.documents) {
      project.documents.forEach((doc) => {
        documentCount += 1;
        pageCount += doc.pages.length;
      });
      templateIds = project.documents.map(
        (document) => document.template_docid,
      );
    }

    analyticsService.track('Document Set Downloaded', {
      title: project.name,
      projectName: project.name,
      projectId: project.id,
      matterId: project.matterId,
      cards: project.cards && project.cards.toJSON(),
      cardCount,
      fieldCount,
      pageCount,
      documentCount,
      templateIds: JSON.stringify(templateIds),
    });
  },
};

export default (call, rootStore) => {
  return delegateMSTEvent(call, rootStore, { events, flowReturnEvents });
};
