/* Libraries */
import React, { useState, useEffect } from 'react';
import { snakeCase } from 'lodash';

/* Hooks */
import usePageScroll from '~/src/hooks/usePageScroll';
import useContactsPagination from '~/src/hooks/useContactsPagination';
import useContactsActions from '~/src/hooks/useContactsActions';

/* Components */
import ContactDeleteWarningModal from '~/src/pages/Contacts/ContactDeleteWarningModal';
import LoadMoreDisplayText from '~/src/components/LoadMoreDisplayText';
import EmptyView from '~/src/components/EmptyView';
import Page from '~/src/components/PageLayout';
import { ContactsTable } from './ContactsTable';

export const ContactsPage = () => {
  const [contactIdToDelete, setContactIdToDelete] = useState(undefined);

  const isDeleteModalOpen = !!contactIdToDelete;

  const {
    onViewContact: handleViewContact,
    onEditContact: handleEditContact,
    onCreateContact: handleCreateContact,
    onDeleteContact,
  } = useContactsActions();

  const { next, loading, empty, done, data, setSearch, setSortBy, setOrder } =
    useContactsPagination();

  const pageScroll = usePageScroll();
  const loadMore = next;

  const handleOnPageScroll = () => {
    if ((pageScroll === 0 || pageScroll >= 85) && !loading) {
      loadMore();
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleOnPageScroll, [pageScroll]);

  const tableData = data.map((contact) => ({
    id: contact.id,
    type: contact.type,
    firstName: contact.fields.firstName || contact.fields.fullName || '',
    lastName: contact.fields.lastName || contact.fields.companyName || '',
    email: contact.fields.email || contact.fields.emailAddress || '',
    lastModified: contact.fields.lastModified,
    lastModifiedBy: contact.fields.lastModifiedBy,
  }));

  const handleSearchChange = (searchValue) => {
    setSearch(searchValue);
  };

  const handleSortByChange = (property, order) => {
    if (!property) {
      return;
    }

    const orderLabel = order > 0 ? 'desc' : 'asc';
    let sortBy = snakeCase(property);

    // disable sort by email temporarily
    // related https://clio.slack.com/archives/C03573CFPCN/p1667484126183779?thread_ts=1667417660.555939&cid=C03573CFPCN
    // if (property === 'email') {
    //   sortBy = 'data.email_address';
    // }

    if (property === 'type') {
      sortBy = 'contact_type';
    }

    // TODO: abstract this into a model/transformer lookup
    if (property === 'updatedAt') {
      sortBy = 'last_modified';
    }

    setSortBy(sortBy);
    setOrder(orderLabel);
  };

  if (empty && tableData.length === 0) {
    const onLinkClick = (event) => {
      event.stopPropagation();
      event.preventDefault();
      handleCreateContact();
    };
    return (
      <EmptyView
        title={'Your contacts will appear here'}
        paragraphs={[
          <>
            You can create a new contact by{' '}
            <a href="#" onClick={onLinkClick}>
              clicking here.
            </a>
          </>,
        ]}
      />
    );
  }

  const handleOpenDeleteModal = (contactId) => {
    setContactIdToDelete(contactId);
  };

  const handleDeleteContact = () => {
    onDeleteContact(contactIdToDelete);
    setContactIdToDelete(null);
  };

  const closeDeleteModal = () => {
    setContactIdToDelete(null);
  };

  return (
    <Page title="Contacts">
      <ContactsTable
        isLoading={false}
        contacts={tableData}
        empty={!loading && done && tableData.length === 0}
        onCreateContact={handleCreateContact}
        onViewContact={handleViewContact}
        onEditContact={handleEditContact}
        onDeleteContact={handleOpenDeleteModal}
        onSearchChange={handleSearchChange}
        onSortByChange={handleSortByChange}
      />
      <LoadMoreDisplayText
        loading={loading}
        done={done}
        onLoadMore={loadMore}
      />
      <ContactDeleteWarningModal
        open={isDeleteModalOpen}
        onCancel={closeDeleteModal}
        onConfirm={handleDeleteContact}
      />
    </Page>
  );
};
