import React from 'react';
import { Box, Grid, Chip } from '@mui/material';
import { ReactComponent as WordIcon } from '~/src/static/word-icon.svg';
import { ReactComponent as PDFIcon } from '~/src/static/pdf-icon.svg';
import { ReactComponent as DeleteIcon } from '~/src/static/close.svg';
import { Template } from './TemplateListInput';

interface Props {
  handleDelete: (template: Template) => void;
  templates: Template[];
  title: string;
}
const TemplateList = ({ templates, handleDelete, title }: Props) => {
  return (
    <div>
      <h3
        style={{ marginBottom: '24px' }}
      >{`${title} (${templates.length})`}</h3>
      <Box
        sx={{
          marginBottom: '32px',
          fontFamily: 'SF Pro Text',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '14px',
          lineHeight: '20px',
        }}
      >
        <Grid
          container
          direction={'row'}
          rowSpacing={1}
          columnSpacing={{ xs: 1 }}
        >
          {templates.map((template) => {
            return (
              <Grid key={template.value} item xs={4}>
                <Chip
                  label={
                    template.label.toLowerCase() === 'na' &&
                    template?.documentType === 1
                      ? template?.subtitle
                      : template.label
                  }
                  style={{
                    backgroundColor: '#EFF6FF',
                    color: '#2563EB',
                    // max width calculated by 180px + 8px left margin + 8 px right margin = 196px
                    maxWidth: '196px',
                  }}
                  onDelete={() => {
                    return handleDelete(template);
                  }}
                  deleteIcon={
                    <span
                      style={{
                        marginLeft: '10px',
                        marginRight: '0px',
                        display: 'flex',
                      }}
                    >
                      <DeleteIcon />
                    </span>
                  }
                  icon={
                    <span
                      style={{
                        marginLeft: '0px',
                        marginRight: '10px',
                        display: 'flex',
                      }}
                    >
                      {template?.documentType === 4 ? (
                        <WordIcon />
                      ) : (
                        <PDFIcon />
                      )}
                    </span>
                  }
                ></Chip>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </div>
  );
};

export default TemplateList;
