import React from 'react';

import { X, Loader } from 'react-feather';
import { css } from 'aphrodite';
import theme from '~/src/theme';

import styles from './styles';

const LoadingToast = ({ message }) => (
  <div className={css(styles.toast, styles.loadingToast)}>
    <i className={css(styles.toastLoadingIcon)}>
      <Loader size={16} color={theme.colors.white} />
    </i>
    {message}
    <i className={css(styles.toastCloseIcon)}>
      <X size={16} color={theme.colors.white} />
    </i>
  </div>
);

LoadingToast.defaultProps = {
  message: 'Loading...',
};

export default LoadingToast;
