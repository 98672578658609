import React from 'react';
import { css } from 'aphrodite';
import styles from './styles';

const HelpMenu = () => {
  return (
    <>
      <a
        className={css(styles.menuLink)}
        href="https://help.lawyaw.com/en/collections/3186432-lawyaw-s-document-drafting-software"
        target="_blank"
        rel="noreferrer"
      >
        Help center
      </a>
      <a
        className={css(styles.menuLink)}
        href="https://academy.lawyaw.com"
        target="_blank"
        rel="noreferrer"
      >
        Template Academy
      </a>
    </>
  );
};
export default HelpMenu;
