import React from 'react';
import { Heading } from '~/src/components/Typography';
import { classNames } from '~/src/components/SignaturePackage/styles';
import Button from '~/src/components/Button';
import EmailCarbonCopyList from './EmailCarbonCopyList';

interface Props {
  emailCC: { emailId: string }[];
  setEmailCC: React.Dispatch<React.SetStateAction<{ emailId: string }[]>>;
}

const EmailCarbonCopy: React.FC<Props> = ({ emailCC, setEmailCC }) => {
  const updateEmailCCList = (email: string, index: number) => {
    setEmailCC(emailCC.map((e, i) => (i === index ? { emailId: email } : e)));
  };

  const addBlankEmailCC = () => {
    setEmailCC([...emailCC, { emailId: '' }]);
  };

  const removeEmailCC = (emailToRemove: string) => {
    setEmailCC(emailCC.filter((email) => email.emailId != emailToRemove));
  };

  return (
    <div className={`${classNames.card} mb-6`}>
      <div className="p-4">
        <Heading>Does anyone else need a finalized copy?</Heading>
      </div>
      <div>
        <EmailCarbonCopyList
          emailCCs={emailCC}
          updateEmailCCList={updateEmailCCList}
          removeEmailCC={removeEmailCC}
        ></EmailCarbonCopyList>
      </div>
      <div className="p-4 flex border-t border-gray-200">
        <Button onClick={addBlankEmailCC}> + Add CC recipient</Button>
      </div>
    </div>
  );
};

export default EmailCarbonCopy;
