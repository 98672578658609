import React from 'react';
import { Heading, Text } from '~/src/components/Typography';
import { classNames } from '~/src/components/SignaturePackage/styles';
import { SignaturePackage } from '~/src/models';

interface Props {
  checkedDocuments: SignaturePackage.Document[];
}

const DocumentsToSend: React.FC<Props> = ({ checkedDocuments }) => {
  return (
    <div className={`${classNames.card} mt-6 mb-6`}>
      <div className="p-4 flex gap-3 items-center">
        <Heading>Documents</Heading>
        <div className="bg-gray-100 rounded-full w-6 h-6 flex justify-center items-center">
          <Text variant="caption" strong subdued>
            {checkedDocuments.length}
          </Text>
        </div>
      </div>

      <div className="p-4 pt-0 flex flex-col gap-2">
        {checkedDocuments.map((doc, index) => {
          const docDescription = doc.subtitle
            ? `${doc.title} ${doc.subtitle}`
            : doc.title;
          return (
            <div className="p-3 border rounded" key={index}>
              <Text strong>{docDescription}</Text>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DocumentsToSend;
