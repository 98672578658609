/* Libraries */
import React from 'react';
import { inject, observer } from 'mobx-react';

/* Higher Order Components */
import WithLayoutProps from '~/src/hoc/WithLayoutProps';

/* Components */
import SettingsAccount from '~/src/components/Settings/SettingsAccount';

/* Constants */
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';

class SettingsAccountContainer extends React.Component {
  onImageRemove = async () => {
    const res = await this.props.store.user.profile.removePhoto();

    if (res) {
      this.props.showToast(LAYOUT_TOAST_TYPES.success, {
        message: 'Photo removed',
      });
    } else {
      this.props.showToast(LAYOUT_TOAST_TYPES.error, {
        message: 'Failed to remove photo, please try again later.',
      });
    }

    return res;
  };

  onImageUpload = async (files) => {
    const file = files[0];

    if (file) {
      const res = await this.props.store.user.profile.uploadPhoto(file);

      if (res) {
        this.props.showToast(LAYOUT_TOAST_TYPES.onImageRemove, {
          message: 'Photo upload succesfully!',
        });
      } else {
        this.props.showToast(LAYOUT_TOAST_TYPES.error, {
          message: 'Failed to upload photo, please try again later.',
        });
      }

      return res;
    }
  };

  handleImageUploadError = (error) => {
    if (error.message) {
      this.props.showToast(LAYOUT_TOAST_TYPES.error, {
        message: error.message,
      });
    }
  };

  render() {
    const { avatar } = this.props.store.user.profile;
    return (
      <SettingsAccount
        avatar={avatar}
        onImageUpload={this.onImageUpload}
        onImageRemove={this.onImageRemove}
        onImageUploadError={this.handleImageUploadError}
      />
    );
  }
}

export default inject((store) => store)(
  WithLayoutProps(observer(SettingsAccountContainer)),
);
