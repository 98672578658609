/* Libraries */
import React, { useEffect } from 'react';
import { snakeCase } from 'lodash';
import { Link } from 'react-router-dom';

/* Hooks */
import usePageScroll from '~/src/hooks/usePageScroll';
import useDocumentsPagination from './useDocumentsPagination';

/* Components */
import Documents from '~/src/components/Documents';
import LoadMoreDisplayText from '~/src/components/LoadMoreDisplayText';
import EmptyView from '~/src/components/EmptyView';
import { mapDocumentToTable } from '~/src/utils/dataTransformers';
import Page from '~/src/components/PageLayout';
import { useObserver } from 'mobx-react';

export const DocumentsPage = (props) => {
  /* Hooks */
  const pageScroll = usePageScroll();

  const { columnWidths, hideHeader, title, noBorder } = props;

  // Default data fetching method, dynamic pagination
  const { next, loading, done, search, data, setSearch, setSortBy, setOrder } =
    useDocumentsPagination();

  // View specific pagination logic
  const loadMore = next;

  const handleOnPageScroll = () => {
    if ((pageScroll === 0 || pageScroll >= 85) && !loading) {
      loadMore();
    }
  };

  useEffect(
    handleOnPageScroll,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageScroll],
  );

  const tableData = useObserver(() => data.map(mapDocumentToTable) || []);

  const handleSearchChange = (searchValue) => {
    setSearch(searchValue);
  };

  const handleSortByChange = (property, order) => {
    if (!property) {
      return;
    }

    const orderLabel = order > 0 ? 'desc' : 'asc';
    let sortBy = snakeCase(property);

    // TODO: abstract this into a model/transformer lookup
    if (property === 'updatedAt') {
      sortBy = 'last_modified';
    }

    setSortBy(sortBy);
    setOrder(orderLabel);
  };

  if (data.length === 0 && !search && !loading) {
    return (
      <Page title={'Documents'}>
        <EmptyView
          title={'Drafted documents will show up here'}
          paragraphs={[
            <>
              To draft a document select a template from the
              <Link to="/templates"> templates page.</Link>
            </>,
          ]}
        />
      </Page>
    );
  }

  return (
    <Page title={'Documents'}>
      <Documents
        empty={!loading && done && tableData.length === 0}
        documents={tableData}
        visibleItems={tableData.length}
        loading={tableData.length === 0 && loading}
        hideHeader={hideHeader}
        title={title}
        noBorder={noBorder}
        columnWidths={columnWidths}
        onSearchChange={handleSearchChange}
        onSortByChange={handleSortByChange}
      />
      <LoadMoreDisplayText
        loading={loading}
        done={done}
        onLoadMore={loadMore}
      />
    </Page>
  );
};
