/* Services */
import documentsService from '~/src/services/api/documentsService';

/* Stores */

/* Utilities */
import { mapProject } from '~/src/utils/dataTransformers';

function documents() {
  const fetch = async ({ page, search, sortBy, order, limit, orgFprint }) => {
    const documents = await documentsService.fetch(orgFprint, {
      page,
      search,
      sortBy,
      order,
      limit,
    });
    return documents.map((project) => mapProject(project));
  };

  const updateDocumentMetadata = async (
    orgFprint,
    projectId,
    documentId,
    metaData,
  ) => {
    return documentsService.updateDocumentMetadata(
      orgFprint,
      projectId,
      documentId,
      metaData,
    );
  };

  const deleteDocumentFile = async (orgFprint, projectId, documentId) => {
    return documentsService.deleteDocument(orgFprint, projectId, documentId);
  };

  return {
    fetch,
    updateDocumentMetadata,
    deleteDocumentFile,
  };
}

export default documents();
