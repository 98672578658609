import {
  useMutation,
  useQuery,
  UseQueryOptions,
  UseMutationOptions,
} from 'react-query';
import env from '~/src/utils/env';
import { APIError } from '~/src/utils/error';
import {
  composeMutationOpts,
  composeQueryOpts,
  useInvalidateQuery,
} from '../../utils';
import { keys } from '../keys';

export type UserPreferences = {
  warn_sync_overwrite: boolean;
};

const PREFERENCES_URL = `${env.apiUrl}/api/v2/account/preferences/`;

export const useUserPreferences = (
  options: UseQueryOptions<UserPreferences, APIError> = {},
) =>
  useQuery(
    composeQueryOpts(options, {
      queryKey: keys.preferences(),
      queryFn: async () => {
        const response = await fetch(PREFERENCES_URL);
        if (!response.ok)
          throw new APIError('Failed to retrieve user preferences', response);
        return response.json();
      },
    }),
  );

export const useUpdateUserPreferences = (
  options: UseMutationOptions<
    UserPreferences,
    APIError,
    Partial<UserPreferences>
  > = {},
) =>
  useMutation(
    composeMutationOpts(options, {
      onSuccess: useInvalidateQuery(keys.preferences()),
      mutationFn: async (body) => {
        const response = await fetch(PREFERENCES_URL, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        });
        if (!response.ok) {
          throw new APIError('Failed to update user preferences', response);
        }
        return response.json();
      },
    }),
  );
