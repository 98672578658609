import { useMutation, UseMutationOptions } from 'react-query';
import { getProjectContactUrl } from '../urls';
import { APIError } from '~/src/utils/error';
import { composeMutationOpts, fetchAndParse } from '../../utils';
import { ProjectContact } from '../types';
import { useCurrentOrgFprint } from '../../user';

type Variables = {
  projectId: number;
  contactId: number;
  role: string;
};

export const useUpdateProjectWithContact = (
  options: UseMutationOptions<ProjectContact, APIError, Variables> = {},
) => {
  const orgFprint = useCurrentOrgFprint();
  return useMutation(
    composeMutationOpts(options, {
      mutationFn: ({ projectId, contactId, role }) =>
        fetchAndParse<ProjectContact>(
          getProjectContactUrl(orgFprint, projectId, contactId, role),
          {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
          },
        ),
    }),
  );
};
