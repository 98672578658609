/* Libraries */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

/* Components */
import SettingsAccountContainer from './subpages/SettingsAccount';
import SettingsSecurityContainer from './subpages/SettingsSecurity';
import SettingsBillingContainer from './subpages/SettingsBilling';
import SettingsOrganizationContainer from './subpages/SettingsOrganization';
import SettingsTeamContainer from './subpages/SettingsTeam';
import SettingsIntegrationsContainer from './subpages/SettingsIntegrations';

import { useLayoutContext } from '~/src/contexts';
import Page from '~/src/components/PageLayout';
import { Sidebar } from './Sidebar';
import SettingsSubscriptionPage from './subpages/SettingsSubscription';

export const SettingsPage = () => {
  const { hideModal, showToast, showModal } = useLayoutContext();

  return (
    <Page title={'Settings'} sidebar={<Sidebar />}>
      <Switch>
        <Route path="/settings/account">
          <SettingsAccountContainer />
        </Route>
        <Route path="/settings/security">
          <SettingsSecurityContainer
            showModal={showModal}
            showToast={showToast}
            hideModal={hideModal}
          />
        </Route>
        <Route path="/settings/subscriptions">
          <SettingsSubscriptionPage />
        </Route>
        <Route path="/settings/billing">
          <SettingsBillingContainer />
        </Route>
        <Route path="/settings/organization">
          <SettingsOrganizationContainer />
        </Route>
        <Route path="/settings/team">
          <SettingsTeamContainer />
        </Route>
        <Route path="/settings/integrations">
          <SettingsIntegrationsContainer />
        </Route>
        <Redirect to="/settings/account" />
      </Switch>
    </Page>
  );
};
