import React, {
  ChangeEvent,
  HTMLInputTypeAttribute,
  MouseEventHandler,
  useState,
} from 'react';
import theme from '~/src/theme';
import { css } from 'aphrodite';

import { X } from 'react-feather';
import Label, { LabelProps } from './Label';

import styles from './styles';

type CSSInputType = Parameters<typeof css>[0];

type TextInputProps = {
  value: string;
  onChange: (value: string) => void;
  onRemove?: (id: { id?: string }) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  id?: string;
  type?: HTMLInputTypeAttribute;
  autoComplete?: string; // this is https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  removeable?: boolean;
  noMargin?: boolean;
  editable?: boolean;
  pattern?: string; // this is https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/pattern
  LeftIcon?: React.ElementType;
  LabelIcon?: React.ElementType;
  onChangeEvent?: React.ChangeEventHandler<HTMLInputElement>;
  inputClassStyle?: CSSInputType;
} & Pick<
  LabelProps,
  | 'inline'
  | 'label'
  | 'labelCssStyle'
  | 'noMargin'
  | 'hint'
  | 'count'
  | 'error'
  | 'editable'
  | 'cssStyle'
  | 'className'
  | 'style'
  | 'required'
>;

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      id,
      cssStyle,
      style,
      className,
      LeftIcon,
      LabelIcon,
      placeholder,
      inline,
      label,
      labelCssStyle,
      autoComplete,
      value,
      type,
      hint,
      count,
      error,
      editable,
      pattern,
      required,
      disabled,
      removeable,
      noMargin,
      onChangeEvent,
      onRemove,
      onChange,
      onFocus,
      onBlur,
      inputClassStyle,
    },
    ref,
  ) => {
    const handleRemove: MouseEventHandler = (event) => {
      event.stopPropagation();
      onRemove?.({ id });
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();

      if (onChangeEvent) {
        return onChangeEvent(event);
      }
      onChange(event.target.value);
    };

    const [focus, setFocus] = useState(false);

    const handleOnFocus = () => {
      onFocus?.();
      setFocus(true);
    };

    const handleOnBlur = () => {
      onBlur?.();
      setFocus(false);
    };

    const handleOnClick: MouseEventHandler<HTMLInputElement> = (event) => {
      event.preventDefault();
      return false;
    };

    return (
      <Label
        Icon={LabelIcon}
        inline={inline}
        label={label}
        labelCssStyle={labelCssStyle}
        noMargin={noMargin}
        hint={hint}
        count={count}
        error={error}
        focus={focus}
        editable={editable}
        cssStyle={cssStyle}
        className={className}
        style={style}
        required={required}
      >
        <>
          {LeftIcon && editable && (
            <i className={css(styles.leftIcon)}>
              <LeftIcon
                color={theme.colors.charcoal}
                size={theme.fonts.size.regular}
              />
            </i>
          )}
          {!editable && (
            <p className={css(styles.textInput, styles.textInputLabelStatic)}>
              {value}
            </p>
          )}
          {editable && (
            <input
              ref={ref}
              value={value || ''}
              onClick={handleOnClick}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
              onChange={handleChange}
              autoComplete={autoComplete}
              className={css(
                styles.textInput,
                LeftIcon && styles.textInputLeftIcon,
                true && styles.textInputRightIcon,
                !!error && styles.textInputError,
                inputClassStyle,
              )}
              type={type}
              placeholder={placeholder}
              pattern={pattern}
              required={required}
              disabled={disabled}
            />
          )}
          {removeable && (
            <i className={css(styles.rightIcon)} onClick={handleRemove}>
              <X color={theme.colors.chrome} size={theme.fonts.size.small} />
            </i>
          )}
        </>
      </Label>
    );
  },
);

TextInput.defaultProps = {
  editable: true,
  noMargin: false,
  removeable: false,
  type: 'text',
};

export default TextInput;
