/* Libraries */
import React, { useEffect } from 'react';
import { snakeCase } from 'lodash';
import { Link } from 'react-router-dom';

/* Hooks */
import usePageScroll from '~/src/hooks/usePageScroll';
import useDocumentSetsPagination from './useDocumentSetsPagination';

/* Components */
import DocumentSetsTable from '~/src/components/DocumentSets';
import LoadMoreDisplayText from '~/src/components/LoadMoreDisplayText';
import EmptyView from '~/src/components/EmptyView';

/* Utils */
import { mapDocumentSetToTable } from '~/src/utils/dataTransformers';
import Page from '~/src/components/PageLayout';
import useFeatureAccesses from '~/src/hooks/useFeatureAccesses';
import DocumentSetsGated from './DocumentSetsGated';
import { useObserver } from 'mobx-react';

export const DocumentSetsPage = (props) => {
  const pageScroll = usePageScroll();

  const { isClioFreePlan } = useFeatureAccesses();

  const { columnWidths } = props;
  const { hideHeader } = props;
  const { title } = props;
  const { noBorder } = props;

  const { next, data, loading, empty, done, setSearch, setSortBy, setOrder } =
    useDocumentSetsPagination();

  const loadMore = next;

  const handleOnPageScroll = () => {
    if ((pageScroll === 0 || pageScroll >= 85) && !loading) {
      loadMore();
    }
  };

  useEffect(
    handleOnPageScroll,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageScroll],
  );

  const dataList = useObserver(() => data.map(mapDocumentSetToTable) || []);

  const handleSearchChange = (searchValue) => {
    setSearch(searchValue);
  };

  const handleSortByChange = (property, order) => {
    if (!property) {
      return;
    }

    const orderLabel = order > 0 ? 'desc' : 'asc';
    let sortBy = snakeCase(property);

    // TODO: abstract this into a model/transformer lookup
    if (property === 'updatedAt') {
      sortBy = 'last_modified';
    }

    setSortBy(sortBy);
    setOrder(orderLabel);
  };

  const pageTitle = 'Document Sets';

  if (empty) {
    return (
      <Page title={pageTitle}>
        <EmptyView
          title={'Your document sets will appear here'}
          paragraphs={[
            <>
              You can create a document set using a template set which you can
              find <Link to="/template-sets"> here.</Link>
            </>,
          ]}
        />
      </Page>
    );
  }

  return (
    <Page title={pageTitle}>
      {isClioFreePlan ? (
        <DocumentSetsGated />
      ) : (
        <>
          <DocumentSetsTable
            documentSets={dataList}
            visibleItems={dataList.length}
            loading={false}
            empty={!loading && done && dataList.length === 0}
            hideHeader={hideHeader}
            title={title}
            noBorder={noBorder}
            columnWidths={columnWidths}
            onSearchChange={handleSearchChange}
            onSortByChange={handleSortByChange}
          />
          <LoadMoreDisplayText
            loading={loading}
            done={done}
            onLoadMore={loadMore}
          />
        </>
      )}
    </Page>
  );
};
