import React, { FC } from 'react';

interface Props {}

const SideMenu: FC<Props> = ({ children }) => {
  return (
    <div className="bg-white flex-none w-72 py-6 px-4 border-r border-l border-gray-200">
      {children}
    </div>
  );
};

export default SideMenu;
