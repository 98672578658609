import {
  Box,
  Chip,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import React from 'react';
import { capitalize } from 'lodash';
import { FormField } from '~/src/components/FormField';
import { BillingCycleType } from '~/src/entities/subscription';

type BillingCycleProps = {
  value: BillingCycleType;
  readOnly?: boolean;
  onChange: (value: BillingCycleType) => void;
};

export const BillingCycle = ({
  value,
  readOnly,
  onChange,
}: BillingCycleProps) => {
  if (readOnly) {
    return (
      <Box sx={{ pb: 2 }}>
        <Box sx={{ pb: 1 }}>
          <Typography variant={'label'}>Billing cycle</Typography>
        </Box>
        <Box>
          <Typography variant={'body1'}>{capitalize(value)}</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <FormField label={'Billing cycle'}>
      <RadioGroup
        value={value}
        onChange={(_, next) => onChange(next as BillingCycleType)}
      >
        <FormControlLabel
          control={<Radio value={'monthly'} />}
          label={<Typography variant={'body1'}>Monthly</Typography>}
        />

        <FormControlLabel
          control={<Radio value={'annual'} />}
          label={
            <Box display={'flex'} alignItems={'center'}>
              <Box pr={1}>
                <Typography variant={'body1'}>Annual</Typography>
              </Box>
              <Chip
                label={
                  <Typography
                    variant={'smallLabel'}
                    sx={(theme) => ({
                      color: theme.palette.green[700],
                    })}
                  >
                    16% discount
                  </Typography>
                }
                sx={(theme) => ({
                  background: theme.palette.green[50],
                })}
              />
            </Box>
          }
        />
      </RadioGroup>
    </FormField>
  );
};
