import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, renderFormField } from '~/src/components/Forms';
import useMst from '~/src/hooks/useMst';
import { TOTAL_DOCUMENT_LIMIT } from '~/src/utils/constants';

import { css } from 'aphrodite';

import Button from '~/src/components/Button';

import styles from './styles';

const FormFieldModal = (props) => {
  const { totalNumber } = useMst((store) => ({
    totalNumber: store.sidebarItems.items.length, // fortunately, store.sidebarItems.items.length is mutable. So, it will trigger re-render.
  }));
  const {
    title,
    primaryActionTitle,
    primaryActionInProcessTitle,
    fields,
    disableCancel,
    onCancel,
    onConfirm,
    className,
    analyticsName,
  } = props;

  const [formValid, setFormValid] = useState(false);
  const [formValue, setFormValue] = useState(0);
  const [formRenameValue, setFormRenameValue] = useState('');

  const [formState, setFormState] = useState({});
  const [primaryActionLabel, setPrimaryActionLabel] =
    useState(primaryActionTitle);
  const [primaryActionDisabled, setPrimaryActionDisabled] = useState(false);

  const handleFormChange = (form) => {
    setFormState(form);
    setFormValid(form.valid);

    setFormValue(form.fields?.number_of_documents_to_duplicate?.value);
    setFormRenameValue(form.fields?.name?.value);
  };

  const handleSubmit = () => {
    if (formValid) {
      setPrimaryActionDisabled(true);
      setPrimaryActionLabel(primaryActionInProcessTitle || 'Processing...');
      onConfirm(formState);
    }
  };

  const renderDuplicateLimit = () => {
    const input_value_cleaned =
      isNaN(parseInt(formValue)) || typeof formValue === 'undefined'
        ? 0
        : parseInt(formValue);
    let document_limit_clean = parseInt(TOTAL_DOCUMENT_LIMIT - totalNumber);
    document_limit_clean =
      isNaN(document_limit_clean) || typeof document_limit_clean === 'undefined'
        ? 0
        : document_limit_clean;

    if (totalNumber + input_value_cleaned <= TOTAL_DOCUMENT_LIMIT) {
      return null;
    }
    // Have special cases for wording for 0, 1, and N number of allowed duplicates
    if (document_limit_clean === 0) {
      return (
        <div>
          <div className={css(styles.duplicate_error_message)}>
            Cannot add any copies
          </div>
        </div>
      );
    }
    if (document_limit_clean === 1) {
      return (
        <div>
          <div className={css(styles.duplicate_error_message)}>
            Cannot add more than {document_limit_clean} copy
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className={css(styles.duplicate_error_message)}>
          Cannot add more than {document_limit_clean} copies
        </div>
      </div>
    );
  };

  const renderDuplicateDocumentTitleError = () => {
    let dictionary_of_document_titles = {};
    fields.forEach((field) => {
      if (typeof field.documentNamesObject !== 'undefined') {
        dictionary_of_document_titles = field.documentNamesObject;
      }
    });

    if (formRenameValue in dictionary_of_document_titles) {
      return (
        <div>
          <div className={css(styles.duplicate_error_message)}>
            Another document with that name exists, please choose a different
            name.
          </div>
        </div>
      );
    }
    return null;
  };

  const handleGetInputRef = (ref) => {
    ref.current.focus();
  };

  const fieldsProp = fields.map((field, index) => {
    const fieldProps = { ...field, editable: true };
    const customProps = {};

    if (index === 0) {
      customProps.getInputRef = handleGetInputRef;
    }

    return renderFormField(fieldProps, customProps);
  });
  return (
    <div className={css(styles.modalContainer)}>
      {title && <h1 className={css(styles.modalTitle)}>{title}</h1>}
      <div
        className={css(styles.modalBody, !title && styles.modalBodySmallMargin)}
      >
        <Form
          triggerOnMount
          fields={fieldsProp}
          onChange={handleFormChange}
          className={className}
        />
      </div>
      {renderDuplicateLimit()}
      {renderDuplicateDocumentTitleError()}
      <div className={css(styles.modalActions)}>
        {!disableCancel && (
          <Button cssStyle={styles.buttonAction} onClick={onCancel}>
            Cancel
          </Button>
        )}{' '}
        <Button
          cssStyle={styles.buttonAction}
          onClick={handleSubmit}
          disabled={!formValid || primaryActionDisabled}
          primary
          analyticsname={analyticsName}
        >
          {primaryActionLabel}
        </Button>
      </div>
    </div>
  );
};

FormFieldModal.propTypes = {
  title: PropTypes.string,
  primaryActionTitle: PropTypes.string,
  fields: PropTypes.array,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

FormFieldModal.defaultProps = {
  title: 'Field Form',
  fields: [],
  primaryActionTitle: 'Confirm',
  onCancel: () => {},
  onConfirm: () => {},
};

export default FormFieldModal;
