import { Contact } from './types';

export const isRelated = ({ related_contact_roles }: Contact) =>
  !!related_contact_roles.length;

export const getContactName = ({ first_name, last_name, data }: Contact) => {
  let firstName = first_name || '';
  if (!firstName && data && data.first_name) {
    firstName = data.first_name;
  }

  let lastName = last_name || '';
  if (!lastName && data && data.last_name) {
    lastName = data.last_name;
  }

  return `${firstName} ${lastName}`;
};
