import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

export default StyleSheet.create({
  docContainer: {
    padding: theme.unit * 2,
    margin: '0 auto',
  },
  docImage: {
    background: 'white',
    width: '100%',
    maxWidth: '100%',
    marginBottom: theme.unit,
    ':last-of-type': {
      marginBottom: '0',
    },
  },
  imgSetEmptyText: {
    lineHeight: '1.5em',
    fontSize: theme.fonts.size.small,
    textAlign: 'center',
    marginBottom: `${theme.unit * 2}px`,
  },
});
