import { useMutation, useQueryClient } from 'react-query';
import { APIError } from '~/src/utils/error';
import { useCurrentOrgFprint } from '../../user';
import { keys } from '../keys';
import { Project } from '../types';
import { getProjectUrl } from '../urls';

type Variables = {
  stack_saved_data?: Record<string, string>;
  clio_parent_id?: string;
};

export const usePatchProject = (projectId: number) => {
  const orgFprint = useCurrentOrgFprint();
  const queryClient = useQueryClient();
  return useMutation<Project, APIError, Variables>({
    onSuccess: () => {
      queryClient.invalidateQueries(keys.detail(projectId));
    },
    mutationFn: async (payload) => {
      const response = await fetch(getProjectUrl(orgFprint, projectId), {
        method: 'PATCH',
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new APIError('Failed to patch project in lawyaw', response);
      }

      return response.json();
    },
  });
};
