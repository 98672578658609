import React from 'react';
import { useSubscriptionV2Enabled } from '~/src/entities/user';
import { SettingsSubscription as SettingsSubscriptionV1 } from '../SettingsSubscriptionV1';
import { SettingsSubscriptionV2 } from './SettingsSubscriptionV2';

export const SettingsSubscriptionPageV2 = () =>
  useSubscriptionV2Enabled() ? (
    <SettingsSubscriptionV2 />
  ) : (
    <SettingsSubscriptionV1 />
  );
