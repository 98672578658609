import React from 'react';
import { css } from 'aphrodite';
import { CardTitle } from '~/src/components/Card';
import styles from './styles';

export const NoSubscriptionNeeded = () => {
  return (
    <>
      <CardTitle>No subscription</CardTitle>
      <div className={css(styles.settingsRow)}>
        This account does not require a subscription.
      </div>
    </>
  );
};
