import React from 'react';
import { Text } from '~/src/components/Typography';
import { ReactComponent as IconCheckmark } from '~/src/static/iconCheckmark.svg';

export interface SaveIndicatorProps {
  hasUnsavedChanges: boolean;
}

const SaveIndicator = ({ hasUnsavedChanges }: SaveIndicatorProps) => {
  const text = hasUnsavedChanges ? 'Unsaved changes' : 'All changes saved';
  return (
    <div className="mr-4 flex items-center gap-2">
      {!hasUnsavedChanges && <IconCheckmark />}
      <Text subdued>{text}</Text>
    </div>
  );
};

export default SaveIndicator;
