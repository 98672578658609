import { useMutation, UseMutationOptions } from 'react-query';
import { getProjectUrl } from '../urls';
import { Project } from '../types';
import { APIError } from '~/src/utils/error';
import { composeMutationOpts, fetchAndParse } from '../../utils';
import { useCurrentOrgFprint } from '../../user';
import { TaskStatusResponse } from '~/src/hooks/types';

type ProjectPayload = {
  projectId: number;
  stack_saved_data: Project['stack_saved_data'];
};

export const useUpdateProject = (
  options: UseMutationOptions<Project, APIError, ProjectPayload> = {},
) => {
  const orgFprint = useCurrentOrgFprint();

  return useMutation(
    composeMutationOpts(options, {
      mutationFn: async ({ projectId, stack_saved_data }) => {
        const response = await fetchAndParse<Project | TaskStatusResponse>(
          getProjectUrl(orgFprint, projectId),
          {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({ stack_saved_data }),
          },
        );
        return response as Project;
      },
    }),
  );
};
