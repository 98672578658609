import { useState, useEffect } from 'react';

import useMst from '~/src/hooks/useMst';

const useIntakePublishLink = (intakeId) => {
  const [loading, setLoading] = useState(true);
  const [publishLink, setPublishLink] = useState('');
  const [matterId, setMatterId] = useState();

  const { intakesStore } = useMst((store) => ({
    intakesStore: store.questionnaires,
  }));

  useEffect(() => {
    setLoading(true);
    intakesStore.publishQuestionnaire(intakeId, matterId).then((link) => {
      setPublishLink(link);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matterId]);

  const handleSetMatterId = (nextMatterId) => {
    setMatterId(nextMatterId);
  };

  return {
    loading,
    publishLink,
    setMatterId: handleSetMatterId,
  };
};

export default useIntakePublishLink;
