/* Libs */
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import qs from 'qs';

/* Hooks */

/* Containers */
import ChooseTemplateSetTableContainer from '~/src/containers/ChooseTemplateSetTable';

/* Constants */
import { LAYOUT_FOOTER_TYPES, useLayoutContext } from '~/src/contexts/Layout';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import { history } from '~/src/utils/history';

const CreateNewQuestionnaireMapTemplatesPageContainer = () => {
  /* State */

  const isTemplateSetsActive = false;

  const [selectedTemplateSet, setSelectedTemplateSet] = useState(null);
  const [selectedTemplates] = useState([]);

  const { showFooter, hideFooter, showToast } = useLayoutContext();

  /* Actions */
  const handleNext = () => {
    if (!selectedTemplateSet) {
      showToast(LAYOUT_TOAST_TYPES.error, {
        message: 'Please select a template set',
      });
    } else {
      const query = {};

      if (selectedTemplateSet) {
        query.templateSetId = selectedTemplateSet;
      }

      if (selectedTemplates.length > 0) {
        query.templates = selectedTemplates;
      }

      const searchQuery = qs.stringify(query);
      history.replace(`/questionnaire/new/create?${searchQuery}`);
    }
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleSelectTemplateSet = (id) => {
    setSelectedTemplateSet(id);
  };

  /* Effects */
  useEffect(() => {
    showFooter(LAYOUT_FOOTER_TYPES.action, {
      actionLabel: 'Next',
      cancelLabel: 'Back',
      onAction: handleNext,
      onCancel: handleCancel,
    });

    return hideFooter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplateSet]);

  return (
    <div className="container max-w-screen-lg mx-auto py-10">
      <div>
        <h1 className="text-xl font-bold leading-loose">
          Choose Your Templates
        </h1>
        <p className="text-base text-gray-500">
          We will convert your templates fields into questions for you.
        </p>
      </div>
      <div>
        <ol className="list-none mt-10">
          <li
            className={cn('text-m uppercase font-bold mr-5 py-5 inline-block', {
              'text-blue-500': isTemplateSetsActive,
              'border-b-4': isTemplateSetsActive,
              'border-blue-500': isTemplateSetsActive,
              'border-solid': isTemplateSetsActive,
            })}
          >
            Template Sets
          </li>
        </ol>
      </div>
      <div>
        <ChooseTemplateSetTableContainer onChange={handleSelectTemplateSet} />
      </div>
    </div>
  );
};

export default CreateNewQuestionnaireMapTemplatesPageContainer;
