import React, { FC } from 'react';
import cns from 'classnames';

type OnClick = (id: string) => void;

interface Props {
  title: string;
  id: string;
  onClick: OnClick;
  isActive?: boolean;
}

const DocumentButton: FC<Props> = ({
  title,
  id,
  onClick,
  isActive = false,
}) => {
  return (
    <button
      className={cns(
        'grow border border-gray-300 px-4 py-2 text-left rounded-md text-ellipsis overflow-hidden',
        {
          'border-black font-bold border-2': isActive,
        },
      )}
      onClick={() => onClick(id)}
    >
      {title}
    </button>
  );
};

export default DocumentButton;
