import * as React from 'react';
import { ReactComponent as DragHandleIcon } from '~/src/static/drag-handle-icon.svg';

type SelectionSidebarItemProps = {
  label: string;
  onClick: () => void;
  selected: boolean;
  actions: React.ReactNode;
};

export const SelectionSidebarItem = ({
  label,
  onClick,
  selected,
  actions,
}: SelectionSidebarItemProps) => {
  return (
    <div
      className={`draggable_side_document group bg-white rounded border mb-2 hover:bg-gray-100
        ${selected ? 'border-blue-600' : 'border-gray-300'}`}
    >
      <div className="flex" onClick={onClick}>
        <div className="flex-grow flex items-center font-medium truncate text-body">
          <div className="ml-1 flex items-center">
            <DragHandleIcon />
          </div>
          <div className="truncate py-3 pl-1">{label}</div>
        </div>
        <div className="flex items-center pr-2">{actions}</div>
      </div>
    </div>
  );
};
