import { types } from 'mobx-state-tree';

export const OrganizationMember = types
  .model({
    id: types.integer,
    admin: types.boolean,
    email: types.string,
    canShare: types.boolean,
    displayName: types.string,
    invitationPending: types.boolean,
  })
  .actions((self) => {
    const setRole = (role: string) => {
      self.admin = role === 'admin';
      self.canShare = role === 'admin';
    };

    return {
      setRole,
    };
  });
