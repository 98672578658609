/* Libraries */
import React from 'react';
import { Link } from 'react-router-dom';
import { css } from 'aphrodite';

/* Components */
import { Clipboard } from 'react-feather';
import Button from '~/src/components/Button';
import Card from '~/src/components/Card';

/* Styles */
import theme from '~/src/theme';
import styles from './styles';

const QuestionnairesTableEmpty = () => {
  return (
    <Card dynamic cssStyle={styles.intakesTableEmptyCardContainer}>
      <div className={css(styles.intakesTableEmptyContainer)}>
        <Clipboard color={theme.colors.chrome} size={40} />
        <div className={css(styles.iconHover)} />
        <h1 className={css(styles.emptyTitle)}>
          You don&apos;t have any questionnaires yet
        </h1>
        <h2 className={css(styles.emptySubtitle)}>
          Create a new questionnaire by click the button below
        </h2>
        <div className={css(styles.emptyAction)}>
          <Link to="/questionnaire/new">
            <Button primary>Create a New Intake</Button>
          </Link>
        </div>
      </div>
    </Card>
  );
};

export default QuestionnairesTableEmpty;
