/* Libs */
import { useEffect, useState } from 'react';

/* Hooks */
import useMst from '~/src/hooks/useMst';

const useFetchQuestionnaireForm = (intakeId, forceFetch = false) => {
  const { getQuestionnaire, fetchQuestionnaire } = useMst((store) => ({
    getQuestionnaire: store.questionnaires.getQuestionnaire,
    fetchQuestionnaire: store.questionnaires.fetchQuestionnaire,
  }));

  const [intake, setIntake] = useState({});

  useEffect(() => {
    const currentIntake = getQuestionnaire(intakeId);

    if (currentIntake && currentIntake.id) {
      setIntake(currentIntake);
    }

    if (!currentIntake || forceFetch) {
      fetchQuestionnaire(intakeId).then(setIntake);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading: !intake.id,
    intake,
  };
};

export default useFetchQuestionnaireForm;
