import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useObserver } from 'mobx-react';

/* Hooks */

import { useQuestionnairePublishLink } from '~/src/hooks/questionnaires';

/* Constants */
import { LAYOUT_MODAL_TYPES } from '~/src/components/PageLayout/Modals';

/* Components */
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Plus } from 'react-feather';
import TextareaAutosize from 'react-textarea-autosize';
import Button from '~/src/components/Button';
import { useLayoutContext } from '~/src/contexts/Layout';
import QuestionGroup from './QuestionGroup';

// const ANIMATION_DURATION = 400;

const QuestionGroups = (props) => {
  const { children, intake } = props;

  const questionGroups = useObserver(() => intake.sortedQuestionGroups || []);

  const onDragEnd = ({ source, destination }) => {
    intake.moveQuestionGroups(source.index, destination.index);
  };

  const handleExpand = () => {};

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {questionGroups.map((group, index) => (
                <Draggable key={group.id} draggableId={group.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={provided.draggableProps.style}
                      className={cn({
                        'opacity-75 bg-white': snapshot.isDragging,
                      })}
                    >
                      <QuestionGroup
                        key={group.id}
                        group={group}
                        onExpand={handleExpand}
                        dragHandleProps={provided.dragHandleProps}
                        isDragging={snapshot.isDragging}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {children}
    </div>
  );
};

QuestionGroups.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(QuestionGroup.propTypes)),
};

const QuestionnaireForm = ({ intake }) => {
  const { showModal } = useLayoutContext();

  const { publishLink } = useQuestionnairePublishLink(intake.id);

  const displayDescription = useObserver(() => intake.displayDescription);
  const displayTitle = useObserver(() => intake.displayTitle);

  const handleChange = intake.update;

  const handleAddNewQuestionSet = () => {
    intake.createNewQuestionGroup({
      sortOrder: intake.sortedQuestionGroups.length + 1,
    });
  };

  const AddQuestionSetButton = ({ onClick: handleClick }) => (
    <div
      className="group m-6 flex items-center justify-center transition-all cursor-pointer transition-all opacity-80 hover:opacity-100"
      onClick={handleClick}
    >
      <div className="rounded-full h-7 w-7 mr-4 inline-flex items-center justify-center bg-black text-white transition-all group-hover:bg-blue-600">
        <Plus size={18} color={'white'} />
      </div>
      <span className="text-base inline-block transition-all group-hover:text-blue-600">
        Add new question set
      </span>
    </div>
  );

  const handleTitleChange = (event) => {
    const value = event && event.target && event.target.value;
    // setDisplayTitle(value);
    handleChange({
      displayTitle: value || '',
    });
  };

  const handleTextareaChange = (event) => {
    const value = event && event.target && event.target.value;
    // intake.update({displayDescription: value });
    handleChange({
      displayDescription: value || '',
    });
  };

  const handlePreview = () => {
    window.open(publishLink, '_newtab');
  };

  const handlePublish = () => {
    showModal(LAYOUT_MODAL_TYPES.publishQuestionnaireForm, {
      intakeId: intake.id,
    });
  };

  return (
    <>
      <div className="flex justify-end">
        <div className="inline-flex items-center py-4">
          <Button className="mr-2" secondary onClick={handlePreview}>
            Preview
          </Button>
          <Button className="mr-2" primary onClick={handlePublish}>
            Publish
          </Button>
        </div>
      </div>

      <div className="bg-white flex-col justify-items-stretch py-5 rounded shadow-sm hover:shadow-lg">
        <div className="text-center m-4">
          <input
            className="p-4 w-full text-2xl font-bold text-center outline-none rounded cursor-text transition-all hover:bg-blueGray-100 focus:bg-blueGray-100"
            type="text"
            placeholder="Click here to add public title"
            value={displayTitle}
            onChange={handleTitleChange}
          />
        </div>
        <div className="text-center m-4">
          <TextareaAutosize
            className="p-4 w-full text-lg text-left outline-none rounded cursor-text transition-all hover:bg-blueGray-100 focus:bg-blueGray-100"
            type="text"
            placeholder="Click here to add public description"
            value={displayDescription}
            onChange={handleTextareaChange}
          />
        </div>
      </div>
      <QuestionGroups intake={intake}>
        <AddQuestionSetButton onClick={handleAddNewQuestionSet} />
      </QuestionGroups>
    </>
  );
};

QuestionnaireForm.propTypes = {
  id: PropTypes.string,
};

export default QuestionnaireForm;
