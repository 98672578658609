import React from 'react';
import { IconButton, Typography } from '@mui/material';
import { ListViewItemWrapper } from './ListViewItemWrapper';

export const ListViewItem: React.FC<{
  title: string;
  handleOnClick: () => void;
  buttonName: string;
}> = ({ title, handleOnClick, buttonName }) => {
  return (
    <ListViewItemWrapper>
      <Typography variant="body1" sx={{ flex: '1 1 0', fontWeight: 600 }}>
        {title}
      </Typography>
      <IconButton
        onClick={() => handleOnClick()}
        sx={(theme) => ({
          fontSize: 14,
          fontWeight: 600,
          padding: '6px 16px',
          background: theme.palette.grey[100],
          color: theme.palette.text.primary,
          '&:hover': {
            background: theme.palette.grey[200],
          },
        })}
      >
        {buttonName}
      </IconButton>
    </ListViewItemWrapper>
  );
};
