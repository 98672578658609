import React, { useCallback, useState } from 'react';
import {
  CreateContactModalProps,
  CreateContactModal,
} from './CreateContactModal';

type UseCreateContactModalArgs = {
  onContactCreated: CreateContactModalProps['onContactCreated'];
} & Partial<
  Omit<
    CreateContactModalProps,
    'onContactCreated' | 'firstName' | 'lastName' | 'onClose'
  >
>;

export const useCreateContactModal = ({
  onContactCreated,
  ...rest
}: UseCreateContactModalArgs) => {
  const [props, setProps] = useState<Omit<CreateContactModalProps, 'onClose'>>({
    open: false,
    onContactCreated,
    ...rest,
  });

  const toggle = useCallback(
    (props: Partial<Omit<CreateContactModalProps, 'open'>> = {}) =>
      setProps((p) => ({ ...p, ...props, open: !p.open })),
    [],
  );

  const modal = <CreateContactModal {...props} onClose={() => toggle()} />;

  return [toggle, modal] as const;
};
