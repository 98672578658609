/* Libraries */
import { types, flow } from 'mobx-state-tree';

/* Services */
import tagsService from '~/src/services/tags';
import { getOrgFprintFromStoreNode } from './utils';

const Tag = types
  .model('Tag', {
    templateId: types.maybeNull(types.string),
    tag: types.identifier,
    entityLabel: types.maybeNull(types.string),
    fieldLabel: types.maybeNull(types.string),
    entityType: types.maybeNull(types.string),
    fieldType: types.maybeNull(types.string),
    source: types.maybeNull(types.string),
  })
  .views((self) => {
    const getFormattedTagLabel = () => {
      return `${self.entityLabel} / ${self.fieldLabel}`;
    };

    return {
      getFormattedTagLabel,
      get prompt() {
        return self.fieldLabel;
      },
      get type() {
        return self.fieldType;
      },
    };
  });

const Tags = types
  .model('Tags', {
    dictionary: types.optional(types.map(Tag), {}),
  })
  .actions((self) => {
    const fetchTagByTemplateId = flow(function* fetchTagByTemplateId(
      templateId,
    ) {
      const res = yield tagsService.fetchByTemplateId(
        getOrgFprintFromStoreNode(self),
        templateId,
      );

      res.forEach((tag) => {
        if (!self.dictionary.get(tag.tag)) {
          self.dictionary.set(tag.tag, tag);
        }
      });

      const tagIds = res.map((tag) => tag.tag);

      return tagIds;
    });

    const fetchTagIds = flow(function* fetchTagIds(tagIds) {
      const res = yield tagsService.fetchTagIds(
        getOrgFprintFromStoreNode(self),
        tagIds,
      );

      res.forEach((tag) => {
        if (!self.dictionary.get(tag.tag)) {
          self.dictionary.set(tag.tag, tag);
        }
      });

      return tagIds;
    });

    return {
      fetchTagByTemplateId,
      fetchTagIds,
    };
  });

export { Tag, Tags, Tags as default };
