import { useMutation, useQueryClient } from 'react-query';
import { APIError } from '~/src/utils/error';
import { type Questionnaire } from '@clio/questionnaire-builder';
import { getQuestionnaireById } from '../urls';
import {
  getClioQuestionnaireAuthToken,
  questionnaireAuthorizationHeaders,
} from '../utils';
import { questionnaireQueryArgs } from '../queryArgs';

type UpdateClioQuestionnaire = {
  questionnaireId: string;
  clioQuestionnaire: Partial<Questionnaire>;
};

interface QuestionnaireListQueryData {
  pageParamters: unknown[];
  pages: {
    params: object;
    questionnaires: (Questionnaire & { total_questions: number })[];
  }[];
}

export const useUpdateClioQuestionnaire = (orgFprint: string) => {
  const queryClient = useQueryClient();
  return useMutation<Questionnaire, APIError, UpdateClioQuestionnaire>({
    onSuccess: (updatedItem) => {
      // for builder page
      queryClient.invalidateQueries(questionnaireQueryArgs.all);

      // for template listing page
      /* 
      queryClient.invalidateQueries not working on update mutation is a known bug in useInfiniteQuery invalidation in react query 3.
      The workaround is to use queryClient.setQueryData to manually update query data.
      */
      queryClient.setQueryData(
        questionnaireQueryArgs.list({ limit: 15 }),
        (qnListQueryData: QuestionnaireListQueryData | undefined) => {
          if (qnListQueryData && qnListQueryData.pages) {
            const matchedItem = qnListQueryData.pages
              .map((page) => page.questionnaires)
              .flat()
              .find((qn: Questionnaire) => qn.id === updatedItem.id);

            if (matchedItem) {
              const total_questions = updatedItem.sections_attributes.reduce(
                (accumulator, currentValue) =>
                  accumulator + currentValue.questions_attributes.length,
                0,
              );
              matchedItem.total_questions = total_questions;
            }
          }
          return qnListQueryData!;
        },
      );
    },
    mutationFn: async ({ questionnaireId, clioQuestionnaire }) => {
      const token = await getClioQuestionnaireAuthToken(orgFprint);
      const response = await fetch(getQuestionnaireById(questionnaireId), {
        method: 'PUT',
        headers: {
          ...questionnaireAuthorizationHeaders(token),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(clioQuestionnaire),
      });

      if (!response.ok) {
        const message = await response.json();

        switch (message.error) {
          case 'Validation failed: Sections show logic cannot reference a question in the same section':
            throw new APIError('section reordering invalid', message);
          case 'Validation failed: Sections questions show logic cannot reference a question in the same section':
          case 'Validation failed: Sections questions show logic cannot reference a question in a later section':
            throw new APIError('question reordering invalid', message);
          default:
            throw new APIError('Failed to update questionnaire', message);
        }
      }

      return response.json();
    },
  });
};
