import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Box,
  Divider,
  styled,
} from '@mui/material';
import React from 'react';
import { Eye } from 'react-feather';

import {
  ProjectStackDocument,
  ProjectStackFieldGroup,
} from '~/src/entities/project';
import { SectionIndicator } from './SectionIndicator';

type SectionsListProps = {
  expanded?: string;
  sections: ProjectStackFieldGroup[];
  getDocumentsForFields: (fields: string[]) => ProjectStackDocument[];
  onClickDocument: (document: ProjectStackDocument) => void;
  onClickSection: (group: ProjectStackFieldGroup) => void;
};

const StyledEyeIcon = styled(Eye)(`
    margin-right: 5px;
    min-width: 13.3px;
`);

export const SectionsList = ({
  expanded,
  sections,
  onClickSection,
  onClickDocument,
  getDocumentsForFields,
}: SectionsListProps) => (
  <>
    {sections.map((group) => (
      <Accordion
        key={group.gid}
        expanded={expanded === group.gid}
        onChange={() => onClickSection(group)}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          sx={(theme) => ({
            '&:hover': {
              background: theme.palette.blue[50],
            },
            background:
              expanded === group.gid ? theme.palette.blue[50] : '#fff',
          })}
        >
          <Box
            pr={0.5}
            flex={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Typography variant="body1">{group.label}</Typography>
            <Box width={'18px'} sx={{ textAlign: 'center' }}>
              <SectionIndicator fields={group.fields} />
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {getDocumentsForFields(group.fields).map((document) => {
            return (
              <Box
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
                key={document.docid}
                pb={1}
                onClick={() => onClickDocument(document)}
              >
                <Typography
                  variant={'caption'}
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  {document.title}
                  {document.subtitle
                    ? ', ' + document.subtitle
                    : document.subtitle}
                  <StyledEyeIcon size={13.3} />
                </Typography>
                <Divider
                  sx={(theme) => ({
                    marginTop: theme.spacing(1),
                    borderColor: '#E3E3E3',
                  })}
                />
              </Box>
            );
          })}
        </AccordionDetails>
      </Accordion>
    ))}
  </>
);
