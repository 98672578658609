import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

/* Hooks */
import useContact from '~/src/hooks/useContact';

/* Components */
import ContactSlideIn from '~/src/components/Contacts/ContactSlideIn';
import { validateContactFields } from '~/src/components/Contacts/utils';

const ContactSlideInContainer = (props) => {
  const { id, fieldValues, hideSlideIn, onSave } = props;

  const { contact, updateContact } = useContact(id, props.matterId);

  const [edit, setEdit] = useState(props.edit);
  const [formValue, setFormValue] = useState({});
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const handleEdit = () => setEdit(true);
  const handleCancelEdit = () => {
    setEdit(false);
  };
  const [invalidFormFields, setInvalidFormFields] = useState({});

  const getFormFieldValues = () => {
    const values = {};
    Object.keys(formValue.fields).forEach((key) => {
      values[key] = formValue.fields[key].value;
    });

    return values;
  };

  const addToInvalidFormFields = (field_error_key, new_item) => {
    const updatedInvalidFormFields = { ...invalidFormFields };
    updatedInvalidFormFields[field_error_key] = new_item;
    setInvalidFormFields(updatedInvalidFormFields);
  };

  const removeFromInvalidFormFields = (field_error_key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (invalidFormFields.hasOwnProperty(field_error_key)) {
      const updatedInvalidFormFields = { ...invalidFormFields };
      delete updatedInvalidFormFields[field_error_key];
      setInvalidFormFields(updatedInvalidFormFields);
    }
  };

  useEffect(() => {
    if (formValue.fields && contact) {
      validateContactFields(
        formValue,
        addToInvalidFormFields,
        removeFromInvalidFormFields,
        contact.type,
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue]);

  useEffect(() => {
    if (Object.keys(invalidFormFields).length === 0) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [invalidFormFields]);

  const handleFormChange = (value) => {
    setFormValue(value);
  };

  const handleSave = async () => {
    const newContact = getFormFieldValues();

    const updatedContact = await updateContact(id, newContact);

    if (hideSlideIn) {
      hideSlideIn();
    }

    if (onSave) {
      onSave(updatedContact);
    }
  };

  return contact && contact.id ? (
    <ContactSlideIn
      fieldValues={fieldValues}
      contact={contact}
      edit={edit}
      onFormChange={handleFormChange}
      onCancelEdit={handleCancelEdit}
      onEdit={handleEdit}
      onSave={handleSave}
      submitDisabled={submitDisabled}
      invalidFormFields={invalidFormFields}
    />
  ) : null;
};

ContactSlideInContainer.defaultProps = {
  hideSlideIn: () => {},
  edit: false,
};

ContactSlideInContainer.propTypes = {
  hideSlideIn: PropTypes.func,
  id: PropTypes.number,
  edit: PropTypes.bool,
};

export default ContactSlideInContainer;
