import { useMutation } from 'react-query';
import { APIError } from '~/src/utils/error';
import {
  getClioQuestionnaireAuthToken,
  questionnaireAuthorizationHeaders,
} from '../utils';
import { getQuestionnaireSubmissionApprovalByIdUrl } from '../urls';
import { type QuestionnaireSubmission } from '@clio/questionnaire-builder';

export const useClioQuestionnaireSubmissionApprove = (
  orgFprint: string,
  submissionId: string,
) => {
  return useMutation<QuestionnaireSubmission, APIError, void>({
    mutationFn: async () => {
      const token = await getClioQuestionnaireAuthToken(orgFprint);
      const response = await fetch(
        getQuestionnaireSubmissionApprovalByIdUrl(submissionId),
        {
          method: 'POST',
          headers: {
            ...questionnaireAuthorizationHeaders(token),
            'Content-Type': 'application/json',
          },
        },
      );

      if (!response.ok) {
        throw new APIError(
          'Failed to approve submission as submission not in a state that can be approved',
          response,
        );
      }

      return response.json();
    },
  });
};
