import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useUpdatePaymentMethodModal } from '~/src/entities/subscription';

type UpdatePaymentMethodProps = {
  last4: string;
};

export const UpdatePaymentMethod = ({ last4 }: UpdatePaymentMethodProps) => {
  const [show, modal] = useUpdatePaymentMethodModal();
  return (
    <>
      {modal}
      <Box pb={3}>
        <Box pb={1}>
          <Typography variant={'subheading'}>Payment Method</Typography>
        </Box>
        <Typography variant={'body1'}>Card ending in {last4}</Typography>
      </Box>
      <Button size={'large'} onClick={show} variant={'outlined'}>
        Update payment method
      </Button>
    </>
  );
};
