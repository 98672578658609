import env from '~/src/utils/env';

export type QueryParams = {
  search: string;
  sort_by: string;
  order: 'desc' | 'asc';
  limit: number;
};

const getBaseUrl = (orgFprint: string) =>
  `${env.apiUrl}/clients/api/v1/org/${orgFprint}/matter/`;

export const getMatterUrl = (orgFprint: string, matterId: string | number) =>
  `${getBaseUrl(orgFprint)}${matterId}/`;
