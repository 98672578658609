import React from 'react';
import { DialogContainer } from '~/src/components/Modal/mui';
import { DeleteQuestionnaireDialogContents } from './DeleteQuestionnaireDialogContents';

interface Props {
  open: boolean;
  questionnaireId: string;
  onClose: () => void;
  onConfirmDelete: () => void;
}

export const DeleteQuestionnaireDialogModal = ({
  open,
  questionnaireId,
  onClose,
  onConfirmDelete,
}: Props) => {
  return (
    <DialogContainer open={open} onClose={onClose}>
      <DeleteQuestionnaireDialogContents
        questionnaireId={questionnaireId}
        onClose={onClose}
        onConfirmDelete={onConfirmDelete}
      />
    </DialogContainer>
  );
};
