import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';
import { X } from 'react-feather';

import styles, { SLIDE_IN_ANIMATION_DURATION } from './styles';

class SlideInOverlay extends React.Component {
  state = {
    hide: false,
  };

  reset = () => {
    this.setState({ hide: false });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.show && !this.props.show) {
      this.setState({ hide: true });
      setTimeout(this.reset, SLIDE_IN_ANIMATION_DURATION);
    }
  }

  render() {
    const { children, show, large, onClose } = this.props;
    const { hide } = this.state;

    return (
      <div
        className={css(
          styles.slideInOverlayContainer,
          !hide && !show && styles.slideInOverlayContainerHide,
          hide && styles.slideInOverlayContainerHideAnimate,
        )}
      >
        <div
          className={css(
            styles.slideInOverlayClickCatcher,
            show && styles.slideInOverlayClickCatcherShow,
          )}
          onClick={onClose}
        />
        <div
          className={css(
            styles.slideInOverlayPanel,
            show && styles.slideInOverlayPanelShow,
            large && styles.slideInOverlayPanelLarge,
          )}
        >
          <div className={css(styles.slideInOverlayExit)} onClick={onClose}>
            <X color={'#7B8396'} size={16} />
          </div>
          {children}
        </div>
      </div>
    );
  }
}

SlideInOverlay.defaultProps = {
  show: false,
  large: false,
  onClose: () => {},
};

SlideInOverlay.propTypes = {
  large: PropTypes.bool,
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

export default SlideInOverlay;
