import React from 'react';
import { css } from 'aphrodite';

import { CardTitle, CardBreak } from '~/src/components/Card';
import Button from '~/src/components/Button';
import { Label } from '~/src/components/Inputs';

import styles from '../styles';
import { UpdatePaymentMethodButton } from '../UpdatePaymentMethodButton';
import { RenewSubscriptionButton } from '../RenewSubscriptionButton';

export const SubscriptionDetails = ({
  subscription,
  onUpdateSeats,
  nextInvoice,
}) => {
  const { quantity } = subscription;
  const seatLabel = quantity > 1 ? 'seats' : 'seat';
  const endDate = subscription.nextInvoiceEndFormatted();

  const { ccId } = subscription;

  const canceled = subscription.isCanceled();

  const handleUpdateSeats = (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    if (onUpdateSeats) {
      onUpdateSeats();
    }
  };

  return (
    <>
      <CardTitle bold>Subscription</CardTitle>
      <div className={css(!canceled && styles.settingsRow)}>
        <Label className={css(styles.settingsLabel)} label={'Plan'}>
          {quantity} {seatLabel}
        </Label>
      </div>
      {!canceled && (
        <div className={css(styles.settingsRow)}>
          <Button primary onClick={handleUpdateSeats}>
            Update seats
          </Button>
        </div>
      )}
      {canceled && (
        <RenewSubscriptionButton
          message={`Your subscription is set to cancel on ${endDate}`}
        />
      )}
      <div className={css(styles.settingsRow)}>
        <CardBreak />
      </div>
      {!canceled && (
        <div className={css(styles.settingsRow)}>
          <Label className={css(styles.settingsLabel)} label={'Next invoice'}>
            ${nextInvoice} on {endDate}
          </Label>
        </div>
      )}
      <div className={css(styles.settingsRow)}>
        <Label className={css(styles.settingsLabel)} label={'Payment method'}>
          Card ending in {ccId}
        </Label>
        <UpdatePaymentMethodButton />
      </div>
    </>
  );
};
