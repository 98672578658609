export class APIError extends Error {
  public response: Response;

  constructor(message: string, response: Response) {
    super(message);
    this.name = 'APIError';
    this.response = response;
  }
}

export class APIErrorFailedToParse extends APIError {
  constructor(response: Response) {
    super('failed to parse', response);
  }
}

export class APIErrorNotOk extends APIError {
  constructor(response: Response) {
    super('status not ok', response);
  }
}
