import React, { useRef } from 'react';

interface DragAndDropProps {
  children: React.ReactChild;
  onUpload: (files: File[]) => any;
  drag: React.RefObject<HTMLDivElement>;
  setDragging: (isDragging: boolean) => void;
}

const DragAndDropFiles = ({
  children,
  drag,
  onUpload,
  setDragging,
}: DragAndDropProps) => {
  const drop = useRef<HTMLDivElement>(null);

  const handleDragEnter = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target !== drag.current) {
      setDragging(true);
    }
  };

  const handleDragLeave = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target === drag.current) {
      setDragging(false);
    }
  };

  const handleDragOver = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setDragging(true);
  };

  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const fileList = e.dataTransfer?.files;
    if (fileList && fileList.length) {
      onUpload(Array.from(fileList));
    }

    setDragging(false);
  };

  React.useEffect(() => {
    if (!drop.current) return;

    const element = drop.current;
    element.addEventListener('dragover', handleDragOver);
    element.addEventListener('drop', handleDrop);
    element.addEventListener('dragenter', handleDragEnter);
    element.addEventListener('dragleave', handleDragLeave);

    return () => {
      element.removeEventListener('dragover', handleDragOver);
      element.removeEventListener('drop', handleDrop);
      element.removeEventListener('dragenter', handleDragEnter);
      element.removeEventListener('dragleave', handleDragLeave);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div ref={drop}>{children}</div>;
};

export default DragAndDropFiles;
