import React, { Component } from 'react';
import { ChevronDown, Trash } from 'react-feather';
import TextWithIndicator from '~/src/components/SignerSelection/TextWithIndicator';
import SignerMenuItem from '~/src/components/SignerSelection/ChooseSignerMenuItem';
import {
  signersPropType,
  getAllSigners,
} from '~/src/components/SignerSelection/utils';
import theme from '~/src/theme';
import T from 'prop-types';
import { SIGNER_INDICATOR_COLORS } from '~/src/components/SignerSelection/constants';
import assert from 'assert';
import { Signers, Email, Signer } from '~/src/models';

interface Props {
  // The signers in this document
  signers: Signers;
  // The current person assigned to this element
  signerEmail: string | null;
  // What to do if the attorney chooses to sign this field now
  onSignNow: () => void;
  // What to do if this signature element is deleted
  onDelete: () => void;
  // What to do when a new signer is selected to be assigned to this element
  onSignerSelected: (signerEmail: string) => void;
}

interface State {
  isDropDownOpen: boolean;
  signerEmail: Email | null;
  bottom: '0' | 'none';
}

class ChooseSignerMenu extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { signerEmail } = props;
    this.state = {
      isDropDownOpen: false,
      signerEmail,
      bottom: 'none',
    };
  }

  static propTypes = {
    signers: signersPropType,
    onSignNow: T.func.isRequired,
  };

  // The ref of the actual dropdown containing the list of signers
  dropDownRef = React.createRef<HTMLDivElement>();

  /**
   * When triggered, opens the dropdown if closed (or closes it if open). Then,
   * determines where the dropdown should be placed relative to the container and
   * updates the styling for the dropdown div accordingly
   */
  toggleDropDown = () => {
    this.setState({ isDropDownOpen: !this.state.isDropDownOpen }, () => {
      if (this.dropDownRef.current) {
        const iframeApp = this.dropDownRef.current.closest('.App');
        const { y, height } = this.dropDownRef.current.getBoundingClientRect();
        /**
         * If the menu's bottom border will appear below the app's height, change
         * `bottom` property that we will use to position the dropdown so that it
         * is not cut off. We include 10px as a margin of safety.
         */
        this.setState({
          bottom: y + height + 10 > iframeApp!.clientHeight ? '0' : 'none',
        });
      }
    });
  };

  selectSigner = (signer: Signer) => {
    this.setState({ signerEmail: signer.email });
    this.props.onSignerSelected(signer.email);
  };

  selectMeNow = () => {
    assert('me' in this.props.signers, '`me` not found in daters!');
    this.setState({ signerEmail: this.props.signers.me.email });
    this.props.onSignNow();
  };

  deleteAssignment = () => {
    this.props.onDelete();
  };

  renderSignerMenuItems = (signers: Signers) => {
    const signerDetailsArray = [];
    if ('me' in signers) {
      signerDetailsArray.push(
        <SignerMenuItem
          key={`${signers.me.email}-now`}
          onSignerSelected={this.selectMeNow}
          indicatorColor="#000"
          first
          last={false}
        >
          Me now
        </SignerMenuItem>,
      );
    }

    if ('others' in signers) {
      signers.others.forEach((signer, i) => {
        const first = i === 0;
        const last = i === signers.others.length - 1;
        signerDetailsArray.push(
          <SignerMenuItem
            key={signer.email}
            onSignerSelected={() => this.selectSigner(signer)}
            indicatorColor={
              SIGNER_INDICATOR_COLORS[i % SIGNER_INDICATOR_COLORS.length]!
            }
            first={first}
            last={last}
          >
            {signer.name}
          </SignerMenuItem>,
        );
      });
    }

    return signerDetailsArray;
  };

  render() {
    const { signers } = this.props;
    const { signerEmail } = this.state;
    const allSigners = getAllSigners(signers);
    assert(
      signerEmail === null ||
        allSigners.map((e) => e.email).includes(signerEmail),
      'SignerMenu.tsx has an unexpected signer!',
    );

    const assignedSigner = allSigners.find((s) => s.email === signerEmail) || {
      name: '',
    };

    return (
      <div
        style={{
          padding: 13,
          display: 'flex',
          flexDirection: 'column',
          gap: 8,
          width: 220,
          fontSize: theme.fonts.size.small,
        }}
      >
        <div style={{ display: 'flex' }}>
          <h5
            style={{
              margin: 0,
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              fontSize: theme.fonts.size.extraSmall,
              color: '#000',
            }}
          >
            Signature field
          </h5>
          <Trash
            size={theme.fonts.size.regular}
            color={theme.colors.charcoal}
            style={{
              cursor: 'pointer',
            }}
            onClick={this.deleteAssignment}
          />
        </div>
        <div>Select signer</div>
        <div
          onClick={this.toggleDropDown}
          style={{
            display: 'flex',
            borderRadius: theme.borderRadius,
            border: theme.border,
            font: theme.fonts.size.regular,
            padding: 7,
            alignItems: 'center',
          }}
        >
          <div style={{ flexGrow: 1 }}>
            {signerEmail ? (
              <TextWithIndicator
                indicatorColor={
                  SIGNER_INDICATOR_COLORS[
                    allSigners.map((e) => e.email).indexOf(signerEmail)!
                  ]!
                }
                style={{
                  padding: 0,
                }}
              >
                {assignedSigner.name}
              </TextWithIndicator>
            ) : (
              'Choose signer'
            )}
          </div>
          {this.state.signerEmail === null && <ChevronDown />}
        </div>
        <div
          style={{
            position: 'relative',
            width: '100%',
          }}
        >
          {this.state.isDropDownOpen && (
            <div
              ref={this.dropDownRef}
              style={{
                position: 'absolute',
                bottom: this.state.bottom,
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'white',
                border: this.state.isDropDownOpen ? theme.border : 'none',
                borderRadius: this.state.isDropDownOpen
                  ? theme.borderRadius
                  : '0px',
                width: '100%',
                boxShadow: this.state.isDropDownOpen ? theme.boxShadow : 'none',
              }}
            >
              {this.renderSignerMenuItems(signers)}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ChooseSignerMenu;
