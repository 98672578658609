import { types, flow } from 'mobx-state-tree';
import signaturesService from '~/src/services/signatures';
import { keysToSnakeCase } from '~/src/utils/objects';
import { WhoSigns } from '../models';
import { getOrgFprintFromStoreNode } from './utils';
import {
  getSignaturePackageIdentifier,
  signature_status_map,
} from '~/src/utils/dataTransformers';
import createPaginatedModel from '~/src/stores/composers/createPaginatedModel';

const recipientModel = types.model({
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  font: types.maybeNull(types.string),
  submitted: types.maybeNull(types.boolean),
  signOrder: types.maybeNull(types.integer),
  text: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  image: types.maybeNull(types.string),
  xfdfSignature: types.maybeNull(types.string),
});

export const SignaturePackage = types
  .model('SignaturePackage', {
    id: types.integer,
    identifier: types.identifier,
    lawyawProjectId: types.integer,
    lawyawClientId: types.maybeNull(types.integer),
    isActive: types.maybeNull(types.boolean),
    isUnifiedEsign: types.maybeNull(types.boolean),
    fprint: types.string,
    createdAt: types.string,
    updatedAt: types.maybeNull(types.string),
    packageStatus: types.maybeNull(types.string),
    recipients: types.optional(types.array(recipientModel), []),
    lawyawDocumentIds: types.array(types.integer),
    whoSigns: types.maybeNull(types.integer),
    freeze: types.boolean,
    title: types.maybeNull(types.string),
    customOrder: types.maybeNull(types.boolean),
    currentStep: types.maybeNull(types.integer),
    emailSubject: types.maybeNull(types.string),
    emailBody: types.maybeNull(types.string),
    emailCC: types.maybeNull(
      types.union(
        types.string,
        types.array(
          types.model({
            emailId: types.string,
          }),
        ),
      ),
    ),
    auditTrail: types.array(
      types.model({
        actionText: types.maybeNull(types.string),
        ipAddress: types.string,
        recipient: recipientModel,
        timestamp: types.string,
        timezoneOffset: types.integer,
      }),
      [],
    ),
    lawyawDocuments: types.array(
      types
        .model({
          subtitle: types.maybeNull(types.string),
          id: types.integer,
          templateId: types.maybeNull(types.integer),
          title: types.string,
          sortOrder: types.maybeNull(types.integer),
          originalFileUrl: types.maybeNull(types.string),
          pages: types.maybeNull(
            types.array(
              types.model({
                id: types.maybeNull(types.integer),
                pageNumber: types.maybeNull(types.integer),
                backgroundUrl: types.maybeNull(types.string),
                elements: types.maybeNull(
                  types.array(
                    types.maybeNull(
                      types.model({
                        id: types.maybeNull(types.integer),
                        signaturePageId: types.maybeNull(types.integer),
                        top: types.maybeNull(types.number),
                        left: types.maybeNull(types.number),
                        width: types.maybeNull(types.number),
                        imageWidth: types.maybeNull(
                          types.union(types.number, types.string),
                        ),
                        imageHeight: types.maybeNull(
                          types.union(types.number, types.string),
                        ),
                        lineHeight: types.maybeNull(types.number),
                        value: types.maybeNull(
                          types.union(types.boolean, types.string),
                        ),
                        height: types.maybeNull(types.number),
                        inputType: types.maybeNull(types.integer),
                        recipient: types.maybeNull(recipientModel),
                      }),
                    ),
                  ),
                  [],
                ),
              }),
            ),
          ),
        })
        .volatile(() => ({
          originalPDfBlob: null,
        }))
        .actions((self) => {
          const updatePdfBlob = (pdfBlob) => {
            self.originalPDfBlob = pdfBlob;
          };
          return {
            updatePdfBlob,
          };
        }),
    ),
  })
  .actions((self) => {
    const setCustomOrder = (newVal) => {
      self.customOrder = newVal;
    };
    return {
      setCustomOrder,
    };
  });

const SignaturePackageStore = types
  .model('SignaturePackageStore', {
    isLoading: false,
    error: '',
    list: types.optional(types.array(SignaturePackage), []),
  })
  .actions((self) => {
    const createSignaturePackage = flow(function* createSignaturePackage(
      files,
      projectId,
    ) {
      const res = yield signaturesService.createSignaturePackage(
        getOrgFprintFromStoreNode(self),
        files,
        projectId,
      );
      if (!res.task_id) {
        self.addItem(res.identifier, res);
        self.addItemToList(res);
      }
      return res;
    });

    const createCustomSignaturePackage = flow(
      function* createCustomSignaturePackage(documentsData) {
        const res = yield signaturesService.createCustomSignaturePackage(
          getOrgFprintFromStoreNode(self),
          documentsData,
        );
        self.addItem(res.identifier, res);
        self.addItemToList(res);
        return res;
      },
    );

    const createSignaturePackageFromBlob = flow(
      function* createSignaturePackage(pdfBlobs) {
        const res = yield signaturesService.createSignaturePackageFromBlob(
          getOrgFprintFromStoreNode(self),
          pdfBlobs,
        );
        self.addItem(res.identifier, res);
        self.addItemToList(res);
        return res;
      },
    );

    const createSignaturePackageFromExistingProject = flow(
      function* createSignaturePackageFromExistingProject(projectId, data) {
        const res =
          yield signaturesService.createSignaturePackageFromExistingProject(
            getOrgFprintFromStoreNode(self),
            projectId,
            data,
          );
        self.addItem(res.identifier, res);
        self.addItemToList(res);
        return res;
      },
    );

    const getSignaturePackage = (id) => {
      const identifier = getSignaturePackageIdentifier(id);
      return self.getItem(identifier);
    };

    const setSignaturePackage = (item) => {
      const identifier = self.addItem(item.identifier, item);
      return identifier;
    };

    const updateSignaturePackageDocuments = (signaturePackage) => {
      const updatedSignaturePackage = signaturePackage;
      setSignaturePackage(updatedSignaturePackage);
    };

    const updateSignaturePackage = flow(function* updateSignaturePackage(
      currentUserEmail,
      signaturePackageData,
    ) {
      if (signaturePackageData.recipients) {
        signaturePackageData.recipients = signaturePackageData.recipients.map(
          (recipient, orderIndex) => {
            let signOrder = signaturePackageData.customOrder
              ? orderIndex + 1
              : 1;

            if (orderIndex === 0) {
              // "Me" (sender)
              signOrder = 0;
            }
            recipient.signOrder = signOrder;
            return recipient;
          },
        );
      }

      const currentPackage = self.getSignaturePackage(
        signaturePackageData.signaturePackageId || signaturePackageData.id,
      );

      const updatedSignaturePackage =
        yield signaturesService.updateSignaturePackage(
          getOrgFprintFromStoreNode(self),
          signaturePackageData,
          currentPackage,
        );

      setSignaturePackage(updatedSignaturePackage);

      return updatedSignaturePackage;
    });

    const updateDocumentForPackage = flow(function* updateDocumentForPackage(
      signaturePackage,
      currentDocument,
      pdfBlob,
    ) {
      const updatedSignaturePackage =
        yield signaturesService.updateDocumentForPackage(
          getOrgFprintFromStoreNode(self),
          signaturePackage.lawyawProjectId,
          signaturePackage.fprint,
          currentDocument,
          pdfBlob,
        );
      setSignaturePackage(updatedSignaturePackage);
    });

    const addDocumentsToPackage = flow(function* addDocumentsToPackage(
      signaturePackageId,
      pdfBlobs,
    ) {
      const currentSignaturePackage =
        self.getSignaturePackage(signaturePackageId);
      const updatedSignaturePackage =
        yield signaturesService.addDocumentsToPackage(
          getOrgFprintFromStoreNode(self),
          signaturePackageId,
          pdfBlobs,
          currentSignaturePackage,
        );
      setSignaturePackage(updatedSignaturePackage);
      return updatedSignaturePackage;
    });

    const reorderDocuments = flow(function* reorderDocuments(
      signaturePackage,
      documents,
      docIds,
    ) {
      yield signaturesService.reorderDocuments(
        getOrgFprintFromStoreNode(self),
        signaturePackage,
        docIds,
      );
      updateSignaturePackageDocuments(signaturePackage, documents);
    });

    const assignSignatureField = flow(function* assignSignatureField(
      signaturePackage,
      signatureElementData,
    ) {
      const updatedSignaturePackage =
        yield signaturesService.assignSignatureField(
          getOrgFprintFromStoreNode(self),
          signaturePackage.lawyawProjectId,
          signaturePackage.fprint,
          signatureElementData,
        );
      setSignaturePackage(updatedSignaturePackage);
      return updatedSignaturePackage;
    });

    const deleteAssignedSignatureField = flow(
      function* deleteAssignedSignatureField(signaturePackage, elementId) {
        const updatedSignaturePackage =
          yield signaturesService.deleteAssignedSignatureField(
            getOrgFprintFromStoreNode(self),
            signaturePackage.lawyawProjectId,
            signaturePackage.fprint,
            elementId,
          );
        setSignaturePackage(updatedSignaturePackage);
        return updatedSignaturePackage;
      },
    );

    const uploadUserSignatureImage = flow(function* uploadUserSignatureImage(
      signaturePackage,
      recipientId,
      imageParams,
      signatureElementData,
    ) {
      /* eslint-disable camelcase, no-param-reassign */
      const { signature_image_url } =
        yield signaturesService.uploadUserSignatureImage(
          getOrgFprintFromStoreNode(self),
          signaturePackage.lawyawProjectId,
          signaturePackage.fprint,
          recipientId,
          imageParams.blob,
        );
      signatureElementData[0].image_height = imageParams.height;
      signatureElementData[0].image_width = imageParams.width;
      signatureElementData[0].recipient.image = signature_image_url;
      /* eslint-enable camelcase, no-param-reassign */
      const updatedSignaturePackage =
        yield signaturesService.assignSignatureField(
          getOrgFprintFromStoreNode(self),
          signaturePackage.lawyawProjectId,
          signaturePackage.fprint,
          signatureElementData,
        );
      setSignaturePackage(updatedSignaturePackage);
      return updatedSignaturePackage;
    });

    const uploadUserSignatureImageXfdf = flow(
      function* uploadUserSignatureImageXfdf(
        signaturePackage,
        recipientId,
        imageParams,
        signatureElementData,
      ) {
        /* eslint-disable camelcase, no-param-reassign */
        const { signature_image_xfdf } =
          yield signaturesService.uploadUserSignatureImageXfdf(
            getOrgFprintFromStoreNode(self),
            signaturePackage.lawyawProjectId,
            signaturePackage.fprint,
            recipientId,
            imageParams.xfdfSignature,
          );
        signatureElementData[0].image_height = imageParams.height;
        signatureElementData[0].image_width = imageParams.width;
        signatureElementData[0].recipient.image = signature_image_xfdf;
        /* eslint-enable camelcase, no-param-reassign */
        const updatedSignaturePackage =
          yield signaturesService.assignSignatureField(
            getOrgFprintFromStoreNode(self),
            signaturePackage.lawyawProjectId,
            signaturePackage.fprint,
            signatureElementData,
          );
        setSignaturePackage(updatedSignaturePackage);
        return updatedSignaturePackage;
      },
    );

    const getDocumentsForPackage = (signaturePackageId) => {
      return getSignaturePackage(signaturePackageId).lawyawDocuments;
    };

    const getElementsForPackage = (signaturePackageId) => {
      return getDocumentsForPackage(signaturePackageId)
        .map((document) => {
          return document.pages
            .map((page) => {
              return page.elements;
            })
            .flat();
        })
        .flat();
    };

    const assignJustMeSignatureField = flow(
      function* assignJustMeSignatureField(signaturePackage, currentUserEmail) {
        const elements = getElementsForPackage(signaturePackage.id)
          .filter((element) => {
            return (
              element.recipient && element.recipient.email == currentUserEmail
            );
          })
          .map((element) => {
            const updatedElement = keysToSnakeCase(element);
            updatedElement.is_signed = true;
            updatedElement.recipient.font = 'prox_esign';
            updatedElement.recipient.text = ' ';
            updatedElement.is_assigned = true;
            updatedElement.recipient.submitted = true;
            return updatedElement;
          });
        const updatedSignaturePackage =
          yield signaturesService.assignSignatureField(
            getOrgFprintFromStoreNode(self),
            signaturePackage.lawyawProjectId,
            signaturePackage.fprint,
            elements,
          );
        setSignaturePackage(updatedSignaturePackage);
      },
    );

    const sendSignaturePackage = flow(function* sendSignaturePackage(
      currentSignaturePackage,
    ) {
      const signaturePackage = yield signaturesService.sendSignaturePackage(
        getOrgFprintFromStoreNode(self),
        currentSignaturePackage,
      );
      setSignaturePackage(signaturePackage);
    });

    const cancelOrRemoveSignaturePackage = flow(
      function* cancelOrRemoveSignaturePackage(currentSignaturePackageId) {
        const signaturePackage = getSignaturePackage(currentSignaturePackageId);
        signaturePackage.isActive = false;
        signaturePackage.packageStatus = 'Cancelled';
        setSignaturePackage(signaturePackage);

        yield signaturesService.cancelOrRemoveSignaturePackage(
          getOrgFprintFromStoreNode(self),
          signaturePackage,
        ); // Status Code: 204 No Content
      },
    );

    const removeSignaturePackage = flow(function* removeSignaturePackage(
      currentSignaturePackageId,
    ) {
      const signaturePackage = getSignaturePackage(currentSignaturePackageId);
      self.removeItem(signaturePackage.identifier);

      yield signaturesService.cancelOrRemoveSignaturePackage(
        getOrgFprintFromStoreNode(self),
        signaturePackage,
      ); // Status Code: 204 No Content
    });

    const getElementsForPage = (signaturePackageId, signaturePageId) => {
      return getDocumentsForPackage(signaturePackageId)
        .map((document) => {
          return document.pages
            .filter((page) => {
              return page.id === signaturePageId;
            })
            .map((page) => {
              return page.elements;
            })
            .flat();
        })
        .flat();
    };

    const printCombined = (documentsHTML) => {
      return signaturesService.printCombined(
        getOrgFprintFromStoreNode(self),
        documentsHTML,
      );
    };

    const fetchSignaturePackages = flow(function* () {
      self.isLoading = true;
      try {
        self.error = '';
        const signaturePackages = yield signaturesService.getSignaturePackages(
          getOrgFprintFromStoreNode(self),
        );
        self.list = signaturePackages;
        self.isLoading = false;
        return signaturePackages;
      } catch (error) {
        console.error('Failed to fetch signature packages', error);
        self.error = error.message;
        self.isLoading = false;
        return false;
      }
    });

    const fetchSignaturePackage = flow(function* (
      projectId,
      signaturePackageFprint,
    ) {
      try {
        self.error = '';
        const signaturePackage = yield signaturesService.getSignaturePackage(
          getOrgFprintFromStoreNode(self),
          projectId,
          signaturePackageFprint,
        );
        self.addItem(signaturePackage.identifier, signaturePackage);
        self.addItemToList(signaturePackage);
        self.isLoading = false;
        return signaturePackage;
      } catch (error) {
        console.error('Failed to fetch signature package', error);
        self.error = error.message;
        self.isLoading = false;
        return false;
      }
    });

    const fetch = flow(function* (query = {}) {
      return yield self.paginate(query);
    });

    return {
      createSignaturePackage,
      createCustomSignaturePackage,
      createSignaturePackageFromExistingProject,
      fetchSignaturePackage,
      fetchSignaturePackages,
      getSignaturePackage,
      getDocumentsForPackage,
      updateSignaturePackage,
      reorderDocuments,
      assignSignatureField,
      uploadUserSignatureImage,
      uploadUserSignatureImageXfdf,
      deleteAssignedSignatureField,
      assignJustMeSignatureField,
      sendSignaturePackage,
      updateDocumentForPackage,
      addDocumentsToPackage,
      getElementsForPackage,
      getElementsForPage,
      printCombined,
      cancelOrRemoveSignaturePackage,
      removeSignaturePackage,
      createSignaturePackageFromBlob,
      fetch,
    };
  })
  .views((self) => {
    const tableList = () => {
      return self.list.map((signaturePackage) => ({
        id: signaturePackage.id,
        lawyawProjectId: signaturePackage.lawyawProjectId,
        createdAt: signaturePackage.createdAt,
        packageStatus: signature_status_map[signaturePackage.packageStatus],
        currentStep: signaturePackage.currentStep,
        title: signaturePackage.title,
        documents: signaturePackage.lawyawDocuments,
      }));
    };

    const isOthers = (whoSignType) => {
      return whoSignType === WhoSigns.Others;
    };

    return {
      tableList,
      isOthers,
    };
  });

const PaginatedSignaturePackageStore = createPaginatedModel(
  'PaginatedSignaturePackageStore',
  SignaturePackageStore,
  SignaturePackage,
  { paginate: signaturesService.getSignaturePackages },
);

export default PaginatedSignaturePackageStore;
