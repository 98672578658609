import { useObserver } from 'mobx-react';
import { Instance } from 'mobx-state-tree';
import moment from 'moment';
import { useIsClioAuthenticated } from '~/src/hooks/useClio';
import { Matter } from '~/src/stores/matterStore';

const SYNC_BEFORE_THRESHOLD_SECONDS = 60;

export const useShouldShowMatterSync = (matter?: Instance<typeof Matter>) => {
  const isAuthenticated = useIsClioAuthenticated();
  return useObserver(() => {
    if (!isAuthenticated) return false;
    if (!matter) return false;
    if (!matter.lastSynced) return false;
    return moment(matter.lastSynced).isBefore(
      moment().subtract(SYNC_BEFORE_THRESHOLD_SECONDS, 'seconds'),
    );
  });
};
