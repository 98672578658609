/* Libraries */
import React from 'react';

/* Components */
import Label from './Label';

/* Styles */
import styles from './styles';

const StaticText = ({ label, value, hint, count, placeholder, onClick }) => (
  <Label
    label={label}
    hint={hint}
    count={count}
    onClick={onClick}
    cssStyle={placeholder && !value && styles.staticTextPlaceholder}
  >
    {value
      ? value.split('\n').map((line, index, array) => (
          <React.Fragment key={index}>
            {line}
            {index < array.length - 1 && <br />}
          </React.Fragment>
        ))
      : placeholder}
  </Label>
);

export default StaticText;
