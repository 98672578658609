import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { QuestionnaireSubmissionTableCardList } from './QuestionnaireSubmissionTableCardList';
import {
  useClioQuestionnaireSubmissions,
  useUpdateQuestionnaireSubmission,
} from '~/src/entities/questionnaires';
import { useRenameRelatedQuestionnaireSubmission } from '~/src/entities/questionnaires/hooks';
import {
  QuestionnaireStatus,
  type QuestionnaireSubmission,
} from '@clio/questionnaire-builder';
import usePageScroll from '~/src/hooks/usePageScroll';
import LoadMoreDisplayText from '~/src/components/LoadMoreDisplayText';
import { useCurrentOrgFprint } from '~/src/entities/user';
import { VALIDATION_TYPES } from '~/src/utils/validation';
import { LAYOUT_MODAL_TYPES } from '~/src/components/PageLayout/Modals';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import { useLayoutContext } from '~/src/contexts';
import { EmptyTableMessage } from './EmptyTableMessage';
import { DeleteSubmissionDialogModal } from '~/src/entities/questionnaires/components/DeleteSubmissionDialog';
import { useUIFlagEnabled } from '~/src/entities/uiFlags';
import { QuestionnaireSubmissions } from './QuestionnaireSubmissions';
import matterService from '~/src/services/matter';
import { LoadingOverlay } from '~/src/components/PageLayout';
import { getQuestionnaireDocumentNumbers } from '~/src/entities/questionnaires/services';

export const QuestionnaireSubmissionTable: React.FC<{
  status: QuestionnaireStatus[];
  onViewDetails: (submission: QuestionnaireSubmission) => void;
}> = ({ status, onViewDetails }) => {
  const orgFprint = useCurrentOrgFprint();
  const pageScroll = usePageScroll();
  const useDeprecatedTable = useUIFlagEnabled('deprecatedQuestionnairesTable');
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [submission, setSubmission] =
    React.useState<QuestionnaireSubmission | null>(null);
  const [matterIdMap, setMatterIdMap] =
    React.useState<{ [key: string]: string }>();
  const [matterIdsLoaded, setMatterIdsLoaded] = React.useState(false);
  const [questionnaireDocumentMapping, setQuestionnaireDocumentMapping] =
    React.useState<{ [key: string]: number }>();
  const [
    questionnaireDocumentMappingLoaded,
    setQuestionnaireDocumentMappingLoaded,
  ] = React.useState(false);

  const {
    data,
    isError,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useClioQuestionnaireSubmissions(orgFprint, {
    limit: 15,
    status,
  });

  const { showToast, showModal, hideModal } = useLayoutContext();

  const loadTableDependancies = async () => {
    const matterIdTitleMap = await matterService.getMatterIdTitleMap({
      orgFprint,
    });
    setMatterIdMap(matterIdTitleMap);
    setMatterIdsLoaded(true);

    const questionnaireDocNumbers = await getQuestionnaireDocumentNumbers(
      orgFprint,
    );
    setQuestionnaireDocumentMapping(questionnaireDocNumbers);
    setQuestionnaireDocumentMappingLoaded(true);
  };

  useEffect(() => {
    if (data) {
      loadTableDependancies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const { mutate: updateSubmission } =
    useUpdateQuestionnaireSubmission(orgFprint);

  const patchRelatedQuestionnaireSubmission =
    useRenameRelatedQuestionnaireSubmission(orgFprint);

  const handleSubmissionRename = (qs: QuestionnaireSubmission) => {
    const updateSubmissionTitle = async (form: {
      fields: { name: { value: string } };
    }) => {
      const { value: title } = form.fields.name;
      updateSubmission(
        {
          submissionId: `${qs.id}`,
          submissionUpdate: { title },
        },
        {
          onError: () => {
            showToast(LAYOUT_TOAST_TYPES.error, {
              message: 'Something went wrong, please try again later.',
            });
          },
          onSuccess: () => {
            patchRelatedQuestionnaireSubmission.mutate({ qs, title });
            showToast(LAYOUT_TOAST_TYPES.success, {
              message: 'Live questionnaire title updated',
            });
            hideModal();
          },
        },
      );
    };

    showModal(LAYOUT_MODAL_TYPES.formField, {
      title: 'Rename live questionnaire',
      primaryActionTitle: 'Save',
      fields: [
        {
          label: 'Name',
          id: 'name',
          type: 'text',
          defaultValue: qs.title,
          validation: VALIDATION_TYPES.present,
        },
      ],
      onConfirm: updateSubmissionTitle,
    });
  };

  const handleSubmissionDelete = (submission: QuestionnaireSubmission) => {
    setShowDeleteModal(true);
    setSubmission(submission);
  };

  useEffect(() => {
    if ((pageScroll === 0 || pageScroll >= 85) && !isLoading) {
      fetchNextPage();
    }
  }, [fetchNextPage, isLoading, pageScroll]);

  if (isError || (!isLoading && !data)) {
    return (
      <Box margin="auto">
        <Typography textAlign={'center'} variant="h2">
          Live questionnaires will show up here.
        </Typography>
        <Typography textAlign={'center'} variant="subtitle1">
          Cannot retrieve live questionnaires, please try again later.
        </Typography>
      </Box>
    );
  }

  const submissions: QuestionnaireSubmission[] =
    data?.pages.flatMap((page) => page.submissions) || [];

  const isEmpty = !isLoading && !submissions.length;

  if (!matterIdsLoaded || !questionnaireDocumentMappingLoaded) {
    return <LoadingOverlay title="Loading Details..." subtitle="Please wait" />;
  }

  return (
    <>
      {submission && (
        <DeleteSubmissionDialogModal
          open={showDeleteModal}
          submission={submission}
          onClose={() => setShowDeleteModal(false)}
        />
      )}
      <Box
        sx={{
          margin: '0px auto !important',
          maxWidth: '1080px !important',
          width: ' 100% !important',
        }}
      >
        {isEmpty ? (
          <EmptyTableMessage />
        ) : (
          <>
            {useDeprecatedTable ? (
              <QuestionnaireSubmissionTableCardList
                submissions={submissions}
                onRename={handleSubmissionRename}
                onDelete={handleSubmissionDelete}
                onViewDetails={onViewDetails}
              />
            ) : (
              <QuestionnaireSubmissions
                empty={isEmpty}
                submissions={submissions}
                visibleItems={submissions.length}
                noBorder={false}
                hideHeader={true}
                handleEditSubmission={onViewDetails}
                handleRenameSubmission={handleSubmissionRename}
                handleDeleteSubmission={handleSubmissionDelete}
                matterIdMap={matterIdMap as { [key: string]: string }}
                questionnaireDocumentMapping={
                  questionnaireDocumentMapping as { [key: string]: number }
                }
              />
            )}
            <LoadMoreDisplayText
              loading={isLoading || isFetchingNextPage}
              done={!hasNextPage}
              onLoadMore={fetchNextPage}
            />
          </>
        )}
      </Box>
    </>
  );
};
