import { useMutation } from 'react-query';
import { APIError } from '~/src/utils/error';
import { useInvalidateQuery } from '../../utils';
import { documentQueryArgs } from '../queryArgs';
import { getTagMapsUrl } from '../urls';
import { type Document } from '@clio/questionnaire-builder';

type GetDocumentFields = {
  templateIds: string[];
};

export const useGetDocumentFields = (orgFprint: string) =>
  useMutation<Document[], APIError, GetDocumentFields>({
    onSuccess: useInvalidateQuery(documentQueryArgs.all),
    mutationFn: async ({ templateIds }) => {
      const response = await fetch(getTagMapsUrl(orgFprint), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ template_ids: templateIds }),
      });

      if (!response.ok)
        throw new APIError(
          'Failed to retrieve tags for selected documents',
          response,
        );

      return response.json();
    },
  });
