import React from 'react';
import { Heading } from '~/src/components/Typography';
import { classNames } from '~/src/components/SignaturePackage/styles';
import { TextInput, TextArea } from '~/src/components/Inputs';
import styles from '~/src/pages/Signatures/Signatures/styles';
import { css } from 'aphrodite';

interface Props {
  emailSubject: string;
  setEmailSubject: React.Dispatch<React.SetStateAction<string>>;
  emailMessage: string;
  setEmailMessage: React.Dispatch<React.SetStateAction<string>>;
}

const EmailBody: React.FC<Props> = ({
  emailSubject,
  setEmailSubject,
  emailMessage,
  setEmailMessage,
}) => {
  return (
    <div className={`${classNames.card} mb-8`}>
      <div className="p-4 pb-0">
        <Heading>Message for signers</Heading>
      </div>
      <div className="p-4">
        <TextInput
          label="Signature package title"
          placeholder="Email subject"
          value={emailSubject}
          onChange={setEmailSubject}
        />
        <TextArea
          // @ts-expect-error
          label="Message (optional)"
          placeholder="Include an optional email message..."
          value={emailMessage}
          minRows={5}
          className={css(styles.emailMessageField)}
          onChange={setEmailMessage}
        />
      </div>
    </div>
  );
};

export default EmailBody;
