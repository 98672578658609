/* Services */
import tagsService from '~/src/services/api/tagsService';

/* Stores */

/* Utils */
import { mapV2TagFromApi } from '~/src/utils/dataTransformers';

function tags() {
  const fetchByTemplateId = async (orgFprint, templateId) => {
    const res = await tagsService.fetchTags(orgFprint, {
      templateIds: [templateId],
    });
    return res.map(mapV2TagFromApi);
  };

  const fetchTagIds = async (orgFprint, tagIds) => {
    const res = await tagsService.fetchTags(orgFprint, { tagIds });
    return res.map(mapV2TagFromApi);
  };

  return {
    fetchTagIds,
    fetchByTemplateId,
  };
}

export default tags();
