import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { useCurrentOrgFprint } from '~/src/entities/user';
import { composeQueryOpts, fetchAndParse } from '~/src/entities/utils';
import { APIError } from '~/src/utils/error';
import { keys } from '../keys';
import { UpcomingInvoiceParams, UpcomingInvoiceV2 } from '../types';
import { getUpcomingInvoiceV2URLWithParams } from '../urls';

export const useFetchUpcomingInvoiceV2 = () => {
  const orgFprint = useCurrentOrgFprint();
  const client = useQueryClient();
  return async (params: UpcomingInvoiceParams, updateCache = true) => {
    const response = await fetchAndParse<UpcomingInvoiceV2>(
      getUpcomingInvoiceV2URLWithParams(orgFprint, params),
    );
    if (updateCache) client.setQueryData(keys.upcoming(params), response);
    return response;
  };
};

export const useUpcomingInvoiceV2 = (
  params: UpcomingInvoiceParams,
  options: Partial<UseQueryOptions<UpcomingInvoiceV2, APIError>> = {},
) => {
  const orgFprint = useCurrentOrgFprint();

  return useQuery(
    composeQueryOpts(options, {
      queryKey: keys.upcoming(params),
      queryFn: () =>
        fetchAndParse(getUpcomingInvoiceV2URLWithParams(orgFprint, params)),
    }),
  );
};
