/* Libraries */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

/* Components */
import { ccFormat, checkIsNumber } from '~/src/utils/strings';
import TextInput from './TextInput';

/* Utils */

const CreditCardInput = React.forwardRef(
  (
    {
      id,
      cssStyle,
      style,
      className,
      LeftIcon,
      label,
      value,
      hint,
      count,
      error,
      editable,

      noMargin,

      onRemove,
      onChange,
      onFocus,
      onBlur,
    },
    ref,
  ) => {
    const handleRemove = () => {
      onRemove({ id });
    };

    const handleChange = (value) => {
      if (value.length === 0 || checkIsNumber(value)) {
        onChange(ccFormat(value));
      }

      return false;
    };

    const [, setFocus] = useState(false);

    const handleOnFocus = () => {
      onFocus();
      setFocus(true);
    };
    const handleOnBlur = () => {
      onBlur();
      setFocus(false);
    };

    return (
      <TextInput
        ref={ref}
        id={id}
        required={true}
        cssStyle={cssStyle}
        style={style}
        className={className}
        LeftIcon={LeftIcon}
        placeholder={'xxxx xxxx xxxx xxxx'}
        label={label}
        autoComplete={'cc-number'}
        value={value}
        type={'text'}
        hint={hint}
        count={count}
        error={error}
        editable={editable}
        noMargin={noMargin}
        onRemove={handleRemove}
        onChange={handleChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
      />
    );
  },
);

CreditCardInput.propTypes = {
  cssStyle: PropTypes.object,
  className: PropTypes.string,
  LeftIcon: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.string,
  hint: PropTypes.string,
  count: PropTypes.number,
  editable: PropTypes.bool,
  noMargin: PropTypes.bool,
  onRemove: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

CreditCardInput.defaultProps = {
  editable: true,
  noMargin: false,
  onRemove: () => {},
  onFocus: () => {},
  onBlur: () => {},
};

export default CreditCardInput;
