import React, { FC } from 'react';
import ReactTooltip from 'react-tooltip';
import Button from '~/src/components/Button';
import { Step } from '~/src/components/SignaturePackage/constants';
import { WhoSigns } from '~/src/models';

export interface FooterProps {
  step: Step;
  whoSigns: WhoSigns;
  valid: boolean;
  invalidReason: string;
  onNextClick: () => void;
  onBackClick: () => void;
}

/**
 * Renders the footer at the bottom of the page that the user
 * can use to take action on the wizard.
 */

const stepLabel = (step: Step, whoSigns: WhoSigns) => {
  if (step === 'send-email') return 'Send request';
  if (step === 'add-signatures' && whoSigns === WhoSigns.JustMe) return 'Done';
  return 'Next';
};

const Footer: FC<FooterProps> = ({
  valid,
  invalidReason,
  step,
  whoSigns,
  onNextClick,
  onBackClick,
}) => {
  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white p-4 border-t border-gray-200">
      <div className="flex justify-end">
        {step !== 'select-documents' && (
          <Button onClick={onBackClick}>Back</Button>
        )}
        {!valid && (
          <ReactTooltip id="foo" place="top" effect="solid">
            {invalidReason!}
          </ReactTooltip>
        )}
        <Button
          primary
          className="ml-4"
          disabled={!valid}
          onClick={onNextClick}
          data-tip
          data-for="foo"
        >
          {stepLabel(step, whoSigns)}
        </Button>
      </div>
    </div>
  );
};

export default Footer;
