/* Libraries */
import React from 'react';

/* Hooks */
import useMst from '~/src/hooks/useMst';
import { useObserver } from 'mobx-react';
import useTemplateDefaultValuesListActions from '~/src/hooks/useTemplateDefaultValuesListActions';

/* Components */
import { TemplateDefaultValuesListWrapper } from '~/src/components/TemplateDefaultValuesList';
import { TOTAL_DOCUMENT_LIMIT } from '../utils/constants';

const TemplateDefaultValuesListContainer = () => {
  const {
    sidebarItemTotalNumber,
    containedInSidebar,
    addFormTemplateToSelectionSidebar,
  } = useMst((store) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useObserver(() => ({
      sidebarItemTotalNumber: store.sidebarItems.items.length,
      containedInSidebar: store.sidebarItems.containedInSidebar,
      addFormTemplateToSelectionSidebar:
        store.templateDefaultValues.addFormTemplateToSelectionSidebar,
    })),
  );

  useObserver(() => ({
    sidebarItemTotalNumber,
  }));

  const {
    rename,
    delete: handleDelete,
    duplicate,
    edit,
  } = useTemplateDefaultValuesListActions();

  return (
    <TemplateDefaultValuesListWrapper
      addFormTemplateToSelectionSidebar={addFormTemplateToSelectionSidebar}
      containedInSidebar={containedInSidebar}
      rowActions={{
        rename,
        delete: handleDelete,
        duplicate,
        edit,
      }}
      sidebarItemTotalNumber={sidebarItemTotalNumber}
      sidebarItemTotalNumberLimit={TOTAL_DOCUMENT_LIMIT}
    />
  );
};

export default TemplateDefaultValuesListContainer;
