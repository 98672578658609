import React, { useState, useEffect } from 'react';
import { Plus, X } from 'react-feather';

/* Components */
import Button from '~/src/components/Button';
import Card from '~/src/components/Card';

import TextInput from './TextInput';
import TextArea from './TextArea';
import Label from './Label';
import theme from '~/src/theme';

const MultipleChoiceOptionsInput = (props) => {
  const { value, onChange, optionLabel, optionValueLabel } = props;

  const [options, setOptions] = useState(value || []);

  useEffect(() => {
    onChange(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const handleAddOption = () => {
    const nextOptions = [
      ...options,
      {
        label: '',
        value: '',
      },
    ];

    setOptions(nextOptions);
  };

  const renderOption = (option, index) => {
    const { label, value } = option;

    const handleLabelChange = (value) => {
      const nextOptions = [...options];
      nextOptions[index].label = value;
      setOptions(nextOptions);
    };

    const handleValueChange = (value) => {
      const nextOptions = [...options];
      nextOptions[index].value = value;
      setOptions(nextOptions);
    };

    const handleRemove = () => {
      const nextOptions = [...options];
      nextOptions.splice(index, 1);
      setOptions(nextOptions);
    };

    return (
      <li
        key={`option-${index}`}
        style={{
          marginBottom: theme.unit * 2,
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Card dynamic hover>
          <i
            onClick={handleRemove}
            style={{ position: 'absolute', top: theme.unit, right: theme.unit }}
          >
            <X size={theme.fonts.size.regular} color={theme.colors.charcoal} />
          </i>
          <TextInput
            label={optionLabel}
            onChange={handleLabelChange}
            value={label}
          />
          <TextArea
            label={optionValueLabel}
            onChange={handleValueChange}
            value={value}
          />
        </Card>
      </li>
    );
  };

  const mostRecentOption = options[options.length - 1];
  const isButtonDisabled =
    options.length > 0 &&
    (mostRecentOption.label.length < 1 || mostRecentOption.value.length < 1);

  return (
    <>
      {options && <ol>{options.map(renderOption)}</ol>}
      <Button Icon={Plus} onClick={handleAddOption} disabled={isButtonDisabled}>
        Add Option
      </Button>
    </>
  );
};

const MultipleChoiceOptions = (props) => {
  const {
    label,
    hint,
    count,
    error,
    editable,
    labelCssStyle,
    noMargin,
    value,
    onChange,
    optionLabel,
    optionValueLabel,
  } = props;

  return (
    <Label
      label={label}
      hint={hint}
      count={count}
      error={error}
      focus={focus}
      editable={editable}
      cssStyle={labelCssStyle}
      noMargin={noMargin}
    >
      <MultipleChoiceOptionsInput
        value={value}
        onChange={onChange}
        optionLabel={optionLabel}
        optionValueLabel={optionValueLabel}
      />
    </Label>
  );
};

MultipleChoiceOptions.defaultProps = {
  optionLabel: 'Option Label',
  optionValueLabel: 'Option Value',
  onChange: () => {},
};

export default MultipleChoiceOptions;
