import React from 'react';

import { css } from 'aphrodite';

import styles from './styles';
import Spinner from './Spinner';

type SpinnerWithTitleProps = {
  title?: string;
  subtitle?: string;
};

export const SpinnerWithTitle = ({
  title,
  subtitle,
}: SpinnerWithTitleProps) => (
  <div>
    <Spinner />
    {title ? <h1 className={css(styles.title)}>{title}</h1> : null}
    {subtitle ? <h2 className={css(styles.subtitle)}>{subtitle}</h2> : null}
  </div>
);
