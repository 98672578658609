import React, { useCallback, useState } from 'react';
import {
  ConfirmPlanChangeModal,
  ConfirmPlanChangeModalProps,
} from './ConfirmPlanChangeModal';

export const useConfirmPlanChangeModal = (
  existing: ConfirmPlanChangeModalProps['existing'],
  onConfirm: () => void,
) => {
  const [open, setOpen] = useState(false);
  const [nextPlan, setNextPlan] =
    useState<ConfirmPlanChangeModalProps['next']>();

  const toggle = useCallback((next?: ConfirmPlanChangeModalProps['next']) => {
    if (next) setNextPlan(next);
    setOpen((o) => !o);
  }, []);

  const modal = (
    <ConfirmPlanChangeModal
      existing={existing}
      next={nextPlan!}
      open={open}
      onConfirm={onConfirm}
      onClose={() => setOpen(false)}
    />
  );

  return [toggle, modal] as const;
};
