import { StyleSheet } from 'aphrodite';

export const ANIMATION_DURATION = 500;

export default StyleSheet.create({
  overlay: {
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    background: 'rgba(245,250,255,0.1)',
    transition: `background ${ANIMATION_DURATION}ms linear`,
  },
  overlayHidden: {
    display: 'none',
  },
  overlayShow: {
    background: 'rgba(245,250,255,0.62)',
  },
});

export const customizeOpacity = (alpha) =>
  StyleSheet.create({
    customOpacity: {
      background: `rgba(245,250,255,${alpha})`,
    },
  }).customOpacity;
