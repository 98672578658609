import React from 'react';
import { Box, Typography } from '@mui/material';
import { ProjectStackField } from '~/src/entities/project';

export type FieldHeaderHintProps = {
  projectField: ProjectStackField;
};

export const FieldHeaderHint = ({ projectField }: FieldHeaderHintProps) => {
  return (
    <Box>
      <Typography
        variant="smallLabel"
        color={'grey.600'}
        sx={{
          fontWeight: 400,
        }}
      >
        {projectField.hint}
      </Typography>
    </Box>
  );
};
