import React, { ElementType, FC } from 'react';
import T from 'prop-types';
import cn from 'classnames';

interface Props {
  htmlElement?: ElementType;
  italic?: boolean;
  strong?: boolean;
  subdued?: boolean;
  style?: object;
  variant?: 'caption' | 'display' | 'body' | 'button';
}

const Text: FC<Props> = ({
  children,
  htmlElement: Element = 'span',
  italic = false,
  strong = false,
  subdued = false,
  style = {},
  variant = 'body',
}) => {
  return (
    <Element
      className={cn({
        'text-gray-900': !subdued,
        'text-gray-600': subdued,
        'font-regular': !strong,
        'font-semibold': strong,
        'text-body': variant === 'body',
        'text-button font-semibold': variant === 'button',
        'text-caption': variant === 'caption',
        'text-display': variant === 'display',
        italic,
      })}
      style={style}
    >
      {children}
    </Element>
  );
};

Text.propTypes = {
  htmlElement: T.oneOf(['div', 'span', 'a', 'p'] as const),
  italic: T.bool,
  strong: T.bool,
  subdued: T.bool,
  variant: T.oneOf(['body', 'button', 'display', 'caption'] as const),
};

export default Text;
