import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { css } from 'aphrodite';

/* Components */
import Triangle from '~/src/components/Triangle';
import theme from '~/src/theme';
import Label from './Label';

import styles from './styles';

const defaultCustomStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: theme.unit * 4,
    border: 'none',
    boxShadow: 'none',
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: '1px',
    background: '#FFFFFF',
    border: theme.border,
    boxShadow: '0 6px 8px 0 rgba(35,40,54,0.10)',
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    padding: '12px 16px',
    borderBottom: theme.border,
    textAlign: 'left',
  }),
  option: (provided) => ({
    ...provided,
    padding: '12px 16px',
    borderBottom: theme.border,
  }),
};

const customTheme = (selectTheme) => ({
  ...selectTheme,
  colors: {
    ...selectTheme.colors,
    border: theme.colors.bluebird,
    borderRadius: theme.borderRadius,
    primary: theme.colors.bluebird,
  },
});

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Triangle />
    </components.DropdownIndicator>
  );
};

const SelectInput = ({
  value,
  options,
  onChange,
  onInputChange,
  onFocus,
  onBlur,
  hint,
  count,
  error,
  noOptionsMessage,
  editable,
  multiple,
  mergeValues,
  label,
  required,
  isClearable,
  placeholder,
  cssStyle,
  customComponents,
  customStyles,
  noMargin,
  noDropdownIcon,
  ...rest
}) => {
  const [focus, setFocus] = useState(false);

  const handleChange = (event, action) => {
    if (multiple) {
      let values = event && event.length > 0 ? event.map((v) => v.value) : [];
      if (mergeValues) {
        values = values.join(',');
      }
      onChange(values, action);
    } else {
      const value = event && event.value ? event.value : null;
      onChange(value, action);
    }
  };

  const handleOnFocus = () => {
    onFocus();
    setFocus(true);
  };
  const handleOnBlur = () => {
    onBlur();
    setFocus(false);
  };

  let sortedSelectedValues;
  if (multiple) {
    sortedSelectedValues = [];
    if (value) {
      // value is not null nor undefined
      // value is an array of Id string, e.g. ["32", "33", "24"]
      for (let i = 0; i < value.length; i++) {
        const found = options.find((t) => t.value === value[i]);
        if (found) {
          sortedSelectedValues.push(found);
        }
      }
    }
  } else {
    sortedSelectedValues = options.filter(
      (option) => option.value === value,
    )[0];
  }

  const handleClick = (event) => {
    event.stopPropagation();
  };

  const DropdownIndicatorIcon = noDropdownIcon ? null : DropdownIndicator;

  return (
    <Label
      label={label}
      hint={hint}
      count={count}
      error={error}
      focus={focus}
      editable={editable}
      cssStyle={cssStyle}
      noMargin={noMargin}
      required={required}
    >
      {!editable && !multiple && (
        <p className={css(styles.textInput, styles.textInputLabelStatic)}>
          {value}
        </p>
      )}
      {(editable || multiple) && (
        <Select
          styles={{
            ...defaultCustomStyles,
            ...customStyles,
          }}
          isMulti={!!multiple}
          noOptionsMessage={noOptionsMessage}
          placeholder={placeholder}
          components={{
            DropdownIndicator: DropdownIndicatorIcon,
            ...customComponents,
          }}
          isClearable={isClearable}
          value={sortedSelectedValues}
          onChange={handleChange}
          onInputChange={onInputChange}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          options={options}
          onClick={handleClick}
          theme={customTheme}
          isDisabled={!editable}
          {...rest}
        />
      )}
    </Label>
  );
};

SelectInput.propTypes = {
  noOptionsMessage: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onInputChange: PropTypes.func,
  editable: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
};

SelectInput.defaultProps = {
  noOptionsMessage: () => 'No matches found.',
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  onInputChange: () => {},
  isClearable: false,
  editable: true,
  options: [],
};

export default SelectInput;
