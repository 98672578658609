import { useMutation, UseMutationOptions } from 'react-query';
import { getProjectContactUrl } from '../urls';
import { APIError } from '~/src/utils/error';
import { composeMutationOpts, fetcher } from '../../utils';
import { useCurrentOrgFprint } from '../../user';

type Variables = {
  projectId: number;
  contactId: number;
  role: string;
};

export const useRemoveContactFromProject = (
  options: UseMutationOptions<unknown, APIError, Variables> = {},
) => {
  const orgFprint = useCurrentOrgFprint();
  return useMutation(
    composeMutationOpts(options, {
      mutationFn: ({ projectId, contactId, role }) =>
        fetcher(getProjectContactUrl(orgFprint, projectId, contactId, role), {
          method: 'DELETE',
        }),
    }),
  );
};
