import { Box, OutlinedInput } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { FormField } from '~/src/components/FormField';
import { getExpiryDateFromSubmittedFormValues } from './utils';
import { CCExpInput } from './CCExpInput';
import { CCNumberInput } from './CCNumber';
import { PaymentFormValues } from './types';

const CC_NUMBER_VALIDATOR = (value: string) =>
  value.length >= 15 ? undefined : 'Invalid credit card number';

const CC_EXPIRY_VALIDATOR = (value: string) => {
  const { month, year } = getExpiryDateFromSubmittedFormValues(value);

  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth() + 1;

  if (isNaN(month) || isNaN(year)) return 'Invalid expiry date';

  if (year < currentYear) return 'Invalid expiry date';

  if (year === currentYear && month < currentMonth)
    return 'Invalid expiry date';
};

const CC_CVC_VALIDATOR = (value: string) =>
  value.length >= 3 ? undefined : 'Invalid CVC';

const CC_ZIP_VALIDATOR = (value: string) =>
  /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value) ? undefined : 'Invalid Zip Code';

export const PaymentForm = () => {
  return (
    <Box sx={{ containerType: 'inline-size' }}>
      <Box pb={2}>
        <Controller<PaymentFormValues>
          name={'number'}
          defaultValue={''}
          rules={{
            required: 'This field is required',
            validate: CC_NUMBER_VALIDATOR,
          }}
          render={({ field, fieldState }) => (
            <FormField
              labelProps={{ htmlFor: 'credit-card-number' }}
              required
              error={fieldState.error?.message}
              label={'Credit card number'}
            >
              <OutlinedInput
                {...field}
                id={'credit-card-number'}
                inputMode={'numeric'}
                type={'tel'}
                autoComplete={'cc-number'}
                placeholder="XXXX XXXX XXXX XXXX"
                inputComponent={CCNumberInput}
              />
            </FormField>
          )}
        />
      </Box>
      <Box
        sx={() => ({
          ['@container (max-width: 540px)']: { display: 'flex', gap: 2 },
        })}
      >
        <Box pb={2} flex={1}>
          <Controller<PaymentFormValues>
            name={'exp'}
            defaultValue={''}
            rules={{
              required: 'This field is required',
              validate: CC_EXPIRY_VALIDATOR,
            }}
            render={({ field, fieldState }) => (
              <FormField
                required
                labelProps={{ htmlFor: 'credit-card-expiry' }}
                label={'Expiration date'}
                error={fieldState.error?.message}
              >
                <OutlinedInput
                  id={'credit-card-expiry'}
                  autoComplete="cc-exp"
                  placeholder={'MM/YYYY'}
                  inputComponent={CCExpInput}
                  {...field}
                />
              </FormField>
            )}
          />
        </Box>
        <Box pb={2} flex={1}>
          <Controller<PaymentFormValues>
            name={'cvc'}
            defaultValue={''}
            rules={{
              required: 'This field is required',
              validate: CC_CVC_VALIDATOR,
            }}
            render={({ field, fieldState }) => (
              <FormField
                labelProps={{ htmlFor: 'credit-card-cvc' }}
                required
                label={'CVC'}
                error={fieldState.error?.message}
              >
                <OutlinedInput
                  id={'credit-card-cvc'}
                  inputMode="numeric"
                  type={'tel'}
                  inputProps={{ maxLength: 4 }}
                  autoComplete={'cc-cvc'}
                  placeholder={'XXX'}
                  {...field}
                />
              </FormField>
            )}
          />
        </Box>
      </Box>
      <Box pb={2}>
        <Controller<PaymentFormValues>
          name={'address_zip'}
          defaultValue={''}
          rules={{
            required: 'This field is required',
            validate: CC_ZIP_VALIDATOR,
          }}
          render={({ field, fieldState }) => (
            <FormField
              required
              labelProps={{ htmlFor: 'billing-zip' }}
              label={'Billing zip code'}
              error={fieldState.error?.message}
            >
              <OutlinedInput id={'billing-zip'} {...field} />
            </FormField>
          )}
        />
      </Box>
      <Box pb={2}>
        <Controller<PaymentFormValues>
          name={'address_city'}
          defaultValue={''}
          rules={{ required: 'This field is required' }}
          render={({ field, fieldState }) => (
            <FormField
              required
              labelProps={{ htmlFor: 'billing-address' }}
              label={'City'}
              error={fieldState.error?.message}
            >
              <OutlinedInput id={'billing-address'} {...field} />
            </FormField>
          )}
        />
      </Box>
      <Box pb={2}>
        <Controller<PaymentFormValues>
          name={'address_state'}
          defaultValue={''}
          rules={{ required: 'This field is required' }}
          render={({ field, fieldState }) => (
            <FormField
              required
              labelProps={{ htmlFor: 'billing-state' }}
              label={'State'}
              error={fieldState.error?.message}
            >
              <OutlinedInput id={'billing-state'} {...field} />
            </FormField>
          )}
        />
      </Box>
    </Box>
  );
};
