import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import cns from 'classnames';

/* Utils */
import { sortFormFields } from '~/src/utils/list';
import useForm from './hooks/useForm';

const Form = ({
  fields,
  className,
  triggerOnMount,
  focusFirstInput,
  focusTimeout,
  sort,
  onSubmit,
  onChange,
  onBlur,
}) => {
  const {
    formFocused,
    removeField,
    setField,
    formValid,
    formTouched,
    formFields,
  } = useForm();

  const handleSubmit = (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    onSubmit({
      focused: formFocused,
      valid: formValid,
      touched: formTouched,
      fields: formFields,
    });
  };

  useEffect(() => {
    const formValue = {
      focused: formFocused,
      valid: formValid,
      touched: formTouched,
      fields: formFields,
    };

    const hasFields = Object.keys(formFields).length > 0;
    // We only want to trigger an event once the forms fields have been initalized
    // Or the form has been touched.
    if ((triggerOnMount && hasFields) || formTouched === true) {
      onChange(formValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFields]);

  const handleChange = (id, field) => {
    if (field) {
      const { value, touched, focus, valid } = field;
      setField(id, { value, touched, focus, valid });
    } else {
      removeField(id);
    }
  };

  let inputRefToFocusSet = false;
  let inpuRefToFocus = null;

  const handleGetInputRef = (ref) => {
    if (ref.current) {
      inpuRefToFocus = ref.current;
    }

    setTimeout(() => {
      inpuRefToFocus.focus();
    }, focusTimeout || 0);
  };

  const renderFieldList = ({ key, id, type, editable, render }) => {
    const props = {
      key: key || id,
      fields,
      onChange: handleChange.bind(null, id),
    };

    if (
      focusFirstInput &&
      !inputRefToFocusSet &&
      type === focusFirstInput &&
      editable !== false
    ) {
      props.getInputRef = handleGetInputRef;
      inputRefToFocusSet = true;
    }

    return render(props);
  };

  const sortedFields = sort ? sort(fields, true) : fields;
  const fieldList = sortedFields.map(renderFieldList);

  return (
    <form
      className={cns('relative', className)}
      onSubmit={handleSubmit}
      onBlur={onBlur}
    >
      {fieldList}
    </form>
  );
};

Form.defaultProps = {
  fields: [],
  triggerOnMount: false,
  sort: sortFormFields,
  onSubmit: () => {},
  onChange: () => {},
  onBlur: () => {},
};

Form.propTypes = {
  triggerOnMount: PropTypes.bool,
  fields: PropTypes.array,
  sort: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default Form;
