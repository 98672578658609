import React, { useEffect, useState } from 'react';
import { Box, Card, Stack } from '@mui/material';
import { MultipleChoiceIndividualInput } from './MultipleChoiceIndividualInput';
import { FieldRenderProps } from '..';
import {
  ProjectStackField,
  ProjectStackDocument,
} from '~/src/entities/project';
import { FieldHeaderWithEdit } from '../FieldHeader/FieldHeaderWithEdit';

export type MultipleChoiceFieldProps = {
  field: FieldRenderProps;
  projectField: ProjectStackField;
  projectStackDocuments: ProjectStackDocument[];
};

const tryToParseJson = (value: string) => {
  try {
    return JSON.parse(value);
  } catch {
    return '';
  }
};

export const MultipleChoiceField = React.memo(
  ({
    field,
    projectField,
    projectStackDocuments,
  }: MultipleChoiceFieldProps) => {
    const [isEditing, setIsEditing] = useState(false);

    const { onChange, onBlur, onFocus, countTouched } = field;

    const options = projectField.multiple_choice_variable!.options;

    let stackSavedValue: string[] =
      tryToParseJson(field.value) || Array.from({ length: options.length });

    const isStackDataCompatible = stackSavedValue.length === options.length;
    if (!isStackDataCompatible) {
      stackSavedValue = Array.from({ length: options.length });
    }
    useEffect(() => {
      if (isStackDataCompatible) return;
      onChange(JSON.stringify(Array.from({ length: options.length })));
    }, [isStackDataCompatible, onChange, options.length]);

    const [textToMergeValues, setTextToMergeValues] = useState<string[]>(() => {
      return options.map((opt, idx) => stackSavedValue[idx] || opt.value);
    });

    const isOptionChecked = (idx: number) => !!stackSavedValue[idx];

    const handleChange = (idx: number, nextValue: string) => {
      const nextValues = stackSavedValue.map((original, i) =>
        i === idx ? nextValue : original || '',
      );
      onChange(JSON.stringify(nextValues));
    };

    const canClose = textToMergeValues.every((v) => !!v);
    const isEdited = textToMergeValues.some(
      (v, idx) => v && v !== options[idx]?.value,
    );

    return (
      <Card
        sx={(theme) => ({
          border: `1px solid ${
            isEditing ? theme.palette.border : 'transparent'
          }`,
          marginBottom: theme.spacing(3),
        })}
        elevation={isEditing ? 3 : 0}
      >
        <Box p={isEditing ? 2 : 0}>
          <Box>
            <FieldHeaderWithEdit
              projectField={projectField}
              projectStackDocuments={projectStackDocuments}
              isEdited={isEdited}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              canClose={canClose}
            />

            <Stack direction={'column'} spacing={1}>
              {options.map((option, idx) => {
                const textValue = textToMergeValues[idx] || '';
                const isChecked = isOptionChecked(idx);
                const defaultValue = option.value;
                return (
                  <MultipleChoiceIndividualInput
                    key={option.value}
                    label={option.option}
                    isChecked={isChecked}
                    showTextToMerge={isEditing}
                    inputRef={idx === 0 ? field.ref : undefined}
                    onChange={() => {
                      handleChange(idx, isChecked ? '' : textValue);
                      if (countTouched) {
                        countTouched(field.name);
                      }
                    }}
                    isEdited={options[idx]?.value !== textValue}
                    textToMergeValue={textValue}
                    onChangeTextToMergeValue={(value) => {
                      setTextToMergeValues((values) =>
                        values.map((text, i) => (i === idx ? value : text)),
                      );
                    }}
                    onRevert={() => {
                      setTextToMergeValues((values) =>
                        values.map((text, i) =>
                          i === idx ? defaultValue : text,
                        ),
                      );
                      if (!isChecked) return;
                      handleChange(idx, defaultValue);
                    }}
                    onBlur={() => {
                      onBlur();
                      if (!isChecked) return;
                      handleChange(idx, textValue);
                    }}
                    onFocus={onFocus}
                  />
                );
              })}
            </Stack>
          </Box>
        </Box>
      </Card>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.field.value === nextProps.field.value &&
      prevProps.projectField === nextProps.projectField &&
      prevProps.projectStackDocuments === nextProps.projectStackDocuments
    );
  },
);
