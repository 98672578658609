import { Typography, Box, CircularProgress, Button } from '@mui/material';
import React from 'react';
import {
  BillingCycleType,
  getProductID,
  useFetchUpcomingInvoiceV2,
  useUpcomingInvoiceV2,
  NYSBA_SURROGATE_ID,
  WORD_PRODUCT_ID,
  RecurringCharges,
} from '~/src/entities/subscription';

import { APIError } from '~/src/utils/error';
import { CouponCodeForm } from './CouponCodeForm';
import { Total } from './Total';

type SummaryProps = {
  seats: number;
  billingCycle: BillingCycleType;
  contentPackage: boolean;
  courtForms: string[];
  word: boolean;
  coupon: string;
  onChangeCoupon: (coupon: string) => void;
  renderExtra: (isLoading: boolean, isError: boolean) => React.ReactNode;
};

export const Summary = ({
  seats,
  billingCycle,
  contentPackage,
  courtForms,
  word,
  coupon,
  onChangeCoupon,
  renderExtra,
}: SummaryProps) => {
  let add_plans: string[] = [];

  if (contentPackage)
    add_plans.push(getProductID(billingCycle, NYSBA_SURROGATE_ID));

  courtForms.forEach((form) => {
    add_plans.push(getProductID(billingCycle, form));
  });

  if (word) {
    add_plans.push(getProductID(billingCycle, WORD_PRODUCT_ID));
  }

  const params = {
    seat: seats,
    duration: billingCycle,
    coupon,
    add_plans: add_plans,
  };

  const fetchSummary = useFetchUpcomingInvoiceV2();
  const { isLoading, isError, data, refetch } = useUpcomingInvoiceV2(params);

  return (
    <>
      <Box p={3}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography variant={'h2'}>Purchase Summary</Typography>
        </Box>
      </Box>
      <Box p={3}>
        <RecurringCharges
          contentPackage={contentPackage}
          courtForms={courtForms}
          word={word}
          billingCycle={billingCycle}
          seats={seats}
        />
        <Box pb={3}>
          <CouponCodeForm
            defaultValues={{ coupon }}
            onSubmit={async ({ coupon }) => {
              try {
                await fetchSummary({ ...params, coupon });
                onChangeCoupon(coupon);
                return true;
              } catch (err) {
                const { response } = err as APIError;
                if (response.status === 400) return false;
                throw err;
              }
            }}
          />
        </Box>
        <Box>
          {isLoading ? (
            <Box display={'flex'} justifyContent={'center'}>
              <CircularProgress />
            </Box>
          ) : null}
          {isError ? (
            <>
              <Typography textAlign={'center'}>
                An error occurred retrieving your total
              </Typography>
              <Box pt={1} display={'flex'} justifyContent={'center'}>
                <Button variant={'text'} onClick={() => refetch()}>
                  Retry
                </Button>
              </Box>
            </>
          ) : null}
          {data ? <Total {...data} /> : null}
        </Box>
        {renderExtra?.(isLoading, isError)}
      </Box>
    </>
  );
};
