import React from 'react';
import { PatternFormat } from 'react-number-format';

export const CCNumberInput = React.forwardRef(({ onChange, ...rest }, ref) => (
  <PatternFormat
    {...rest}
    format={'#### #### #### ####'}
    getInputRef={ref}
    onValueChange={(values) => onChange?.(values.value)}
  />
));
