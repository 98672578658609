import React from 'react';

import { X } from 'react-feather';
import { css, StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

import styles from './styles';
import { useSnackbar } from 'notistack';

const ErrorToast = React.forwardRef(({ onCancel, message, style }, ref) => {
  const customStyle = StyleSheet.create(style ? { style } : {});
  const { closeSnackbar } = useSnackbar();
  const handleClose = () => closeSnackbar();
  return (
    <div
      ref={ref}
      className={css(styles.toast, styles.errorToast, customStyle.style)}
    >
      {message}
      <i className={css(styles.toastCloseIcon)}>
        <X
          size={16}
          color={theme.colors.white}
          onClick={onCancel ?? handleClose}
        />
      </i>
    </div>
  );
});

ErrorToast.defaultProps = {
  message: 'Something went wrong, please try again.',
};

export default ErrorToast;
