import React, { useState } from 'react';
import Button from '~/src/components/Button';
import { css } from 'aphrodite';
import PropTypes from 'prop-types';

import ValidationCheckList from '~/src/components/ValidationCheckList';
import { Form, renderFormField } from '~/src/components/Forms';
import { VALIDATION_TYPES } from '~/src/utils/validation';
import styles from './styles';

const PasswordChangeModal = ({
  title,
  primaryActionTitle,
  onCancel,
  onConfirm,
}) => {
  const [isSixCharactersLong, setIsSixCharactersLong] = useState(false);
  const [hasOneLowerCaseCharacter, setHasOneLowerCaseCharacter] =
    useState(false);
  const [hasOneUpperCaseCharacter, setHasOneUpperCaseCharacter] =
    useState(false);
  const [hasOneNumber, setHasOneNumber] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [formValue, setFormValue] = useState({});

  const [error, setError] = useState('');

  const handleSumbit = async () => {
    const success = await onConfirm(formValue);

    if (!success) {
      setError('Password is incorrect');
    }
  };

  const handleFormChange = (form) => {
    const newPassword = form.fields.newPassword
      ? form.fields.newPassword.value
      : '';

    setFormValue(form);

    if (newPassword) {
      setIsSixCharactersLong(newPassword.length >= 6 || false);
      setHasOneLowerCaseCharacter(newPassword.search(/[a-z]/) !== -1);
      setHasOneUpperCaseCharacter(newPassword.search(/[A-Z]/) !== -1);
      setHasOneNumber(newPassword.search(/\d/) !== -1);
    } else {
      setIsSixCharactersLong(false);
      setHasOneLowerCaseCharacter(false);
      setHasOneUpperCaseCharacter(false);
      setHasOneNumber(false);
    }
    setFormValid(form.valid);
  };

  const fields = [
    {
      label: 'Current password',
      id: 'currentPassword',
      type: 'password',
      defaultValue: self.firstName,
      autoComplete: 'current-password',
      validation: VALIDATION_TYPES.present,
      error,
    },
    {
      label: 'New password',
      id: 'newPassword',
      type: 'password',
      autocomplete: 'new-password',
      defaultValue: self.lastName,
      validation: VALIDATION_TYPES.newPassword,
    },
  ];

  const validationCheckListItems = [
    { valid: isSixCharactersLong, label: 'At least 6 characters long' },
    { valid: hasOneLowerCaseCharacter, label: 'One lowercase character' },
    { valid: hasOneUpperCaseCharacter, label: 'One uppercase character' },
    { valid: hasOneNumber, label: 'One number' },
  ];

  const fieldsProp = fields.map((field) =>
    renderFormField({ ...field, editable: true }),
  );

  return (
    <div className={css(styles.modalContainer)}>
      <h1 className={css(styles.modalTitle)}>{title}</h1>
      <div className={css(styles.modalBody)}>
        <Form triggerOnMount fields={fieldsProp} onChange={handleFormChange} />
        <ValidationCheckList list={validationCheckListItems} />
      </div>
      <div className={css(styles.modalActions)}>
        <Button cssStyle={styles.buttonAction} onClick={onCancel}>
          Cancel
        </Button>{' '}
        <Button
          cssStyle={styles.buttonAction}
          onClick={handleSumbit}
          disabled={!formValid}
          primary
        >
          {primaryActionTitle}
        </Button>
      </div>
    </div>
  );
};

PasswordChangeModal.propTypes = {
  title: PropTypes.string,
};

PasswordChangeModal.defaultProps = {
  title: 'Update Password',
  primaryActionTitle: 'Update password',
};

export default PasswordChangeModal;
