import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { css } from 'aphrodite';

import Button from '~/src/components/Button';
import Checkbox from '~/src/components/Checkbox';

import styles from './styles';

const DownloadDocumentModal = (props) => {
  const { documentTitle, onCancel, onConfirm } = props;

  const [showHighlights, setShowHighlights] = useState(true);

  const onToggleShowHighlights = () => {
    setShowHighlights(!showHighlights);
  };

  const onDownload = () => {
    onConfirm(showHighlights);
  };

  return (
    <div className={css(styles.modalContainer)}>
      <h1 className={css(styles.modalTitle)}>
        Downloading &quot;{documentTitle}&quot;
      </h1>
      <div className={css(styles.modalBody)}>
        <p className={css(styles.modalMessage)}>
          Would you like your document(s) to have template field highlights?
        </p>
        <div className={css(styles.cancelSubscriptionModalAcknowledge)}>
          <Checkbox
            checked={showHighlights}
            state={showHighlights}
            size={2}
            onClick={onToggleShowHighlights}
            cssStyles={styles.downloadDocumentModalShowHighlightInput}
          />
          <p>Keep highlights</p>
        </div>
      </div>
      <div className={css(styles.modalActions)}>
        <Button cssStyle={styles.buttonAction} onClick={onCancel}>
          Cancel
        </Button>{' '}
        <Button cssStyle={styles.buttonAction} onClick={onDownload} primary>
          Download
        </Button>
      </div>
    </div>
  );
};

DownloadDocumentModal.propTypes = {
  documentTitle: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

DownloadDocumentModal.defaultProps = {
  documentTitle: '',
  onCancel: () => {},
  onConfirm: () => {},
};

export default DownloadDocumentModal;
