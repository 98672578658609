import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';

import { User, Briefcase } from 'react-feather';
import CreateContactContainer from '~/src/containers/slideIns/CreateContactSlideIn';
import { Container, Header, Body } from '~/src/components/SlideIn';
import { default as slideInStyles } from '~/src/components/SlideIn/styles';
import styles from './styles';

/* Containers */

/* Components */
import AddContacts from './AttachContactAddContacts';
import SearchContacts from './AttachContactSearchContacts';
import ContactDetails from './AttachContactContactDetails';

class AttachContactSlideIn extends React.Component {
  state = {
    showCreateContact: false,
  };

  showCreateContact = () => {
    this.setState({ showCreateContact: true });
  };

  hideCreateContact = () => {
    this.setState({ showCreateContact: false });
  };

  renderContactDetailColumnType = () => {
    if (this.props.contact.type === 'business') {
      return <Briefcase size={18} color={'#626E7A'} />;
    }
    return <User size={18} color={'#626E7A'} />;
  };

  renderContactDetailHeader = () => {
    return (
      <div className={css(styles.contactDetailHeader)}>
        <i className={css(styles.contactDetailHeaderIcon)}>
          {this.renderContactDetailColumnType()}
        </i>
        {this.props.contact.fullName()}
      </div>
    );
  };

  render() {
    const {
      contacts,
      title,
      contact,
      onSelectContact,
      onCancelContact,
      onCreateNewContact,
    } = this.props;

    const { showCreateContact } = this.state;

    let bodyEl = <AddContacts />;

    if (contacts.length > 0) {
      bodyEl = (
        <SearchContacts
          contacts={contacts}
          onCreateContact={this.showCreateContact}
          onSelectContact={onSelectContact}
        />
      );
    }

    if (contact) {
      bodyEl = (
        <ContactDetails
          renderHeader={this.renderContactDetailHeader}
          contact={contact}
          onBack={onCancelContact}
        />
      );
    }

    if (showCreateContact) {
      return (
        <CreateContactContainer
          onSumbit={onCreateNewContact}
          onCancel={this.hideCreateContact}
        />
      );
    }

    return (
      <Container>
        <Header title="Attach Contact" subtitle={title} />
        <Body>
          <div className={css(slideInStyles.innerBody)}>{bodyEl}</div>
        </Body>
      </Container>
    );
  }
}

AttachContactSlideIn.propTypes = {
  onCreateNewContact: PropTypes.func,
  onCancelContact: PropTypes.func,
  contacts: PropTypes.array,
};

AttachContactSlideIn.defaultProps = {
  contacts: [],
  onCreateNewContact: () => {},
  onCancelContact: () => {},
};

export default AttachContactSlideIn;
