import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useCurrentOrg, useUpdateAccount } from '~/src/entities/user';
import { useSubmitWelcomeForm } from '~/src/entities/welcomeForm';
import analyticsService from '~/src/services/analytics';
import { WelcomeFormContent } from './WelcomeFormContent';
import { WelcomeFormResponse } from './types';
import { questionKeyToLabel } from './utils';

export const WelcomeFormPage = () => {
  const history = useHistory();
  const isFreeTrial = history.location.pathname.includes('welcome-free-trial');
  const { org } = useCurrentOrg();
  const snackbar = useSnackbar();
  const { mutateAsync: updateAccount, isSuccess: isUpdateAccountSuccess } =
    useUpdateAccount();
  const {
    mutateAsync: submitWelcomeForm,
    isSuccess: isSubmitWelcomeFormSuccess,
  } = useSubmitWelcomeForm();

  useEffect(() => {
    analyticsService.track(
      isFreeTrial
        ? 'Welcome Clio-SSO-free-trial Form Viewed'
        : 'Welcome Form Viewed',
    );
  }, [isFreeTrial]);

  const handleSubmit = async (values: WelcomeFormResponse) => {
    const questionnairePayload = Object.entries(values).map(([key, value]) => ({
      question: questionKeyToLabel(key as keyof WelcomeFormResponse),
      answer: value,
    }));

    try {
      if (!isSubmitWelcomeFormSuccess) {
        await submitWelcomeForm({ payload: questionnairePayload });
      }

      if (!isUpdateAccountSuccess) {
        await updateAccount({ phone: values.phoneNumber, state: values.state });
      }

      if (isFreeTrial) {
        history.push('/library');
        return;
      }

      if (org?.is_non_profit) {
        history.push('/library');
        return;
      }

      history.push('/subscribe');
    } catch (err) {
      snackbar.enqueueSnackbar('An error occurred', { variant: 'error' });
    }
  };

  return (
    <Box maxWidth={'560px'} my={5} mx={'auto'}>
      <WelcomeFormContent isFreeTrial={isFreeTrial} onSubmit={handleSubmit} />
    </Box>
  );
};
