import { useObserver } from 'mobx-react';
import useMst from '~/src/hooks/useMst';
import useResourcePagination from '~/src/hooks/useResourcePagination';

const useDocumentsPagination = (defaultProps = {}) => {
  const { documents } = useMst((store) => ({
    documents: store.documents,
  }));

  const {
    loading,
    next,
    done,
    empty,
    page,
    search,
    setSearch,
    setSortBy,
    setOrder,
  } = useResourcePagination({
    loading: defaultProps.loading,
    fetch: documents.fetch,
    limit: 30,
    order: 'desc',
  });

  const listData = useObserver(() => {
    return documents.list.flat();
  });

  return {
    next,
    page,
    search,
    data: listData,
    loading,
    done,
    empty,

    setSearch,
    setSortBy,
    setOrder,
  };
};

export default useDocumentsPagination;
