/* Libraries */
import React from 'react';
import { inject, observer } from 'mobx-react';

/* Higher Order Components */
import WithLayoutProps from '~/src/hoc/WithLayoutProps';

/* Components */
import SettingsOrganization from '~/src/components/Settings/SettingsOrganization';

/* Constants */
import { LAYOUT_MODAL_TYPES } from '~/src/components/PageLayout/Modals';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';

class SettingsOrganizationContainer extends React.Component {
  handleSetAsDefault = () => {
    const handleConfirm = () => {
      this.props.store.user.currentOrganization.setAsDefault();
      const orgName =
        this.props.store.user.currentOrganization.organizationName;
      this.props.showToast(LAYOUT_TOAST_TYPES.success, {
        message: `${orgName} set as default orgnaization`,
      });
      this.props.hideModal();
    };

    const orgName = this.props.store.user.currentOrganization.organizationName;

    this.props.showModal(LAYOUT_MODAL_TYPES.confirm, {
      title: `Set as default organization`,
      message: `You are about to set ${orgName} as your default organization.`,
      onConfirm: handleConfirm,
      primaryActionTitle: 'Set default',
    });
  };

  handleChange = (nextOrganizationName) => {
    this.setState({ nextOrganizationName });
  };

  handleSubmit = async () => {
    const { nextOrganizationName } = this.state;
    if (nextOrganizationName) {
      const success = await this.props.store.user.currentOrganization.update({
        organizationName: nextOrganizationName,
      });

      if (success) {
        this.props.showToast(LAYOUT_TOAST_TYPES.success, {
          message: 'Organization name updated!',
        });
      } else {
        this.props.showToast(LAYOUT_TOAST_TYPES.error, {
          message:
            'Failed to update organization name, please try again later.',
        });
      }
    }
  };

  render() {
    const { currentOrganization } = this.props.store.user;
    const currentOrganizationName =
      this.props.store.user.currentOrganization.organizationName;
    const showSetAsDefault = this.props.store.user.organizations.length > 1;

    return (
      <SettingsOrganization
        onSave={this.handleSubmit}
        showSetAsDefault={showSetAsDefault}
        isDefault={currentOrganization.isDefault}
        currentOrganizationName={currentOrganizationName}
        onChange={this.handleChange}
        onSetAsDefault={this.handleSetAsDefault}
      />
    );
  }
}

export default inject((store) => store)(
  WithLayoutProps(observer(SettingsOrganizationContainer)),
);
