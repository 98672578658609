/* Libs */
import { types, getParent } from 'mobx-state-tree';
import { toJS } from 'mobx';
import { startCase } from 'lodash';
import uuid from 'uuid/v4';

import { Tag } from '~/src/stores/tagsStore';

/* Constants */
import {
  UPLOAD_LIMIT_OPTIONS,
  UPLOAD_TYPES_OPTIONS,
} from '~/src/components/QuestionnaireForm/constants';
import { isUnifiedPlatform } from '../utils';

const QuestionnaireQuestion = types
  .model('QuestionnaireQuestion', {
    id: types.optional(types.string, uuid),
    type: types.string,
    sortOrder: types.integer,
    label: types.string,
    options: types.optional(types.frozen(), []),

    // V2.0 Tags Specific
    tagId: types.maybeNull(types.reference(Tag)),
    // TODO: V1.0 Tags Specific, we will eventually deperate this in favor of tagId once all users have been migrated.
    tag: types.maybeNull(types.string),

    hint: types.optional(types.string, ''),
    required: types.optional(types.boolean, false),
    uploadLimit: types.optional(types.frozen(), UPLOAD_LIMIT_OPTIONS[0].value),
    uploadTypes: types.optional(types.frozen(), UPLOAD_TYPES_OPTIONS[0].value),
    editing: types.optional(types.boolean, false),
    creating: types.optional(types.boolean, false),
  })
  .actions((self) => {
    const setEditing = (nextEditingValue) => {
      self.editing = nextEditingValue;
    };

    const setCreating = (nextValue) => {
      self.creating = nextValue;
    };

    const setSortOrder = (order, options = {}) => {
      const { update = true } = options;

      self.sortOrder = order;
      if (update) {
        self.update();
      }
    };

    const setType = (type) => {
      self.type = type;
      self.update();
    };

    const setOptions = (options) => {
      self.options = options;
      self.update();
    };

    const setRequired = (required) => {
      self.required = required;
      self.update();
    };

    const setUploadTypes = (uploadTypes) => {
      self.uploadTypes = uploadTypes;
      self.update();
    };

    const setUploadLimit = (uploadLimit) => {
      self.uploadLimit = uploadLimit;
      self.update();
    };

    const setLabel = (label) => {
      self.label = label;
      self.update();
    };

    const getQuestionnaireStore = () => {
      return getParent(self, 5);
    };

    const update = () => {
      const questionnaireStore = getQuestionnaireStore();
      questionnaireStore.update();
    };

    const remove = () => {
      const questionnaireGroup = self.getQuestionnaireGroupStore();
      questionnaireGroup.removeQuestion(self);
    };
    const createNewQuestion = () => {
      const parentQuestionnaireGroupStore = self.getQuestionnaireGroupStore();
      parentQuestionnaireGroupStore.createNewQuestion({
        sortOrder: self.sortOrder,
      });
    };

    const duplicateQuestion = () => {
      const parentQuestionnaireGroupStore = self.getQuestionnaireGroupStore();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, ...questionToDuplicate } = toJS(self);
      parentQuestionnaireGroupStore.createNewQuestion({
        ...questionToDuplicate,
        label: `${self.label} Copy`,
      });
    };

    const getQuestionnaireGroupStore = () => {
      return getParent(self, 2);
    };

    const getQuestionnaire = () => {
      const questionnaireGroup = self.getQuestionnaireGroupStore();
      return questionnaireGroup.getQuestionnaireStore();
    };

    const setTag = (tag) => {
      const nextValue = tag ? tag.tag : null;

      if (isUnifiedPlatform(self)) {
        self.tagId = nextValue;
      } else {
        self.tag = nextValue;
      }

      self.update();
    };

    return {
      getQuestionnaire,
      getQuestionnaireGroupStore,
      createNewQuestion,
      duplicateQuestion,
      setEditing,
      setTag,
      setType,
      setLabel,
      setOptions,
      setRequired,
      setUploadTypes,
      setUploadLimit,
      setSortOrder,
      setCreating,
      update,
      remove,
    };
  })
  .views((self) => {
    const getTagLabel = () => {
      if (isUnifiedPlatform(self)) {
        if (!self.tagId) {
          return null;
        }

        return self.tagId.getFormattedTagLabel();
      }
      if (self.tag) {
        return startCase(self.tag.replace('.', ' / '));
      }

      return '';
    };

    const nextQuestion = () => {
      const questionGroupStore = self.getQuestionnaireGroupStore();
      const sortedQuestions = questionGroupStore.sortedQuestions();
      return sortedQuestions[self.sortOrder];
    };

    const previousQuestion = () => {
      const questionGroupStore = self.getQuestionnaireGroupStore();
      const sortedQuestions = questionGroupStore.sortedQuestions();
      return sortedQuestions[self.sortOrder - 2];
    };

    const isNextQuestion = () => {
      return self.nextQuestion();
    };

    const isPreviousQuestion = () => {
      return self.previousQuestion();
    };

    return {
      previousQuestion,
      nextQuestion,
      isNextQuestion,
      isPreviousQuestion,
      getTagLabel,
    };
  });

export default QuestionnaireQuestion;
