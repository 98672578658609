import React, { useEffect, useState } from 'react';

/* Hooks */
import useTemplatesPagination from '~/src/hooks/useTemplatesPagination';

/* Components */
import VariablesEntityFieldCardsList from '~/src/components/VariablesEntityFieldCardsList';
import { ChevronUp, ChevronDown, Share2 } from 'react-feather';
import { Container, Body, Header, Footer } from '~/src/components/SlideIn';
import Button from '~/src/components/Button';
import { useObserver } from 'mobx-react';

import { SelectInput, Label } from '~/src/components/Inputs';
import { find } from 'lodash';

const QuestionHeader = (props) => {
  const { question, onNextQuestion, onPreviousQuestion } = props;

  const isNextQuestion = useObserver(() => question.isNextQuestion());
  const isPreviousQuestion = useObserver(() => question.isPreviousQuestion());

  const handlePreviousQuestion = () => {
    const previousQuestion = question.previousQuestion();
    onPreviousQuestion(previousQuestion);
  };

  const handleNextQuestion = () => {
    const nextQuestion = question.nextQuestion();
    onNextQuestion(nextQuestion);
  };

  return (
    <div className="flex p-4 border border-r-0 border-l-0 border-solid border-gray-300">
      <div className="text-sm font-bold">Q{question.sortOrder}</div>
      <div className="mx-4 flex-grow text-base">{question.label}</div>
      <div className="flex justify-center items-center">
        {isPreviousQuestion && (
          <i className="mr-2 cursor-pointer" onClick={handlePreviousQuestion}>
            <ChevronUp size={16} />
          </i>
        )}
        {isNextQuestion && (
          <i className="cursor-pointer" onClick={handleNextQuestion}>
            <ChevronDown size={16} />
          </i>
        )}
      </div>
    </div>
  );
};

const TemplateOptions = (props) => {
  const { template, onSelectTemplate } = props;

  const { data: templateListData, setSearch } = useTemplatesPagination();

  const options = templateListData.map((template) => ({
    label: template.title,
    value: template.id,
  }));

  if (template && template.id) {
    const hasTemplate = find(options, ['value', template.id]);
    if (!hasTemplate) {
      options.unshift({
        label: template.title,
        value: template.id,
      });
    }
  }

  const handleChange = onSelectTemplate;

  return (
    <div className="p-4 border border-r-0 border-l-0 border-b-0 border-solid border-gray-300">
      <SelectInput
        label="Template"
        value={(template && template.id) || null}
        options={options}
        onInputChange={setSearch}
        onChange={handleChange}
      />
    </div>
  );
};

const TemplateSetOptions = (props) => {
  const { templateSet } = props;

  const templateSetListPage = 0;
  const templateSetListData = [];

  const [, setSelectedTemplateSetOption] = useState();
  const [, setTemplateSetSearchOptions] = useState([]);

  useEffect(() => {
    if (templateSet) {
      templateSetListData.push({
        label: templateSet.title,
        value: templateSet.id,
      });

      setSelectedTemplateSetOption(templateSet.id);
    }

    setTemplateSetSearchOptions(templateSetListData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateSet, templateSetListPage]);

  return (
    <div className="p-4 border border-r-0 border-b-0 border-l-0 border-solid border-gray-300">
      {templateSet && <Label label="Template set">{templateSet.title}</Label>}
      {/* <SelectInput label="Template Set" value={selectedTemplateSetOption} options={templateSetSearchOptions} onChange={handleTemplateSetChange} /> */}
    </div>
  );
};

const QuestionnaireQuestionMapField = (props) => {
  const { question, questionnaire, hideSlideIn: handleCancel } = props;

  if (!question || !questionnaire) {
    return null;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (!question || !questionnaire) {
      handleCancel();
    } else {
      questionnaire.fetchTemplates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [currentQuestion, setQuestion] = useState(question);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { template, templateSet, formattedTag } = useObserver(() => ({
    template: questionnaire.getTemplate(),
    templateSet: questionnaire.getTemplateSet(),
    formattedTag: currentQuestion.getTagLabel(),
  }));

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const variables = useObserver(() => {
    let variables = [];

    if (template && template.getTags) {
      variables = variables.concat(template.getTags);
    }

    if (templateSet && templateSet.getTags) {
      variables = variables.concat(templateSet.getTags());
    }

    return variables;
  });

  const isAutoGeneratedQuestionnaire = questionnaire.templateSetIds.length > 0;

  const handleNextQuestion = (nextQuestion) => {
    setQuestion(nextQuestion);
  };

  const handlePreviousQuestion = (nextQuestion) => {
    setQuestion(nextQuestion);
  };

  const renderTitle = () => (
    <>
      <i className="mr-4 text-base">
        <Share2 size={16} />
      </i>
      Map Fields
    </>
  );

  const handleFieldClick = (field) => {
    currentQuestion.setTag(field);
  };

  const handleRemoveQuestionTag = () => {
    currentQuestion.setTag(null);
  };

  const handleSelectTemplate = (id) => {
    questionnaire.attachTemplate(id);
  };

  return (
    <Container>
      <Header title={renderTitle} />
      <Body>
        <QuestionHeader
          question={currentQuestion}
          onPreviousQuestion={handlePreviousQuestion}
          onNextQuestion={handleNextQuestion}
        />
        <div className="relative m-4 bg-white rounded shadow-sm hover:shadow-lg tranistion">
          <div className="text-sm p-4 ">
            <b>When</b> someone answers this question, the response will be
            mapped to the following destination:
          </div>
          {isAutoGeneratedQuestionnaire && (
            <TemplateSetOptions templateSet={templateSet} />
          )}
          {!isAutoGeneratedQuestionnaire && (
            <TemplateOptions
              template={template}
              onSelectTemplate={handleSelectTemplate}
            />
          )}
          {formattedTag && (
            <div className="p-4 border border-r-0 border-l-0 border-b-0 border-solid border-gray-300">
              <Label label="Field">
                <div className="flex justify-between">
                  <span className="text-sm">{formattedTag}</span>
                  <i
                    className="text-xs text-blue-500 uppercase"
                    onClick={handleRemoveQuestionTag}
                  >
                    Remove
                  </i>
                </div>
              </Label>
            </div>
          )}
          {!formattedTag && (
            <div className="p-4 border border-r-0 border-l-0 border-b-0 border-solid border-gray-300">
              <Label label="Field">
                <VariablesEntityFieldCardsList
                  variables={variables}
                  selectedField={{ tag: currentQuestion.tag }}
                  onFieldClick={handleFieldClick}
                />
              </Label>
            </div>
          )}
        </div>
      </Body>
      <Footer alignRight>
        <Button className="mr-2" onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleCancel} primary>
          Confirm
        </Button>
      </Footer>
    </Container>
  );
};

export default QuestionnaireQuestionMapField;
