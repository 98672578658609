/* Libraries */
import React from 'react';
import { css } from 'aphrodite';
import cns from 'classnames';
/* Models */
import { Project } from '~/src/models/project';
/* Styles */
import styles from './styles';

type ViewDocumentProps = {
  document: Project.Document;
  showHighlights: boolean;
};

export const ViewDocument = (props: ViewDocumentProps) => {
  const { document, showHighlights } = props;
  // with highlights
  const unprocessedPages = document && document.pages ? document.pages : [];

  const processedPages =
    document && document.processedPages ? document.processedPages : [];

  const pageImages = (pages: Project.Page[]) => {
    const images: React.ReactFragment[] = [];
    pages.forEach((page) => {
      images.push(
        <img
          key={page.id}
          src={page.backgroundUrl}
          className={css(styles.documentPreviewImage)}
        />,
      );
    });

    return images;
  };

  /* need to load both versions at once, or we get jump scrolling when
   toggling 'Highlight Changes' occasionally */
  return (
    <div className={css(styles.documentContainer)}>
      <div className={cns({ hidden: !showHighlights })}>
        {pageImages(unprocessedPages)}
      </div>
      <div className={cns({ hidden: showHighlights })}>
        {pageImages(processedPages)}
      </div>
    </div>
  );
};

ViewDocument.defaultProps = {
  document: null,
  showHighlights: true,
};
