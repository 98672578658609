import assert from 'assert';
import React, { FC, useState } from 'react';
import SignerSelection from '~/src/components/SignerSelection/SignerSelection';

import {
  extractNewAssignmentId,
  makeAssignDateNowBody,
  makeAssignSignatureOrDateBody,
  makePackagePdfs,
  makePackageSigners,
  makePageIdsByPageNumber,
  sanitizeSignature,
} from '~/src/components/SignerSelection/utils';
import useMst from '~/src/hooks/useMst';
import {
  Assignee,
  UpdateElementPayload,
  Dated,
  SignaturePackage,
} from '../models';

interface Props {
  signaturePackage: SignaturePackage.SignaturePackage;
  setValid: (valid: boolean, reason?: string) => void;
}

const SignerSelectionContainer: FC<Props> = ({
  signaturePackage,
  setValid,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { signatures, user, profile } = useMst((store) => {
    return {
      profile: store.user.profile,
      signatures: store.signatures,
      user: store.user,
    };
  });

  if (!signaturePackage || !signaturePackage.recipients.length) {
    return (
      <div className="h-full w-full flex items-center justify-center">
        Loading...
      </div>
    );
  }
  // TODO - Fix packagestatus
  // assert(
  //   signaturePackage.packageStatus === 'Drafting',
  //   'Trying to select signers for a package that is not in `Drafting` state!',
  // );

  const pdfs = makePackagePdfs(signaturePackage);

  // const signers = makePackageSigners(signaturePackage, myEmail);
  const signers = makePackageSigners(signaturePackage);

  const signNow = async (
    documentId: string,
    assignee: Assignee,
    imageParams: { xfdfSignature: string; width: number; height: number },
  ): Promise<number> => {
    assert(
      'me' in signers,
      'Me not found in signers when uploading current user signature!',
    );
    const pdf = signaturePackage.lawyawDocuments.find(
      (e) => e.id.toString() === documentId,
    )!;
    const pageIdsByPageNumber = makePageIdsByPageNumber(pdf.pages);
    const assignRequestBody = makeAssignSignatureOrDateBody(
      assignee,
      pageIdsByPageNumber,
    );
    /**
     * Any time we sign anywhere on the document, we want to be sure
     * that the signature we used becomes the default signature for
     * the user's profile. Much of the time this request isn't needed
     * because we're reusing the same signature as is on the user's
     * profile. So an optimization here would be to conditionally
     * fire this request only when the signature changes
     */
    setIsLoading(true);
    await user.profile?.uploadSignatureXfdf(imageParams.xfdfSignature);
    const updatedPackage = await signatures.uploadUserSignatureImageXfdf(
      {
        lawyawProjectId: signaturePackage.lawyawProjectId,
        fprint: signaturePackage.fprint,
      },
      assignee.recipient.id,
      imageParams,
      [assignRequestBody],
    );
    const id = extractNewAssignmentId(updatedPackage, documentId, assignee);
    setIsLoading(false);
    return id;
  };

  const upsertElements = async (
    documentId: string,
    elements: UpdateElementPayload[],
  ): Promise<number[]> => {
    const pdf = signaturePackage.lawyawDocuments.find(
      (e) => e.id.toString() === documentId,
    )!;
    const pageIdsByPageNumber = makePageIdsByPageNumber(pdf.pages);
    const elementsToUpsert = elements.map((element) =>
      makeAssignSignatureOrDateBody(element, pageIdsByPageNumber),
    );
    setIsLoading(true);
    if (elementsToUpsert.length > 0) {
      const newPackage = await signatures.assignSignatureField(
        {
          lawyawProjectId: signaturePackage.lawyawProjectId,
          fprint: signaturePackage.fprint,
        },
        elementsToUpsert,
      );
      setIsLoading(false);
      return elements.map((element) =>
        extractNewAssignmentId(newPackage, documentId, element),
      );
    }
    setIsLoading(false);
    return [];
  };

  const deleteAssignment = (assignmentId: number) => {
    signatures.deleteAssignedSignatureField(
      {
        lawyawProjectId: signaturePackage.lawyawProjectId,
        fprint: signaturePackage.fprint,
      },
      assignmentId,
    );
  };

  const assignDateNow = async (documentId: string, dated: Dated) => {
    const pdf = signaturePackage.lawyawDocuments.find(
      (e) => e.id.toString() === documentId,
    )!;
    const pageIdsByPageNumber = makePageIdsByPageNumber(pdf.pages);
    const requestBody = makeAssignDateNowBody(dated, pageIdsByPageNumber);
    setIsLoading(true);
    const newPackage = await signatures.assignSignatureField(
      {
        lawyawProjectId: signaturePackage.lawyawProjectId,
        fprint: signaturePackage.fprint,
      },
      requestBody,
    );
    setIsLoading(false);
    return extractNewAssignmentId(newPackage, documentId, dated);
  };

  return (
    <div className="flex flex-col grow">
      <SignerSelection
        signers={signers}
        onAssignFields={upsertElements}
        onDeleteAssignedField={deleteAssignment}
        onDateNow={assignDateNow}
        pdfs={pdfs}
        onSignNow={signNow}
        setValid={setValid}
        isLoading={isLoading}
        myCurrentSignature={
          profile && profile.xfdfSignature
            ? sanitizeSignature(profile.xfdfSignature)
            : null
        }
      />
    </div>
  );
};

export default SignerSelectionContainer;
