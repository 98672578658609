import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

export default StyleSheet.create({
  couponButtonContainer: {
    marginTop: theme.unit * 2,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  couponFormContainer: {
    marginTop: theme.unit * 2,
  },
  cardContainer: {
    marginTop: theme.unit * 4,
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  summary: {
    width: '320px',
  },
  applyCouponButton: {
    height: '39px',
  },
});
