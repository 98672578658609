import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

export default StyleSheet.create({
  menuLink: {
    display: 'block',
    textAlign: 'left',
    padding: `${theme.unit * 1.5}px`,
    color: '#000000',
    fontSize: '14px',
    cursor: 'pointer',
    ':hover': {
      color: theme.colors.bluebird,
    },
  },
});
