/* Hooks */
import useMst from '~/src/hooks/useMst';
import useQuestionnaire from '~/src/hooks/questionnaires/useQuestionnaire';

/* Constants */
import { LAYOUT_SLIDEIN_TYPES } from '~/src/components/PageLayout/SlideIns';
import { LAYOUT_MODAL_TYPES } from '~/src/components/PageLayout/Modals';
import { useLayoutContext } from '~/src/contexts/Layout';
import { history } from '~/src/utils/history';

const useQuestionnaireActions = (id) => {
  const { showSlideIn, showModal, hideModal } = useLayoutContext();

  const { createQuestionnaire } = useMst((store) => ({
    createQuestionnaire: store.questionnaires.createQuestionnaire,
  }));

  const { intake, updateQuestionnaire } = useQuestionnaire(id);

  const handleMapQuestion = (question) => {
    const payload = {
      intake,
      question,
    };

    showSlideIn(LAYOUT_SLIDEIN_TYPES.questionFieldMap, payload);
  };

  const handleChange = updateQuestionnaire;
  const handleEditIntakeSummary = ({
    onChange,
    title,
    ctaLabel,
    clickCatcherDisabled,
  }) => {
    const handleRenameConfirm = ({ fields }) => {
      const title = fields.title && fields.title.value;
      const description = fields.description && fields.description.value;
      const nextValues = {};

      if (title) {
        nextValues.title = title;
      }

      if (description) {
        nextValues.description = description;
      }

      if (onChange) {
        onChange(nextValues).then(() => {
          hideModal();
        });
      } else {
        handleChange(nextValues);
      }
    };

    const handleCancel = () => {
      hideModal();
      history.goBack();
    };

    /**
     * When creating a new intake form
     * users must enter a name for that intake form
     * we enforce this by disabling the cancel functionality for
     * the new intake forms first view of the sumary modal,
     * which is shown after a users clicks on "create new intake form"
     */
    // const isCreateNewModalsFirstView = !intake.id && !title && !description;
    const modalTitle = title || null;
    const modalCTA = ctaLabel || 'Edit';

    showModal(LAYOUT_MODAL_TYPES.formField, {
      title: modalTitle,
      primaryActionTitle: modalCTA,
      disableClickCatcher: clickCatcherDisabled,
      fields: [
        {
          autoFocus: true,
          label: 'Internal title',
          id: 'title',
          type: 'text',
          placeholder: 'What would you like to call this questionnaire?',
          required: true,
          defaultValue: intake.title,
          validation: ({ value }) => {
            return !!(value && value.length > 1);
          },
        },
        {
          label: 'Internal description',
          id: 'description',
          type: 'textarea',
          defaultValue: intake.description,
          placeholder: 'Describe the purpose of this questionnaire form',
          validation: () => true,
        },
      ],
      onConfirm: handleRenameConfirm,
      onCancel: handleCancel,
    });

    return hideModal;
  };

  const handleInitalizeIntakeForm = (createConfig) => {
    const isNewIntakeForm = !intake.id;
    const { templateSetIds, templateIds } = createConfig;

    /*
     * If this is a new intake form then show the modal forcing the user to assign a title and descrioption for the questionnaire
     * If this is not a new intake then we don't need to do anything as the useQuestionnaire hooks fetches that questionnaire from the server
     */

    if (isNewIntakeForm) {
      const createIntakeForm = ({ title, description }) => {
        return createQuestionnaire({
          title: title || '',
          description: description || '',
          templateSetIds,
          templateIds,
        }).then((intake) => {
          history.push(`/questionnaire/edit/${intake.id}`);
          hideModal();
        });
      };

      handleEditIntakeSummary({
        title: 'Create new questionnaire',
        ctaLabel: 'Create',
        clickCatcherDisabled: true,
        onChange: createIntakeForm,
      });
    }
  };

  const handlePublishIntake = () => {
    showModal(LAYOUT_MODAL_TYPES.publishQuestionnaireForm, {
      intakeId: intake.id,
    });
  };

  return {
    intake,
    // questionGroups,
    // title,
    // description,
    // displayTitle,
    // displayDescription,
    // saving: isSaving,
    // loading: isLoading,

    onCreateIntake: createQuestionnaire,
    onMapQuestion: handleMapQuestion,
    onChange: handleChange,
    onEditIntakeSummary: handleEditIntakeSummary,
    onPublishIntake: handlePublishIntake,
    onInitalizeIntakeForm: handleInitalizeIntakeForm,
  };
};

export default useQuestionnaireActions;
