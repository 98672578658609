import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
  Select,
  MenuItem,
} from '@mui/material';
import React, { useState } from 'react';
import { X } from 'react-feather';
import {
  Controller,
  FieldErrors,
  FormProvider,
  Resolver,
  useForm,
} from 'react-hook-form';
import { useCreateContact } from '../../hooks';
import { Contact, ContactType } from '../../types';
import { PERSON_TYPE, ORGANIZATION_TYPE } from '~/src/stores/contactsStore';

export type CreateContactModalProps = {
  firstName?: string;
  lastName?: string;
  name?: string;
  existingFields?: { [key: string]: string };
  onContactCreated: (contact: Contact) => void;
  onClose: () => void;
} & Omit<DialogProps, 'onClose'>;

type ContactFormValues = {
  selectedContactType: ContactType;
  firstName: string;
  lastName: string;
  name: string;
};

const contactFormResolver: Resolver<ContactFormValues> = (values) => {
  let hasError = false;
  const errors: FieldErrors<ContactFormValues> = {};
  if (values.selectedContactType === ORGANIZATION_TYPE) {
    if (!values.name) {
      hasError = true;
      errors.name = {
        type: 'required',
        message: 'Name is required',
      };
    }
  } else {
    // selectedContactType === 'Person'
    if (!values.firstName) {
      hasError = true;
      errors.firstName = {
        type: 'required',
        message: 'First Name is required',
      };
    }
    if (!values.lastName) {
      hasError = true;
      errors.lastName = {
        type: 'required',
        message: 'Last Name is required',
      };
    }
  }
  return { values: hasError ? {} : values, errors: hasError ? errors : {} };
};

export const CreateContactModal = ({
  firstName = '',
  lastName = '',
  name = '',
  onContactCreated,
  onClose,
  existingFields,
  ...rest
}: CreateContactModalProps) => {
  const [selectedContactType, setSelectedContactType] =
    useState<ContactType>(PERSON_TYPE);
  const methods = useForm<ContactFormValues>({
    values: { selectedContactType, firstName, lastName, name },
    resolver: contactFormResolver,
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const { mutate, isLoading } = useCreateContact({
    onSuccess: onContactCreated,
    onError: () => methods.setError('root', { message: 'An error occurred' }),
  });

  const rootErrorMessage = methods.formState.errors.root?.message;

  return (
    <FormProvider {...methods}>
      <Dialog
        TransitionProps={{ onExited: () => methods.reset() }}
        fullWidth
        maxWidth="sm"
        onClose={onClose}
        {...rest}
      >
        <form
          noValidate
          onSubmit={methods.handleSubmit(({ firstName, lastName, name }) => {
            if (selectedContactType === ORGANIZATION_TYPE) {
              return mutate({
                ...existingFields,
                contact_type: selectedContactType,
                last_name: name,
              });
            } else {
              const full_name = `${firstName} ${lastName}`;
              return mutate({
                full_name,
                ...existingFields,
                contact_type: selectedContactType,
                first_name: firstName,
                last_name: lastName,
              });
            }
          })}
        >
          <DialogTitle>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <span>Create new Contact</span>
              <IconButton onClick={onClose}>
                <X />
              </IconButton>
            </Box>
            {rootErrorMessage ? (
              <Alert
                variant="filled"
                sx={(theme) => ({
                  padding: theme.spacing(0.5, 2),
                  margin: theme.spacing(1, 0),
                })}
                severity="error"
              >
                {rootErrorMessage}
              </Alert>
            ) : null}
          </DialogTitle>
          <DialogContent>
            <Box pb={1}>
              <Stack direction={'column'} gap={1}>
                <Typography variant="label">
                  Type <span>*</span>
                </Typography>
                <Controller
                  name={'selectedContactType'}
                  render={({ field }) => {
                    return (
                      <Select
                        {...field}
                        size="small"
                        value={selectedContactType}
                        onChange={(event) => {
                          if (
                            event?.target.value === PERSON_TYPE ||
                            event?.target.value === ORGANIZATION_TYPE
                          ) {
                            setSelectedContactType(event?.target.value);
                          }
                        }}
                      >
                        <MenuItem value={PERSON_TYPE} key={PERSON_TYPE}>
                          Person
                        </MenuItem>
                        <MenuItem
                          value={ORGANIZATION_TYPE}
                          key={ORGANIZATION_TYPE}
                        >
                          Organization
                        </MenuItem>
                      </Select>
                    );
                  }}
                />
              </Stack>
            </Box>
            {selectedContactType === PERSON_TYPE && (
              <Box pb={1}>
                <Stack direction={'column'} gap={1}>
                  <Typography variant="label">
                    First Name <span>*</span>
                  </Typography>
                  <Controller
                    name={'firstName'}
                    render={({ field, fieldState, formState }) => {
                      const shouldShowError =
                        (formState.isSubmitted || fieldState.isTouched) &&
                        !!fieldState.error;
                      return (
                        <TextField
                          autoFocus
                          required
                          {...field}
                          inputProps={{
                            'data-1p-ignore': true,
                          }}
                          error={shouldShowError}
                          helperText={
                            shouldShowError ? fieldState.error?.message : ' '
                          }
                          fullWidth
                          size={'small'}
                        />
                      );
                    }}
                  />
                </Stack>
              </Box>
            )}
            {selectedContactType === PERSON_TYPE && (
              <Box pb={1}>
                <Stack direction={'column'} gap={1}>
                  <Typography variant="label">
                    Last Name <span>*</span>
                  </Typography>
                  <Controller
                    name={'lastName'}
                    render={({ field, fieldState, formState }) => {
                      const shouldShowError =
                        (formState.isSubmitted || fieldState.isTouched) &&
                        !!fieldState.error;
                      return (
                        <TextField
                          required
                          {...field}
                          inputProps={{
                            'data-1p-ignore': true,
                          }}
                          error={shouldShowError}
                          helperText={
                            shouldShowError ? fieldState.error?.message : ' '
                          }
                          fullWidth
                          size={'small'}
                        />
                      );
                    }}
                  />
                </Stack>
              </Box>
            )}
            {selectedContactType === ORGANIZATION_TYPE && (
              <Box pb={1}>
                <Stack direction={'column'} gap={1}>
                  <Typography variant="label">
                    Name <span>*</span>
                  </Typography>
                  <Controller
                    name={'name'}
                    render={({ field, fieldState, formState }) => {
                      const shouldShowError =
                        (formState.isSubmitted || fieldState.isTouched) &&
                        !!fieldState.error;
                      return (
                        <TextField
                          required
                          {...field}
                          inputProps={{
                            'data-1p-ignore': true,
                          }}
                          error={shouldShowError}
                          helperText={
                            shouldShowError ? fieldState.error?.message : ' '
                          }
                          fullWidth
                          size={'small'}
                        />
                      );
                    }}
                  />
                </Stack>
              </Box>
            )}
          </DialogContent>
          <DialogActions sx={(theme) => ({ padding: theme.spacing(0, 3, 2) })}>
            <Button onClick={onClose}>Cancel</Button>
            <Button type={'submit'} disabled={isLoading} variant={'contained'}>
              {isLoading ? 'Creating Contact...' : 'Create'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </FormProvider>
  );
};
