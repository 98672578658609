import React from 'react';
import { css } from 'aphrodite';
import PropTypes from 'prop-types';

/* Components */
import { Container, Body, Footer, Header } from '~/src/components/SlideIn';
import Card from '~/src/components/Card';
import Button from '~/src/components/Button';
import { Form, renderFormField } from '~/src/components/Forms';

/* Styles */
import { default as slideInStyles } from '~/src/components/SlideIn/styles';
import { SLIDE_IN_ANIMATION_DURATION } from '~/src/components/PageLayout/styles';
import styles from '~/src/components/Contacts/styles';

const CreateContactSlideIn = ({
  formFields,
  onCancel,
  onChange,
  onSubmit,
  disabled,
  invalidFormFields,
}) => {
  const handleChange = onChange;
  const fieldsProp = formFields.map((field) => {
    return renderFormField({ ...field, editable: true });
  });

  const invalidFormFieldErrorsList = (
    <ul className={css(styles.contactValidationError)}>
      {Object.entries(invalidFormFields).map(([key, value]) => (
        <li key={key}>
          <strong>{value.field_key}:</strong> {value.error}
        </li>
      ))}
    </ul>
  );

  return (
    <Container>
      <Header title="Create contact" />
      <Body>
        <div className={css(slideInStyles.innerBody)}>
          {invalidFormFields && invalidFormFieldErrorsList}
          <Card dynamic>
            <Form
              focusFirstInput={'text'}
              focusTimeout={SLIDE_IN_ANIMATION_DURATION}
              triggerOnMount
              fields={fieldsProp}
              onChange={handleChange}
              onSubmit={onSubmit}
            />
          </Card>
        </div>
      </Body>
      <Footer alignRight>
        <Button onClick={onCancel} cssStyle={slideInStyles.footerButton}>
          Cancel
        </Button>
        <Button
          primary
          cssStyle={slideInStyles.footerButton}
          onClick={onSubmit}
          disabled={disabled}
        >
          Create contact
        </Button>
      </Footer>
    </Container>
  );
};

CreateContactSlideIn.propTypes = {
  formFields: PropTypes.array,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
};

CreateContactSlideIn.defaultProps = {
  formFields: [],
  onCancel: () => {},
  onChange: () => {},
  onSubmit: () => {},
};

export default CreateContactSlideIn;
