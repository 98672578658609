const global = typeof window === 'undefined' ? {} : window;

export const document =
  typeof global.document === 'undefined'
    ? {
        addEventListener: () => {},
        removeEventListener: () => {},
      }
    : global.document;

export const location =
  typeof global.location === 'undefined' ? '' : global.location;

export const getWindowDimensions = () => {
  const win = global;
  const doc = document;
  const docElem = doc.documentElement;
  const body = doc.getElementsByTagName('body')[0];
  const x = win.innerWidth || docElem.clientWidth || body.clientWidth;
  const y = win.innerHeight || docElem.clientHeight || body.clientHeight;

  return {
    x,
    y,
  };
};

export default global;
