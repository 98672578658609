import React from 'react';
import { css } from 'aphrodite';

import { User, Briefcase } from 'react-feather';

import Table from '~/src/containers/Table';
import Button from '~/src/components/Button';
import Triangle from '~/src/components/Triangle';
import Dropdown from '~/src/components/Dropdown';

import { CardListContactsTable } from '~/src/components/Contacts/CardListContactsTable';
import { useUIFlagEnabled } from '~/src/entities/uiFlags';
import { SearchField } from '~/src/components/SearchField';
import { Box, Typography } from '@mui/material';

import dropdownStyles from '~/src/components/Dropdown/styles';

import { ORGANIZATION_TYPE } from '~/src/stores/contactsStore';
import styles from './styles';

const renderColumnType = (type) => {
  if (type === ORGANIZATION_TYPE) {
    return <Briefcase size={18} />;
  }

  return <User size={18} />;
};

const ContactsActions = ({ onCreateContact }) => {
  return (
    <div className={css(styles.actions)}>
      <Button primary onClick={onCreateContact}>
        Create contact
      </Button>
    </div>
  );
};

const RowAction = (onViewContact, onEditContact, onDeleteContact, data) => {
  let rowToggleDropdown = () => {};

  const renderTrigger = ({ toggleDropdown }) => {
    rowToggleDropdown = toggleDropdown;
    return (
      <span analyticsname="Actions">
        <Button onClick={toggleDropdown} cssStyle={styles.rowActionButton}>
          Actions <Triangle primary orientation={'down'} />
        </Button>
      </span>
    );
  };

  const handleViewContact = () => {
    rowToggleDropdown(false);
    onViewContact({ id: data.id });
  };

  const handleEditContact = () => {
    rowToggleDropdown(false);
    onEditContact({ id: data.id });
  };

  const handleDeleteContact = () => {
    rowToggleDropdown(false);
    onDeleteContact({ id: data.id });
  };

  return (
    <Dropdown renderTrigger={renderTrigger}>
      <a
        onClick={handleViewContact}
        className={css(dropdownStyles.dropdownActionItem)}
        analyticsname="View"
      >
        View
      </a>
      <a
        onClick={handleEditContact}
        className={css(dropdownStyles.dropdownActionItem)}
        analyticsname="Edit"
      >
        Edit
      </a>
      <a
        onClick={handleDeleteContact}
        className={css(dropdownStyles.dropdownActionItem)}
        analyticsname="Delete"
      >
        Delete
      </a>
    </Dropdown>
  );
};

export const ContactsTable = ({
  contacts = [],
  empty,
  onViewContact,
  onEditContact,
  onCreateContact,
  onDeleteContact,
  onSearchChange,
  onSortByChange,
}) => {
  const useCardLists = useUIFlagEnabled('cardLists');

  if (useCardLists) {
    const rowActions = {
      delete: onDeleteContact,
      view: onViewContact,
      edit: onEditContact,
    };

    return (
      <div>
        <Box pb={3} sx={{ display: 'flex' }}>
          <Box>
            <SearchField
              onChange={onSearchChange}
              size="small"
              placeholder="Search Contacts"
            />
          </Box>
          <Box sx={{ position: 'absolute', right: '16px' }}>
            <ContactsActions onCreateContact={onCreateContact} />
          </Box>
        </Box>
        {empty ? (
          <Typography textAlign={'center'} variant="subtitle1">
            No contacts match that filter
          </Typography>
        ) : (
          <CardListContactsTable
            handleDelete={rowActions.delete}
            handleEdit={rowActions.edit}
            handleView={rowActions.view}
            contacts={contacts}
          />
        )}
      </div>
    );
  }

  return (
    <div analyticsname="Contact Actions">
      <Table
        visibleItems={contacts.length}
        empty={empty}
        emptyTitle={'No contacts match that filter'}
        searchPlaceholder={'Search contacts'}
        renderActions={() => (
          <ContactsActions onCreateContact={onCreateContact} />
        )}
        data={contacts}
        onSearchChange={onSearchChange}
        onSortByChange={onSortByChange}
        columns={[
          {
            label: 'Type',
            dataKey: 'type',
            render: renderColumnType,
            style: {
              width: '5%',
              justifyContent: 'center',
              textAlign: 'center',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            },
          },
          {
            label: 'Last Name',
            dataKey: 'lastName',
            style: {
              width: '10%',
              textAlign: 'left',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            },
          },
          {
            label: 'First Name',
            dataKey: 'firstName',
            style: {
              width: '10%',
              textAlign: 'left',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            },
          },
          {
            label: 'Email',
            dataKey: 'email',
            style: { width: '35%' },
            disableSort: true, // disable sort by email temporarily, related https://clio.slack.com/archives/C03573CFPCN/p1667484126183779?thread_ts=1667417660.555939&cid=C03573CFPCN
          },
          {
            label: 'Actions',
            hideLabel: true,
            render: RowAction.bind(
              null,
              onViewContact,
              onEditContact,
              onDeleteContact,
            ),
            style: { flexGrow: 1, display: 'flex', justifyContent: 'flex-end' },
          },
        ]}
      />
    </div>
  );
};
