import React from 'react';
import { forEach } from 'lodash';

/* Components */
import CourtFormsLandingEmptyView from '~/src/components/CourtForms/CourtFormsLandingEmptyView';
import FederalSection from '~/src/components/CourtForms/FederalSection';
import StateSection from '~/src/components/CourtForms/StateSection';
import { DrillDownPanelLink } from '~/src/components/DrillDownPanel';

/* Hooks */
import useFeatureAccesses from '~/src/hooks/useFeatureAccesses';

export const stateCodeToStateName = {
  // USA
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',

  // Canada
  CA_BC: 'British Columbia',
};

const CourtFormsLandingContainer = ({ routeUrl = '/library/court-forms' }) => {
  const {
    isCourtFormsEnabled,
    isFederalCourtFormsEnabled,
    doesFeatureAccessForThisStateCodeExist,
  } = useFeatureAccesses();

  if (!isCourtFormsEnabled) {
    return <CourtFormsLandingEmptyView />;
  }

  const stateCourtFormArray = [];
  forEach(stateCodeToStateName, (stateName, stateCode) => {
    const b = doesFeatureAccessForThisStateCodeExist(stateCode);
    if (b) {
      stateCourtFormArray.push(
        <StateSection key={`${stateCode}_section`} stateName={stateName}>
          <DrillDownPanelLink
            key={`${stateCode}_form`}
            label={`${stateName} forms`}
            linkUrl={`${routeUrl}/${stateCode}/form-listing`}
          />
          <DrillDownPanelLink
            key={`${stateCode}_locality`}
            label={`${stateName} regional forms`}
            linkUrl={`${routeUrl}/${stateCode}/locality-listing`}
          />
        </StateSection>,
      );
    }
  });

  return (
    <div>
      {isFederalCourtFormsEnabled && (
        <FederalSection>
          <DrillDownPanelLink
            label="USCIS forms"
            linkUrl={`${routeUrl}/federal_USCIS`}
          />
        </FederalSection>
      )}

      {stateCourtFormArray}
    </div>
  );
};

export default CourtFormsLandingContainer;
