import React from 'react';
import { Box } from '@mui/material';
import { ControllerRenderProps } from 'react-hook-form';
import {
  ProjectStackField,
  ProjectStackDocument,
} from '~/src/entities/project';
import { FieldType } from '~/src/entities/project/types';
import { ShortTextField } from './ShortTextField';
import { LongTextField } from './LongTextField';
import { SingleSelectField } from './SingleSelectField';
import { MultipleChoiceField } from './MultipleChoiceField';
import { DatePickerField } from './DatePickerField';

export type FieldRenderProps = ControllerRenderProps & {
  onBlur: () => void;
  onFocus: () => void;
  countTouched?: (fieldId: string) => void;
};

export type PopulateCardFieldProps = {
  field: FieldRenderProps;
  projectField: ProjectStackField;
  projectStackDocuments: ProjectStackDocument[];
};

const componentForField = (field: ProjectStackField) => {
  const hasOptions = !!field.multiple_choice_variable?.options;

  if (field.variable_type === FieldType.LONG_TEXT) return LongTextField;

  if (field.variable_type === FieldType.DATE) return DatePickerField;

  if (field.variable_type === FieldType.BATCH_SELECT && hasOptions)
    return MultipleChoiceField;

  if (field.variable_type === FieldType.MULTIPLE_CHOICE && hasOptions)
    return SingleSelectField;

  return ShortTextField;
};

export const PopulateCardField = ({
  field,
  projectField,
  projectStackDocuments,
}: PopulateCardFieldProps) => {
  const FieldComponent = componentForField(projectField);

  return (
    <Box pt={1}>
      <FieldComponent
        field={field}
        projectField={projectField}
        projectStackDocuments={projectStackDocuments}
      />
    </Box>
  );
};
