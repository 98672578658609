/* Libraries */
import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';

/* Components */
import Spinner from '~/src/components/Spinner';

/* Styles */
import styles from './styles';

const ClioLoading = ({ title, subtitle }) => (
  <div className={css(styles.container)}>
    <div>
      {/* TODO: replace with large loader */}
      <Spinner />
      <h1 className={css(styles.title)}>{title}</h1>
      <h2 className={css(styles.subtitle)}>{subtitle}</h2>
    </div>
  </div>
);

ClioLoading.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ClioLoading.defaultProps = {
  title: 'Loading',
  subtitle: 'Please wait',
};

export default ClioLoading;
