import React from 'react';
import { Chip, ChipProps, Typography } from '@mui/material';

type PopulatedChipProps = ChipProps;

const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
  // do not expand/collapse card when this component is clicked
  event.stopPropagation();
};

export const PopulatedChip = (props: PopulatedChipProps) => (
  <Chip
    {...props}
    onClick={handleClick}
    label={
      <Typography
        variant={'smallLabel'}
        sx={(theme) => ({
          color: theme.palette.green[700],
        })}
      >
        Completed
      </Typography>
    }
    sx={(theme) => ({
      background: theme.palette.green[50],
      cursor: 'default',
      ':hover': {
        background: theme.palette.green[50],
      },
    })}
  />
);
