import React, { useEffect } from 'react';

import NodeResolver from './NodeResolver';

const ScrollCaptor = (props) => {
  let isBottom = false;
  let isTop = false;
  let scrollTarget = null;
  let touchStart = 0;

  const { onBottomArrive, onBottomLeave, onTopArrive, onTopLeave } = props;

  const cancelScroll = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleEventDelta = (event, delta) => {
    const { scrollTop, scrollHeight, clientHeight } = scrollTarget;

    const target = scrollTarget;
    const isDeltaPositive = delta > 0;
    const availableScroll = scrollHeight - clientHeight - scrollTop;

    let shouldCancelScroll = false;

    // reset bottom/top flags
    if (availableScroll > delta && isBottom) {
      if (onBottomLeave) {
        onBottomLeave(event);
      }
      isBottom = false;
    }
    if (isDeltaPositive && isTop) {
      if (onTopLeave) {
        onTopLeave(event);
      }
      isTop = false;
    }

    // bottom limit
    if (isDeltaPositive && delta > availableScroll) {
      if (onBottomArrive && !isBottom) {
        onBottomArrive(event);
      }
      target.scrollTop = scrollHeight;
      shouldCancelScroll = true;
      isBottom = true;

      // top limit
    } else if (!isDeltaPositive && -delta > scrollTop) {
      if (onTopArrive && !isTop) {
        onTopArrive(event);
      }
      target.scrollTop = 0;
      shouldCancelScroll = true;
      isTop = true;
    }

    // cancel scroll
    if (shouldCancelScroll) {
      cancelScroll(event);
    }
  };

  const onWheel = (event) => {
    handleEventDelta(event, event.deltaY);
  };

  const onTouchStart = (event) => {
    // set touch start so we can calculate touchmove delta
    touchStart = event.changedTouches[0].clientY;
  };

  const onTouchMove = (event) => {
    const deltaY = touchStart - event.changedTouches[0].clientY;
    handleEventDelta(event, deltaY);
  };

  const startListening = (el) => {
    // bail early if no element is available to attach to
    if (!el) {
      return;
    }

    if (typeof el.addEventListener === 'function') {
      el.addEventListener('wheel', onWheel, false);
      el.addEventListener('touchstart', onTouchStart, false);
      el.addEventListener('touchmove', onTouchMove, false);
    }
  };

  const stopListening = (el) => {
    if (typeof el.removeEventListener === 'function') {
      el.removeEventListener('wheel', onWheel, false);
      el.removeEventListener('touchstart', onTouchStart, false);
      el.removeEventListener('touchmove', onTouchMove, false);
    }
  };

  useEffect(() => {
    startListening(scrollTarget);

    return () => {
      stopListening(scrollTarget);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getScrollTarget = (ref) => {
    scrollTarget = ref;
  };

  return (
    <NodeResolver innerRef={getScrollTarget}>{props.children}</NodeResolver>
  );
};

export default function ScrollCaptorSwitch({ isEnabled = true, ...props }) {
  return isEnabled ? <ScrollCaptor {...props} /> : props.children;
}
