import { useMutation } from 'react-query';
import env from '~/src/utils/env';
import { APIError } from '~/src/utils/error';
import { useCurrentOrgFprint } from '../../user';
import { fetchAndParse, useInvalidateQuery } from '../../utils';
import { keys } from '../keys';

export type WelcomeFormQuestionAnswer = {
  question: string;
  answer: string;
};

export type WelcomeFormData = WelcomeFormQuestionAnswer[];

export const getWelcomeFormSubmitUrl = (orgFprint: string) =>
  `${env.apiUrl}/clients/api/v1/org/${orgFprint}/questionnaire/`;

export type SubmitWelcomeFormVariables = {
  payload: WelcomeFormData;
};

export const useSubmitWelcomeForm = () => {
  const orgFprint = useCurrentOrgFprint();
  return useMutation<WelcomeFormData, APIError, SubmitWelcomeFormVariables>({
    onSuccess: useInvalidateQuery(keys.all),
    mutationFn: ({ payload }) =>
      fetchAndParse(getWelcomeFormSubmitUrl(orgFprint), {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(payload),
      }),
  });
};
