/* Libraries */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import qs from 'qs';

/* Hooks */

/* Components */
import TemplateSetDraftContainer from '~/src/containers/TemplateSetDraft';
import { useLayoutContext } from '~/src/contexts/Layout';

const TemplateRoutes = () => {
  const { setTitle } = useLayoutContext();

  return (
    <Switch>
      <Route
        exact
        path="/templates/docset/new"
        render={({ location }) => {
          const { ids, title } = qs.parse(location.search, {
            ignoreQueryPrefix: true,
          });

          setTitle('Draft new document set');

          return <TemplateSetDraftContainer templateIds={ids} title={title} />;
        }}
      />
      <Route
        exact
        path="/templates/:templateId/new"
        render={({ match: { params } }) => {
          setTitle('Draft new document');

          return (
            <TemplateSetDraftContainer templateIds={[params.templateId]} />
          );
        }}
      />
      <Redirect to={'/library'} />
    </Switch>
  );
};

export default TemplateRoutes;
