import { useEffect } from 'react';
import { document } from '~/src/utils/window';

const useRefMouseDown = (ref, editing, onRefClick, onOutsideRefClick) => {
  function onPointerDown(event) {
    if (
      event &&
      ref.current &&
      !ref.current.contains(event.target) &&
      !event.defaultPrevented
    ) {
      onOutsideRefClick();
    } else {
      onRefClick();
    }
  }

  function addListner() {
    if (window.PointerEvent) {
      document.body.addEventListener('pointerdown', onPointerDown, false);
    } else {
      document.body.addEventListener('mousedown', onPointerDown, false);
    }
  }

  function removeListener() {
    if (window.PointerEvent) {
      document.removeEventListener('pointerdown', onPointerDown, false);
    } else {
      document.removeEventListener('mousedown', onPointerDown, false);
    }
  }

  function addOrRemoveListener() {
    if (editing) {
      addListner();
    } else {
      removeListener();
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(addOrRemoveListener, [editing]);

  useEffect(() => {
    addOrRemoveListener();
    return removeListener;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useRefMouseDown;
