import React from 'react';
import { css } from 'aphrodite';
import Button from '~/src/components/Button';
import { TextInput } from '~/src/components/Inputs';
import { ReactComponent as MiniSpinner } from '~/src/static/mini-spinner.svg';

import styles from '../styles';

type CouponInputProps = {
  coupon?: string;
  error?: string;
  onChange: (coupon: string) => void;
  onSubmit: () => void;
  submitting?: boolean;
};

export const CouponInput = ({
  coupon = '',
  error,
  onChange,
  onSubmit,
  submitting,
}: CouponInputProps) => {
  return (
    <div className="flex flex-row mt-10">
      <TextInput
        id="coupon"
        placeholder="Add coupon"
        value={coupon}
        noMargin={true}
        type="text"
        editable={true}
        error={error}
        onChange={onChange}
      />
      <Button
        className={`${css(styles.applyCouponButton)} ml-3`}
        disabled={!coupon || submitting}
        onClick={onSubmit}
        discrete
      >
        {submitting ? <MiniSpinner height={20} width={20} /> : 'Apply'}
      </Button>
    </div>
  );
};
