import { useQuery, UseQueryOptions } from 'react-query';
import {
  PreviewDocumentPdfResponse,
  PreviewDocumentWordResponse,
} from '../types';
import { APIError } from '~/src/utils/error';
import { useCurrentOrgFprint } from '../../user';
import { composeQueryOpts } from '../../utils';
import { keys } from '../keys';
import env from '~/src/utils/env';

export const useTemplatePreview = (
  templateId: number,
  isMSWord: boolean,
  options: UseQueryOptions<
    PreviewDocumentPdfResponse | PreviewDocumentWordResponse,
    APIError,
    PreviewDocumentPdfResponse | PreviewDocumentWordResponse
  > = {},
) => {
  const orgFprint = useCurrentOrgFprint();
  return useQuery(
    composeQueryOpts(options, {
      queryKey: keys.templatePreview(templateId),
      queryFn: async () => {
        const response = await fetch(
          isMSWord
            ? `${env.apiUrl}/word-addin/api/v1/org/${orgFprint}/template/${templateId}/`
            : `${env.apiUrl}/pdf/api/v1/org/global_template/preview/${templateId}/`,
        );

        if (!response.ok)
          throw new APIError(
            `Failed to fetch template preview for ${templateId}`,
            response,
          );
        return response.json() as Promise<
          PreviewDocumentPdfResponse | PreviewDocumentWordResponse
        >;
      },
    }),
  );
};
