import { Close } from '@mui/icons-material';
import { DialogTitle, IconButton, Box } from '@mui/material';
import React from 'react';

type ImportHeaderProps = {
  title: React.ReactNode | string;
  onClose: () => void;
};

export const ImportHeader = ({ title, onClose }: ImportHeaderProps) => {
  return (
    <DialogTitle>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Box display={'flex'} alignItems={'center'}>
          {title}
        </Box>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
    </DialogTitle>
  );
};
