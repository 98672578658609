import React from 'react';
import { NavLink } from 'react-router-dom';
import { css } from 'aphrodite';

import styles from '../../components/Settings/styles';

import { useCurrentOrg } from '~/src/entities/user';

export const Sidebar = () => {
  const admin = useCurrentOrg().org?.admin;

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.section)}>
        <div className={css(styles.header)}>YOUR ACCOUNT</div>
        <NavLink
          to={'/settings/account'}
          className={'sub-navigation-link'}
          activeClassName={'sub-navigation-link-active'}
        >
          Profile
        </NavLink>
        <NavLink
          to={'/settings/security'}
          className={'sub-navigation-link'}
          activeClassName={'sub-navigation-link-active'}
        >
          Security
        </NavLink>
      </div>
      {admin && (
        <div className={css(styles.section)}>
          <div className={css(styles.header)}>SUBSCRIPTION & BILLING</div>
          <NavLink
            to={'/settings/subscriptions'}
            className={'sub-navigation-link'}
            activeClassName={'sub-navigation-link-active'}
          >
            Subscription
          </NavLink>
          <NavLink
            to={'/settings/billing'}
            className={'sub-navigation-link'}
            activeClassName={'sub-navigation-link-active'}
          >
            Billing history
          </NavLink>
        </div>
      )}
      <div className={css(styles.section)}>
        <div className={css(styles.header)}>WORKSPACE & TEAM</div>
        <NavLink
          to={'/settings/organization'}
          className={'sub-navigation-link'}
          activeClassName={'sub-navigation-link-active'}
        >
          Organization
        </NavLink>
        {admin && (
          <NavLink
            to={'/settings/team'}
            className={'sub-navigation-link'}
            activeClassName={'sub-navigation-link-active'}
          >
            Team
          </NavLink>
        )}
        <NavLink
          to={'/settings/integrations'}
          className={'sub-navigation-link'}
          activeClassName={'sub-navigation-link-active'}
        >
          Integrations
        </NavLink>
      </div>
    </div>
  );
};
