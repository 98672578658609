import { useObserver } from 'mobx-react';

/* Hooks */
import { useStore } from '~/src/hooks/useMst';
import useFeatureAccesses from '~/src/hooks/useFeatureAccesses';

export const useClio = () => {
  const { clio } = useStore();
  return useObserver(() => ({
    isAuthenticated: clio.authenticated,
  }));
};

export const useIsClioAuthenticated = () => useClio().isAuthenticated;

export const useCanSyncSingleMatter = () => {
  const isClioAuthenticted = useIsClioAuthenticated();
  const isUnifiedPlatform = useFeatureAccesses().isUnifiedPlatform;
  return isClioAuthenticted && isUnifiedPlatform;
};

export default useClio;
