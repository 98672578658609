import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Box,
  Select,
  MenuItem,
} from '@mui/material';

import { Close } from '@mui/icons-material';
import { FormField } from '~/src/components/FormField';

export type UpdateSeatsModalProps = {
  defaultSeats: number;
  onChange: (seats: number) => void;
} & Omit<DialogProps, 'onChange'>;

export const UpdateSeatsModal = ({
  defaultSeats,
  onChange,
  open,
  ...rest
}: UpdateSeatsModalProps) => {
  const [seats, setSeats] = useState(defaultSeats + 1);

  return (
    <Dialog
      fullWidth
      maxWidth={'xs'}
      open={open}
      {...rest}
      TransitionProps={{
        onEnter: () => setSeats(defaultSeats + 1),
      }}
    >
      <DialogTitle>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box>Update seats</Box>
          <IconButton
            data-testid="update-seats-modal-close-icon"
            onClick={(e) => rest.onClose?.(e, 'backdropClick')}
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers={true}>
        <FormField
          helperText={
            defaultSeats > 1
              ? `Occupied seats cannot be removed. You must remove members of your team if you'd like to have less than ${defaultSeats}`
              : undefined
          }
          labelProps={{ htmlFor: 'seats' }}
          label={'Number of seats'}
        >
          <Select
            inputProps={{ id: 'seats' }}
            value={seats}
            onChange={(e) => setSeats(+e.target.value)}
          >
            {Array.from({ length: 50 - defaultSeats }).map((_, i) => (
              <MenuItem key={i} value={i + defaultSeats + 1}>
                {i + defaultSeats + 1}
              </MenuItem>
            ))}
          </Select>
        </FormField>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="update-seats-modal-cancel"
          variant="outlined"
          onClick={(e) => rest.onClose?.(e, 'escapeKeyDown')}
        >
          Cancel
        </Button>
        <Button
          data-testid="update-seats-modal-confirm"
          variant="contained"
          onClick={() => onChange(seats)}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
