import React, { FC } from 'react';

import { Text } from '~/src/components/Typography';
import { Signer } from '~/src/models';

interface Props {
  signer: Signer;
  label: string;
  signatureCount: number;
  dateCount: number;
  indicatorColor: string;
}

const SignerDetails: FC<Props> = ({
  signer,
  label,
  signatureCount,
  dateCount,
  indicatorColor,
}) => {
  const noFieldsAssigned = dateCount + signatureCount === 0;
  const signatureLabel =
    signatureCount === 1
      ? `${signatureCount} Signature`
      : `${signatureCount} Signatures`;
  const dateCountLabel =
    dateCount === 1 ? `${dateCount} Date` : `${dateCount} Dates`;
  const noFieldsAssignedText = noFieldsAssigned
    ? 'No fields assigned'
    : `${signatureLabel} • ${dateCountLabel}`;

  return (
    <div className="flex flex-row pb-4">
      <div className="pt-2 pr-2">
        <div
          className="w-3 h-3 rounded-full"
          style={{ backgroundColor: indicatorColor }}
        ></div>
      </div>
      <div className="w-full">
        <div>
          <Text variant="body" strong>
            {label}
          </Text>
        </div>

        <div>
          <Text variant="body">{signer.email}</Text>
        </div>

        <div>
          <Text variant="caption" subdued>
            {noFieldsAssignedText}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default SignerDetails;
