/* Libraries */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import qs from 'qs';
import _ from 'lodash';

/* Higher Order Components */
import WithLayoutProps from '~/src/hoc/WithLayoutProps';

/* Components */
import TemplateSetDraft from '~/src/components/Templates/TemplateSetDraft';

/* Constants */
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import { history } from '../utils/history';

import { usePollingTaskStatus } from '~/src/hooks/usePollingTaskStatus';
import { AsyncResultStatus } from '~/src/entities/project/types';

const TemplateSetDraftContainer = ({
  templateIds,
  title,
  store,
  showToast,
}) => {
  const [taskId, setTaskId] = useState(undefined);
  useEffect(() => {
    store.templates.fetchTemplates(templateIds);
  }, [templateIds, store.templates.fetchTemplates, store.templates]);

  const updatePathWithQueryParams = (templateIDs, title) => {
    const queryParams = qs.stringify(
      { ids: templateIDs, title: title },
      { encode: false },
    );
    const path = `/templates/docset/new?${queryParams}`;

    history.replace(path);
  };

  const clearTaskIdAndToastError = () => {
    setTaskId(undefined);
    showToast(LAYOUT_TOAST_TYPES.error, {
      message: 'Something went wrong, please try again later.',
    });
  };

  const taskStatusQuery = usePollingTaskStatus(taskId);

  useEffect(() => {
    const { data } = taskStatusQuery;
    if (data) {
      const { status, result } = data;
      if (status === AsyncResultStatus.SUCCESS) {
        if (result?.project_id) {
          history.replace(`/populate/${result.project_id}`, {
            initialProject: true,
          });
        } else {
          clearTaskIdAndToastError();
        }
      } else if (status === AsyncResultStatus.FAILURE) {
        clearTaskIdAndToastError();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskStatusQuery]);

  const handleCreateDraft = async (name, matterId) => {
    try {
      const draft = await store.projects.createDocSet(
        templateIds,
        name,
        matterId,
      );
      if (draft.task_id) {
        setTaskId(draft.task_id);
      } else {
        if (!draft || !draft.id) throw new Error('Missing response object.');

        if (draft.documents.length > 1) {
          store.documentSets.addDocumentSetToStore(draft);
        } else {
          store.documents.addDocumentToStore(draft);
        }

        history.replace(`/populate/${draft.id}`, {
          initialProject: true,
        });
      }
    } catch (error) {
      if (error.message === 'WORD_DOCUMENT_AUTOMATION') {
        // user not authorized for Word document automation (not subbed).
        showToast(LAYOUT_TOAST_TYPES.error, {
          message:
            'You need to be subscribed to word document automation to use word templates',
        });
        history.push('/settings/subscriptions');
        return;
      }
      showToast(LAYOUT_TOAST_TYPES.error, {
        message: 'Something went wrong, please try again later.',
      });
    }
  };

  const handleChangeTemplateIDs = (field) => {
    const nextTemplateIds = field.value || [];
    const changed = !_.isEqual(
      _.sortBy(nextTemplateIds),
      _.sortBy(templateIds),
    );

    if (changed) {
      templateIds = nextTemplateIds || [];

      updatePathWithQueryParams(templateIds, title);
    }
  };

  const matter = store.matter.selectOptions();
  const msTemplates = store.templates.selectOptions();
  const pdfTemplates = store.pdfCourtForms.selectOptions();
  const templateDefaultValues = store.templateDefaultValues.selectOptions();
  const templates = [...msTemplates, ...pdfTemplates, ...templateDefaultValues];

  const loading = templates.length < templateIds.length;
  // to keep order of templateIds (which is ordered by sortOrder property of sidebarItem)
  const sortedSelectedTemplates = [];
  for (let i = 0; i < templateIds.length; i++) {
    const found = templates.find((t) => t.value === templateIds[i]);
    if (found) {
      sortedSelectedTemplates.push(found);
    }
  }

  if (loading) {
    return null;
  }

  return (
    <TemplateSetDraft
      matter={matter}
      templates={templates}
      defaults={{ name: title }}
      sortedSelectedTemplates={sortedSelectedTemplates}
      onChangeTemplate={handleChangeTemplateIDs}
      onCreateDraft={handleCreateDraft}
    />
  );
};

TemplateSetDraftContainer.propTypes = {
  templateIds: PropTypes.array,
};

export default inject((store) => store)(
  WithLayoutProps(observer(TemplateSetDraftContainer)),
);
