import { useMutation, UseMutationOptions } from 'react-query';
import { APIError } from '~/src/utils/error';
import { useCurrentOrgFprint } from '../../user';
import { composeMutationOpts, fetcher, useInvalidateQuery } from '../../utils';
import { keys } from '../keys';
import { getRenewSubscriptionUrl } from '../urls';

export const useRenewSubscription = (
  options: UseMutationOptions<void, APIError> = {},
) => {
  const orgFprint = useCurrentOrgFprint();
  return useMutation(
    composeMutationOpts(options, {
      onSuccess: useInvalidateQuery(keys.subscription),
      mutationFn: async () => {
        await fetcher(getRenewSubscriptionUrl(orgFprint), {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ plan: 'Pro', duration: 'monthly' }),
        });
      },
    }),
  );
};
