import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

import { NAVIGATION_WIDTH } from '~/src/components/PageLayout/styles';

export default StyleSheet.create({
  documentContainer: {
    flexGrow: 1,
    paddingBottom: '67px',
  },
  documentPreviewImage: {
    display: 'block',
    margin: '0 auto',
    maxWidth: '800px',
    boxShadow:
      'inset 1px 1px 0 rgba(0, 0, 0, .1), inset 0 -1px 0 rgba(0, 0, 0, .07)',
    marginBottom: theme.unit * 2,
  },
  fullWidthFooter: {
    left: 0,
  },
  documentPreviewFooter: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'space-between',
    bottom: 0,
    left: NAVIGATION_WIDTH,
    right: 0,
    borderTop: theme.border,
    background: theme.surface,
    padding: theme.unit * 2,
  },
  rightSideButtons: {
    display: 'flex',
    alignItems: 'center',
  },
  clioButton: {
    marginRight: '10px',
  },
  eSignButton: {
    marginRight: '10px',
  },
  documentPreviewHighlightToggle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  documentPreviewHighlightToggleText: {
    display: 'inline-flex',
    fontSize: theme.fonts.size.small,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: theme.unit,
    marginRight: theme.unit * 2,
  },
  saveFormTemplateButton: {
    marginLeft: theme.unit,
  },
});
