/* Libraries */
import React from 'react';
import { StyleSheet, css } from 'aphrodite';

/* Components */
import { components } from 'react-select';
import { X, Search, Plus } from 'react-feather';
import SelectInput from './SelectInput';
import Spinner from '~/src/components/Spinner';

/* Configurations */
import theme from '~/src/theme';

const styles = StyleSheet.create({
  input: {
    border: 'none',
  },
  inputIcon: {
    paddingLeft: '10px',
  },
  newContactIcon: {
    marginBottom: '-1px',
    paddingRight: '5px',
  },
  createNewContact: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 16px',
    color: theme.colors.bluebird,
    borderBottom: theme.border,
  },
  contactSearchSpinner: {
    // this scale factor reduces the 38x38 Spinner to be 24x24
    transform: 'scale(0.63157)',
    display: 'flex',
    alignItems: 'center',
    padding: '12px 16px',
  },
  noMatchesFound: {
    padding: '12px 16px',
    textAlign: 'left',
  },
});

const ClearIndicator = (props) => {
  const {
    children = <X size={14} color={theme.colors.charcoal} />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;

  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles('clearIndicator', props)}
    >
      <div style={{ padding: '0px 5px' }}>{children}</div>
    </div>
  );
};

const ClearIndicatorStyles = (base) => ({
  ...base,
  cursor: 'pointer',
});

const ControlStyles = (provided, state) => {
  return {
    ...provided,
    minHeight: theme.unit * 4,
    border: 'solid 1px #EEF1F6',
    borderColor: state.isFocused ? theme.colors.bluebird : '#EEF1F6 !important',
    boxShadow: '0',
    background: '#EEF1F6',
  };
};

const Control = (props) => {
  const { isFocused, hasValue } = props;

  const iconColor = isFocused ? theme.colors.bluebird : theme.colors.charcoal;

  const children = hasValue
    ? props.children
    : [
        <div key={`icon`} className={css(styles.inputIcon)}>
          <Search size={14} color={iconColor} />
        </div>,
        ...props.children,
      ];

  return <components.Control {...props}>{children}</components.Control>;
};

const ContactsSearchBar = ({
  value,
  onChange,
  contacts,
  onCreateNewContact,
  onSearchContact,
  loading,
  done,
  data,
  empty,
}) => {
  const handleInputChange = (value, { action }) => {
    if (action === 'input-change') {
      onSearchContact(value);
    }
  };

  let noOptionsLoadedMessageStyleOverride = {
    noOptionsMessage: (provided) => ({
      ...provided,
      padding: '0px 16px',
      borderBottom: theme.border,
      textAlign: 'left',
    }),
  };

  const MenuList = (props) => {
    return (
      <components.MenuList {...props}>
        <div
          className={css(styles.createNewContact)}
          onClick={onCreateNewContact}
        >
          <i className={css(styles.newContactIcon)}>
            <Plus color={theme.colors.bluebird} size={12} />
          </i>
          <span>Create new contact</span>
        </div>
        {loading && (
          <div className={css(styles.contactSearchSpinner)}>
            <Spinner />
          </div>
        )}
        {props.children}
      </components.MenuList>
    );
  };

  const noOptionsLoadedMessage = () => {
    // This function generates the message for the Contacts Search Bar when there are no options
    if (done && data.length === 0 && !loading && !empty) {
      // we don't want to display the No matches found message until we have completed a fetch to the backend
      noOptionsLoadedMessageStyleOverride = {};
      return (
        <div className={css(styles.noMatchesFound)}>No matches found.</div>
      );
    }
    return '';
  };

  return (
    <SelectInput
      cssStyle={styles.input}
      placeholder="Attach contact"
      noOptionsMessage={noOptionsLoadedMessage}
      customComponents={{
        IndicatorSeparator: () => null,
        DropdownIndicator: () => null,
        ClearIndicator,
        Control,
        MenuList,
      }}
      customStyles={{
        clearIndicator: ClearIndicatorStyles,
        control: ControlStyles,
        ...noOptionsLoadedMessageStyleOverride,
      }}
      value={value}
      onChange={onChange}
      isClearable={true}
      editable={true}
      onInputChange={handleInputChange}
      options={contacts}
    />
  );
};

export default ContactsSearchBar;
