import { http, getPaginationUrlQuery } from '~/src/services/requests';

function signaturesService() {
  return {
    createSignaturePackage: async (orgFprint, data) => {
      return http.formUpload({
        parse: true,
        relativePath: `/sign/api/v1/org/${orgFprint}/new_package/`,
        data,
      });
    },
    createCustomSignaturePackage: async (orgFprint, documentsData) => {
      return http.formUpload({
        parse: true,
        relativePath: `/sign/api/v1/org/${orgFprint}/new_package/custom/`,
        data: documentsData,
      });
    },
    createSignaturePackageFromExistingProject: async (
      orgFprint,
      projectId,
      data,
    ) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/sign/api/v1/org/${orgFprint}/project/${projectId}/`,
          method: 'POST',
          body: {
            lawyaw_project_id: data.lawyawProjectId,
            lawyaw_org_id: data.lawyawOrgId,
            documents: data.documents,
          },
        },
        parse,
      );
    },
    deleteDocument: async (orgFprint, templateDocId) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/document/api/v1/org/${orgFprint}/template/${templateDocId}/uncoded/`,
          method: 'GET',
        },
        parse,
      );
    },
    updateDocumentForPackage: async (
      orgFprint,
      projectId,
      signaturePackageFprint,
      documentsData,
    ) => {
      return http.formUpload({
        parse: true,
        relativePath: `/sign/api/v1/org/${orgFprint}/project/${projectId}/package/${signaturePackageFprint}/documents/`,
        data: documentsData,
      });
    },
    addDocumentsToPackage: async (
      orgFprint,
      projectId,
      signaturePackageFprint,
      documentsData,
    ) => {
      return http.formUpload({
        parse: true,
        relativePath: `/sign/api/v1/org/${orgFprint}/project/${projectId}/package/${signaturePackageFprint}/documents/add/`,
        data: documentsData,
      });
    },
    updateSignaturePackage: async (
      orgFprint,
      projectId,
      signatureFprint,
      signaturePackageData,
    ) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/sign/api/v1/org/${orgFprint}/project/${projectId}/package/${signatureFprint}/`,
          method: 'PUT',
          body: signaturePackageData,
        },
        parse,
      );
    },
    uploadUserSignatureImage: async (
      orgFprint,
      projectId,
      signatureFprint,
      recipientFprint,
      signatureImageBlob,
    ) => {
      const signatureImageData = new FormData();
      signatureImageData.append('recipient_id', recipientFprint);
      signatureImageData.append('photofile', signatureImageBlob);
      // eslint-disable-next-line no-return-await
      return await http.formUpload({
        parse: true,
        relativePath: `/sign/api/v2/org/${orgFprint}/project/${projectId}/package/${signatureFprint}/saveCurrentUserImage/`,
        data: signatureImageData,
      });
    },
    uploadUserSignatureImageXfdf: async (
      orgFprint,
      projectId,
      signatureFprint,
      recipientFprint,
      signatureImageBlob,
    ) => {
      const signatureImageData = new FormData();
      signatureImageData.append('recipient_id', recipientFprint);
      signatureImageData.append('xfdf_signature', signatureImageBlob);
      // eslint-disable-next-line no-return-await
      return await http.formUpload({
        parse: true,
        relativePath: `/sign/api/v2/org/${orgFprint}/project/${projectId}/package/${signatureFprint}/saveCurrentUserImage/`,
        data: signatureImageData,
      });
    },
    assignSignatureField: async (
      orgFprint,
      projectId,
      signaturePackageFprint,
      signatureElementData,
    ) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/sign/api/v1/org/${orgFprint}/project/${projectId}/package/${signaturePackageFprint}/assign/`,
          method: 'POST',
          headers: { 'Return-Documents-data': true },
          body: signatureElementData,
        },
        parse,
      );
    },
    deleteAssignedSignatureField: async (
      orgFprint,
      projectId,
      signaturePackageFprint,
      elementId,
    ) => {
      const parse = true;
      // eslint-disable-next-line no-return-await
      return await http.innerSendAuthenticated(
        {
          relativePath: `/sign/api/v1/org/${orgFprint}/project/${projectId}/package/${signaturePackageFprint}/element/${elementId}/`,
          method: 'DELETE',
          headers: { 'Return-Documents-data': true },
        },
        parse,
      );
    },
    sendSignaturePackage: async (orgFprint, signaturePackageData) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/sign/api/v1/org/${orgFprint}/project/${signaturePackageData.lawyawProjectId}/package/${signaturePackageData.fprint}/send/`,
          method: 'POST',
          body: {
            email_subject: signaturePackageData.emailSubject,
            email_cc: signaturePackageData.emailCC,
            email_body: signaturePackageData.emailBody,
          },
        },
        parse,
      );
    },

    /*
    (1) If the signature package (email) is not sent yet,
    this API will remomve(delete) the signature package.

    (2) If the signature package (email) is sent and not every recipient has signed/submitted,
    this API will cancel the signature package.

    (3) If the signature package (email) is sent and every recipient has signed/submitted,
    this API will remomve(delete) the signature package.
    */
    cancelOrRemoveSignaturePackage: async (orgFprint, signaturePackageData) => {
      const parse = false;
      return http.innerSendAuthenticated(
        {
          relativePath: `/sign/api/v2/org/${orgFprint}/project/${signaturePackageData.lawyawProjectId}/package/${signaturePackageData.fprint}/delete/`,
          method: 'DELETE',
        },
        parse,
      );
    },

    reorderDocuments: async (orgFprint, signaturePackage, docIds) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/project/${signaturePackage.lawyawProjectId}/sort-order/`,
          method: 'PUT',
          body: {
            docids: docIds,
          },
        },
        parse,
      );
    },
    printCombined: async (orgFprint, documentsHTML) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/panels/api/v1/printcombined/`,
          method: 'POST',
          responseType: 'arraybuffer',
          body: {
            header: '',
            documents: [
              {
                src: documentsHTML,
                title: 'yellow',
              },
            ],
            fprint: orgFprint,
          },
        },
        parse,
      );
    },

    getSignaturePackages: async (
      orgFprint,
      query = { sortBy: 'id', order: 'desc' },
    ) => {
      let searchQuery = '';

      if (query) {
        const { page, search, sortBy, order, limit } = query;
        searchQuery = getPaginationUrlQuery({
          page,
          search,
          sortBy,
          order,
          limit,
        });
      }

      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/sign/api/v1/org/${orgFprint}/packages/${searchQuery}`,
          method: 'GET',
        },
        parse,
      );
    },
    getSignaturePackage: async (
      orgFprint,
      projectId,
      signaturePackageFprint,
    ) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          method: 'GET',
          relativePath: `/sign/api/v1/org/${orgFprint}/project/${projectId}/package/${signaturePackageFprint}/`,
        },
        parse,
      );
    },
  };
}
export default signaturesService();
