import { QueryParams } from './urls';

export const keys = {
  all: ['matters'] as const,
  lists: () => [...keys.all, 'list'] as const,
  list: (filters: Partial<QueryParams>) =>
    [...keys.lists(), { filters }] as const,
  details: () => [...keys.all, 'detail'] as const,
  detail: (id: number) => [...keys.details(), id] as const,
};
