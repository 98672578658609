import React from 'react';

import Table from '~/src/containers/Table';
import { DrillDownPanelLink, DrillDownPanelButton } from '../DrillDownPanel';
import { ReactComponent as InformationIcon } from './information-icon.svg';

const RequestCourtForm = () => (
  <div className="mt-2">
    <InformationIcon className="mr-2 mb-1" />
    <span className="mr-2">Can&apos;t find the forms you are looking for?</span>
    <a
      href="https://lawyaw.typeform.com/request-a-form"
      target="_blank"
      rel="noreferrer"
    >
      Request a form
    </a>
  </div>
);

const normalizeLocality = (locality) => {
  return locality.replaceAll(' ', '_');
};

export const denormalizeLocality = (normalizedLocality) => {
  return normalizedLocality.replaceAll('_', ' ');
};

const StateRegionTable = ({
  actionType,
  pdfCourtForms,
  visibleItems,
  empty,
  onSearchChange,
  handleClick,
  routeUrl = '/library/court-forms',
}) => {
  const renderLocalityColumnButton = (columnData, { rowData }) => (
    <DrillDownPanelButton
      label={rowData.locality}
      handleClick={() => handleClick(rowData.locality)}
    />
  );

  const renderLocalityColumnLink = (columnData, { rowData }) => (
    <DrillDownPanelLink
      label={rowData.locality}
      linkUrl={`${routeUrl}/${rowData.filterCategory}/${normalizeLocality(
        rowData.locality,
      )}/form-listing`}
      noBorder={true}
    />
  );

  return (
    <div className="flex">
      <Table
        emptyTitle={'No localities match that filter'}
        empty={empty}
        isLoading={false}
        searchPlaceholder="Search regions"
        data={pdfCourtForms}
        visibleItems={visibleItems}
        onSearchChange={onSearchChange}
        isSortByDisabled={
          true
        } /* since backend library(typesense) does NOT support sorting by string value, https://github.com/typesense/typesense/issues/117, https://github.com/typesense/typesense/issues/90 */
        columns={[
          {
            label: 'Region',
            dataKey: 'locality',
            render:
              actionType === 'button'
                ? renderLocalityColumnButton
                : renderLocalityColumnLink,
            style: {
              padding: '0',
              margin: '0px 0px 0px 3px',
              width: '100%',
              textAlign: 'left',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            },
          },
        ]}
        renderActions={() => <RequestCourtForm />}
      />
    </div>
  );
};

export default StateRegionTable;
