import React from 'react';

import PropTypes from 'prop-types';
import DraggableList from '~/src/components/Draggable/DraggableList';

const DraggableListContainer = ({
  renderDraggableListItem,
  items,
  showNSelectedFragment,
  selectedDocumentIds,
}) => {
  const renderNSelectedFragment = () => {
    if (showNSelectedFragment) {
      return (
        <div className="text-gray-900 p-4">
          {
            Object.keys(selectedDocumentIds).filter(
              (key) => selectedDocumentIds[key],
            ).length
          }{' '}
          selected
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {renderNSelectedFragment()}
      <DraggableList
        items={items}
        renderDraggableListItem={renderDraggableListItem}
      />
    </>
  );
};

DraggableListContainer.propTypes = {
  renderDraggableListItem: PropTypes.func,
  items: PropTypes.array,
};

DraggableListContainer.defaultProps = {
  renderDraggableListItem: () => {},
  items: [],
};

export default DraggableListContainer;
