import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { history } from '~/src/utils/history';

import Tabs from '~/src/components/PageLayout/Tabs';
import Page from '~/src/components/PageLayout';

import { QuestionnaireTable } from './QuestionnaireTable';
import { QuestionnaireSubmissionTable } from './QuestionnaireSubmissionTable';
import { QuestionnaireSubmission } from '@clio/questionnaire-builder';

import analyticsService from '~/src/services/analytics';
import { tryConvertToNumber } from '~/src/entities/questionnaires';

const tabs = (
  <Tabs
    tabsMetadata={[
      {
        headerText: 'Questionnaire Templates',
        toUrl: '/questionnaires/templates',
      },
      {
        headerText: 'Live Questionnaires',
        toUrl: '/questionnaires/responses',
      },
    ]}
    active={undefined}
    setActive={undefined}
  />
);

export const ClioQuestionnairePage: React.FC = () => {
  return (
    <Page title={'Questionnaires'} headerProps={{ tabs }}>
      <Switch>
        <Route exact path="/questionnaires/templates">
          <QuestionnaireTable />
        </Route>
        <Route exact path="/questionnaires/responses">
          <QuestionnaireSubmissionTable
            status={['pending', 'not_started', 'completed', 'needs_review']}
            onViewDetails={(submission: QuestionnaireSubmission) => {
              analyticsService.track('Clicked on Live Questionnaire', {
                questionnaire_id: submission.questionnaire_id,
                submission_id: submission.id,
                matter_id: tryConvertToNumber(submission.target_identifier),
              });
              history.push(
                `/questionnaires/${submission.questionnaire_id}/responses/${submission.id}`,
              );
            }}
          />
        </Route>
        <Route path="*">
          <QuestionnaireTable />
        </Route>
      </Switch>
    </Page>
  );
};
