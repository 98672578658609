import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { camelCase } from 'lodash';

/* Hooks */
import useFetchQuestionnaireResponse from '~/src/hooks/useFetchQuestionnaireResponse';

/* Containers */
import PageContainer from '~/src/containers/pages/Page';

/* Components */
import { Column } from '~/src/components/PageLayout/Layout';
import LoadingOverlay from '~/src/components/PageLayout/LoadingOverlay';
// import { QuestionnaireFormPreview } from '~/src/components/QuestionnaireForm'
import { Form, renderFormField } from '~/src/components/Forms';

/* Styles */

/* Constants */
import { LAYOUT_FOOTER_TYPES, useLayoutContext } from '~/src/contexts/Layout';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import {
  QUESTION_TYPE_LOOKUP,
  QUESTION_TYPE_SHORT_TEXT,
  QUESTION_TYPE_STATIC_TEXT,
} from '~/src/components/QuestionnaireForm/QuestionForm';

const QuestionResponseGroup = (props) => {
  const { name, edit, answers, onChange } = props;

  const questions = answers.map((answer) => ({
    id: answer.id,
    label: answer.questionText,
    type: edit
      ? answer.type || QUESTION_TYPE_SHORT_TEXT
      : QUESTION_TYPE_STATIC_TEXT,
    defaultValue: answer.answerText,
    hint: answer.questionHint,
    options: (answer.questionData && answer.questionData.options) || [],
  }));

  const formFields = questions.map((question) => {
    const {
      label,
      type,
      hint,
      defaultValue,
      required,
      options,
      uploadLimit,
      uploadTypes,
    } = question;

    const field = {
      label,
      hint,
      defaultValue,
      type: QUESTION_TYPE_LOOKUP[type],
      id: question.id || camelCase(label),
      required,
      options,
      validation: () => true,
      uploadLimit,
      uploadTypes: uploadTypes && uploadTypes.flat(),
    };

    return renderFormField(field);
  });

  return (
    <Column small width="750px" margin={'0 auto'}>
      <div className="relative my-4 p-4 bg-white rounded shadow-sm hover:shadow-lg tranistion">
        <h1 className="p-2 mb-4 w-full text-lg text-center outline-none rounded cursor-text transition-all hover:bg-blueGray-100 focus:bg-blueGray-100">
          {name}
        </h1>
        <Form fields={formFields} className="mb-4" onChange={onChange} />
      </div>
    </Column>
  );
};

const QuestionnaireResponsePageContainer = (props) => {
  const { id } = props;

  const { intakeResponse, loading, updateQuestionnaireResponse } =
    useFetchQuestionnaireResponse(id);

  const [edit, setEdit] = useState(props.edit);
  const [formFields, setFormFields] = useState({});
  const [answersIdLookup, setAnswersIdLookup] = useState({});

  const { setTitle, showToast, showFooter } = useLayoutContext();

  const handleEditClick = () => {
    setEdit(true);
  };

  const handleSaveClick = () => {
    const nextAnswers = [];

    Object.keys(formFields).forEach((key) => {
      nextAnswers.push({
        ...answersIdLookup[key],
        answerText: formFields[key].value,
      });
    });

    updateQuestionnaireResponse({
      answers: nextAnswers,
    })
      .then(() => {
        setEdit(false);
        showToast(LAYOUT_TOAST_TYPES.success, {
          message: 'Questionnaire repsonse updated',
        });
      })
      .catch(() => {
        showToast(LAYOUT_TOAST_TYPES.error, {
          message:
            'Unable to update questionnaire response, please try again later.',
        });
      });
  };

  useEffect(() => {
    if (!loading && intakeResponse) {
      const intakeResponseName = `${
        intakeResponse.name || 'Questionnaire Submission'
      } - ${intakeResponse.email || intakeResponse.phoneNumber}`;
      setTitle(intakeResponseName);

      const answersLookup = {};

      intakeResponse.answerGroups.forEach((group) => {
        group.answers.forEach((answer) => {
          answersLookup[answer.id] = answer;
        });
      });

      setAnswersIdLookup(answersLookup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (!loading && intakeResponse) {
      showFooter(LAYOUT_FOOTER_TYPES.questionnaireResponsePage, {
        onEdit: handleEditClick,
        onSave: handleSaveClick,
        edit,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit, loading]);

  const handleIntakeFormChange = (formValues) => {
    setFormFields(formValues.fields);
  };

  if (loading) {
    return <LoadingOverlay />;
  }

  return (
    <PageContainer loading={loading} loadingTitle="Loading">
      {/* <h1>{intakeResponse.name}</h1>
      <h2>{intakeResponse.description}</h2> */}
      {intakeResponse.answerGroups.map((answerGroup) => (
        <QuestionResponseGroup
          key={`question-group-${answerGroup.id}`}
          name={answerGroup.name}
          edit={edit}
          answers={answerGroup.answers}
          onChange={handleIntakeFormChange}
        />
      ))}
    </PageContainer>
  );
};

QuestionnaireResponsePageContainer.propTypes = {
  id: PropTypes.string.isRequired,
  edit: PropTypes.bool,
};

QuestionnaireResponsePageContainer.defaultProps = {
  edit: false,
};

export default QuestionnaireResponsePageContainer;
