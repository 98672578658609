import React from 'react';

/* Components */
import ModalOverlay from '~/src/components/Modal/Overlay';
import ConfirmationModal from '~/src/components/Modal/Confirmation';
import ErrorModal from '~/src/components/Modal/Error';
import WarningModal from '~/src/components/Modal/Warning';
import PasswordChangeModal from '~/src/components/Modal/PasswordChangeModal';
import FormFieldModal from '~/src/components/Modal/FormFieldModal';
import TwoFactorAuthModal from '~/src/components/Modal/TwoFactorAuth';
import DownloadDocumentModal from '~/src/components/Modal/DownloadDocumentModal';
import CreateSubscriptionModal from '~/src/components/Modal/CreateSubscription';
import ConfigurePlanModal from '~/src/components/Modal/ConfigurePlan';
import SubscriptionUpdateModal from '~/src/components/Modal/SubscriptionUpdate';
import ClioImportConfirmModal from '~/src/components/Modal/ClioImportConfirmModal';
import PublishIntakeFormModal from '~/src/components/Modal/PublishIntakeFormModal';
import ClioDisconnectConfirmModal from '~/src/components/Modal/ClioDisconnectConfirmModal';
import DeleteModal from '~/src/components/Modal/DeleteModal';
import InviteTeamMembersModal from '~/src/components/Modal/InviteTeamMembers';
import TemplateDefaultValuesModal from '~/src/components/Modal/TemplateDefaultValuesModal';

import { useLayoutContext } from '~/src/contexts';

export const LAYOUT_MODAL_TYPES = {
  confirm: 'confirm',
  error: 'error',
  warning: 'warning',
  delete: 'delete',
  passwordChange: 'passwordChange',
  formField: 'formField',
  inviteTeamMembers: 'inviteTeamMembers',
  twoFactorAuth: 'twoFactorAuth',
  downloadDocument: 'documentDocument',
  createSubscription: 'createSubscription',
  configurePlan: 'configurePlan',
  subscriptionUpdate: 'subscriptionUpdate',
  clioImportConfirm: 'clioImportConfirm',
  publishQuestionnaireForm: 'publishQuestionnaireForm',
  clioDisconnect: 'clioDisconnect',
  templateDefaultValuesModal: 'templateDefaultValuesModal',
} as const;

export type ModalType =
  typeof LAYOUT_MODAL_TYPES[keyof typeof LAYOUT_MODAL_TYPES];

export const MODALS: Record<ModalType, React.ElementType> = {
  [LAYOUT_MODAL_TYPES.subscriptionUpdate]: SubscriptionUpdateModal,
  [LAYOUT_MODAL_TYPES.createSubscription]: CreateSubscriptionModal,
  [LAYOUT_MODAL_TYPES.configurePlan]: ConfigurePlanModal,
  [LAYOUT_MODAL_TYPES.confirm]: ConfirmationModal,
  [LAYOUT_MODAL_TYPES.error]: ErrorModal,
  [LAYOUT_MODAL_TYPES.warning]: WarningModal,
  [LAYOUT_MODAL_TYPES.delete]: DeleteModal,
  [LAYOUT_MODAL_TYPES.passwordChange]: PasswordChangeModal,
  [LAYOUT_MODAL_TYPES.formField]: FormFieldModal,
  [LAYOUT_MODAL_TYPES.inviteTeamMembers]: InviteTeamMembersModal,
  [LAYOUT_MODAL_TYPES.twoFactorAuth]: TwoFactorAuthModal,
  [LAYOUT_MODAL_TYPES.downloadDocument]: DownloadDocumentModal,
  [LAYOUT_MODAL_TYPES.clioImportConfirm]: ClioImportConfirmModal,
  [LAYOUT_MODAL_TYPES.publishQuestionnaireForm]: PublishIntakeFormModal,
  [LAYOUT_MODAL_TYPES.clioDisconnect]: ClioDisconnectConfirmModal,
  [LAYOUT_MODAL_TYPES.templateDefaultValuesModal]: TemplateDefaultValuesModal,
};

const Modals = () => {
  const { modalActive, hideModal, modalProps, modalType } = useLayoutContext();

  const ModalChild = modalType && MODALS[modalType] ? MODALS[modalType] : null;

  const handleCancel = () => {
    if (modalProps.onCancel) {
      modalProps.onCancel();
    }

    hideModal();
  };

  if (modalType === 'delete') {
    return (
      <DeleteModal
        title={'placeholder title'}
        message={undefined}
        open={modalActive}
        onCancel={hideModal}
        onConfirm={hideModal}
        {...modalProps}
      />
    );
  }

  return (
    <ModalOverlay
      show={modalActive}
      onCancel={handleCancel}
      disableClickCatcher={modalProps.disableClickCatcher}
    >
      {ModalChild && (
        <ModalChild
          onCancel={hideModal}
          onConfirm={hideModal}
          {...modalProps}
        />
      )}
    </ModalOverlay>
  );
};

export default Modals;
