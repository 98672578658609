import React, { useEffect, useState } from 'react';
import Page from '~/src/components/PageLayout';
import { useMatterDetail } from '../../hooks/useMatterDetail';
import { useRelatedQuestionnaireSubmissionsByMatterId } from '~/src/entities/questionnairesV2/hooks/useRelatedQuestionnaireSubmissionsByMatterId';
import { useRelatedQuestionnaireSubmissionUnmappedQuestions } from '~/src/entities/questionnairesV2/hooks/useRelatedQuestionnaireSubmissionUnmappedQuestions';

import { MatterDetailsPageContent } from './MatterDetailsPageContent';

export const MatterDetailsPage = ({ matterId, matterTitle }) => {
  const [title, setTitle] = useState(matterTitle || 'Loading Matter...');
  const {
    isError,
    isFetching,
    data: matter,
    refetch,
    remove,
  } = useMatterDetail(matterId, {
    refetchOnWindowFocus: false,
    staleTime: Number.POSITIVE_INFINITY,
    onSuccess: (matter) => setTitle(matter.fullTitle()),
    onError: () => setTitle('Error'),
  });

  const { data: clioQuestionnaireSubmissions } =
    useRelatedQuestionnaireSubmissionsByMatterId(matterId);

  const { data: clioQuestionnaireSubmissionUnmappedQuestions } =
    useRelatedQuestionnaireSubmissionUnmappedQuestions(matterId);

  useEffect(() => remove, [remove]);

  return (
    <Page title={title}>
      <MatterDetailsPageContent
        refetch={refetch}
        isFetching={isFetching}
        isError={isError}
        matter={matter}
        clioQuestionnaireSubmissions={clioQuestionnaireSubmissions}
        clioQuestionnaireSubmissionUnmappedQuestions={
          clioQuestionnaireSubmissionUnmappedQuestions
        }
      />
    </Page>
  );
};
