import { http, getPaginationUrlQuery } from '~/src/services/requests';

function documentService() {
  return {
    updateDocument: async (orgFprint, projectId, properties) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v2/org/${orgFprint}/document/${projectId}'`,
          method: 'PUT',
          body: properties,
        },
        parse,
      );
    },
    updateDocumentMetadata: async (
      orgFprint,
      projectId,
      documentId,
      properties,
    ) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/client/0/project/${projectId}/document/${documentId}/`,
          method: 'PUT',
          body: properties,
        },
        parse,
      );
    },
    deleteDocument: async (orgFprint, projectId, documentId) => {
      const parse = false;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/client/0/project/${projectId}/document/${documentId}/`,
          method: 'DELETE',
        },
        parse,
      );
    },
    fetch: async (orgFprint, { page, search, sortBy, order, limit }) => {
      const parse = true;

      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v2/org/${orgFprint}/document/${getPaginationUrlQuery(
            { page, search, sortBy, order, limit },
          )}`,
          method: 'GET',
        },
        parse,
      );
    },
    getDocument: async (orgFprint, documentId) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/document/${documentId}/`,
          method: 'GET',
        },
        parse,
      );
    },
  };
}

export default documentService();
