import React from 'react';

import Button from '~/src/components/Button';
import { Container, Body, Header, Footer } from '~/src/components/SlideIn';

// import placeholderSrc from "static/doc-preview-placeholder.png";

import { css } from 'aphrodite';

import { default as slideInStyles } from '~/src/components/SlideIn/styles';
import styles from './styles';

const EmptyImageSet = ({ message }) => (
  <p className={css(styles.imgSetEmptyText)}>
    {message || `There are no preview pages for this document.`}
  </p>
);

const DocumentPreviewSlideIn = ({
  title,
  images,
  message,
  hideAddToSelection,
  hideDraftDocument,
  disableDraftDocument,
  onCancel,
  onDraftNewDocument,
  onAddToSelection,
}) => (
  <Container>
    <Header title={title} />
    <Body>
      <div className={css(styles.docContainer)}>
        {images &&
          images.length > 0 &&
          images.map((image) => (
            <img key={image} className={css(styles.docImage)} src={image} />
          ))}
        {(!images || images.length === 0) && (
          <EmptyImageSet message={message} />
        )}
      </div>
    </Body>
    <Footer alignRight>
      <Button cssStyle={slideInStyles.footerButton} onClick={onCancel}>
        Back
      </Button>

      {!hideAddToSelection && (
        <Button
          cssStyle={slideInStyles.footerButton}
          onClick={onAddToSelection}
          primary
        >
          Add to Selection
        </Button>
      )}

      {!hideDraftDocument && (
        <Button
          cssStyle={slideInStyles.footerButton}
          onClick={onDraftNewDocument}
          primary
          disabled={disableDraftDocument}
        >
          Draft new document
        </Button>
      )}
    </Footer>
  </Container>
);

DocumentPreviewSlideIn.defaultProps = {
  onCancel: () => {},
  onDraftNewDocument: () => {},
  disableDraftDocument: false,
};

export default DocumentPreviewSlideIn;
