const analyticsStub = {
  track: () => {
    // stub
  },
  identify: () => {
    // stub
  },
  page: (..._args) => {
    // stub
  },
};

const analytics = window.analytics || analyticsStub;

export const delegateMSTEvent = (call, rootStore, handlers) => {
  const parentName = call.parentEvent && call.parentEvent.name;

  let actionEvent = false;
  let flowReturnEvent = false;

  if (handlers.events && handlers.events[call.name]) {
    actionEvent = handlers.events[call.name];
  }

  if (handlers.flowReturnEvents && handlers.flowReturnEvents[call.name]) {
    flowReturnEvent = handlers.flowReturnEvents[call.name];
  } else if (
    handlers.flowReturnEvents &&
    handlers.flowReturnEvents[parentName]
  ) {
    flowReturnEvent = handlers.flowReturnEvents[parentName];
  }

  if (call.type === 'action' && actionEvent) {
    actionEvent(call.args, rootStore);
  } else if (call.type === 'flow_return' && flowReturnEvent) {
    flowReturnEvent(call.args, rootStore);
  }
};

export default analytics;
