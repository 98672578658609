import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

export default StyleSheet.create({
  docSetContainer: {
    padding: theme.unit * 2,
    margin: '0 auto',
  },
  docImage: {
    display: 'block',
    margin: '0 auto',
    maxWidth: '800px',
    boxShadow:
      'inset 1px 1px 0 rgba(0, 0, 0, .1), inset 0 -1px 0 rgba(0, 0, 0, .07)',
    marginBottom: theme.unit * 2,
    ':last-of-type': {
      marginBottom: theme.unit,
    },
  },
  imgSetTitle: {
    fontSize: theme.fonts.size.regular,
    textAlign: 'center',
    marginBottom: `${theme.unit * 4}px`,
  },
  imgSetContainer: {
    width: '100%',
    margin: '0 auto',
    marginBottom: `${theme.unit * 6}px`,
    ':first-of-type': {
      marginTop: `${theme.unit * 2}px`,
    },
    ':last-of-type': {
      marginBottom: `${theme.unit * 4}px`,
    },
  },
  imgSetDivider: {
    height: '1px',
    width: '65%',
    margin: '0 auto',
    background: theme.colors.smoke,
    marginBottom: `${theme.unit * 2}px`,
    marginTop: `${theme.unit * 4}px`,
  },
  imgSetEmptyText: {
    lineHeight: '1.5em',
    fontSize: theme.fonts.size.small,
    textAlign: 'center',
    marginBottom: `${theme.unit * 2}px`,
  },
});
