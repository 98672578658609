import {
  Subscription,
  SubscriptionV2,
  BillingCycleType,
  SubscriptionV2Plans,
} from './types';
import {
  WORD_DOCUMENT_AUTOMATION_MONTHLY_PRICE,
  WORD_DOCUMENT_AUTOMATION_YEARLY_PRICE,
  CONTENT_PACKAGE,
  COURT_FORMS_MONTHLY_PRICE,
  COURT_FORMS_YEARLY_PRICE,
  COURT_FORMS,
  SEATS_MONTHLY_PRICE,
  SEATS_YEARLY_PRICE,
  SEAT_PRODUCT_ID,
  NYSBA_SURROGATE_ID,
  WORD_PRODUCT_ID,
} from './constants';

/*
In stripe, we name our products by convention like {PRODUCT_ID}_MONTHLY or {PRODUCT_ID}_YEARLY
In this application, we maintain the PRODUCT_ID constants and can use this function to get 
the conventionalized product name in stripe
*/

export const getRawProductID = (plan: string) =>
  plan.replace(/_(MONTHLY|YEARLY)/, '');

export const getProductID = (billingCycle: BillingCycleType, plan: string) =>
  `${plan}_${billingCycle === 'annual' ? 'YEARLY' : 'MONTHLY'}`;

export const billingCycleToDisplayString = (billingCycle: BillingCycleType) =>
  billingCycle === 'annual' ? 'year' : 'month';

export const getCourtFormLabelFromPlanId = (plan_id: string) =>
  COURT_FORMS.find(({ id }) => plan_id.startsWith(id))?.name || 'Court Form';

export const getPlanPrice = (price: number, billingCycle: BillingCycleType) =>
  `$${price}/${billingCycle === 'annual' ? 'year' : 'month'}`;

export const getWordDocumentAutomationProductPrice = (
  billingCycle: BillingCycleType,
) =>
  billingCycle === 'annual'
    ? WORD_DOCUMENT_AUTOMATION_YEARLY_PRICE
    : WORD_DOCUMENT_AUTOMATION_MONTHLY_PRICE;

export const getNYSBASurrogatePrice = () => CONTENT_PACKAGE[0]!.price;

export const getCourtFormProductPrice = (billingCycle: BillingCycleType) =>
  billingCycle === 'annual'
    ? COURT_FORMS_YEARLY_PRICE
    : COURT_FORMS_MONTHLY_PRICE;

export const getSeatsProductPrice = (billingCycle: BillingCycleType) =>
  billingCycle === 'annual' ? SEATS_YEARLY_PRICE : SEATS_MONTHLY_PRICE;

export const getSeatsPlanFromSubscription = (subscription: SubscriptionV2) =>
  subscription.plan.find(({ fields }) =>
    fields.plan_id.startsWith(SEAT_PRODUCT_ID),
  )!;

export const getSeatsFromSubscription = (subscription: SubscriptionV2) =>
  subscription.quantity;

export const getBillingCycleFromSubscription = (subscription: SubscriptionV2) =>
  getSeatsPlanFromSubscription(subscription).fields.interval === 'month'
    ? 'monthly'
    : 'annual';

export const getNysbaPlanFromSubscription = (subscription: SubscriptionV2) =>
  subscription.plan.find(({ fields }) =>
    fields.plan_id.startsWith(NYSBA_SURROGATE_ID),
  );

export const getWordPlanFromSubscription = (subscription: SubscriptionV2) =>
  subscription.plan.find(({ fields }) =>
    fields.plan_id.startsWith(WORD_PRODUCT_ID),
  );

export const getCourtFormPlansFromSubscription = (
  subscription: SubscriptionV2,
) =>
  COURT_FORMS.filter(
    ({ id }) =>
      !!subscription.plan.find(({ fields }) => fields.plan_id.startsWith(id)),
  ).map(({ id }) => id);

export const isSubscriptionCanceled = ({
  canceled_at,
  status,
}: Subscription | SubscriptionV2) => !!canceled_at || status === 'canceled';

export const getPlanDifference = (
  existing: SubscriptionV2Plans,
  next: SubscriptionV2Plans,
) => {
  if (next.seats !== existing.seats) {
    return {
      add_plans: [],
      remove_plans: [],
      seat: next.seats,
      duration: next.billingCycle,
      interval_change: false,
    };
  }

  const interval_change = next.billingCycle !== existing.billingCycle;

  if (interval_change) {
    return {
      add_plans: [],
      remove_plans: [],
      seat: next.seats,
      duration: next.billingCycle,
      interval_change,
    };
  }

  const add_plans = [];
  const remove_plans = [];

  if (existing.contentPackage !== next.contentPackage) {
    if (next.contentPackage) {
      add_plans.push(getProductID(next.billingCycle, NYSBA_SURROGATE_ID));
    } else {
      remove_plans.push(
        getProductID(existing.billingCycle, NYSBA_SURROGATE_ID),
      );
    }
  }

  if (existing.word !== next.word) {
    if (next.word) {
      add_plans.push(getProductID(next.billingCycle, WORD_PRODUCT_ID));
    } else {
      remove_plans.push(getProductID(existing.billingCycle, WORD_PRODUCT_ID));
    }
  }

  if (existing.courtForms !== next.courtForms) {
    next.courtForms.forEach((id) => {
      if (existing.courtForms.includes(id)) return;
      add_plans.push(getProductID(next.billingCycle, id));
    });

    existing.courtForms.forEach((id) => {
      if (next.courtForms.includes(id)) return;
      remove_plans.push(getProductID(existing.billingCycle, id));
    });
  }

  return {
    add_plans,
    remove_plans,
    seat: next.seats,
    duration: next.billingCycle,
  };
};
