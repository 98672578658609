import { useInfiniteQuery } from 'react-query';
import { QueryParams } from '../types';
import { questionnaireQueryArgs } from '../queryArgs';
import { getQuestionnaireListUrl } from '../urls';
import {
  getClioQuestionnaireAuthToken,
  questionnaireAuthorizationHeaders,
} from '../utils';
import { APIError } from '~/src/utils/error';

export const useClioQuestionnaires = (
  orgFprint: string,
  parameters: Partial<QueryParams> = { limit: 15 },
) =>
  useInfiniteQuery(
    questionnaireQueryArgs.list(parameters),
    async ({ pageParam }) => {
      const params = { ...parameters, offset: pageParam || 0 };
      const token = await getClioQuestionnaireAuthToken(orgFprint);
      const response = await fetch(getQuestionnaireListUrl(params), {
        headers: questionnaireAuthorizationHeaders(token),
      });

      if (!response.ok) {
        throw new APIError(
          'Failed to retrieve list of questionnaires',
          response,
        );
      }

      const questionnaires = await response.json();

      return { questionnaires, params };
    },
    {
      getNextPageParam: ({ questionnaires, params }) =>
        questionnaires.length === params.limit
          ? params.offset + params.limit
          : undefined,
    },
  );
