import { useWebViewerContext } from '~/src/components/Webviewer';

const useWebViewerIframe = () => {
  const { instance } = useWebViewerContext();
  if (instance?.UI.iframeWindow.frameElement) {
    return instance.UI.iframeWindow.frameElement as HTMLIFrameElement;
  }
  return null;
};

export default useWebViewerIframe;
