import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import PropTypes from 'prop-types';

const DraggableListItem = ({
  id,
  index,
  item,
  moveItem,
  renderItem,
  enableDragDrop,
  draggableType,
  onDragStart,
  onDragEnd,
}) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: draggableType,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      // console.log("make the move now")
      moveItem(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: draggableType, id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    begin: () => onDragStart(),
    end: () => onDragEnd(),
  });
  const opacity = isDragging ? 0 : 1;
  if (enableDragDrop) {
    drag(drop(ref));
  }
  return (
    <div ref={ref} style={{ opacity }}>
      {renderItem(item, index)}
    </div>
  );
};

DraggableListItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  index: PropTypes.number,
  item: PropTypes.object,
  moveItem: PropTypes.func,
  renderItem: PropTypes.func,
  enableDragDrop: PropTypes.bool,
  draggableType: PropTypes.string,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
};

DraggableListItem.defaultProps = {
  enableDragDrop: true,
  onDragStart: () => null,
  onDragEnd: () => null,
};

export default DraggableListItem;
