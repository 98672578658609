import * as React from 'react';
import Button from '~/src/components/Button';

import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import { LAYOUT_MODAL_TYPES } from '~/src/components/PageLayout/Modals';
import { VALIDATION_TYPES } from '~/src/utils/validation';
import { useLayoutContext } from '~/src/contexts';
import { useStore } from '~/src/hooks/useMst';

export const SaveAsSetButton = ({ items }) => {
  const { templateSets } = useStore();
  const { showToast, showModal, hideModal } = useLayoutContext();

  const handleSaveAsNewSet = () => {
    const handleSaveAsNewSetConfirm = async (form) => {
      const payload = {
        title: form.fields.name.value,
        templateIds: items.map((item) =>
          parseInt(
            item.templateId
              ? item.templateId // for addDocumentContainer special case
              : item.id.toString().split('+')[0],
          ),
        ),
        templatesWithValues: items.map((item) => ({
          templateId: parseInt(
            item.templateId
              ? item.templateId // for addDocumentContainer special case
              : item.id.toString().split('+')[0],
          ),
          templateDefaultValuesId: item.defaultValueId,
        })),
      };
      try {
        const res = await templateSets.create(payload);
        showToast(LAYOUT_TOAST_TYPES.success, {
          message: `Template set ${res.title} saved successfully!`,
        });
      } catch (error) {
        showToast(LAYOUT_TOAST_TYPES.error, {
          message:
            error.message ||
            'Unable to save template set, please try again later.',
        });
      }
      hideModal();
    };

    showModal(LAYOUT_MODAL_TYPES.formField, {
      title: 'Save as new set',
      primaryActionTitle: 'Save',
      primaryActionInProcessTitle: 'Saving...',
      fields: [
        {
          label: 'Name',
          id: 'name',
          type: 'text',
          validation: VALIDATION_TYPES.present,
        },
      ],
      onConfirm: handleSaveAsNewSetConfirm,
    });
  };

  return (
    <Button onClick={handleSaveAsNewSet} discrete disabled={items.length === 0}>
      Save as new set
    </Button>
  );
};
