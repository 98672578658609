import { useState, useEffect } from 'react';

const useSuspendLoading = (loading) => {
  const [suspend, setSuspend] = useState(true);

  useEffect(() => {
    const currentTimeout = setTimeout(() => {
      setSuspend(false);
    }, 500);

    return () => {
      if (currentTimeout) {
        clearTimeout(currentTimeout);
      }
    };
  }, []);

  useEffect(() => {
    if (!loading) {
      setSuspend(false);
    }
  }, [loading]);

  return suspend;
};

export default useSuspendLoading;
