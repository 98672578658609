import React from 'react';
import { css } from 'aphrodite';
import { CardTitle } from '~/src/components/Card';
import Button from '~/src/components/Button';
import styles from './styles';

export const CreateNewSubscription = ({ onCreateNewSubscription }) => {
  return (
    <>
      <CardTitle>Create new subscription</CardTitle>
      <div className={css(styles.settingsRow)}>
        <Button onClick={onCreateNewSubscription}>Choose plan</Button>
      </div>
    </>
  );
};
