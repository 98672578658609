/* Libraries */
import React from 'react';
import { css } from 'aphrodite';
import cns from 'classnames';
/* Models */
import { Project } from '~/src/models/project';
/* Styles */
import styles from './styles';

type DocumentContainerProps = {
  documents: Project.Document[];
  document: Project.Document;
  index: number;
  images: string[];
  key: string;
};

interface ViewDocumentSetProps extends DocumentContainerProps {
  documentSet: {
    documents: Project.Document[];
  };
  showHighlights: boolean;
}

const ImageSet = ({ images }: { images: string[] }) => (
  <>
    {images.map((image: string) => (
      <img key={image} className={css(styles.docImage)} src={image} />
    ))}
  </>
);

const DocumentContainer = ({
  documents,
  document,
  index,
  images,
  key,
}: DocumentContainerProps) => (
  <div
    key={`${document.title}-${index}-${key}`}
    className={css(styles.imgSetContainer)}
  >
    <h1 className={css(styles.imgSetTitle)}>{document.title}</h1>
    {images.length > 0 && <ImageSet images={images} />}
    {images.length === 0 && (
      <p className={css(styles.imgSetEmptyText)}>
        There are no pages in this document.
      </p>
    )}
    {index !== documents.length - 1 && (
      <div className={css(styles.imgSetDivider)} />
    )}
  </div>
);

export const ViewDocumentSet = (props: ViewDocumentSetProps) => {
  const { documentSet, showHighlights } = props;

  const documents =
    documentSet && documentSet.documents ? documentSet.documents : [];

  // with hightlights
  const imagesUnprocessed = (document: Project.Document) =>
    document && document.pages
      ? document.pages.map((page) => page.backgroundUrl)
      : [];

  const imagesProcessed = (document: Project.Document) =>
    document && document.processedPages
      ? document.processedPages.map((page) => page.backgroundUrl)
      : [];

  const documentSetPreviews = (
    documents: Project.Document[],
    key: string,
    processFunction: (document: Project.Document) => string[],
  ) =>
    documents.map((document: Project.Document, index: number) => {
      return (
        <DocumentContainer
          {...props}
          documents={documents}
          document={document}
          index={index}
          images={processFunction(document)}
          key={key}
        />
      );
    });

  /* need to load both versions at once, or we get jump scrolling when
   toggling 'Highlight Changes' occasionally */
  return (
    <div className={css(styles.docSetContainer)}>
      <div className={cns({ hidden: !showHighlights })}>
        {documentSetPreviews(documents, 'unprocessed', imagesUnprocessed)}
      </div>
      <div className={cns({ hidden: showHighlights })}>
        {documentSetPreviews(documents, 'processed', imagesProcessed)}
      </div>
    </div>
  );
};
