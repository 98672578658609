/* Libraries */
import React from 'react';

/* Components */
import ContactSlideInContainer from '~/src/containers/slideIns/ContactSlideIn';
import createContactSlideInContainer from '~/src/containers/slideIns/CreateContactSlideIn';
import AttachContactSlideInContainer from '~/src/containers/slideIns/AttachContactSlideIn';
import DocumentPreviewSlideInContainer from '~/src/containers/slideIns/DocumentPreviewSlideIn';
import ProjectPreviewSlideInContainer from '~/src/containers/slideIns/ProjectPreviewSlideIn';
import QuestionFieldMapSlideInContainer from '~/src/containers/slideIns/QuestionFieldMapSlideInContainer';
import QuestionInstructionalContentSlideIn from '~/src/containers/slideIns/QuestionInstructionalContentSlideIn';
import QuestionnaireQuestionMapField from '~/src/containers/slideIns/QuestionnaireQuestionMapField';

/* Styles */
import SlideInOverlay from './SlideInOverlay';
import { useLayoutContext } from '~/src/contexts';

/* Contexts */
export const LAYOUT_SLIDEIN_TYPES = {
  default: 'DEFAULT',
  contact: 'CONTACT',
  contactCreate: 'CONTACT_CREATE',
  attachContact: 'ATTACH_CONTACT',
  documentPreview: 'DOCUMENT_PREVIEW',
  projectPreview: 'PROJECT_PREVIEW',
  questionFieldMap: 'QUESTION_FIELD_MAP',
  questionInstructionalContent: 'QUESTION_INSTRUCTIONAL_CONTENT',
  questionnaireQuestionMapField: 'QUESTIONNAIRE_QUESTION_FIELD_MAPPING',
} as const;

export type SlideInType =
  typeof LAYOUT_SLIDEIN_TYPES[keyof typeof LAYOUT_SLIDEIN_TYPES];

export const SLIDEINS: Record<SlideInType, React.ElementType> = {
  [LAYOUT_SLIDEIN_TYPES.default]: () => <div />,
  [LAYOUT_SLIDEIN_TYPES.contact]: ContactSlideInContainer,
  [LAYOUT_SLIDEIN_TYPES.contactCreate]: createContactSlideInContainer,
  [LAYOUT_SLIDEIN_TYPES.attachContact]: AttachContactSlideInContainer,
  [LAYOUT_SLIDEIN_TYPES.documentPreview]: DocumentPreviewSlideInContainer,
  [LAYOUT_SLIDEIN_TYPES.projectPreview]: ProjectPreviewSlideInContainer,
  [LAYOUT_SLIDEIN_TYPES.questionFieldMap]: QuestionFieldMapSlideInContainer,
  [LAYOUT_SLIDEIN_TYPES.questionInstructionalContent]:
    QuestionInstructionalContentSlideIn,
  [LAYOUT_SLIDEIN_TYPES.questionnaireQuestionMapField]:
    QuestionnaireQuestionMapField,
};

const SlideIns = () => {
  const {
    slideInActive: active,
    slideIn: type,
    slideInProps,
    hideSlideIn: hide,
  } = useLayoutContext();

  // NOTE: A bit hacky, we should include a seperate state for slideInProps
  const { largeSlideIn: large, ...props } = slideInProps;

  const SlideInChild = type && SLIDEINS[type] ? SLIDEINS[type] : null;

  return (
    <SlideInOverlay show={active} large={large} onClose={hide}>
      {SlideInChild && <SlideInChild hideSlideIn={hide} {...props} />}
    </SlideInOverlay>
  );
};

export default SlideIns;
