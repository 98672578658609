import { Button } from '@mui/material';
import React from 'react';
import { useFormState } from 'react-hook-form';

type SubmitButtonProps = {
  label: string;
};

export const SubmitButton = ({ label }: SubmitButtonProps) => {
  const { isSubmitting } = useFormState();

  return (
    <Button
      type="submit"
      color={'primary'}
      size={'large'}
      variant={'contained'}
      disabled={isSubmitting}
    >
      {isSubmitting ? 'Loading' : label}
    </Button>
  );
};
