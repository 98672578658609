/* Libraries */
import React from 'react';
import { css } from 'aphrodite';

/* Components */
import Label from './Label';

/* Styles */
import styles from './styles';

const StaticLink = ({ label, value, hint, count, onClick, disabled, href }) => (
  <Label label={label} hint={hint} count={count} onClick={!disabled && onClick}>
    <a
      role="button"
      tabIndex={0}
      href={href}
      onKeyPress={!disabled && onClick}
      className={css(styles.staticLink, disabled && styles.staticLinkDisabled)}
    >
      {value}
    </a>
  </Label>
);

export default StaticLink;
