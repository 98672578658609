/* Services */
import documentSetsService from '~/src/services/api/documentSets';

/* Utilities */
import { mapProject } from '~/src/utils/dataTransformers';

function documents() {
  const fetch = async ({ page, search, sortBy, order, limit, orgFprint }) => {
    const documents = await documentSetsService.fetch(orgFprint, {
      page,
      search,
      sortBy,
      order,
      limit,
    });

    // Documents are essentially just projects with a single document.
    return documents.map((project) => mapProject(project));
  };

  return {
    fetch,
  };
}

export default documents();
