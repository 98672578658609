/* Libs */
import React from 'react';

/* Components */
import Button from '~/src/components/Button';

const ActionFooter = (props) => {
  const {
    actionLabel,
    cancelLabel,
    actionDisabled,
    cancelDisabled,
    onAction,
    onCancel,
  } = props;

  return (
    <footer
      className={
        'flex flex-grow bg-white border-solid border-0 border-gray-300 justify-between'
      }
    >
      <Button secondary onClick={onCancel} disabled={cancelDisabled}>
        {cancelLabel}
      </Button>
      <Button primary onClick={onAction} disabled={actionDisabled}>
        {actionLabel}
      </Button>
    </footer>
  );
};

ActionFooter.defaultProps = {
  actionLabel: 'Confirm',
  cancelLabel: 'Cancel',
  actionDisabled: false,
  cancelDisabled: false,
  onAction: () => {},
  onCancel: () => {},
};

export default ActionFooter;
