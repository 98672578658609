import { Box, Checkbox, Paper } from '@mui/material';
import * as React from 'react';
import { CardListItemTitle, CardListItemTitleProps } from './CardListItemTitle';

type RenderMeta = (hovered: boolean) => React.ReactNode;

export type CardListItemProps = {
  title: CardListItemTitleProps;
  renderMeta?: RenderMeta;
  children?: React.ReactNode;
  checked?: boolean;
  icon?: React.ReactNode;
  onClick?: () => void;
  onChangeChecked?: (checked: boolean) => void;
};

export const CardListItem = ({
  title,
  renderMeta,
  children,
  checked = false,
  onClick,
  icon,
  onChangeChecked,
}: CardListItemProps) => {
  const [hovered, setHovered] = React.useState(false);

  return (
    <Paper
      sx={() => ({
        '&:hover': { cursor: 'pointer' },
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      })}
      elevation={hovered ? 3 : 1}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Box
        onClick={onClick}
        display={'flex'}
        minHeight={64}
        padding={'6px 24px'}
      >
        {onChangeChecked ? (
          <Box flex={0} mr={1}>
            <Checkbox
              sx={(theme) => {
                if (checked) return {};
                return {
                  color: hovered
                    ? theme.palette.grey[900]
                    : theme.palette.grey[300],
                };
              }}
              checked={checked}
              onClick={(e) => e.stopPropagation()}
              onChange={(_, isChecked) => onChangeChecked(isChecked)}
            />
          </Box>
        ) : null}
        {icon ? (
          <Box flex={0} mr={1}>
            {icon}
          </Box>
        ) : null}
        <Box flex={'1 0 50%'}>
          <CardListItemTitle {...title} />
          <Box>{children}</Box>
        </Box>
        <Box
          position={'absolute'}
          top={0}
          right={'24px'}
          height={'64px'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
        >
          <div>{renderMeta?.(hovered)}</div>
        </Box>
      </Box>
    </Paper>
  );
};
