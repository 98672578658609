import { useEffect, useState } from 'react';

export const useDebouncedValue = <T>(value: T, delayInMs: number) => {
  const [state, setState] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => setState(value), delayInMs);
    return () => clearTimeout(timer);
  }, [value, delayInMs]);

  return state;
};
