import React from 'react';

import Button from '~/src/components/Button';
import { Container, Body, Header, Footer } from '~/src/components/SlideIn';

import { css } from 'aphrodite';

import { default as slideInStyles } from '~/src/components/SlideIn/styles';
import styles from './styles';

const ImageSetImages = ({ images }) => (
  <>
    {images.map((image) => (
      <img key={image} className={css(styles.docImage)} src={image} />
    ))}
  </>
);

const EmptyImageSet = ({ message }) => (
  <p className={css(styles.imgSetEmptyText)}>
    {message || `There are no preview pages in this document.`}
  </p>
);

const ImageSet = ({ imageSet, numImageSets, i }) => (
  <div className={css(styles.imgSetContainer)}>
    <h1 className={css(styles.imgSetTitle)}>{imageSet.title}</h1>
    {imageSet.list.length > 0 && <ImageSetImages images={imageSet.list} />}
    {imageSet.list.length === 0 && <EmptyImageSet />}
    {i !== numImageSets - 1 && <div className={css(styles.imgSetDivider)} />}
  </div>
);

const DocumentSetPreviewSlideIn = ({
  title,
  imageSets,
  disableDraftDocSet,
  onCancel,
  onDraftNewDocSet,
}) => (
  <Container>
    <Header title={title} />
    <Body>
      <div className={css(styles.docSetContainer)}>
        {imageSets.map((imageSet, i) => (
          <ImageSet
            key={`${imageSet.title}-${i}`}
            imageSet={imageSet}
            numImageSets={imageSets.length}
            i={i}
          />
        ))}
      </div>
    </Body>
    <Footer alignRight>
      <Button cssStyle={slideInStyles.footerButton} onClick={onCancel}>
        Cancel
      </Button>
      <Button
        cssStyle={slideInStyles.footerButton}
        onClick={onDraftNewDocSet}
        disabled={disableDraftDocSet}
        primary
      >
        Draft new document set
      </Button>
    </Footer>
  </Container>
);

DocumentSetPreviewSlideIn.defaultProps = {
  onCancel: () => {},
  onDraftNewDocSet: () => {},
  disableDraftDocSet: false,
};

export default DocumentSetPreviewSlideIn;
