import React, { useEffect, useState, useRef } from 'react';
import { useObserver } from 'mobx-react';
/* Hooks */
import useFetchProject from '~/src/hooks/useFetchProject';
import useMst from '~/src/hooks/useMst';
import useTemplatesActions from '~/src/hooks/useTemplatesActions';
import usePdfCourtFormsActions from '~/src/hooks/usePdfCourtFormsActions';
import useTemplateSetsActions from '~/src/hooks/useTemplateSetsActions';
import useTemplateDefaultValuesListActions from '~/src/hooks/useTemplateDefaultValuesListActions';

/* Component */

import { TemplateSetsTableWrapper } from '~/src/components/TemplateSets';
import CourtFormsWrapper from '~/src/components/CourtForms/CourtFormWrapper';
import { TemplateDefaultValuesListWrapper } from '~/src/components/TemplateDefaultValuesList';
import { TemplatesWrapper } from '~/src/components/Templates';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';

import analyticsService from '~/src/services/analytics';
import { history } from '../../utils/history';
import { useLayoutContext } from '../../contexts';
import Tabs from '../../components/PageLayout/Tabs';
import Page from '../../components/PageLayout';
import AddDocumentFooter from './AddDocumentFooter';
import { SelectionSidebarHeader } from '../../components/SelectionSidebar';
import { TOTAL_DOCUMENT_LIMIT } from '~/src/utils/constants';

interface AddDocumentContainerProps {
  projectId: number;
}

const AddDocumentContainer = ({ projectId }: AddDocumentContainerProps) => {
  const {
    sidebarItems,
    totalNumber,
    addDocumentToSelectionSidebar,
    addTemplateSetToSelectionSidebar,
    addCourtFormTemplateToSelectionSidebar,
    addFormTemplateToSelectionSidebar,
    addWordTemplateToSelectionSidebar,
    sidebarItemTotalNumber,
    containedInSidebar,
    updateProjectDocuments,
  } = useMst((store) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useObserver(() => ({
      projects: store.projects,
      sidebarItems: store.sidebarItems,
      totalNumber: store.sidebarItems.items.length,
      addDocumentToSelectionSidebar:
        store.documents.addDocumentToSelectionSidebar,
      addTemplateSetToSelectionSidebar:
        store.templateSets.addTemplatesToSeletionSidebar,
      addCourtFormTemplateToSelectionSidebar:
        store.pdfCourtForms.addTemplateToSeletionSidebar,
      addFormTemplateToSelectionSidebar:
        store.templateDefaultValues.addFormTemplateToSelectionSidebar,
      addWordTemplateToSelectionSidebar:
        store.templates.addTemplateToSeletionSidebar,
      sidebarItemTotalNumber: store.sidebarItems.items.length,
      containedInSidebar: store.sidebarItems.containedInSidebar,
      updateProjectDocuments: store.projects.updateProjectDocuments,
    })),
  );

  const { showToast } = useLayoutContext();

  const [active, setActive] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { project, loading } = useFetchProject(
    projectId,
    undefined,
    false,
    true,
  );

  const initialDocData = useRef<number[]>([]);

  useEffect(() => {
    if (!loading && project) {
      initialDocData.current = project.documents
        .slice()
        .map((document: any) => document.id);

      project.documents.forEach((document: any) => {
        addDocumentToSelectionSidebar(document);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const onConfirmClick = async () => {
    setIsLoading(true);
    const payload = sidebarItems?.items.slice().map((item: any) => {
      return {
        id: item.templateId ? parseInt(item.templateId) : parseInt(item.id), // item.templateId is for project.document's template's id, item.id for all new added template's id
        default_values_id: item.defaultValueId ? item.defaultValueId : null,
      };
    });
    const success_await = await updateProjectDocuments(projectId, payload);
    if (success_await) {
      const documentsAddedCount =
        success_await.result.added_document_ids.length;
      if (documentsAddedCount > 0) {
        showToast(LAYOUT_TOAST_TYPES.success, {
          message: `${documentsAddedCount} Document(s) added`,
        });
      }
      setIsLoading(false);

      analyticsService.track('Add Documents - Confirmed');
      history.replace(`/populate/${projectId}`);
    } else {
      showToast(LAYOUT_TOAST_TYPES.error, {
        message: `Couldn't add documents. Please try again.`,
      });
      setIsLoading(false);
    }
  };

  const onBack = () => {
    analyticsService.track('Add Documents - Canceled');
    history.replace(`/drafting/${projectId}`);
  };

  const {
    previewTemplate: handlePreviewWorkTemplate,
    renameTemplate: handleRenameWordTemplate,
    deleteTemplate: handleDeleteWorkTemplate,
  } = useTemplatesActions();
  const { previewTemplate: handlePreviewCourtFormTemplate } =
    usePdfCourtFormsActions();
  const {
    viewTemplate: handleViewTemplate,
    removeTemplateFromSet: handleRemoveTemplateFromSet,
    rename: handleRenameTemplateSet,
    delete: handleDeleteTemplateSet,
  } = useTemplateSetsActions();

  const { rename, delete: handleDelete } =
    useTemplateDefaultValuesListActions();

  const hasDocumentSelectionChanged = () => {
    const sidebarItemsSliced = sidebarItems?.items.slice();

    if (sidebarItemsSliced.length === initialDocData.current.length) {
      return !sidebarItemsSliced.every((item: any) =>
        initialDocData.current.includes(parseInt(item.id)),
      );
    }
    return true;
  };

  const tabsProps = {
    active,
    setActive,
    tabsMetadata: [
      {
        headerText: 'Sets',
        component: (
          <TemplateSetsTableWrapper
            onAddTemplatesToSeletionSidebar={(templateSet) =>
              addTemplateSetToSelectionSidebar(templateSet)
            }
            rowActions={{
              rename: handleRenameTemplateSet,
              delete: handleDeleteTemplateSet,
            }}
            childRowActions={{
              view: handleViewTemplate,
              remove: ({ template, templateSetId }) =>
                handleRemoveTemplateFromSet({ template, templateSetId }),
            }}
          />
        ),
      },
      {
        headerText: 'Court forms',
        requiredFeatureAccess: 'isUnifiedPlatform',
        component: (
          <CourtFormsWrapper
            actionType="button"
            disableAddButton={sidebarItemTotalNumber >= TOTAL_DOCUMENT_LIMIT}
            handleAddTemplateToSeletionSidebar={
              addCourtFormTemplateToSelectionSidebar
            }
            handlePreviewTemplate={handlePreviewCourtFormTemplate}
            containedInSidebar={containedInSidebar}
          />
        ),
      },
      {
        headerText: 'Form templates',
        component: (
          <TemplateDefaultValuesListWrapper
            addFormTemplateToSelectionSidebar={
              addFormTemplateToSelectionSidebar
            }
            rowActions={{
              delete: handleDelete,
              rename,
            }}
            containedInSidebar={containedInSidebar}
            sidebarItemTotalNumber={sidebarItemTotalNumber}
            sidebarItemTotalNumberLimit={TOTAL_DOCUMENT_LIMIT}
          />
        ),
      },
      {
        headerText: 'Word templates',
        component: (
          <TemplatesWrapper
            addTemplateToSeletionSidebar={addWordTemplateToSelectionSidebar}
            rowActions={{
              rename: handleRenameWordTemplate,
              preview: handlePreviewWorkTemplate,
              delete: handleDeleteWorkTemplate,
            }}
            hideDraftButton={true}
          />
        ),
      },
    ],
  };

  const isConfirmDisabled =
    loading || totalNumber === 0 || !hasDocumentSelectionChanged() || isLoading;

  return (
    <Page
      showNavigation={false}
      title={'Add Documents'}
      headerProps={{
        showCloseButton: true,
        showAvatar: false,
        tabs: <Tabs {...tabsProps} />,
        onClose: onBack,
      }}
      showSelectionSidebar
      selectionSidebarProps={{
        isDrafting: true,
        renderHeader: (items: any[]) => (
          <SelectionSidebarHeader title={'Documents'} count={items.length} />
        ),
      }}
      footer={
        <AddDocumentFooter
          isLoading={isLoading}
          confirmDisabled={isConfirmDisabled}
          onBackClick={onBack}
          onConfirmClick={onConfirmClick}
        />
      }
    >
      {tabsProps.tabsMetadata[active]?.component}
    </Page>
  );
};

export default AddDocumentContainer;
