import { InputType } from '~/src/models/inputType';
import { WhoSigns } from '~/src/models/signerSelection';

export namespace SignaturePackage {
  export interface SignaturePackage {
    id: number;
    lawyawProjectId: number;
    fprint: string;
    createdAt: string;
    recipients: Array<SignaturePackage.Recipient>;
    lawyawDocumentIds: Array<number>;
    whoSigns: WhoSigns;
    freeze: boolean;
    title: string;
    customOrder: boolean | null;
    currentStep: number | null;
    emailSubject: string | null;
    emailBody: string | null;
    emailCc: unknown;
    lawyawDocuments: Array<SignaturePackage.Document>;
    isActive: boolean;
    isUnifiedEsign: boolean;
    lawyawClientId: number;
    packageStatus:
      | 'Closed'
      | 'Declined'
      | 'Out for signature'
      | 'Pending'
      | 'Waiting for sender'
      | 'Drafting'
      | 'Expired'
      | 'Unknown'
      | 'Signed'
      | 'Pending'
      | 'Canceled'
      | 'Cancelled';
    auditTrail?: SignaturePackage.AuditTrailItem[];
    generatedCombinedPdf: string | null;
  }

  export interface Document {
    id: number;
    title: string;
    subtitle: string;
    templateId: number;
    sortOrder: number;
    originalFileUrl?: string;
    originalPDfBlob?: Blob;
    pages: Array<SignaturePackage.Page>;
  }

  export interface Page {
    id: number;
    backgroundUrl: string;
    elements: Array<SignaturePackage.Element>;
    pageNumber: number;
  }

  export interface Element {
    id: number;
    signaturePageId: number;
    top: number;
    left: number;
    width: number;
    height: number;
    imageWidth?: number;
    imageHeight?: number;
    lineHeight: unknown;
    inputType: InputType;
    value: string | null;
    recipient?: SignaturePackage.Recipient;
  }

  export interface Recipient {
    id: number;
    signaturePackageId: number;
    signOrder: number;
    name: string;
    emailSent: boolean;
    email: string;
    lawyawClientId: unknown;
    lawyawMembershipId: unknown;
    submitted: boolean;
    text: unknown;
    declined: boolean;
    expired: boolean;
    font: string;
    image?: string;
    xfdfSignature?: string;
    status: 'Pending' | unknown;
  }

  export interface AuditTrailItem {
    actionText: 'Completed' /* or...? */;
    ipAddress: string;
    recipient: SignaturePackage.Recipient;
    signature_package: unknown;
    timestamp: string;
    timezoneOffset: number;
  }

  export type LeanRecipient = {
    id?: number | null;
  } & Pick<SignaturePackage.Recipient, 'name' | 'email' | 'signOrder'>;

  export type LeanSignaturePackage = Pick<
    SignaturePackage.SignaturePackage,
    'id' | 'whoSigns' | 'customOrder' | 'currentStep'
  > & { recipients: LeanRecipient[] };
}
