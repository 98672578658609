import React from 'react';
import { MissingFields } from './MissingFields';
import { useMissingFields } from '../useMissingFields';
import { Complete } from './Complete';

type SectionIndicatorProps = {
  fields: string[];
};

export const SectionIndicator = ({ fields }: SectionIndicatorProps) => {
  const numMissing = useMissingFields(fields).length;
  if (numMissing === fields.length) return null;
  return numMissing ? <MissingFields number={numMissing} /> : <Complete />;
};
