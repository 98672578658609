import { types, flow, getRoot } from 'mobx-state-tree';
import uuid from 'uuid/v4';
import analyticsService from '~/src/services/analytics';

/* Services */
import questionnairesService from '~/src/services/questionnaires';

/* Models */
import CreatePaginatedDictionaryListModel from '~/src/stores/composers/createPaginatedModel';

/* Utilities */
import {
  getQuestionnaireIdentifier,
  getQuestionnaireQuestionGroupIdentifier,
} from '~/src/utils/dataTransformers';
import { getOrgFprintFromStoreNode, isUnifiedPlatform } from '../utils';

/* Stores */
import { QuestionnaireQuestionGroups } from './questionnaireQuestionGroupStore';

/* Constants */
const STATE_PENDING = 'PENDING';
const QuestionnaireQuestionGroupsStore = QuestionnaireQuestionGroups;

export const Questionnaire = types
  .model('Questionnaire', {
    id: types.maybeNull(types.string),
    identifier: types.optional(types.identifier, uuid),
    loading: types.optional(types.boolean, false),

    title: types.optional(types.string, ''),
    description: types.optional(types.string, ''),
    displayTitle: types.optional(types.string, ''),
    displayDescription: types.optional(types.string, ''),

    templateIds: types.optional(types.array(types.string), []),
    templateSetIds: types.optional(types.array(types.string), []),

    questionGroupsStore: types.optional(QuestionnaireQuestionGroupsStore, {
      questionnaireGroups: {},
    }),
  })
  .actions((self) => {
    const removeQuestionGroup = (questionGroup) => {
      self.questionGroupsStore.remove(questionGroup);
      self.update({}, { hardRefresh: true });
    };

    const setLoading = (value) => {
      self.loading = value;
    };

    const createNewQuestionGroup = (payload = {}) => {
      const { name, description, videoUrl, questions, sortOrder } = payload;

      const insertionPoint = sortOrder || 1;
      const sortedQuestionGroups = self.sortedQuestionGroups.map(
        (questionGroup) => ({ ...questionGroup.toJSON() }),
      );

      const nextQuestions = questions
        ? questions.map(({ tag, tagId, ...question }) => ({
            ...question,
            tagId: tagId ? tagId.tag : null,
            tag,
          }))
        : [];

      const id = payload.id || uuid();

      sortedQuestionGroups.splice(insertionPoint - 1, 0, {
        id,
        identifier: getQuestionnaireQuestionGroupIdentifier(id),
        name: name || '',
        description: description || '',
        sortOrder: typeof sortOrder === 'undefined' ? 1 : sortOrder,
        questions: nextQuestions,
        videoUrl: videoUrl || '',
      });

      sortedQuestionGroups.forEach((group, index) => {
        const nextSortOrder = index + 1;

        if (group.setSortOrder) {
          group.setSortOrder(nextSortOrder);
        } else {
          group.sortOrder = nextSortOrder;
        }
      });

      self.update(
        { questionGroups: sortedQuestionGroups },
        {
          hardRefresh: true,
          callback: () => {
            self.sortedQuestionGroups.forEach((group) => {
              if (group.sortOrder === insertionPoint) {
                group.setExpanded(true);
              }
            });
          },
        },
      );
    };

    const moveQuestionGroups = (currentIndex, dropIndex) => {
      const currentQuestionGroup = self.sortedQuestionGroups[currentIndex];
      const dropQuestionGroup = self.sortedQuestionGroups[dropIndex];

      const nextSortedQuestionGroups = [...self.sortedQuestionGroups].filter(
        (q) => q.id !== currentQuestionGroup.id,
      );

      const insertBefore = dropIndex > currentIndex;
      const nextDropQuestionGroupIndex = insertBefore
        ? dropIndex - 1
        : dropIndex + 1;

      nextSortedQuestionGroups.splice(dropIndex, 1, currentQuestionGroup);
      nextSortedQuestionGroups.splice(
        nextDropQuestionGroupIndex,
        0,
        dropQuestionGroup,
      );

      nextSortedQuestionGroups.forEach((questionGroup, index) => {
        questionGroup.setSortOrder(index + 1, { update: false });
      });

      self.update();
    };

    const setQuestionGroups = (questionGroups, options) => {
      return self.questionGroupsStore.setQuestionGroups(
        questionGroups,
        options,
      );
    };

    const refresh = flow(function* refresh() {
      self.loading = true;

      if (!self.id) {
        self.loading = true;
        return self;
      }

      const {
        title,
        description,
        displayTitle,
        displayDescription,
        questionGroups,
        templateSetIds,
        templateIds,
      } = yield questionnairesService.fetchQuestionnaire(
        getOrgFprintFromStoreNode(self),
        self.id,
      );

      self.setQuestionnaire({
        title,
        description,
        displayTitle,
        displayDescription,
        templateIds,
        templateSetIds,
        questionGroups,
        loading: false,
      });

      return self;
    });

    const fetchTemplates = flow(function* fetchTemplates() {
      const rootStore = getRoot(self);

      // Fetch all templates at once
      const templates = yield rootStore.templates.fetchTemplates(
        self.templateIds,
      );

      // Iterate over the fetched templates to call fetchTags
      for (let i = 0; i < templates.length; i += 1) {
        const template = templates[i];
        if (template && template.fetchTags) {
          template.fetchTags();
        }
      }

      for (let j = 0; j < self.templateSetIds.length; j += 1) {
        const templateSetId = self.templateSetIds[j];
        const templateSet = yield rootStore.templateSets.fetchById(
          templateSetId,
        );

        if (templateSet) {
          templateSet.fetchTags();
        }
      }

      return true;
    });

    const setQuestionnaire = (nextValues, options) => {
      const keys = Object.keys(nextValues);

      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];

        if (key === 'questionGroups') {
          self.setQuestionGroups(nextValues[key], options);
        } else if (typeof self[key] !== 'undefined') {
          self[key] = nextValues[key];
        }
      }

      return self;
    };

    const update = function update(valuesToUpdate = {}, options = {}) {
      const hardRefresh = options.hardRefresh || false;
      const callback = options.callback || function () {};

      self.setQuestionnaire(valuesToUpdate, options);

      if (!self.id) {
        return self;
      }

      questionnairesService
        .updateQuestionnaire(getOrgFprintFromStoreNode(self), {
          ...self.getQuestionnaireJS(),
          ...valuesToUpdate,
        })
        .then((nextQuestionnaire) => {
          if (hardRefresh) {
            self.setQuestionnaire(nextQuestionnaire, { hardRefresh: true });
            if (callback) {
              callback(self);
            }
          }
        });

      return self;
    };

    const attachTemplate = function attachTemplateToIntake(templateId) {
      self.update({
        templateIds: [templateId],
      });
      self.fetchTemplates();
    };

    const removeTemplate = function removeTemplate() {
      self.update({
        templateIds: [],
      });
    };

    const attachTemplateSet = function attachTemplateSet(templateSetIds) {
      self.update({
        templateSetIds,
      });
    };
    return {
      // fetchTags,
      setLoading,
      fetchTemplates,
      setQuestionGroups,
      removeQuestionGroup,
      createNewQuestionGroup,
      moveQuestionGroups,
      removeTemplate,
      attachTemplate,
      attachTemplateSet,
      setQuestionnaire,
      update,
      refresh,
    };
  })
  .views((self) => ({
    get sortedQuestionGroups() {
      return self.questionGroupsStore.sortedQuestionGroups;
    },
    getQuestionnaireJS() {
      return {
        id: self.id,
        title: self.title,
        description: self.description,
        displayTitle: self.displayTitle,
        displayDescription: self.displayDescription,
        templateIds: self.templateIds,
        templateSetIds: self.templateSetIds,
        questionGroups: self.sortedQuestionGroups.map((questionGroup) => ({
          ...questionGroup.toJSON(),
        })),
      };
    },

    // Tags 2.0 specific
    getQuestionMappedTagIds() {
      const tagIds = [];
      self.questionGroups.forEach((questionGroups) => {
        questionGroups.questions.forEach((question) => {
          if (question.tagId) {
            tagIds.push(question.tagId);
          }
        });
      });

      return tagIds;
    },
    getTemplate() {
      const rootStore = getRoot(self);

      if (
        self.templateIds &&
        self.templateIds.length >= 1 &&
        self.templateIds[0]
      ) {
        return rootStore.templates.getTemplate(self.templateIds[0]);
      }

      return null;
    },
    getTemplateSet() {
      const rootStore = getRoot(self);

      if (
        self.templateSetIds &&
        self.templateSetIds.length >= 1 &&
        self.templateSetIds[0]
      ) {
        return rootStore.templateSets.getById(self.templateSetIds[0]);
      }

      return null;
    },
  }));

/**
 * Note: This Questionaires model get modified by the
 * CreatePaginatedDictionaryListModel factory, add methods such ass addItem etc..
 */
const Questionnaires = types
  .model('Questionnaires', {
    error: types.maybeNull(types.string),
    state: types.optional(types.string, STATE_PENDING),
  })
  .actions((self) => {
    const createQuestionnaire = flow(function* createQuestionnaire(payload) {
      const intake = yield questionnairesService.createNewQuestionnaire(
        getOrgFprintFromStoreNode(self),
        {
          ...payload,
        },
      );

      self.addItem(intake.identifier, intake);
      if (!self.list[0]) {
        self.list[0] = [];
      }

      self.list[0].unshift(intake.identifier);
      analyticsService.track('Create Questionnaire', {
        ...payload,
      });

      return intake;
    });

    const removeQuestionnaire = flow(function* removeQuestionnaire(id) {
      self.removeItem(getQuestionnaireIdentifier(id));
      yield questionnairesService.deleteQuestionnaire(
        getOrgFprintFromStoreNode(self),
        id,
      );
      analyticsService.track('Remove Questionnaire', {
        id,
      });
      return self;
    });

    const updateQuestionnaire = flow(function* updateQuestionnaire(
      propertiesToUpdate,
    ) {
      const intake = self.getItem(
        getQuestionnaireIdentifier(propertiesToUpdate.id),
      );
      yield intake.update(propertiesToUpdate);
      analyticsService.track('Update Questionnaire', {
        ...propertiesToUpdate,
      });
      return intake;
    });

    const getQuestionnaire = function getQuestionnaire(id) {
      return self.getItem(getQuestionnaireIdentifier(id));
    };

    const getTagsStore = function getTagsStore() {
      const rootStore = getRoot(self);
      return rootStore.tags;
    };

    const getUserStore = function getUserStore() {
      const rootStore = getRoot(self);
      return rootStore.user;
    };

    const fetchQuestionnaire = flow(function* fetchQuestionnaire(id) {
      const res = yield questionnairesService.fetchQuestionnaire(
        getOrgFprintFromStoreNode(self),
        id,
      );

      if (isUnifiedPlatform(self)) {
        const tagsStore = self.getTagsStore();
        const questionTagIds = [];

        res.questionGroups.forEach((questionGroup) => {
          questionGroup.questions.forEach((question) => {
            if (question.tagId) {
              questionTagIds.push(question.tagId);
            }
          });
        });

        yield tagsStore.fetchTagIds(questionTagIds);
      }

      self.addItem(res.identifier, res);

      const questionnaire = self.getItem(res.identifier);
      questionnaire.setLoading(true);

      questionnaire.setQuestionGroups(res.questionGroups);

      if (questionnaire.sortedQuestionGroups[0]) {
        questionnaire.sortedQuestionGroups[0].setExpanded(true);
      }

      yield questionnaire.fetchTemplates();

      questionnaire.setLoading(false);
      return questionnaire;
    });

    const fetch = flow(function* fetch(query) {
      return yield self.paginate(query);
    });

    const publishQuestionnaire = flow(function* publishQuestionnaire(
      intakeId,
      matterId,
    ) {
      return yield questionnairesService.publishQuestionnaire(
        getOrgFprintFromStoreNode(self),
        intakeId,
        matterId,
      );
    });

    return {
      getUserStore,
      fetch,
      fetchQuestionnaire,
      removeQuestionnaire,
      createQuestionnaire,
      updateQuestionnaire,
      getQuestionnaire,
      getTagsStore,
      publishQuestionnaire,
    };
  });

const PaginatedIntakesStore = CreatePaginatedDictionaryListModel(
  'PaginatedQuestionnairesStore',
  Questionnaires,
  Questionnaire,
  { paginate: questionnairesService.fetch },
);

export default PaginatedIntakesStore;
