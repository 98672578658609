import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import cn from 'classnames';

import useFeatureAccesses from '~/src/hooks/useFeatureAccesses';

const TabHeaders = ({ data }) => {
  const featureAccesses = useFeatureAccesses();
  return data.tabs.map((tab, index) => {
    if (
      tab.requiredFeatureAccess &&
      !featureAccesses[tab.requiredFeatureAccess]
    )
      return null;

    if (!tab.toUrl) {
      return (
        <li
          className={cn(
            {
              'border-opacity-100 text-blue-600 border-blue-600':
                data.active === index,
              'border-opacity-0': data.active !== index,
              'border-gray-600 text-gray-600': data.activeTab !== index,
            },
            `mr-8 cursor-pointer font-medium py-4 transition border-b-4 border-opacity-0
        hover:border-blue-600`,
          )}
          key={tab.headerText}
          onClick={() => {
            data.setActive(index);
          }}
        >
          {tab.headerText}
        </li>
      );
    }

    return (
      <li className={cn('mr-8')} key={tab.toUrl}>
        <NavLink
          to={tab.toUrl}
          className={cn(
            {
              'border-opacity-100 text-blue-600 border-blue-600':
                data.activeTab === index,
              'border-opacity-0': data.activeTab !== index,
              'border-gray-600 text-gray-600': data.activeTab !== index,
            },
            `cursor-pointer font-medium py-4 transition border-b-4  border-opacity-0
            hover:border-blue-600`,
          )}
        >
          {tab.headerText}
        </NavLink>
      </li>
    );
  });
};

const Tabs = ({ tabsMetadata, active, setActive }) => {
  const location = useLocation();

  const activeTab = tabsMetadata.findIndex(({ toUrl }) =>
    location.pathname.includes(toUrl),
  );

  const data = {
    activeTab,
    tabs: tabsMetadata,
    active,
    setActive,
  };

  return (
    <div className="flex bg-white  -mt-4 p-4 pb-0">
      <div>
        <ul className={`flex items-center ${tabsMetadata[0].toUrl && 'my-4'}`}>
          <TabHeaders data={data}></TabHeaders>
        </ul>
      </div>
    </div>
  );
};

export default Tabs;
