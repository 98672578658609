import React, { useEffect } from 'react';
import { register } from 'fetch-intercept';
import { createInterceptor } from './createInterceptor';

type InterceptorProviderProps = {
  children: React.ReactNode;
};

export const InterceptorProvider = ({ children }: InterceptorProviderProps) => {
  useEffect(() => register(createInterceptor()), []);
  return <>{children}</>;
};
