import * as React from 'react';
import ReactTooltip from 'react-tooltip';

type RenameIconButtonProps = {
  onClick: () => void;
};

export const RenameIconButton = ({ onClick }: RenameIconButtonProps) => {
  return (
    <div
      data-tip
      data-for="rename_tooltip"
      className="w-8 h-8 flex-grow-0 cursor-pointer justify-center rounded items-center hidden group-hover:flex hover:bg-gray-300"
      onClick={onClick}
      data-testid="rename-icon"
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.8766 3.12257L16.8781 6.12472L17.3787 5.62403C18.2074 4.79514 18.2074 3.45144 17.3787 2.62255C16.5497 1.7934 15.2055 1.7934 14.3766 2.62255L13.8766 3.12257Z"
          fill="#6B7280"
        />
        <path
          d="M15.5001 7.49998L12.4986 4.49783L2.97429 14.0227L2 17.9993L5.97577 17.0248L15.5001 7.49998Z"
          fill="#6B7280"
        />
      </svg>
      <ReactTooltip id="rename_tooltip" place="bottom" effect="solid">
        Rename
      </ReactTooltip>
    </div>
  );
};
