import * as React from 'react';
import { Box, TextField, TextFieldProps } from '@mui/material';
import { Search } from 'react-feather';
import { useDebounce } from 'react-use';

type SearchFieldProps = {
  initialValue?: string;
  placeholder?: string;
  debounce?: number;
  onChange: (value: string) => void;
} & Omit<TextFieldProps, 'onChange'>;

export const SearchField = ({
  initialValue = '',
  placeholder = 'Search',
  debounce = 300,
  onChange,
  ...rest
}: SearchFieldProps) => {
  const [search, setSearch] = React.useState(initialValue);
  useDebounce(() => onChange(search), debounce, [search]);

  return (
    <TextField
      {...rest}
      value={search}
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <Box pr={1} mt={-0.5}>
            <Search size={16} />
          </Box>
        ),
      }}
      onChange={(e) => setSearch(e.target.value)}
    />
  );
};
