import { ReactComponent as CloseIcon } from '~/src/static/close-icon.svg';
import { Text } from '~/src/components/Typography';
import React from 'react';
import TextInput from '~/src/components/Inputs/TextInput';

interface Props {
  emailCCs: { emailId: string }[];
  updateEmailCCList: (value: string, index: number) => void;
  removeEmailCC: (emailId: string) => void;
}

const EmailCarbonCopyList: React.FC<Props> = ({
  emailCCs,
  updateEmailCCList,
  removeEmailCC,
}) => {
  const renderEmailCCItem = (emailCC: { emailId: string }, index: number) => {
    const handleRemove = () => {
      removeEmailCC(emailCC.emailId);
    };

    const updateEmailCC = (value: string) => {
      updateEmailCCList(value, index);
    };

    return (
      <div key={index} className="border-t border-gray-200 p-4">
        <div>
          <div className="flex items-center justify-between mb-3">
            <Text strong>CC recipient</Text>
            <button
              onClick={handleRemove}
              className="flex justify-center items-center w-8 h-8 hover:bg-gray-100 rounded-md"
            >
              <CloseIcon />
            </button>
          </div>
          <div className="flex gap-3">
            <TextInput
              placeholder="Email"
              value={emailCC.emailId}
              className="flex-auto"
              onChange={updateEmailCC}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {emailCCs.map((emailCC, index) => renderEmailCCItem(emailCC, index))}
    </div>
  );
};
export default EmailCarbonCopyList;
