import React from 'react';
import moment from 'moment';
import { Alert, Typography } from '@mui/material';
import { AlertCircle } from 'react-feather';

type CanceledBannerProps = {
  endDate: string;
  extra?: React.ReactNode;
};

export const CanceledBanner = ({ endDate, extra }: CanceledBannerProps) => {
  const isAfterPeriodEnd = moment().isAfter(endDate);

  return (
    <Alert
      data-testid="subscription-canceled-banner"
      icon={<AlertCircle color="black" />}
      severity="warning"
      sx={{ background: '#fff1c8' }}
      variant="standard"
    >
      <Typography variant={'body1'}>
        {isAfterPeriodEnd
          ? `Your subscription was canceled on ${moment(endDate).format(
              'MMMM DD, YYYY',
            )}`
          : `Your subscription is set to cancel on ${moment(endDate).format(
              'MMMM DD, YYYY',
            )}`}
      </Typography>
      {extra}
    </Alert>
  );
};
