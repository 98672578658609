import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import React from 'react';
import { CLIO_ERROR_STATE } from '~/src/utils/constants';
import { ErrorMessage } from './ErrorMessage';

type ClioSyncDialogProps = {
  open: boolean;
  onClose: () => void;
  onReturnToClio: () => void;
  error?: string;
};

export const ClioSyncDialog = ({
  open,
  onClose,
  error,
  onReturnToClio,
}: ClioSyncDialogProps) => {
  const title = error ? 'An error occurred' : 'Syncing data from Clio...';

  const shouldShowReturnToClio =
    error === CLIO_ERROR_STATE.COURT_FORMS_NOT_ENABLED;

  return (
    <Dialog maxWidth={'xs'} open={open}>
      <DialogTitle>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box display={'flex'} alignItems={'center'}>
            <Typography variant={'subheading'}>{title}</Typography>
          </Box>
          {error ? (
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          ) : null}
        </Box>
      </DialogTitle>
      <DialogContent>
        {error ? (
          <ErrorMessage error={error} />
        ) : (
          <Box display={'flex'} justifyContent={'center'}>
            <CircularProgress size={20} />
          </Box>
        )}
      </DialogContent>
      {shouldShowReturnToClio ? (
        <DialogActions>
          <Button onClick={onReturnToClio}>Return to Clio</Button>
        </DialogActions>
      ) : null}
    </Dialog>
  );
};
