import { useQuery, UseQueryOptions } from 'react-query';
import { APIError } from '~/src/utils/error';
import { composeQueryOpts } from '../../utils';

import { QuestionnaireTemplate } from '../types';
import { getQuestionnaireTemplateUrlById } from '../urls';

export const useGetQuestionnaireTemplates = (
  orgFprint: string,
  questionnaireId: string,
  options: UseQueryOptions<QuestionnaireTemplate[], APIError> = {},
) =>
  useQuery(
    composeQueryOpts(options, {
      queryKey: ['questionnaireTemplate', questionnaireId],
      queryFn: async () => {
        const response = await fetch(
          getQuestionnaireTemplateUrlById(orgFprint, questionnaireId),
        );

        if (!response.ok)
          throw new APIError(
            'Failed to retrieve templates for selected questionnaire',
            response,
          );

        return response.json();
      },
    }),
  );
