import React, { FC } from 'react';
import Footer, { FooterProps } from '../components/SignaturePackage/Footer';
import Header, { HeaderProps } from '../components/SignaturePackage/Header';

type Props = HeaderProps & FooterProps;

const HeaderFooter: FC<Props> = ({
  children,
  step,
  whoSigns,
  invalidReason,
  valid,
  signaturePackageTitle = 'New signature package',
  onBackClick,
  onNextClick,
  onExitClick,
}) => {
  return (
    <div className="flex flex-col grow">
      <Header
        signaturePackageTitle={signaturePackageTitle}
        step={step}
        whoSigns={whoSigns}
        onExitClick={onExitClick}
      />
      <div className="flex flex-col grow pb-16">
        {children}
        <Footer
          step={step}
          whoSigns={whoSigns}
          valid={valid}
          invalidReason={invalidReason}
          onNextClick={onNextClick}
          onBackClick={onBackClick}
        />
      </div>
    </div>
  );
};

export default HeaderFooter;
