import { useObserver } from 'mobx-react';
import useMst from '~/src/hooks/useMst';
import { SignaturePackage } from '../models';

const useFetchSignaturePackage = (signaturePackageId: string) => {
  const { signatures } = useMst((store) => {
    return {
      signatures: store.signatures,
    };
  });

  const listData = useObserver(() => {
    return signatures.list.flat();
  });

  const storedSignaturePackage = listData.find(
    (e: any) => e.id.toString() === signaturePackageId,
  ) as SignaturePackage.SignaturePackage | undefined;

  return {
    signaturePackage: storedSignaturePackage,
  };
};

export default useFetchSignaturePackage;
