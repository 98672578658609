import { useEffect, useState } from 'react';

// Hook to be used to get an element that only appears after a page has completely loaded
// E.g. ChurnZero button. This hook will wait for the element to appear and then return it.
export const useGetElement = (query: string) => {
  const [element, setElement] = useState<Element | null>(null);

  useEffect(() => {
    (async () => {
      const element: Element | null = await new Promise((resolve) => {
        const getElement = () => {
          const element = document.querySelector(query);
          if (element) {
            resolve(element);
          } else {
            setTimeout(() => {
              requestAnimationFrame(getElement);
            }, 100);
          }
        };

        getElement();
      });

      setElement(element);
    })();
  }, [query]);

  return element;
};
