import { useState } from 'react';
import useMst from '~/src/hooks/useMst';

import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import { useLayoutContext } from '~/src/contexts/Layout';

const useContact = (id, matterId) => {
  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState({});

  const {
    getContact,
    fetchContact,
    updateContact,
    fetchContactInSpecificMatter,
  } = useMst((store) => ({
    updateContact: store.contacts.updateContact,
    getContact: store.contacts.getContact,
    fetchContact: store.contacts.fetchContact,
    fetchContactInSpecificMatter: store.contacts.fetchContactInSpecificMatter,
  }));

  const { showToast } = useLayoutContext();

  useState(() => {
    if (matterId) {
      // in this case we want to get a contact within a specific matter (allows user to see contact role)
      fetchContactInSpecificMatter(id, matterId).then((success) => {
        if (success) {
          const contact = getContact(id);
          setContact(contact);
        } else {
          showToast(LAYOUT_TOAST_TYPES.error);
        }
      });
    } else {
      fetchContact(id).then((success) => {
        if (success) {
          const contact = getContact(id);
          setContact(contact);
        } else {
          showToast(LAYOUT_TOAST_TYPES.error);
        }
      });
    }
    setLoading(false);
  }, [id]);

  return {
    contact,
    loading,
    updateContact,
  };
};

export default useContact;
