import * as React from 'react';
import ReactTooltip from 'react-tooltip';

type DuplicateIconButtonProps = {
  onClick: () => void;
};

export const DuplicateIconButton = ({ onClick }: DuplicateIconButtonProps) => {
  return (
    <div
      data-tip
      data-for="duplicate_tooltip"
      className={`w-8 h-8 flex-grow-0 cursor-pointer justify-center rounded items-center hidden group-hover:flex hover:bg-gray-300`}
      onClick={onClick}
      data-testid="duplicate-icon"
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.5 2C6.67157 2 6 2.67157 6 3.5V13C6 13.5523 6.44771 14 7 14H16.5C17.3284 14 18 13.3284 18 12.5V3.5C18 2.67157 17.3284 2 16.5 2H7.5Z"
          fill="#6B7280"
        />
        <path
          d="M3.5 6H4V16H14V16.5C14 17.3284 13.3284 18 12.5 18H3.5C2.67157 18 2 17.3284 2 16.5V7.5C2 6.67157 2.67157 6 3.5 6Z"
          fill="#6B7280"
        />
      </svg>

      <ReactTooltip id="duplicate_tooltip" place="left" effect="solid">
        Duplicate
      </ReactTooltip>
    </div>
  );
};
