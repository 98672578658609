import React from 'react';

import { ReactComponent as EmptyViewIcon } from './empty-view.svg';

const TemplateSetsEmptyView = () => {
  return (
    <div className="mx-6">
      <div className="flex justify-center mt-20">
        <EmptyViewIcon />
      </div>

      <div className="text-center mt-12 text-lg font-medium">
        Create sets of documents that are commonly drafted together
      </div>

      <div className="text-center mt-2">
        use sets to group documents that are usually drafted together.
        <br />
        To create a set, add documents to selection and click on &quot;Save as
        new set&quot;.
      </div>
    </div>
  );
};

export default TemplateSetsEmptyView;
