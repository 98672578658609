import React from 'react';
import { DialogContent } from '@mui/material';

import { SpinnerWithTitle } from '~/src/components/Spinner';

const LoadingContent = () => {
  return (
    <DialogContent dividers={true}>
      <SpinnerWithTitle title="Importing..." subtitle="Please Wait" />
    </DialogContent>
  );
};

export default LoadingContent;
