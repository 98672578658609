/* Libs */
import { css, StyleSheet } from 'aphrodite';
import React from 'react';

/* Components */
import MiniSpinner from '~/src/components/Spinner/MiniSpinner';
import Button from '~/src/components/Button';

const styles = StyleSheet.create({
  addDocumentFooter: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    bottom: '0px',
  },
  confirmClickSpinner: {
    height: '21px',
  },
});

interface AddDocumentFooterProps {
  isLoading: boolean;
  confirmDisabled?: boolean;
  onBackClick: () => void;
  onConfirmClick: () => void;
}

const AddDocumentFooter = ({
  isLoading,
  confirmDisabled,
  onBackClick,
  onConfirmClick,
}: AddDocumentFooterProps) => {
  return (
    <footer className={css(styles.addDocumentFooter)}>
      <Button onClick={onBackClick}>Back</Button>
      <Button primary onClick={onConfirmClick} disabled={!!confirmDisabled}>
        {isLoading && (
          <MiniSpinner className={css(styles.confirmClickSpinner)} />
        )}
        Confirm selection
      </Button>
    </footer>
  );
};

export default AddDocumentFooter;
