import { useMutation, useQueryClient } from 'react-query';
import { APIError } from '~/src/utils/error';
import {
  getClioQuestionnaireAuthToken,
  questionnaireAuthorizationHeaders,
} from '../utils';
import { getLogoRemoveUrl } from '../urls';
import { questionnaireQueryArgs } from '../queryArgs';
import { Questionnaire } from '@clio/questionnaire-builder';

type RemoveLogo = {
  questionnaireId: string;
};

export const useRemoveLogo = (orgFprint: string) => {
  const queryClient = useQueryClient();
  return useMutation<Questionnaire, APIError, RemoveLogo>({
    onSuccess: (questionniare: Questionnaire) => {
      queryClient.invalidateQueries(
        questionnaireQueryArgs.detail(String(questionniare.id)),
      );
    },
    mutationFn: async ({ questionnaireId }) => {
      const token = await getClioQuestionnaireAuthToken(orgFprint);
      const response = await fetch(getLogoRemoveUrl(questionnaireId), {
        method: 'DELETE',
        headers: {
          ...questionnaireAuthorizationHeaders(token),
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new APIError('Failed to remove logo', response);
      }

      return response.json();
    },
  });
};
