import React from 'react';

import Button from '~/src/components/Button';
import gatedActions from '~/src/services/gatedActions';

const ContactUsButton = ({ utmCampaign, routeToLink }) => {
  const handleClick = async () => {
    await gatedActions.userClickedContactSales(utmCampaign);
    window.open(routeToLink, '_blank');
  };
  return (
    <div>
      <Button onClick={handleClick} primary>
        Contact Us
      </Button>
    </div>
  );
};

export default ContactUsButton;
