import React from 'react';

import Table from '~/src/containers/Table';
import { RowActions } from './RowActions';

import {
  QuestionnaireStatus,
  type QuestionnaireSubmission,
} from '@clio/questionnaire-builder';
import moment from 'moment';
import { QuestionnaireStatusBadge } from '../QuestionnaireStatusBadge';

export const QuestionnaireSubmissions = ({
  hideHeader,
  empty,
  noBorder,
  visibleItems,
  submissions,
  handleEditSubmission,
  handleRenameSubmission,
  handleDeleteSubmission,
  matterIdMap,
  questionnaireDocumentMapping,
}: {
  hideHeader?: boolean;
  empty?: boolean;
  noBorder?: boolean;
  visibleItems: number;
  submissions: QuestionnaireSubmission[];
  handleEditSubmission: (submission: QuestionnaireSubmission) => void;
  handleRenameSubmission: (submission: QuestionnaireSubmission) => void;
  handleDeleteSubmission: (submission: QuestionnaireSubmission) => void;
  matterIdMap: { [key: string]: string };
  questionnaireDocumentMapping: { [key: string]: number };
}) => {
  const rowActions = {
    edit: handleEditSubmission,
    rename: handleRenameSubmission,
    delete: handleDeleteSubmission,
  };

  const renderRowAction = (submission: QuestionnaireSubmission) => (
    <RowActions actions={rowActions} submission={submission} />
  );

  const renderMatterColumn = (value: string) => {
    return <>{matterIdMap[value] || ''}</>;
  };

  return (
    <>
      <Table
        hideHeader={hideHeader}
        noBorder={noBorder}
        emptyTitle={'No live questionnaires found.'}
        empty={empty}
        isLoading={false}
        renderActions={() => <div />}
        data={submissions}
        visibleItems={visibleItems}
        columns={[
          {
            label: 'Questionnaire name',
            dataKey: 'title',
            style: {
              width: '30%',
              textAlign: 'left',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            },
          },
          {
            label: 'Matter',
            dataKey: 'target_identifier',
            style: {
              width: '21%',
              textAlign: 'left',
            },
            render: renderMatterColumn,
          },
          {
            label: 'Documents',
            dataKey: 'questionnaire_id',
            style: { width: '10%', textAlign: 'left' },
            render: (value: number) => {
              return questionnaireDocumentMapping[value] || 0;
            },
          },
          {
            label: 'Response status',
            dataKey: 'status',
            style: { width: '12%', textAlign: 'left' },
            render: (value: QuestionnaireStatus) => {
              return <QuestionnaireStatusBadge status={value} />;
            },
          },
          {
            label: 'Last updated',
            dataKey: 'updated_at',
            style: { width: '10%', textAlign: 'left' },
            render: (value: string) => {
              return <span>{moment(value).format('MM/DD/YYYY')}</span>;
            },
          },
          {
            label: 'Actions',
            hideLabel: true,
            render: renderRowAction,
            style: {
              width: '12%',
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'flex-end',
            },
          },
        ]}
      />
    </>
  );
};
