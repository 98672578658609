import { SnackbarProvider, SnackbarProviderProps } from 'notistack';
import React from 'react';
import { ErrorToast, SuccessToast } from '../components/Toasts';

export const ToastProvider = ({ children, ...rest }: SnackbarProviderProps) => {
  return (
    <SnackbarProvider
      Components={{ error: ErrorToast, success: SuccessToast }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      {...rest}
    >
      {children}
    </SnackbarProvider>
  );
};
