import React from 'react';
import { CardListItemMetaText } from '~/src/components/CardList';
import {
  useDraftDocumentsUsingQuestionnaire,
  useGetQuestionnaireTemplates,
} from '~/src/entities/questionnaires';
import { useCurrentOrgFprint } from '~/src/entities/user';
import { Box, Typography } from '@mui/material';

export const QuestionnaireSubmissionMetadataRow: React.FC<{
  matterId: string;
  questionnaireId: string;
}> = ({ matterId, questionnaireId }) => {
  const orgFprint = useCurrentOrgFprint();

  const { matterData, isMatterError } = useDraftDocumentsUsingQuestionnaire(
    orgFprint,
    matterId,
  );

  const {
    data: questionnaireTemplates,
    isError: isQuestionnaireTemplatesError,
  } = useGetQuestionnaireTemplates(orgFprint, questionnaireId);

  if (isMatterError || isQuestionnaireTemplatesError) {
    return (
      <CardListItemMetaText>
        <Box display={'flex'} sx={{ paddingLeft: 3 }} alignItems={'center'}>
          <Typography
            component={'span'}
            fontSize={12}
            fontWeight={400}
            sx={(theme) => ({
              color: theme.palette.grey[600],
            })}
          >
            Error retrieving metadata, please try again later.
          </Typography>
        </Box>
      </CardListItemMetaText>
    );
  }

  return (
    <CardListItemMetaText>
      <Box display={'flex'} sx={{ paddingLeft: 3 }} alignItems={'center'}>
        {!matterData || !questionnaireTemplates ? (
          <Typography
            component={'span'}
            fontSize={12}
            fontWeight={400}
            sx={(theme) => ({
              color: theme.palette.grey[600],
            })}
          >
            Loading...
          </Typography>
        ) : (
          <>
            <Typography
              component={'span'}
              fontSize={12}
              fontWeight={400}
              sx={(theme) => ({
                color: theme.palette.blue[600],
              })}
            >
              {matterData.title}
            </Typography>
            <Typography
              component={'span'}
              fontSize={12}
              fontWeight={400}
              padding={'0 6px'}
              sx={(theme) => ({
                color: theme.palette.grey[300],
              })}
            >
              |
            </Typography>
            <Typography
              component={'span'}
              fontSize={12}
              fontWeight={400}
              sx={(theme) => ({
                color: theme.palette.grey[600],
              })}
            >
              {questionnaireTemplates.length > 0
                ? questionnaireTemplates.length === 1
                  ? '1 output document'
                  : `${questionnaireTemplates.length} output documents`
                : 'No output documents'}
            </Typography>
          </>
        )}
      </Box>
    </CardListItemMetaText>
  );
};
