import { keys } from '../keys';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useState, useEffect } from 'react';
import { APIError } from '~/src/utils/error';
import { usePollingTaskStatus } from '~/src/hooks/usePollingTaskStatus';
import { getProjectUrl } from '~/src/entities/project/urls';
import { TaskStatusResponse } from '~/src/hooks/types';
import { Project } from '../types';
import { useCurrentOrgFprint } from '~/src/entities/user';

export type UseProjectResult = Omit<
  UseQueryResult<Project | TaskStatusResponse | undefined, APIError>,
  'data'
> & {
  data: Project | undefined;
};

export const useProject = (
  projectId: number,
  options?: UseQueryOptions<Project | TaskStatusResponse, APIError>,
): UseProjectResult => {
  const orgFprint = useCurrentOrgFprint();
  const [project, setProject] = useState<Project>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const queryFn = async (): Promise<Project | TaskStatusResponse> => {
    const response = await fetch(getProjectUrl(orgFprint, projectId));

    if (!response.ok) {
      setIsLoading(false);
      throw new APIError(
        `Failed to fetch project ${projectId}: ${response.statusText}`,
        response,
      );
    }
    const data = await response.json();

    if ('task_id' in data) {
      // If the response is a TaskStatusResponse
      return data as TaskStatusResponse;
    } else {
      // If the response is an Instance of Project
      return data as Project;
    }
  };

  const projectQuery = useQuery<Project | TaskStatusResponse, APIError>(
    keys.detail(projectId),
    {
      ...options,
      queryFn,
      onSuccess: (data) => {
        if (!('task_id' in data)) {
          setProject(data);
          setIsLoading(false);
        }
      },
    },
  );

  const taskId =
    projectQuery.data && 'task_id' in projectQuery.data
      ? projectQuery.data.task_id
      : undefined;

  const { data: taskStatusData, isFetching: isPolling } = usePollingTaskStatus(
    taskId,
    {
      onSuccess: (pollData) => {
        if (pollData && pollData.status === 'SUCCESS') {
          // Refetch project data once the task is completed
          projectQuery.refetch();
        }
      },
    },
  );

  // Effect to handle the completion of the task
  useEffect(() => {
    if (taskStatusData && taskStatusData.status === 'SUCCESS' && !project) {
      setIsLoading(true);
      projectQuery.refetch().then(() => setIsLoading(false));
    }
  }, [taskStatusData, projectQuery, project]);

  return {
    ...(projectQuery || {}),
    data: project,
    isLoading: isLoading || isPolling,
    isError: projectQuery?.isError || !projectQuery,
    isSuccess: projectQuery?.isSuccess && project !== undefined,
    error: projectQuery?.error,
  };
};
