/* Libraries */
import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';

/* Components */
import { ReactComponent as AlertIcon } from '~/src/static/alert-icon.svg';

/* Styles */
import styles from './styles';

const ClioError = ({ title, subtitle, renderAction }) => (
  <div className={css(styles.container)}>
    <div className={css(styles.successContainer)}>
      {/* TODO: replace with large loader */}
      <i className={css(styles.successIcon)}>
        <AlertIcon />
      </i>
      <h1 className={css(styles.title)}>{title}</h1>
      <h2 className={css(styles.subtitle)}>{subtitle}</h2>

      {renderAction && (
        <div className={css(styles.actionContainer)}>{renderAction()}</div>
      )}
    </div>
  </div>
);

ClioError.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ClioError.defaultProps = {
  title: 'Failed to import from Clio!',
  subtitle:
    'There was a problem importing your data from Clio. Retry importing by clicking on the button below or reach out to our support team if the problem persists.',
};

export default ClioError;
