import { types, flow } from 'mobx-state-tree';
import pdfCourtFormsService from '~/src/services/pdfCourtFormsService';

import createPaginatedModel from '~/src/stores/composers/createPaginatedModel';

const PdfLocalityStore = types.model('PdfLocalityStore', {
  identifier: types.identifier,
  filterCategory: types.string,
  locality: types.string,
});

const PdfLocalitiesStore = types
  .model('PdfLocalitiesStore', {
    isLoading: false,
    error: '',
  })
  .actions((self) => {
    const fetch = flow(function* (query, requestPayload) {
      return yield self.paginate(query || {}, requestPayload);
    });

    return {
      fetch,
    };
  });

const PaginatedPdfLocalitiesStore = createPaginatedModel(
  'PaginatedPdfLocalitiesStore',
  PdfLocalitiesStore,
  PdfLocalityStore,
  { paginate: pdfCourtFormsService.getLocalities },
);

export { PaginatedPdfLocalitiesStore as default, PdfLocalityStore };
