import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';
import uuid from 'uuid/v4';

import theme from '~/src/theme';

import { ArrowUp, ArrowDown } from 'react-feather';
import styles from './styles';

const TableHeaderColumn = ({
  label,
  dataKey,
  isSortByDisabled,
  sortBy,
  sortOrder,
  style,
  onClick,
}) => {
  const handleClick = () => {
    if (isSortByDisabled) {
      return;
    }
    onClick(dataKey);
  };

  const selected = sortBy === dataKey && sortOrder !== 0;
  const Icon = sortOrder > 0 ? ArrowUp : ArrowDown;

  const inlineStyles = {
    margin: `0 ${theme.unit * 2}px`,
    ...style,
  };

  return (
    <div
      className={css(
        styles.headerColumn,
        selected && styles.headerColumnActive,
      )}
      style={inlineStyles}
      onClick={handleClick}
    >
      {label}
      {selected && (
        <i className={css(styles.headerColumnIcon)}>
          <Icon color={theme.colors.eggplant} size={10} />
        </i>
      )}
    </div>
  );
};

const TableHeader = ({
  noBorder,
  columns,
  sortBy,
  isSortByDisabled,
  sortOrder,
  onColumnClick,
}) => {
  return (
    <div className={css(styles.header, noBorder && styles.headerNoBorder)}>
      {columns.map(
        ({ label, style, dataKey, hideLabel, disableSort }, index) => (
          <TableHeaderColumn
            key={`${label}-${index}`}
            label={!hideLabel && label}
            dataKey={dataKey}
            isSortByDisabled={isSortByDisabled || disableSort}
            sortBy={sortBy}
            sortOrder={sortOrder}
            style={style}
            onClick={onColumnClick}
          />
        ),
      )}
    </div>
  );
};

const RowColumn = ({
  data,
  dataKey,
  style = {},
  toggleChildren,
  showChildren,
  render,
}) => {
  let value = null;

  const hasKey = dataKey && Object.prototype.hasOwnProperty.call(data, dataKey);
  const props = { rowData: data, toggleChildren, showChildren };

  // Allows props to be passed to "render"
  if (render && hasKey) {
    value = render(data[dataKey], props);
  } else if (render) {
    value = render(data, props);
  } else if (hasKey) {
    value = data[dataKey];
  }

  const inlineStyles = {
    margin: `0 ${theme.unit * 2}px`,
    padding: `${theme.unit * 3}px 0`,
    ...style,
  };

  return (
    <div className={css(styles.rowColumn)} style={inlineStyles}>
      {value}
    </div>
  );
};

function TableRow({
  columns,
  childColumns,
  childRowStyle,
  parentRow,
  row,
  rowStyle,
  onRowClick,
  index,
  data,
  showLeftBorder,
  showRightBorder,
  showBottomBorder,
  rowClickable,
}) {
  const [showChildren, setShowChildren] = useState(false);

  const toggleChildren = () => {
    setShowChildren(!showChildren);
  };

  const rowColumns = columns.map((column) => {
    const { style, ...rowProps } = column;

    const inlineStyles = {
      ...style,
      ...rowStyle,
    };

    return (
      <RowColumn
        key={uuid()}
        data={{ ...row, parentRow }}
        showChildren={showChildren}
        style={inlineStyles}
        {...rowProps}
      />
    );
  });

  const hasChildren = row.children && row.children.length > 0;

  const handleRowClick = () => {
    onRowClick(row, index, data);

    if (hasChildren) {
      toggleChildren();
    }
  };

  const childrenExpanded = hasChildren && childColumns && showChildren;

  return (
    <div
      key={uuid()}
      className={css(showBottomBorder && styles.rowBottomBorder)}
    >
      <div
        className={css(
          styles.row,
          showLeftBorder && styles.rowLeftBorder,
          showRightBorder && styles.rowRightBorder,
          rowClickable && styles.rowClickable,
          showChildren && styles.rowShowChildren,
        )}
        onClick={rowClickable ? handleRowClick : undefined}
      >
        {rowColumns}
      </div>
      {childrenExpanded && (
        <div className={css(styles.childRow)}>
          {/* eslint-disable-next-line no-use-before-define */}
          <TableRows
            showBottomBorder
            showRightBorder
            parentRow={row}
            data={row.children}
            columns={childColumns}
            rowStyle={childRowStyle}
          />
        </div>
      )}
    </div>
  );
}

function TableRows({
  data,
  parentRow,
  columns,
  childColumns,
  childRowStyle,
  rowStyle,
  showLeftBorder,
  showRightBorder,
  showBottomBorder,
  onRowClick,
  rowClickable,
}) {
  const rows = data.map((row, index) => {
    const id = row.id ? row.id : index;
    const key = `table-row-${id}`;

    return (
      <TableRow
        key={key}
        showLeftBorder={showLeftBorder}
        showRightBorder={showRightBorder}
        showBottomBorder={showBottomBorder}
        columns={columns}
        childColumns={childColumns}
        childRowStyle={childRowStyle}
        parentRow={parentRow}
        row={row}
        rowStyle={rowStyle}
        onRowClick={onRowClick}
        index={index}
        data={data}
        rowClickable={rowClickable}
      />
    );
  });

  return <div>{rows}</div>;
}

const TableBody = ({
  sortedData,
  columns,
  childColumns,
  childRowStyle,
  rowStyle,
  setSortBy,
  sortBy,
  isSortByDisabled,
  sortOrder,
  noBorder,
  onRowClick,
  hideColumnHeaders,
  rowClickable,
}) => {
  return (
    <div className={css(styles.table, noBorder && styles.tableNoBorder)}>
      {!hideColumnHeaders && (
        <TableHeader
          noBorder={noBorder}
          columns={columns}
          onColumnClick={setSortBy}
          isSortByDisabled={isSortByDisabled}
          sortBy={sortBy}
          sortOrder={sortOrder}
        />
      )}
      <TableRows
        showRightBorder={!noBorder}
        showLeftBorder={!noBorder}
        showBottomBorder
        noBorder={noBorder}
        columns={columns}
        childColumns={childColumns}
        childRowStyle={childRowStyle}
        rowStyle={rowStyle}
        data={sortedData}
        rowClickable={rowClickable}
        onRowClick={onRowClick}
      />
    </div>
  );
};

TableBody.propTypes = {
  hideColumnHeaders: PropTypes.bool,
  noBorder: PropTypes.bool,
  sortedData: PropTypes.array,
  rowClickable: PropTypes.bool,

  sortOrder: PropTypes.oneOf([0, 1, -1]),
  sortBy: PropTypes.string,
  setSortBy: PropTypes.func,
  onRowClick: PropTypes.func,

  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      dataKey: PropTypes.string,
      hideLabel: PropTypes.bool,
      style: PropTypes.object,
      render: PropTypes.func,
    }),
  ),
};

TableBody.defaultProps = {
  rowClickable: false,
  onRowClick: () => {},
};

export default TableBody;
