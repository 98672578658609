import { WebViewerInstance } from '@pdftron/webviewer';
import { createContext, CSSProperties, useContext } from 'react';

export interface Position {
  top?: CSSProperties['top'];
  bottom?: CSSProperties['bottom'];
  left?: CSSProperties['left'];
  right?: CSSProperties['right'];
}

interface WebViewerContextType {
  makeWebViewerVisible: () => void;
  setWebViewerPosition: (position: Position) => void;
  setZIndexOfWebViewer: (zIndex: number) => void;
  hideWebViewer: () => void;
  instance?: WebViewerInstance;
}

// exported for testing purposes
export const WebViewerContext = createContext<WebViewerContextType | undefined>(
  undefined,
);

export const useWebViewerContext = () =>
  useContext(WebViewerContext) as WebViewerContextType;

export default WebViewerContext;
