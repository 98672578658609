import { types, flow } from 'mobx-state-tree';
import membershipService from '~/src/services/membership';

export const ProfileStore = types
  .model({
    id: types.integer,
    authenticatedUserId: types.maybeNull(types.integer),
    displayName: types.string,
    firstName: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    fprint: types.string,
    initials: types.string,
    lastName: types.maybeNull(types.string),
    middleName: types.maybeNull(types.string),
    avatar: types.maybeNull(types.string),
    ssoMethod: types.maybeNull(types.integer),
    ssoEnabled: types.optional(types.boolean, false),
    xfdfSignature: types.maybeNull(types.string),
  })
  .actions((self) => {
    const update = flow(function* update(properties) {
      const { phone, firstName, middleName, lastName } = properties;

      try {
        self.phone = phone;
        self.firstName = firstName;
        self.lastName = lastName;
        self.middleName = middleName;

        self.displayName = [firstName, middleName, lastName]
          .filter(Boolean)
          .join(' ');

        const res = yield membershipService.updateUserProfile({
          id: self.id,
          phone,
          middleName,
          firstName,
          lastName,
        });

        return typeof res.ok === 'undefined' ? true : res.ok;
      } catch (error) {
        console.error('Failed to update user', error);
        return false;
      }
    });

    const removePhoto = flow(function* removePhoto() {
      try {
        self.avatar = null;
        const res = yield membershipService.removeProfilePhoto();
        return res.ok || true;
      } catch (error) {
        console.error('Failed to remove photo', error);
        return false;
      }
    });

    const uploadPhoto = flow(function* uploadPhoto(imageFile) {
      try {
        const data = new FormData();
        data.append('photofile', imageFile);
        const res = yield membershipService.uploadProfilePhoto(data);

        if (res) {
          self.avatar = URL.createObjectURL(imageFile);
        }

        return res.ok || true;
      } catch (error) {
        console.error('Failed to upload image file : ', error);
        return false;
      }
    });

    /**
     * Uploads an XFDF string to save for the current member. This is
     * used for future signatures on signature packages.
     */
    const uploadSignatureXfdf = flow(function* uploadSignatureXfdf(xfdf) {
      try {
        const data = new FormData();
        data.append('xfdf_signature', xfdf);
        const res = yield membershipService.uploadSignatureXfdf(data);

        if (res) {
          self.xfdfSignature = xfdf;
        }

        return res.ok || true;
      } catch (error) {
        console.error('Failed to upload XFDF signature : ', error);
        return false;
      }
    });

    const setSsoEnabled = (value: boolean) => (self.ssoEnabled = value);
    const setSsoMethod = (value: number) => (self.ssoMethod = value);

    return {
      setSsoEnabled,
      setSsoMethod,
      update,
      uploadPhoto,
      uploadSignatureXfdf,
      removePhoto,
    };
  });
