import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

export default StyleSheet.create({
  dropdown: {
    display: 'block',
    position: 'absolute',
    border: theme.border,
    background: theme.surface,
    padding: theme.unit * 2,
    borderRadius: theme.borderRadius,
    boxShadow: '0 4px 6px 0 rgba(0, 0, 0, 0.09)',
    zIndex: '10',
    minWidth: '150px',
  },
  dropdownBottom: {
    top: '110%',
  },
  dropdownTop: {
    bottom: '110%',
  },
  dropdownRight: {
    right: '0',
  },
  dropdownLeft: {
    left: '0',
  },
  dropdownClose: {
    display: 'none',
  },
  dropdownActionItemRed: {
    color: theme.colors.paleTomato,
  },
  dropdownActionItem: {
    display: 'block',
    whiteSpace: 'nowrap',
    color: theme.colors.charcoal,
    fontSize: theme.fonts.size.small,
    paddingBottom: theme.unit * 2,
    ':hover': {
      color: theme.colors.bluebird,
      cursor: 'pointer',
    },
    ':last-of-type': {
      paddingBottom: 0,
    },
  },
});
