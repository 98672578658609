export const SIGNATURE_ANNOTATION_WIDTH_PX = 128;
export const SIGNATURE_ANNOTATION_HEIGHT_PX = 40;

export const SIGNER_INDICATOR_COLORS = [
  '#ECBD77',
  '#8671F4',
  '#F98E8E',
  '#62B411',
  '#3372EC',
  '#53C4E7',
];

export const SIGNER_INDICATOR_OPACITY = 0.15;
