/* Libraries */
import React from 'react';
import { css } from 'aphrodite';
import { Copy } from 'react-feather';

/* Components */
import theme from '~/src/theme';
import Label from './Label';

/* Styles */
import styles from './styles';

const CopyLink = ({
  label,
  value,
  hint,
  count,
  target,
  onClick,
  disabled,
  href,
}) => (
  <Label label={label} hint={hint} count={count} onClick={!disabled && onClick}>
    <a
      role="button"
      target={target}
      tabIndex={0}
      href={href}
      onKeyPress={!disabled && onClick}
      className={css(styles.copyLink, disabled && styles.copyLinkDisabled)}
    >
      {value}
    </a>
    <div className={css(styles.copyLinkIcon)}>
      <Copy color={theme.colors.bluebird} size={14} />
    </div>
  </Label>
);

CopyLink.defaultProps = {
  target: '_default',
};

export default CopyLink;
