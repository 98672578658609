import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { snakeCase } from 'lodash';

/* Hooks */
import {
  useQuestionnairesActions,
  useQuestionnairesPagination,
} from '~/src/hooks/questionnaires';
import usePageScroll from '~/src/hooks/usePageScroll';
import useSuspendLoading from '~/src/hooks/useSuspendLoading';

/* Containers */
import PageContainer from '~/src/containers/pages/Page';

/* Components */
import QuestionnairesTable from '~/src/components/QuestionnairesTable';
import LoadMoreDisplayText from '~/src/components/LoadMoreDisplayText';

const useQuestionnaireTable = () => {
  const {
    viewIntake: handleViewIntake,
    renameIntake: handleRenameIntake,
    deleteQuestionnaire: handleDeleteIntake,
    publishQuestionnaire,
  } = useQuestionnairesActions();

  const { next, data, loading, done, setSearch, setSortBy, setOrder, empty } =
    useQuestionnairesPagination();

  const pageScroll = usePageScroll();

  const loadMore = next;

  const handleOnPageScroll = () => {
    if ((pageScroll === 0 || pageScroll >= 85) && !loading) {
      loadMore();
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleOnPageScroll, [pageScroll]);

  const handleSearchChange = (searchValue) => {
    setSearch(searchValue);
  };

  const handleSortByChange = (property, order) => {
    if (!property) {
      return;
    }

    const orderLabel = order > 0 ? 'desc' : 'asc';
    let sortBy = snakeCase(property);

    if (property === 'title') {
      sortBy = 'name';
    }

    // TODO: abstract this into a model/transformer lookup
    if (property === 'updatedAt') {
      sortBy = 'last_modified';
    }

    setSortBy(sortBy);
    setOrder(orderLabel);
  };

  const handlePublish = (intake) => {
    publishQuestionnaire(intake);
  };

  return {
    data,
    loading,
    done,
    empty,

    loadMore,

    handlePublish,
    handleSortByChange,
    handleSearchChange,

    handleViewIntake,
    handleRenameIntake,
    handleDeleteIntake,
  };
};

const QuestionnaireTablePageContainer = () => {
  const {
    data,
    loading,
    done,
    empty,

    loadMore,

    handlePublish,
    handleSortByChange,
    handleSearchChange,

    handleViewIntake,
    handleRenameIntake,
    handleDeleteIntake,
  } = useQuestionnaireTable();

  const suspend = useSuspendLoading(data.length === 0 || empty);

  if (suspend) {
    return null;
  }

  return (
    <PageContainer loading={suspend} loadingText="Loading questionnaires">
      <QuestionnairesTable
        loading={loading}
        intakes={data}
        empty={empty}
        onView={handleViewIntake}
        onRename={handleRenameIntake}
        onDelete={handleDeleteIntake}
        onSearchChange={handleSearchChange}
        onSortByChange={handleSortByChange}
        onPublish={handlePublish}
      />
      <LoadMoreDisplayText
        loading={loading}
        done={done}
        onLoadMore={loadMore}
      />
    </PageContainer>
  );
};

export default observer(QuestionnaireTablePageContainer);
