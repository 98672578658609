import React from 'react';

import theme from '~/src/theme';
import { ReactComponent as CloseIcon } from '~/src/static/close-icon.svg';

import DraggableIcon from '~/src/components/DraggableIcon';
import DraggableListContainer, {
  DraggableListItem,
} from '~/src/components/Draggable';
import TextInput from '~/src/components/Inputs/TextInput';
import { Text } from '~/src/components/Typography';

const DraggableRecipientsList = ({
  recipients,
  draggableType,
  updateAllRecipients,
  enableDragDrop,
  removeRecipient,
  updateName,
  updateEmail,
  onTextFieldChange,
}) => {
  const renderRecipientItem = (recipient) => {
    return (
      <div className="flex border-t border-gray-200">
        {enableDragDrop && (
          <div className="p-3 flex justify-center items-center border-r border-gray-200 hover:bg-gray-100">
            <DraggableIcon color={theme.colors.concrete} size={14} />
          </div>
        )}
        <div className="p-4 flex-auto">
          <div className="flex items-center justify-between mb-3">
            <Text strong>{recipient.name || 'Signer'}</Text>
            {recipients.length > 1 && (
              <button
                onClick={() => removeRecipient(recipient)}
                className="flex justify-center items-center w-8 h-8 hover:bg-gray-100 rounded-md"
              >
                <CloseIcon />
              </button>
            )}
          </div>
          <div className="flex gap-3">
            <TextInput
              label="Name"
              className="flex-auto"
              placeholder="Name"
              value={recipient.name}
              onChange={(value) => {
                updateName(value, recipient);
                onTextFieldChange();
              }}
              noMargin
            />
            <TextInput
              label="Email"
              className="flex-auto"
              placeholder="Email"
              value={recipient.email}
              onChange={(value) => {
                updateEmail(value, recipient);
                onTextFieldChange();
              }}
              noMargin
            />
          </div>
        </div>
      </div>
    );
  };

  const moveRecipient = (dragIndex, hoverIndex) => {
    const updatedRecipients = [...recipients];
    const dragCard = updatedRecipients[dragIndex];
    updatedRecipients.splice(dragIndex, 1);
    updatedRecipients.splice(hoverIndex, 0, dragCard);
    updateAllRecipients(updatedRecipients);
  };

  const renderDraggableListItem = (item, index) => {
    return (
      <DraggableListItem
        enableDragDrop={enableDragDrop}
        key={`${index}_${item.id}`}
        index={index}
        id={`${index}_${item.id}`}
        moveItem={moveRecipient}
        item={item}
        renderItem={renderRecipientItem}
        draggableType={draggableType}
      />
    );
  };

  return (
    <DraggableListContainer
      renderDraggableListItem={renderDraggableListItem}
      items={recipients}
    />
  );
};

export default DraggableRecipientsList;
