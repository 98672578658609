import React from 'react';
import ConfigurePlan from './ConfigurePlan';
import Page from '~/src/components/PageLayout';
import { useStore } from '~/src/hooks/useMst';
import { useHistory } from 'react-router-dom';
import { COUPON } from '~/src/entities/subscription';

export const Subscribe = () => {
  const history = useHistory();
  const store = useStore();
  const currentOrganization = store.user.currentOrganization;
  const subscription = currentOrganization?.subscription;
  const isFreeTrialActive = subscription?.isActiveFreeTrial;

  return (
    <Page showNavigation={false} title={'Subscribe'}>
      <ConfigurePlan
        defaultSeats={
          isFreeTrialActive ? currentOrganization.members.length : 1
        }
        defaultCoupon={isFreeTrialActive ? COUPON.CLIO_SSO_FREE_TRIAL : ''}
        createSubscription={async (...args) => {
          const res = await currentOrganization.createSubscription_V2(...args);
          if (!res) return;

          if (subscription) {
            currentOrganization.fetchSubscription();
          } else {
            store.initialize();
          }
          history.replace('/library');
        }}
        fetchUpcomingInvoice={currentOrganization.fetchUpcomingInvoice}
        isFreeTrialActive={isFreeTrialActive}
      />
    </Page>
  );
};
