import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Prepare } from '@clio/hancock';
import { ReactComponent as SignSignatureIcon } from '~/src/static/sign-signature-icon.svg';
import { ReactComponent as SignDateIcon } from '~/src/static/sign-date-icon.svg';
import Button from '~/src/components/Button';
import useGhostAnnotation from '~/src/components/SignerSelection/hooks/useGhostAnnotation';
import SideMenu from '~/src/components/SignerSelection/SideMenu/SideMenu';
import SideMenuItem from '~/src/components/SignerSelection/SideMenu/SideMenuItem';
import { getAllSigners } from '~/src/components/SignerSelection/utils';
import DocumentButton from '~/src/components/SignerSelection/SideMenu/DocumentButton';
import {
  IS_LOADING,
  NEED_AT_LEAST_ONE_SIGNATURE_FOR_EACH,
} from '~/src/components/SignaturePackage/constants';
import { useWebViewerContext } from '~/src/components/Webviewer';
import { findFirstXfdfSignatureInPackage } from '~/src/components/SignerSelection/hancock';
import SignerDetailsArray from '~/src/components/SignerSelection/SignerDetailsArray';
import { CLICK_TO_SIGN_ANNOTATION } from '~/src/components/SignerSelection/clickToSign';
import useSignatureCount from '~/src/components/SignerSelection/hooks/useSignatureCount';
import HancockWrapper from '~/src/components/SignerSelection/hancockWrapper';
import {
  OnAssignFields,
  OnDateNow,
  OnDeleteAssignedField,
  OnSignNow,
} from '~/src/components/SignerSelection/SignerSelectionCallbacks';
import useMakeSignerSelectionVisible from '~/src/components/SignerSelection/hooks/useMakeSignerSelectionVisible';
import { Pdf, Signers } from '~/src/models';

interface Props {
  // PDFs in the signature package
  pdfs: Pdf[];
  // Signers in the signature package
  signers: Signers;
  // Callback for when the attorney signs a document
  onSignNow: OnSignNow;
  // Callback for when element is generally updated (e.g. coordinates changed, assignee changed)
  onAssignFields: OnAssignFields;
  // Callback for when an element is deleted
  onDeleteAssignedField: OnDeleteAssignedField;
  // Callback for when the attorney dates a document
  onDateNow: OnDateNow;
  // Callback that sets whether or not the user can proceed past this page
  setValid: (valid: boolean, reason?: string) => void;
  // Determines whether to enable or disable 'Next' button
  isLoading: boolean;
  // The attorney's current signature in XFDF format, if any
  myCurrentSignature?: string | null;
}

/**
 * This component enables the attorney to add signature and date
 * assignments to a document in the e-sign prepare flow
 */
const SignerSelection: FC<Props> = ({
  pdfs,
  signers,
  onSignNow,
  onAssignFields,
  onDeleteAssignedField,
  onDateNow,
  setValid,
  isLoading,
  myCurrentSignature,
}) => {
  const [currentDocumentId, setCurrentDocumentId] = useState<string | null>(
    null,
  );
  const hancockWrapper = useRef<HancockWrapper | null>(null);
  const maybePackageXfdfSignature = useMemo(
    () => findFirstXfdfSignatureInPackage(pdfs),
    [pdfs],
  );
  const { signatureCount, dateCount } = useSignatureCount(pdfs, signers);

  /**
   * Any time the number of signatures changes, update the state
   * of the "Next" button to be enabled if each recipient has one
   * assignment, or disabled if at least one recipient has no assignment
   */
  useEffect(() => {
    if (isLoading) {
      setValid(false, IS_LOADING);
      return;
    }

    const signatureAssignmentCount = Object.values(signatureCount);
    const allSigners = getAllSigners(signers);
    const valid =
      signatureAssignmentCount.length === allSigners.length &&
      !signatureAssignmentCount.some((e) => e === 0);
    if (valid) {
      setValid(true);
    } else {
      setValid(false, NEED_AT_LEAST_ONE_SIGNATURE_FOR_EACH);
    }
    return () => {
      // when the component unmounts, make the button active again
      setValid(true);
    };
  }, [signatureCount, setValid, signers, isLoading]);

  const { ghostAnnotation, startGhostSignature, startGhostDate } =
    useGhostAnnotation(hancockWrapper.current?.handleGhostDrop);

  const { instance } = useWebViewerContext();

  useMakeSignerSelectionVisible();

  /**
   * Sets a given PDF to be the document shown in
   * the signer selection view
   *
   * @param pdf - the PDF to use as the current doc
   */
  const setCurrentDocument = (pdf: Pdf) => {
    setCurrentDocumentId(pdf.id);
    hancockWrapper.current!.switchDocument(
      pdf,
      /**
       * If an XFDF signature is present on the package, we prefer that one.
       * Otherwise we'll use the attorney's saved signature if it exists.
       *
       * The reason for this preference order: Imagine an attorney creates
       * Package A with one signature. Then when the attorney is creating
       * Package B decides to change their signature (e.g. switching from
       * hand-drawn to typed). We don't want the previously created Package A
       * to have its signature changed, so we use whatever the attorney's
       * signature was at that point in time.
       */
      maybePackageXfdfSignature || myCurrentSignature || undefined,
    );
  };

  useEffect(() => {
    if (!hancockWrapper.current) return;
    hancockWrapper.current.signers = signers;
    hancockWrapper.current.pdfs = pdfs;
    hancockWrapper.current.onSignNow = onSignNow;
    hancockWrapper.current.onAssignFields = onAssignFields;
    hancockWrapper.current.onDeleteAssignedField = onDeleteAssignedField;
    hancockWrapper.current.onDateNow = onDateNow;
  }, [
    signers,
    pdfs,
    onSignNow,
    onAssignFields,
    onDeleteAssignedField,
    onDateNow,
  ]);

  /**
   * Instantiate hancock when webviewer becomes available
   * after the component mounts. Then load the first doc
   * and apply the event listeners/settings.
   */
  useEffect(() => {
    if (!instance) return;
    if (hancockWrapper.current) return;
    if (!pdfs.length) return;
    const hancock = new Prepare(instance, {
      clickToSignAnnotation: CLICK_TO_SIGN_ANNOTATION,
    });
    hancockWrapper.current = new HancockWrapper(
      hancock,
      signers,
      pdfs,
      onSignNow,
      onAssignFields,
      onDeleteAssignedField,
      onDateNow,
    );
    hancock.setPopOverMenuRenderer(hancockWrapper.current.renderPopoverMenu);
    hancock.on('deleted', hancockWrapper.current.handleDeletion);
    hancock.on('assigned', hancockWrapper.current.handleAssigned);
    hancock.on('signed', hancockWrapper.current.handleSigned);
    hancock.on('modified', hancockWrapper.current.handleModified);
    setCurrentDocument(pdfs[0]!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance]);

  // Destroy event listeners when the component unmounts
  useEffect(() => {
    return () => {
      // Remove any webviewer listeners that Hancock created as part of its duties:
      hancockWrapper.current?.destroy();
    };
  }, []);

  return (
    <>
      {ghostAnnotation}
      <div className="flex flex-row grow">
        <SideMenu>
          <SideMenuItem title="Signature fields">
            <div>
              <Button
                Icon={SignSignatureIcon}
                onClick={startGhostSignature}
                className="w-full"
              >
                Add signature
              </Button>
            </div>
            <div className="mt-2">
              <Button
                Icon={SignDateIcon}
                onClick={startGhostDate}
                className="w-full"
              >
                Add date
              </Button>
            </div>
          </SideMenuItem>
          <SideMenuItem title="Signers">
            <SignerDetailsArray
              signers={signers}
              signatureCountByAssigneeEmail={signatureCount}
              dateCountByAssigneeEmail={dateCount}
            />
          </SideMenuItem>
        </SideMenu>
        <div className="flex-auto">
          {/* Nothing is rendered here intentionally. WebViewer renders on top of this div */}
        </div>
        <SideMenu>
          <SideMenuItem
            title="Documents"
            childContainerClassName="flex flex-col gap-3"
          >
            {pdfs.map((pdf) => {
              const { title, id } = pdf;
              return (
                <DocumentButton
                  key={id}
                  title={title}
                  id={id}
                  isActive={id === currentDocumentId}
                  onClick={() => {
                    setCurrentDocument(pdf);
                  }}
                />
              );
            })}
          </SideMenuItem>
        </SideMenu>
      </div>
    </>
  );
};

export default SignerSelection;
