import { StyleSheet } from 'aphrodite';

import theme from '~/src/theme';

export default StyleSheet.create({
  /* ChooseTemplatesForm */
  slideInCard: {
    width: '100%',
  },
  clearFieldLink: {
    fontSize: theme.fonts.size.small,
  },
  chooseTemplatesOrText: {
    textTransform: 'uppercase',
    fontSize: theme.fonts.size.small,
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: theme.unit * 3,
  },
});
