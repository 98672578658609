/* Services */
import questionnairesApiService from '~/src/services/api/questionnaires';

/* Utilities */
import {
  mapQuestionnaireToApi,
  mapQuestionnaireResponseAnswerToApi,
  mapQuestionnaireFromApi,
  mapQuestionnaireResponseFromApi,
  mapTemplateVariableFromApi,
} from '~/src/utils/dataTransformers';

function questionnaires() {
  const deleteQuestionnaire = async (orgFprint, id) => {
    const res = await questionnairesApiService.deleteQuestionnaire(orgFprint, {
      id,
    });
    return res;
  };

  const updateQuestionnaire = async (
    orgFprint,
    {
      id,
      title,
      description,
      displayTitle,
      displayDescription,
      questionGroups,
      templateIds,
      templateSetIds,
    },
  ) => {
    const payload = mapQuestionnaireToApi({
      id,
      title,
      description,
      displayTitle,
      displayDescription,
      questionGroups,
      templateIds,
      templateSetIds,
    });

    try {
      return mapQuestionnaireFromApi(
        await questionnairesApiService.updateQuestionnaire(orgFprint, payload),
      );
    } catch {
      return null;
    }
  };

  const createNewQuestionnaire = async (
    orgFprint,
    {
      title,
      description,
      displayTitle,
      displayDescription,
      questionGroups,
      templateIds,
      templateSetIds,
    },
  ) => {
    const payload = mapQuestionnaireToApi({
      title,
      description,
      displayTitle,
      displayDescription,
      questionGroups,
      templateIds,
      templateSetIds,
    });

    const res = await questionnairesApiService.createNewQuestionnaire(
      orgFprint,
      payload,
    );

    return mapQuestionnaireFromApi(res);
  };

  const fetchQuestionnaire = async (orgFprint, id) => {
    const res = await questionnairesApiService.fetchQuestionnaire(orgFprint, {
      id,
    });

    if (res) {
      return mapQuestionnaireFromApi(res);
    }

    return null;
  };

  const fetch = async ({ orgFprint, ...query }) => {
    const res = await questionnairesApiService.fetch(orgFprint, query);

    return res.map(mapQuestionnaireFromApi);
  };

  const fetchAllQuestionnaires = async (orgFprint) => {
    const res = await questionnairesApiService.fetchQuestionnaires(orgFprint);
    return res.map(mapQuestionnaireFromApi);
  };

  const fetchTags = async (orgFprint, { templateIds, templateSetIds }) => {
    const res = await questionnairesApiService.fetchTags(orgFprint, {
      templateIds,
      templateSetIds,
    });
    return res.map(mapTemplateVariableFromApi);
  };

  const publishQuestionnaire = async (orgFprint, intakeId, matterId) => {
    const res = await questionnairesApiService.publishQuestionnaire(orgFprint, {
      intakeId,
      matterId,
    });

    if (matterId) {
      return `${res.public_url}?matter_id=${matterId}`;
    }

    return res.public_url;
  };

  const fetchMatterQuestionnaire = async (orgFprint, matterId) => {
    const res = await questionnairesApiService.fetchMatterQuestionnaire(
      orgFprint,
      { matterId },
    );
    return res.map(mapQuestionnaireResponseFromApi);
  };

  const fetchQuestionnaireResponse = async (orgFprint, id) => {
    const res = await questionnairesApiService.fetchQuestionnaireResponse(
      orgFprint,
      { id },
    );
    return mapQuestionnaireResponseFromApi(res);
  };

  const updateQuestionnaireResponse = async (
    orgFprint,
    id,
    propertiesToUpdate,
  ) => {
    const answers = propertiesToUpdate.answers.map(
      mapQuestionnaireResponseAnswerToApi,
    );
    const res = await questionnairesApiService.updateQuestionnaireResponse(
      orgFprint,
      { id, answers },
    );
    return mapQuestionnaireResponseFromApi(res);
  };

  return {
    updateQuestionnaireResponse,
    fetchQuestionnaireResponse,
    fetchMatterQuestionnaire,
    publishQuestionnaire,
    deleteQuestionnaire,
    updateQuestionnaire,
    createNewQuestionnaire,
    fetch,
    fetchQuestionnaire,
    fetchAllQuestionnaires,
    fetchTags,
  };
}

export default questionnaires();
