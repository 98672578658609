import * as React from 'react';
import { Chip, ChipProps } from '@mui/material';

export const Edited = (props: ChipProps) => {
  return (
    <Chip
      {...props}
      sx={(theme) => ({
        ...(typeof props.sx === 'function' ? props.sx(theme) : {}),
        background: theme.palette.red['50'],
        color: theme.palette.red['700'],
      })}
      label={'Custom value'}
    />
  );
};
