import React from 'react';
import { AlertTriangle, X } from 'react-feather';
import { useLayoutContext } from '~/src/contexts/Layout';
import useFeatureAccess from '~/src/hooks/useFeatureAccesses';

const UpcomingMaintenanceBannerContainer = () => {
  const { isMaintenanceBannerEnabled } = useFeatureAccess();
  const { isMaintenanceBannerActive, hideMaintenanceBanner } =
    useLayoutContext();

  const maintenanceTime = new Date('2024-10-22T03:00:00.000Z'); // Oct 21th 2024, 8:00:00 pm PDT-07:00

  if (!isMaintenanceBannerEnabled || !isMaintenanceBannerActive) {
    return <></>;
  }

  const formattedDate = maintenanceTime.toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const message =
    `Clio Draft will be undergoing scheduled maintenance ${formattedDate} between 8:00 PM - 11:59 PM PDT the next day` +
    ' during which time it will be temporarily unavailable. Thank you for your understanding.';

  return (
    <div className="flex justify-between p-4 bg-yellow-100">
      <div className="flex">
        <div className="flex items-center justify-center mr-5">
          <AlertTriangle size="25px" />
        </div>
        <p className="text-sm">{message}</p>
      </div>
      <div className="mr-2 ml-auto flex items-center justify-center">
        <X
          onClick={() => {
            hideMaintenanceBanner();
          }}
          aria-label="Close"
          size={'20px'}
          className="cursor-pointer"
        />
      </div>
    </div>
  );
};

export default UpcomingMaintenanceBannerContainer;
