import { ReactComponent as IconOnlyMe } from '~/src/static/icon-only-me.svg';
import { ReactComponent as IconMeAndOthers } from '~/src/static/icon-me-and-others.svg';
import { ReactComponent as IconOnlyOthers } from '~/src/static/icon-only-others.svg';
import { WhoSigns } from '~/src/models/signerSelection';

export const JUST_ME = {
  who: WhoSigns.JustMe,
  label: 'Only me',
  Icon: IconOnlyMe,
};

export const ME_AND_OTHERS = {
  who: WhoSigns.MeAndOthers,
  label: 'Me & others',
  Icon: IconMeAndOthers,
};

export const OTHERS = {
  who: WhoSigns.Others,
  label: 'Only others',
  Icon: IconOnlyOthers,
};

export const SIGNERS_BUTTONS = [JUST_ME, ME_AND_OTHERS, OTHERS];
