import { Button } from '@mui/material';
import React from 'react';
import { useRenewSubscription } from '~/src/entities/subscription';

export const RenewSubscriptionButton = () => {
  const { mutate, isLoading } = useRenewSubscription();
  return (
    <Button
      data-testid="renew-subscription-btn"
      size={'large'}
      variant={'contained'}
      onClick={() => mutate()}
    >
      {isLoading ? 'Renewing...' : 'Renew subscription'}
    </Button>
  );
};
