/* Libraries */
import React from 'react';
import { css } from 'aphrodite';

/* Components */
import Table from '~/src/containers/Table';
import Button from '~/src/components/Button';
import Triangle from '~/src/components/Triangle';
import Dropdown from '~/src/components/Dropdown';

/* Styles */
import dropdownStyles from '~/src/components/Dropdown/styles';
import styles from './styles';

const TeamActions = ({ onAddTeamMembers }) => {
  return (
    <div>
      <Button primary onClick={onAddTeamMembers}>
        Invite teammate
      </Button>
    </div>
  );
};

const renderNameLabel = (name, { rowData: data }) => {
  if (data.pending) {
    return (
      <div className={css(styles.pendingInvitationLabel)}>
        Pending Invitation
      </div>
    );
  }

  return data.name && data.name.length > 0 ? data.name : data.email;
};

const RowAction = ({ actions, member, disabled, text }) => {
  if (!member.showActions) {
    return null;
  }

  const handleChangeRole = () => {
    actions.changeRole(member);
  };

  const handleRemoveUser = () => {
    actions.removeUser(member);
  };

  const handleResendInvitation = () => {
    actions.resendInvitation(member);
  };

  const handleRevoke = () => {
    actions.revoke(member);
  };

  const memberActions = (
    <>
      <div
        onClick={handleChangeRole}
        className={css(dropdownStyles.dropdownActionItem)}
      >
        Change Role
      </div>
      <div
        onClick={handleRemoveUser}
        className={css(dropdownStyles.dropdownActionItem)}
      >
        Remove User
      </div>
    </>
  );

  const pendingActions = (
    <>
      <div
        onClick={handleResendInvitation}
        className={css(dropdownStyles.dropdownActionItem)}
      >
        Resend Invitation
      </div>
      <div
        onClick={handleRevoke}
        className={css(dropdownStyles.dropdownActionItem)}
      >
        Revoke
      </div>
    </>
  );

  let triggerText = (
    <>
      Actions <Triangle primary />
    </>
  );

  if (text) {
    triggerText = text;
  }

  const renderTrigger = ({ toggleDropdown }) => (
    <Button
      onClick={toggleDropdown}
      disabled={disabled}
      cssStyle={styles.rowActionButton}
    >
      {triggerText}
    </Button>
  );

  return (
    <Dropdown renderTrigger={renderTrigger}>
      {member.pending ? pendingActions : memberActions}
    </Dropdown>
  );
};

const SettingsTeam = ({
  members,
  rowActions,
  isAdmin,
  actionText,
  actionDisabled,
  onAddTeamMembers,
}) => {
  const textRowStyles = {
    textAlign: 'left',
    width: '25%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  };

  const renderRowAction = (member) => (
    <RowAction
      disabled={actionDisabled || !isAdmin}
      text={actionText}
      actions={rowActions}
      member={member}
    />
  );

  return (
    <div>
      <Table
        emptyTitle={'No team mates match that filter'}
        searchPlaceholder="Search team"
        visibleItems={members.length}
        renderActions={() =>
          isAdmin ? <TeamActions onAddTeamMembers={onAddTeamMembers} /> : null
        }
        data={members}
        columns={[
          {
            label: 'Name',
            dataKey: 'name',
            render: renderNameLabel,
            style: {
              ...textRowStyles,
              width: '20%',
            },
          },
          {
            label: 'Email',
            dataKey: 'email',
            style: textRowStyles,
          },
          {
            label: 'Role',
            dataKey: 'role',
            style: textRowStyles,
          },
          {
            label: 'Actions',
            hideLabel: true,
            render: renderRowAction,
            style: { flexGrow: 1, display: 'flex', justifyContent: 'flex-end' },
          },
        ]}
      />
    </div>
  );
};

SettingsTeam.defaultProps = {
  onAddTeamMembers: () => {},
  teamMates: [
    { name: 'John', email: 'john@johndoelaw.com', role: 'Admin' },
    { name: 'Omid', email: 'omid@lawyaw.com', role: 'Member' },
    { name: 'Sarah', email: 'sarah@gmail.com', role: 'Member' },
    { name: 'Juda', email: 'jude@earthrider.com', role: 'Admin' },
  ],
};

export default SettingsTeam;
