/* Libraries */
import React from 'react';
import { css } from 'aphrodite';

/* Styles */
import styles from './styles';

const Error = ({ children, cssStyle }) => (
  <span className={css(styles.errorText, cssStyle)}>{children}</span>
);

export default Error;
