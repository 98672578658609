import React from 'react';
import Button from '~/src/components/Button';
import ReactTooltip from 'react-tooltip';
/* Styles */
import { ReactComponent as ItemSelectedIcon } from '~/src/static/item-selected.svg';

interface Props {
  templateDefaultValues: any;
  handleAddTemplateToSelectionSidebar: Function;
  isAddButtonDisabled: boolean;
  getAddButtonTooltip: string | null;
  containedInSidebar: Function;
}

const AddButtonOrAddedIcon = ({
  templateDefaultValues,
  handleAddTemplateToSelectionSidebar,
  isAddButtonDisabled,
  getAddButtonTooltip,
  containedInSidebar,
}: Props) => {
  if (containedInSidebar(templateDefaultValues)) {
    return (
      <div className="flex">
        <div className="mr-2 flex items-center">
          <ItemSelectedIcon />
        </div>
        <div className="leading-none">Added</div>
      </div>
    );
  }

  return (
    <div>
      <Button
        data-tip
        data-for={`add-button-${templateDefaultValues.id}`}
        onClick={() => {
          handleAddTemplateToSelectionSidebar(templateDefaultValues);
        }}
        disabled={isAddButtonDisabled}
      >
        <span>Add</span>

        <ReactTooltip
          id={`add-button-${templateDefaultValues.id}`}
          place="bottom"
          effect="solid"
          getContent={() => {
            return getAddButtonTooltip;
          }}
        ></ReactTooltip>
      </Button>
    </div>
  );
};

export default AddButtonOrAddedIcon;
