import questionnairesService from '~/src/services/questionnaires';
import matterService from '~/src/services/matter';
import { useCallback } from 'react';
import { useStore } from './useMst';
import { useCurrentOrgFprint } from '../entities/user';

export const useFetchMatterDetail = () => {
  const {
    matter: matterStore,
    questionnaireResponses: qsStore,
    projects: projectsStore,
    contacts: contactsStore,
  } = useStore();
  const orgFprint = useCurrentOrgFprint();
  return useCallback(
    async (matterId) => {
      const {
        matter: nextMatter,
        attachedProjects,
        attachedContacts,
      } = await matterService.fetchMatter(orgFprint, matterId);

      const questionnaireResponses =
        await questionnairesService.fetchMatterQuestionnaire(
          orgFprint,
          matterId,
        );

      questionnaireResponses.map(qsStore.addQuestionnaireResponse);
      attachedProjects.map(projectsStore.addProject);
      attachedContacts.map(contactsStore.addContact);

      nextMatter.details.questionnaireResponses = questionnaireResponses.map(
        ({ identifier }) => identifier,
      );

      nextMatter.documentCount = attachedProjects
        .map((project) => project.documents.length)
        .reduce((acc, val) => acc + val, 0);

      matterStore.setMatter(nextMatter);
      return matterStore.getMatter(matterId);
    },
    [matterStore, qsStore, projectsStore, contactsStore, orgFprint],
  );
};
