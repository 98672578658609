/* Utilities */
import { delegateMSTEvent } from '~/src/utils/analytics';
import analyticsService from '~/src/services/analytics';

/* Events */
const events = {
  generate: (actionArgs, rootStore) => {
    const templateSetId = actionArgs[0];
    const templateSet = rootStore.templateSets.getById(templateSetId);

    analyticsService.track('Template Set Generate', {
      templateSetId: templateSet.id,
      templateSetTitle: templateSet.title,
      templatesCount: templateSet.templates.length,
      templatesIds: templateSet.templates,
      templatesTitles: templateSet.templates.map((t) => t.title),
    });
  },
  update: (actionArgs, rootStore) => {
    const payload = actionArgs[0];

    const templateSet = rootStore.templateSets.getById(payload.id);

    const currentTemplateSet = {
      templateSetId: payload.id,
      templateSetTitle: templateSet.title,
      templatesCount: templateSet.templates.length,
      templatesTitles: templateSet.templates.map((t) => t.title),
      templatesIds: templateSet.templates.map((t) => t.id),
    };

    const nextTemplateSet = {
      templateSetId: payload.id,
      templateSetTitle: payload.title || templateSet.title,
      templatesCount: payload.templates
        ? payload.templates.length
        : currentTemplateSet.templatesCount,
      templatesTitles: payload.templates
        ? payload.templates.map(
            (id) => rootStore.templates.getTemplate(id).title,
          )
        : currentTemplateSet.templatesTitles,
      templatesIds: payload.templates
        ? payload.templates
        : currentTemplateSet.templatesIds,
    };

    analyticsService.track('Template Set Updated', {
      templateSetId: currentTemplateSet.templateSetId,
      templateSetCurrentTitle: currentTemplateSet.templateSetTitle,
      templateSetNextTitle: nextTemplateSet.templateSetTitle,
      templateSetCurrentTemplatesCount: currentTemplateSet.templatesCount,
      templateSetNextTemplatesCount: nextTemplateSet.templatesCount,
      templateSetCurrentTemplatesTitles: currentTemplateSet.templatesTitles,
      templateSetNextTemplatesTitles: nextTemplateSet.templatesTitles,
      templateSetCurrentTemplatesIds: currentTemplateSet.tempaltesIds,
      templateSetNextTemplatesIds: nextTemplateSet.tempaltesIds,
    });
  },
  remove: (actionArgs) => {
    const payload = actionArgs[0];

    const children = payload.children || [];

    analyticsService.track('Template Set Removed', {
      templateSetId: payload.id,
      templateSetTitle: payload.title,
      templatesCount: children.length,
      templatesTitles: children.map((t) => t.title),
      templatesIds: children.map((t) => t.id),
    });
  },
};

const flowReturnEvents = {
  create: (actionArgs) => {
    const payload = actionArgs[0];

    analyticsService.track('Template Set Created', {
      templateSetId: payload?.id,
      templateSetTitle: payload?.title,
      templatesCount: payload?.templates?.length,
      templatesIds: payload?.templates,
    });
  },
};

export default (call, rootStore) => {
  return delegateMSTEvent(call, rootStore, { events, flowReturnEvents });
};
