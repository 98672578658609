import React from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { FormField } from '~/src/components/FormField';

type CancelSubscriptionPromptProps = {
  checked: boolean;
  endDate: string;
  onChange: (checked: boolean) => void;
};

export const CancelSubscriptionPrompt = ({
  checked,
  endDate,
  onChange,
}: CancelSubscriptionPromptProps) => {
  return (
    <>
      <Typography>
        By canceling your subscription you and all members of your organization
        will lose access to Clio Draft at the end of your current billing period
        on {endDate}. You will no longer have access to your client information,
        projects or documents.
      </Typography>
      <Box pt={2}>
        <FormField>
          <FormControlLabel
            sx={{ display: 'flex', alignItems: 'flex-start', margin: 0 }}
            control={
              <Checkbox
                data-testid="confirm-cancel-checkbox"
                checked={checked}
                onChange={() => onChange(!checked)}
              />
            }
            label={
              <Box pl={2}>
                I acknowledge that by canceling this account, all members of
                this organization will lose access to all their clients,
                projects, and documents created by Clio Draft.
              </Box>
            }
          />
        </FormField>
      </Box>
    </>
  );
};
