import { ReactComponent as StatusCanceledIcon } from '~/src/static/signature-status-canceled.svg';
import { ReactComponent as StatusDeclinedIcon } from '~/src/static/signature-status-declined.svg';
import { ReactComponent as StatusDraftingIcon } from '~/src/static/signature-status-drafting.svg';
import { ReactComponent as StatusPendingIcon } from '~/src/static/signature-status-pending.svg';
import { ReactComponent as StatusSignedIcon } from '~/src/static/signature-status-signed.svg';

/*
key: display text
value: icon component
*/
export const STATUS_ICON_MAP = {
  Completed: StatusSignedIcon, // green circle
  Declined: StatusDeclinedIcon, // red circle
  'Out for signature': StatusPendingIcon, // blue half circle
  'Waiting for sender': StatusDraftingIcon, // blank circle with gray border
  Draft: StatusDraftingIcon, // blank circle with gray border
  Expired: StatusCanceledIcon, // gray circle
  Unknown: StatusCanceledIcon, // gray circle
  Closed: StatusCanceledIcon, // gray circle
  Signed: StatusSignedIcon, // green circle
  Pending: StatusPendingIcon, // blue half circle
  Canceled: StatusCanceledIcon, // gray circle,
  Cancelled: StatusCanceledIcon,
};
