/**
 * Default pagination ordering field options.
 */
// eslint-disable-next-line no-shadow
export enum SortByOrder {
  /**
   * Ascending [A => Z ... or 0 => 9]
   */
  asc = 'asc',
  /**
   *  Descending [Z => A ... or 9 => 0]
   */
  desc = 'desc',
}

export default SortByOrder;
