import { Core, WebViewerInstance } from '@pdftron/webviewer';
import env from '~/src/utils/env';

export const PD_DELIMITER = '\t';
export const FONT_SIZE = 9;

export const setHeaderItemsOfInstance = function (instance: WebViewerInstance) {
  instance.UI.setHeaderItems((header) => {
    const headerItems = header.getItems() as { [key: string]: string }[];
    // Only include the zoom and pan tools:
    const zoomOverlay = headerItems.find((e) => e.element === 'zoomOverlay');
    const pan = headerItems.find((e) => e.toolName === 'Pan');
    const zoomAndPanItems = [];
    if (zoomOverlay) {
      zoomAndPanItems.push(zoomOverlay);
    }
    if (pan) {
      zoomAndPanItems.push(pan);
    }
    header.update(zoomAndPanItems);
    header.push({
      type: 'spacer',
    });
    const newToolButton = {
      type: 'toolButton',
      toolName: 'AnnotationCreateFreeText',
    };
    header.push(newToolButton);
  });
  const tool = instance.Core.documentViewer.getTool('AnnotationCreateFreeText');
  tool.setStyles((styles: Record<string, any>) => {
    return {
      ...styles,
      Font: 'Lato',
      FontSize: 9.5,
    };
  });
  tool.cursor =
    "url('/static/js/webviewer/8.12.3/ui/assets/insert-icon.svg'), auto";
  tool.setStyles(() => ({
    TextColor: new instance.Core.Annotations.Color(0, 0, 0),
  }));
  instance.UI.updateTool('AnnotationCreateFreeText', {
    buttonImage: '/static/js/webviewer/8.12.3/ui/assets/custombox.svg',
    buttonName: 'add-custom-field',
    tooltip: 'Add custom field',
  });
  const CUSTOM_FIELD_BTN_LABEL = 'add-custom-field-btn';
  const addCustomField = document.createElement('span');
  addCustomField.id = CUSTOM_FIELD_BTN_LABEL;
  addCustomField.innerText = 'Add custom field';
  addCustomField.style.paddingLeft = '5px';

  instance.UI.updateTool('Pan', {
    tooltip: 'Pan',
  });

  const customFieldNode = instance.UI.iframeWindow.document?.querySelector(
    '[data-element="add-custom-field"',
  );
  const existingCustomFieldInnerText =
    instance.UI.iframeWindow.document?.getElementById(CUSTOM_FIELD_BTN_LABEL);
  // Add the 'Add Custom Field' text to the button if it's not there already:
  if (customFieldNode && !existingCustomFieldInnerText) {
    customFieldNode.appendChild(addCustomField);
  }

  // TODO - Determine default color palette
  instance.UI.setColorPalette([
    '#FF2222',
    '#000000',
    '#FFFFFF',
    'transparency',
  ]);
  instance.UI.disableElements([
    'toolsHeader',
    'annotationCommentButton',
    'linkButton',
    'opacitySlider',
  ]);
  instance.UI.enableElements(['header']);
};

export const init = (instance: WebViewerInstance) => {
  instance.Core.setCustomFontURL(
    `${env.apiUrl}/static/js/webviewer/8.12.3/webfonts/`,
  );
  setHeaderItemsOfInstance(instance);

  instance.Core.documentViewer.addEventListener('beginRendering', () => {
    // We want the document viewer to always start with the following tool set
    instance.Core.documentViewer.setToolMode(
      instance.Core.documentViewer.getTool('AnnotationEdit'),
    );
    instance.UI.hotkeys.off();
  });
  /**
   * WebViewer 8.12.3 has some problems when it comes to hiding
   * the tools header at certain screen sizes. So we have to do
   * it manually in this very hacky way to ensure users don't
   * see the tools header. Once we've upgraded to 8.4 or whatever,
   * we can remove this code.
   */

  const toolsHeader = instance.UI.iframeWindow.document.querySelector(
    "[data-element='toolsHeader']",
  ) as HTMLElement;
  if (toolsHeader) {
    toolsHeader.style.display = 'none';
  }
};

/**
 * This function handles the tab index for input elements
 * on the form, and also establishes a listener for when
 * the user resets a field.
 */
export const setTabIndexOfPDFElementsAndFieldResetHandler = (
  // The WebViewer instance
  instance: WebViewerInstance,
  // The callback to handle the resetting of a field
  onFieldReset: (foo: string) => void,
  // When called, returns the starting value for the field (IE the value to reset to)
  getStartingValue: (annotation: Core.Annotations.Annotation) => string,
  // When called, returns the starting value for the field (IE the value to reset to)
  getTabIndex: (annotation: Core.Annotations.Annotation) => number,
  // Toggles whether or not the annotation can be reset
  toggleResetability: (
    annotation: Core.Annotations.Annotation,
    newValue: boolean,
  ) => void,
  // Checks whether or not the annotation can be reset
  getIsResetable: (annotation: Core.Annotations.Annotation) => boolean,
) => {
  const { Annotations } = instance.Core;
  const originalCheckButtonWidgetInnerElement =
    Annotations.CheckButtonWidgetAnnotation.prototype.createInnerElement;
  const originalTextWidgetInnerElement =
    Annotations.TextWidgetAnnotation.prototype.createInnerElement;

  /**
   * This function allows us to restore the widget element function to
   * its original state. This is useful on component unmount, so that
   * different views can treat form fields differently
   */
  const destroy = () => {
    Annotations.CheckButtonWidgetAnnotation.prototype.createInnerElement =
      originalCheckButtonWidgetInnerElement;
    Annotations.TextWidgetAnnotation.prototype.createInnerElement =
      originalTextWidgetInnerElement;
  };

  let isTyping = false;

  const tooltipForReset = () => {
    const tooltipDiv = document.createElement('div');
    tooltipDiv.innerText = 'Reset to initial value';
    tooltipDiv.className = 'reset-tooltip tooltip--bottom';
    tooltipDiv.hidden = true;
    return tooltipDiv;
  };

  const resetButtonElement = (
    annotation: any,
    originalValue: any,
    enabledReset: any,
    inFocus: any,
    instance: WebViewerInstance,
  ) => {
    const field = annotation.getField();
    const buttonElement = document.createElement('button');
    buttonElement.id = annotation.Id;
    buttonElement.className = 'reset-button';
    buttonElement.addEventListener('click', (event) => {
      toggleResetability(annotation, false);
      field.setValue(originalValue);
      // @ts-expect-error - TODO: Remove hidden? It doesn't seem valid
      event.target!.hidden = true;
      enabledReset = false;

      const currentDocumentId = instance.Core.documentViewer
        .getDocument()
        .getDocumentId();
      const stringToFind = `${PD_DELIMITER + String(currentDocumentId)}$`;
      const regExp = new RegExp(stringToFind, 'g');

      if (field.name.match(regExp)) {
        // if the annotation we're resetting is specific to the document
        // then we want to delete the document-specific annotation from stack_saved_data
        // because otherwise, despite resetting the value to the original
        // the field will not track with any changes to the values that the user may set in the populate stage
        const oneWayFieldToDelete = field.name.substring(
          field.name.indexOf('lawyaw_prefix') + 'lawyaw_prefix'.length,
        );
        onFieldReset(oneWayFieldToDelete);
      }
    });
    buttonElement.addEventListener('mouseout', () => {
      const tooltipDiv =
        buttonElement.parentElement!.querySelector('.reset-tooltip');
      // @ts-expect-error - TODO: Remove hidden? It doesn't seem valid
      tooltipDiv.hidden = true;
    });
    buttonElement.addEventListener('mouseover', (event) => {
      inFocus = true;
      // @ts-expect-error - TODO: Remove hidden? It doesn't seem valid
      event.target!.hidden = false;
      const tooltipDiv =
        buttonElement.parentElement!.querySelector('.reset-tooltip');
      // @ts-expect-error - TODO: Remove hidden? It doesn't seem valid
      tooltipDiv.hidden = false;
    });
    return buttonElement;
  };

  let currentBackground = '';
  // TODO improve tabindex calculation, use from basic as tab doesn't work for 2nd page.
  const setTabIndexOfInnerElement = function (innerElement: HTMLElement) {
    return function () {
      // @ts-expect-error
      const button = this;
      // @ts-expect-error
      // eslint-disable-next-line prefer-rest-params
      const el = innerElement.apply(this, arguments);
      el.tabIndex = getTabIndex(button);
      const ogVal = getStartingValue(button);
      let enabledReset = false;
      let inFocus = false;
      const isTaggedField = button.getField().name.indexOf('untagged_') >= 0;

      el.addEventListener('focusin', () => {
        inFocus = true;
        const allButtons =
          instance.UI.iframeWindow.document.querySelectorAll('.reset-button');
        allButtons.forEach((eachButton) => {
          if (eachButton.id != button.Id) {
            // @ts-expect-error - TODO: Remove hidden? It doesn't seem valid
            eachButton.hidden = true;
          }
        });
      });
      el.addEventListener('focusout', () => {
        const resetButton = instance.UI.iframeWindow.document.getElementById(
          button.Id,
        );
        if (resetButton && !isTyping) {
          enabledReset = false;
          resetButton.hidden = true;
        }
        inFocus = false;
      });
      el.addEventListener('keydown', (event: any) => {
        isTyping = true;
        if (event.target!.value != ogVal && !isTaggedField) {
          toggleResetability(button, true);
          if (getIsResetable(button)) {
            enabledReset = true;
            const resetButtonExists =
              instance.UI.iframeWindow.document.getElementById(button.Id);
            if (resetButtonExists) {
              resetButtonExists.hidden = false;
            } else {
              event.target.after(tooltipForReset());
              event.target.after(
                resetButtonElement(
                  button,
                  ogVal,
                  enabledReset,
                  inFocus,
                  instance,
                ),
              );
            }
          }
        }
      });
      el.addEventListener('mouseover', (event: any) => {
        currentBackground = event.target.style.background;

        if (getIsResetable(button)) {
          event.target.style.background = 'rgba(113,113,113,.2)';
          if (event.target.value != ogVal && !enabledReset && !isTaggedField) {
            enabledReset = true;
            const resetButton =
              instance.UI.iframeWindow.document.getElementById(button.Id);
            if (resetButton) {
              resetButton.hidden = false;
            } else {
              event.target.after(tooltipForReset());
              event.target.after(
                resetButtonElement(
                  button,
                  ogVal,
                  enabledReset,
                  inFocus,
                  instance,
                ),
              );
            }
          }
        } else {
          const metadata = button.getCustomData('METADATA');
          if (metadata) {
            try {
              const tagString = JSON.parse(metadata).tagString;
              if (tagString) {
                event.target.style.background = 'rgba(132, 87, 255, 0.2)';
              } else {
                event.target.style.background = 'rgba(73,182,239,.21)';
              }
            } catch (err) {
              console.error(
                'METADATA is not parseable - could not extract tag string from annotation',
              );
            }
          } else {
            event.target.style.background = 'rgba(73,182,239,.21)';
          }
        }
      });
      el.addEventListener('mouseout', (event: any) => {
        event.target.style.background = currentBackground;
        const resetButton = instance.UI.iframeWindow.document.getElementById(
          button.Id,
        );
        if (resetButton && !inFocus) {
          enabledReset = false;
          resetButton.hidden = true;
        }
      });
      return el;
    };
  };
  Annotations.TextWidgetAnnotation.prototype.createInnerElement =
    setTabIndexOfInnerElement(
      // @ts-expect-error
      Annotations.TextWidgetAnnotation.prototype.createInnerElement,
    );
  Annotations.CheckButtonWidgetAnnotation.prototype.createInnerElement =
    setTabIndexOfInnerElement(
      // @ts-expect-error
      Annotations.CheckButtonWidgetAnnotation.prototype.createInnerElement,
    );

  return { destroy };
};
