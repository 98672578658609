/* Libraries */
import React from 'react';
import { inject, observer } from 'mobx-react';

/* Higher Order Components */
import WithLayoutProps from '~/src/hoc/WithLayoutProps';

/* Components */
import SettingsTeam from '~/src/components/Settings/SettingsTeam';

/* Constanst */
import { LAYOUT_MODAL_TYPES } from '~/src/components/PageLayout/Modals';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';

const LOADING = 'LOADING';
const SUCCESS = 'SUCCESS';
const ERROR = 'ERROR';

const defaultState = {
  removeUserState: SUCCESS,
  resendInvitationState: SUCCESS,
  revokeInvitationState: SUCCESS,
};

class SettingsTeamContainer extends React.Component {
  state = defaultState;

  handleAddTeamMembers = () => {
    this.props.showModal(LAYOUT_MODAL_TYPES.inviteTeamMembers);
  };

  handleChangeRole = (member) => {
    const handleChangeRoleConfirm = (form) => {
      const newRole = form.fields.role.value;
      this.props.store.user.currentOrganization.changeMemberRole(
        member.id,
        newRole,
      );
      this.props.hideModal();
      this.props.showToast(LAYOUT_TOAST_TYPES.success, {
        message: `${member.email} role has succesfully been updated to ${newRole}`,
      });
    };

    const memberRoleValue = member.role.toLowerCase();

    this.props.showModal(LAYOUT_MODAL_TYPES.formField, {
      title: 'Change Role',
      primaryActionTitle: 'Save',
      fields: [
        {
          id: 'email',
          hint: 'Email',
          type: 'staticText',
          defaultValue: member.email,
          validation: () => true,
        },
        {
          id: 'role',
          hint: 'Role',
          type: 'multipleChoice',
          defaultValue: memberRoleValue,
          options: [
            { label: 'Member', value: 'member' },
            { label: 'Admin', value: 'admin' },
          ],
          validation: ({ touched, value }) =>
            touched && value !== memberRoleValue,
        },
      ],
      onConfirm: handleChangeRoleConfirm,
    });
  };

  handleRemoveUser = async (member) => {
    // this.setState({ removeUserState: LOADING });
    const success =
      await this.props.store.user.currentOrganization.removeMember(member.id);

    if (success) {
      this.props.showToast(LAYOUT_TOAST_TYPES.success, {
        message: `${member.email} has been removed!`,
      });
    } else {
      this.props.showToast(LAYOUT_TOAST_TYPES.error, {
        message: `${member.email} failed to to be removed, please try again later.`,
      });
    }

    this.setState({ removeUserState: success ? SUCCESS : ERROR });
  };

  handleResendInvitation = async (member) => {
    // this.setState({ resendInvitationState: LOADING });
    const success =
      await this.props.store.user.currentOrganization.resendMemberInvitation(
        member.id,
      );

    if (success) {
      this.props.showToast(LAYOUT_TOAST_TYPES.success, {
        message: `Invitation Sent to ${member.email}!`,
      });
    } else {
      this.props.showToast(LAYOUT_TOAST_TYPES.error, {
        message: 'Invitation failed to send, please try again later.',
      });
    }

    this.setState({ resendInvitationState: success ? SUCCESS : ERROR });
  };

  handleRevoke = async (member) => {
    // this.setState({ revokeInvitationState: LOADING });
    const success =
      await this.props.store.user.currentOrganization.revokeMemberInvitation(
        member.id,
      );

    if (success) {
      this.props.showToast(LAYOUT_TOAST_TYPES.success, {
        message: `${member.email} invitation has been revoked!`,
      });
    } else {
      this.props.showToast(LAYOUT_TOAST_TYPES.error, {
        message: 'Inivitation failed to revoke, please try again later.',
      });
    }

    this.setState({ revokeInvitationState: success ? SUCCESS : ERROR });
  };

  getActionState = () => {
    const { revokeInvitationState, resendInvitationState, removeUserState } =
      this.state;

    if (revokeInvitationState === LOADING) {
      return 'Revoking invitation...';
    }
    if (resendInvitationState === LOADING) {
      return 'Resending invitation...';
    }
    if (removeUserState === LOADING) {
      return 'Removing user...';
    }

    return null;
  };

  getActionDisabled = () => {
    const { revokeInvitationState, resendInvitationState, removeUserState } =
      this.state;

    if (
      revokeInvitationState === LOADING ||
      resendInvitationState === LOADING ||
      removeUserState === LOADING
    ) {
      return true;
    }

    return false;
  };

  render() {
    const currentOrg = this.props.store.user.currentOrganization;
    const members = currentOrg ? currentOrg.members : [];
    const isAdmin = currentOrg.admin || false;

    const membersDataList = members.map((member) => {
      return {
        id: member.id,
        name: member.displayName,
        email: member.email,
        showActions: member.email !== currentOrg.email,
        role: member.admin ? 'Admin' : 'Member',
        pending: member.invitationPending,
      };
    });

    const actions = {
      changeRole: this.handleChangeRole,
      removeUser: this.handleRemoveUser,
      resendInvitation: this.handleResendInvitation,
      revoke: this.handleRevoke,
    };

    const actionText = this.getActionState();
    const actionDisabled = this.getActionDisabled();

    return (
      <SettingsTeam
        isAdmin={isAdmin}
        actionText={actionText}
        actionDisabled={actionDisabled}
        members={membersDataList}
        onAddTeamMembers={this.handleAddTeamMembers}
        rowActions={actions}
      />
    );
  }
}

export default inject((store) => store)(
  WithLayoutProps(observer(SettingsTeamContainer)),
);
